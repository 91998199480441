import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, AuthenticationService, NotyService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-password-new',
  templateUrl: './auth-password-new.component.html',
  styleUrls: ['../auth.scss', './auth-password-new.component.scss']
})
export class AuthPasswordNewComponent implements OnInit {
  form!: FormGroup;
  isLoading = false;
  wasSent = false;
  secondChance = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({ email: '' });
  }

  ngOnInit() {
    this.authenticationService.logout().subscribe();
  }

  sendSecondChance() {
    this.secondChance = true;
    this.sendPasswordConfirmation();
  }

  sendPasswordConfirmation() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.authenticationService
      .sendResetPasswordConfirmation(this.form.get('email').value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.notyService.success(this.translateService.instant('notyService.emailSentResetPassword'));
          // this.form.get('email').setValue('');

          this.wasSent = true;
        },
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }

  isOutlookEmail(email: string) {
    return !!(email || '').match(/(@hotmail\.com)|(@outlook\.com)/);
  }
}
