<div class="page">
  <div class="title blured">
    <p class="title-text"></p>
  </div>
  <div class="paragraph">
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </div>
  <div class="paragraph">
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet</p>
  </div>
  <div class="paragraph">
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ipsum</p>
    <p class="paragraph-text blured">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </div>
</div>
