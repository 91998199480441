<aside *ngIf="whitelabelService.params.sidebar" class="taxonomy">
  <h2 class="taxonomy-title">
    <span>{{ mainTitle }}</span>
    <button type="button" class="taxonomy-search-button" tabindex="-1" (click)="isSearchOpen = !isSearchOpen; isSearchOpenChange.emit(isSearchOpen)" *ngIf="allowSearch !== false">
      <i class="fal fa-search"></i>
      <i class="fal fa-caret-right" *ngIf="!isSearchOpen"></i>
      <i class="fal fa-caret-left" *ngIf="isSearchOpen"></i>
    </button>
  </h2>
  <ng-content></ng-content>
</aside>
