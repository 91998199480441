import { NgModule } from '@angular/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpBatchLinkModule, HttpBatchLink } from 'apollo-angular-link-http-batch';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache, InMemoryCacheConfig, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import { CredentialsService } from '@app/core';
import { environment } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';

@NgModule({
  exports: [ApolloModule, HttpBatchLinkModule, HttpLinkModule]
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpBatchLink: HttpBatchLink, httpLink: HttpLink, credentialsService: CredentialsService) {
    const authLink = setContext((_, { headers }) => {
      return { headers: new HttpHeaders({ ...headers, ...credentialsService.authorizationHeader }) };
    });

    const cacheConfig: InMemoryCacheConfig = {
      dataIdFromObject: data => {
        if (data.__typename === 'Member') {
          const id = data.id || (data as any).user.id;
          return `${data.__typename}:${typeof id === 'number' || typeof id === 'string' ? id : JSON.stringify(id)}`;
        } else {
          return defaultDataIdFromObject(data);
        }
      }
    };

    apollo.create({
      link: authLink.concat(httpBatchLink.create({ uri: '/graphql/secret', withCredentials: false, batchMax: 60 })),
      cache: new InMemoryCache(cacheConfig),
      defaultOptions: { query: { fetchPolicy: 'network-only' } },
      connectToDevTools: !environment.production || environment.debug // Extensão só funciona com um client e dá preferência ao último a ser criado
    });

    apollo.create(
      {
        link: httpBatchLink.create({ uri: '/graphql/public', withCredentials: false, batchMax: 60 }),
        cache: new InMemoryCache(cacheConfig),
        defaultOptions: { query: { fetchPolicy: 'network-only' } }
      },
      'public'
    );

    apollo.create(
      {
        link: authLink.concat(httpLink.create({ uri: '/graphql/secret', withCredentials: false, useMultipart: true })),
        cache: new InMemoryCache(cacheConfig),
        defaultOptions: { query: { fetchPolicy: 'network-only' } }
      },
      'upload'
    );
  }
}
