import { Component, ElementRef, Input, OnChanges, QueryList, ViewChildren, SimpleChanges } from '@angular/core';

import { Document, Whitelabel } from '@app/models';
import { WhitelabelService } from '@app/services';

@Component({
  selector: 'app-footer-preview',
  templateUrl: './footer-preview.component.html',
  styleUrls: ['./footer-preview.component.scss']
})
export class FooterPreviewComponent implements OnChanges {
  @ViewChildren('container') container: QueryList<ElementRef>;
  @Input() pdfContainer: HTMLElement;
  @Input() pagesCount: number;
  @Input() loadedPages: number;
  @Input() documentFooter: string;
  @Input() qualified: boolean;
  @Input() documentWhitelabel: Whitelabel;
  @Input() document: Document;
  @Input() isDocumentWhitelabelLoaded: boolean;
  private readonly maxPageWidth = 794;
  private readonly maxFooterHeight = 54;
  private readonly maxFooterMargin = 4;

  constructor(public whitelabelService: WhitelabelService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.documentFooter && (changes.pagesCount || changes.loadedPages || changes.documentFooter)) {
      setTimeout(() => {
        const footerElements = this.container?.toArray() || [];
        this.positionFooter(footerElements, this.documentFooter);
      });
    }
  }

  positionFooter(footerElements: Array<ElementRef>, position: string) {
    let page = 1;
    footerElements.forEach((footer: { nativeElement: any }) => {
      if (this.pdfContainer.children.length >= page) {
        const canvas = this.pdfContainer.children[page - 1] as HTMLElement;
        const scaleLow = canvas.offsetWidth / this.maxPageWidth;
        const scaleHigh = this.maxPageWidth / canvas.offsetWidth;
        const style = footer.nativeElement.style;
        if (position === 'BOTTOM') {
          style.transform = 'rotate(0) ' + `scale(${scaleLow})`;
          style.transformOrigin = 'bottom left';
          style.height = this.maxFooterHeight + 'px';
          style.width = canvas.offsetWidth * scaleHigh - this.maxFooterMargin * scaleHigh * 2 + 'px';
          style.left = this.maxFooterMargin + 'px';
          style.right = null;
          style.top = canvas.offsetTop - footer.nativeElement.offsetHeight + canvas.offsetHeight - this.maxFooterMargin + 'px';
        } else if (position === 'RIGHT') {
          style.transform = 'rotate(-90deg) ' + `scale(${scaleLow})`;
          style.transformOrigin = 'bottom right';
          style.height = this.maxFooterHeight + 'px';
          style.width = canvas.offsetHeight * scaleHigh - this.maxFooterMargin * scaleHigh * 2 + 'px';
          style.left = null;
          style.right = this.maxFooterMargin + 'px';
          style.top = canvas.offsetTop - footer.nativeElement.offsetHeight + this.maxFooterMargin + 'px';
        } else if (position === 'LEFT') {
          style.transform = 'rotate(90deg) ' + `scale(${scaleLow})`;
          style.transformOrigin = 'bottom left';
          style.height = this.maxFooterHeight + 'px';
          style.width = canvas.offsetHeight * scaleHigh - this.maxFooterMargin * scaleHigh * 2 + 'px';
          style.left = this.maxFooterMargin + 'px';
          style.right = null;
          style.top = canvas.offsetTop - footer.nativeElement.offsetHeight + this.maxFooterMargin + 'px';
        }
        page++;
      }
    });
  }

  qrCodeUrl() {
    return `https://${this.whitelabelService?.whitelabel?.domain ? this.whitelabelService.whitelabel.domain + '/verificar' : 'valida.ae'}/${this.document?.id || ''}`;
  }
}
