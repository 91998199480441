import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, OrganizationService, UserService } from '@app/services';
import { SlimOrganizationGroup, OrganizationMemberPermissions } from '@app/models';

export type ModalResult = boolean[];

enum FormSteps {
  invites,
  permissions,
  group
}

@Component({
  selector: 'app-invite-organization-members-modal',
  templateUrl: './invite-organization-members-modal.component.html',
  styleUrls: ['./invite-organization-members-modal.component.scss']
})
export class InviteOrganizationMembersModalComponent implements AfterContentInit {
  form!: FormGroup;
  isLoading = false;
  formStep = FormSteps.invites;
  groups: SlimOrganizationGroup[];
  readonly FormSteps = FormSteps;
  @ViewChild('emailsInput', { static: false }) private emailsInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private organizationService: OrganizationService
  ) {
    this.form = this.formBuilder.group({
      member: this.formBuilder.group({
        email: null,
        group_id: null,
        permissions: this.formBuilder.group({
          create_documents: true,
          archive_documents: true,
          delete_documents: true,
          sign_documents: true,
          view_documents_gr: true,
          view_folders_gr: true,
          actions_documents_gr: false,
          actions_folders_gr: false,
          actions_templates_gr: false,
          view_documents_oz: true,
          view_folders_oz: true,
          view_member_documents_oz: false,
          view_member_folders_oz: false,
          view_group_documents_oz: false,
          view_group_folders_oz: false,
          view_invoices_oz: true,
          actions_documents_oz: false,
          actions_folders_oz: false,
          actions_members_oz: false,
          actions_groups_oz: false,
          actions_webhooks_oz: false,
          change_appearances_oz: false,
          change_plan_oz: false
          // change_whitelabel_oz: false // TO-DO: ACTIVATE WHEN WHITELABEL LAUNCHES
        } as OrganizationMemberPermissions)
      })
    });
  }

  ngAfterContentInit() {
    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(user => {
      this.organizationService.slimOrganizationGroups({ page: 1, limit: 60, organizationId: user.organization.id }, { fetchPolicy: 'cache-first' }).subscribe(page => {
        this.groups = page.data;
        this.form.get('member.group_id').setValue(this.groups.length === 60 ? this.groups[0].id : this.groups.length > 0 ? this.groups[this.groups.length - 1].id : null);
      });
    });

    setTimeout(() => this.emailsInput.nativeElement.focus());
  }

  getEmails() {
    return (this.form.get('member.email').value.match(/[^,\s]+/g) as string[]).map(email => (email || '').trim().toLowerCase()).filter(email => !!email);
  }

  invite() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.organizationService
      .createMember(this.getEmails().map(email => ({ member: { ...this.form.get('member').value, email } })))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => {
          this.formStep = FormSteps.invites;
          setTimeout(() => this.errorHandlerService.handleValidation(this.form, error));
        }
      );
  }
}
