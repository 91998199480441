<form class="modal-body" [formGroup]="form" (ngSubmit)="submit()" novalidate>
  <p class="centermodal__title">{{ 'model.setModelName' | translate }}</p>

  <div class="centermodal__form" formGroupName="template">
    <label class="field__holder" [appFormControlWrap]="form.get('template.name')">
      <input type="text" class="field" formControlName="name" name="name" autocomplete="none" role="presentation" required #nameInput />
      <span class="field__placeholder">{{ 'model.modelName' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid">{{ 'button.save' | translate }}</button>
  </div>
</form>
