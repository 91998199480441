import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSegment, UrlTree } from '@angular/router';

@Injectable()
export class UrlSerializerService {
  private defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    return this.defaultSerializer.parse(url ? url.replace('index.html', '') : url);
  }

  serialize(tree: UrlTree): string {
    return this.defaultSerializer.serialize(tree);
  }
}
