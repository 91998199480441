import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';

import { ModalService } from '@app/core/modal.service';
import { ModalPublicProperties, ModalResult, ManualBiometryApprovalEnum, VerificationDataModalComponent as ModalComponent } from './verification-data-modal.component';
import { NotyService } from '@app/services/noty.service';
import { DocumentService } from '@app/services/document.service';
import { TranslateService } from '@ngx-translate/core';

export { ManualBiometryApprovalEnum };

@Injectable({ providedIn: 'root' })
export class VerificationDataModalService {
  constructor(private modalService: ModalService, private notyService: NotyService, private documentService: DocumentService, private translateService: TranslateService) {}

  open(properties?: ModalPublicProperties, options?: NgbModalOptions) {
    const verification = this.documentService.getPendingManualVerification(properties?.signer);
    if (verification && !verification.user.images) {
      this.notyService.error(this.translateService.instant('notyService.verificationImagesNotProcessed'));
    }
    return verification && !verification.user.images ? of(null as ModalResult) : this.modalService.open<ModalResult>(ModalComponent, properties, options);
  }
}
