import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-menu-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  constructor(private router: Router, private location: Location) {}

  ngOnInit() {
    this.onResize();
  }

  @HostListener('window:resize') private onResize() {
    if (window.innerWidth > 991) {
      // this.router.navigate(['/documentos'])
      this.location.back();
    }
  }
}
