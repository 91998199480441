import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { I18nService } from '@app/core';
import { LoaderService } from '@app/shared';

interface GoogleFile {
  name: string;
  lastModified: number;
  data: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class GooglePickerService {
  constructor(private httpClient: HttpClient, private i18nService: I18nService, private ngZone: NgZone, private loaderService: LoaderService) {}

  open() {
    return new Observable<GoogleFile>(subscriber => {
      forkJoin([this.loadGoogleAuthorization(), this.loadGooglePicker()])
        .pipe(map(values => values[0]))
        .subscribe(oauthToken => {
          const view = new google.picker.DocsView();
          view.setIncludeFolders(true);
          // view.setMimeTypes('image/png,image/jpeg,image/jpg');
          const picker = new google.picker.PickerBuilder()
            .setAppId(environment.oauthGoogle.split('-')[0])
            .setOAuthToken(oauthToken)
            .setDeveloperKey(environment.googleDeveloperKey)
            .setLocale(this.i18nService.language)
            .addView(view)
            .addView(new google.picker.DocsView(google.picker.ViewId.RECENTLY_PICKED))
            .setCallback((response: any) => {
              if (response[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
                const document = response[google.picker.Response.DOCUMENTS][0];
                this.ngZone.run(() => this.loaderService.show());
                this.downloadBlob(document)
                  .pipe(
                    finalize(() => this.loaderService.hide()),
                    finalize(() => this.ngZone.run(() => {})) // Para atualizar o Angular na página
                  )
                  .subscribe(
                    data => {
                      subscriber.next({
                        name: document[google.picker.Document.NAME],
                        lastModified: document[google.picker.Document.LAST_EDITED_UTC],
                        data
                      } as GoogleFile);
                      subscriber.complete();
                    },
                    error => subscriber.error(error)
                  );
              }
            })
            .build();
          picker.setVisible(true);
        });
    });
  }

  private loadGoogleAuthorization() {
    return new Observable<string>(subscriber => {
      gapi.load('auth', () => {
        gapi.auth.authorize(
          {
            client_id: environment.oauthGoogle,
            scope: ['https://www.googleapis.com/auth/drive.readonly'],
            immediate: false
          },
          token => {
            if (token && !token.error) {
              subscriber.next(token.access_token);
              subscriber.complete();
            } else {
              subscriber.error(token.error);
            }
          }
        );
      });
    });
  }

  private loadGooglePicker() {
    return new Observable<undefined>(subscriber => {
      gapi.load('picker', () => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private downloadBlob(document: any) {
    let downloadUrl = 'https://www.googleapis.com/drive';
    if (document.mimeType === 'application/pdf' || !(document.mimeType || '').match('google-apps')) {
      downloadUrl += `/v2/files/${(document || {}).id}?alt=media`;
    } else {
      downloadUrl += `/v3/files/${(document || {}).id}/export?alt=media&mimeType=application/pdf`;
    }
    return this.httpClient.get(downloadUrl, { responseType: 'blob', headers: { Authorization: 'Bearer ' + gapi.auth.getToken().access_token } });
  }
}
