<label class="field__holder" [class.disabled]="disabled" ngbDropdown placement="bottom-left" #dropdown="ngbDropdown">
  <span *ngIf="selectedOption?.icon" [class]="'withIcon ' + selectedOption.icon"></span>
  <input
    *ngIf="!isLoading"
    type="text"
    [class]="'field ' + (inputValue ? '' : 'empty ') + inputClass + (selectedOption?.icon ? ' withIcon' : '')"
    [name]="name"
    [value]="inputValue"
    [disabled]="disabled"
    [required]="required"
    [readOnly]="disableSearch"
    (click)="onInputClick()"
    (change)="onInputChange()"
    (keyup)="onInputKey($event)"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    autocomplete="none"
    role="presentation"
    autocorrect="off"
    spellcheck="false"
    autocapitalize="off"
    ngbDropdownAnchor
    #selectElement
  />
  <input *ngIf="isLoading" class="animatePlaceholder-content field" style="height: 56px;" />
  <span *ngIf="!isLoading" class="field__placeholder">{{ placeholder }}</span>

  <div class="credits__content input" *ngIf="selectedOption?.credits" [ngbTooltip]="infoCreditsTooltip" container="body">
    <span>
      <i class="fal fa-shield-check"></i>
      {{ selectedOption.credits }}
    </span>
  </div>

  <div class="field__separator"></div>

  <div ngbDropdownMenu #dropdownMenu>
    <div *ngFor="let option of options || [] | filterBy: { value: selectedOption && selectedOption.key === value ? '' : inputValue }" style="position: relative" [class.d-none]="option.hidden">
      <button
        type="button"
        [ngbTooltip]="option.tooltip"
        container="body"
        placement="right"
        (mouseenter)="removeHoverClass()"
        (click)="onSelectOption(option)"
        (mousedown)="onSelectOption(option)"
        style="height:{{ option.height }}px; font-size:{{ option.fontSize }}px; max-width:{{ option.limitWidth }}px"
        ngbDropdownItem
      >
        <span *ngIf="option?.icon" [class]="option.icon"></span>
        <span [innerHTML]="option.value"></span>
      </button>
      <span class="credits__content" *ngIf="option.credits" [ngbTooltip]="infoCreditsTooltip" container="body"> <i class="fal fa-shield-check"></i> {{ option.credits }} </span>
    </div>
  </div>
</label>

<ng-template #infoCreditsTooltip>
  <div class="tooltip__title">{{ 'shared.additionalValidationCredit' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'shared.additionalValidationExplanation' | translate }}
  </div>
</ng-template>
