<div class="header" [ngClass]="{ headerDM: isDarkmode }">
  <div class="dots-container">
    <div class="dots dots-1"></div>
    <div class="dots dots-2"></div>
    <div class="dots dots-3"></div>
  </div>
  <div class="url-container" [ngClass]="{ 'urlDM-container': isDarkmode }">
    <p class="url"><span class="grey">https://</span>sign.acme.com/fy8Ik34hnbs0q9</p>
  </div>
  <div class="body">
    <div class="body-left" [ngClass]="{ 'bodyDM-left': isDarkmode }">
      <div>
        <div class="body-img" [ngClass]="{ 'bodyDM-img': isDarkmode }">
          <img [src]="selectedLogoImageUrl || logo || whitelabelService.logo" alt="" />
        </div>
      </div>
      <div class="body-signers" [ngClass]="{ 'bodyDM-signers': isDarkmode }">
        <div class="body-signers-card">
          <app-blured-signer-card [isDarkmode]="isDarkmode"></app-blured-signer-card>
        </div>

        <div class="body-signers-card">
          <app-blured-signer-card [isDarkmode]="isDarkmode"></app-blured-signer-card>
        </div>
      </div>
    </div>
    <div class="body-right">
      <div class="body-right-header">
        <div class="body-right-title">
          <p class="body-right-title-text" [ngClass]="{ 'bodyDM-right-title-text': isDarkmode }">{{ 'whitelabel.rentalAgreement' | translate }}</p>
          <p class="body-right-title-desc">{{ 'whitelabel.createdOnExample' | translate }}</p>
        </div>
        <div>
          <i class="fal fa-ellipsis-v"></i>
        </div>
      </div>
      <div class="body-right-document">
        <app-blured-document></app-blured-document>
        <div class="body-right-button">
          <button type="button" class="document-action" [style.background]="mainColor" [style.box-shadow]="'0 5px 25px ' + mainColor" [style.color]="appService.getContrastColor(mainColor)">
            {{ 'button.sign' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
