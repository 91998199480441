<div class="d-flex">
  <app-mobile-header-main (titleClicked)="openTaxonomyMenu = $event" mainTitle="Pastas da organização" menulink="/menu/pastas"></app-mobile-header-main>
  <div class="taxonomy-overlay" [class.taxonomy-overlay-active]="openTaxonomyMenu" (click)="openTaxonomyMenu = false"></div>
  <app-taxonomy
    *ngIf="whitelabelService.params.sidebar"
    mainTitle="{{ 'menu.folders' | translate }}"
    allowSearch
    [(isSearchOpen)]="isSearchOpen"
    (isSearchOpenChange)="toggleSearch($event)"
    [class.isMenuMobileOpen]="openTaxonomyMenu"
  >
    <app-documents-taxonomy
      [isSearchOpen]="isSearchOpen"
      [disableMainButton]="!currentUser?.currentPermissions.actions_folders_oz"
      [listPath]="'organization'"
      (newFolderButtonClick)="openCreateFolderModal()"
      (sortingChange)="loadFolders({ page: 1, search: searchQuery })"
    ></app-documents-taxonomy>
  </app-taxonomy>
</div>

<div class="d-flex flex-column w-100">
  <app-documents-search
    [isFoldersSearch]="true"
    [(search)]="searchQuery"
    (searchChange)="pageNumber = 1; loadFolders({ page: 1, search: $event })"
    (closeClick)="isSearchOpen = false; toggleSearch(isSearchOpen)"
    *ngIf="isSearchOpen"
    @slideDownUpTrigger
  ></app-documents-search>

  <div class="documents">
    <div class="documents__header justify-content-start">
      <ng-container *ngIf="!((isSearchOpen && searchQuery) || mobileSearch)">
        <h2 class="documents__title">
          {{ 'menu.organizationFoldersIn' | translate }} <span class="highlight" *ngIf="currentUser?.organization?.name">{{ currentUser?.organization?.name }}</span>
        </h2>
        <a class="documents__header-button" (click)="openCreateFolderModal()" *ngIf="currentUser?.currentPermissions.actions_folders_oz">
          {{ 'menu.newFolder' | translate }}
        </a>
      </ng-container>
      <h2 class="documents__title" *ngIf="(isSearchOpen && searchQuery) || mobileSearch">{{ 'documents.searchResults' | translate }}</h2>
      <app-search [(query)]="mobileSearch" (queryChange)="pageNumber = 1; loadFolders({ page: 1, search: { search: $event } })"></app-search>
    </div>

    <div class="documents-empty" *ngIf="!isLoading && folders && folders.length === 0 && !searchQuery && !mobileSearch; else foldersEmptyWithFilters">
      <div class="documents-empty__box">
        <p>{{ 'documents.noFoldersInOrganization' | translate }}</p>
        <button type="button" class="btn btn-block btn-primary" (click)="openCreateFolderModal()" *ngIf="currentUser?.currentPermissions.actions_folders_oz">
          {{ 'documents.createNewFolder' | translate }}
        </button>
      </div>
    </div>

    <ng-template #foldersEmptyWithFilters>
      <div class="documents-filtered-empty" *ngIf="!isLoading && folders && folders.length === 0 && (searchQuery || mobileSearch)">
        <div class="documents-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="documents-filtered-empty_text">
          <span>{{ 'documents.noDocumentsMatchingFilter' | translate }}</span>
        </div>
        <button type="button" class="default-button" (click)="clearFilters()">{{ 'documents.clearFilters' | translate }}</button>
      </div>
    </ng-template>

    <app-folders-list-boxes
      [folders]="folders"
      [linkPath]="['/pastas', ':id', 'organizacao']"
      [hidePagination]="isLoading || isLastPage"
      [hideDropdownMenu]="!currentUser?.currentPermissions.actions_folders_oz"
      (loadPageClick)="loadFolders({ page: pageNumber + 1, search: searchQuery })"
      (folderClick)="openFoldersModal($event)"
      (updateClick)="openUpdateFolderModal($event)"
      (deleteClick)="deleteFolder($event)"
    >
    </app-folders-list-boxes>
  </div>
</div>
