import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { CurrentUserDocument, OrganizationsDocument } from 'src/generated/graphql.default';

@Injectable({
  providedIn: 'root'
})
export class AppApolloService {
  constructor(private apollo: Apollo) {}

  getCurrentUserCache() {
    return this.apollo.getClient().readQuery({ query: CurrentUserDocument });
  }

  getOrganizationsCache() {
    return (this.apollo.getClient().readQuery({ query: OrganizationsDocument }) || {}).organizations;
  }

  forceUpdateCurrentUserCache(currentUserChanges: { me?: any; organization?: any; newHash?: string }) {
    const currentUser = this.getCurrentUserCache();
    if (currentUser && currentUser.me && currentUser.me.id) {
      this.apollo.getClient().writeQuery({
        query: CurrentUserDocument,
        data: {
          me: {
            ...(currentUser.me || {}),
            ...(currentUser.newHash ? { newHash: currentUser.newHash } : {}),
            ...(currentUserChanges.me || {}),
            ...(currentUserChanges.newHash ? { newHash: currentUserChanges.newHash } : {})
          },
          organization: currentUser.organization && currentUser.organization.id ? { ...(currentUser.organization || {}), ...(currentUserChanges.organization || {}) } : null
        }
      });
    }
  }

  forceOrganizationsCache(organizations: any[]) {
    this.apollo.getClient().writeQuery({
      query: OrganizationsDocument,
      data: { organizations: (organizations || []).length > 0 ? organizations : null }
    });
  }
}
