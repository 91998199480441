import { AfterContentInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { pick } from 'lodash';

import { AuthenticationService, DateService, ErrorHandlerService, NotyService, UserService, ValidationService } from '@app/services';
import { User } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@app/shared';
import { I18nService } from '@app/core';

export type ModalResult = void;
export interface ModalPublicProperties {
  currentUser: User;
  ignoreEmailAndPassword?: boolean;
  ignoreCpf?: boolean;
  forceCpfAs?: string;
}

export const userNameValidations = ['required', 'appValidName', 'appFullName', 'appForbiddenNames'];
export const userCpfValidations = ['required', 'appMatchCpf'];

@Component({
  selector: 'app-set-missing-user-info-modal',
  templateUrl: './set-missing-user-info-modal.component.html',
  styleUrls: ['./set-missing-user-info-modal.component.scss']
})
export class SetMissingUserInfoModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() currentUser: User;
  @Input() ignoreEmailAndPassword: boolean;
  @Input() ignoreCpf: boolean;
  @Input() forceCpfAs: string;
  form!: FormGroup;
  userForm: FormGroup;
  localeForm: FormGroup;
  isLoading = false;
  isVerifyingEmail = false;

  readonly countries: SelectOption[] = [];
  readonly languages: SelectOption[] = [];
  readonly timezones: SelectOption[] = [];
  readonly dateFormat: SelectOption[] = [];

  private normalAttributes = ['name', 'cpf', 'birthday'] as const;
  private emailAttributes = ['email', 'password'] as const;
  private localeAttributes = ['country', 'language', 'timezone', 'date_format'] as const;

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private validationService: ValidationService,
    private notyService: NotyService,
    private authenticationService: AuthenticationService,
    private dateService: DateService,
    private i18nService: I18nService
  ) {
    this.userForm = this.formBuilder.group({});
    this.localeForm = this.formBuilder.group({});
    this.form = this.formBuilder.group({ user: this.userForm, locale: this.localeForm });
    this.countries = this.i18nService.countriesWithCodes || null;
    this.languages = this.i18nService.supportedLanguagesAsSelectOptions || null;
    this.timezones = this.i18nService.timezonesWithGMT || null;
    this.dateFormat = this.i18nService.dateFormatOptions || null;
  }

  ngAfterContentInit() {
    if (
      this.normalAttributes.find(attr => !this.currentUser[attr]) ||
      !!this.validationService.validate(this.currentUser.name, userNameValidations) ||
      !!this.validationService.validate(this.currentUser.cpf, userCpfValidations, { appMatchCpf: [this.forceCpfAs] })
    ) {
      this.normalAttributes.forEach(attr => {
        let value = this.currentUser[attr];
        if (attr === 'birthday') value = this.dateService.normalizeDate(value); // Normaliza a data para o formato correto conforme o idioma selecionado
        this.userForm.addControl(attr, this.formBuilder.control(value || ''));
      });
    }
    if (!this.ignoreEmailAndPassword && !this.currentUser.email) {
      // Como não tem como saber se o usuário tem ou não senha, assume que se não tiver email, também não tem senha, pois é uma conta criada com celular.
      this.emailAttributes.forEach(attr => this.userForm.addControl(attr, this.formBuilder.control('')));
    }

    this.localeAttributes.forEach(attr => {
      let value = this.currentUser.locale[attr];
      if (attr === 'language') value = this.translateService.currentLang;
      if (attr === 'timezone') value = this.i18nService.timezone;
      this.localeForm.addControl(attr, this.formBuilder.control(value || ''));
    });

    setTimeout(() => {
      ['name', 'cpf'].forEach(attribute => {
        if (this.currentUser[attribute] && this.userForm.get(attribute) && this.userForm.get(attribute).value) {
          this.userForm.get(attribute).markAsTouched();
        } else if (this.userForm.get(attribute)) {
          this.userForm.get(attribute).markAsUntouched();
        }
      });

      if (document.querySelector('app-set-missing-user-info-modal input')) {
        (document.querySelector('app-set-missing-user-info-modal input') as HTMLInputElement).focus();
      }

      if (this.currentUser.email && !this.currentUser.email_verified_at) {
        this.isVerifyingEmail = true;
        this.sendEmailConfirmation();
      }
    });
  }

  update() {
    let normalAttrs = pick(this.userForm.value, this.normalAttributes);
    let localeAttrs = pick(this.localeForm.value, this.localeAttributes);
    let emailAttrs = pick(this.userForm.value, this.emailAttributes);
    if (normalAttrs.birthday) normalAttrs.birthday = this.dateService.normalizeDate(normalAttrs.birthday); // Normaliza a data para o formato DD/MM/YYYY, antes de enviar para o backend
    normalAttrs = Object.keys(normalAttrs).length > 0 ? normalAttrs : null;
    emailAttrs = Object.keys(emailAttrs).length > 0 ? emailAttrs : null;

    let request$: Observable<any>;
    if (emailAttrs) {
      request$ = this.userService.setCurrentUserMissingInfo({ user: emailAttrs }).pipe(
        tap(() => Object.keys(emailAttrs).forEach(attr => this.userForm.removeControl(attr))),
        switchMap(() => (normalAttrs ? this.userService.updateCurrentUser({ user: normalAttrs }) : of(null))),
        switchMap(() => (localeAttrs ? this.userService.updateCurrentUserLocale({ locale: localeAttrs }) : of(null)))
      );
    } else if (normalAttrs) {
      request$ = this.userService.updateCurrentUser({ user: normalAttrs }).pipe(switchMap(() => (localeAttrs ? this.userService.updateCurrentUserLocale({ locale: localeAttrs }) : of(null))));
    } else {
      request$ = this.userService.updateCurrentUserLocale({ locale: localeAttrs });
    }

    this.isLoading = true;
    request$.pipe(finalize(() => (this.isLoading = false))).subscribe(
      () => {
        if (emailAttrs) {
          this.isVerifyingEmail = true;
          this.currentUser.email = emailAttrs.email;
          this.sendEmailConfirmation();
        } else {
          this.modal.close();
        }
      },
      error => this.errorHandlerService.handleValidation(this.form, error)
    );
  }

  sendEmailConfirmation() {
    this.isLoading = true;
    this.authenticationService
      .sendEmailConfirmation(this.currentUser.email)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => this.notyService.success(this.translateService.instant('notyService.confirmationEmailResent')),
        error => this.errorHandlerService.handle(error)
      );
  }
}
