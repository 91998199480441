import { Component, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from '@app/core';
import { ContextEnum, User } from '@app/models';
import { UserService } from '@app/services';
import { FoldersListingService } from '@app/pages/documents/shared/folders-listing.service';

@Component({
  selector: 'app-menu-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit, OnDestroy {
  currentUser: User;

  constructor(private userService: UserService, private foldersListingService: FoldersListingService) {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnInit() {}

  ngOnDestroy() {}

  openCreateFolderModal() {
    this.foldersListingService.openCreateFolderModal(ContextEnum.User).subscribe();
  }
}
