import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** UUID is represented as a string type identifier */
  UUID: string;
  /** A UNIX timestamp represented as an integer */
  Timestamp: string;
  EmailScalar: any;
  /** CPF is represented as a string type in the format 000.000.000-00 */
  CPF: string;
  /** CNPJ is represented as a string type in the format 00.000.000/0000-00 */
  CNPJ: string;
  /** Date is represented as a string type in the format dd/mm/yyyy */
  Date: string;
  PhoneScalar: any;
  JSONScalar: any;
  ArrayScalar: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
}



export type AcceptSignatureUnion = HashToSign | Signature;

export interface Action {
  name: ActionEnum;
}

export enum ActionEnum {
  Sign = 'SIGN',
  Approve = 'APPROVE',
  Recognize = 'RECOGNIZE',
  SignAsPart = 'SIGN_AS_PART',
  SignAsAWitness = 'SIGN_AS_A_WITNESS',
  SignAsIntervener = 'SIGN_AS_INTERVENER',
  AcknowledgeReceipt = 'ACKNOWLEDGE_RECEIPT',
  EndorseInBlack = 'ENDORSE_IN_BLACK',
  EndorseInWhite = 'ENDORSE_IN_WHITE'
}

export interface AddressInput {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  ibge_code?: Maybe<Scalars['String']>;
}

export interface Affiliate {
  id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  referral_code?: Maybe<Scalars['String']>;
  referred_to?: Maybe<Scalars['String']>;
  is_receiving_payments?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  referral_configuration?: Maybe<Scalars['String']>;
  payment_info?: Maybe<Scalars['String']>;
}

export interface ApiOptionsInput {
  webhook_url?: Maybe<Scalars['String']>;
  webhook_format: WebhookFormatEnum;
}


export interface Attribute {
  id: Scalars['UUID'];
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
}

export interface AttributeInput {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  type: Scalars['String'];
  required: Scalars['Boolean'];
}

export interface Author {
  id: Scalars['UUID'];
  organization_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  company?: Maybe<Scalars['String']>;
}

export interface AuthType {
  access_token: Scalars['String'];
  token_type: Scalars['String'];
  expires_in: Scalars['Int'];
}

export interface CardData {
  last_digits?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
}

export interface ChildOrganization {
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['CNPJ']>;
  default_email_templates_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  settings?: Maybe<Setting>;
  groups?: Maybe<Array<Maybe<Group>>>;
  created_at?: Maybe<Scalars['Timestamp']>;
}

export interface ChildOrganizationInput {
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  webhook_url?: Maybe<Scalars['String']>;
  default_email_templates_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export enum CloudSignaturesEnum {
  Safeid = 'SAFEID',
  Vidaas = 'VIDAAS'
}


export interface Contact {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
}

export interface ContactInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** Your datetime should be in ISO-8601 format */
  birthday?: Maybe<Scalars['String']>;
}

export enum ContextEnum {
  User = 'USER',
  Organization = 'ORGANIZATION',
  Group = 'GROUP'
}


export interface CreditPackType {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  best?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amount_promo?: Maybe<Scalars['Float']>;
  is_promo?: Maybe<Scalars['Boolean']>;
}

export interface CreditType {
  value?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
}

export interface Customer {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['CPF']>;
  cnpj?: Maybe<Scalars['CNPJ']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  ibge_code?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  iugu_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  updated_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
}

export interface CustomerInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['CPF']>;
  cnpj?: Maybe<Scalars['CNPJ']>;
}

export interface DataSource {
  name?: Maybe<Scalars['String']>;
  voucher?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
}

export enum DataSourceEnum {
  Cpf = 'CPF',
  Cnpj = 'CNPJ'
}


export enum DateFormatEnum {
  DdMmYyyy = 'DD_MM_YYYY',
  MmDdYyyy = 'MM_DD_YYYY'
}

export interface DefaultEmailTemplates {
  ptBR?: Maybe<EmailTemplate>;
  enUS?: Maybe<EmailTemplate>;
}

export enum DeliveryMethodEnum {
  DeliveryMethodEmail = 'DELIVERY_METHOD_EMAIL',
  DeliveryMethodLink = 'DELIVERY_METHOD_LINK',
  DeliveryMethodSms = 'DELIVERY_METHOD_SMS',
  DeliveryMethodWhatsapp = 'DELIVERY_METHOD_WHATSAPP'
}

export interface Document {
  id: Scalars['UUID'];
  name: Scalars['String'];
  refusable?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  qualified?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  is_blocked?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  signers_history?: Maybe<Array<Maybe<SignerHistory>>>;
  locale?: Maybe<Locale>;
  created_at: Scalars['Timestamp'];
  deleted_at?: Maybe<Scalars['Timestamp']>;
  deadline_at?: Maybe<Scalars['Timestamp']>;
  lifecycle_in?: Maybe<Scalars['Timestamp']>;
  email_template_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['Timestamp'];
  processed_at?: Maybe<Scalars['Timestamp']>;
  expiration_at?: Maybe<Scalars['Timestamp']>;
  notify_in?: Maybe<Scalars['Int']>;
  reminder?: Maybe<ReminderEnum>;
  message?: Maybe<Scalars['String']>;
  footer?: Maybe<FooterEnum>;
  cc?: Maybe<Array<Maybe<Scalars['EmailScalar']>>>;
  reply_to?: Maybe<Scalars['EmailScalar']>;
  configs?: Maybe<DocumentConfig>;
  signatures_count?: Maybe<Scalars['Int']>;
  signed_count?: Maybe<Scalars['Int']>;
  rejected_count?: Maybe<Scalars['Int']>;
  signatures?: Maybe<Array<Maybe<Signature>>>;
  author: Author;
  files?: Maybe<File>;
  hashes?: Maybe<Hash>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
}

export interface DocumentConfig {
  notification_finished?: Maybe<Scalars['Boolean']>;
  notification_signed?: Maybe<Scalars['Boolean']>;
  signature_appearance?: Maybe<SignatureAppearanceEnum>;
  reply_to?: Maybe<Scalars['EmailScalar']>;
  keep_metadata?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}

export interface DocumentConfigInput {
  notification_finished?: Maybe<Scalars['Boolean']>;
  notification_signed?: Maybe<Scalars['Boolean']>;
  signature_appearance?: Maybe<SignatureAppearanceEnum>;
  keep_metadata?: Maybe<Scalars['Boolean']>;
}

export interface DocumentInput {
  name: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  refusable?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  qualified?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  reminder?: Maybe<ReminderEnum>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
  /** Your datetime should be in ISO-8601 format */
  deadline_at?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['Int']>;
  expiration?: Maybe<ExpirationInput>;
  footer?: Maybe<FooterEnum>;
  cc?: Maybe<Array<Maybe<EmailInput>>>;
  reply_to?: Maybe<Scalars['EmailScalar']>;
  locale?: Maybe<LocaleInput>;
  configs?: Maybe<DocumentConfigInput>;
}

export enum DocumentReportColumnEnum {
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  ExpirationAt = 'EXPIRATION_AT',
  Signatures = 'SIGNATURES',
  Status = 'STATUS',
  Sha = 'SHA',
  Author = 'AUTHOR',
  Link = 'LINK'
}

export interface DocumentSignature {
  document_id: Scalars['UUID'];
  signature_id: Scalars['UUID'];
  signature_public_id: Scalars['UUID'];
  action?: Maybe<Action>;
}

export enum DocumentStatusEnum {
  Pending = 'PENDING',
  Signed = 'SIGNED',
  NotSigned = 'NOT_SIGNED',
  Deleted = 'DELETED'
}

export interface EmailEvent {
  id?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['String']>;
  refused?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  author?: Maybe<User>;
  type?: Maybe<EmailTypeEnum>;
}

export interface EmailInput {
  email: Scalars['String'];
}


export interface EmailTemplate {
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
}

export interface EmailTemplates {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateTypeEnum>;
  /** @deprecated Use attributes referring to the specific language instead. */
  email?: Maybe<EmailTemplate>;
  ptBR?: Maybe<EmailTemplate>;
  enUS?: Maybe<EmailTemplate>;
}

export interface EmailTemplatesContentInput {
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  template?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  footer?: Maybe<Scalars['String']>;
}

export interface EmailTemplatesInput {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateTypeEnum>;
  ptBR?: Maybe<EmailTemplatesContentInput>;
  enUS?: Maybe<EmailTemplatesContentInput>;
}

export enum EmailTemplateTypeEnum {
  Solicitation = 'SOLICITATION',
  Completed = 'COMPLETED',
  SignatureCompleted = 'SIGNATURE_COMPLETED'
}

export interface EmailType {
  email?: Maybe<Scalars['String']>;
  has_password?: Maybe<Scalars['Boolean']>;
  email_verified_at?: Maybe<Scalars['Timestamp']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
}

export enum EmailTypeEnum {
  Solicitation = 'SOLICITATION',
  Reminder = 'REMINDER',
  Resend = 'RESEND'
}

export interface EnterpriseMember {
  id: Scalars['Int'];
  user?: Maybe<User>;
  group?: Maybe<Group>;
  permissions?: Maybe<Permission>;
  api_token?: Maybe<Token>;
  created_at?: Maybe<Scalars['Timestamp']>;
}

export interface EnterpriseMemberInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  birthday?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<PermissionInput>;
  locale?: Maybe<LocaleInput>;
}

export interface Escavador {
  body?: Maybe<Scalars['String']>;
}

export interface Event {
  user?: Maybe<User>;
  ip?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  /** @deprecated Use ip intead. */
  ipv4?: Maybe<Scalars['String']>;
  /** @deprecated Use ip intead. */
  ipv6?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Geolocation>;
  /** Autentique's Privacy */
  user_agent?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Timestamp']>;
}

export interface ExpirationInput {
  days_before: Scalars['Int'];
  notify_at: Scalars['Date'];
}

export interface File {
  original?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['String']>;
  certified?: Maybe<Scalars['String']>;
  pades?: Maybe<Scalars['String']>;
}

export interface Folder {
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  /** @deprecated Deprecated due to use of context field. */
  type: FolderTypeEnum;
  context: ContextEnum;
  parentFolder?: Maybe<Folder>;
  path?: Maybe<Scalars['String']>;
  subfolders?: Maybe<Array<Maybe<Folder>>>;
  children_counter?: Maybe<Scalars['Int']>;
  created_at: Scalars['Timestamp'];
  updated_at: Scalars['Timestamp'];
}

export interface FolderInput {
  name: Scalars['String'];
}

export enum FolderTypeEnum {
  Default = 'DEFAULT',
  Organization = 'ORGANIZATION',
  Group = 'GROUP'
}

export enum FontEnum {
  Autograf = 'AUTOGRAF',
  Fathur = 'FATHUR',
  Robertson = 'ROBERTSON'
}

export enum FooterEnum {
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export interface Geolocation {
  country?: Maybe<Scalars['String']>;
  countryISO?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateISO?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
}

export interface Group {
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['CNPJ']>;
  overwrite_template_group?: Maybe<Scalars['Boolean']>;
  overwrite_template?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use email_templates instead. */
  email_template?: Maybe<EmailTemplates>;
  email_templates?: Maybe<Array<Maybe<EmailTemplates>>>;
  permissions?: Maybe<Permission>;
  styles?: Maybe<GroupStyle>;
  /** Autentique's Privacy */
  members?: Maybe<Array<Maybe<Member>>>;
  members_count?: Maybe<Scalars['Int']>;
  is_default?: Maybe<Scalars['Boolean']>;
}

export interface GroupInput {
  name?: Maybe<Scalars['String']>;
  overwrite_template_group?: Maybe<Scalars['Boolean']>;
  overwrite_template?: Maybe<Scalars['Boolean']>;
}

export interface GroupStyle {
  overwrite_email?: Maybe<Scalars['Boolean']>;
  overwrite_name?: Maybe<Scalars['Boolean']>;
}

export interface GroupStyleInput {
  company?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  overwrite_email?: Maybe<Scalars['Boolean']>;
  email_templates_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  overwrite_name?: Maybe<Scalars['Boolean']>;
}

export interface Hash {
  md5?: Maybe<Scalars['String']>;
  sha1?: Maybe<Scalars['String']>;
  sha2?: Maybe<Scalars['String']>;
}

export interface HashToSign {
  hash: Scalars['String'];
}

export interface Invitation {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  group?: Maybe<Group>;
  permissions?: Maybe<Permission>;
}

export interface Invoice {
  id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['Timestamp']>;
  price?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
  url_payment?: Maybe<Scalars['String']>;
  url_nfse?: Maybe<Scalars['String']>;
}

export interface InvoicePagination {
  /** List of Invoices on the current page */
  data?: Maybe<Array<Maybe<Invoice>>>;
  /** Number of total Invoices selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Invoices returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Invoices */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Invoices returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Invoice returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export interface IuguCard {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  data?: Maybe<IuguCardData>;
}

export interface IuguCardData {
  brand?: Maybe<Scalars['String']>;
  holder_name?: Maybe<Scalars['String']>;
  display_number?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  last_digits?: Maybe<Scalars['String']>;
  first_digits?: Maybe<Scalars['String']>;
  masked_number?: Maybe<Scalars['String']>;
}

export interface IuguCardInput {
  set_as_default: Scalars['Boolean'];
  token: Scalars['String'];
  description: Scalars['String'];
}

export interface IuguCharge {
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  bank_slip?: Maybe<IuguChargeBankSlip>;
  credit_card?: Maybe<IuguChargeCreditCard>;
  pix?: Maybe<IuguChargePix>;
  errors?: Maybe<Scalars['String']>;
}

export interface IuguChargeBankSlip {
  digitable_line?: Maybe<Scalars['String']>;
  barcode_data?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  bank_slip_bank?: Maybe<Scalars['Int']>;
  bank_slip_error_code?: Maybe<Scalars['String']>;
  bank_slip_error_message?: Maybe<Scalars['String']>;
}

export interface IuguChargeCreditCard {
  success?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  info_message?: Maybe<Scalars['String']>;
}

export interface IuguChargePix {
  qrcode?: Maybe<Scalars['String']>;
  qrcode_text?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payer_name?: Maybe<Scalars['String']>;
  payer_cpf_cnpj?: Maybe<Scalars['Int']>;
}

export interface IuguCustomer {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  updated_at?: Maybe<Scalars['Timestamp']>;
  cc_emails?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_prefix?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  default_payment?: Maybe<IuguCard>;
}

export interface IuguInvoice {
  id?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  total_paid?: Maybe<Scalars['String']>;
  total_cents?: Maybe<Scalars['Int']>;
  total_paid_cents?: Maybe<Scalars['Int']>;
  paid_at?: Maybe<Scalars['Timestamp']>;
  payable_with?: Maybe<IuguPaymentEnum>;
  secure_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  updated_at?: Maybe<Scalars['Timestamp']>;
}

export interface IuguOrder {
  active?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['Boolean']>;
  cycled_at?: Maybe<Scalars['Timestamp']>;
  expires_at?: Maybe<Scalars['Timestamp']>;
  payable_with?: Maybe<IuguPaymentEnum>;
  plan_identifier?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
  price_cents?: Maybe<Scalars['Int']>;
  recent_invoices?: Maybe<Scalars['JSONScalar']>;
  subitems?: Maybe<Array<Maybe<Scalars['JSONScalar']>>>;
}

export interface IuguOrderInput {
  plan: Scalars['String'];
  payable: IuguPaymentEnum;
}

export enum IuguPaymentEnum {
  CreditCard = 'CREDIT_CARD',
  BankSlip = 'BANK_SLIP',
  Pix = 'PIX',
  All = 'ALL'
}

export interface IuguPlan {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
}


export interface Link {
  id: Scalars['String'];
  short_link?: Maybe<Scalars['String']>;
}

export interface Locale {
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}

export interface LocaleInput {
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}

export interface Member {
  /** @deprecated Deprecated in this version. */
  id: Scalars['String'];
  /** @deprecated Deprecated in this version. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in this version. */
  email?: Maybe<Scalars['EmailScalar']>;
  /** @deprecated Deprecated in this version. */
  role?: Maybe<Role>;
  user?: Maybe<User>;
  /** Autentique's Privacy */
  organization?: Maybe<Organization>;
  group?: Maybe<Group>;
  permissions?: Maybe<Permission>;
  created_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
}

export interface MemberInput {
  email?: Maybe<Scalars['EmailScalar']>;
  group_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<PermissionInput>;
}

export interface Mutation {
  viewSignature?: Maybe<PublicSignature>;
  rejectSignature?: Maybe<PublicSignature>;
  downloadSignedDocument?: Maybe<Storage>;
  createInvoicePdfLink?: Maybe<Storage>;
}


export interface MutationViewSignatureArgs {
  id: Scalars['UUID'];
}


export interface MutationRejectSignatureArgs {
  id: Scalars['UUID'];
  type: TypeEnum;
  reason?: Maybe<Scalars['String']>;
}


export interface MutationDownloadSignedDocumentArgs {
  id: Scalars['UUID'];
}


export interface MutationCreateInvoicePdfLinkArgs {
  id: Scalars['UUID'];
}

export interface Notification {
  signature_you: Scalars['Boolean'];
  signature_others: Scalars['Boolean'];
}

export interface NotificationInput {
  signature_you: Scalars['Boolean'];
  signature_others: Scalars['Boolean'];
}

export interface Order {
  plan_id?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['Boolean']>;
  payment_method?: Maybe<PaymentMethodsEnum>;
  card_data?: Maybe<CardData>;
  subitems?: Maybe<Array<Maybe<OrderSubItem>>>;
  latest_invoice?: Maybe<Invoice>;
  cycled_at?: Maybe<Scalars['Timestamp']>;
  expires_at?: Maybe<Scalars['Timestamp']>;
}

export interface OrderBy {
  field: Scalars['String'];
  direction: OrderByEnum;
}

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface OrderSubItem {
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
}

export interface Organization {
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['CNPJ']>;
  /** @deprecated Deprecated after stripe integration. */
  customer_id?: Maybe<Scalars['String']>;
  settings?: Maybe<Setting>;
  /** @deprecated Deprecated in this version. */
  overwrite_email: Scalars['Boolean'];
  /** @deprecated Deprecated in this version. */
  overwrite_template: Scalars['Boolean'];
  /** @deprecated Deprecated in this version. */
  overwrite_name: Scalars['Boolean'];
  /**
   * Autentique's Privacy
   * @deprecated Deprecated in this version.
   */
  members?: Maybe<Array<Maybe<Member>>>;
  /** Autentique's Privacy */
  groups?: Maybe<Array<Maybe<Group>>>;
  subscription?: Maybe<Subscription>;
  billing_customer?: Maybe<Customer>;
  created_at?: Maybe<Scalars['Timestamp']>;
}

export interface OrganizationInput {
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
}

export enum PadesActionEnum {
  Start = 'START',
  Complete = 'COMPLETE'
}

export interface PasswordInput {
  current?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['String']>;
  new_confirmation?: Maybe<Scalars['String']>;
}

export enum PaymentMethodsEnum {
  CreditCard = 'CREDIT_CARD',
  Boleto = 'BOLETO',
  Pix = 'PIX'
}

export interface Permission {
  overwrite_permissions?: Maybe<Scalars['Boolean']>;
  create_documents?: Maybe<Scalars['Boolean']>;
  sign_documents?: Maybe<Scalars['Boolean']>;
  delete_documents?: Maybe<Scalars['Boolean']>;
  archive_documents?: Maybe<Scalars['Boolean']>;
  view_documents_gr?: Maybe<Scalars['Boolean']>;
  view_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_documents_gr?: Maybe<Scalars['Boolean']>;
  actions_templates_gr?: Maybe<Scalars['Boolean']>;
  actions_members_oz?: Maybe<Scalars['Boolean']>;
  actions_groups_oz?: Maybe<Scalars['Boolean']>;
  actions_webhooks_oz?: Maybe<Scalars['Boolean']>;
  view_documents_oz?: Maybe<Scalars['Boolean']>;
  view_member_documents_oz?: Maybe<Scalars['Boolean']>;
  view_group_documents_oz?: Maybe<Scalars['Boolean']>;
  view_folders_oz?: Maybe<Scalars['Boolean']>;
  view_member_folders_oz?: Maybe<Scalars['Boolean']>;
  view_group_folders_oz?: Maybe<Scalars['Boolean']>;
  actions_documents_oz?: Maybe<Scalars['Boolean']>;
  view_invoices_oz?: Maybe<Scalars['Boolean']>;
  change_plan_oz?: Maybe<Scalars['Boolean']>;
  actions_folders_oz?: Maybe<Scalars['Boolean']>;
  change_appearances_oz?: Maybe<Scalars['Boolean']>;
  change_whitelabel_oz?: Maybe<Scalars['Boolean']>;
  enterprise_access?: Maybe<Scalars['Boolean']>;
}

export interface PermissionInput {
  overwrite_permissions?: Maybe<Scalars['Boolean']>;
  create_documents?: Maybe<Scalars['Boolean']>;
  sign_documents?: Maybe<Scalars['Boolean']>;
  delete_documents?: Maybe<Scalars['Boolean']>;
  archive_documents?: Maybe<Scalars['Boolean']>;
  view_documents_gr?: Maybe<Scalars['Boolean']>;
  view_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_documents_gr?: Maybe<Scalars['Boolean']>;
  actions_templates_gr?: Maybe<Scalars['Boolean']>;
  actions_members_oz?: Maybe<Scalars['Boolean']>;
  actions_groups_oz?: Maybe<Scalars['Boolean']>;
  actions_webhooks_oz?: Maybe<Scalars['Boolean']>;
  view_documents_oz?: Maybe<Scalars['Boolean']>;
  view_member_documents_oz?: Maybe<Scalars['Boolean']>;
  view_group_documents_oz?: Maybe<Scalars['Boolean']>;
  view_folders_oz?: Maybe<Scalars['Boolean']>;
  view_member_folders_oz?: Maybe<Scalars['Boolean']>;
  view_group_folders_oz?: Maybe<Scalars['Boolean']>;
  actions_documents_oz?: Maybe<Scalars['Boolean']>;
  view_invoices_oz?: Maybe<Scalars['Boolean']>;
  change_plan_oz?: Maybe<Scalars['Boolean']>;
  actions_folders_oz?: Maybe<Scalars['Boolean']>;
  change_appearances_oz?: Maybe<Scalars['Boolean']>;
}


export interface Plan {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  iugu_id?: Maybe<Scalars['String']>;
}

export enum PositionElementEnum {
  Signature = 'SIGNATURE',
  Name = 'NAME',
  Initials = 'INITIALS',
  Date = 'DATE',
  Cpf = 'CPF'
}

export interface PositionInput {
  x: Scalars['String'];
  y: Scalars['String'];
  z: Scalars['Int'];
  angle?: Maybe<Scalars['Float']>;
  element?: Maybe<PositionElementEnum>;
}

export interface Provider {
  name: Scalars['String'];
}

export interface PublicDocument {
  id: Scalars['UUID'];
  name: Scalars['String'];
  refusable?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  qualified?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  is_blocked?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  signers_history?: Maybe<Array<Maybe<SignerHistory>>>;
  created_at: Scalars['Timestamp'];
  deadline_at?: Maybe<Scalars['Timestamp']>;
  lifecycle_in?: Maybe<Scalars['Timestamp']>;
  email_template_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['Timestamp'];
  expiration_at?: Maybe<Scalars['Timestamp']>;
  notify_in?: Maybe<Scalars['Int']>;
  reminder?: Maybe<ReminderEnum>;
  footer?: Maybe<FooterEnum>;
  configs?: Maybe<DocumentConfig>;
  signatures?: Maybe<Array<Maybe<PublicSignature>>>;
  author: Author;
  files?: Maybe<File>;
  hashes?: Maybe<Hash>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Locale>;
}

export interface PublicSignature {
  public_id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  delivery_method?: Maybe<DeliveryMethodEnum>;
  email_events?: Maybe<EmailEvent>;
  email_history?: Maybe<Array<Maybe<EmailEvent>>>;
  signature_history?: Maybe<SignatureHistory>;
  positions?: Maybe<Array<Maybe<SignaturePosition>>>;
  configs?: Maybe<SignatureConfig>;
  created_at?: Maybe<Scalars['Timestamp']>;
  updated_at?: Maybe<Scalars['Timestamp']>;
  archived_at?: Maybe<Scalars['Timestamp']>;
  certificate?: Maybe<SignedCertificate>;
  viewed?: Maybe<Event>;
  signed?: Maybe<Event>;
  rejected?: Maybe<Event>;
  signed_unapproved?: Maybe<Event>;
  biometric_approved?: Maybe<Event>;
  biometric_rejected?: Maybe<Event>;
  parent?: Maybe<Scalars['String']>;
  action?: Maybe<Action>;
  document: PublicDocument;
  user?: Maybe<PublicUser>;
  verifications?: Maybe<Array<Maybe<SecurityVerificationType>>>;
}

export interface PublicUser {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['CPF']>;
  company?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  escavador?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  member?: Maybe<Member>;
  created_at: Scalars['Timestamp'];
  /** @deprecated Deprecated in this version. */
  auth_methods?: Maybe<Array<Maybe<Provider>>>;
  settings?: Maybe<Setting>;
  /** @deprecated Deprecated in this version. */
  group?: Maybe<Group>;
}

export interface Query {
  document?: Maybe<PublicDocument>;
  signature: PublicSignature;
  signatures?: Maybe<Array<Maybe<DocumentSignature>>>;
  signatureByLink?: Maybe<PublicSignature>;
  signatureBySecurityVerificationCode?: Maybe<Scalars['String']>;
  userExist?: Maybe<Scalars['Boolean']>;
  organizationInvoices?: Maybe<InvoicePagination>;
  documentEmailHistory?: Maybe<PublicDocument>;
  whitelabelByDomain?: Maybe<Whitelabel>;
  whitelabelByDocument?: Maybe<Whitelabel>;
}


export interface QueryDocumentArgs {
  id?: Maybe<Scalars['UUID']>;
  public_id?: Maybe<Scalars['UUID']>;
}


export interface QuerySignatureArgs {
  id: Scalars['UUID'];
}


export interface QuerySignaturesArgs {
  id: Scalars['UUID'];
}


export interface QuerySignatureByLinkArgs {
  id: Scalars['UUID'];
}


export interface QuerySignatureBySecurityVerificationCodeArgs {
  code?: Maybe<Scalars['String']>;
}


export interface QueryUserExistArgs {
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['PhoneScalar']>;
}


export interface QueryOrganizationInvoicesArgs {
  organization_uuid?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}


export interface QueryDocumentEmailHistoryArgs {
  id: Scalars['UUID'];
}


export interface QueryWhitelabelByDomainArgs {
  domain?: Maybe<Scalars['String']>;
}


export interface QueryWhitelabelByDocumentArgs {
  document_id: Scalars['UUID'];
}

export interface Referral {
  id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  referral_code?: Maybe<Scalars['String']>;
  referral_to?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['Float']>;
  is_receiving_payments?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  affiliates?: Maybe<Array<Maybe<Affiliate>>>;
  referral_configuration?: Maybe<Scalars['String']>;
  payment_info?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['String']>;
  recent_extracts?: Maybe<Scalars['String']>;
}

export enum ReminderEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export interface Role {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export enum RoleEnum {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum SecurityVerificationEnum {
  Sms = 'SMS',
  Upload = 'UPLOAD',
  Live = 'LIVE',
  PfFacial = 'PF_FACIAL',
  Manual = 'MANUAL'
}

export enum SecurityVerificationFileTypeEnum {
  Selfie = 'SELFIE',
  Front = 'FRONT',
  Back = 'BACK'
}

export interface SecurityVerificationInput {
  type?: Maybe<SecurityVerificationEnum>;
  verify_phone?: Maybe<Scalars['PhoneScalar']>;
}

export interface SecurityVerificationPayloadType {
  url?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Timestamp']>;
  failcode?: Maybe<Scalars['Int']>;
}

export interface SecurityVerificationType {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<SecurityVerificationEnum>;
  verify_phone?: Maybe<Scalars['String']>;
  payload?: Maybe<SecurityVerificationPayloadType>;
  user?: Maybe<SecurityVerificationUserType>;
  verified_at?: Maybe<Scalars['Timestamp']>;
  max_attempt?: Maybe<Scalars['Int']>;
  logs_attempt?: Maybe<Scalars['ArrayScalar']>;
}

export interface SecurityVerificationUserType {
  images?: Maybe<Scalars['JSONScalar']>;
  confidence?: Maybe<Scalars['Int']>;
}

export interface SessionType {
  ip_address?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Timestamp']>;
}

export interface Setting {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<EmailTemplate>;
  format?: Maybe<Scalars['String']>;
  webhook_url?: Maybe<Scalars['String']>;
  webhook_format?: Maybe<WebhookFormatEnum>;
  signature?: Maybe<Scalars['String']>;
  signature_draw?: Maybe<Scalars['String']>;
  initials_format?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  initials_draw?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
  signature_text?: Maybe<Scalars['String']>;
  has_signature_appearance?: Maybe<Scalars['Boolean']>;
  has_initials_appearance?: Maybe<Scalars['Boolean']>;
}

export interface Signature {
  public_id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  delivery_method?: Maybe<DeliveryMethodEnum>;
  email_events?: Maybe<EmailEvent>;
  email_history?: Maybe<Array<Maybe<EmailEvent>>>;
  positions?: Maybe<Array<Maybe<SignaturePosition>>>;
  configs?: Maybe<SignatureConfig>;
  organization_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  updated_at?: Maybe<Scalars['Timestamp']>;
  archived_at?: Maybe<Scalars['Timestamp']>;
  action?: Maybe<Action>;
  link?: Maybe<Link>;
  certificate?: Maybe<SignedCertificate>;
  viewed?: Maybe<Event>;
  signed?: Maybe<Event>;
  rejected?: Maybe<Event>;
  signed_unapproved?: Maybe<Event>;
  biometric_approved?: Maybe<Event>;
  biometric_rejected?: Maybe<Event>;
  user?: Maybe<User>;
  parent?: Maybe<Scalars['String']>;
  verifications?: Maybe<Array<Maybe<SecurityVerificationType>>>;
  /** Autentique's Privacy */
  folder?: Maybe<Folder>;
  /** Autentique's Privacy */
  organization?: Maybe<Organization>;
}

export enum SignatureAppearanceEnum {
  Draw = 'DRAW',
  Handwriting = 'HANDWRITING',
  Eletronic = 'ELETRONIC',
  Image = 'IMAGE'
}

export interface SignatureConfig {
  overwrite_name?: Maybe<Scalars['String']>;
  overwrite_date?: Maybe<Scalars['Date']>;
  cpf?: Maybe<Scalars['String']>;
}

export interface SignatureConfigInput {
  overwrite_name?: Maybe<Scalars['String']>;
  overwrite_date?: Maybe<Scalars['String']>;
}

export interface SignatureHistory {
  type?: Maybe<DeliveryMethodEnum>;
  sent?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['String']>;
  refused?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  email_type?: Maybe<EmailTypeEnum>;
  phone?: Maybe<Scalars['PhoneScalar']>;
}

export interface SignatureInput {
  email?: Maybe<Scalars['EmailScalar']>;
  positions?: Maybe<Array<Maybe<PositionInput>>>;
  configs?: Maybe<SignatureConfigInput>;
}

export interface SignaturePosition {
  x: Scalars['String'];
  y: Scalars['String'];
  z: Scalars['Int'];
  angle?: Maybe<Scalars['Float']>;
  element?: Maybe<PositionElementEnum>;
}

export enum SignatureTypesEnum {
  A1 = 'A1',
  A3 = 'A3',
  Safeid = 'SAFEID',
  Vidaas = 'VIDAAS'
}

export interface SignedCertificate {
  type?: Maybe<SignatureTypesEnum>;
  name?: Maybe<Scalars['String']>;
  /** Document number (TAX ID) */
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
}

export interface SignerConfigInput {
  cpf?: Maybe<Scalars['String']>;
}

export interface SignerHistory {
  created_at?: Maybe<Scalars['Timestamp']>;
  user?: Maybe<PublicSignature>;
  author?: Maybe<PublicSignature>;
  category?: Maybe<SignerHistoryEnum>;
}

export enum SignerHistoryEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  GenerateLink = 'GENERATE_LINK'
}

export interface SignerInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  delivery_method?: Maybe<DeliveryMethodEnum>;
  positions?: Maybe<Array<Maybe<PositionInput>>>;
  action: ActionEnum;
  configs?: Maybe<SignerConfigInput>;
  security_verifications?: Maybe<Array<Maybe<SecurityVerificationInput>>>;
}

export interface SocialType {
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
}

export interface SpamIncident {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  action?: Maybe<SpamIncidentActionEnum>;
  signers_today?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['Timestamp']>;
}

export enum SpamIncidentActionEnum {
  Pending = 'PENDING',
  Ignore = 'IGNORE',
  AllowAll = 'ALLOW_ALL',
  BlockUser = 'BLOCK_USER'
}

export interface Statistic {
  documents: Scalars['Int'];
  sent: Scalars['Int'];
  signatures: Scalars['Int'];
}

export interface Storage {
  path: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
}

export interface Subscription {
  name?: Maybe<Scalars['String']>;
  documents: Scalars['Int'];
  credits?: Maybe<Scalars['Int']>;
  bonus: Scalars['Int'];
  has_premium_features: Scalars['Boolean'];
  gateway_name?: Maybe<Scalars['String']>;
  gateway_id?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['String']>;
  /** @deprecated Use `gateway_id` field instead. */
  iugu_id?: Maybe<Scalars['String']>;
  /** @deprecated Use `plan_id` field instead. */
  iugu_plan?: Maybe<Scalars['String']>;
  created_at: Scalars['Timestamp'];
  ends_at?: Maybe<Scalars['Timestamp']>;
  bought_at?: Maybe<Scalars['Timestamp']>;
  expires_at?: Maybe<Scalars['Timestamp']>;
}

export interface Template {
  id: Scalars['UUID'];
  user: User;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  created_at: Scalars['Timestamp'];
  updated_at: Scalars['Timestamp'];
  attributes?: Maybe<Array<Maybe<Attribute>>>;
}

export interface TemplateInput {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  text: Scalars['String'];
}


export interface Token {
  access_token: Scalars['String'];
  token_type: Scalars['String'];
  env: Scalars['String'];
  created_at: Scalars['Timestamp'];
}

export interface TokenInput {
  name: Scalars['String'];
}

export enum TypeEnum {
  Link = 'LINK',
  Signature = 'SIGNATURE'
}

export interface UpdateDocumentInput {
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  refusable?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  reminder?: Maybe<ReminderEnum>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<ExpirationInput>;
  footer?: Maybe<FooterEnum>;
  cc?: Maybe<Array<Maybe<EmailInput>>>;
  locale?: Maybe<UpdateDocumentLocaleInput>;
  configs?: Maybe<DocumentConfigInput>;
  /** Your datetime should be in ISO-8601 format */
  deadline_at?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['Int']>;
}

export interface UpdateDocumentLocaleInput {
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}


export interface User {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['Timestamp']>;
  cpf?: Maybe<Scalars['CPF']>;
  cnpj?: Maybe<Scalars['CNPJ']>;
  company?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  founder: Scalars['Boolean'];
  user_hash?: Maybe<Scalars['String']>;
  nolt?: Maybe<Scalars['String']>;
  escavador?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  member?: Maybe<Member>;
  created_at: Scalars['Timestamp'];
  updated_at?: Maybe<Scalars['Timestamp']>;
  /** @deprecated Deprecated in this version. */
  auth_methods?: Maybe<Array<Maybe<Provider>>>;
  settings?: Maybe<Setting>;
  locale?: Maybe<Locale>;
  /** @deprecated Deprecated in this version. */
  statistics: Statistic;
  subscription?: Maybe<Subscription>;
  /** @deprecated Deprecated in this version. */
  group?: Maybe<Group>;
  /** @deprecated Deprecated in this version. */
  permissions?: Maybe<Permission>;
  /** @deprecated Deprecated in this version. */
  customer_id?: Maybe<Scalars['String']>;
  /** Autentique's Privacy */
  emails?: Maybe<Array<Maybe<EmailType>>>;
  /** Autentique's Privacy */
  socials?: Maybe<Array<Maybe<SocialType>>>;
  /** Autentique's Privacy */
  members?: Maybe<Array<Maybe<Member>>>;
  /** Autentique's Privacy */
  sessions?: Maybe<Array<Maybe<SessionType>>>;
  referred_by?: Maybe<Scalars['UUID']>;
}

export interface UserCloudCert {
  /** The serial number of the certificate */
  serial_number?: Maybe<Scalars['String']>;
  /** The type of the certificate */
  type?: Maybe<CloudSignaturesEnum>;
}

export interface UserDetailInput {
  email: Scalars['EmailScalar'];
  password: Scalars['String'];
}

export interface UserInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  password?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationInput>;
}

export interface UserSignatureInput {
  format: Scalars['String'];
  file_image?: Maybe<Scalars['Upload']>;
  file_draw?: Maybe<Scalars['Upload']>;
  font: FontEnum;
  signature_text?: Maybe<Scalars['String']>;
}


export interface Webhook {
  id: Scalars['Int'];
  url: Scalars['String'];
  type: Scalars['String'];
  created_at: Scalars['Timestamp'];
  updated_at: Scalars['Timestamp'];
}

export enum WebhookFormatEnum {
  Urlencoded = 'URLENCODED',
  Json = 'JSON'
}

export interface WebhookType {
  id: Scalars['Int'];
  name: Scalars['String'];
  created_at: Scalars['Timestamp'];
  updated_at: Scalars['Timestamp'];
}

export interface Whitelabel {
  domain?: Maybe<Scalars['String']>;
  sendgrid_domain?: Maybe<Scalars['String']>;
  sendgrid_email_user?: Maybe<Scalars['String']>;
  is_darkmode?: Maybe<Scalars['Boolean']>;
  main_color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  pdf_logo?: Maybe<Scalars['String']>;
  pdf_logo_mini?: Maybe<Scalars['String']>;
}

export interface WhitelabelAppearanceInput {
  main_color?: Maybe<Scalars['String']>;
  is_darkmode?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['Upload']>;
  pdf_logo?: Maybe<Scalars['Upload']>;
  pdf_logo_mini?: Maybe<Scalars['Upload']>;
}

export interface WhitelabelSendgrid {
  whitelabel?: Maybe<Whitelabel>;
  sendgrid_dns?: Maybe<WhitelabelSendgridDns>;
}

export interface WhitelabelSendgridDns {
  valid?: Maybe<Scalars['Boolean']>;
  dns?: Maybe<Array<Maybe<WhitelabelSendgridDnsRecord>>>;
}

export interface WhitelabelSendgridDnsRecord {
  valid?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
}

export interface WhitelabelSendgridInput {
  sendgrid_domain?: Maybe<Scalars['String']>;
  sendgrid_email_user?: Maybe<Scalars['String']>;
}

export type SignatureEventFragment = (
  Pick<Event, 'ip' | 'port' | 'reason' | 'created_at'>
  & { geolocation?: Maybe<Pick<Geolocation, 'country' | 'countryISO' | 'state' | 'stateISO' | 'city' | 'zipcode' | 'latitude' | 'longitude'>> }
);

export type EventFragment = (
  Pick<Event, 'ip' | 'port' | 'reason' | 'created_at'>
  & { geolocation?: Maybe<Pick<Geolocation, 'country' | 'countryISO' | 'state' | 'stateISO' | 'city' | 'zipcode' | 'latitude' | 'longitude'>> }
);

export type EmailEventFragment = Pick<EmailEvent, 'delivered' | 'opened' | 'reason' | 'refused' | 'sent'>;

export type DocumentAuthorFragment = Pick<Author, 'id' | 'email' | 'name' | 'company' | 'organization_id'>;

export type DocumentFileFragment = Pick<File, 'original' | 'signed' | 'certified' | 'pades'>;

export type SignatureTokenFragment = Pick<DocumentSignature, 'document_id' | 'signature_id' | 'signature_public_id'>;

export type SlimOrganizationFragment = Pick<Organization, 'id' | 'name'>;

export type MemberPermissionsBaseFragment = Pick<Permission, 'create_documents' | 'archive_documents' | 'delete_documents' | 'sign_documents' | 'view_documents_gr' | 'view_folders_gr' | 'actions_documents_gr' | 'actions_folders_gr' | 'actions_templates_gr' | 'view_documents_oz' | 'view_folders_oz' | 'view_member_documents_oz' | 'view_member_folders_oz' | 'view_group_documents_oz' | 'view_group_folders_oz' | 'view_invoices_oz' | 'actions_documents_oz' | 'actions_folders_oz' | 'actions_members_oz' | 'actions_groups_oz' | 'actions_webhooks_oz' | 'change_appearances_oz' | 'change_plan_oz'>;

export type MemberPermissionsFragment = (
  Pick<Permission, 'change_whitelabel_oz'>
  & MemberPermissionsBaseFragment
);

export type GroupPermissionsFragment = (
  Pick<Permission, 'overwrite_permissions'>
  & MemberPermissionsFragment
);

export type OrganizationGroupFragment = (
  Pick<Group, 'id' | 'uuid' | 'name' | 'cnpj' | 'company' | 'members_count' | 'is_default' | 'overwrite_template' | 'overwrite_template_group'>
  & { styles?: Maybe<Pick<GroupStyle, 'overwrite_email' | 'overwrite_name'>>, email_templates?: Maybe<Array<Maybe<Pick<EmailTemplates, 'id' | 'name' | 'type'>>>>, permissions?: Maybe<GroupPermissionsFragment> }
);

export type OrganizationMemberFragment = { user?: Maybe<Pick<User, 'id' | 'name' | 'email'>>, group?: Maybe<OrganizationGroupFragment>, permissions?: Maybe<MemberPermissionsBaseFragment> };

export type EmailDetailsFragment = Pick<EmailTemplate, 'colors' | 'footer' | 'image' | 'sender' | 'subject' | 'template' | 'text' | 'title'>;

export type EmailTemplateFragment = (
  Pick<EmailTemplates, 'id' | 'name' | 'type'>
  & { ptBR?: Maybe<EmailDetailsFragment>, enUS?: Maybe<EmailDetailsFragment> }
);

export type UserStatisticsFragment = Pick<Statistic, 'documents' | 'sent' | 'signatures'>;

export type UserSettingsFragment = (
  Pick<Setting, 'format' | 'initials_format' | 'font' | 'signature' | 'signature_draw' | 'initials' | 'initials_draw' | 'has_signature_appearance' | 'has_initials_appearance'>
  & { email?: Maybe<EmailDetailsFragment> }
);

export type UserOrganizationFragment = (
  Pick<Organization, 'id' | 'uuid' | 'name' | 'cnpj' | 'customer_id'>
  & { billing_customer?: Maybe<Pick<Customer, 'iugu_id' | 'stripe_id'>>, settings?: Maybe<Pick<Setting, 'webhook_url' | 'webhook_format'>> }
);

export type UserBasicFragment = (
  Pick<User, 'id' | 'name' | 'email' | 'cpf' | 'birthday' | 'founder' | 'company' | 'cnpj' | 'phone' | 'created_at' | 'email_verified_at' | 'user_hash' | 'nolt' | 'escavador'>
  & { locale?: Maybe<Pick<Locale, 'country' | 'date_format' | 'language' | 'timezone'>> }
);

export type UserFragment = (
  { subscription?: Maybe<Pick<Subscription, 'iugu_id' | 'iugu_plan' | 'credits' | 'name' | 'documents' | 'bonus' | 'has_premium_features' | 'created_at' | 'expires_at'>>, settings?: Maybe<UserSettingsFragment>, organization?: Maybe<UserOrganizationFragment>, member?: Maybe<OrganizationMemberFragment> }
  & UserBasicFragment
);

export type PositionsFragment = Pick<SignaturePosition, 'element' | 'x' | 'y' | 'z' | 'angle'>;

export type VerificationsFragment = (
  Pick<SecurityVerificationType, 'id' | 'type' | 'verified_at' | 'verify_phone' | 'max_attempt' | 'logs_attempt'>
  & { user?: Maybe<UserVerificationFragment>, payload?: Maybe<VerificationPayloadFragment> }
);

export type UserVerificationFragment = Pick<SecurityVerificationUserType, 'confidence' | 'images'>;

export type VerificationPayloadFragment = Pick<SecurityVerificationPayloadType, 'url' | 'reference' | 'expiry' | 'failcode'>;

export type BasicSignerFragment = (
  Pick<Signature, 'public_id' | 'organization_id' | 'group_id'>
  & { signed?: Maybe<EventFragment>, signed_unapproved?: Maybe<EventFragment> }
);

export type WhitelabelFragment = Pick<Whitelabel, 'domain' | 'main_color' | 'is_darkmode' | 'logo' | 'pdf_logo' | 'pdf_logo_mini' | 'sendgrid_domain' | 'sendgrid_email_user'>;

export type SendgridDnsFragment = (
  Pick<WhitelabelSendgridDns, 'valid'>
  & { dns?: Maybe<Array<Maybe<Pick<WhitelabelSendgridDnsRecord, 'valid' | 'type' | 'host' | 'data' | 'reason'>>>> }
);

export type InvoiceFragment = Pick<Invoice, 'description' | 'due_date' | 'payment_method' | 'price' | 'status' | 'id' | 'url_nfse' | 'url_payment'>;

export type PublicDocumentQueryVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
  publicId?: Maybe<Scalars['UUID']>;
}>;


export type PublicDocumentQuery = { document?: Maybe<PublicDocumentFragment> };

export type SignatureQueryVariables = Exact<{
  documentId: Scalars['UUID'];
}>;


export type SignatureQuery = { signature: SlimSignatureFragment };

export type LinkSignatureQueryVariables = Exact<{
  documentId: Scalars['UUID'];
}>;


export type LinkSignatureQuery = { signatureByLink?: Maybe<SlimSignatureFragment> };

export type PublicSignTokensQueryVariables = Exact<{
  signatureId: Scalars['UUID'];
}>;


export type PublicSignTokensQuery = { signatures?: Maybe<Array<Maybe<SignatureTokenFragment>>> };

export type PublicDocumentFileQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PublicDocumentFileQuery = { document?: Maybe<{ files?: Maybe<DocumentFileFragment> }> };

export type PublicDocumentHistoryQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PublicDocumentHistoryQuery = { documentEmailHistory?: Maybe<{ signatures?: Maybe<Array<Maybe<PublicSignerHistoryFragment>>>, signers_history?: Maybe<Array<Maybe<(
      Pick<SignerHistory, 'created_at' | 'category'>
      & { user?: Maybe<(
        Pick<PublicSignature, 'public_id'>
        & { user?: Maybe<Pick<PublicUser, 'id' | 'name' | 'email' | 'phone'>> }
      )>, author?: Maybe<(
        Pick<PublicSignature, 'public_id'>
        & { user?: Maybe<Pick<PublicUser, 'id' | 'name' | 'email'>> }
      )> }
    )>>> }> };

export type SignatureBySecurityVerificationCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type SignatureBySecurityVerificationCodeQuery = Pick<Query, 'signatureBySecurityVerificationCode'>;

export type ViewSignatureMutationVariables = Exact<{
  signatureId: Scalars['UUID'];
}>;


export type ViewSignatureMutation = { viewSignature?: Maybe<(
    Pick<PublicSignature, 'public_id'>
    & { viewed?: Maybe<EventFragment> }
  )> };

export type RejectSignatureMutationVariables = Exact<{
  signatureId: Scalars['UUID'];
  type: TypeEnum;
  reason?: Maybe<Scalars['String']>;
}>;


export type RejectSignatureMutation = { rejectSignature?: Maybe<(
    Pick<PublicSignature, 'public_id'>
    & { rejected?: Maybe<EventFragment> }
  )> };

export type SlimSignatureFragment = (
  Pick<PublicSignature, 'public_id'>
  & { document: Pick<PublicDocument, 'id'> }
);

export type PublicDocumentFragment = (
  { author: DocumentAuthorFragment, files?: Maybe<DocumentFileFragment>, hashes?: Maybe<Pick<Hash, 'sha2'>>, configs?: Maybe<Pick<DocumentConfig, 'notification_signed' | 'notification_finished' | 'signature_appearance'>>, signatures?: Maybe<Array<Maybe<PublicSignerFragment>>> }
  & PublicBasicDocumentFragment
);

export type PublicBasicDocumentFragment = (
  Pick<PublicDocument, 'id' | 'name' | 'ignore_cpf' | 'refusable' | 'sortable' | 'qualified' | 'sandbox' | 'footer' | 'new_signature_style' | 'scrolling_required' | 'stop_on_rejected' | 'is_blocked' | 'created_at' | 'updated_at' | 'expiration_at' | 'deadline_at' | 'lifecycle_in' | 'show_audit_page'>
  & { locale?: Maybe<Pick<Locale, 'language' | 'country' | 'date_format' | 'timezone'>> }
);

export type PublicSignerFragment = (
  Pick<PublicSignature, 'public_id' | 'email' | 'name' | 'parent' | 'created_at' | 'updated_at' | 'delivery_method'>
  & { certificate?: Maybe<Pick<SignedCertificate, 'type' | 'email' | 'document' | 'name'>>, action?: Maybe<Pick<Action, 'name'>>, email_events?: Maybe<EmailEventFragment>, configs?: Maybe<Pick<SignatureConfig, 'cpf' | 'overwrite_name' | 'overwrite_date'>>, viewed?: Maybe<EventFragment>, signed?: Maybe<EventFragment>, rejected?: Maybe<EventFragment>, signed_unapproved?: Maybe<EventFragment>, biometric_approved?: Maybe<EventFragment>, biometric_rejected?: Maybe<EventFragment>, positions?: Maybe<Array<Maybe<PositionsFragment>>>, user?: Maybe<(
    Pick<PublicUser, 'id' | 'name' | 'email' | 'cpf' | 'birthday' | 'company' | 'phone' | 'escavador'>
    & { organization?: Maybe<Pick<Organization, 'id' | 'name'>>, member?: Maybe<{ user?: Maybe<Pick<User, 'id'>>, group?: Maybe<(
        Pick<Group, 'id' | 'company'>
        & { styles?: Maybe<Pick<GroupStyle, 'overwrite_email' | 'overwrite_name'>> }
      )> }>, settings?: Maybe<Pick<Setting, 'format' | 'initials_format' | 'font' | 'signature' | 'signature_draw' | 'initials' | 'initials_draw'>> }
  )>, verifications?: Maybe<Array<Maybe<VerificationsFragment>>> }
);

export type PublicSignerHistoryFragment = (
  Pick<PublicSignature, 'public_id' | 'email' | 'name' | 'created_at' | 'updated_at'>
  & { email_history?: Maybe<Array<Maybe<(
    Pick<EmailEvent, 'id' | 'type'>
    & { user?: Maybe<Pick<User, 'name' | 'email'>>, author?: Maybe<Pick<User, 'name' | 'email'>> }
    & EmailEventFragment
  )>>>, configs?: Maybe<Pick<SignatureConfig, 'cpf' | 'overwrite_name' | 'overwrite_date'>>, viewed?: Maybe<(
    { user?: Maybe<Pick<User, 'name' | 'email' | 'cpf'>> }
    & EventFragment
  )>, signed?: Maybe<(
    { user?: Maybe<Pick<User, 'name' | 'email' | 'cpf'>> }
    & EventFragment
  )>, rejected?: Maybe<(
    { user?: Maybe<Pick<User, 'name' | 'email' | 'cpf'>> }
    & EventFragment
  )>, signed_unapproved?: Maybe<(
    { user?: Maybe<Pick<User, 'name' | 'email' | 'cpf'>> }
    & EventFragment
  )>, biometric_approved?: Maybe<(
    { user?: Maybe<Pick<User, 'name' | 'email' | 'cpf'>> }
    & EventFragment
  )>, biometric_rejected?: Maybe<(
    { user?: Maybe<Pick<User, 'name' | 'email' | 'cpf'>> }
    & EventFragment
  )>, user?: Maybe<Pick<PublicUser, 'id' | 'name' | 'email' | 'cpf' | 'phone'>> }
);

export type OrganizationInvoicesQueryVariables = Exact<{
  organizationUuid: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type OrganizationInvoicesQuery = { organizationInvoices?: Maybe<(
    Pick<InvoicePagination, 'current_page' | 'last_page' | 'total'>
    & { data?: Maybe<Array<Maybe<InvoiceFragment>>> }
  )> };

export type UserExistQueryVariables = Exact<{
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['PhoneScalar']>;
}>;


export type UserExistQuery = Pick<Query, 'userExist'>;

export type WhitelabelByDomainQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type WhitelabelByDomainQuery = { whitelabelByDomain?: Maybe<WhitelabelFragment> };

export type WhitelabelByDocumentQueryVariables = Exact<{
  documentId: Scalars['UUID'];
}>;


export type WhitelabelByDocumentQuery = { whitelabelByDocument?: Maybe<WhitelabelFragment> };

export const SignatureEventFragmentDoc = gql`
    fragment signatureEvent on Event {
  ip
  port
  reason
  created_at
  geolocation {
    country
    countryISO
    state
    stateISO
    city
    zipcode
    latitude
    longitude
  }
}
    `;
export const SignatureTokenFragmentDoc = gql`
    fragment signatureToken on DocumentSignature {
  document_id
  signature_id
  signature_public_id
}
    `;
export const SlimOrganizationFragmentDoc = gql`
    fragment slimOrganization on Organization {
  id
  name
}
    `;
export const EmailDetailsFragmentDoc = gql`
    fragment emailDetails on EmailTemplate {
  colors
  footer
  image
  sender
  subject
  template
  text
  title
}
    `;
export const EmailTemplateFragmentDoc = gql`
    fragment emailTemplate on EmailTemplates {
  id
  name
  type
  ptBR {
    ...emailDetails
  }
  enUS {
    ...emailDetails
  }
}
    ${EmailDetailsFragmentDoc}`;
export const UserStatisticsFragmentDoc = gql`
    fragment userStatistics on Statistic {
  documents
  sent
  signatures
}
    `;
export const UserBasicFragmentDoc = gql`
    fragment userBasic on User {
  id
  name
  email
  cpf
  birthday
  founder
  company
  cnpj
  phone
  created_at
  email_verified_at
  user_hash
  nolt
  escavador
  locale {
    country
    date_format
    language
    timezone
  }
}
    `;
export const UserSettingsFragmentDoc = gql`
    fragment userSettings on Setting {
  format
  initials_format
  font
  signature
  signature_draw
  initials
  initials_draw
  has_signature_appearance
  has_initials_appearance
  email {
    ...emailDetails
  }
}
    ${EmailDetailsFragmentDoc}`;
export const UserOrganizationFragmentDoc = gql`
    fragment userOrganization on Organization {
  id
  uuid
  name
  cnpj
  customer_id
  billing_customer {
    iugu_id
    stripe_id
  }
  settings {
    webhook_url
    webhook_format
  }
}
    `;
export const MemberPermissionsBaseFragmentDoc = gql`
    fragment memberPermissionsBase on Permission {
  create_documents
  archive_documents
  delete_documents
  sign_documents
  view_documents_gr
  view_folders_gr
  actions_documents_gr
  actions_folders_gr
  actions_templates_gr
  view_documents_oz
  view_folders_oz
  view_member_documents_oz
  view_member_folders_oz
  view_group_documents_oz
  view_group_folders_oz
  view_invoices_oz
  actions_documents_oz
  actions_folders_oz
  actions_members_oz
  actions_groups_oz
  actions_webhooks_oz
  change_appearances_oz
  change_plan_oz
}
    `;
export const MemberPermissionsFragmentDoc = gql`
    fragment memberPermissions on Permission {
  ...memberPermissionsBase
  change_whitelabel_oz
}
    ${MemberPermissionsBaseFragmentDoc}`;
export const GroupPermissionsFragmentDoc = gql`
    fragment groupPermissions on Permission {
  overwrite_permissions
  ...memberPermissions
}
    ${MemberPermissionsFragmentDoc}`;
export const OrganizationGroupFragmentDoc = gql`
    fragment organizationGroup on Group {
  id
  uuid
  name
  cnpj
  company
  members_count
  is_default
  overwrite_template
  overwrite_template_group
  styles {
    overwrite_email
    overwrite_name
  }
  email_templates {
    id
    name
    type
  }
  permissions {
    ...groupPermissions
  }
}
    ${GroupPermissionsFragmentDoc}`;
export const OrganizationMemberFragmentDoc = gql`
    fragment organizationMember on Member {
  user {
    id
    name
    email
  }
  group {
    ...organizationGroup
  }
  permissions {
    ...memberPermissionsBase
  }
}
    ${OrganizationGroupFragmentDoc}
${MemberPermissionsBaseFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment user on User {
  ...userBasic
  subscription {
    iugu_id
    iugu_plan
    credits
    name
    documents
    bonus
    has_premium_features
    created_at
    expires_at
  }
  settings {
    ...userSettings
  }
  organization {
    ...userOrganization
  }
  member {
    ...organizationMember
  }
}
    ${UserBasicFragmentDoc}
${UserSettingsFragmentDoc}
${UserOrganizationFragmentDoc}
${OrganizationMemberFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment event on Event {
  ip
  port
  reason
  created_at
  geolocation {
    country
    countryISO
    state
    stateISO
    city
    zipcode
    latitude
    longitude
  }
}
    `;
export const BasicSignerFragmentDoc = gql`
    fragment basicSigner on Signature {
  public_id
  organization_id
  group_id
  signed {
    ...event
  }
  signed_unapproved {
    ...event
  }
}
    ${EventFragmentDoc}`;
export const WhitelabelFragmentDoc = gql`
    fragment whitelabel on Whitelabel {
  domain
  main_color
  is_darkmode
  logo
  pdf_logo
  pdf_logo_mini
  sendgrid_domain
  sendgrid_email_user
}
    `;
export const SendgridDnsFragmentDoc = gql`
    fragment sendgridDns on WhitelabelSendgridDns {
  valid
  dns {
    valid
    type
    host
    data
    reason
  }
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment invoice on Invoice {
  description
  due_date
  payment_method
  price
  status
  id
  url_nfse
  url_payment
}
    `;
export const SlimSignatureFragmentDoc = gql`
    fragment slimSignature on PublicSignature {
  public_id
  document {
    id
  }
}
    `;
export const PublicBasicDocumentFragmentDoc = gql`
    fragment publicBasicDocument on PublicDocument {
  id
  name
  ignore_cpf
  refusable
  sortable
  qualified
  sandbox
  footer
  new_signature_style
  scrolling_required
  stop_on_rejected
  is_blocked
  created_at
  updated_at
  expiration_at
  deadline_at
  lifecycle_in
  new_signature_style
  show_audit_page
  locale {
    language
    country
    date_format
    timezone
  }
}
    `;
export const DocumentAuthorFragmentDoc = gql`
    fragment documentAuthor on Author {
  id
  email
  name
  company
  organization_id
}
    `;
export const DocumentFileFragmentDoc = gql`
    fragment documentFile on File {
  original
  signed
  certified
  pades
}
    `;
export const EmailEventFragmentDoc = gql`
    fragment emailEvent on EmailEvent {
  delivered
  opened
  reason
  refused
  sent
}
    `;
export const PositionsFragmentDoc = gql`
    fragment positions on SignaturePosition {
  element
  x
  y
  z
  angle
}
    `;
export const UserVerificationFragmentDoc = gql`
    fragment userVerification on SecurityVerificationUserType {
  confidence
  images
}
    `;
export const VerificationPayloadFragmentDoc = gql`
    fragment verificationPayload on SecurityVerificationPayloadType {
  url
  reference
  expiry
  failcode
}
    `;
export const VerificationsFragmentDoc = gql`
    fragment verifications on SecurityVerificationType {
  id
  type
  user {
    ...userVerification
  }
  payload {
    ...verificationPayload
  }
  verified_at
  verify_phone
  max_attempt
  logs_attempt
}
    ${UserVerificationFragmentDoc}
${VerificationPayloadFragmentDoc}`;
export const PublicSignerFragmentDoc = gql`
    fragment publicSigner on PublicSignature {
  public_id
  email
  name
  parent
  certificate {
    type
    email
    document
    name
  }
  created_at
  updated_at
  action {
    name
  }
  delivery_method
  email_events {
    ...emailEvent
  }
  configs {
    cpf
    overwrite_name
    overwrite_date
  }
  viewed {
    ...event
  }
  signed {
    ...event
  }
  rejected {
    ...event
  }
  signed_unapproved {
    ...event
  }
  biometric_approved {
    ...event
  }
  biometric_rejected {
    ...event
  }
  positions {
    ...positions
  }
  user {
    id
    name
    email
    cpf
    birthday
    company
    phone
    escavador
    organization {
      id
      name
    }
    member {
      user {
        id
      }
      group {
        id
        company
        styles {
          overwrite_email
          overwrite_name
        }
      }
    }
    settings {
      format
      initials_format
      font
      signature
      signature_draw
      initials
      initials_draw
    }
  }
  verifications {
    ...verifications
  }
}
    ${EmailEventFragmentDoc}
${EventFragmentDoc}
${PositionsFragmentDoc}
${VerificationsFragmentDoc}`;
export const PublicDocumentFragmentDoc = gql`
    fragment publicDocument on PublicDocument {
  ...publicBasicDocument
  author {
    ...documentAuthor
  }
  files {
    ...documentFile
  }
  hashes {
    sha2
  }
  configs {
    notification_signed
    notification_finished
    signature_appearance
  }
  signatures {
    ...publicSigner
  }
}
    ${PublicBasicDocumentFragmentDoc}
${DocumentAuthorFragmentDoc}
${DocumentFileFragmentDoc}
${PublicSignerFragmentDoc}`;
export const PublicSignerHistoryFragmentDoc = gql`
    fragment publicSignerHistory on PublicSignature {
  public_id
  email
  name
  created_at
  updated_at
  email_history {
    id
    type
    user {
      name
      email
    }
    author {
      name
      email
    }
    ...emailEvent
  }
  configs {
    cpf
    overwrite_name
    overwrite_date
  }
  viewed {
    user {
      name
      email
      cpf
    }
    ...event
  }
  signed {
    user {
      name
      email
      cpf
    }
    ...event
  }
  rejected {
    user {
      name
      email
      cpf
    }
    ...event
  }
  signed_unapproved {
    user {
      name
      email
      cpf
    }
    ...event
  }
  biometric_approved {
    user {
      name
      email
      cpf
    }
    ...event
  }
  biometric_rejected {
    user {
      name
      email
      cpf
    }
    ...event
  }
  user {
    id
    name
    email
    cpf
    phone
  }
}
    ${EmailEventFragmentDoc}
${EventFragmentDoc}`;
export const PublicDocumentDocument = gql`
    query PublicDocument($id: UUID, $publicId: UUID) {
  document(id: $id, public_id: $publicId) {
    ...publicDocument
  }
}
    ${PublicDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicDocumentGQL extends Apollo.Query<PublicDocumentQuery, PublicDocumentQueryVariables> {
    document = PublicDocumentDocument;
    client = 'public';
  }
export const SignatureDocument = gql`
    query Signature($documentId: UUID!) {
  signature(id: $documentId) {
    ...slimSignature
  }
}
    ${SlimSignatureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SignatureGQL extends Apollo.Query<SignatureQuery, SignatureQueryVariables> {
    document = SignatureDocument;
    client = 'public';
  }
export const LinkSignatureDocument = gql`
    query LinkSignature($documentId: UUID!) {
  signatureByLink(id: $documentId) {
    ...slimSignature
  }
}
    ${SlimSignatureFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkSignatureGQL extends Apollo.Query<LinkSignatureQuery, LinkSignatureQueryVariables> {
    document = LinkSignatureDocument;
    client = 'public';
  }
export const PublicSignTokensDocument = gql`
    query PublicSignTokens($signatureId: UUID!) {
  signatures(id: $signatureId) {
    ...signatureToken
  }
}
    ${SignatureTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicSignTokensGQL extends Apollo.Query<PublicSignTokensQuery, PublicSignTokensQueryVariables> {
    document = PublicSignTokensDocument;
    client = 'public';
  }
export const PublicDocumentFileDocument = gql`
    query PublicDocumentFile($id: UUID!) {
  document(id: $id) {
    files {
      ...documentFile
    }
  }
}
    ${DocumentFileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicDocumentFileGQL extends Apollo.Query<PublicDocumentFileQuery, PublicDocumentFileQueryVariables> {
    document = PublicDocumentFileDocument;
    client = 'public';
  }
export const PublicDocumentHistoryDocument = gql`
    query PublicDocumentHistory($id: UUID!) {
  documentEmailHistory(id: $id) {
    signatures {
      ...publicSignerHistory
    }
    signers_history {
      created_at
      category
      user {
        public_id
        user {
          id
          name
          email
          phone
        }
      }
      author {
        public_id
        user {
          id
          name
          email
        }
      }
    }
  }
}
    ${PublicSignerHistoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicDocumentHistoryGQL extends Apollo.Query<PublicDocumentHistoryQuery, PublicDocumentHistoryQueryVariables> {
    document = PublicDocumentHistoryDocument;
    client = 'public';
  }
export const SignatureBySecurityVerificationCodeDocument = gql`
    query SignatureBySecurityVerificationCode($code: String!) {
  signatureBySecurityVerificationCode(code: $code)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignatureBySecurityVerificationCodeGQL extends Apollo.Query<SignatureBySecurityVerificationCodeQuery, SignatureBySecurityVerificationCodeQueryVariables> {
    document = SignatureBySecurityVerificationCodeDocument;
    client = 'public';
  }
export const ViewSignatureDocument = gql`
    mutation ViewSignature($signatureId: UUID!) {
  viewSignature(id: $signatureId) {
    public_id
    viewed {
      ...event
    }
  }
}
    ${EventFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ViewSignatureGQL extends Apollo.Mutation<ViewSignatureMutation, ViewSignatureMutationVariables> {
    document = ViewSignatureDocument;
    client = 'public';
  }
export const RejectSignatureDocument = gql`
    mutation RejectSignature($signatureId: UUID!, $type: TypeEnum!, $reason: String) {
  rejectSignature(id: $signatureId, type: $type, reason: $reason) {
    public_id
    rejected {
      ...event
    }
  }
}
    ${EventFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectSignatureGQL extends Apollo.Mutation<RejectSignatureMutation, RejectSignatureMutationVariables> {
    document = RejectSignatureDocument;
    client = 'public';
  }
export const OrganizationInvoicesDocument = gql`
    query OrganizationInvoices($organizationUuid: String!, $limit: Int!, $page: Int!) {
  organizationInvoices(organization_uuid: $organizationUuid, limit: $limit, page: $page) {
    current_page
    last_page
    total
    data {
      ...invoice
    }
  }
}
    ${InvoiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationInvoicesGQL extends Apollo.Query<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables> {
    document = OrganizationInvoicesDocument;
    client = 'public';
  }
export const UserExistDocument = gql`
    query UserExist($email: EmailScalar, $phone: PhoneScalar) {
  userExist(email: $email, phone: $phone)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserExistGQL extends Apollo.Query<UserExistQuery, UserExistQueryVariables> {
    document = UserExistDocument;
    client = 'public';
  }
export const WhitelabelByDomainDocument = gql`
    query WhitelabelByDomain($domain: String!) {
  whitelabelByDomain(domain: $domain) {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WhitelabelByDomainGQL extends Apollo.Query<WhitelabelByDomainQuery, WhitelabelByDomainQueryVariables> {
    document = WhitelabelByDomainDocument;
    client = 'public';
  }
export const WhitelabelByDocumentDocument = gql`
    query WhitelabelByDocument($documentId: UUID!) {
  whitelabelByDocument(document_id: $documentId) {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WhitelabelByDocumentGQL extends Apollo.Query<WhitelabelByDocumentQuery, WhitelabelByDocumentQueryVariables> {
    document = WhitelabelByDocumentDocument;
    client = 'public';
  }