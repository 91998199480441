import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, AuthenticationService, NotyService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = void;
export interface ModalPublicProperties {
  email: string;
}

@Component({
  selector: 'app-auth-email-confirmation-modal',
  templateUrl: './auth-email-confirmation-modal.component.html',
  styleUrls: ['./auth-email-confirmation-modal.component.scss']
})
export class AuthEmailConfirmationModalComponent implements ModalPublicProperties {
  @Input() email: string;
  isLoading = false;
  isWaitingConfirmation = true;

  constructor(
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {
    setTimeout(() => (this.isWaitingConfirmation = false), 10000);
  }

  sendEmailConfirmation() {
    this.isLoading = true;

    this.authenticationService
      .sendEmailConfirmation(this.email)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => this.notyService.success(this.translateService.instant('notyService.confirmationEmailResent')),
        error => this.errorHandlerService.handle(error)
      );
  }

  nextStep(confirmation: boolean) {
    if (confirmation) this.modal.close();
  }
}
