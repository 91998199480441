import { Component, EventEmitter, Input, Output, OnInit, ElementRef, ViewChild } from '@angular/core';
import { User, Whitelabel } from '@app/models';
import { contextMenuState } from '../configurations-whitelabel.component';
import { ErrorHandlerService, NotyService, WhitelabelService } from '@app/services';
import { switchMap } from 'rxjs/operators';
import { WhitelabelSendgridDns } from 'src/generated/graphql.default';
import { ClipboardService } from 'ngx-clipboard';

export enum emailDomainSteps {
  default = 0,
  verification = 1
}

@Component({
  selector: 'app-whitelabel-email-adress',
  templateUrl: './whitelabel-email-adress.component.html',
  styleUrls: ['../../configurations.scss', './whitelabel-email-adress.component.scss']
})
export class WhitelabelEmailAdressComponent implements OnInit {
  @Input() currentUser: User;
  @Input() sendgridDns: WhitelabelSendgridDns;
  @Input() whitelabelData: Whitelabel;
  @Output() contextChange: EventEmitter<contextMenuState> = new EventEmitter();
  @Output() goBack: EventEmitter<contextMenuState> = new EventEmitter();
  @ViewChild('saveButton') saveButton: ElementRef;
  @ViewChild('verifyButton') verifyButton: ElementRef;

  contextMenuState = contextMenuState;

  emailDomainSteps = emailDomainSteps;
  domainSteps: emailDomainSteps = emailDomainSteps.default;

  emailPlaceholder: string;
  domainPlaceholder: string;
  emailToggle: boolean = false;

  isEmailVerified: boolean = false;
  domainWithBlur: boolean = false;

  isDomainLoading: boolean = false;

  constructor(private whitelabelService: WhitelabelService, private errorHandlerService: ErrorHandlerService, private notyService: NotyService, private clipboardService: ClipboardService) {}

  ngOnInit(): void {
    if (this.whitelabelData && this.whitelabelData.sendgrid_domain) {
      // controls toggle if user has previous data;
      this.emailToggle = true;
    }
  }

  changeContext(context: contextMenuState) {
    if (this.domainSteps === this.emailDomainSteps.default) {
      this.contextChange.emit(context);
    } else {
      this.domainSteps = this.emailDomainSteps.default;
    }
  }

  copyToClipboard(content: string) {
    this.clipboardService.copyFromContent(content);
    this.notyService.success('Texto copiado com sucesso!');
  }

  adjustDomainPlaceholderPosition() {
    // makes everything after '@' translocate right on email input;
    const inputElement = document.getElementById('domainInput') as HTMLInputElement;
    const spanElement = document.querySelector('.field__dinamicDomain') as HTMLElement;
    const domainPlaceholderWidth = this.getTextWidth(this.emailPlaceholder, getComputedStyle(inputElement).font);
    spanElement.style.left = `${domainPlaceholderWidth}px`;
  }

  getTextWidth(text: string, font: string) {
    // gives emailinput width to adjustDomainPlaceholderPosition();
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width + 15;
  }

  domainForwardEmail() {
    if (this.isEmailVerified) {
      //runs only after verifyDomain() receives true ( 'Salvar' button);
      this.submitForm();
      return;
    } // bellow runs on 'Avançar' pressing;
    this.whitelabelService
      .updateWhitelabelSendgrid({
        whitelabelSendgrid: {
          sendgrid_domain: this.domainPlaceholder || this.whitelabelData.sendgrid_domain,
          sendgrid_email_user: this.emailPlaceholder || this.whitelabelData.sendgrid_email_user
        }
      })
      .subscribe(
        response => {
          this.domainSteps = emailDomainSteps.verification;
          this.sendgridDns = response.sendgrid_dns;
        },
        error => {
          this.errorHandlerService.handle(error);
        }
      );
  }

  domainForwardEmptyEmail() {
    // only runs if user wants to deactivate Whitelabel and reset data;
    this.whitelabelService
      .updateWhitelabelSendgrid({ whitelabelSendgrid: { sendgrid_domain: '', sendgrid_email_user: '' } })
      .pipe(switchMap(() => this.whitelabelService.activateWhitelabelSendgrid()))
      .subscribe(
        () => {
          this.domainSteps = emailDomainSteps.verification;
          this.goBack.emit();
          this.notyService.success('Remoção salva com sucesso.');
        },
        error => {
          this.errorHandlerService.handle(error);
        }
      );
  }

  verifyDomainEmail() {
    this.isDomainLoading = true;
    this.verifyButton.nativeElement.disabled = true;
    // timeout to give some user feedback;
    setTimeout(() => {
      this.isDomainLoading = false;
      this.verifyButton.nativeElement.disabled = false;
    }, 3000); // method actualy starts bellow:
    this.whitelabelService.whitelabelSendgridValidate({ sendgrid_domain: this.domainPlaceholder || this.whitelabelData.sendgrid_domain }).subscribe(
      isVerified => {
        this.isEmailVerified = isVerified.valid;
        this.sendgridDns = isVerified;
      },
      error => {
        this.errorHandlerService.handle(error);
      }
    );
  }

  returnEmail() {
    if (this.whitelabelData && this.whitelabelData.sendgrid_domain) {
      let domain = this.domainPlaceholder || this.whitelabelData.sendgrid_domain || '';
      let user = this.emailPlaceholder || this.whitelabelData.sendgrid_email_user || '';
      return `${user}@${domain}`;
    } else return '';
  }

  submitForm() {
    // last step, runs when 'Salvar' is pressed;
    this.saveButton.nativeElement.disabled = true;
    this.whitelabelService.activateWhitelabelSendgrid().subscribe(
      () => {
        this.notyService.success('Registros salvos com sucesso.');
        this.goBack.emit();
        this.saveButton.nativeElement.disabled = false;
      },
      error => {
        this.errorHandlerService.handle(error);
        this.saveButton.nativeElement.disabled = false;
      }
    );
  }
}
