import { AfterViewInit, Component, ElementRef } from '@angular/core';

import { UserService } from '@app/services';

declare const nolt: any;

@Component({
  selector: '[app-nolt]',
  templateUrl: './nolt.component.html',
  styleUrls: ['./nolt.component.scss']
})
export class NoltComponent implements AfterViewInit {
  constructor(private elementRef: ElementRef<HTMLElement>, private userService: UserService) {}

  ngAfterViewInit() {
    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(user => {
      if (nolt) {
        const selector = `${this.elementRef.nativeElement.tagName.toLowerCase()}[app-nolt].${this.elementRef.nativeElement.classList
          .toString()
          .split(/\s+/)
          .join('.')}`;
        nolt('init', { url: 'https://autentique.nolt.io', selector });
        if (user && user.nolt) {
          nolt('identify', { jwt: user.nolt });
        }
      }
    });
  }
}
