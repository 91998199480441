<form class="modal-body" [formGroup]="form" (ngSubmit)="create()" novalidate>
  <p class="centermodal__title">{{ 'model.createNewField' | translate }}</p>

  <div class="centermodal__form" formGroupName="variable">
    <div class="centermodal__group-title">{{ 'model.fieldData' | translate }}</div>

    <label class="field__holder select" [appFormControlWrap]="form.get('variable.type')">
      <select class="field" formControlName="type" [class.empty]="false" required>
        <option [ngValue]="type.key" *ngFor="let type of types | keyvalue">{{ type.value | translate }}</option>
      </select>
      <span class="field__placeholder">{{ 'model.fieldType' | translate }}</span>
    </label>

    <label class="field__holder" [appFormControlWrap]="form.get('variable.name')">
      <input type="text" class="field" formControlName="name" autocomplete="none" required [appExistingNames]="existingNames" #nameInput />
      <span class="field__placeholder">{{ 'model.fieldName' | translate }}</span>
    </label>

    <div class="sidemodal__switch">
      <span class="sidemodal__switch-name">{{ 'model.isRequired' | translate }}</span>
      <app-switch formControlName="required"></app-switch>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.back' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid">{{ 'button.create' | translate }}</button>
  </div>
</form>
