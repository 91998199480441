<div class="container">
  <app-header *ngIf="whitelabelService.params.tab"></app-header>
  <div class="d-flex">
    <app-taxonomy *ngIf="whitelabelService.params.sidebar" mainTitle="{{ 'menu.contacts' | translate }}">
      <!-- <button routerLink="/documentos/novo" class="taxonomy-button">Novo contato</button> -->
      <a class="taxonomy-option" routerLink="/contatos" [routerLinkActive]="'current'"> <i class="fal fa-book-user"></i> {{ 'documents.myContacts' | translate }} </a>
      <!-- <a class="taxonomy-option"> <i class="fal fa-users-class"></i> Contatos do grupo </a> -->
      <!-- <a class="taxonomy-option"> <i class="fal fa-users"></i> Toda a organização </a> -->
    </app-taxonomy>
  </div>
  <main class="configuration">
    <app-contacts-user></app-contacts-user>
  </main>
</div>
