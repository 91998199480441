import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { AuthenticationService, DocumentService, ErrorHandlerService, NotyService } from '@app/services';
import { SecurityVerificationEnum, SecurityVerificationType } from '@app/models';
import { LoaderService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { CountdownTimerService } from '@app/services/countdowntimer.service';

export type ModalResult = SecurityVerificationType;
export interface ModalPublicProperties {
  verification: SecurityVerificationType;
}

export enum SecurityCodeType {
  Sms = 'sms',
  Whatsapp = 'whatsapp'
}

@Component({
  selector: 'app-verification-sms-modal',
  templateUrl: './verification-sms-modal.component.html',
  styleUrls: ['./verification-sms-modal.component.scss']
})
export class VerificationSmsModalComponent implements ModalPublicProperties, AfterContentInit, OnDestroy {
  @Input() verification: SecurityVerificationType;
  @Input() signatureId: any;
  form!: FormGroup;
  isLoading = false;
  isVerifyingToken = false;
  isInvalidToken = false;
  token: string;
  verifyPhoneExisting = false;
  formattedTime: string = '';
  isSubmiting = false;
  readonly SecurityCodeType = SecurityCodeType;

  constructor(
    public modal: NgbActiveModal,
    public whatsAppTimer: CountdownTimerService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private documentService: DocumentService,
    private notyService: NotyService,
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({ phoneNumber: '' });
  }

  ngOnDestroy() {}

  ngAfterContentInit() {
    if (this.verification && this.verification.type === SecurityVerificationEnum.Sms) {
      if (this.verification.verify_phone) {
        this.verifyPhoneExisting = true;
        this.sendSecurityCode();
      }
    }
    this.timeOutForWhatsappVerification();
  }

  updateSecurityVerificationMutation() {
    const verifyPhone = this.form.get('phoneNumber').value.replace(/^[+]/, '');

    this.isLoading = true;
    this.loaderService.show();
    this.documentService
      .updateSecurityVerification({ id: this.verification.id, securityVerification: { type: SecurityVerificationEnum.Sms, verify_phone: verifyPhone } })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        () => {
          this.verifyPhoneExisting = true;
          this.sendSecurityCode();
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  sendSecurityCode(securityCodeType: string = SecurityCodeType.Sms) {
    this.isLoading = true;
    this.loaderService.show();
    this.isVerifyingToken = true;
    this.isInvalidToken = false;
    this.documentService
      .sendSecurityCode({
        id: this.verification.id,
        type: securityCodeType
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        () => {
          setTimeout(() => (this.isVerifyingToken = false), 20000);
          this.authenticationService.clearAllFields();
          this.notyService.success(this.translateService.instant('notyService.' + (securityCodeType === SecurityCodeType.Whatsapp ? 'whatsappVerificationSent' : 'smsVerificationSent')));
        },
        error => {
          setTimeout(() => (this.isVerifyingToken = false), 2000);
          this.errorHandlerService.handle(error);
        }
      );
  }

  submitToken() {
    this.isVerifyingToken = true;
    this.isLoading = true;
    this.isInvalidToken = false;

    this.documentService
      .checkSecurityVerification({ id: this.verification.id, securityCode: this.token })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isVerifyingToken = false;
        })
      )
      .subscribe(
        response => {
          if (response.verified_at) {
            this.notyService.success(this.translateService.instant('notyService.verificationCodeValidated'));
            this.modal.close(response as ModalResult);
          } else {
            this.isInvalidToken = true;
          }
        },
        error => {
          this.isInvalidToken = true;
          this.errorHandlerService.handle(error);
        }
      );
  }

  timeOutForWhatsappVerification() {
    if (this.whatsAppTimer.isCountdownInProgress()) {
      this.whatsAppTimer.stopCountdown();
    }
    this.whatsAppTimer
      .startCountdownForWhatsApp()
      .pipe(untilDestroyed(this))
      .subscribe(time => {
        this.formattedTime = time;
      });
  }
}
