<div class="modal-body">
  <p class="centermodal__title">{{ 'auth.selectAuthenticationMethod' | translate }}</p>

  <div class="centermodal__form">
    <div class="centermodal__button-group centermodal__button-group--vertical">
      <!--
      <div class="centermodal__button centermodal__button--large centermodal__button--custom-google" (click)="modal.close(Result.Google)">
        <figure style="background-image: url('/assets/google.svg');background-size: 24px;" alt="Google" class="icon"></figure>
        Fazer login com o Google
      </div>
      -->
      <app-google-oauth
        class="centermodal__button--custom-google"
        context="use"
        uxMode="popup"
        type="standard"
        shape="rectangular"
        theme="outline"
        text="continue_with"
        size="large"
        width="230"
        logoAlignment="left"
        (callback)="emit(Result.Google, $event)"
        *ngIf="!isElectron"
      >
      </app-google-oauth>
      <div class="centermodal__button centermodal__button--large centermodal__button--custom-facebook" (click)="emit(Result.Facebook)" *ngIf="!isElectron">
        <i class="icon fab fa-facebook-square"></i>
        {{ 'auth.continueWithFacebook' | translate }}
      </div>
      <div class="centermodal__button centermodal__button--large centermodal__button--custom-microsoft" (click)="emit(Result.Microsoft)" *ngIf="!isElectron">
        <figure style="background-image: url('/assets/microsoft.svg');background-size: 20px;" alt="Google" class="icon"></figure>
        {{ 'auth.continueWithMicrosoft' | translate }}
      </div>
      <div class="centermodal__button centermodal__button--large centermodal__button--custom-sms" (click)="emit(Result.Sms)">
        <i class="icon fal fa-mobile"></i>
        {{ 'auth.continueWithPhone' | translate }}
      </div>
      <!--
      <div class="centermodal__button centermodal__button--large centermodal__button--custom-whatsapp" (click)="emit(Result.Whatsapp)">
        <i class="icon fab fa-whatsapp"></i>
        {{ 'auth.continueWithWhatsapp' | translate }}
      </div>
      -->
      <div class="centermodal__button centermodal__button--large centermodal__button--custom-email" (click)="emit(Result.Email)">
        <i class="icon fal fa-envelope"></i>
        {{ 'auth.continueWithEmail' | translate }}
      </div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <div class="centermodal__button" (click)="modal.dismiss()">{{ 'button.back' | translate }}</div>
  </div>
</div>
