import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AttributeInput, TemplateAttributeTypes } from '@app/models';
import { templateAttributeTypes } from '../template-attribute-types';

type TemplateAttribute = Pick<AttributeInput, 'name' | 'type' | 'required'>;

export type ModalResult = TemplateAttribute;
export interface ModalPublicProperties {
  variable?: {
    name?: string;
    type?: string;
    required?: boolean;
  };
  existingNames?: string[];
}

@Component({
  selector: 'app-create-template-variable-modal',
  templateUrl: './create-template-variable-modal.component.html',
  styleUrls: ['./create-template-variable-modal.component.scss']
})
export class CreateTemplateVariableModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() variable: ModalPublicProperties['variable'];
  @Input() existingNames: ModalPublicProperties['existingNames'] = [];
  form!: FormGroup;
  readonly types = templateAttributeTypes;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ variable: this.formBuilder.group({ name: '', type: '', required: false } as ModalPublicProperties['variable']) });
  }

  ngAfterContentInit() {
    this.form.get('variable').setValue({
      name: (this.variable || {}).name || '',
      type: (this.variable || {}).type || TemplateAttributeTypes.Text,
      required: typeof (this.variable || {}).required === 'boolean' ? this.variable.required : false
    } as ModalPublicProperties['variable']);
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  create() {
    const result: ModalResult = this.form.get('variable').value;
    result.name = (result.name || '').trim().replace(/\s+/, ' ');
    this.modal.close(result);
  }
}
