<div class="auth__container">
  <div class="container">
    <div class="autentique-logo-holder">
      <img class="autentique-logo dark" src="assets/logo-and-text-white.png" alt="" />
      <img class="autentique-logo light" src="assets/Autentique.svg" alt="" />
    </div>
    <ng-content></ng-content>
    <div class="terms">
      <a href="https://www.autentique.com.br/termos-de-uso/" class="terms-item">{{ 'external.useTerm' | translate }}</a>
      <a href="https://www.autentique.com.br/politica-de-privacidade/" class="terms-item">{{ 'external.privacyPolicy' | translate }}</a>
    </div>
  </div>
</div>
<app-auth-brand-container [backToRegister]="backToRegister"></app-auth-brand-container>
