export const idColors = [
  '#ff9ff3',
  '#feca57',
  '#ff6b6b',
  '#48dbfb',
  '#1dd1a1',
  '#01a3a4',
  '#2e86de',
  '#341f97',
  '#8395a7',
  '#222f3e',
  '#00d2d3',
  '#54a0ff',
  '#5f27cd',
  '#c8d6e5',
  '#576574',
  '#f368e0',
  '#ff9f43',
  '#ee5253',
  '#0abde3',
  '#10ac84'
];
