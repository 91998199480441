<form (ngSubmit)="submitToken()">
  <h2 class="tokenField__title">{{ 'shared.confirmYourEmailAddress' | translate }}</h2>
  <p class="tokenField__excerpt" [innerHTML]="'shared.weSentEmailWithConfirmationCode' | translate: { email: email }"></p>

  <p class="tokenField__alert warning" *ngIf="isOutlookEmail(email)"><i class="tokenField__alert-icon"></i> <span [innerHTML]="'shared.ifEmailNotVisibleAddToContacts' | translate"></span></p>

  <app-token-field [class.invalid]="isInvalidToken" [isVerifyingToken]="isVerifyingToken" (tokenChange)="token = $event" (submit)="submitToken()"></app-token-field>

  <p class="tokenField__alert" *ngIf="isInvalidToken"><i class="tokenField__alert-icon"></i> {{ 'shared.oopsIncorrectCode' | translate }}</p>
  <div class="tokenField__resend">
    <span class="tokenField__resend-text">{{ 'shared.didNotReceiveCode' | translate }}</span>
    <button type="button" class="tokenField__resend-button btn btn-link" (click)="sendEmailConfirmation()" [disabled]="isVerifyingToken">
      {{ 'shared.clickHereToResend' | translate }}
    </button>
  </div>

  <ng-content></ng-content>
</form>
