<div class="d-flex">
  <app-mobile-header-main
    (titleClicked)="openTaxonomyMenu = $event"
    [mainTitle]="isOnFolder ? currentFolder?.name : ('menu.wholeOrganization' | translate)"
    menulink="/menu/documentos"
  ></app-mobile-header-main>
  <div class="taxonomy-overlay" [class.taxonomy-overlay-active]="openTaxonomyMenu" (click)="openTaxonomyMenu = false"></div>
  <app-taxonomy
    *ngIf="whitelabelService.params.sidebar"
    [mainTitle]="isOnFolder ? ('documents.folders' | translate) : ('documents.documents' | translate)"
    allowSearch
    [(isSearchOpen)]="isSearchOpen"
    (isSearchOpenChange)="toggleSearch($event)"
    [class.isMenuMobileOpen]="openTaxonomyMenu"
  >
    <app-documents-taxonomy
      [isSearchOpen]="isSearchOpen"
      [listPath]="currentContext === ContextEnum.Group ? 'group' : 'organization'"
      [disableMainButton]="!(currentUser?.currentPermissions)[currentContext === ContextEnum.Group ? 'actions_folders_gr' : 'actions_folders_oz']"
      (sortingChange)="loadDocuments({ page: 1, search: searchQuery }); loadFolders({ page: 1, search: searchQuery })"
      (newFolderButtonClick)="openCreateFolderModal()"
    >
    </app-documents-taxonomy>
  </app-taxonomy>
</div>

<div class="d-flex flex-column w-100">
  <app-documents-search
    [(search)]="searchQuery"
    (searchChange)="loadDocuments({ page: 1, search: $event }); loadFolders({ page: 1, search: $event })"
    (closeClick)="isSearchOpen = false; toggleSearch(isSearchOpen)"
    *ngIf="isSearchOpen"
    @slideDownUpTrigger
  ></app-documents-search>

  <div class="documents">
    <div class="documents__header justify-content-start" *ngIf="whitelabelService.params.folderBreadCrumbs">
      <ng-container *ngIf="!isOnFolder">
        <h2 class="documents__title" *ngIf="!((isSearchOpen && searchQuery) || mobileSearch)">{{ 'menu.wholeOrganization' | translate }}</h2>
        <h2 class="documents__title" *ngIf="(isSearchOpen && searchQuery) || mobileSearch">{{ 'documents.searchResults' | translate }}</h2>
        <app-search [(query)]="mobileSearch" (queryChange)="loadDocuments({ page: 1, search: { search: $event } })"></app-search>
      </ng-container>

      <h2 class="documents__title" *ngIf="currentFolder?.name">
        {{ 'menu.' + (currentContext === ContextEnum.Group ? 'groupFoldersIn' : 'organizationFoldersIn') | translate }}
        <span class="highlight" *ngIf="currentUser?.organization?.name">{{ currentUser?.organization?.name }}</span>
      </h2>
      <a
        class="documents__header-button"
        (click)="openCreateFolderModal()"
        *ngIf="
          (currentFolder?.name && currentUser.currentPermissions.actions_folders_gr && currentContext === ContextEnum.Group) ||
          (currentFolder?.name && currentUser.currentPermissions.actions_folders_oz && currentContext === ContextEnum.Organization)
        "
        >{{ 'menu.newFolder' | translate }}</a
      >
      <ng-container *ngIf="isOnFolder && currentFolder">
        <div class="documents__pathing" *ngIf="currentFolder && whitelabelService.params.folderBreadCrumbs">
          <a [routerLink]="['/pastas/organizacao']" *ngIf="currentContext === ContextEnum.Organization">{{ 'menu.organizationFolders' | translate }}&nbsp;</a>
          <a [routerLink]="currentGroup ? ['/pastas', 'grupo', this.currentGroup.uuid] : ['/pastas/grupo']" *ngIf="currentContext === ContextEnum.Group">
            {{ currentGroup?.name || ('menu.groupFolders' | translate) | truncate: 35 }}&nbsp;
          </a>
          <ng-container *ngIf="currentFolder.parentFolder">
            <p class="documents__pathing__current">>&nbsp;&hellip;&nbsp;>&nbsp;</p>
            <a [routerLink]="['/pastas', currentFolder.parentFolder.id, 'grupo'].concat(currentGroup ? [currentGroup.uuid, 'documentos'] : [])" *ngIf="currentContext === ContextEnum.Group">
              {{ currentFolder.parentFolder.name | truncate: 35 }}&nbsp;
            </a>
            <a [routerLink]="['/pastas', currentFolder.parentFolder.id, 'organizacao']" *ngIf="currentContext === ContextEnum.Organization">
              {{ currentFolder.parentFolder.name | truncate: 35 }}&nbsp;
            </a>
          </ng-container>
          <p class="documents__pathing__current">
            >&nbsp;<b>{{ currentFolder.name | truncate: 35 }}</b>
          </p>
        </div>
      </ng-container>
      <button
        type="button"
        class="btn btn-link text-right d-none d-md-inline-block"
        [disabled]="isLoading"
        (click)="exportDocuments(searchQuery)"
        *ngIf="isSearchOpen && searchQuery && this.documents?.length > 0"
      >
        <i class="fal fa-file-csv"></i> {{ 'documents.export' | translate }}
      </button>
    </div>

    <app-folders-list-boxes
      *ngIf="whitelabelService.params.folderBreadCrumbs"
      [folders]="folders"
      [linkPath]="currentContext === ContextEnum.Group ? $any(['/pastas', ':id', 'grupo'].concat(currentGroup ? [currentGroup.uuid, 'documentos'] : [])) : ['/pastas', ':id', 'organizacao']"
      [hidePagination]="isLoading || isLastPageFolders"
      [hideDropdownMenu]="!(currentUser?.currentPermissions || [])[currentContext === ContextEnum.Group ? 'actions_folders_gr' : 'actions_folders_oz']"
      (loadPageClick)="loadFolders({ page: pageNumberFolders + 1, search: searchQuery })"
      (folderClick)="openFoldersModal({ folder: $event })"
      (updateClick)="openUpdateFolderModal($event)"
      (deleteClick)="deleteFolder($event)"
    >
    </app-folders-list-boxes>

    <div class="documents__header justify-content-start" *ngIf="currentFolder?.name">
      <h2 class="documents__title">
        {{ 'menu.documentsIn' | translate }} <span class="highlight">{{ currentFolder?.name }}</span>
      </h2>
      <a class="documents__header-button" (click)="routeToNewDocument()" *ngIf="currentUser?.currentPermissions.create_documents">{{ 'menu.createDocument' | translate }}</a>
    </div>

    <div
      class="documents-empty"
      *ngIf="
        currentUser && !isLoading && !searchQuery && !mobileSearch && !appService.documentStatusFilter && documents?.length === 0 && (!isOnFolder || folders?.length === 0);
        else documentsEmptyWithFilters
      "
    >
      <div class="documents-empty__box">
        <p>{{ 'documents.noDocumentsHere' | translate }}</p>
        <button type="button" (click)="routeToNewDocument()" class="btn btn-block btn-primary" *ngIf="currentUser?.currentPermissions.create_documents">
          {{ 'documents.createNewDocument' | translate }}
        </button>
        <button type="button" *ngIf="currentContext === ContextEnum.Group && whitelabelService.params.folderBreadCrumbs" routerLink="/pastas/grupo" class="btn btn-block btn-outline-secondary">
          {{ 'documents.accessFoldersGroup' | translate }}
        </button>
        <button
          type="button"
          *ngIf="currentContext === ContextEnum.Organization && whitelabelService.params.folderBreadCrumbs"
          routerLink="/pastas/organizacao"
          class="btn btn-block btn-outline-secondary"
        >
          {{ 'documents.accessFoldersOrganization' | translate }}
        </button>
      </div>
    </div>

    <ng-template #documentsEmptyWithFilters>
      <div
        class="documents-filtered-empty"
        *ngIf="currentUser && !isLoading && documents && (searchQuery || mobileSearch || appService.documentStatusFilter) && documents?.length === 0 && (!isOnFolder || folders?.length === 0)"
      >
        <div class="documents-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="documents-filtered-empty_text">
          <span>{{ 'documents.noDocumentsMatchingFilter' | translate }}</span>
        </div>
        <button type="button" class="default-button" (click)="clearFilters()">{{ 'documents.clearFilters' | translate }}</button>
      </div>
    </ng-template>

    <app-documents-list-boxes
      *ngIf="appService.listFormat === ListFormat.Box"
      [currentUser]="currentUser"
      [documents]="documents"
      [hidePagination]="isLoading || isLastPage"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      (signClick)="sign($event)"
      (folderClick)="openFoldersModal({ documents: $event })"
      (deleteClick)="delete($event)"
      (transferClick)="transfer($event)"
      (toggleBlockClick)="toggleBlock($event)"
      (resendWebhookClick)="resendWebhook($event)"
    >
    </app-documents-list-boxes>

    <app-documents-list-table
      *ngIf="appService.listFormat === ListFormat.List"
      [currentUser]="currentUser"
      [hidePagination]="isLoading || isLastPage"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      [documents]="documents"
      (signClick)="sign($event)"
      (folderClick)="openFoldersModal({ documents: $event })"
      (deleteClick)="delete($event)"
      (transferClick)="transfer($event)"
      (toggleBlockClick)="toggleBlock($event)"
      (resendSignaturesClick)="resendSignatures($event)"
      (resendWebhookClick)="resendWebhook($event)"
    >
    </app-documents-list-table>
  </div>
</div>
