import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app/services';

@Component({
  selector: 'app-menu-mobile-sign-out',
  templateUrl: './mobile-sign-out.component.html',
  styleUrls: ['./mobile-sign-out.component.scss']
})
export class MobileSignOutComponent implements OnInit {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {}

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true }));
  }
}
