<form class="modal-body" [formGroup]="form" (ngSubmit)="submit()" novalidate>
  <p class="centermodal__title">{{ 'signature.changeSignatureAppearance' | translate }}</p>

  <div class="centermodal__form" formGroupName="user">
    <app-signature-pad [format]="form.get('user.format').value" [font]="form.get('user.font').value" (formatChange)="form.get('user.format').setValue($event)" #signaturePad> </app-signature-pad>
    <label class="field__holder select" *ngIf="user && form.get('user.format').value === SignatureFormat.Handwriting">
      <select class="field" formControlName="font">
        <option [ngValue]="font.key" *ngFor="let font of fonts | keyvalue">{{ font.value | translate }}</option>
      </select>
      <p class="field__placeholder">{{ 'settings.font' | translate }}</p>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next">{{ 'button.save' | translate }}</button>
  </div>
</form>
