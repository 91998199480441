import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppService } from '@app/services';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    }
  ]
})
export class ColorInputComponent {
  @Input() mainColor = '#000000';
  @Input() formControlName: string;
  @Input() disabled = false;
  @Input() disableAlpha = true;

  showHexadecimalError = false;

  onChange: (_: string) => void;
  onTouch: () => void;

  constructor(private appService: AppService) {
    this.onChange = () => {};
    this.onTouch = () => {};
  }

  writeValue(value: string) {
    this.mainColor = value;
    this.onChange(this.mainColor);
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateMainColor(color: string) {
    if (this.appService.isHexaColor(color.trim())) {
      this.mainColor = color.trim();
      this.showHexadecimalError = false;
    } else {
      this.showHexadecimalError = true;
    }
  }
}
