<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" *ngIf="!isVerifyingEmail" novalidate>
  <p class="centermodal__title">{{ 'profile.completeRegistration' | translate }}</p>

  <div class="centermodal__form">
    <ng-container formGroupName="user">
      <label class="field__holder" [appFormControlWrap]="form.get('user.email')" *ngIf="form.get('user.email')">
        <input type="email" class="field" formControlName="email" name="email" autocomplete="email" required appValidEmail />
        <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
      </label>

      <label class="field__holder" [appFormControlWrap]="form.get('user.name')" *ngIf="form.get('user.name')">
        <input type="text" class="field" formControlName="name" name="name" autocomplete="name" required appValidName appFullName appForbiddenNames />
        <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
      </label>

      <ng-container *ngIf="forceCpfAs || !ignoreCpf">
        <label class="field__holder" [appFormControlWrap]="form.get('user.cpf')" *ngIf="form.get('user.cpf')">
          <input type="tel" class="field" formControlName="cpf" name="cpf" autocomplete="cpf" mask="000.000.000-00" [appMatchCpf]="forceCpfAs" required />
          <span class="field__placeholder">{{ 'profile.cpf' | translate }}</span>
        </label>
      </ng-container>

      <label class="field__holder" [appFormControlWrap]="form.get('user.birthday')" *ngIf="form.get('user.birthday')">
        <input type="tel" class="field" formControlName="birthday" name="birthday" autocomplete="birthday" mask="00/00/0000" [dropSpecialCharacters]="false" [specialCharacters]="['/']" required />
        <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
      </label>

      <label class="field__holder" [appFormControlWrap]="form.get('user.password')" *ngIf="form.get('user.password')">
        <input type="password" class="field" formControlName="password" name="password" autocomplete="password" required appShowPassword data-sentry-mask />
        <span class="field__placeholder">{{ 'settings.setPassword' | translate }}</span>
      </label>
    </ng-container>
    <ng-container formGroupName="locale">
      <div class="row">
        <div class="col-lg-6" [appFormControlWrap]="form.get('locale.country')" *ngIf="form.get('locale.country')">
          <app-select placeholder="{{ 'profile.country' | translate }}" formControlName="country" [options]="countries" [required]="true"></app-select>
        </div>
        <div class="col-lg-6" [appFormControlWrap]="form.get('locale.language')" *ngIf="form.get('locale.language')">
          <app-select
            placeholder="{{ 'profile.language' | translate }}"
            formControlName="language"
            (click)="translateService.use(form.get('locale.language').value)"
            [disableSearch]="true"
            [options]="languages"
          ></app-select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6" [appFormControlWrap]="form.get('locale.timezone')" *ngIf="form.get('locale.timezone')">
          <app-select placeholder="{{ 'profile.timezone' | translate }}" formControlName="timezone" [options]="timezones" [required]="true"></app-select>
        </div>
        <div class="col-lg-6" [appFormControlWrap]="form.get('locale.date_format')" *ngIf="form.get('locale.date_format')">
          <app-select placeholder="{{ 'profile.dateFormat' | translate }}" formControlName="date_format" [disableSearch]="true" [options]="dateFormat"> </app-select>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container [ngTemplateOutlet]="modalButtons"></ng-container>
</form>

<div class="modal-body" *ngIf="isVerifyingEmail">
  <app-email-verify [verifier]="currentUser.email" [email]="currentUser.email" (verified)="modal.close()">
    <ng-container [ngTemplateOutlet]="modalButtons"></ng-container>
  </app-email-verify>
</div>

<ng-template #modalButtons>
  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.continue' | translate }}</button>
  </div>
</ng-template>
