import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from '@app/core';
import { User } from '@app/models';
import { AppService, UserService, WhitelabelService } from '@app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-sidebar-header',
  templateUrl: './document-sidebar-header.component.html',
  styleUrls: ['./document-sidebar-header.component.scss']
})
export class DocumentSidebarHeaderComponent implements OnInit, OnDestroy {
  currentUser: User;
  isLoading: boolean = false;
  @Input() hideCurrentUser = false;

  constructor(public whitelabelService: WhitelabelService, private appService: AppService, private userService: UserService, private router: Router) {}

  ngOnInit() {
    this.isLoading = true;
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.currentUser = user;
        this.isLoading = false;
      });
  }

  ngOnDestroy() {}

  isCurrentUserHidden() {
    return this.hideCurrentUser;
  }

  isWhitelabel() {
    // return true;
    return !this.appService.isAutentiqueDomain() && !!this.whitelabelService?.whitelabel?.domain;
  }

  isOnSigningPage() {
    return this.router.url.includes('/assinar');
  }
}
