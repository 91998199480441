import { Injectable } from '@angular/core';
import { throwOnGraphqlError } from '@app/core';
import { DeleteContactGQL } from 'src/generated/graphql.default';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(private deleteContactGQL: DeleteContactGQL) {}

  deleteContacts(contacts: any[]) {
    return forkJoin(
      contacts.map(id => {
        return this.deleteContactGQL.mutate({ id }).pipe(throwOnGraphqlError());
      })
    );
  }
}
