import { AfterContentInit, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { concat, from, interval, of, Subscription, throwError, timer } from 'rxjs';
import { filter, finalize, map, switchMap, tap, toArray } from 'rxjs/operators';
import * as Bowser from 'bowser';

import { environment } from '@env/environment';
import { Logger } from '@app/core';
import { SecurityVerificationEnum, SecurityVerificationFileTypeEnum, SecurityVerificationPayloadType, SecurityVerificationType } from '@app/models';
import { AppService, DocumentService, ErrorHandlerService, NotyService } from '@app/services';
import { LoaderService } from '@app/shared/loader/loader.service';

export type ModalResult = SecurityVerificationType;
export interface ModalPublicProperties {
  verification: SecurityVerificationType;
}

const DesktopSteps = {
  Intro: 'Intro',
  HasBackPrompt: 'HasBackPrompt',
  MobilePrompt: 'MobilePrompt',
  CameraError: 'CameraError',
  VerificationError: 'VerificationError',
  ...SecurityVerificationFileTypeEnum
};
const MobileSteps = {
  Intro: 'Intro',
  MobileFrontCheck: 'MobileFrontCheck',
  MobileBackCheck: 'MobileBackCheck',
  MobileSelfieCheck: 'MobileSelfieCheck',
  ...SecurityVerificationFileTypeEnum
};
const Steps = { ...DesktopSteps, ...MobileSteps };

const log = new Logger('VerificationModalComponent');

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss']
})
export class VerificationModalComponent implements ModalPublicProperties, AfterContentInit, OnDestroy {
  @Input() verification: SecurityVerificationType;
  @ViewChild('video', { static: false }) video: ElementRef<HTMLVideoElement>;
  desktopStep: string;
  mobileStep: string;
  picturesUrls: { [key: string]: string } = {};
  picturesImages: { [key: string]: File } = {};
  isLoading = false;
  currentUrl: string;
  referenceCode: SecurityVerificationPayloadType;
  readonly mobileCheckMap = { [Steps.MobileFrontCheck]: Steps.Front, [Steps.MobileBackCheck]: Steps.Back, [Steps.MobileSelfieCheck]: Steps.Selfie };
  readonly Steps = Steps;
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  private referenceSubscription: Subscription;
  private previousSteps: string[] = [];

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService,
    private ngZone: NgZone,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private appService: AppService,
    private documentService: DocumentService
  ) {}

  get currentStep() {
    return this.isMobile() ? this.mobileStep : this.desktopStep;
  }
  set currentStep(step: string) {
    this.previousSteps.unshift(this[this.isMobile() ? 'mobileStep' : 'desktopStep'] || Steps.Intro);
    this[this.isMobile() ? 'mobileStep' : 'desktopStep'] = step || Steps.Intro;
  }

  ngAfterContentInit() {
    this.resetPictures();
    this.currentStep = null;
  }

  ngOnDestroy() {
    this.stopCamera();
    if (this.referenceSubscription) {
      this.referenceSubscription.unsubscribe();
    }
  }

  setInitialStep() {
    this.currentStep = this.verification.type === SecurityVerificationEnum.PfFacial ? Steps.Selfie : Steps.Front;
  }

  goBack() {
    if (this.currentStep !== Steps.Intro) {
      this.currentStep = this.previousSteps[0] || Steps.Intro;
      this.previousSteps.splice(0, 2);
    } else {
      this.modal.dismiss();
    }
  }

  stopCamera() {
    if (this.video && this.video.nativeElement && this.video.nativeElement.srcObject) {
      (this.video.nativeElement.srcObject as MediaStream).getTracks().forEach(track => track.stop());
    }
  }

  loadCamera() {
    this.stopCamera();
    this.setInitialStep();
    this.isLoading = true;
    timer(500)
      .pipe(
        switchMap(() => (this.video && this.video.nativeElement ? of(null) : throwError(''))),
        switchMap(() => (navigator.mediaDevices && navigator.mediaDevices.getUserMedia ? from(navigator.mediaDevices.getUserMedia({ video: true })) : throwError(''))),
        tap(stream => (this.video.nativeElement.srcObject = stream)),
        switchMap(() => from(this.video.nativeElement.play())),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {},
        error => {
          this.video.nativeElement.srcObject = null;
          this.currentStep = Steps.CameraError;
          if (error) {
            log.error(error);
          }
        }
      );
  }

  takePictureDesktop() {
    this.isLoading = true;
    const canvasElement = document.createElement('canvas');
    const pixelRatio = 3;
    canvasElement.setAttribute('width', (this.video.nativeElement.offsetWidth * pixelRatio).toString());
    canvasElement.setAttribute('height', (this.video.nativeElement.offsetHeight * pixelRatio).toString());
    canvasElement.style.display = 'none';

    const ctx = canvasElement.getContext('2d', { alpha: false });
    ctx.scale(pixelRatio, pixelRatio);
    ctx.drawImage(this.video.nativeElement, 0, 0, this.video.nativeElement.offsetWidth, this.video.nativeElement.offsetHeight);
    canvasElement.toBlob(blob => {
      this.ngZone.run(() => {
        if (this.picturesUrls[this.currentStep]) {
          URL.revokeObjectURL(this.picturesUrls[this.currentStep]);
        }
        this.picturesUrls[this.currentStep] = blob ? URL.createObjectURL(blob) : '';
        this.picturesImages[this.currentStep] = new File([blob], this.currentStep + '.png', { type: 'image/png', lastModified: new Date().getTime() });
        this.isLoading = false;
        if (this.currentStep === Steps.Selfie) {
          this.verify(this.picturesImages);
        } else {
          this.currentStep = this.currentStep === Steps.Back ? Steps.Selfie : Steps.HasBackPrompt;
        }
      });
    }, 'image/jpeg');
  }

  takePictureMobile(image: File) {
    if (image) {
      this.isLoading = true;

      if (this.picturesUrls[this.currentStep]) {
        URL.revokeObjectURL(this.picturesUrls[this.currentStep]);
      }

      this.appService
        .downsizeImage(image, 1500)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          downsizedImage => {
            this.picturesImages[this.currentStep] = downsizedImage;
            this.picturesUrls[this.currentStep] = URL.createObjectURL(downsizedImage);
            this.currentStep = this.swapKeyValue(this.mobileCheckMap)[this.currentStep];
          },
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  useFrontAsBackPicture() {
    if (this.picturesUrls[Steps.Back]) {
      URL.revokeObjectURL(this.picturesUrls[Steps.Back]);
    }
    delete this.picturesUrls[Steps.Back];
    delete this.picturesImages[Steps.Back];
    this.currentStep = Steps.Selfie;
  }

  verify(images: { [key: string]: File }) {
    this.isLoading = true;
    this.loaderService.show();
    of(Object.keys(images).map(key => this.documentService.updateSecurityVerification({ id: this.verification.id, fileType: key as SecurityVerificationFileTypeEnum, file: images[key] })))
      .pipe(
        switchMap(requests => concat(...requests).pipe(toArray())),
        map(verifications => verifications[verifications.length - 1]),
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        data => {
          this.verification = data;
          if (data?.verified_at || data?.type === SecurityVerificationEnum.Manual) {
            this.modal.close(data as ModalResult);
          } else {
            this.notyService.error((data.logs_attempt && data.logs_attempt[0][0]?.message) || this.translateService.instant('error_pf_facial_mismatch'));
            this.currentStep = Steps.VerificationError;
            this.resetPictures();
            if (data?.max_attempt === 0) {
              this.modal.close(data as ModalResult);
            }
          }
        },
        error => {
          this.currentStep = Steps.VerificationError;
          this.stopCamera();
          this.resetPictures();
          this.errorHandlerService.handle(error);
        }
      );
  }

  selectDesktopMobilePrompt() {
    this.currentStep = Steps.MobilePrompt;
    this.isLoading = true;
    this.generateSecurityVerificationCode()
      .pipe(
        switchMap(() => (this.currentUrl ? of(null) : this.documentService.signatureBySecurityVerificationCode({ code: this.referenceCode.reference }))),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        signatureId => {
          if (!this.currentUrl) {
            this.currentUrl = [environment.panelUrl, 'assinar', signatureId].join('/') + '?signPrompt=1';
            this.referenceSubscription = interval(5000)
              .pipe(
                filter(() => this.referenceCode && new Date(this.referenceCode.expiry) < new Date()),
                switchMap(() => this.generateSecurityVerificationCode())
              )
              .subscribe();
          }
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  private generateSecurityVerificationCode() {
    this.isLoading = true;
    return this.documentService.generateSecurityVerificationCode({ id: this.verification.id }).pipe(tap(data => (this.referenceCode = data)));
  }

  private resetPictures() {
    this.currentStep = null;
    Object.values(this.picturesUrls || {}).forEach(pic => pic && URL.revokeObjectURL(pic));
    this.picturesUrls = {};
    this.picturesImages = {};
  }

  private swapKeyValue(obj: { [k: string]: string }) {
    return Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({ [b]: a })));
  }

  private isMobile() {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType() === 'mobile';
  }
}
