import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalService } from '@app/core/modal.service';
import { ModalPublicProperties, ModalResult, AuthRegisterModalComponent as ModalComponent } from './auth-register-modal.component';

@Injectable({ providedIn: 'root' })
export class AuthRegisterModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: ModalPublicProperties, options?: NgbModalOptions, throwOnDismiss?: boolean) {
    return this.modalService.open<ModalResult>(ModalComponent, properties, options, throwOnDismiss);
  }
}
