import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of, throwError, timer } from 'rxjs';
import { catchError, filter, finalize, mergeMap, retryWhen, switchMap, takeWhile } from 'rxjs/operators';
import * as Bowser from 'bowser';

import { environment } from '@env/environment';
import { untilDestroyed } from '@app/core';
import { ActionEnum, CloudSignaturesEnum, Document, SignatureFormat, SignatureTypesEnum, SlimDocument, SlimOrganization, User } from '@app/models';
import { AppService, DateService, DocumentService, ErrorHandlerService, NotyService, OrganizationService, PadesService, PadesSignatureData, UserService } from '@app/services';

export type ModalResult = PadesSignatureData;
export interface ModalPublicProperties {
  action: ActionEnum;
  document: SlimDocument | Document;
  padesSignatureId: string;
  hasFailedBiometry?: boolean;
}

enum ModalType {
  preSign,
  qualifiedOptions,
  a1,
  safeId,
  a3,
  vidaas
}
enum A1FormStep {
  upload,
  password
}
enum SafeIdFormStep {
  cpfCnpj,
  waitingAuthorization,
  password
}
enum VidaasFormStep {
  cpfCnpj,
  waitingAuthorization
}

@Component({
  selector: 'app-pre-sign-modal',
  templateUrl: './pre-sign-modal.component.html',
  styleUrls: ['./pre-sign-modal.component.scss']
})
export class PreSignModalComponent implements ModalPublicProperties, AfterViewInit, OnDestroy {
  @Input() action: ActionEnum;
  @Input() padesSignatureId: string;
  @Input() document: SlimDocument | Document;
  @Input() hasFailedBiometry: boolean;
  preSignForm!: FormGroup;
  a1Form!: FormGroup;
  a3Form!: FormGroup;
  safeIdForm!: FormGroup;
  vidaasForm!: FormGroup;
  modalType: ModalType;
  a1FormStep = A1FormStep.upload;
  safeIdFormStep = SafeIdFormStep.cpfCnpj;
  vidaasFormStep = VidaasFormStep.cpfCnpj;
  currentUser: User;
  organizations: SlimOrganization[] = [];
  format: SignatureFormat;
  certInfo: { serial?: string; cn?: string; o?: string; type?: CloudSignaturesEnum };
  isLoading = false;
  canSelectPkcs11Library = false;
  readonly isElectron = environment.electron;
  readonly ModalType = ModalType;
  readonly A1FormStep = A1FormStep;
  readonly SafeIdFormStep = SafeIdFormStep;
  readonly VidaasFormStep = VidaasFormStep;
  readonly ActionEnum = ActionEnum;
  readonly SignatureFormat = SignatureFormat;
  readonly cloudSignatureInfo = {
    [CloudSignaturesEnum.Safeid]: {
      modalType: ModalType.safeId,
      label: 'SafeID'
    },
    [CloudSignaturesEnum.Vidaas]: {
      modalType: ModalType.vidaas,
      label: 'VIDaaS'
    }
  };
  @ViewChild('uploadWrapper', { static: false }) private uploadWrapper: ElementRef<HTMLElement>;
  @ViewChild('a1PasswordInput', { static: false }) private a1PasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('a3PasswordInput', { static: false }) private a3PasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('safeidPasswordInput', { static: false }) private safeidPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('safeidCpfCnpjInput', { static: false }) private safeidCpfCnpjInput: ElementRef<HTMLInputElement>;
  @ViewChild('vidaasCpfCnpjInput', { static: false }) private vidaasCpfCnpjInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    public documentService: DocumentService,
    public dateService: DateService,
    public appService: AppService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private notyService: NotyService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private padesService: PadesService,
    private organizationService: OrganizationService,
    private userService: UserService
  ) {
    this.preSignForm = this.formBuilder.group({ organization: null });
    this.a1Form = this.formBuilder.group({ file: null, password: '' });
    this.a3Form = this.formBuilder.group({ password: '' });
    this.safeIdForm = this.formBuilder.group({ cpfCnpj: '', password: '' });
    this.vidaasForm = this.formBuilder.group({ cpfCnpj: '' });
  }

  ngAfterViewInit() {
    if (!this.document || !this.document.qualified) {
      setTimeout(() => (this.modalType = ModalType.preSign));
    } else {
      setTimeout(() => (this.isLoading = true));
    }

    this.uploadWrapper.nativeElement.addEventListener('dragover', event => event.preventDefault(), false);
    this.uploadWrapper.nativeElement.addEventListener(
      'drop',
      event => {
        event.stopPropagation();
        event.preventDefault();
        const files: FileList = event.dataTransfer.files;

        if (files && files[0] && files[0].type.match('application/x-pkcs')) {
          this.a1Form.get('file').setValue(files[0]);
          this.padesNextStep();
        } else {
          this.notyService.error(this.translateService.instant('notyService.invalidCertificateFile'));
        }
      },
      false
    );

    this.organizationService.getOrganizations().subscribe(organizations => (this.organizations = organizations));
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      this.preSignForm.get('organization').setValue(this.currentUser.organization && this.currentUser.organization.id);

      this.format = this.currentUser.settings.format as SignatureFormat;

      if (this.document && this.document.configs && this.document.configs.signature_appearance) {
        this.format = this.documentService.signatureAppearanceToFormats[this.document.configs.signature_appearance];
      }

      if (this?.document && this?.document.qualified) {
        const certData = this.padesService.getStoredA1CertificateData(this.currentUser);
        if (certData && certData.file && certData.password) {
          setTimeout(() => (this.isLoading = false));
          this.modalType = ModalType.preSign;
          this.a1Form.setValue({ file: certData.file, password: certData.password });
          const certificate = this.padesService.parseA1Certificate(certData.file, certData.password);
          this.certInfo = certificate ? { cn: certificate.subject.getField('CN').value, o: certificate.subject.getField('O').value } : null;
        } else {
          this.padesService
            .checkCloudCert({ fetchPolicy: 'cache-first' })
            .pipe(catchError(() => of(null)))
            .subscribe(data => {
              setTimeout(() => (this.isLoading = false));
              if (data?.serial_number) {
                this.certInfo = { serial: data.serial_number, type: data.type };
                this.modalType = ModalType.preSign;
              } else {
                this.modalType = ModalType.qualifiedOptions;
              }
            });
        }
      }
    });
  }

  ngOnDestroy() {}

  padesNextStep() {
    if (this.modalType === ModalType.a1) {
      if (this.a1FormStep === A1FormStep.password) {
        this.a1Form.markAllAsTouched();
        this.signPades();
      } else {
        this.a1FormStep = A1FormStep.password;
        this.focusPasswordInput();
      }
    } else if (this.modalType === ModalType.a3) {
      this.a3Form.markAllAsTouched();
      this.signPades();
    } else if (this.modalType === ModalType.safeId) {
      if (this.safeIdFormStep === SafeIdFormStep.cpfCnpj) {
        const waitingErrors = ['cloud_cert_not_found', 'cloud_cert_app_not_authorized', 'cloud_cert_app_expired', 'cloud_cert_sign_expired'];
        const successErrors = ['cloud_cert_sign_not_authorized', 'cloud_cert_sign_expired'];
        this.safeIdFormStep = SafeIdFormStep.waitingAuthorization;
        this.padesService
          .checkCloudCert({ fetchPolicy: 'network-only' })
          .pipe(
            retryWhen(
              mergeMap((error, i) => {
                const retryAttempt = i + 1;
                const handledError = this.errorHandlerService.handle(error, { ignoreNotificationOn: waitingErrors.concat(successErrors) });
                if (waitingErrors.includes(handledError.message) && retryAttempt === 1) {
                  this.padesService.authorizeCloudCertApp({ cpfCnpj: this.safeIdForm.get('cpfCnpj').value, type: CloudSignaturesEnum.Safeid }).subscribe(
                    () => {},
                    currentError => this.errorHandlerService.handle(currentError)
                  );
                } else if (!waitingErrors.includes(handledError.message)) {
                  return throwError(handledError);
                } else if (retryAttempt > 50 || this.safeIdFormStep !== SafeIdFormStep.waitingAuthorization) {
                  return throwError(handledError);
                }
                return timer((10 + retryAttempt) * 1000);
              })
            ),
            catchError(handledError => (successErrors.includes(handledError && handledError.message) ? of(null) : throwError(handledError))),
            takeWhile(() => this.safeIdFormStep === SafeIdFormStep.waitingAuthorization),
            untilDestroyed(this)
          )
          .subscribe(
            () => {
              this.safeIdFormStep = SafeIdFormStep.password;
              this.focusPasswordInput();
            },
            () => {
              this.safeIdFormStep = SafeIdFormStep.cpfCnpj;
              this.focusCpfCnpjInput();
            }
          );
      } else if (this.safeIdFormStep === SafeIdFormStep.password) {
        this.safeIdForm.markAllAsTouched();
        this.signPades();
      } else {
        this.safeIdFormStep = SafeIdFormStep.password;
        this.focusPasswordInput();
      }
    } else if (this.modalType === ModalType.vidaas) {
      if (this.vidaasFormStep === VidaasFormStep.cpfCnpj) {
        const waitingErrors = ['cloud_cert_not_found', 'cloud_cert_app_not_authorized', 'cloud_cert_app_expired', 'cloud_cert_sign_expired'];
        const successErrors = ['cloud_cert_sign_not_authorized', 'cloud_cert_sign_expired'];
        this.vidaasFormStep = VidaasFormStep.waitingAuthorization;
        this.padesService
          .checkVidaasAuthorization()
          .pipe(
            retryWhen(
              mergeMap((error, i) => {
                const retryAttempt = i + 1;
                const handledError = this.errorHandlerService.handle(error, { ignoreNotificationOn: waitingErrors.concat(successErrors) });
                if (waitingErrors.includes(handledError.message) && retryAttempt === 1) {
                  this.padesService.authorizeCloudCertApp({ cpfCnpj: this.vidaasForm.get('cpfCnpj').value, type: CloudSignaturesEnum.Vidaas }).subscribe(
                    () => {},
                    currentError => this.errorHandlerService.handle(currentError)
                  );
                } else if (!waitingErrors.includes(handledError.message)) {
                  return throwError(handledError);
                } else if (retryAttempt > 50 || this.vidaasFormStep !== VidaasFormStep.waitingAuthorization) {
                  return throwError(handledError);
                }
                return timer((10 + retryAttempt) * 1000);
              })
            ),
            catchError(handledError => (successErrors.includes(handledError && handledError.message) ? of(null) : throwError(handledError))),
            takeWhile(() => this.vidaasFormStep === VidaasFormStep.waitingAuthorization),
            untilDestroyed(this)
          )
          .subscribe(
            data => {
              if (data) {
                this.safeIdForm.markAllAsTouched();
                this.signPades();
              }
            },
            () => {
              this.vidaasFormStep = VidaasFormStep.cpfCnpj;
              this.focusCpfCnpjInput();
            }
          );
      }
    }
  }

  chooseOrganization() {
    const id = this.preSignForm.get('organization').value;

    const done = () => {
      if (this.document && this.document.qualified) {
        this.modalType = this.padesService.getStoredA1CertificateData(this.currentUser) ? ModalType.a1 : this.certInfo.type ? this.cloudSignatureInfo[this.certInfo.type].modalType : ModalType.preSign;
        this.signPades();
      } else {
        this.modal.close({} as PadesSignatureData);
      }
    };

    if (this.currentUser.organization && id !== this.currentUser.organization.id) {
      this.isLoading = true;
      this.preSignForm.markAllAsTouched();

      this.organizationService
        .selectDefault({ id })
        .pipe(
          switchMap(() => this.userService.getCurrentUser({ fetchPolicy: 'network-only' })),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          () => done(),
          error => this.errorHandlerService.handle(error)
        );
    } else {
      done();
    }
  }

  signPades(modalType?: ModalType) {
    modalType = modalType || this.modalType;
    const type = { [ModalType.a1]: SignatureTypesEnum.A1, [ModalType.a3]: SignatureTypesEnum.A3, [ModalType.safeId]: SignatureTypesEnum.Safeid, [ModalType.vidaas]: SignatureTypesEnum.Vidaas }[
      modalType
    ];
    const formValue = { [ModalType.a1]: this.a1Form, [ModalType.a3]: this.a3Form, [ModalType.safeId]: this.safeIdForm, [ModalType.vidaas]: this.vidaasForm }[modalType].value;

    if (type === SignatureTypesEnum.A1 && (!formValue.file || (typeof formValue.file !== 'string' && !formValue.file.type.match('application/x-pkcs')))) {
      this.a1FormStep = A1FormStep.upload;
      this.a1Form.get('file').setErrors({ server: this.translateService.instant('error_invalid_certificate_file') });
    } else {
      this.isLoading = true;
      this.padesService
        .prepareToSignPades({ type, user: this.currentUser, signatureId: this.padesSignatureId, ...formValue })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          data => this.modal.close(data as PadesSignatureData),
          error => {
            if (type === SignatureTypesEnum.Safeid) {
              if (error) {
                this.errorHandlerService.handleValidation(this.safeIdForm, error);
              }
              if (!this.safeIdForm.get('password').errors) {
                this.safeIdForm.get('password').setErrors({ server: this.translateService.instant('error_incorrect_password') });
              }
            } else if (type === SignatureTypesEnum.A3) {
              this.canSelectPkcs11Library = false;
              if (error && typeof error.message === 'string' && error.message.match(/error_pkcs11/i)) {
                if (error.message.match(/error_pkcs11_could_not_load_module/i)) {
                  this.canSelectPkcs11Library = true;
                  this.errorHandlerService.handle(error, { ignoreNotificationOn: ['error_pkcs11_could_not_load_module'] });
                } else {
                  this.focusPasswordInput();
                  this.a3Form.get('password').setErrors({ server: this.translateService.instant(error.message) });
                }
              } else {
                this.canSelectPkcs11Library = true;
                this.errorHandlerService.handle(error);
              }
            } else if (type === SignatureTypesEnum.Vidaas) {
              if (error) {
                this.errorHandlerService.handleValidation(this.vidaasForm, error);
              }
            } else {
              console.log(error && error.message);
              console.log('File: ', formValue.file && formValue.file.name, formValue.file && formValue.file.type, formValue.file && formValue.file.size);
              if (error && typeof error.message === 'string' && error.message.match(/wrong password/i)) {
                this.a1FormStep = A1FormStep.password;
                this.a1Form.get('password').setErrors({ server: this.translateService.instant('error_incorrect_password') });
                this.focusPasswordInput();
              } else if (error && typeof error.message === 'string' && (error.message.match(/failed to decrypt/i) || error.message.match(/too few bytes/i) || error.message.match(/bits supported/i))) {
                this.a1FormStep = A1FormStep.upload;
                this.a1Form.get('file').setErrors({ server: this.translateService.instant('error_corrupted_certificate', { description: error.message }) });
              } else if (error && typeof error.message === 'string' && error.message.match(/certificate expired/i)) {
                this.a1FormStep = A1FormStep.upload;
                this.a1Form.get('file').setErrors({ server: this.translateService.instant('error_expired_certificate') });
              } else {
                this.a1FormStep = A1FormStep.upload;
                this.errorHandlerService.handle(error);
              }
            }
          }
        );
    }
  }

  focusCpfCnpjInput() {
    if (this.modalType === ModalType.safeId) {
      setTimeout(() => this.safeidCpfCnpjInput && this.safeidCpfCnpjInput.nativeElement && this.safeidCpfCnpjInput.nativeElement.focus());
    }
    if (this.modalType === ModalType.vidaas) {
      setTimeout(() => this.vidaasCpfCnpjInput && this.vidaasCpfCnpjInput.nativeElement && this.vidaasCpfCnpjInput.nativeElement.focus());
    }
  }

  focusPasswordInput() {
    if (this.modalType === ModalType.a1) {
      setTimeout(() => this.a1PasswordInput && this.a1PasswordInput.nativeElement && this.a1PasswordInput.nativeElement.focus());
    } else if (this.modalType === ModalType.a3) {
      setTimeout(() => this.a3PasswordInput && this.a3PasswordInput.nativeElement && this.a3PasswordInput.nativeElement.focus());
    } else if (this.modalType === ModalType.safeId) {
      setTimeout(() => this.safeidPasswordInput && this.safeidPasswordInput.nativeElement && this.safeidPasswordInput.nativeElement.focus());
    }
  }

  desktopAppUrl() {
    return this.sanitizer.bypassSecurityTrustUrl('autnq://' + location.pathname);
  }

  downloadDesktopApp() {
    if (Bowser.getParser(window.navigator.userAgent).getOSName() === 'Windows') {
      window.open('ms-windows-store://pdp/?productid=9NG67VW1T3LK');
    } else {
      this.notyService.error(this.translateService.instant('notyService.desktopAppWindowsOnly'));
    }
  }

  selectPkcs11Lib() {
    this.padesService
      .selectPkcs11LibDialog()
      .pipe(filter(libPath => !!libPath))
      .subscribe(() => {
        this.canSelectPkcs11Library = false;
        this.padesNextStep();
      });
  }

  // Force the input element to reload.
  dispatchInputEvent(input: HTMLInputElement) {
    input?.dispatchEvent(new Event('input'));
  }
}
