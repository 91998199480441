import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User, Whitelabel } from '@app/models';
import { environment } from '@env/environment';
import { ErrorHandlerService, NotyService, WhitelabelService } from '@app/services';
import { contextMenuState } from '../configurations-whitelabel.component';
import { switchMap } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';

export enum domainNameSteps {
  default = 0,
  verification = 1
}
@Component({
  selector: 'app-whitelabel-domain-name',
  templateUrl: './whitelabel-domain-name.component.html',
  styleUrls: ['../../configurations.scss', './whitelabel-domain-name.component.scss']
})
export class WhitelabelDomainNameComponent implements OnInit {
  @Input() currentUser: User;
  @Input() whitelabelData: Whitelabel;
  @Output() contextChange: EventEmitter<contextMenuState> = new EventEmitter();
  @Output() goBack: EventEmitter<contextMenuState> = new EventEmitter();
  @ViewChild('saveButton') saveButton: ElementRef;
  @ViewChild('verifyButton') verifyButton: ElementRef;

  contextMenuState = contextMenuState;

  domainNameSteps = domainNameSteps;
  domainSteps: domainNameSteps = domainNameSteps.default;

  env = environment;

  subdomainPlaceholder: string;
  subdomainToggle: boolean = false;

  subdomainVerified: boolean = false;
  isDomainLoading: boolean = false;

  constructor(private whitelabelService: WhitelabelService, private clipboardService: ClipboardService, private notyService: NotyService, private errorHandlerService: ErrorHandlerService) {}

  ngOnInit(): void {
    if (this.whitelabelData && this.whitelabelData.domain) {
      this.subdomainToggle = true;
    }
  }

  changeContext(context: contextMenuState) {
    if (this.domainSteps === this.domainNameSteps.default) {
      this.contextChange.emit(context);
    } else {
      this.domainSteps = this.domainNameSteps.default;
    }
  }

  copyToClipboard(content: string) {
    this.clipboardService.copyFromContent(content);
    this.notyService.success('Texto copiado com sucesso!');
  }

  domainForward() {
    if (this.subdomainVerified) {
      //runs only after verifyDomain() receives true ( 'Salvar' button);
      this.submitForm();
      return;
    } // bellow runs on 'Avançar' pressing;
    this.whitelabelService.updateWhitelabelDomain({ domain: this.subdomainPlaceholder || this.whitelabelData.domain }).subscribe(
      () => {
        this.domainSteps = domainNameSteps.verification;
      },
      error => {
        this.errorHandlerService.handle(error);
      }
    );
  }

  domainForwardEmpty() {
    // only runs if user wants to deactivate Whitelabel and reset data;
    this.whitelabelService
      .updateWhitelabelDomain({ domain: '' })
      .pipe(switchMap(() => this.whitelabelService.activateWhitelabelDomain()))
      .subscribe(
        () => {
          this.domainSteps = domainNameSteps.verification;
          this.goBack.emit();
          this.notyService.success('Remoção salva com sucesso.');
        },
        error => {
          this.errorHandlerService.handle(error);
        }
      );
  }

  verifyDomain() {
    this.isDomainLoading = true;
    this.verifyButton.nativeElement.disabled = true;
    // timeout to give some user feedback
    setTimeout(() => {
      this.isDomainLoading = false;
      this.verifyButton.nativeElement.disabled = false;
    }, 3000); // method actualy starts bellow:
    this.whitelabelService.whitelabelDomainVerified({ domain: this.subdomainPlaceholder || this.whitelabelData.domain }).subscribe(
      isVerified => {
        this.subdomainVerified = isVerified;
      },
      error => {
        this.errorHandlerService.handle(error);
      }
    );
  }

  submitForm() {
    // last step, runs when 'Salvar' is pressed;
    this.saveButton.nativeElement.disabled = true;
    this.whitelabelService.activateWhitelabelDomain().subscribe(
      () => {
        this.notyService.success('Subdomínio salvo com sucesso.');
        this.goBack.emit();
        this.saveButton.nativeElement.disabled = false;
      },
      error => {
        this.errorHandlerService.handle(error);
        this.saveButton.nativeElement.disabled = false;
      }
    );
  }
}
