import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { flatten, omit } from 'lodash';

import { Logger, untilDestroyed } from '@app/core';
import { AppService, DocumentService, ErrorHandlerService, NotyService, UserService, WhitelabelService } from '@app/services';
import { CustomSignerInput, Document, FloatingField, PositionElementEnum, SignerInput, SignerMainAttributes, User } from '@app/models';
import { PdfViewerComponent } from '@app/shared';
import { DocumentFormService } from '../shared/document-form.service';
import { UniqueSignersPipe } from '../shared/unique-signers.pipe';
import { idColors } from '../shared/floating-fields-id-colors';
import { TranslateService } from '@ngx-translate/core';

interface SignerId {
  public_id: string;
}

const log = new Logger('DocumentsFieldsEditComponent');

@Component({
  selector: 'app-documents-fields-edit',
  templateUrl: './documents-fields-edit.component.html',
  styleUrls: ['./documents-fields-edit.component.scss']
})
export class DocumentsFieldsEditComponent implements OnInit, OnDestroy {
  documentId: string;
  currentUser: User;
  document: Document;
  signers: (CustomSignerInput & SignerId)[] = [];
  isLoading = false;
  isSettingFloatingFields = false;
  readonly PositionElementEnum = PositionElementEnum;
  readonly idColors = idColors;
  @ViewChild('pdfViewer', { static: false }) private pdfViewerComponent!: PdfViewerComponent;

  constructor(
    public documentService: DocumentService,
    public appService: AppService,
    public whitelabelService: WhitelabelService,
    private router: Router,
    private route: ActivatedRoute,
    private notyService: NotyService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private documentFormService: DocumentFormService,
    private uniqueSigners: UniqueSignersPipe,
    private translateService: TranslateService
  ) {
    this.documentId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.loadDocument().subscribe(data => {
      this.document = data.document;
      // @ts-ignore
      this.signers = data.signers;
      const floatingFields = flatten(
        this.signers.map((signer, index) =>
          (signer.positions || []).map(position => ({
            element: position.element,
            identification: this.uniqueSigners.identification(signer),
            idColor: this.idColors[index % idColors.length],
            position: { x: parseFloat(position.x), y: parseFloat(position.y), z: position.z, angle: position.angle }
          }))
        )
      );

      if (this.document.qualified) {
        if (this.signers.length > 0) {
          this.pdfViewerComponent.floatingFields = floatingFields.concat(this.documentService.floatingFieldsFromDocument(this.document) as any[]);
          this.pdfViewerComponent.refreshFloatingFields();
        } else {
          this.notyService.error(this.translateService.instant('notyService.cannotPositionSignatureWithActiveQualifiedSignature'), 15000);
          setTimeout(() => this.router.navigate(['/documentos/', this.document.id]));
        }
      } else {
        this.pdfViewerComponent.floatingFields = floatingFields;
        this.pdfViewerComponent.refreshFloatingFields();
      }
    });
  }

  ngOnDestroy() {}

  updateDocument() {
    this.isLoading = true;

    this.loadDocument()
      .pipe(
        map(data =>
          this.documentFormService
            .transformToSignerInputs(data.document, data.signers, this.pdfViewerComponent.getFloatingFields())
            .map((signer: SignerInput & SignerId) => ({ publicId: signer.public_id, signer: { positions: signer.positions || null } }))
            .filter(signer => {
              // Filters only if there were changes
              const originalSigner = data.signers.find(item => item.public_id === signer.publicId);
              return !originalSigner || JSON.stringify((originalSigner.positions || []).map(pos => omit(pos, ['__typename']))) !== JSON.stringify(signer.signer.positions || []);
            })
        ),
        switchMap(signers => ((signers || []).length > 0 ? this.documentService.updateSignersPositions(signers) : of(null))),
        switchMap(() => this.documentService.clearPdfCache({ id: this.document.id }))
      )
      .subscribe(
        () => this.router.navigate(['/documentos/', this.document.id]),
        error => {
          this.isLoading = false;
          const handledError = this.errorHandlerService.handle(error, { ignoreGraphqlErrorNotifications: true, validationMode: true });
          log.error(handledError.message, handledError.validation);
        }
      );
  }

  setFloatingFieldFor(signer: CustomSignerInput, element: PositionElementEnum, idColor: string) {
    this.isSettingFloatingFields = true;
    this.pdfViewerComponent
      .floatingFieldsSettingModeFor({ element, idColor, identification: this.uniqueSigners.identification(signer) })
      .pipe(finalize(() => (this.isSettingFloatingFields = false)))
      .subscribe();
  }

  disableFloatingFieldsSettingMode() {
    if (this.isSettingFloatingFields) {
      this.isSettingFloatingFields = false;
      this.pdfViewerComponent.disableFloatingFieldsSettingMode();
    }
  }

  selectFloatingFieldInput(field: FloatingField) {
    this.signers.forEach(signer => (signer._extra.isSelected = field && this.uniqueSigners.identification(signer) === field.identification));
  }

  private loadDocument() {
    return this.documentService.document({ id: this.documentId }).pipe(
      map(document => {
        const signers = document.signatures
          .filter(signer => signer.action && signer.action.name && (!document.qualified || !(signer.signed && signer.signed.created_at)))
          .map(signer => {
            const mainAttribute = signer.email ? SignerMainAttributes.Email : signer.name ? SignerMainAttributes.Name : SignerMainAttributes.Phone;
            const values = { email: signer.email, name: signer.name, phone: signer.user?.phone };
            return {
              [mainAttribute]: values[mainAttribute],
              public_id: signer.public_id,
              action: signer.action.name,
              positions: signer.positions,
              _extra: { signerMainAttribute: mainAttribute }
            };
          });

        return { document, signers };
      })
    );
  }

  @HostListener('window:keydown', ['$event']) private onKeyDown(event: KeyboardEvent) {
    if ((event.key || '').toLowerCase() === 'escape') {
      this.disableFloatingFieldsSettingMode();
    }
  }
}
