import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { filter, finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { EmailTemplates, User } from '@app/models';
import { ErrorHandlerService, NotyService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-appearance',
  templateUrl: './configurations-appearance.component.html',
  styleUrls: ['../configurations.scss', './configurations-appearance.component.scss']
})
export class ConfigurationsAppearanceComponent implements OnInit, OnDestroy {
  currentUser: User;
  emailForm: FormGroup;
  isEditingEmail = false;
  isLoading = false;

  footerText = {
    default: 'Assine documentos com o Autentique\nwww.autentique.com.br\nFeito com ❤️ no Brasil',
    enterprise: 'www.autentique.com.br\ne-mail de contato: contato@autentique.com.br\ne-mail jurídico: juridico@autentique.com.br'
  };

  readonly emailTemplates = {
    [EmailTemplates.Default]: { key: 'email_template_default', multiColor: false },
    [EmailTemplates.Classic]: { key: 'email_template_classic', multiColor: false },
    [EmailTemplates.Corporate]: { key: 'email_template_corporate', multiColor: true },
    [EmailTemplates.Clean]: { key: 'email_template_clean', multiColor: true }
  };
  readonly emailVariables = ['nome', 'empresa', 'funcao', 'documento', 'identificador'];

  constructor(
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private userService: UserService
  ) {
    this.emailForm = this.formBuilder.group({
      user: this.formBuilder.group({
        title: null,
        text: null,
        subject: null,
        sender: null,
        template: null,
        image: null,
        footer: null,
        colors: new FormArray([new FormControl('#000000'), new FormControl('#000000')])
      })
    });
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.emailForm.setValue({
          user: {
            template: this.currentUser.settings.email.template,
            subject: this.currentUser.settings.email.subject,
            sender: this.currentUser.settings.email.sender,
            title: this.currentUser.settings.email.title,
            text: this.currentUser.settings.email.text,
            colors: [this.currentUser.settings.email.colors[0] || '#3379F2', this.currentUser.settings.email.colors[1] || '#3379F2'],
            image: this.currentUser.settings.email.image,
            footer: this.currentUser.settings.email.template === EmailTemplates.Default ? this.footerText.default : this.footerText.enterprise
          }
        });
      });
  }

  ngOnDestroy() {}

  updateSignatureEmail() {
    this.emailForm.markAllAsTouched();
    this.isLoading = true;

    if (typeof this.emailForm.get('user.image').value === 'string') {
      this.emailForm.get('user.image').setValue(undefined);
    }

    if (
      !this.emailForm.get('user.colors').value[0] ||
      (!this.emailForm.get('user.colors').value[1] && [EmailTemplates.Corporate, EmailTemplates.Clean].includes(this.emailForm.get('user.template').value))
    ) {
      this.notyService.error(this.translateService.instant('notyService.hexadecimalColorError'));
      this.isLoading = false;
      return;
    }

    this.userService
      .updateCurrentUserSignatureEmail(this.emailForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => (this.isEditingEmail = false),
        error => this.errorHandlerService.handleValidation(this.emailForm, error)
      );
  }

  selectEmailImage(image: File) {
    if (image) {
      this.emailForm.get('user.image').setValue(image);
    }
  }

  addEmailVariableTo(variable: string, input: FormControl) {
    input.setValue((input.value ? input.value + ' ' : '') + `{{${variable}}}`);
  }

  allowedToEditEmailAppearence() {
    return this.currentUser && (!this.currentUser.organization || !this.currentUser.member.group.styles.overwrite_email);
  }

  changeFooter(value: any) {
    this.emailForm.patchValue({
      user: {
        footer: value === EmailTemplates.Default ? this.footerText.default : this.footerText.enterprise
      }
    });
  }
}
