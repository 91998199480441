import { Pipe, PipeTransform } from '@angular/core';

/**
 * Shortens the name provided by returning only the first name and the last letter from the surname
 */
@Pipe({ name: 'signatureNewStyleName' })
export class SignatureNewStyleNamePipe implements PipeTransform {
  constructor() {}

  transform(name: string): string {
    if (!name) {
      return '';
    }

    if (name.length > 30) {
      const names = name.split(' ');
      const middleNames: string[] = [];
      const firstName = names.shift();
      const lastName = names.pop();

      names.forEach(value => {
        middleNames.push(value.length > 3 ? value.slice(0, 1) + '.' : value);
      });

      return firstName + ' ' + middleNames.join(' ') + ' ' + lastName;
    } else {
      return name;
    }
  }
}
