<div class="group">
  <div class="group__title">{{ 'menu.organizationFolders' | translate }}</div>
  <div class="group__options">
    <a class="group__option" (click)="openCreateFolderModal()" *ngIf="currentUser?.currentPermissions.view_folders_oz">
      <div class="group__option-icon"><i class="fal fa-folder-plus"></i></div>
      <div class="group__option-name">{{ 'menu.newFolder' | translate }}</div>
    </a>
    <a routerLink="/pastas" class="group__option">
      <div class="group__option-icon"><i class="fal fa-folder-open"></i></div>
      <div class="group__option-name">{{ 'menu.myFolders' | translate }}</div>
    </a>
    <a routerLink="/pastas/grupo" class="group__option" *ngIf="currentUser?.currentPermissions.view_folders_gr">
      <div class="group__option-icon"><i class="fal fa-users"></i></div>
      <div class="group__option-name">{{ 'menu.groupFolders' | translate }}</div>
    </a>
    <a routerLink="/pastas/organizacao" class="group__option" *ngIf="currentUser?.currentPermissions.view_folders_oz">
      <div class="group__option-icon"><i class="fal fa-users"></i></div>
      <div class="group__option-name">{{ 'menu.organizationFolders' | translate }}</div>
    </a>
  </div>
</div>

<div class="group">
  <div class="group__title">{{ 'menu.others' | translate }}</div>
  <div class="group__options">
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-file-alt"></i></div>
      <div class="group__option-name">{{ 'menu.myDocuments' | translate }}</div>
    </a>
    <a routerLink="/menu/configuracoes" class="group__option">
      <div class="group__option-icon"><i class="fal fa-cog"></i></div>
      <div class="group__option-name">{{ 'menu.settings' | translate }}</div>
    </a>
    <a routerLink="/documentos/lixeira" class="group__option">
      <div class="group__option-icon"><i class="fal fa-trash"></i></div>
      <div class="group__option-name">{{ 'menu.trash' | translate }}</div>
    </a>
    <app-menu-mobile-sign-out></app-menu-mobile-sign-out>
  </div>
</div>

<!--
<div class="group" *ngIf="currentUser?.organization?.members?.length > 1">
  <div class="group__title">Pastas dos membros</div>
  <div class="group__options">
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">Daniel Didone</div>
    </a>
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">Ronaldo Jorge</div>
    </a>
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">Cassiano Calegari</div>
    </a>

    <a
      class="group__option"
      *ngFor="let member of currentUser.organization.members | slice: 0:10"
      [routerLink]="['/pastas/organizacao', member.user?.id]"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="current"
    >
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">{{ member.user?.name }}</div>
    </a>
  </div>
</div>
-->
