import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appValidEmail]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidEmailDirective, multi: true }]
})
export class ValidEmailDirective implements Validator {
  @Input('appValidEmail') isActive = true;

  static validator(control: AbstractControl, isActive: boolean = true): ValidationErrors | null {
    return (typeof isActive === 'boolean' && !isActive) || !control.value || (typeof control.value === 'string' && ValidEmailDirective.isEmailValid(control.value)) ? null : { appValidEmail: true };
  }

  static isEmailValid(email: string) {
    const parsedEmail = String(email || '').trim();
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const specialCharsRegex = /[^a-zA-Z0-9_/\-@\.+$]/;
    return !parsedEmail || (parsedEmail.match(emailRegex) && !parsedEmail.match(specialCharsRegex));
  }

  constructor() {}

  validate(control: AbstractControl) {
    return ValidEmailDirective.validator(control, this.isActive);
  }
}
