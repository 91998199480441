import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { delay, finalize, switchMap } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { SlimOrganization, User } from '@app/models';
import { AppService, UserService, AuthenticationService, OrganizationService, WhitelabelService } from '@app/services';
import { AppDropdown } from '@app/shared/dropdown/dropdown';
import { LoaderService } from '@app/shared/loader/loader.service';
import { CreateOrganizationModalService } from '@app/shared/create-organization-modal/create-organization-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: User;
  organizations: SlimOrganization[] = [];
  isOnFoldersListing: boolean;
  @ViewChild(AppDropdown, { static: false }) dropdown: AppDropdown;

  constructor(
    public appService: AppService,
    public userService: UserService,
    public router: Router,
    public translateService: TranslateService,
    public whitelabelService: WhitelabelService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private organizationService: OrganizationService,
    private createOrganizationModalService: CreateOrganizationModalService
  ) {
    this.isOnFoldersListing = !!location.pathname.match('^/pastas');
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.currentUser = user;
        this.whitelabelService.loadWhitelabelByDomain();
      });

    this.organizationService
      .watchOrganizations()
      .pipe(untilDestroyed(this))
      .subscribe(organizations => (this.organizations = organizations));
  }

  ngOnDestroy() {}

  openCreateOrganizationModal() {
    this.createOrganizationModalService.open().subscribe(() => {
      from(this.router.navigate(['/'])).subscribe(() => {
        this.userService.getCurrentUser({ fetchPolicy: 'network-only' }).subscribe();
        this.organizationService.getOrganizations({ fetchPolicy: 'network-only' }).subscribe();
      });
    });
  }

  selectDefaultOrganization(id: number) {
    if (!this.currentUser.organization || id !== this.currentUser.organization.id) {
      this.loaderService.show();
      this.organizationService.selectDefault({ id }).subscribe(
        () => {
          from(this.router.navigate(['/']))
            .pipe(
              switchMap(() => this.userService.getCurrentUser({ fetchPolicy: 'network-only' })),
              delay(2000),
              finalize(() => this.loaderService.hide())
            )
            .subscribe();
        },
        () => this.loaderService.hide()
      );
    }
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true }));
  }

  isWhitelabel() {
    // return true;
    return !this.appService.isAutentiqueDomain() && !!this.whitelabelService?.whitelabel?.domain;
  }
}
