import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

declare const google: any;
interface GoogleOauth {
  credential: string;
  content: { email: string; email_verified: boolean; name: string };
}

@Component({
  selector: 'app-google-oauth',
  templateUrl: './google-oauth.component.html',
  styleUrls: ['./google-oauth.component.scss']
})
export class GoogleOauthComponent implements AfterContentInit {
  @Input() context: string;
  @Input() uxMode: string;
  @Input() type: string;
  @Input() theme: string;
  @Input() shape: string;
  @Input() text: string;
  @Input() size: number;
  @Input() width: string;
  @Input() logoAlignment: string;
  @Output() callback = new EventEmitter<GoogleOauth>();

  constructor(private elementRef: ElementRef<HTMLElement>, private translateService: TranslateService) {}

  ngAfterContentInit() {
    const interval = setInterval(() => {
      if (typeof google !== 'undefined') {
        clearInterval(interval);
        this.loadGoogleOauth();
      }
    }, 200);
  }

  loadGoogleOauth() {
    google.accounts.id.initialize({
      client_id: environment.oauthGoogle,
      context: this.context,
      ux_mode: this.uxMode,
      callback: (response: any) =>
        this.callback.emit({
          credential: response.credential,
          content: JSON.parse(
            atob(
              response.credential
                .split('.')[1]
                .replace(/-/g, '+')
                .replace(/_/g, '/')
            )
          )
        })
    });

    google.accounts.id.renderButton(this.elementRef.nativeElement, {
      type: this.type,
      theme: this.theme,
      shape: this.shape,
      text: this.text,
      size: this.size,
      width: parseFloat(this.width),
      locale: this.translateService.currentLang || 'pt-BR',
      logo_alignment: this.logoAlignment
    });
  }
}
