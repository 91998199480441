import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn, Validator } from '@angular/forms';

@Directive({
  selector: '[appExistingNames]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ExistingNamesDirective, multi: true }]
})
export class ExistingNamesDirective implements Validator {
  @Input('appExistingNames') existingNames: string[] = [];

  static validator(existingNames: string[]): ValidatorFn | null {
    return (control: AbstractControl) => {
      return control.value && typeof control.value === 'string' && ExistingNamesDirective.exists(control.value, existingNames) ? { appExistingNames: true } : null;
    };
  }

  static exists(name: string, existingNames: string[]) {
    return existingNames.map(existingName => ExistingNamesDirective.parseName(existingName)).includes(ExistingNamesDirective.parseName(name));
  }

  static parseName(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/\s+/, ' ');
  }

  constructor() {}

  validate(control: AbstractControl) {
    return ExistingNamesDirective.validator(this.existingNames)(control);
  }
}
