<app-header *ngIf="whitelabelService.params.tab"></app-header>
<div class="d-flex">
  <app-taxonomy *ngIf="whitelabelService.params.sidebar" mainTitle="{{ 'menu.settings' | translate }}">
    <a *ngIf="whitelabelService.params.configPersonal" routerLink="/perfil" routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" class="taxonomy-option"
      ><i class="fal fa-user"></i> {{ 'profile.personalInfo' | translate }}</a
    >
    <a *ngIf="whitelabelService.params.configAppearance" routerLink="/perfil/aparencia" routerLinkActive="current" class="taxonomy-option"
      ><i class="fal fa-paint-brush-alt"></i> {{ 'menu.appearance' | translate }}</a
    >
    <a *ngIf="whitelabelService.params.configNotifications" routerLink="/perfil/notificacoes" routerLinkActive="current" class="taxonomy-option"
      ><i class="fal fa-bell"></i> {{ 'menu.notification' | translate }}</a
    >

    <div *ngIf="whitelabelService.params.configOrganization" class="taxonomy-separator">{{ 'menu.organization' | translate }}</div>
    <a *ngIf="whitelabelService.params.configGeneral" routerLink="/perfil/organizacao" routerLinkActive="current" class="taxonomy-option"
      ><i class="fal fa-cog"></i> {{ 'menu.settingsGeneral' | translate }}</a
    >
    <a *ngIf="whitelabelService.params.configPlans" routerLink="/perfil/planos" routerLinkActive="current" class="taxonomy-option"
      ><i class="fal fa-credit-card"></i> {{ 'menu.plansPayment' | translate }}</a
    >
    <a *ngIf="whitelabelService.params.configEmails" routerLink="/perfil/emails" routerLinkActive="current" class="taxonomy-option"><i class="fal fa-envelope"></i> {{ 'menu.emails' | translate }}</a>
    <a *ngIf="whitelabelService.params.configMembers" routerLink="/perfil/membros" routerLinkActive="current" class="taxonomy-option"><i class="fal fa-users"></i> {{ 'menu.members' | translate }}</a>
    <a *ngIf="whitelabelService.params.configGroups" routerLink="/perfil/grupos" routerLinkActive="current" class="taxonomy-option"
      ><i class="fal fa-users-class"></i> {{ 'menu.groups' | translate }}</a
    >
    <a *ngIf="whitelabelService.params.configWebhooks" routerLink="/perfil/webhooks" routerLinkActive="current" class="taxonomy-option"
      ><i class="fal fa-brackets-curly"></i> {{ 'menu.webhooks' | translate }}</a
    >
    <a
      routerLink="/perfil/personalizacao"
      *ngIf="currentUser?.currentPermissions.change_whitelabel_oz && whitelabelService.params.configCustomization"
      routerLinkActive="current"
      class="taxonomy-option"
      ><i class="fal fa-palette"></i> {{ 'menu.customization' | translate }}</a
    >

    <div *ngIf="whitelabelService.params.configDeveloper" class="taxonomy-separator">{{ 'menu.developer' | translate }}</div>
    <a *ngIf="whitelabelService.params.configApi" routerLink="/perfil/api" routerLinkActive="current" class="taxonomy-option"><i class="fal fa-key"></i> {{ 'menu.accessApi' | translate }}</a>

    <ng-container *ngIf="currentUser?.created_at < '2021-02'">
      <div *ngIf="whitelabelService.params.configOther" class="taxonomy-separator">{{ 'menu.other' | translate }}</div>
      <a *ngIf="whitelabelService.params.configReferal" routerLink="/perfil/indicacoes" routerLinkActive="current" class="taxonomy-option"
        ><i class="fal fa-heart"></i> {{ 'menu.indications' | translate }}</a
      >
    </ng-container>
  </app-taxonomy>
</div>
<main class="configuration">
  <div
    routerLink="/perfil/planos"
    class="configuration-plans-banner"
    *ngIf="showPlanBannerOn(['/perfil/aparencia', '/perfil/emails']) && currentUser && !currentUser.subscription?.has_premium_features"
  >
    <i class="fal fa-arrow-circle-up"></i> {{ 'menu.customizeEmailAppearanceWithCorporatePlan' | translate }}
  </div>
  <div routerLink="/perfil/planos" class="configuration-plans-banner" *ngIf="showPlanBannerOn(['/perfil/personalizacao']) && currentUser && !currentUser.subscription?.has_premium_features">
    <i class="fal fa-arrow-circle-up"></i> {{ 'menu.subscribeToWhitelabelPlan' | translate }}
  </div>

  <router-outlet></router-outlet>
</main>
