import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clone } from 'lodash';

import { Folder } from '@app/models';

@Component({
  selector: 'app-folders-list-boxes',
  templateUrl: './folders-list-boxes.component.html',
  styleUrls: ['./folders-list-boxes.component.scss']
})
export class FoldersListBoxesComponent implements OnInit {
  @Input() folders: Folder[];
  @Input() linkPath: string[];
  @Input() hideDropdownMenu: boolean;
  @Input() hidePagination: boolean;
  @Output() folderClick = new EventEmitter<Folder>();
  @Output() updateClick = new EventEmitter<Folder>();
  @Output() deleteClick = new EventEmitter<Folder>();
  @Output() loadPageClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  insertIdOnPath(path: string[], id: string) {
    const index = path.findIndex(part => part === ':id');
    const newPath = clone(path);
    if (index >= 0) {
      newPath[index] = id;
    }
    return newPath;
  }
}
