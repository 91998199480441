<div class="modal-body" [class.restoration]="isRestoration">
  <p class="centermodal__title">{{ 'shared.attention' | translate }}</p>
  <img src="/assets/alerts/warning.svg" class="centermodal__img" />
  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <div class="centermodal__message">
      <p>{{ text }}</p>
      <div class="font-weight-bolder mb-2">
        {{ 'shared.' + (isRestoration ? 'whatHappensWhenRestoring' : 'whatHappensWhenDeletingDocument') | translate }}
      </div>
      <div class="explanation-list" *ngIf="!isRestoration">
        <div [innerHTML]="'shared.documentWillBeSentToTrash' | translate"></div>
        <div [innerHTML]="'shared.signersWhoHaveAlreadySigned' | translate"></div>
        <div [innerHTML]="'shared.deletingDocumentDoesNotPreventSigning' | translate"></div>
        <div [innerHTML]="'shared.blockingAndDeletingDocumentPreventsSigningAndAccess' | translate"></div>
      </div>
      <div class="explanation-list" *ngIf="isRestoration">
        <div [innerHTML]="'shared.documentWillBeRemovedFromTrashAndRestored' | translate"></div>
        <div *ngIf="showExtraBtn" [innerHTML]="'shared.pendingSignersCanOnlyAccessAndSignIfUnblocked' | translate"></div>
      </div>
    </div>
  </div>
  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss(AlertButtonType.close)">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next-extra" (click)="modal.close(AlertButtonType.confirmExtra)" *ngIf="showExtraBtn">
      {{ 'button.' + (isRestoration ? 'unlockAndRestore' : 'lockAndDelete') | translate }}
    </button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [class.ml-2]="showExtraBtn" (click)="modal.close(AlertButtonType.confirm)">
      {{ 'button.' + (isRestoration ? 'restore' : 'exclude') | translate }}
    </button>
  </div>
</div>
