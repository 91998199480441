import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, UserService } from '@app/services';

@Component({
  selector: 'app-configurations-notification',
  templateUrl: './configurations-notification.component.html',
  styleUrls: ['../configurations.scss', './configurations-notification.component.scss']
})
export class ConfigurationsNotificationComponent implements OnInit {
  notificationForm: FormGroup;
  isLoading = false;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private errorHandlerService: ErrorHandlerService) {}

  ngOnInit() {
    this.userService.notificationsInfo().subscribe(notificationInfo => {
      this.notificationForm = this.formBuilder.group({
        notification: this.formBuilder.group({ signature_you: !!notificationInfo.signature_you, signature_others: !!notificationInfo.signature_others })
      });
    });
  }

  updateNotificationsInfo() {
    this.notificationForm.markAllAsTouched();
    this.isLoading = true;

    this.userService
      .updateNotificationsInfo(this.notificationForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {},
        error => this.errorHandlerService.handle(error)
      );
  }
}
