import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalService } from '@app/core/modal.service';
import { ModalResult, VersionUpdateModalComponent as ModalComponent } from './version-update-modal.component';

@Injectable({ providedIn: 'root' })
export class VersionUpdateModalService {
  constructor(private modalService: ModalService) {}

  open(options?: NgbModalOptions) {
    return this.modalService.open<ModalResult>(ModalComponent, null, options);
  }
}
