<form class="modal-body mb-0" [formGroup]="form" (ngSubmit)="updateSecurityVerificationMutation()" novalidate *ngIf="!verifyPhoneExisting">
  <p class="centermodal__title">{{ 'documents.smsVerification' | translate }}</p>
  <p class="centermodal__description">{{ 'documents.enterPhoneNumber' | translate }}</p>

  <div class="centermodal__form">
    <label class="field__holder">
      <app-phone-number inputClass="field" formControlName="phoneNumber" name="phoneNumber" autocomplete="tel-national" required></app-phone-number>
      <span class="field__placeholder">{{ 'documents.yourPhoneNumber' | translate }}</span>
      <small [hidden]="!form.get('phoneNumber').value || !form.get('phoneNumber').hasError('phoneError')" class="text-danger">{{ 'error_invalid_phone_number' | translate }}</small>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.forward' | translate }}</button>
  </div>
</form>

<form class="modal-body mb-0" (ngSubmit)="submitToken()" *ngIf="verifyPhoneExisting" novalidate>
  <p class="centermodal__title">{{ 'documents.confirmPhoneNumber' | translate }}</p>
  <p class="centermodal__description" [innerHTML]="'documents.smsSent' | translate: { phone: verification?.verify_phone || form.get('phoneNumber').value }"></p>

  <app-token-field class="token" [class.invalid]="isInvalidToken" (tokenChange)="token = $event" (submit)="submitToken()"></app-token-field>

  <div class="tokenField">
    <p class="tokenField__alert" *ngIf="isInvalidToken"><i class="tokenField__alert-icon"></i> {{ 'documents.incorrectCode' | translate }}</p>
    <div class="tokenField__resend">
      <span class="tokenField__resend-text">{{ 'documents.didntReceiveCode' | translate }}</span>
      <button
        type="button"
        class="tokenField__resend-button btn btn-link btnAnimatedBackground"
        (click)="sendSecurityCode(SecurityCodeType.Sms); timeOutForWhatsappVerification()"
        [disabled]="isVerifyingToken"
      >
        {{ 'documents.resendCode' | translate }}
      </button>
    </div>
    <div class="tokenField__resend-text whatsDiv">
      <span class="tokenField__resend-text">{{ 'documents.orTryWhatsappIn' | translate }}</span>
      <button
        type="button"
        class="btn btn-link whatsDiv__btn btnAnimatedBackground"
        [ngClass]="{ whatsAppColor: !whatsAppTimer.isCountdownInProgress() }"
        [disabled]="isLoading || whatsAppTimer.isCountdownInProgress()"
        (click)="sendSecurityCode(SecurityCodeType.Whatsapp); timeOutForWhatsappVerification()"
      >
        <span class="whatsDiv__whatsapp"> <i class="fab fa-whatsapp"></i> WhatsApp </span>
        &nbsp;{{ (whatsAppTimer.isCountdownInProgress() ? 'documents.in' : 'documents.clickingHere') | translate }}
        <div>&nbsp;{{ whatsAppTimer.isCountdownInProgress() ? formattedTime : '' }}</div>
      </button>
    </div>
  </div>
  <div class="centermodal__button-group mt-3">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || isInvalidToken">{{ 'button.forward' | translate }}</button>
  </div>
</form>
