<form class="modal-body" [formGroup]="form" (ngSubmit)="updatePassword()" novalidate>
  <p class="centermodal__title">{{ 'settings.changeAccessData' | translate }}</p>

  <div class="centermodal__form" formGroupName="newPasswordInfo">
    <div class="centermodal__group-title">{{ 'profile.personalData' | translate }}</div>

    <label class="field__holder full" [appFormControlWrap]="form.get('newPasswordInfo.current')">
      <input type="password" class="field" formControlName="current" name="current" autocomplete="current-password" required minlength="8" appShowPassword data-sentry-mask #passwordInput />
      <span class="field__placeholder">{{ 'settings.passwordCurrent' | translate }}</span>
    </label>
    <label class="field__holder full" [appFormControlWrap]="form.get('newPasswordInfo.new')">
      <input type="password" class="field" formControlName="new" name="new" autocomplete="new-password" required minlength="8" appShowPassword data-sentry-mask />
      <span class="field__placeholder">{{ 'settings.passwordNew' | translate }}</span>
    </label>
    <label class="field__holder full mb-0" [appFormControlWrap]="form.get('newPasswordInfo.new_confirmation')">
      <input type="password" class="field" formControlName="new_confirmation" name="new_confirmation" autocomplete="new-password" required minlength="8" appShowPassword data-sentry-mask />
      <span class="field__placeholder">{{ 'settings.passwordConfirm' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.save' | translate }}</button>
  </div>
</form>
