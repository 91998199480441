<div class="outer-wrapper">
  <div class="inner-wrapper">
    <div class="lifecycle-icon"><i class="fal fa-boxes-alt fa-3x"></i></div>
    <div class="lifecycle-title">{{ 'shared.documentSaved' | translate }}</div>
    <div class="lifecycle-body">
      <p>
        {{ 'shared.documentStoredLongTerm' | translate }} <a href="javascript:void(0)">{{ 'shared.learnMore' | translate }}</a>
      </p>
      <p>{{ 'shared.youCanRestoreIt' | translate }}</p>
    </div>
    <div class="lifecycle-button">
      <button class="btn btn-primary" type="button" (click)="openRestoreLifecycleModal()">
        {{ 'shared.restoreDocument' | translate }}
        <span class="credits-badge" *ngIf="creditAmount() > 0"><i class="fal fa-shield-check"></i> {{ creditAmount() }}</span>
      </button>
    </div>
  </div>
</div>
