import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, finalize } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/core';
import { AppService, DocumentService, ErrorHandlerService, NotyService, UserService } from '@app/services';
import { CustomSignerInput, Signer, SignerInput, SecurityVerificationEnum, CreditTypeEnum, SignerMainAttributes, User, DeliveryMethodEnum, Document } from '@app/models';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = Signer;
export interface ModalPublicProperties {
  document: Document;
}

@Component({
  selector: 'app-create-signer-modal',
  templateUrl: './create-signer-modal.component.html',
  styleUrls: ['./create-signer-modal.component.scss', '../../documents-new/documents-new.component.scss']
})
export class CreateSignerModalComponent implements ModalPublicProperties, AfterContentInit, OnDestroy {
  @Input() document: Document;
  form: FormGroup;
  currentUser: User;
  isLoading = false;
  signer: CustomSignerInput;
  validationErrors: { [k: string]: any } = {};
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  readonly CreditTypeEnum = CreditTypeEnum;
  constructor(
    public modal: NgbActiveModal,
    public appService: AppService,
    public documentService: DocumentService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private notyService: NotyService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.form = this.formBuilder.group({});
    this.signer = {
      email: '',
      action: this.documentService.roles[0],
      configs: {},
      _extra: { signerMainAttribute: SignerMainAttributes.Email, phoneNumber: '', documentType: SecurityVerificationEnum.Manual },
      security_verifications: []
    };
  }

  ngAfterContentInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => (this.currentUser = user));

    setTimeout(() => (document.querySelector('app-create-signer-modal app-signer-input input') as HTMLInputElement).focus());
  }

  ngOnDestroy() {}

  create(signer: CustomSignerInput) {
    this.isLoading = true;
    this.validationErrors = {};
    this.documentService
      .createSigner({ documentId: this.document.id, signer: this.prepareSigner(signer) })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => (this.validationErrors = this.errorHandlerService.handleValidation(this.form, error, { ignoreGraphqlErrorNotifications: true }).validation || {})
      );
  }

  onSignerChange() {
    this.validationErrors = {};
  }

  checkPremiumFeatures(event: MouseEvent) {
    if (!this.currentUser || !this.currentUser.subscription) {
      event.preventDefault();
    } else if (!this.currentUser.subscription.has_premium_features) {
      event.preventDefault();
      this.notyService.error(this.translateService.instant('notyService.notAvailableFreePlan'));
    }
  }

  private prepareSigner(signer: CustomSignerInput): SignerInput {
    const newSigner = cloneDeep(signer);
    if (newSigner && newSigner._extra) {
      if (newSigner.configs && !newSigner._extra.showCpfVerification) {
        newSigner.configs.cpf = '';
      }
      if (newSigner._extra.showSmsVerification && [SignerMainAttributes.Email, SignerMainAttributes.Name].includes(newSigner._extra.signerMainAttribute)) {
        newSigner.security_verifications.push({
          type: SecurityVerificationEnum.Sms,
          verify_phone: newSigner._extra.phoneNumber ? newSigner._extra.phoneNumber.replace(/^[+]/, '') : null
        });
      }
      if (newSigner._extra.showSecurityVerifications && newSigner._extra.documentType) {
        newSigner.security_verifications.push({
          type: newSigner._extra.documentType as SecurityVerificationEnum
        });
      }
    }

    if ([SignerMainAttributes.Sms, SignerMainAttributes.Whatsapp].includes(newSigner._extra.signerMainAttribute)) {
      delete newSigner.name;
      delete newSigner.email;

      newSigner.phone = newSigner?.sms || newSigner?.whatsapp;

      delete newSigner.sms;
      delete newSigner.whatsapp;
    }

    if ([SignerMainAttributes.Email].includes(newSigner._extra.signerMainAttribute)) newSigner.delivery_method = DeliveryMethodEnum.DeliveryMethodEmail;
    if ([SignerMainAttributes.Name].includes(newSigner._extra.signerMainAttribute)) newSigner.delivery_method = DeliveryMethodEnum.DeliveryMethodLink;
    if ([SignerMainAttributes.Sms].includes(newSigner._extra.signerMainAttribute)) newSigner.delivery_method = DeliveryMethodEnum.DeliveryMethodSms;
    if ([SignerMainAttributes.Whatsapp].includes(newSigner._extra.signerMainAttribute)) newSigner.delivery_method = DeliveryMethodEnum.DeliveryMethodWhatsapp;

    delete newSigner._extra;
    if (!newSigner.security_verifications) delete newSigner.security_verifications;

    return newSigner;
  }
}
