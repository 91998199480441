import { AfterContentInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';

import { untilDestroyed } from '@app/core';
import { TranslateService } from '@ngx-translate/core';

enum PasswordInputTypes {
  Password = 'password',
  Text = 'text'
}
enum PasswordVisibilityCss {
  Show = 'inline',
  Hide = 'none'
}

@Directive({
  selector: '[appShowPassword]'
})
export class ShowPasswordDirective implements AfterContentInit, OnDestroy {
  private readonly passwordElement: HTMLInputElement;
  private readonly actionElement: HTMLAnchorElement;

  constructor(private elementRef: ElementRef, private ngControl: NgControl, private translateService: TranslateService) {
    this.passwordElement = this.elementRef.nativeElement;
    this.actionElement = document.createElement('a');
    this.actionElement.classList.add('show-password-action');
    this.actionElement.href = 'javascript:void(0)';
    this.actionElement.tabIndex = -1;
    this.actionElement.style.display = PasswordVisibilityCss.Hide;
    this.actionElement.textContent = this.translateService.instant('button.show');
    this.actionElement.addEventListener('click', this.togglePasswordVisibility.bind(this));
  }

  ngAfterContentInit() {
    const parentElement = this.passwordElement.parentElement;
    parentElement.appendChild(this.actionElement);

    this.checkValue(this.ngControl.value);
    this.ngControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.checkValue(value));
  }

  ngOnDestroy() {}

  togglePasswordVisibility(event?: any, options: { focus: boolean } = { focus: true }) {
    const isHidden = this.passwordElement.type === PasswordInputTypes.Password;
    this.passwordElement.type = isHidden ? PasswordInputTypes.Text : PasswordInputTypes.Password;
    this.actionElement.textContent = isHidden ? this.translateService.instant('button.hide') : this.translateService.instant('button.show');
    if (options.focus) {
      this.passwordElement.focus();
    }
  }

  private checkValue(inputValue: any) {
    this.actionElement.style.display = inputValue ? PasswordVisibilityCss.Show : PasswordVisibilityCss.Hide;
    if (!inputValue && this.passwordElement.type !== PasswordInputTypes.Password) {
      this.togglePasswordVisibility(null, { focus: false });
    }
  }
}
