import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
// import { MaskDirective } from 'ngx-mask';

import {
  ExistingNamesDirective,
  ForbiddenNamesDirective,
  FullNameDirective,
  ValidEmailDirective,
  ValidNameDirective,
  MatchCpfDirective,
  ValidCpfDirective,
  ValidCnpjDirective,
  ValidPhoneDirective,
  ValidEmailPhoneDirective
} from '@app/shared/validators';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private frontendValidations = {
    required: ['error_field_required', Validators.required],
    email: ['error_must_be_a_valid_email_address', Validators.email],
    minlength: ['error_must_be_at_least', Validators.minLength, { min: 'requiredLength' }],
    pattern: ['error_format_is_invalid', Validators.pattern],
    mask: ['error_format_is_invalid', () => {}], // TODO: Validação vem de plugin externo (ngx-mask), algum dia fazer isso funcionar
    appFullName: ['error_incomplete_name', FullNameDirective.validator],
    appValidName: ['error_invalid_name', ValidNameDirective.validator],
    appValidEmail: ['error_invalid_email', ValidEmailDirective.validator],
    appValidCpf: ['error_invalid_cpf', ValidCpfDirective.validator],
    appValidCnpj: ['error_invalid_cnpj', ValidCnpjDirective.validator],
    appValidPhone: ['error_invalid_phone_number', ValidPhoneDirective.validator],
    appValidEmailPhone: ['error_invalid_email_phone_number', ValidEmailPhoneDirective.validator],
    appForbiddenNames: ['error_invalid_name', ForbiddenNamesDirective.validator],
    appExistingNames: ['error_existing_name', ExistingNamesDirective.validator],
    appMatchCpf: ['error_incorrect_cpf', MatchCpfDirective.validator]
  };

  constructor(private translateService: TranslateService) {}

  validate(value: any, validations: string[], validationsParams?: { [attr: string]: any[] }): ValidationErrors | null {
    validationsParams = validationsParams || {};
    if (validations.length === 0) {
      return null;
    }

    const control = new FormControl(
      value,
      validations
        .map(validation =>
          validationsParams[validation] && this.frontendValidations[validation]
            ? this.frontendValidations[validation][1].bind(null, ...[new FormControl(value)].concat(validationsParams[validation] || []))
            : this.frontendValidations[validation]
            ? this.frontendValidations[validation][1]
            : undefined
        )
        .filter(validation => !!validation)
    );
    return Object.keys(control.errors || {}).length > 0 ? control.errors : null;
  }

  getErrorMessage(error: ValidationErrors | string) {
    const validation = Object.keys(this.frontendValidations).find(key => !!error && (typeof error === 'string' ? error === key : !!error[key]));
    const params = {};
    if (typeof error !== 'string' && validation && this.frontendValidations[validation] && this.frontendValidations[validation][2]) {
      const paramsMap = this.frontendValidations[validation][2];
      const errorValue = error[validation];
      Object.keys(paramsMap).forEach(key => (params[key] = errorValue[paramsMap[key]]));
    }
    return validation ? this.translateService.instant(this.frontendValidations[validation][0], params) : error && error !== 'string' ? (error as ValidationErrors).server : error;
  }
}
