<div class="pdf-wrapper">
  <div class="pdf-container" [class.position-setting-mode]="!!floatingFieldSubscriber" [class.d-none]="renderOnIframe" #pdfContainer></div>
  <app-floating-fields
    [pdfContainer]="pdfContainer"
    [enableEdit]="enableEditFloatingFields"
    [scale]="floatingFieldsScale"
    [pagesCount]="pdf?.numPages"
    [qualified]="qualified"
    [isNewSignatureStyle]="isNewSignatureStyle"
    [documentWhitelabel]="documentWhitelabel"
    [documentLocale]="document?.locale"
    [class.d-none]="renderOnIframe"
    (selectChange)="selectedFieldChange.emit($event)"
    [isDocumentWhitelabelLoaded]="isDocumentWhitelabelLoaded"
    #floatingFields
  >
  </app-floating-fields>
  <app-footer-preview
    [pagesCount]="pdf?.numPages"
    [loadedPages]="loadedPages"
    [pdfContainer]="pdfContainer"
    [class.d-none]="renderOnIframe"
    [documentFooter]="documentFooter"
    [qualified]="qualified"
    [document]="document"
    [documentWhitelabel]="documentWhitelabel"
    [isDocumentWhitelabelLoaded]="isDocumentWhitelabelLoaded"
    *ngIf="documentFooter"
  >
  </app-footer-preview>
  <iframe [src]="safePdfUrl" frameborder="0" width="100%" height="1300px" [class.d-none]="!renderOnIframe" *ngIf="safePdfUrl && renderOnIframe !== undefined"></iframe>
</div>

<app-document-loader class="mt-3" [class.d-none]="pdf?.numPages === pdfContainer.childElementCount"></app-document-loader>
