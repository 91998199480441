<div class="selection-box" *ngIf="documents && (documents | filterBy: { _isSelected: true }).length > 0">
  <h5 class="mb-4">
    <ng-container *ngIf="(documents | filterBy: { _isSelected: true }).length">
      {{
        'documents.' + ((documents | filterBy: { _isSelected: true }).length === 1 ? 'selectedDocument' : 'selectedDocuments')
          | translate: { documents: (documents | filterBy: { _isSelected: true }).length }
      }}
    </ng-container>
  </h5>

  <ng-container *ngIf="!isTrash">
    <ng-container *ngIf="(documents | filterBy: { _isSelected: true }).length">
      <button type="button" class="btn btn-sm btn-primary mr-1" (click)="signClick.emit(filterSelectedDocuments())" *ngIf="listingService.isAllowedToSign()">{{ 'button.sign' | translate }}</button>
      <button type="button" class="btn btn-sm btn-outline-primary mr-1" (click)="folderClick.emit(filterSelectedDocuments())" *ngIf="listingService.isAllowedToArchive()">
        {{ 'button.archive' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-primary mr-1"
        (click)="transferClick.emit(filterSelectedDocuments())"
        *ngIf="listingService.isAllowedToManage() && !appService.isEnterpriseChild"
      >
        {{ 'button.transfer' | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-outline-danger" (click)="deleteClick.emit(filterSelectedDocuments())" *ngIf="listingService.isAllowedToDelete()">
        {{ 'button.exclude' | translate }}
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isTrash">
    <button type="button" class="btn btn-outline-primary" (click)="restoreClick.emit(filterSelectedDocuments())">{{ 'button.restore' | translate }}</button>
  </ng-container>

  <ng-container *ngIf="!isTrash && filterSelectedDocuments(true).length > 0">
    <button type="button" class="btn btn-link btn-block mt-2" (click)="resendSignaturesClick.emit(filterSelectedDocuments(true))" *ngIf="documents && documents.length > 0">
      {{ 'button.resendPendingSignaturesLinks' | translate }}
    </button>
  </ng-container>
</div>

<article class="table {{ appService.documentStatusFilter }}" *ngIf="documents && documents.length > 0">
  <div class="table__row table__row-titles" [class.d-none]="!documents[0]">
    <div class="table__col table__col-select">
      <app-checkbox class="checkbox" [(ngModel)]="isMainCheckboxChecked" (change)="onChangeMainCheckbox(isMainCheckboxChecked)" *ngIf="isAllowedToSomething()" #mainCheckbox></app-checkbox>
    </div>
    <div class="content">
      <div class="table__col table__col-name">
        {{ 'documents.name' | translate }}
        <div
          class="resize"
          (mousemove)="resizeWidth($event)"
          (mouseup)="resizePressedUp(false, $event)"
          (mouseleave)="resizePressedUp(false, $event)"
          (mousedown)="resizePressedDown(true, $event, 'table__col table__col-name')"
        ></div>
      </div>
      <div class="table__col table__col-status" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.Status)">
        {{ 'documents.status' | translate }}
        <div
          class="resize"
          (mousemove)="resizeWidth($event)"
          (mouseup)="resizePressedUp(false, $event)"
          (mouseleave)="resizePressedUp(false, $event)"
          (mousedown)="resizePressedDown(true, $event, 'table__col table__col-status')"
        ></div>
      </div>
      <div class="table__col table__col-signers" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.Signers)">
        {{ 'documents.signatories' | translate }}
        <div
          class="resize"
          (mousemove)="resizeWidth($event)"
          (mouseup)="resizePressedUp(false, $event)"
          (mouseleave)="resizePressedUp(false, $event)"
          (mousedown)="resizePressedDown(true, $event, 'table__col table__col-signers')"
        ></div>
      </div>
      <div class="table__col table__col-date created" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.CreatedAt)">
        {{ 'documents.createdIn' | translate }}
        <div
          class="resize"
          (mousemove)="resizeWidth($event)"
          (mouseup)="resizePressedUp(false, $event)"
          (mouseleave)="resizePressedUp(false, $event)"
          (mousedown)="resizePressedDown(true, $event, 'table__col table__col-date created')"
        ></div>
      </div>
      <div class="table__col table__col-date updated" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.UpdatedAt)">
        {{ 'documents.modifiedOn' | translate }}
        <div
          class="resize"
          (mousemove)="resizeWidth($event)"
          (mouseup)="resizePressedUp(false, $event)"
          (mouseleave)="resizePressedUp(false, $event)"
          (mousedown)="resizePressedDown(true, $event, 'table__col table__col-date updated')"
        ></div>
      </div>
    </div>
    <div class="columns-dropdown" display="dynamic" placement="bottom-right" appDropdown>
      <label appDropdownToggle><i class="fal fa-columns"></i></label>
      <div appDropdownMenu>
        <button type="button" class="dropdown-item-checkbox" disabled>
          <label>
            <app-checkbox [value]="true" disabled></app-checkbox>&nbsp;
            {{ 'documents.documentName' | translate }}
          </label>
        </button>
        <button type="button" class="dropdown-item-checkbox">
          <label>
            <app-checkbox (change)="toggleColumn(DocumentsListColumns.Status)" [value]="appService.documentsListColumns.includes(DocumentsListColumns.Status)"></app-checkbox>&nbsp;
            {{ 'documents.status' | translate }}
          </label>
        </button>
        <button type="button" class="dropdown-item-checkbox">
          <label>
            <app-checkbox (change)="toggleColumn(DocumentsListColumns.Signers)" [value]="appService.documentsListColumns.includes(DocumentsListColumns.Signers)"></app-checkbox>&nbsp;
            {{ 'documents.signatories' | translate }}
          </label>
        </button>
        <button type="button" class="dropdown-item-checkbox">
          <label>
            <app-checkbox (change)="toggleColumn(DocumentsListColumns.CreatedAt)" [value]="appService.documentsListColumns.includes(DocumentsListColumns.CreatedAt)"></app-checkbox>&nbsp;
            {{ 'documents.creationDate' | translate }}
          </label>
        </button>
        <button type="button" class="dropdown-item-checkbox">
          <label>
            <app-checkbox (change)="toggleColumn(DocumentsListColumns.UpdatedAt)" [value]="appService.documentsListColumns.includes(DocumentsListColumns.UpdatedAt)"></app-checkbox>&nbsp;
            {{ 'documents.lastModification' | translate }}
          </label>
        </button>
      </div>
    </div>
  </div>
  <div *ngFor="let document of documents" class="table__row table__row-document {{ document ? documentsListingService.documentStatusClasses(document).join(' ') : 'loading' }}">
    <div class="table__col table__col-select" *ngIf="document">
      <app-checkbox class="checkbox" [(ngModel)]="document._isSelected" (change)="onChangeCheckbox('document')"></app-checkbox>
    </div>
    <a [routerLink]="document ? ['/documentos/', document.id] : []" class="link">
      <div class="table__col table__col-name">
        <ng-container *ngIf="document">
          <div class="document-label deadline" *ngIf="documentService.isPastDeadline(document); else bottomLabel" [ngbTooltip]="deadlineTooltip" placement="right" container="body">
            <i class="fal fa-lock"></i>
            <ng-template #deadlineTooltip>
              <div class="tooltip__title">{{ 'documents.documentLocked' | translate }}</div>
              <div class="tooltip__descr">{{ 'documents.documentLockedDate' | translate: { date: document?.deadline_at | dateTime } }}</div>
            </ng-template>
          </div>
          <ng-template #bottomLabel>
            <div
              class="document-label deleted"
              *ngIf="currentOrganizationMemberId && listingService.deletedByUserAt(document, currentOrganizationMemberId); else bottomLifecycle"
              [ngbTooltip]="deletedTooltip"
              placement="right"
              container="body"
            >
              <i class="fal fa-trash-alt"></i>
              <ng-template #deletedTooltip>
                <div class="tooltip__title">{{ 'documents.deletedDocument' | translate }}</div>
                <div class="tooltip__descr">
                  {{ 'documents.deletedDocumentMessage' | translate: { date: listingService.deletedByUserAt(document, currentOrganizationMemberId) | ldate } }}
                </div>
              </ng-template>
            </div>
          </ng-template>
          <ng-template #bottomLifecycle>
            <div class="document-label lifecycle" *ngIf="documentService.isPastLifecycle(document); else bottomSandbox" [ngbTooltip]="lifecycleTooltip" placement="right" container="body">
              <i class="fal fa-boxes-alt"></i>
              <ng-template #lifecycleTooltip>
                <div class="tooltip__title">{{ 'documents.lifecycleExpired' | translate }}</div>
                <div class="tooltip__descr">{{ 'documents.lifecycleExpiredMessage' | translate: { date: document.lifecycle_in | ldate } }}</div>
              </ng-template>
            </div>
          </ng-template>
          <ng-template #bottomSandbox>
            <div class="document-label sandbox" *ngIf="document?.sandbox" [ngbTooltip]="sandboxTooltip" placement="right" container="body"><i class="fal fa-vial"></i></div>
          </ng-template>
        </ng-container>
        <span [ngClass]="{ active: document?._isSelected }">{{ document?.name || '' }}&nbsp;</span>
      </div>
      <div class="table__col table__col-status" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.Status)">
        <span *ngIf="document && documentService.isSigned(document) && !documentService.isExpired(document)">
          {{ 'documents.signed' | translate }}
        </span>
        <span *ngIf="document && documentService.isPending(document) && !documentService.isExpired(document)">
          {{ 'documents.pending' | translate: { pendingSigners: (document.signatures | filterPendingSigners).length, totalSigners: (document.signatures | filterSigners).length } }}
        </span>
        <span *ngIf="document && documentService.isExpired(document)">
          {{ 'documents.expired' | translate }}
        </span>
        <span *ngIf="document && documentService.isRejected(document)">
          {{ 'documents.rejected' | translate }}
        </span>
      </div>
      <div class="table__col table__col-signers" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.Signers)">
        <ng-container *ngFor="let signer of document ? (document.signatures | filterSigners | slice: 0:maxSigners) : [].constructor(2)">
          <div
            *ngIf="!signer || returnSignerIdentifier(signer)"
            class="table__signer"
            [ngClass]="{ signed: documentService.isSignerSigned(signer), rejected: documentService.isSignerRejected(signer), unapproved: documentService.isSignerUnapproved(signer) }"
          >
            {{ signer ? returnSignerIdentifier(signer) : '' }}&nbsp;
          </div>
        </ng-container>
      </div>
      <div class="table__col table__col-date created" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.CreatedAt)">
        <span class="table__col-date-text">{{ document?.created_at | ldate: 'mediumDate' }}&nbsp;</span>
      </div>
      <div class="table__col table__col-date updated" *ngIf="appService.documentsListColumns.includes(DocumentsListColumns.UpdatedAt)">
        <span class="table__col-date-text">{{ document?.updated_at | ldate: 'mediumDate' }}&nbsp;</span>
      </div>
    </a>
    <div class="table__col-dropdown" *ngIf="document" appDropdown>
      <div appDropdownToggle><i class="fal fa-ellipsis-v"></i></div>
      <div appDropdownMenu>
        <button appDropdownItem [routerLink]="['/documentos/', document.id]" *ngIf="!documentsListingService.isCurrentUserSignerPending(document)">
          <i class="fal fa-eye"></i> {{ 'button.view' | translate }}
        </button>
        <button appDropdownItem (click)="signClick.emit([document])" *ngIf="!isTrash && documentsListingService.hasCurrentUser(document) && documentsListingService.isAllowedToSign(document)">
          <i class="fal fa-feather-alt"></i> {{ 'button.sign' | translate }}
        </button>
        <button
          appDropdownItem
          type="button"
          [submenuToggle]="'table-submenu1-' + document.id"
          [attr.aria-labelledby]="'table-submenu1-' + document.id"
          [disabled]="isLoading || documentService.isPastLifecycle(document)"
        >
          <i class="fal fa-cloud-download-alt"></i> {{ 'button.download' | translate }}
        </button>
        <button appDropdownItem (click)="folderClick.emit([document])" *ngIf="!isTrash && listingService.isAllowedToArchive(document)">
          <i class="fal fa-archive"></i> {{ 'button.archive' | translate }}
        </button>
        <button appDropdownItem (click)="deleteClick.emit([document])" *ngIf="!isTrash && listingService.isAllowedToDelete(document)">
          <i class="fal fa-trash-alt"></i> {{ 'button.exclude' | translate }}
        </button>
        <button appDropdownItem (click)="transferClick.emit([document])" *ngIf="!isTrash && listingService.isAllowedToManage(document) && !appService.isEnterpriseChild">
          <i class="fal fa-file-export"></i> {{ 'button.transfer' | translate }}
        </button>
        <button appDropdownItem (click)="toggleBlockClick.emit([document])" *ngIf="!isTrash && listingService.isAllowedToToggleBlock(document)">
          <i class="fal fa-{{ documentService.isPastDeadline(document) ? 'lock-open' : 'lock' }}"></i> {{ 'button.' + (documentService.isPastDeadline(document) ? 'unblock' : 'block') | translate }}
        </button>
        <button appDropdownItem (click)="restoreClick.emit([document])" *ngIf="isTrash"><i class="fal fa-undo"></i> {{ 'button.restore' | translate }}</button>
        <button appDropdownItem (click)="resendWebhookClick.emit(document)" *ngIf="listingService.isAllowedToResendWebhook(currentUser, document)">
          <i class="fal fa-webhook"></i> {{ 'button.webhookResend' | translate }}
        </button>
      </div>

      <div [id]="'table-submenu1-' + document.id" [appDropdownSubmenu]="'table-submenu1-' + document.id">
        <button type="button" appDropdownBackButton>{{ 'button.back' | translate }}</button>
        <a
          class="dropdown-item-descriptive"
          [href]="documentService.signedDocumentUrl(document)"
          [disabled]="!documentService.isSignedOnce(document)"
          target="_blank"
          *ngIf="!document.qualified"
          appDropdownItem
        >
          <i class="fal fa-file-contract"></i> {{ 'documents.signedPDF' | translate }}
        </a>
        <a class="dropdown-item-descriptive" [href]="document.files?.certified" [disabled]="!documentService.isSignedOnce(document)" target="_blank" *ngIf="!document.qualified" appDropdownItem>
          <i class="fal fa-file-certificate"></i> {{ 'documents.certifiedFile' | translate }}
        </a>
        <a
          class="dropdown-item-descriptive"
          [href]="documentService.padesDocumentUrl(document)"
          [disabled]="!documentService.isSignedOnce(document)"
          target="_blank"
          *ngIf="document.qualified"
          appDropdownItem
        >
          <i class="fal fa-file-code"></i> {{ 'documents.padesFile' | translate }}
        </a>
        <a class="dropdown-item-descriptive" [href]="document.files?.original" target="_blank" appDropdownItem> <i class="fal fa-file-contract"></i> {{ 'documents.originalFile' | translate }} </a>
      </div>
    </div>
  </div>
  <div class="documents__more" *ngIf="!hidePagination">
    <button type="button" class="documents__more-button" (click)="loadPageClick.emit()">{{ 'button.loadMoreDocuments' | translate }}</button>
  </div>
</article>

<ng-template #sandboxTooltip>
  <div class="tooltip__title">{{ 'documents.sandboxDocument' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.sandboxDocumentDescr' | translate }}</div>
</ng-template>
