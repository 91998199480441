import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

import { environment } from '@env/environment';
import { Logger, I18nService, untilDestroyed, CredentialsService, versionChange$ } from '@app/core';
import { AppService, GoogleAnalyticsService, IntercomService, NotyService, UserService, WhitelabelParams, WhitelabelService, WootricService } from '@app/services';
import { VersionUpdateModalService } from '@app/shared';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.darkmode') get isDarkmode() {
    return this.appService.isDarkmode;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private credentialsService: CredentialsService,
    private notyService: NotyService,
    private appService: AppService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private intercomService: IntercomService,
    private wootricService: WootricService,
    private versionUpdateModalService: VersionUpdateModalService,
    private whitelabelService: WhitelabelService
  ) {}

  ngOnInit() {
    Sentry.setUser({ ip_address: '{{auto}}' });

    // Setup logger
    if (environment.production && !environment.debug) {
      Logger.enableProductionMode();
    }
    log.debug('init');

    if (environment.production) {
      this.googleAnalyticsService.startTracking();

      if (this.appService.isNotInDocumentPage() && this.appService.isAutentiqueDomain() && window.innerWidth > 915) {
        this.intercomService.startTracking();
      }
    }

    if (!this.appService.isAutentiqueDomain()) {
      this.whitelabelService.loadWhitelabelByDomain().subscribe(whitelabel => {
        this.whitelabelService.isApplicationWhitelabelLoaded = true;
      });
    } else {
      this.whitelabelService.resetWhitelabelInfo();
      this.whitelabelService.isApplicationWhitelabelLoaded = true;
    }

    setTimeout(() => {
      this.userService
        .watchCurrentUser()
        .pipe(untilDestroyed(this))
        .subscribe(
          user => {
            if (environment.production && this.intercomService.isBooted && user) {
              this.intercomService.setUserProperties(user);
            }

            if (user) {
              Sentry.setUser({ id: user.id, username: user.name, email: user.email, ip_address: '{{auto}}' });
            }

            if (user && environment.production && this.appService.isAutentiqueDomain() && this.appService.isNotInDocumentPage()) {
              this.wootricService.boot(user);
            }
            if (this.credentialsService.decodedAccessToken?.organization_id) {
              this.appService.isEnterpriseChild = true;
            }
          },
          error => {
            if (error && error.message) {
              this.notyService.error(error.message);
            }

            this.credentialsService.clearCredentials();
            this.router.navigate(['/entrar'], { replaceUrl: true });
          }
        );
    });

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    // Change page title on navigation or language change, based on route data
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // Sets previous and current url
    onNavigationEnd.subscribe((event: NavigationEnd) => this.appService.setNewUrl(event.url));

    versionChange$
      .pipe(
        delay(500),
        switchMap(version => this.versionUpdateModalService.open().pipe(map(() => version)))
      )
      .subscribe(version => (window.location.href = `${window.location.href}${window.location.href.match(/\?.*$/) ? '&' : '?'}v=${version}`));

    // calls for whitelabel using router params
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe(() => {
      const queryParams = this.router.parseUrl(this.router.url).queryParams as WhitelabelParams;
      if (queryParams) {
        this.whitelabelService.receiveParams(queryParams);
      }
    });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
