<form class="modal-body" [formGroup]="form" novalidate>
  <p class="centermodal__title">{{ 'signature.repeatField' | translate }}</p>

  <div class="centermodal__form">
    <div class="form-check">
      <input class="form-check-input" type="radio" id="radio1" formControlName="option" [value]="null" />
      <label class="form-check-label" for="radio1">{{ 'signature.onlyOnThisPage' | translate }}</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" id="radio2" formControlName="option" [value]="FloatingFieldRepeatEnum.ALL_PAGES" />
      <label class="form-check-label" for="radio2">{{ 'signature.onAllPages' | translate }}</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" id="radio3" formControlName="option" [value]="FloatingFieldRepeatEnum.ALL_PAGES_BUT_LAST" />
      <label class="form-check-label" for="radio3">{{ 'signature.onAllPagesExceptLast' | translate }}</label>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" (click)="apply()">{{ 'button.apply' | translate }}</button>
  </div>
</form>
