import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { throwOnGraphqlError } from '@app/core';
import { UserApiTokensGQL, CreateApiTokenGQL, DeleteApiTokenGQL, DeleteApiTokenMutationVariables } from 'src/generated/graphql.default';

@Injectable({ providedIn: 'root' })
export class ApiTokenService {
  constructor(private userApiTokensGQL: UserApiTokensGQL, private createApiTokenGQL: CreateApiTokenGQL, private deleteApiTokenGQL: DeleteApiTokenGQL) {}

  all() {
    return this.userApiTokensGQL.fetch().pipe(
      throwOnGraphqlError(),
      map(response => response.data.tokens)
    );
  }

  create() {
    return this.createApiTokenGQL.mutate().pipe(
      throwOnGraphqlError(),
      map(response => response.data.createApiToken)
    );
  }

  delete(variables: DeleteApiTokenMutationVariables) {
    return this.deleteApiTokenGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteApiToken)
    );
  }
}
