import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, NotyService, ErrorHandlerService } from '@app/services';
import { AuthenticationMethod } from '@app/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-email-confirmation',
  templateUrl: './auth-email-confirmation.component.html',
  styleUrls: ['../auth.scss']
})
export class AuthEmailConfirmationComponent implements OnInit {
  isLoading = false;
  email: string;
  private navigationState: { [k: string]: any };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notyService: NotyService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.email = this.route.snapshot.queryParams.email;
    this.navigationState = router.getCurrentNavigation()?.extras.state || {};
  }

  ngOnInit() {
    this.authenticationService.logout().subscribe();
  }

  redirect() {
    // Redirects to enterprise or normal autentique navigation
    this.notyService.success(this.translateService.instant('notyService.emailVerificationSuccess'));
    if (this.navigationState.enterpriseToken) {
      this.authenticationService.login({ enterprise_token: this.navigationState.enterpriseToken }, AuthenticationMethod.Enterprise).subscribe(
        () => this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true, queryParams: this.route.snapshot.queryParams }),
        error => this.errorHandlerService.handle(error)
      );
    } else {
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true, queryParams: this.route.snapshot.queryParams });
    }
  }
}
