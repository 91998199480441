import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Document, Signer } from '@app/models';
import { DocumentService } from '@app/services';

export type ModalResult = void;
export interface ModalPublicProperties {
  document: Document;
  currentSigner: Signer;
}

@Component({
  selector: 'app-document-signed-modal',
  templateUrl: './document-signed-modal.component.html',
  styleUrls: ['./document-signed-modal.component.scss']
})
export class DocumentSignedModalComponent implements ModalPublicProperties, OnInit {
  @Input() document: Document;
  @Input() currentSigner: Signer;
  waitingBiometry = false;

  constructor(public modal: NgbActiveModal, public documentService: DocumentService) {}

  ngOnInit() {
    if ((this.currentSigner.verifications || []).length > 0) {
      const pendingVerification = this.documentService.getPendingManualVerification(this.currentSigner);
      this.waitingBiometry = pendingVerification && !pendingVerification.verified_at;
    }
  }

  openSignedDocument() {
    if (this.document && this.document.qualified) {
      window.open(this.documentService.padesDocumentUrl(this.document), 'blank');
    } else {
      window.open(this.documentService.signedDocumentUrl(this.document), 'blank');
    }
    this.modal.close();
  }
}
