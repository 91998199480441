import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { SlimDocument, User } from '@app/models';
import { AppService, DocumentService } from '@app/services';
import { FilterSignersPipe } from '@app/shared/pipes/filter-signers.pipe';
import { DocumentsListingService } from '../documents-listing.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-documents-list-boxes',
  templateUrl: './documents-list-boxes.component.html',
  styleUrls: ['./documents-list-boxes.component.scss']
})
export class DocumentsListBoxesComponent implements OnDestroy {
  @Input() documents: SlimDocument[];
  @Input() currentUser: User;
  @Input() currentOrganizationMemberId: string;
  @Input() hidePagination: boolean;
  @Input() isTrash: boolean;
  @Output() signClick = new EventEmitter<SlimDocument[]>();
  @Output() deleteClick = new EventEmitter<SlimDocument[]>();
  @Output() restoreClick = new EventEmitter<SlimDocument[]>();
  @Output() folderClick = new EventEmitter<SlimDocument[]>();
  @Output() transferClick = new EventEmitter<SlimDocument[]>();
  @Output() toggleBlockClick = new EventEmitter<SlimDocument[]>();
  @Output() resendWebhookClick = new EventEmitter<SlimDocument>();
  @Output() loadPageClick = new EventEmitter();

  maxSigners = 6;

  isLoading = false;

  constructor(
    public appService: AppService,
    public documentService: DocumentService,
    public documentsListingService: DocumentsListingService,
    public listingService: DocumentsListingService,
    private ngbModal: NgbModal,
    private filterSignersPipe: FilterSignersPipe // private documentPreviewModalService: DocumentPreviewModalService
  ) {}

  getMaxSigners(document: SlimDocument) {
    return this.filterSignersPipe && document && this.filterSignersPipe.transform(document.signatures).length === this.maxSigners ? this.maxSigners : this.maxSigners - 1;
  }

  ngOnDestroy() {
    this.ngbModal.dismissAll();
  }

  returnSignerIdentifier(signer: SlimDocument['signatures'][0]) {
    return signer.certificate?.name || signer.certificate?.email || signer.user?.name || signer.user?.email || signer.name || signer.email || signer.user?.phone;
  }
}
