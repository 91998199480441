<ng-container *ngIf="documents && documents.length > 0">
  <div class="documents__list {{ appService.documentStatusFilter }}">
    <div *ngFor="let document of documents" class="document {{ document ? listingService.documentStatusClasses(document).join(' ') : 'loading' }}">
      <a class="document-link" [routerLink]="document ? ['/documentos/', document.id] : []">
        <i class="document-icon fa-regular fa-file-alt"></i>
        <p class="document-title">{{ document?.name }}&nbsp;</p>
        <p class="document-date" title="{{ document?.created_at | ldate: 'long' }}">{{ document?.created_at | dateTime: 'mediumDate' }}&nbsp;</p>
        <div class="document-status">
          <span *ngIf="document && documentService.isSigned(document) && !documentService.isExpired(document)">
            {{ document.expiration_at ? ('documents.signedExpiresIn' | translate: { expiration: document.expiration_at | ldate: 'mediumDate' }) : ('documents.signed' | translate) }}
          </span>
          <span *ngIf="!document || (documentService.isPending(document) && !documentService.isExpired(document))">
            <ng-container *ngIf="document">
              {{ 'documents.pending' | translate: { pendingSigners: (document.signatures | filterPendingSigners).length, totalSigners: (document.signatures | filterSigners).length } }} </ng-container
            >&nbsp;
          </span>
          <span *ngIf="document && documentService.isExpired(document)">{{
            'documents.expiredIn'
              | translate
                : {
                    expiration: document.expiration_at | ldate: 'mediumDate'
                  }
          }}</span>
          <span *ngIf="document && documentService.isRejected(document)">{{ 'documents.rejected' | translate }}</span>
        </div>
        <div class="document-subscribers">
          <ng-container *ngFor="let signer of document ? (document.signatures | filterSigners | slice: 0:getMaxSigners(document)) : [].constructor(3)">
            <p
              *ngIf="!signer || returnSignerIdentifier(signer)"
              class="document-subscriber"
              [ngClass]="{
                checked: documentService.isSignerSigned(signer),
                canceled: documentService.isSignerRejected(signer),
                unapproved: documentService.isSignerUnapproved(signer)
              }"
            >
              {{ signer ? returnSignerIdentifier(signer) : '' }}&nbsp;
            </p>
          </ng-container>
          <p class="document-more" *ngIf="document && (document.signatures | filterSigners).length > maxSigners">
            {{ 'documents.additionalSigners' | translate: { signers: (document.signatures | filterSigners).length - getMaxSigners(document) } }}
          </p>
        </div>
        <ng-container *ngIf="document">
          <div class="document-bottom-block deadline" *ngIf="documentService.isPastDeadline(document); else bottomLabel">
            <div><i class="fal fa-lock"></i>&nbsp; <span [innerHTML]="'documents.lockedAt' | translate"></span></div>
            <div>{{ document.deadline_at | dateTime }}</div>
          </div>
          <ng-template #bottomLabel>
            <div class="document-bottom-block deleted" *ngIf="currentOrganizationMemberId && listingService.deletedByUserAt(document, currentOrganizationMemberId); else bottomLifecycle">
              {{ 'documents.deletedAt' | translate: { deletedAt: listingService.deletedByUserAt(document, currentOrganizationMemberId) | ldate } }}
            </div>
          </ng-template>
          <ng-template #bottomLifecycle>
            <div class="document-bottom-block lifecycle" *ngIf="documentService.isPastLifecycle(document); else bottomSandbox">
              <div>
                <i class="fal fa-boxes-alt"></i>&nbsp; <strong>{{ 'documents.lifecycleExpired' | translate }}</strong>
              </div>
              <div>{{ document.lifecycle_in | ldate }}</div>
            </div>
          </ng-template>
          <ng-template #bottomSandbox>
            <div class="document-bottom-block sandbox" *ngIf="document?.sandbox">{{ 'documents.sandboxDocument' | translate }}</div>
          </ng-template>
        </ng-container>
      </a>
      <div class="document-dropdown" appDropdown placement="bottom-right" *ngIf="document && !isTrash">
        <label appDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
        <div appDropdownMenu>
          <button type="button" (click)="signClick.emit([document])" *ngIf="listingService.isAllowedToSign(document)" appDropdownItem>
            <i class="fal fa-feather-alt"></i> {{ 'button.sign' | translate }}
          </button>
          <button
            appDropdownItem
            type="button"
            [submenuToggle]="'box-submenu1-' + document.id"
            [attr.aria-labelledby]="'box-submenu1-' + document.id"
            [disabled]="documentService.isPastLifecycle(document)"
          >
            <i class="fal fa-cloud-download-alt"></i> {{ 'button.download' | translate }}
          </button>
          <button type="button" (click)="folderClick.emit([document])" *ngIf="listingService.isAllowedToArchive(document)" appDropdownItem>
            <i class="fal fa-archive"></i> {{ 'button.archive' | translate }}
          </button>
          <button type="button" (click)="deleteClick.emit([document])" *ngIf="listingService.isAllowedToDelete(document)" appDropdownItem>
            <i class="fal fa-trash-alt"></i> {{ 'button.exclude' | translate }}
          </button>
          <button type="button" (click)="transferClick.emit([document])" *ngIf="listingService.isAllowedToManage(document) && !appService.isEnterpriseChild" appDropdownItem>
            <i class="fal fa-file-export"></i> {{ 'button.transfer' | translate }}
          </button>
          <button type="button" (click)="toggleBlockClick.emit([document])" *ngIf="listingService.isAllowedToToggleBlock(document)" appDropdownItem>
            <i class="fal fa-{{ documentService.isPastDeadline(document) ? 'lock-open' : 'lock' }}"></i> {{ 'button.' + (documentService.isPastDeadline(document) ? 'unblock' : 'block') | translate }}
          </button>
          <button type="button" (click)="resendWebhookClick.emit(document)" *ngIf="listingService.isAllowedToResendWebhook(currentUser, document)" appDropdownItem>
            <i class="fal fa-webhook"></i> {{ 'button.webhookResend' | translate }}
          </button>
        </div>

        <div [id]="'box-submenu1-' + document.id" [appDropdownSubmenu]="'box-submenu1-' + document.id">
          <button type="button" appDropdownBackButton>{{ 'button.back' | translate }}</button>
          <ng-container *ngIf="!document.qualified">
            <a class="dropdown-item-descriptive" [href]="documentService.signedDocumentUrl(document)" [disabled]="!documentService.isSignedOnce(document)" target="_blank" appDropdownItem>
              <i class="fal fa-file-contract"></i> {{ 'documents.signedPDF' | translate }}
            </a>
            <a class="dropdown-item-descriptive" [href]="document.files?.certified" [disabled]="!documentService.isSignedOnce(document)" target="_blank" appDropdownItem>
              <i class="fal fa-file-certificate"></i> {{ 'documents.certifiedFile' | translate }}
            </a>
          </ng-container>
          <a
            class="dropdown-item-descriptive"
            [href]="documentService.padesDocumentUrl(document)"
            [disabled]="!documentService.isSignedOnce(document)"
            target="_blank"
            *ngIf="document.qualified"
            appDropdownItem
          >
            <i class="fal fa-file-code"></i> {{ 'documents.padesFile' | translate }}
          </a>
          <a class="dropdown-item-descriptive" [href]="document.files?.original" [disabled]="!document.files?.original" target="_blank" appDropdownItem>
            <i class="fal fa-file-alt"></i> {{ 'documents.originalFile' | translate }}
          </a>
        </div>
      </div>
      <div class="document-dropdown" appDropdown placement="bottom-right" container="body" *ngIf="document && isTrash">
        <label appDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
        <div appDropdownMenu>
          <button type="button" (click)="restoreClick.emit([document])" appDropdownItem><i class="fal fa-undo"></i> {{ 'button.restore' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="documents__more" *ngIf="!hidePagination">
    <button type="button" class="documents__more-button" (click)="loadPageClick.emit()">{{ 'button.loadMoreDocuments' | translate }}</button>
  </div>
</ng-container>
