import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { CardModule } from 'ngx-card/ngx-card';
import { NgxStripeModule } from 'ngx-stripe';
import { QRCodeModule } from 'angularx-qrcode';

import { SharedModule } from '@app/shared';
import { LcurrencyPipe } from '@app/shared/pipes';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationsIndexComponent } from './configurations-index/configurations-index.component';
import { ConfigurationsProfileComponent } from './configurations-profile/configurations-profile.component';
import { CurrentPasswordModalComponent } from './configurations-profile/current-password-modal/current-password-modal.component';
import { EditProfileModalComponent } from './configurations-profile/edit-profile-modal/edit-profile-modal.component';
import { EditPasswordModalComponent } from './configurations-profile/edit-password-modal/edit-password-modal.component';
import { EditSignatureModalComponent } from './configurations-profile/edit-signature-modal/edit-signature-modal.component';
import { SignaturePreviewComponent } from './configurations-profile/signature-preview/signature-preview.component';
import { ConfigurationsAppearanceComponent } from './configurations-appearance/configurations-appearance.component';
import { ConfigurationsNotificationComponent } from './configurations-notification/configurations-notification.component';
import { ConfigurationsPlanComponent } from './configurations-plan/configurations-plan.component';
import { EditPaymentInfoModalComponent } from './configurations-plan/edit-payment-info-modal/edit-payment-info-modal.component';
import { ConfigurationsApiComponent } from './configurations-api/configurations-api.component';
import { ConfigurationsWebhooksComponent } from './configurations-webhooks/configurations-webhooks.component';
import { ConfigurationsOrganizationComponent } from './configurations-organization/configurations-organization.component';
import { EditOrganizationModalComponent } from './configurations-organization/edit-organization-modal/edit-organization-modal.component';
import { DeleteSelfOrganizationMemberModalComponent } from './configurations-organization/delete-self-organization-member-modal/delete-self-organization-member-modal.component';
import { ConfigurationsOrganizationMembersComponent } from './configurations-organization-members/configurations-organization-members.component';
import { OrganizationPermissionsFormComponent } from './configurations-organization-members/organization-permissions-form/organization-permissions-form.component';
import { InviteOrganizationMembersModalComponent } from './configurations-organization-members/invite-organization-members-modal/invite-organization-members-modal.component';
import { EditOrganizationMemberModalComponent } from './configurations-organization-members/edit-organization-member-modal/edit-organization-member-modal.component';
import { ConfigurationsOrganizationGroupsComponent } from './configurations-organization-groups/configurations-organization-groups.component';
import { CreateOrganizationGroupModalComponent } from './configurations-organization-groups/create-organization-group-modal/create-organization-group-modal.component';
import { EditOrganizationGroupModalComponent as EditGroupModalComponent } from './configurations-organization-groups/edit-organization-group-modal/edit-organization-group-modal.component';
import { EditOrganizationGroupPermissionsModalComponent } from './configurations-organization-groups/edit-organization-group-permissions-modal/edit-organization-group-permissions-modal.component';
import { EditOrganizationGroupAppearanceModalComponent } from './configurations-organization-groups/edit-organization-group-appearance-modal/edit-organization-group-appearance-modal.component';
import { ConfigurationsReferralsComponent } from './configurations-referrals/configurations-referrals.component';
import { ConfigurationsOrganizationEmailsComponent } from './configurations-organization-emails/configurations-organization-emails.component';
import { SignatureEmailPreviewComponent } from './configurations-organization-emails/signature-email-preview/signature-email-preview.component';
import { ParseEmailVariablesPipe } from './configurations-organization-emails/signature-email-preview/parse-email-variables.pipe';
import { ConfigurationsWhitelabelComponent } from './configurations-whitelabel/configurations-whitelabel.component';
import { WhitelabelDomainNameComponent } from './configurations-whitelabel/whitelabel-domain-name/whitelabel-domain-name.component';
import { WhitelabelEmailAdressComponent } from './configurations-whitelabel/whitelabel-email-adress/whitelabel-email-adress.component';
import { WhitelabelSignedDocumentComponent } from './configurations-whitelabel/whitelabel-signed-document/whitelabel-signed-document.component';
import { WhitelabelSigningPageComponent } from './configurations-whitelabel/whitelabel-signing-page/whitelabel-signing-page.component';
import { SigningPageComponent } from './configurations-whitelabel/whitelabel-shared/signing-page/signing-page.component';
import { SignedDocumentComponent } from './configurations-whitelabel/whitelabel-shared/signed-document/signed-document.component';
import { BluredSignerCardComponent } from './configurations-whitelabel/whitelabel-shared/blured-signer-card/blured-signer-card.component';
import { BluredDocumentComponent } from './configurations-whitelabel/whitelabel-shared/blured-document/blured-document.component';
import { BluredSignerComponent } from './configurations-whitelabel/whitelabel-shared/blured-signer/blured-signer.component';
import { BluredEventComponent } from './configurations-whitelabel/whitelabel-shared/blured-event/blured-event.component';

import { environment } from '@env/environment';
import { EditLocationModalComponent } from '@app/pages/configurations/configurations-profile/edit-location-modal/edit-location-modal.component';

@NgModule({
  declarations: [
    ConfigurationsProfileComponent,
    ConfigurationsAppearanceComponent,
    ConfigurationsNotificationComponent,
    ConfigurationsPlanComponent,
    ConfigurationsApiComponent,
    ConfigurationsWebhooksComponent,
    ConfigurationsOrganizationComponent,
    ConfigurationsIndexComponent,
    CurrentPasswordModalComponent,
    EditProfileModalComponent,
    EditPasswordModalComponent,
    EditSignatureModalComponent,
    InviteOrganizationMembersModalComponent,
    EditOrganizationModalComponent,
    DeleteSelfOrganizationMemberModalComponent,
    EditPaymentInfoModalComponent,
    SignaturePreviewComponent,
    SignatureEmailPreviewComponent,
    ConfigurationsOrganizationMembersComponent,
    ParseEmailVariablesPipe,
    OrganizationPermissionsFormComponent,
    EditOrganizationMemberModalComponent,
    ConfigurationsOrganizationGroupsComponent,
    CreateOrganizationGroupModalComponent,
    EditGroupModalComponent,
    EditOrganizationGroupPermissionsModalComponent,
    EditOrganizationGroupAppearanceModalComponent,
    ConfigurationsReferralsComponent,
    ConfigurationsOrganizationEmailsComponent,
    ConfigurationsWhitelabelComponent,
    WhitelabelDomainNameComponent,
    WhitelabelEmailAdressComponent,
    WhitelabelSignedDocumentComponent,
    WhitelabelSigningPageComponent,
    SigningPageComponent,
    SignedDocumentComponent,
    BluredSignerCardComponent,
    BluredDocumentComponent,
    BluredSignerComponent,
    BluredEventComponent,
    EditLocationModalComponent
  ],
  imports: [
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    CommonModule,
    RouterModule,
    ConfigurationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    TranslateModule,
    ClipboardModule,
    CardModule,
    QRCodeModule,
    NgxStripeModule.forRoot(environment.stripePublicKey)
  ],
  providers: [ParseEmailVariablesPipe, LcurrencyPipe],
  entryComponents: [
    CurrentPasswordModalComponent,
    EditProfileModalComponent,
    EditPasswordModalComponent,
    EditSignatureModalComponent,
    EditOrganizationModalComponent,
    DeleteSelfOrganizationMemberModalComponent,
    InviteOrganizationMembersModalComponent,
    EditOrganizationMemberModalComponent,
    CreateOrganizationGroupModalComponent,
    EditGroupModalComponent,
    EditOrganizationGroupPermissionsModalComponent,
    EditOrganizationGroupAppearanceModalComponent,
    EditPaymentInfoModalComponent,
    EditLocationModalComponent
  ]
})
export class ConfigurationModule {}
