import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { GraphQLResponse } from '@app/models';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(errorData: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!errorData || ![404].includes(errorData.status)) {
      log.debug('Request error', errorData);
    }

    if (errorData) {
      if ([401, 429].includes(errorData.status)) {
        // Can't run the logout request since it would be looping HttpClient. But it's not necessary anyway, so it just clears the credentials
        this.credentialsService.clearCredentials();
        this.router.navigate(['/entrar'], { replaceUrl: true });
      } else if (errorData.status === 423) {
        this.credentialsService.clearCredentials();
        this.router.navigate(['/423'], { replaceUrl: true });
      } else if (errorData.status === 503) {
        this.router.navigate(['/503'], { replaceUrl: true });
      }
    }

    if (errorData && [401, 423, 503].includes(errorData.status)) {
      return of();
    } else {
      // Throws error with similar structure as the success request, to use ErrorHandlerService
      throw { data: null, errors: errorData ? [errorData.error] : [], status: errorData ? errorData.status : -1 } as GraphQLResponse;
    }
  }
}
