<app-auth-container>
  <p class="auth__title mb-3">{{ 'auth.changePassword' | translate }}</p>
  <p class="auth__descr text-center">{{ 'auth.fillNewAccessPasswordFor' | translate: { email: form.get('email').value } }}</p>
  <form class="field__form" (ngSubmit)="resetPassword()" [formGroup]="form" novalidate>
    <label class="field__holder" [appFormControlWrap]="form.get('password')">
      <input type="password" class="field" formControlName="password" name="password" autocomplete="new-password" required appShowPassword data-sentry-mask />
      <span class="field__placeholder">{{ 'auth.newPassword' | translate }}</span>
    </label>
    <label class="field__holder" [appFormControlWrap]="form.get('password_confirmation')">
      <input type="password" class="field" formControlName="password_confirmation" name="password_confirmation" autocomplete="new-password" required appShowPassword data-sentry-mask />
      <span class="field__placeholder">{{ 'auth.confirmNewPassword' | translate }}</span>
    </label>
    <div class="submit-holder mt-0">
      <button type="submit" class="submit" [disabled]="form.invalid || isLoading">{{ 'button.send' | translate }}</button>
    </div>
  </form>
  <a routerLink="/entrar" class="auth__link">{{ 'auth.backToLoginPage' | translate }}</a>
</app-auth-container>
