import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appValidName]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidNameDirective, multi: true }]
})
export class ValidNameDirective implements Validator {
  @Input('appValidName') isActive = true;

  static validator(control: AbstractControl, isActive: boolean = true): ValidationErrors | null {
    return (typeof isActive === 'boolean' && !isActive) || !control.value || (typeof control.value === 'string' && ValidNameDirective.isNameValid(control.value)) ? null : { appValidName: true };
  }

  static isNameValid(name: string) {
    return !name.match(/[^a-zA-Z\u00C0-\u017F\s\u2019\u2018']/);
  }

  constructor() {}

  validate(control: AbstractControl) {
    return ValidNameDirective.validator(control, this.isActive);
  }
}
