import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  constructor() {}

  transform(text: string, highlightedText: string): string {
    const parsedText = (text || '').toLowerCase();
    const parsedHighlightedText = (highlightedText || '').toLowerCase();
    const index = parsedText.indexOf(parsedHighlightedText);
    return index < 0 ? text : text.substring(0, index) + '<strong>' + text.substring(index, index + parsedHighlightedText.length) + '</strong>' + text.substring(index + parsedHighlightedText.length);
  }
}
