<!-- MOBILE: -->
<app-mobile-header-secondary title="{{ 'menu.indications' | translate }}">
  <!--  <a href="javascript:void(0)" class="mobile-header__option" (click)="(null)">Adicionar</a>-->
</app-mobile-header-secondary>

<!-- DESKTOP: -->

<article class="configuration-item">
  <div class="configuration-header">
    <div>
      <div class="configuration-title mb-1">{{ 'menu.indications' | translate }}</div>
      <div class="configuration-text" [innerHTML]="'settings.inviteLinkUserSignUpAndEarn' | translate"></div>
    </div>
    <button type="button" class="btn btn-link text-right d-none d-md-inline-block" [disabled]="isLoading" (click)="exportReferrals()">
      <i class="fal fa-file-csv"></i>&nbsp; {{ 'settings.export' | translate }}
    </button>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="configuration-subtitle font-weight-bold">
        {{ 'settings.availableRescue' | translate }}
        <small class="font-weight-normal">
          <i class="fal fa-info-circle" [ngbTooltip]="tooltipExtract" placement="left"></i>
        </small>
      </div>
      <div class="text-primary" *ngIf="referralsData?.recent_extracts && referralsData.recent_extracts[0]">
        {{ $any((referralsData?.recent_extracts)[0]).amount | currency: 'R$' }}
      </div>
      <ng-container *ngIf="referralsData?.recent_extracts && referralsData.recent_extracts.length > 1">
        <div class="mt-3">{{ 'settings.lastRescues' | translate }}</div>
        <table>
          <tr *ngFor="let extract of referralsData.recent_extracts.slice(1)">
            <td class="text-muted">
              <small>{{ $any(extract).created_at | ldate: 'mediumDate' }}</small>
            </td>
            <td class="text-muted pl-3">
              <small>{{ $any(extract).amount | currency: 'R$ ' }}</small>
            </td>
          </tr>
        </table>
      </ng-container>
    </div>
    <div class="col-lg-6">
      <div class="configuration-subtitle font-weight-bold">{{ 'settings.yourLink' | translate }}</div>
      <div class="configuration-text mb-3" [innerHTML]="'settings.shareYourLinkOnSocialMediaOrWebsite' | translate"></div>

      <div class="configuration-value">
        <input type="text" class="configuration-value-show" [value]="referralLink" readonly #referralLinkInputElement />
        <div class="configuration-value-copy" (click)="copyReferralLink()" [ngxClipboard]="referralLinkInputElement"></div>
      </div>
    </div>
  </div>

  <hr />

  <div class="row mt-4" *ngIf="referralsData?.affiliates?.length > 0">
    <div class="col-12">
      <table class="configuration-table">
        <thead class="head">
          <th class="col1">{{ 'settings.name' | translate }}</th>
          <th class="col2">{{ 'settings.register' | translate }}</th>
          <th class="col2">{{ 'settings.totalGain' | translate }}</th>
          <th class="col2">{{ 'settings.plan' | translate }}</th>
          <th class="col2">{{ 'settings.hirings' | translate }}</th>
        </thead>
        <tbody class="body">
          <tr *ngFor="let ref of referralsData.affiliates">
            <td class="user">
              <div class="user-holder">
                <app-autenticats class="user-avatar" [seed]="ref.email || ref.full_name"></app-autenticats>
                <div class="user-info">
                  <p class="user-name">{{ ref.full_name }}</p>
                  <p class="user-contact">{{ ref.email || ref.phone }}</p>
                </div>
              </div>
            </td>
            <td>{{ ref.created_at | dateTime }}</td>
            <td>{{ paymentsSum(ref.payment_info) | lcurrency: 'R$ ' }}</td>
            <td [ngClass]="{ 'text-primary': ref.is_receiving_payments }">
              {{ ref.is_receiving_payments ? ($any(ref.payment_info[0]).amount | lcurrency: 'R$ ') : 'Grátis' }}
            </td>
            <td>{{ (ref.payment_info || [])[0] ? (ref.payment_info[0].created_at | dateTime) : '' }}</td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-3" *ngIf="!isLoading && !isLastPage">
        <button type="button" class="btn btn-link" (click)="loadReferrals({ page: pageNumber + 1 })">{{ 'button.loadMore' | translate }}</button>
      </div>
    </div>
  </div>
</article>

<ng-template #tooltipExtract>{{ 'settings.balanceAvailableForRedemptionAfter30Days' | translate }}</ng-template>
