import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { contextMenuState } from '../configurations-whitelabel.component';
import { User, Whitelabel } from '@app/models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ErrorHandlerService, NotyService, WhitelabelService } from '@app/services';

@Component({
  selector: 'app-whitelabel-signed-document',
  templateUrl: './whitelabel-signed-document.component.html',
  styleUrls: ['../../configurations.scss', './whitelabel-signed-document.component.scss']
})
export class WhitelabelSignedDocumentComponent implements OnInit {
  @Input() currentUser: User;
  @Input() whitelabelData: Whitelabel;
  @Output() contextChange: EventEmitter<contextMenuState> = new EventEmitter();
  @Output() goBack: EventEmitter<contextMenuState> = new EventEmitter();
  contextMenuState = contextMenuState;

  selectedPdfLogoImage: File | null = null;
  selectedPdfLogoImageUrl: SafeUrl | null = null;

  selectedPdfLogoMiniImage: File | null = null;
  selectedPdfLogoMiniImageUrl: SafeUrl | null = null;

  constructor(public whitelabelService: WhitelabelService, private sanitizer: DomSanitizer, private notyService: NotyService, private errorHandlerService: ErrorHandlerService) {}

  ngOnInit(): void {}

  changeContext(context: contextMenuState) {
    this.contextChange.emit(context);
  }

  submitForm() {
    this.whitelabelService.updateWhitelabelAppearance({ whitelabel: this.whitelabelService.appearance }).subscribe(
      () => {
        this.notyService.success('Personalização salva com sucesso.');
        this.goBack.emit();
      },
      error => {
        this.errorHandlerService.handle(error);
      }
    );
  }

  selectPdfLogoImage(image: File) {
    if (image) {
      if (this.selectedPdfLogoImageUrl) {
        URL.revokeObjectURL(this.selectedPdfLogoImageUrl as string);
      }
      const objectURL = URL.createObjectURL(image);
      this.selectedPdfLogoImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      this.selectedPdfLogoImage = image;
      this.whitelabelService.appearance['pdf_logo'] = this.selectedPdfLogoImage;
    }
  }

  selectPdfLogoMiniImage(image: File) {
    if (image) {
      if (this.selectedPdfLogoMiniImageUrl) {
        URL.revokeObjectURL(this.selectedPdfLogoMiniImageUrl as string);
      }
      const objectURL = URL.createObjectURL(image);
      this.selectedPdfLogoMiniImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      this.selectedPdfLogoMiniImage = image;
      this.whitelabelService.appearance['pdf_logo_mini'] = this.selectedPdfLogoMiniImage;
    }
  }
}
