import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { Signer } from '@app/models';
import { ErrorHandlerService, DocumentService, AppService, NotyService } from '@app/services';

export type ModalResult = void;
export interface ModalPublicProperties {
  signer: Signer;
}

@Component({
  selector: 'app-sign-link-modal',
  templateUrl: './sign-link-modal.component.html',
  styleUrls: ['./sign-link-modal.component.scss']
})
export class SignLinkModalComponent implements ModalPublicProperties {
  @Input() signer: Signer;
  isLoading = false;

  constructor(
    public modal: NgbActiveModal,
    public appService: AppService,
    private documentService: DocumentService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService
  ) {}

  createSignLink() {
    this.isLoading = true;
    this.documentService
      .createSignerLink({ publicId: this.signer.public_id })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        link => (this.signer.link = link),
        error => this.errorHandlerService.handle(error)
      );
  }

  deleteSignLink() {
    this.isLoading = true;
    this.documentService
      .deleteSignerLink({ publicId: this.signer.public_id })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => (this.signer.link = null),
        error => this.errorHandlerService.handle(error)
      );
  }
  copySignatureLink(): void {
    this.notyService.success(`Link de ${this.signer.user.name || this.signer.email} copiado!`, 2000);
  }
}
