import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';

import { FloatingField, FloatingFieldRepeatEnum, Locale, PositionElementEnum, SignatureFormat, Whitelabel } from '@app/models';
import { DocumentService, WhitelabelService } from '@app/services';
import { RepeatFloatingFieldModalService } from './repeat-floating-field-modal/repeat-floating-field-modal.service';
import { I18nService } from '@app/core';

interface ParsedFloatingField {
  originalField: FloatingField;
  element: PositionElementEnum;
  signature?: FloatingField['signature'];
  isSelected?: boolean;
  canRotate?: boolean;
  angle?: number;
  idColor?: string;
  styles: { top: string; left: string; height: string; lineHeight: string; width: string; transform: string; transformOrigin: string };
  innerStyles: { borderColor?: string; backgroundColor?: string; transform: string; transformOrigin: string };
}

@Component({
  selector: 'app-floating-fields',
  templateUrl: './floating-fields.component.html',
  styleUrls: ['./floating-fields.component.scss']
})
export class FloatingFieldsComponent implements OnChanges {
  @Input() pdfContainer!: HTMLElement;
  @Input() fields: FloatingField[] = [];
  @Input() scale: number;
  @Input() pagesCount: number;
  @Input() enableEdit: boolean;
  @Input() qualified: boolean;
  @Input() isNewSignatureStyle: boolean;
  @Input() documentWhitelabel: Whitelabel;
  @Input() documentLocale: Locale;
  @Input() isDocumentWhitelabelLoaded: boolean;
  @Output() selectChange = new EventEmitter<FloatingField>();
  parsedFields: ParsedFloatingField[] = [];
  isDraggingField: boolean;
  isRotateField: boolean;

  originShiftX: number;
  originShiftY: number;
  clickedPointShiftX: number;
  clickedPointShiftY: number;
  elementRotate: number;
  dateFormat: string;
  timezone: string;

  readonly SignatureFormat = SignatureFormat;
  readonly PositionElementEnum = PositionElementEnum;
  private lastDragEvent: MouseEvent | TouchEvent;
  private currentDraggingField: ParsedFloatingField;
  private readonly fieldSizes = { width: 160, fontSize: 10, fullHeight: 60, partialHeight: 36 };
  private readonly fieldSizesNewSignatureStyle = { width: 213.3333, fontSize: 10, fullHeight: 71.10666666666665, partialHeight: 36 };

  constructor(
    public documentService: DocumentService,
    public whitelabelService: WhitelabelService,
    private repeatFloatingFieldModalService: RepeatFloatingFieldModalService,
    private i18nService: I18nService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && changes.fields.currentValue !== changes.fields.previousValue) {
      this.refreshFields(changes.fields.currentValue);
    }
    if ((changes.qualified || changes.isNewSignatureStyle) && this.fields.length > 0) {
      this.refreshFields(this.fields);
    }
    if (changes.documentLocale) {
      this.dateFormat = this.i18nService.transformDateFormat(this.documentLocale?.date_format) || 'shortDate';
      this.timezone = this.i18nService.getTimezoneOffset(this.documentLocale?.timezone) || null;
    }
  }

  createField(field: FloatingField, centerFieldPosition?: boolean) {
    const newField = this.fixFieldToInsideBoundaries(centerFieldPosition ? this.fixFieldToCenterPosition(field) : field);
    this.fields.push(newField);
    this.onStopDraggingField();
    this.refreshFields(this.fields);
    return newField;
  }

  refreshFields(fields?: FloatingField[]) {
    const selectedField = this.parsedFields.find(f => f.isSelected);
    setTimeout(() => (this.parsedFields = this.parseFields(fields || this.fields, selectedField)));
  }

  removeField(field: FloatingField) {
    const fieldIndex = this.fields.indexOf(field);
    if (fieldIndex >= 0) {
      const parsedField = this.parsedFields.find(pField => pField.originalField === field);
      if (parsedField && parsedField.isSelected) {
        this.selectChange.emit(null);
      }

      const idLink = field.idLink;
      this.fields.filter(f => f === field || (idLink && idLink === f.idLink)).forEach(f => this.fields.splice(this.fields.indexOf(f), 1));
      this.refreshFields(this.fields);
    }
  }

  fieldIdentifier(index: number, field: ParsedFloatingField) {
    return field.originalField;
  }

  onStartDraggingField(event: MouseEvent | TouchEvent, parsedField: ParsedFloatingField) {
    this.lastDragEvent = event;
    this.currentDraggingField = parsedField;
    this.isDraggingField = this.enableEdit && !parsedField.signature;
  }

  onStopDraggingField() {
    this.lastDragEvent = null;
    this.currentDraggingField = null;
    this.isDraggingField = false;
  }

  onDragField(event: MouseEvent | TouchEvent) {
    let currentPage;
    const field = this.currentDraggingField;
    let shiftX = 'x' in event ? event.x : 'touches' in event ? event.touches[0].pageX : null;
    let shiftY = 'x' in event ? event.y : 'touches' in event ? event.touches[0].pageY : null;

    if (this.isDraggingField) {
      document.body.style.overflow = 'hidden';
      if (document.getElementsByClassName('models position').length > 0) {
        // @ts-ignore
        document.getElementsByClassName('models position')[0].style.overflowY = 'hidden';
      }
      shiftX = shiftX - ('x' in this.lastDragEvent ? this.lastDragEvent.x : 'touches' in this.lastDragEvent ? this.lastDragEvent.touches[0].pageX : null);
      shiftY = shiftY - ('y' in this.lastDragEvent ? this.lastDragEvent.y : 'touches' in this.lastDragEvent ? this.lastDragEvent.touches[0].pageY : null);
      this.lastDragEvent = event;

      if (shiftX !== 0 || shiftY !== 0) {
        currentPage = this.currentPageFor(field.originalField);
        if (shiftX !== 0) {
          field.originalField.position.x = field.originalField.position.x + (shiftX * 100) / currentPage.offsetWidth;
        }
        if (shiftY !== 0) {
          field.originalField.position.y = field.originalField.position.y + (shiftY * 100) / currentPage.offsetHeight;
        }
        this.fixFieldToInsideBoundaries(field.originalField, currentPage);

        if (shiftX !== 0) {
          field.styles.left = field.originalField.position.x + '%';
        }
        if (shiftY !== 0) {
          field.styles.top = this.calcFieldAbsoluteTopPosition(field.originalField, currentPage) + '%';
        }

        if (field.originalField.idLink) {
          setTimeout(() => {
            this.fields
              .filter(f => f !== field.originalField && field.originalField.idLink === f.idLink)
              .forEach(f => {
                f.position.x = field.originalField.position.x;
                f.position.y = field.originalField.position.y;
              });
            this.parsedFields
              .filter(f => f !== field && field.originalField.idLink === f.originalField.idLink)
              .forEach(f => {
                f.styles.left = f.originalField.position.x + '%';
                f.styles.top = this.calcFieldAbsoluteTopPosition(f.originalField, this.currentPageFor(f.originalField)) + '%';
              });
          });
        }
      }
    }

    if (this.isRotateField) {
      if (shiftX !== this.originShiftX && shiftY !== this.originShiftY) {
        let radius = Math.atan2(shiftY - this.originShiftY, shiftX - this.originShiftX);
        radius -= Math.atan2(this.clickedPointShiftY - this.originShiftY, this.clickedPointShiftX - this.originShiftX);
        radius += field.angle;

        const angle = (((radius * (360 / (2 * Math.PI))) % 360) + 360) % 360;
        field.styles.transform = `scale(${this.scale || 1})`;
        field.innerStyles.transform = `rotate(${angle || 0}deg)`;
        field.originalField.position.angle = parseFloat(angle.toFixed(2));
        this.elementRotate = radius;

        if (field.originalField.idLink) {
          setTimeout(() => {
            this.fields
              .filter(f => f !== field.originalField && field.originalField.idLink === f.idLink)
              .forEach(f => {
                f.position.angle = field.originalField.position.angle;
              });
          });
        }
      }
    }
  }

  onStartRotateField(event: MouseEvent | TouchEvent, parsedField: ParsedFloatingField) {
    this.lastDragEvent = event;
    this.currentDraggingField = parsedField;
    this.currentDraggingField.canRotate = true;
    this.isRotateField = this.enableEdit;
    this.isDraggingField = false;

    this.clickedPointShiftX = 'x' in event ? event.pageX : 'touches' in event ? event.touches[0].pageX : null;
    this.clickedPointShiftY = 'y' in event ? event.pageY : 'touches' in event ? event.touches[0].pageY : null;
    this.elementRotate = this.currentDraggingField.angle || 0;

    event.preventDefault();
    event.stopPropagation();

    // @ts-ignore
    const target = event.target.parentElement;
    this.originShiftX = target.getBoundingClientRect().left + target.getBoundingClientRect().width / 2;
    this.originShiftY = target.getBoundingClientRect().top + target.getBoundingClientRect().height / 2; // origin point

    this.currentDraggingField.angle = this.currentDraggingField.angle || 0;
  }

  onStopRotateField() {
    if (this.currentDraggingField && this.isRotateField) {
      this.currentDraggingField.angle = this.elementRotate || 0;
      this.currentDraggingField.canRotate = false;
    }

    this.elementRotate = null;
    this.isRotateField = false;
  }

  onStopSelectedField() {
    this.onStopRotateField();
    this.onStopDraggingField();

    if (this.enableEdit) {
      document.body.style.overflow = 'unset';
      if (document.getElementsByClassName('models position').length > 0) {
        // @ts-ignore
        document.getElementsByClassName('models position')[0].style.overflowY = 'auto';
      }
    }
  }

  selectField(field: FloatingField) {
    if (this.enableEdit) {
      this.parsedFields.forEach(f => (f.isSelected = !!field && (field === f.originalField || (!!field.idLink && f.originalField.idLink === field.idLink))));
      this.selectChange.emit(field);
    }
  }

  openRepeatFieldModal(field: FloatingField) {
    this.repeatFloatingFieldModalService.open().subscribe(option => {
      field.idLink = Math.floor(Math.random() * 1000000000);
      const pagesAdjust = option === FloatingFieldRepeatEnum.ALL_PAGES_BUT_LAST ? -1 : 0;
      for (let i = 0; i < this.pagesCount + pagesAdjust; i++) {
        if (field.position.z !== i + 1) {
          const newField = cloneDeep(field);
          newField.position.z = i + 1;
          newField.idLink = field.idLink;
          this.createField(newField, false);
        }
      }
    });
  }

  unlinkFields(field: FloatingField) {
    this.fields.filter(f => f.idLink && f.idLink === field.idLink).forEach(f => (f.idLink = null));
  }

  unlinkField(parsedField: ParsedFloatingField) {
    if (parsedField.originalField.idLink) {
      parsedField.originalField.idLink = null;
    }
  }

  currentFormat(parsedField: ParsedFloatingField) {
    return parsedField.signature[parsedField.element && parsedField.element === PositionElementEnum.Initials ? 'initialsFormat' : 'format'];
  }

  returnPlacementTooltip(parsedField: ParsedFloatingField) {
    const angle = (((parsedField.angle * (360 / (2 * Math.PI))) % 360) + 360) % 360; // convert angle
    return angle >= 45 && angle < 135 ? 'right' : angle >= 135 && angle < 225 ? 'bottom' : angle >= 225 && angle < 315 ? 'left' : 'top';
  }

  returnFieldWidth(element: PositionElementEnum) {
    return element === PositionElementEnum.Signature && this.isNewSignatureStyle ? this.fieldSizesNewSignatureStyle.width : this.fieldSizes.width;
  }

  returnFieldFullHeight(element: PositionElementEnum) {
    return element === PositionElementEnum.Signature && this.isNewSignatureStyle ? this.fieldSizesNewSignatureStyle.fullHeight : this.fieldSizes.fullHeight;
  }

  private parseFields(fields: FloatingField[], selectedField?: ParsedFloatingField) {
    return fields
      .filter(field => this.pdfContainer.childElementCount >= field.position.z)
      .map(field => {
        const currentPage = this.currentPageFor(field);
        field.position.angle = this.qualified ? 0 : field.position.angle || 0;
        return {
          originalField: field,
          element: field.element,
          idColor: field.idColor,
          signature: field.signature || null,
          isSelected: !!selectedField && (selectedField.originalField === field || (!!selectedField.originalField.idLink && selectedField.originalField.idLink === field.idLink)),
          angle: (((field.position.angle / (360 / (2 * Math.PI))) % 360) + 360) % 360,
          styles: {
            width: this.returnFieldWidth(field.element) + 'px',
            height: this.fieldHeight(field.element) + 'px',
            lineHeight: this.fieldHeight(field.element) + 'px',
            fontSize: this.fieldSizes.fontSize + 'px',
            left: field.position.x + '%',
            top: this.calcFieldAbsoluteTopPosition(field, currentPage) + '%',
            transform: `scale(${this.scale || 1})`,
            transformOrigin: 'top left'
          },
          innerStyles: {
            ...(field.idColor ? { outlineColor: field.idColor, backgroundColor: `${field.idColor}22` } : {}),
            transform: `rotate(${field.position.angle}deg)`,
            transformOrigin: 'center'
          }
        };
      });
  }

  private currentPageFor(field: FloatingField) {
    return this.pdfContainer.children[(field.position.z || 1) - 1] as HTMLElement;
  }

  private calcFieldAbsoluteTopPosition(field: FloatingField, page: HTMLElement) {
    const positionY = Math.max(field.position.y, 0);
    return (page.offsetTop * 100) / this.pdfContainer.offsetHeight + (positionY * page.offsetHeight) / this.pdfContainer.offsetHeight;
  }

  private fixFieldToInsideBoundaries(field: FloatingField, page?: HTMLElement) {
    page = page || this.currentPageFor(field);
    if (page) {
      field.position.x = Math.min(Math.max(field.position.x, -9), ((page.offsetWidth - this.returnFieldWidth(field.element) * (this.scale || 1)) * 100) / page.offsetWidth + 5);
      field.position.y = Math.min(Math.max(field.position.y, 0), ((page.offsetHeight - this.returnFieldFullHeight(field.element) * (this.scale || 1)) * 100) / page.offsetHeight);
    }
    return field;
  }

  private fixFieldToCenterPosition(field: FloatingField) {
    const currentPage = this.currentPageFor(field);
    field.position.x = field.position.x - (this.returnFieldWidth(field.element) * (this.scale || 1) * 100) / currentPage.offsetWidth / 2;
    field.position.y = field.position.y - (this.fieldHeight(field.element) * (this.scale || 1) * 100) / currentPage.offsetHeight / 2;
    return field;
  }

  private fieldHeight(element: PositionElementEnum) {
    return [PositionElementEnum.Signature, PositionElementEnum.Initials].includes(element) ? this.returnFieldFullHeight(element) : this.fieldSizes.partialHeight;
  }
}
