import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Logger } from '@app/core/logger.service';
import { environment } from '@env/environment';

const log = new Logger('HttpCacheService');
const cachePersistenceKey = `autentique-${environment.version}${environment.production ? '-prod' : ''}-httpCache`;

export interface HttpCacheEntry {
  lastUpdated: Date;
  expiresAt?: Date;
  data: HttpResponse<any>;
}

/**
 * Provides a cache facility for HTTP requests with configurable persistence policy.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {
  private cachedData: { [key: string]: HttpCacheEntry } = {};
  private storage: Storage | null = null;

  constructor() {
    this.loadCacheData();
  }

  /**
   * Sets the cache data for the specified request.
   * @param url The request URL.
   * @param data The received data.
   * @param expiresIn Cache expiration in seconds.
   */
  setCacheData(url: string, data: HttpResponse<any>, expiresIn?: number) {
    this.cachedData[url] = {
      lastUpdated: new Date(),
      expiresAt: expiresIn ? new Date(new Date().setSeconds(new Date().getSeconds() + expiresIn)) : null,
      data
    };
    log.debug(`Cache set for key: "${url}"`);
    this.saveCacheData();
  }

  /**
   * Gets the cached data for the specified request.
   * @param url The request URL.
   * @return The cached data or null if no cached data exists for this request.
   */
  getCacheData(url: string): HttpResponse<any> | null {
    this.checkCacheExpiration();
    const cacheEntry = this.cachedData[url];

    if (cacheEntry) {
      log.debug(`Cache hit for key: "${url}"`);
      return cacheEntry.data;
    }

    return null;
  }

  /**
   * Gets the cached entry for the specified request.
   * @param url The request URL.
   * @return The cache entry or null if no cache entry exists for this request.
   */
  getHttpCacheEntry(url: string): HttpCacheEntry | null {
    return this.cachedData[url] || null;
  }

  /**
   * Clears the cached entry (if exists) for the specified request.
   * @param url The request URL.
   */
  clearCache(url: string): void {
    delete this.cachedData[url];
    log.debug(`Cache cleared for key: "${url}"`);
    this.saveCacheData();
  }

  /**
   * Clears all cache entries.
   */
  cleanCache() {
    this.cachedData = {};
    this.saveCacheData();
  }

  /**
   * Checks if cache entry is expired, then expires if it does.
   */
  checkCacheExpiration() {
    let storeChanged = false;
    Object.entries(this.cachedData).forEach(([key, value]) => {
      if (value.expiresAt && value.expiresAt < new Date()) {
        delete this.cachedData[key];
        storeChanged = true;
        log.debug(`Cache expired for key: "${key}"`);
      }
    });
    if (storeChanged) {
      this.saveCacheData();
    }
  }

  /**
   * Sets the cache persistence policy.
   * Note that changing the cache persistence will also clear the cache from its previous storage.
   * @param persistence How the cache should be persisted, it can be either local or session storage, or if no value is
   *   provided it will be only in-memory (default).
   */
  setPersistence(persistence?: 'local' | 'session') {
    const newStorage = persistence === 'local' || persistence === 'session' ? window[persistence + 'Storage'] : null;
    if (newStorage !== this.storage) {
      this.cleanCache();
    }
    this.storage = newStorage;
    this.loadCacheData();
  }

  private saveCacheData() {
    if (this.storage) {
      this.storage.setItem(cachePersistenceKey, JSON.stringify(this.cachedData));
    }
  }

  private loadCacheData() {
    const data = this.storage ? this.storage.getItem(cachePersistenceKey) : null;
    this.cachedData = {};
    if (data) {
      this.cachedData = JSON.parse(data);
      Object.entries(this.cachedData).forEach(([, value]) => {
        if (value.lastUpdated) {
          value.lastUpdated = new Date(value.lastUpdated);
        }
        if (value.expiresAt) {
          value.expiresAt = new Date(value.expiresAt);
        }
      });
    }
  }
}
