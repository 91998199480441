import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CredentialsService } from '@app/core';
import { SlimDocument, Document, AuthenticationMethod, CreditTypeEnum } from '@app/models';
import { AppService, AuthenticationService, ErrorHandlerService, OauthService } from '@app/services';
import { AuthLoginModalService } from '@app/pages/documents/documents-show/auth-login-modal/auth-login-modal.service';
import { PreLoginModalService } from '@app/pages/documents/documents-show/pre-login-modal/pre-login-modal.service';
import { RestoreLifecycleModalService } from '@app/shared/restore-lifecycle-modal/restore-lifecycle-modal.service';
import { UserExistsModalService } from '@app/pages/documents/documents-show/user-exists-modal/user-exists-modal.service';

@Component({
  selector: 'app-document-lifecycle-warning',
  templateUrl: './document-lifecycle-warning.component.html',
  styleUrls: ['./document-lifecycle-warning.component.scss']
})
export class DocumentLifecycleWarningComponent {
  @Input() document: SlimDocument | Document;
  @Output() documentChange = new EventEmitter<SlimDocument | Document>();
  private _creditAmount: number;

  constructor(
    private credentialsService: CredentialsService,
    private errorHandlerService: ErrorHandlerService,
    private appService: AppService,
    private oauthService: OauthService,
    private authenticationService: AuthenticationService,
    private authLoginModalService: AuthLoginModalService,
    private preLoginModalService: PreLoginModalService,
    private userExistsModalService: UserExistsModalService,
    private restoreLifecycleModalService: RestoreLifecycleModalService
  ) {}

  creditAmount() {
    if (this._creditAmount) {
      return this._creditAmount;
    } else {
      const amount = this.appService.creditPriceByType(CreditTypeEnum.DocumentLifecycleRescue);
      if (amount && amount > 0) {
        this._creditAmount = amount;
      }
      return this.appService.creditPriceByType(CreditTypeEnum.DocumentLifecycleRescue);
    }
  }

  openRestoreLifecycleModal() {
    const authModal$ = this.credentialsService.isAuthenticated()
      ? of(null)
      : this.preLoginModalService.open().pipe(
          switchMap(({ option, additionalInfo }) => {
            if (option === AuthenticationMethod.Email) {
              return this.userExistsModalService.open({ nextWhen: 'exists' }).pipe(switchMap(({ email }) => this.authLoginModalService.open({ email })));
            } else {
              return this.oauthService.login(option, additionalInfo).pipe(switchMap(oauth => this.authenticationService.login({ code: oauth.accessToken, phone_number: oauth.phoneNumber }, option)));
            }
          })
        );

    authModal$.pipe(switchMap(() => this.restoreLifecycleModalService.open({ document: this.document }))).subscribe(
      document => this.documentChange.emit(document),
      error => this.errorHandlerService.handle(error)
    );
  }
}
