import { Component } from '@angular/core';
import { AppService } from '@app/services';

@Component({
  selector: 'app-maintenance',
  templateUrl: './banned.component.html',
  styleUrls: ['./banned.component.scss']
})
export class BannedComponent {
  constructor(public appService: AppService) {}
}
