<div class="holder">
  <div class="holderSquare">
    <div class="square"></div>
  </div>
  <div class="middle">
    <div class="middle-line1"></div>
    <div class="middle-line2"></div>
  </div>
  <div class="last"></div>
</div>
