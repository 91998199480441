<div class="tokenField__container" [class.disabled]="isVerifyingToken">
  <label class="tokenField__field-label">
    <input
      class="tokenField__field"
      type="tel"
      autocomplete="one-time-code"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      pattern="[0-9]"
      maxlength="1"
      [disabled]="isVerifyingToken"
      (keydown)="checkField($event)"
      (change)="$event.stopPropagation(); $event.preventDefault()"
      (paste)="pasteOnFields($event)"
      #tokenField
    />
    <div class="tokenField__field-carot"></div>
  </label>
  <label class="tokenField__field-label">
    <input
      class="tokenField__field"
      type="tel"
      autocomplete="one-time-code"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      pattern="[0-9]"
      maxlength="1"
      [disabled]="isVerifyingToken"
      (keydown)="checkField($event)"
      (change)="$event.stopPropagation(); $event.preventDefault()"
      (paste)="pasteOnFields($event)"
      #tokenField
    />
    <div class="tokenField__field-carot"></div>
  </label>
  <label class="tokenField__field-label">
    <input
      class="tokenField__field"
      type="tel"
      autocomplete="one-time-code"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      pattern="[0-9]"
      maxlength="1"
      [disabled]="isVerifyingToken"
      (keydown)="checkField($event)"
      (change)="$event.stopPropagation(); $event.preventDefault()"
      (paste)="pasteOnFields($event)"
      #tokenField
    />
    <div class="tokenField__field-carot"></div>
  </label>
  <label class="tokenField__field-label">
    <input
      class="tokenField__field"
      type="tel"
      autocomplete="one-time-code"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      pattern="[0-9]"
      maxlength="1"
      [disabled]="isVerifyingToken"
      (keydown)="checkField($event)"
      (change)="$event.stopPropagation(); $event.preventDefault()"
      (paste)="pasteOnFields($event)"
      #tokenField
    />
    <div class="tokenField__field-carot"></div>
  </label>
  <label class="tokenField__field-label">
    <input
      class="tokenField__field"
      type="tel"
      autocomplete="one-time-code"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      pattern="[0-9]"
      maxlength="1"
      [disabled]="isVerifyingToken"
      (keydown)="checkField($event)"
      (change)="$event.stopPropagation(); $event.preventDefault()"
      (paste)="pasteOnFields($event)"
      #tokenField
    />
    <div class="tokenField__field-carot"></div>
  </label>
  <label class="tokenField__field-label">
    <input
      class="tokenField__field"
      type="tel"
      autocomplete="one-time-code"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      pattern="[0-9]"
      maxlength="1"
      [disabled]="isVerifyingToken"
      (keydown)="checkField($event)"
      (change)="$event.stopPropagation(); $event.preventDefault()"
      (paste)="pasteOnFields($event)"
      #tokenField
    />
    <div class="tokenField__field-carot"></div>
  </label>
</div>
