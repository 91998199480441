import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { User } from '@app/models';
import { UserService } from '@app/services';

@Component({
  selector: 'menu-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  currentUser: User;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => (this.currentUser = user));
  }

  ngOnDestroy() {}
}
