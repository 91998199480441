<article class="configuration-item d-none d-lg-block">
  <div class="configuration-container">
    <div class="configuration-content">
      <div class="configuration-title mb-1">
        {{ 'whitelabel.customizeSignedDocumentOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text">{{ 'whitelabel.customizeSignedDocumentDescription' | translate }}</div>
    </div>
    <div class="configuration-generate">
      <button type="button" class="btn btn-link text-danger" (click)="changeContext(contextMenuState.default)">{{ 'button.cancel' | translate }}</button>
      <button type="button" class="configuration-button" (click)="submitForm()">{{ 'button.save' | translate }}</button>
    </div>
  </div>
  <div class="configuration-container holder">
    <div class="holder-uploads">
      <div class="holder-uploads-btn">
        <div class="configuration-generate buttonPosition">
          <label class="btn btn-sm logoBtn">
            <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectPdfLogoImage($event.target['files'][0]); $any($event.target).value = ''" />
            <div class="configuration-button buttonSize">{{ 'whitelabel.headerImage' | translate }}</div>
          </label>
        </div>
        <div class="warning">
          <i class="fal fa-exclamation-circle"></i>
          <p>{{ 'whitelabel.headerImageNote' | translate }}</p>
        </div>
      </div>
      <div class="holder-uploads-btn">
        <div class="configuration-generate buttonPosition">
          <label class="btn btn-sm logoBtn">
            <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectPdfLogoMiniImage($event.target['files'][0]); $any($event.target).value = ''" />
            <div class="configuration-button buttonSize">{{ 'whitelabel.squareImage' | translate }}</div>
          </label>
        </div>
        <div class="warning">
          <i class="fal fa-exclamation-circle"></i>
          <p>{{ 'whitelabel.squareImageNote' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="holder-preview">
      <app-signed-document [whitelabelData]="whitelabelData" [selectedPdfLogoImageUrl]="selectedPdfLogoImageUrl" [selectedPdfLogoMiniImageUrl]="selectedPdfLogoMiniImageUrl"></app-signed-document>
    </div>
  </div>
</article>
