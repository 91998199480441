import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public message: string;

  constructor(private translateService: TranslateService) {
    const loaderMessages = this.translateService.instant('loaderMessages');

    if (!Array.isArray(loaderMessages)) return;
    this.message = loaderMessages[Math.floor(loaderMessages.length * Math.random())];
  }

  ngOnInit() {}
}
