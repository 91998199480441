import { Injectable } from '@angular/core';
import { timer, Subscription, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountdownTimerService {
  private timerSubscription: Subscription | undefined;
  private countdownInProgress: boolean = false;

  startCountdown(totalSeconds: number): Observable<string> {
    this.countdownInProgress = true;

    return new Observable(observer => {
      this.timerSubscription = timer(0, 1000)
        .pipe(takeWhile(() => totalSeconds > 0))
        .subscribe(
          () => {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            const formattedTime = (hours > 0 ? `${hours}h ` : '') + (minutes > 0 ? `${minutes}m ` : '') + (seconds > 0 || (hours === 0 && minutes === 0) ? `${seconds}s` : '');

            observer.next(formattedTime);
            totalSeconds--;
          },
          () => {},
          () => {
            this.countdownInProgress = false;
            observer.complete();
          }
        );
    });
  }

  stopCountdown() {
    this.timerSubscription?.unsubscribe();
    this.countdownInProgress = false;
  }

  isCountdownInProgress(): boolean {
    return this.countdownInProgress;
  }

  startCountdownForWhatsApp() {
    return this.startCountdown(40);
  }
}
