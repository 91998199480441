import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as SecureLS from 'secure-ls';

import { User } from '@app/models';

export interface CertificateStorage {
  file: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  isUsingLocalStorage: boolean;
  private secureLS: SecureLS;
  private lastUser: User;

  constructor(private cookieService: CookieService) {
    try {
      localStorage.getItem('test'); // Test if localStorage works
      this.isUsingLocalStorage = true;
    } catch (error) {
      this.isUsingLocalStorage = false;
    }
  }

  get(key: string) {
    if (this.isUsingLocalStorage) {
      return sessionStorage.getItem(key) || localStorage.getItem(key) || null;
    } else {
      return this.cookieService.get(key) || null;
    }
  }

  set(key: string, value: string | number | boolean, useSessionStorage: boolean = false) {
    if (this.isUsingLocalStorage) {
      (useSessionStorage ? sessionStorage : localStorage).setItem(key, String(value));
    } else {
      this.cookieService.set(key, String(value), useSessionStorage ? null : new Date(new Date().setFullYear(new Date().getFullYear() + 1)), '/');
    }
  }

  remove(key: string) {
    if (this.isUsingLocalStorage) {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
    } else {
      this.cookieService.delete(key);
    }
  }

  getJSON(key: string) {
    return JSON.parse(this.get(key));
  }

  setJSON(key: string, value: any, useSessionStorage: boolean = false) {
    this.set(key, JSON.stringify(value), useSessionStorage);
  }

  getA1CertificateData(user: User): CertificateStorage {
    if (this.isUsingLocalStorage) {
      this.setSecureLS(user);
      return this.secureLS.get(`a1:${user.id}`);
    }
  }

  setA1CertificateData(user: User, value: CertificateStorage) {
    if (this.isUsingLocalStorage) {
      this.setSecureLS(user);
      this.secureLS.set(`a1:${user.id}`, value);
    }
  }

  removeA1CertificateData(user: User) {
    if (this.isUsingLocalStorage) {
      this.setSecureLS(user);
      this.secureLS.remove(`a1:${user.id}`);
    }
  }

  private setSecureLS(user: User) {
    if (this.isUsingLocalStorage && (!this.secureLS || (this.lastUser && user && this.lastUser.id !== user.id))) {
      this.secureLS = new SecureLS({ encodingType: 'aes', isCompression: true, encryptionNamespace: user.id, encryptionSecret: user.newHash });
      this.lastUser = user;
    }
  }
}
