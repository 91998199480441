import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { cloneDeep, merge } from 'lodash';

import { FontEnum, SignatureFormat, Signer, Document, SlimDocument, User } from '@app/models';
import { DocumentService, ErrorHandlerService, NotyService, UserService } from '@app/services';
import { SignaturePadComponent } from '@app/shared/signature-pad/signature-pad.component';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = void;
export interface ModalPublicProperties {
  user: User;
  document: SlimDocument | Document;
  signers: Signer[];
  disableSignature?: boolean;
  disableInitials?: boolean;
}

@Component({
  selector: 'app-choose-signature-modal',
  templateUrl: './choose-signature-modal.component.html',
  styleUrls: ['./choose-signature-modal.component.scss']
})
export class ChooseSignatureModalComponent implements AfterContentInit, ModalPublicProperties {
  @Input() user: User;
  @Input() signers: Signer[];
  @Input() document: SlimDocument | Document;
  @Input() disableSignature: boolean;
  @Input() disableInitials: boolean;
  form!: FormGroup;
  SignatureFormat = SignatureFormat;
  fonts = {
    [FontEnum.Autograf]: 'font_enum_autograf',
    [FontEnum.Fathur]: 'font_enum_farthur',
    [FontEnum.Robertson]: 'font_enum_robertson'
  };
  isLoading = false;
  @ViewChild('signaturePad', { static: false }) private signaturePad: SignaturePadComponent;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private userService: UserService,
    private documentService: DocumentService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      user: this.formBuilder.group({ file_image: null, file_draw: null, font: FontEnum.Autograf, format: SignatureFormat.Handwriting })
    });
  }

  ngAfterContentInit() {
    if (this.user && this.user.settings && this.user.settings.font) {
      this.form.get('user.font').setValue(this.user.settings.font);
    }
    if (this.document && this.document.configs) {
      this.form.get('user.format').setValue(this.documentService.signatureAppearanceToFormats[this.document.configs.signature_appearance] || SignatureFormat.Handwriting);
    }
  }

  update() {
    this.form.markAllAsTouched();
    let signatureFile: File;
    let initialsFile: File;
    const signatureFormat = this.form.get('user.format').value;

    if (this.signaturePad) {
      if (signatureFormat === SignatureFormat.Draw && this.signaturePad.isEmpty()) {
        this.notyService.error(this.translateService.instant('notyService.emptySignatureDrawing'));
        return;
      } else if (signatureFormat === SignatureFormat.Image && this.signaturePad.isEmpty()) {
        this.notyService.error(this.translateService.instant('notyService.emptySignatureImage'));
        return;
      } else if ([SignatureFormat.Image, SignatureFormat.Draw].includes(signatureFormat)) {
        signatureFile = this.signaturePad.toImageFile('signature');
        initialsFile = this.signaturePad.toImageFile('initials');
      }
    }

    const signatureParams = { ...cloneDeep(this.form.value || {}) };
    const initialsParams = { ...cloneDeep(this.form.value || {}) };
    const fileParamName = signatureFormat === SignatureFormat.Draw ? 'file_draw' : 'file_image';
    signatureParams.user[fileParamName] = signatureFile;
    initialsParams.user[fileParamName] = initialsFile;
    [signatureParams, initialsParams].forEach(formParams => {
      ['file_draw', 'file_image'].forEach(paramName => {
        if (formParams.user && !formParams.user[paramName]) {
          delete formParams.user[paramName];
        }
      });
    });

    if (this.document && this.document.configs && this.document.configs.signature_appearance && this.user && this.user.settings && this.user.settings.format) {
      signatureParams.user.format = this.user.settings.format;
      initialsParams.user.format = this.user.settings.format;
    }

    this.isLoading = true;
    (this.disableSignature ? of(null) : this.userService.updateCurrentUserSignature(signatureParams))
      .pipe(
        this.replaceUserSettingsPipe(),
        switchMap(data => (this.disableInitials ? of(data) : this.userService.updateCurrentUserInitials(initialsParams))),
        this.replaceUserSettingsPipe(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => this.modal.close(),
        error => {
          const validationErrors = (this.errorHandlerService.handleValidation(this.form, error).validation || {}).user || {};
          const errorMessage = validationErrors.file_image || validationErrors.file_draw;
          if (errorMessage) {
            this.notyService.error(errorMessage);
          }
        }
      );
  }

  private replaceUserSettingsPipe() {
    return tap((data: User) => {
      if (data && data.settings && this.user && this.user.settings) {
        merge(this.user.settings, data.settings); // Sem cloneDeep. A intenção realmente é reescrever o objeto na memória para que nas próximas iterações com a mesma variável estejam atualizadas.
      }
    });
  }
}
