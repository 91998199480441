import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, UserService, AuthenticationService, NotyService, LoginContext } from '@app/services';
import { Signer, User } from '@app/models';
import { AuthEmailConfirmationModalService } from '../auth-email-confirmation-modal/auth-email-confirmation-modal.service';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = User;
export interface ModalPublicProperties {
  signer?: Signer;
  email?: string;
}

@Component({
  selector: 'app-auth-login-modal',
  templateUrl: './auth-login-modal.component.html',
  styleUrls: ['./auth-login-modal.component.scss']
})
export class AuthLoginModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() signer: Signer;
  @Input() email: string;
  form!: FormGroup;
  isLoading = false;
  @ViewChild('passwordInput', { static: false }) private passwordInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private notyService: NotyService,
    private authEmailConfirmationModalService: AuthEmailConfirmationModalService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({ email: '', password: '' } as LoginContext);
  }

  ngAfterContentInit() {
    setTimeout(() => this.passwordInput.nativeElement.focus());
    if (this.signer || this.email) {
      this.form.get('email').setValue(this.email || this.signer?.email || '');
    }
  }

  login() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.authenticationService.login(this.form.value).subscribe(
      () => {
        this.userService
          .getCurrentUser({ fetchPolicy: 'network-only' })
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            data => this.modal.close(data as ModalResult),
            error => this.errorHandlerService.handle(error)
          );
      },
      error => {
        this.isLoading = false;
        if (error && (error.status || (error.networkError && error.networkError.status)) === 424) {
          this.authEmailConfirmationModalService.open({ email: this.form.get('email').value }).subscribe(() => this.login());
        } else {
          this.errorHandlerService.handleValidation(this.form, error);
        }
      }
    );
  }

  sendResetPasswordConfirmation() {
    this.isLoading = true;
    this.authenticationService
      .sendResetPasswordConfirmation(this.form.get('email').value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => this.notyService.success(this.translateService.instant('notyService.resetPasswordEmailSent')),
        error => this.errorHandlerService.handle(error)
      );
  }
}
