import { Pipe, PipeTransform } from '@angular/core';

import { DeliveryMethodEnum, Document } from '@app/models';

export enum SignatureStepType {
  creation,
  emailDelivery,
  emailChange,
  openEmail,
  toSee,
  toSign
}
export enum SignatureStepStatus {
  waiting,
  deferred,
  bounce,
  dropped,
  blocked,
  spamreport,
  success,
  failed,
  unapproved,
  cellSMS,
  cellWhats
}
export interface SignatureStep {
  type: SignatureStepType;
  status: SignatureStepStatus;
  isActive?: boolean;
  previousEmails?: string[];
}

@Pipe({ name: 'toSignatureSteps' })
export class ToSignatureStepsPipe implements PipeTransform {
  constructor() {}

  transform(signer: Document['signatures'][0], options: { documentAuthorId?: string }): SignatureStep[] {
    const steps: SignatureStep[] = [];

    if (signer.user && options.documentAuthorId && options.documentAuthorId === signer.user.id) {
      this.addOwnerStep(steps);
    } else if (signer.action) {
      if (signer.parent) {
        this.addEmailChangeStep(steps, signer);
      }
      if (signer.email_events && (signer.email || (signer.user?.phone && [DeliveryMethodEnum.DeliveryMethodWhatsapp, DeliveryMethodEnum.DeliveryMethodSms].includes(signer.delivery_method)))) {
        this.addEmailDeliveryStep(steps, signer);
      }
      if (
        signer.email_events &&
        (signer.email || (signer.user?.phone && [DeliveryMethodEnum.DeliveryMethodWhatsapp, DeliveryMethodEnum.DeliveryMethodSms].includes(signer.delivery_method))) &&
        !signer.email_events.refused &&
        (!(signer.viewed || {}).created_at || signer.email_events.opened)
      ) {
        this.addOpenEmailStep(steps, signer);
      }
      this.addToSeeStep(steps, signer);
    }
    if (signer.action) {
      this.addToSignStep(steps, signer);
    }

    // Quando o documento já tiver sido assinado ou rejeitado, remove etapa de envio de email se o email não tiver sido enviado
    const emailDeliveryStep = steps.find(step => step.type === SignatureStepType.emailDelivery);
    const toSignStep = steps.find(step => step.type === SignatureStepType.toSign);
    if (emailDeliveryStep && toSignStep && emailDeliveryStep.status !== SignatureStepStatus.success && toSignStep.status !== SignatureStepStatus.waiting) {
      steps.splice(
        steps.findIndex(step => step === emailDeliveryStep),
        1
      );
    }

    this.findAndSetActiveStep(steps);

    return steps;
  }

  private addOwnerStep(steps: SignatureStep[]) {
    steps.push({ type: SignatureStepType.creation, status: SignatureStepStatus.success });
  }

  private addEmailChangeStep(steps: SignatureStep[], signer: Document['signatures'][0]) {
    steps.push({ type: SignatureStepType.emailChange, status: SignatureStepStatus.waiting, previousEmails: this.getPreviousEmailsRecursively(signer) });
  }

  private addEmailDeliveryStep(steps: SignatureStep[], signer: Document['signatures'][0]) {
    const step: SignatureStep = { type: SignatureStepType.emailDelivery, status: SignatureStepStatus.waiting };

    if (signer.email_events) {
      if (signer.email_events.refused) {
        step.status = SignatureStepStatus.failed;
      }
      if ((signer.email_events.reason || '').match(/deferred/i)) {
        step.status = SignatureStepStatus.deferred;
      }
      if ((signer.email_events.reason || '').match(/Bounce:/i)) {
        step.status = SignatureStepStatus.bounce;
      }
      if ((signer.email_events.reason || '').match(/dropped/i)) {
        step.status = SignatureStepStatus.dropped;
      }
      if ((signer.email_events.reason || '').match(/spamreport/i)) {
        step.status = SignatureStepStatus.spamreport;
      }
      if ((signer.email_events.reason || '').match(/blocked/i)) {
        step.status = SignatureStepStatus.blocked;
      }
      if (signer.email_events.reason && signer.delivery_method === DeliveryMethodEnum.DeliveryMethodSms && signer.viewed === null) {
        step.status = SignatureStepStatus.cellSMS;
      }
      if (signer.email_events.reason && signer.delivery_method === DeliveryMethodEnum.DeliveryMethodWhatsapp && signer.viewed === null) {
        step.status = SignatureStepStatus.cellWhats;
      }
      if (signer.email_events.delivered) {
        step.status = SignatureStepStatus.success;
      }
    }

    steps.push(step);
  }

  private addOpenEmailStep(steps: SignatureStep[], signer: Document['signatures'][0]) {
    steps.push({
      type: SignatureStepType.openEmail,
      status: signer.email_events && signer.email_events.opened ? SignatureStepStatus.success : SignatureStepStatus.waiting
    });
  }

  private addToSeeStep(steps: SignatureStep[], signer: Document['signatures'][0]) {
    steps.push({
      type: SignatureStepType.toSee,
      status: signer.viewed && signer.viewed.created_at ? SignatureStepStatus.success : SignatureStepStatus.waiting
    });
  }

  private addToSignStep(steps: SignatureStep[], signer: Document['signatures'][0]) {
    const step: SignatureStep = { type: SignatureStepType.toSign, status: SignatureStepStatus.waiting };

    if ((signer.rejected || {}).created_at || (signer.biometric_rejected || {}).created_at) {
      step.status = SignatureStepStatus.failed;
    } else if ((signer.signed || {}).created_at || (signer.biometric_approved || {}).created_at) {
      step.status = SignatureStepStatus.success;
    } else if ((signer.signed_unapproved || {}).created_at) {
      step.status = SignatureStepStatus.unapproved;
    }

    steps.push(step);
  }

  private getPreviousEmailsRecursively(signer?: Document['signatures'][0], result: string[] = []) {
    result = result || [];
    if (signer) {
      if (signer.parent) {
        result = this.getPreviousEmailsRecursively(typeof signer.parent === 'string' ? JSON.parse(signer.parent) : signer.parent, result);
      }
      if (signer.email) {
        result.push(signer.email);
      }
    }
    return result;
  }

  private findAndSetActiveStep(steps: SignatureStep[]) {
    if (steps.length > 0) {
      let lastStep: SignatureStep;

      steps.forEach((step, index) => {
        if ([SignatureStepStatus.success, SignatureStepStatus.failed, SignatureStepStatus.unapproved].includes(step.status)) {
          lastStep = step;
        }
        if (!lastStep && index === steps.length - 1) {
          lastStep = steps[steps[0].type === SignatureStepType.emailChange ? 1 : 0];
        }
      });

      if (lastStep) {
        lastStep.isActive = true;
      }
    }
  }
}
