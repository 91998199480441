<div class="d-flex">
  <app-mobile-header-main
    (titleClicked)="openTaxonomyMenu = $event"
    mainTitle="{{ 'documents.foldersOfUser' | translate: { user: currentMember?.user?.name } }}"
    menulink="/menu/pastas"
  ></app-mobile-header-main>
  <div class="taxonomy-overlay" [class.taxonomy-overlay-active]="openTaxonomyMenu" (click)="openTaxonomyMenu = false"></div>
  <app-taxonomy
    *ngIf="whitelabelService.params.sidebar"
    mainTitle="{{ 'menu.folders' | translate }}"
    allowSearch
    [(isSearchOpen)]="isSearchOpen"
    (isSearchOpenChange)="toggleSearch($event)"
    [class.isMenuMobileOpen]="openTaxonomyMenu"
  >
    <app-documents-taxonomy
      [isSearchOpen]="isSearchOpen"
      [disableMainButton]="!isMemberCurrentUser || !currentUser?.currentPermissions.actions_folders_oz"
      [listPath]="'member'"
      (newFolderButtonClick)="openCreateFolderModal()"
      (sortingChange)="loadFolders({ page: 1, search: searchQuery })"
    ></app-documents-taxonomy>
  </app-taxonomy>
</div>

<div class="d-flex flex-column w-100">
  <app-documents-search
    [isFoldersSearch]="true"
    [(search)]="searchQuery"
    (searchChange)="pageNumber = 1; loadFolders({ page: 1, search: $event })"
    (closeClick)="isSearchOpen = false; toggleSearch(isSearchOpen)"
    *ngIf="isSearchOpen"
    @slideDownUpTrigger
  ></app-documents-search>

  <div class="documents">
    <div class="documents__header">
      <h2 class="documents__title" *ngIf="!((isSearchOpen && searchQuery) || mobileSearch) && currentMember">{{ 'documents.foldersOfUser' | translate: { user: currentMember?.user?.name } }}</h2>
      <h2 class="documents__title" *ngIf="(isSearchOpen && searchQuery) || mobileSearch">{{ 'documents.searchResults' | translate }}</h2>
    </div>

    <div class="documents-empty" *ngIf="!isLoading && folders && folders.length === 0 && !searchQuery && !mobileSearch; else foldersEmptyWithFilters">
      <i class="fal fa-folder-open documents-empty__icon"></i>
      {{ 'documents.nothingHereYet' | translate }}
    </div>

    <ng-template #foldersEmptyWithFilters>
      <div class="documents-filtered-empty" *ngIf="!isLoading && folders && folders.length === 0 && (searchQuery || mobileSearch)">
        <div class="documents-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="documents-filtered-empty_text">
          <span>{{ 'documents.noFoldersMatchingFilter' | translate }}</span>
        </div>
        <button type="button" class="default-button" (click)="clearFilters()">{{ 'documents.clearFilters' | translate }}</button>
      </div>
    </ng-template>

    <app-folders-list-boxes
      [folders]="folders"
      [linkPath]="['/pastas', ':id', 'organizacao', currentMember?.user?.id, 'documentos']"
      [hidePagination]="isLoading || isLastPage"
      [hideDropdownMenu]="!isMemberCurrentUser || !currentUser?.currentPermissions.actions_folders_oz"
      (loadPageClick)="loadFolders({ page: pageNumber + 1, search: searchQuery })"
      (updateClick)="openUpdateFolderModal($event)"
      (deleteClick)="deleteFolder($event)"
    >
    </app-folders-list-boxes>
  </div>
</div>
