import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concat, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, takeWhile, tap, toArray } from 'rxjs/operators';
import { cloneDeep, flatten, merge, set } from 'lodash';

import { environment } from '@env/environment';
import { CredentialsService, I18nService, untilDestroyed } from '@app/core';
import { AppService, AuthenticationService, DocumentService, ErrorHandlerService, NotyService, OauthService, PadesSignatureData, UserService, WhitelabelService } from '@app/services';
import {
  AuthenticationMethod,
  DeliveryMethodEnum,
  Document,
  FloatingField,
  ReminderEnum,
  SecurityVerificationEnum,
  SecurityVerificationType,
  SignatureToken,
  SignatureTypeEnum,
  SignatureTypesEnum,
  Signer,
  User,
  Whitelabel
} from '@app/models';
import { AlertModalService, LoaderService, PdfViewerComponent, SetMissingUserInfoModalService } from '@app/shared';
import { CertificateModalService } from '@app/shared/certificate-modal/certificate-modal.service';
import { AuthLoginModalService } from './auth-login-modal/auth-login-modal.service';
import { AuthRegisterModalService } from './auth-register-modal/auth-register-modal.service';
import { CreateSignerModalService } from '../shared/create-signer-modal/create-signer-modal.service';
import { UpdateSignerModalService } from './update-signer-modal/update-signer-modal.service';
import { SignLinkModalService } from './sign-link-modal/sign-link-modal.service';
import { ConsultCPFModalService } from './consult-cpf-modal/consult-cpf-modal.service';
import { DocumentHistoryComponent } from './document-history-sidebar/document-history-sidebar.component';
import { DocumentCreatedModalService } from './document-created-modal/document-created-modal.service';
import { DocumentSignedModalService } from './document-signed-modal/document-signed-modal.service';
import { PreLoginModalService } from './pre-login-modal/pre-login-modal.service';
import { UserExistsModalService } from './user-exists-modal/user-exists-modal.service';
import { ChooseSignatureModalService } from './choose-signature-modal/choose-signature-modal.service';
import { PreSignModalService } from '../shared/pre-sign-modal/pre-sign-modal.service';
import { RejectModalService } from './reject-modal/reject-modal.service';
import { VerificationSmsModalService } from '@app/pages/documents/shared/verification-sms-modal/verification-sms-modal.service';
import { VerificationIdAnalyzerModalService } from '@app/pages/documents/shared/verification-id-analyzer-modal/verification-id-analyzer-modal.service';
import { VerificationModalService } from '@app/pages/documents/shared/verification-modal/verification-modal.service';
import { ManualBiometryApprovalEnum, VerificationDataModalService } from '@app/pages/documents/shared/verification-data-modal/verification-data-modal.service';

export type CustomSigner = Signer & { signature_id: string };

@Component({
  selector: 'app-documents-show',
  templateUrl: './documents-show.component.html',
  styleUrls: ['./documents-show.component.scss']
})
export class DocumentsShowComponent implements OnInit, OnDestroy {
  isLoading = false;
  currentUser: User;
  document: Document;
  currentSigners: CustomSigner[] = [];
  floatingFields: FloatingField[];
  isShowingSignersMobile = false;
  isShowingHistoryMobile = false;
  isHistoryAlreadyOpened = false;
  isShowingMobileMenu = false;
  doABarrelScroll = true;
  isShowingMobileMenuDownloads = false;
  isShowingMobileMenuAlteration = false;
  maxScroll: number;
  screen = document.documentElement;
  sidebarContainer: 'signers' | 'history' = 'signers';
  backLink = '/documentos/todos';
  isDocumentWhitelabelLoaded = false;
  readonly ReminderEnum = ReminderEnum;
  @ViewChild('pdfViewer', { static: false }) pdfViewer: PdfViewerComponent;
  @ViewChild('historyComponent', { static: false }) historyComponent: DocumentHistoryComponent;
  @ViewChild('historyComponentMobile', { static: false }) historyComponentMobile: DocumentHistoryComponent;

  documentWhitelabel: Whitelabel = {
    main_color: null,
    logo: '',
    pdf_logo: '',
    pdf_logo_mini: ''
  };
  private navigationState: { [k: string]: any };

  constructor(
    public appService: AppService,
    public documentService: DocumentService,
    public userService: UserService,
    public whitelabelService: WhitelabelService,
    private route: ActivatedRoute,
    private router: Router,
    private notyService: NotyService,
    private alertModalService: AlertModalService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private oauthService: OauthService,
    private loaderService: LoaderService,
    private authLoginModalService: AuthLoginModalService,
    private authRegisterModalService: AuthRegisterModalService,
    private createSignerModalService: CreateSignerModalService,
    private updateSignerModalService: UpdateSignerModalService,
    private signLinkModalService: SignLinkModalService,
    private consultCPFModalService: ConsultCPFModalService,
    private preLoginModalService: PreLoginModalService,
    private documentCreatedModalService: DocumentCreatedModalService,
    private documentSignedModalService: DocumentSignedModalService,
    private userExistsModalService: UserExistsModalService,
    private chooseSignatureModalService: ChooseSignatureModalService,
    private setMissingUserInfoModalService: SetMissingUserInfoModalService,
    private preSignModalService: PreSignModalService,
    private rejectModalService: RejectModalService,
    private certificateModalService: CertificateModalService,
    private verificationSmsModalService: VerificationSmsModalService,
    private verificationIdAnalyzerModalService: VerificationIdAnalyzerModalService,
    private verificationModalService: VerificationModalService,
    private verificationDataModalService: VerificationDataModalService
  ) {
    this.navigationState = router.getCurrentNavigation().extras.state || {};
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateBackLink();
    });
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.loadDocument(this.route.snapshot.params.id).subscribe(([document, currentSigners]) => {
      this.isDocumentWhitelabelLoaded = false;
      this.whitelabelService.loadWhitelabelByDocument({ documentId: document.id }).subscribe(whitelabel => {
        this.documentWhitelabel = whitelabel;
        this.documentWhitelabel.logo = whitelabel.logo || this.whitelabelService.logo;
        this.documentWhitelabel.pdf_logo = whitelabel.pdf_logo || this.whitelabelService.pdfLogo;
        this.documentWhitelabel.pdf_logo_mini = whitelabel.pdf_logo_mini || this.whitelabelService.pdfLogoMini;
        this.documentWhitelabel.main_color = whitelabel.main_color || this.whitelabelService.mainColor;

        if (whitelabel.pdf_logo_mini) {
          this.whitelabelService.setFavicon(whitelabel.pdf_logo_mini);
        }
        this.isDocumentWhitelabelLoaded = true;
      });

      if (this.navigationState.fromCreateDocument) {
        this.documentCreatedModalService
          .open({ document, currentSigner: currentSigners[0] })
          .pipe(
            switchMap(() => this.setMissingUserInfoModalService.openIfMissingUserInfo({ ignoreCpf: this.document.ignore_cpf, forceCpfAs: currentSigners[0] && currentSigners[0].configs.cpf })),
            switchMap(() => this.openPreSignModal()),
            switchMap(data => this.signDocumentRequest(this.currentSigners, data)),
            tap(() => this.documentSignedModalService.open({ currentSigner: currentSigners[0], document }).subscribe())
          )
          .subscribe(
            () => {},
            error => this.errorHandlerService.handle(error)
          );
      } else if (this.route.snapshot.queryParams?.signPrompt && this.canBeSigned(document, currentSigners[0]) && this.documentService.isSignerTurn(document, currentSigners[0])) {
        this.signDocumentPrompt();
      }
    });

    if (!this.isSignaturePage()) {
      this.appService.loadStatusPageWidget();
    }
    this.updateBackLink();
  }

  ngOnDestroy() {}

  loadDocument(id: string) {
    return new Observable<[Document, Signer[]]>(subscriber => {
      id = (id || 'empty').replace(/[^a-z0-9]/gi, ''); // Gambiarra pra evitar erros caso o ID não esteja no padrão do tipo "UUID" do GraphQL
      let signature$;
      let signTokens$ = this.documentService.authSignToken({ documentsIds: [id] });
      if (this.isSignatureByLink(id)) {
        signature$ = this.documentService.linkSignature({ documentId: id });
        signTokens$ = null;
      } else if (this.isSignaturePage()) {
        signature$ = this.documentService.signature({ documentId: id });
        signTokens$ = this.documentService.publicSignToken({ signatureId: id });
      } else if (!this.credentialsService.isAuthenticated()) {
        signTokens$ = null;
      }

      if (signTokens$) {
        signTokens$ = signTokens$.pipe(
          this.setCurrentSignersPipe(),
          tap(() => this.viewDocument(this.currentSigners)),
          tap(
            () => subscriber.next([this.document, this.currentSigners]),
            error => subscriber.error(error),
            () => subscriber.complete()
          )
        );
      }

      if (signature$) {
        signature$.subscribe(
          signature =>
            this.getDocument(signature.document.id)
              .pipe(
                // Signatário temporário (links)
                tap(() => (this.currentSigners = [{ signature_id: null, ...this.document.signatures.find(sig => sig.public_id === signature.public_id) }])),
                switchMap(doc => {
                  const currentSigner = doc.signatures.find(sig => sig.public_id === signature.public_id);
                  if (this.documentService.isPastDeadline(doc) && !this.isOwner() && currentSigner && this.documentService.isSignerPending(currentSigner, { ignoreEmailErrors: true })) {
                    this.router.navigate(['/404'], { replaceUrl: true, state: { fromDocumentUrl: location.href } });
                    return EMPTY;
                  } else {
                    return of(doc);
                  }
                }),
                tap(() => signTokens$ && signTokens$.subscribe())
              )
              .subscribe(
                doc => !signTokens$ && subscriber.next([doc, []]),
                error => !signTokens$ && subscriber.error(error),
                () => !signTokens$ && subscriber.complete()
              ),
          error => {
            this.errorHandlerService.handle(error, { ignoreNotificationOn: ['signature_not_found', 'link_not_found', 'document_not_found'] });
            subscriber.error(error);
            this.router.navigate(['/404'], { replaceUrl: true, state: { fromDocumentUrl: location.href } });
          }
        );
      } else {
        this.getDocument(id)
          .pipe(tap(() => signTokens$ && signTokens$.subscribe()))
          .subscribe(
            doc => !signTokens$ && subscriber.next([doc, []]),
            error => !signTokens$ && subscriber.error(error),
            () => !signTokens$ && subscriber.complete()
          );
      }
    });
  }

  getDocument(id: string) {
    return this.documentService.documentWithFloatingFields({ id }).pipe(
      tap(
        ({ document, floatingFields }) => {
          this.document = document;
          this.floatingFields = floatingFields;
          setTimeout(() => {
            this.maxScroll = this.screen.scrollHeight - this.screen.clientHeight;
            if (this.maxScroll === 0 && this.scrollingRequired()) {
              this.doABarrelScroll = false;
            }
          }, 10000);
          if (!this.documentService.isPastLifecycle(this.document) && !this.document.files?.original) {
            setTimeout(
              () =>
                this.documentService
                  .retryDocumentFile({ id })
                  .pipe(untilDestroyed(this))
                  .subscribe(data => (this.document.files.original = data.files.original)),
              2000
            );
          }
        },
        error => {
          this.errorHandlerService.handle(error, { ignoreNotificationOn: ['document_not_found'] });
          this.router.navigate(['/404'], { replaceUrl: true, state: { fromDocumentUrl: location.href } });
        }
      ),
      map(({ document }) => document)
    );
  }

  viewDocument(signers: CustomSigner[]) {
    if (!this.isSignatureByLink()) {
      const signatureIds = signers
        .filter(signer => !signer.viewed || !signer.viewed.created_at)
        .map(signer => signer.signature_id)
        .filter(id => !!id);
      this.documentService.view(signatureIds).subscribe(
        data => {
          const signatures = cloneDeep(this.document.signatures);
          (data || []).forEach(item =>
            set(
              signatures.find(sig => sig.public_id === item.public_id),
              'viewed',
              item.viewed
            )
          );
          this.document.signatures = signatures;
        },
        error => this.errorHandlerService.handle(error)
      );
    }
  }

  signDocumentPrompt() {
    if (this.document.refusable && this.document.stop_on_rejected && this.documentService.isRejected(this.document, { ignoreEmailErrors: true })) {
      this.notyService.warning(this.translateService.instant('notyService.cannotSignAfterRejection'));
    } else if (
      this.currentSigners.length > 0 &&
      this.currentSigners[0].user &&
      ((this.currentSigners[0].user.email && this.currentSigners[0].user.name) || this.isPhoneDeliveryMethod(this.currentSigners[0]))
    ) {
      if (this.currentUser && this.currentUser.id === this.currentSigners[0].user.id) {
        // Logado e assinar
        this.openPreSignModal()
          .pipe(switchMap(data => this.signDocument(data)))
          .subscribe(
            () => {},
            error => this.errorHandlerService.handle(error)
          );
      } else if (this.isPhoneDeliveryMethod(this.currentSigners[0]) || (!this.currentUser && !this.isSignatureByLink(this.route.snapshot.params.id))) {
        // Logar passwordless e assinar com link por Email, SMS e Whatsapp
        this.isLoading = true;
        this.authenticationService
          .login({ signature_id: this.currentSigners[0].signature_id }, AuthenticationMethod.Passwordless)
          .pipe(
            switchMap(() => this.userService.getCurrentUser({ fetchPolicy: 'network-only' })),
            tap(
              () => (this.isLoading = false),
              () => (this.isLoading = false)
            ),
            switchMap(() => this.openPreSignModal()),
            switchMap(data => this.signDocument(data))
          )
          .subscribe(
            () => {},
            error => this.errorHandlerService.handle(error)
          );
      } else {
        // Logar e assinar
        this.authLoginModalService
          .open({ signer: this.currentSigners[0] })
          .pipe(
            switchMap(() => this.openPreSignModal()),
            switchMap(data => this.signDocument(data))
          )
          .subscribe(
            () => {},
            error => this.errorHandlerService.handle(error)
          );
      }
    } else if (this.currentSigners.length > 0 && this.currentSigners[0].user && this.currentSigners[0].user.email && !this.currentSigners[0].user.name) {
      if (this.credentialsService.isAuthenticated() && this.currentUser && this.currentUser.id === this.currentSigners[0].user.id) {
        // Logado e assinar
        this.openPreSignModal()
          .pipe(switchMap(data => this.signDocument(data)))
          .subscribe(
            () => {},
            error => this.errorHandlerService.handle(error)
          );
      } else {
        // Cadastro e assinar
        this.authRegisterModalService
          .open({ signer: this.currentSigners[0], ignoreCpf: this.document.ignore_cpf }, {}, true)
          .pipe(finalize(() => this.loadDocument(this.route.snapshot.params.id).subscribe())) // Evita que dê problemas por conta já ter sido cadastrada, após fechar as modais
          .subscribe(
            () =>
              this.openPreSignModal()
                .pipe(switchMap(data => this.signDocument(data)))
                .subscribe(
                  () => {},
                  error => this.errorHandlerService.handle(error)
                ),
            () => {}
          );
      }
    } else if (this.credentialsService.isAuthenticated()) {
      // Logado e assinar por link
      this.openPreSignModal()
        .pipe(switchMap(data => this.signDocument(data)))
        .subscribe(
          () => {},
          error => this.errorHandlerService.handle(error)
        );
    } else {
      // Logar/Cadastrar e assinar por link
      this.preLoginModalService.open().subscribe(({ option, additionalInfo }) => {
        if (option === AuthenticationMethod.Email) {
          this.userExistsModalService.open().subscribe(({ userExists, email }) => {
            (userExists ? this.authLoginModalService : this.authRegisterModalService).open({ signer: this.currentSigners[0], email, ignoreCpf: this.document.ignore_cpf }).subscribe(() =>
              this.loadDocument(this.route.snapshot.params.id).subscribe(() => {
                this.openPreSignModal()
                  .pipe(switchMap(data => this.signDocument(data)))
                  .subscribe(
                    () => {},
                    error => this.errorHandlerService.handle(error)
                  );
              })
            );
          });
        } else if ([AuthenticationMethod.Google, AuthenticationMethod.Facebook, AuthenticationMethod.Microsoft, AuthenticationMethod.Sms, AuthenticationMethod.Whatsapp].includes(option)) {
          this.isLoading = true;
          this.oauthService.login(option, { ...additionalInfo, forceUserCreation: true }).subscribe(
            oauth => {
              this.loaderService.show();
              this.authenticationService
                .login({ code: oauth.accessToken, phone_number: oauth.phoneNumber }, option)
                .pipe(
                  tap(
                    () => (this.isLoading = false),
                    () => (this.isLoading = false)
                  )
                )
                .subscribe(
                  () => {
                    this.loaderService.hide();
                    this.loadDocument(this.route.snapshot.params.id).subscribe(() => {
                      this.openPreSignModal().subscribe(
                        data => {
                          this.loaderService.show();
                          this.signDocument(data)
                            .pipe(finalize(() => this.loaderService.hide()))
                            .subscribe();
                        },
                        error => {
                          this.loaderService.hide();
                          this.errorHandlerService.handle(error);
                        }
                      );
                    });
                  },
                  error => {
                    this.loaderService.hide();
                    this.errorHandlerService.handle(error);
                  }
                );
            },
            () => (this.isLoading = false)
          );
        }
      });
    }
  }

  rejectDocumentPrompt() {
    this.rejectModalService.open().subscribe(data =>
      this.rejectDocumentRequest(this.currentSigners, data.reason || null).subscribe(
        () => {},
        error => this.errorHandlerService.handle(error)
      )
    );
  }

  resendSignatures(signer?: Signer) {
    const signers = signer
      ? [signer]
      : this.document.signatures.filter(
          sig =>
            sig.delivery_method !== DeliveryMethodEnum.DeliveryMethodLink &&
            !this.isOwner(sig.user) &&
            this.documentService.isSignerPending(sig, { ignoreEmailErrors: true }) &&
            !this.documentService.isSignerUnapproved(sig)
        );
    if (signers.length > 0) {
      const creditsPrice = signers.reduce((total, sig) => total + this.appService.creditPriceByType(sig.delivery_method), 0);
      this.alertModalService
        .confirmation({ text: this.translateService.instant('alerts.resendDocument'), creditsPrice })
        .pipe(
          tap(() => this.loaderService.show()),
          switchMap(() => this.documentService.resendSignatures({ publicIds: signers.map(sig => sig.public_id) })),
          tap(() => (creditsPrice > 0 ? this.userService.currentUserCredits().subscribe() : null)),
          finalize(() => this.loaderService.hide())
        )
        .subscribe(
          () => {
            this.notyService.success(this.translateService.instant('notyService.' + (signers.length > 1 ? 'documentResentToSignatories' : 'documentResentToSignatory')));
            signers.forEach(sig => (sig.email_events.refused = null));
            this.getDocument(this.document.id).subscribe();
            if (this.isHistoryAlreadyOpened) this.historyComponent.refreshHistory();
          },
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  deleteDocument() {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.deleteDocument'),
        confirmButtonText: this.translateService.instant('button.exclude')
      })
      .subscribe(() => {
        this.isLoading = true;
        this.documentService
          .delete([this.document.id])
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            () => {
              this.notyService.success(this.translateService.instant('notyService.documentDeletedSuccess'));
              this.router.navigate(['/documentos/todos'], { replaceUrl: true });
            },
            error => this.errorHandlerService.handle(error)
          );
      });
  }

  deleteSigner(signer: Signer) {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.removeSignatory')
      })
      .subscribe(() => {
        this.isLoading = true;
        this.documentService
          .deleteSigner({ documentId: this.document.id, signerPublicId: signer.public_id })
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            () => this.loadDocument(this.document.id).subscribe(),
            error => this.errorHandlerService.handle(error)
          );
      });
  }

  openCreateSignerModal() {
    this.createSignerModalService.open({ document: this.document }).subscribe(() => this.getDocument(this.document.id).subscribe());
  }

  openUpdateSignerModal(signer: Signer) {
    this.updateSignerModalService.open({ signer }).subscribe(data => {
      if (data) {
        signer.email = data.email;
        signer.public_id = data.public_id || signer.public_id;
        signer.parent = data.parent || signer.parent;
        signer.user.name = data.user.name;
        signer.user.cpf = data.user.cpf;
        signer.user.birthday = data.user.birthday;
        signer.configs.overwrite_name = null;
        signer.configs.overwrite_date = null;
      }
    });
  }

  openSignLinkModal(signer: Signer) {
    this.signLinkModalService.open({ signer }).subscribe();
  }

  openConsultCPFModal(signer: Signer) {
    this.consultCPFModalService.open({ signer }).subscribe(() => this.getDocument(this.document.id).subscribe());
  }

  openCertificateModal(signer: Signer) {
    this.certificateModalService.open({ signer }).subscribe();
  }

  openVerificationDataModal(signer: Signer) {
    this.verificationDataModalService
      .open({ signer, hideManualApproval: !this.isAdmin() }, signer.verifications.find(verification => verification.type === SecurityVerificationEnum.Live) ? { size: 'lg' } : {})
      .pipe(filter(data => !!data))
      .subscribe(({ choice, verification }) => {
        this.loaderService.show();
        this.documentService[choice === ManualBiometryApprovalEnum.Accepted ? 'approveBiometric' : 'rejectBiometric']({ publicId: signer.public_id, verificationId: verification.id })
          .pipe(finalize(() => this.loaderService.hide()))
          .subscribe(
            () => {
              this.getDocument(this.document.id).subscribe();
              if (this.isHistoryAlreadyOpened) this.historyComponent.refreshHistory();
            },
            error => this.errorHandlerService.handle(error)
          );
      });
  }

  clearPdfCache() {
    this.documentService.clearPdfCache({ id: this.document.id }).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  resendDocumentWebhook() {
    this.documentService.resendWebhook({ documentId: this.document.id }).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentResendWebhook')),
      error => this.errorHandlerService.handle(error)
    );
  }

  isOwner(user?: User | Signer['user']) {
    user = user || this.currentUser;
    if (this.document && user) {
      return user.id === this.document.author.id;
    }
  }

  isAdmin() {
    return this.currentUser && this.document && this.documentService.isAdmin(this.currentUser, this.document);
  }

  isAutnq(user?: User) {
    user = user || this.currentUser;
    return !!(user && (this.currentUser.email || '').match(/@autentique.com.br$/));
  }

  isCurrentSigner(signer: Signer) {
    return (this.currentSigners || []).map(sig => sig.public_id).includes(signer.public_id);
  }

  isSignaturePage() {
    return !!location.href.match('/assinar/.+');
  }

  isSignatureByLink(id?: string) {
    return (id || this.route.snapshot.params.id).length < 32;
  }

  isPhoneDeliveryMethod(signer: CustomSigner) {
    return [DeliveryMethodEnum.DeliveryMethodSms, DeliveryMethodEnum.DeliveryMethodWhatsapp].includes(signer.delivery_method) && signer.user.phone;
  }

  canBeSigned(document?: Document, signer?: Signer) {
    document = document || this.document;
    signer = signer || this.currentSigners[0];
    return (
      !!signer?.action &&
      !document.is_blocked &&
      !this.documentService.isPastDeadline(document) &&
      !this.documentService.isSignerUnapproved(signer) &&
      this.documentService.isSignerPending(signer, { ignoreEmailErrors: true })
    );
  }

  scrollingRequired() {
    if (this.document.scrolling_required) {
      return this.doABarrelScroll;
    } else return false;
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true }));
  }

  changeSidebarContainer(container: 'signers' | 'history') {
    if (container === 'signers') this.sidebarContainer = 'signers';
    if (container === 'history' && this.document) {
      if (this.historyComponent.documentData.id == null && this.isShowingHistoryMobile === false) {
        this.historyComponent.documentData = {
          id: this.document.id,
          created_at: this.document.created_at,
          author: {
            name: this.document.author.name,
            email: this.document.author.email
          }
        };
        this.historyComponent.refreshHistory();
      }
      if (this.historyComponentMobile.documentData.id == null && this.isShowingHistoryMobile === true) {
        this.historyComponentMobile.documentData = {
          id: this.document.id,
          created_at: this.document.created_at,
          author: {
            name: this.document.author.name,
            email: this.document.author.email
          }
        };
        this.historyComponentMobile.refreshHistory();
      }

      this.sidebarContainer = 'history';
      this.isHistoryAlreadyOpened = true;
    }
  }

  toggleBlock() {
    const isBlocked = this.documentService.isPastDeadline(this.document);
    this.alertModalService
      .confirmation({
        text:
          this.translateService.instant(isBlocked ? 'alerts.unlockDocument' : 'alerts.lockDocument') +
          this.translateService.instant(isBlocked ? 'alerts.allowPendingSigners' : 'alerts.preventPendingSigners')
      })
      .pipe(switchMap(() => this.documentService[isBlocked ? 'unblock' : 'block']([this.document.id])))
      .subscribe(
        () => this.notyService.success(this.translateService.instant('notyService.' + (isBlocked ? 'documentUnlockedSuccess' : 'documentLockedSuccess'))),
        error => this.errorHandlerService.handle(error)
      );
  }

  backButtonLabel() {
    const url = this.appService.previousUrl;
    if (url === '/documentos/grupo') {
      return this.translateService.instant('menu.wholeGroup');
    } else if (url === '/documentos/organizacao') {
      return this.translateService.instant('menu.wholeOrganization');
    } else if ((url || '').includes('/documentos/grupo/')) {
      return this.translateService.instant('menu.documentsGroup');
    } else if ((url || '').includes('/documentos/organizacao/')) {
      return this.translateService.instant('menu.documentsUser');
    } else if ((url || '').includes('/pastas/')) {
      return this.translateService.instant('menu.documentsFolder');
    } else {
      return this.translateService.instant('menu.myDocuments');
    }
  }

  updateBackLink(): void {
    const url = this.appService.previousUrl;
    if (
      url === '/documentos/grupo' ||
      url === '/documentos/organizacao' ||
      (url || '').includes('/documentos/grupo/') ||
      (url || '').includes('/documentos/organizacao/') ||
      (url || '').includes('/pastas/')
    ) {
      this.backLink = url;
    } else {
      this.backLink = '/documentos/todos';
    }
  }

  needsElectronLogin() {
    return environment.electron && !this.credentialsService.isAuthenticated() && !this.isSignaturePage();
  }

  redirectToLogin() {
    this.router.navigate(['/entrar'], { queryParams: { redirect: location.pathname } });
  }

  sendSignerEventWithPostMessage(eventMessage: 'signed' | 'rejected' = 'signed') {
    return tap(() => {
      if (!['signed', 'rejected'].includes(eventMessage)) return;
      window.parent.postMessage(eventMessage, '*');
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTop = window.scrollY;
    const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if (scrollTop >= maxScroll - 40) {
      this.doABarrelScroll = false;
    }
  }
  @HostListener('window:touchmove', ['$event'])
  onWindowTouch() {
    const scrollTop = window.scrollY;
    const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if (scrollTop >= maxScroll - 150) {
      this.doABarrelScroll = false;
    }
  }

  private signDocument(padesSignatureData: PadesSignatureData) {
    const signers = this.currentSigners;
    return this.signDocumentRequest(signers, padesSignatureData).pipe(tap(() => this.documentSignedModalService.open({ currentSigner: signers[0], document: this.document }).subscribe()));
  }

  private signDocumentRequest(signers: CustomSigner[], padesSignatureData: PadesSignatureData) {
    this.isLoading = true;
    this.loaderService.show();
    return of(null).pipe(
      switchMap(() => {
        let ids = signers.map(signer => signer.signature_id).filter(id => !!id);
        ids = ids.length === 0 ? [this.route.snapshot.params.id] : ids;
        if (this.document.qualified) {
          return [SignatureTypesEnum.Safeid, SignatureTypesEnum.Vidaas].includes(padesSignatureData.type)
            ? this.documentService.signCloudCert({ id: ids[0], type: padesSignatureData.type })
            : this.documentService.signPades({ id: ids[0], type: padesSignatureData.type, payload: padesSignatureData.padesSignature });
        } else {
          return this.documentService.sign(ids);
        }
      }),
      this.sendSignerEventWithPostMessage('signed'),
      finalize(() => {
        this.isLoading = false;
        this.loaderService.hide();
      }),
      this.afterSignPipe()
    );
  }

  private rejectDocumentRequest(signers: CustomSigner[], reason?: string) {
    this.isLoading = true;
    return this.documentService
      .reject(
        this.isSignatureByLink() ? [this.route.snapshot.params.id] : signers.map(signer => signer.signature_id).filter(id => !!id),
        this.isSignatureByLink() ? SignatureTypeEnum.Link : SignatureTypeEnum.Signature,
        reason || null
      )
      .pipe(
        this.sendSignerEventWithPostMessage('rejected'),
        finalize(() => {
          this.isLoading = false;
        }),
        this.afterSignPipe()
      );
  }

  private openPreSignModal() {
    return new Observable<User>(subscriber => {
      // Não está sendo usado of() para esperar que o this.currentUser seja preenchido
      subscriber.next(this.currentUser);
      subscriber.complete();
    }).pipe(
      map(user => !user || user.currentPermissions.sign_documents),
      tap(canProceed => !canProceed && this.notyService.error(this.translateService.instant('error_without_permission_sign_documents'))),
      takeWhile(canProceed => canProceed),
      switchMap(() => this.setUserInfoPipe()),
      map(() =>
        flatten(this.currentSigners.map(signer => signer.verifications?.filter(value => !!value))).map(verification =>
          (verification.type === SecurityVerificationEnum.Sms
            ? this.verificationSmsModalService
            : [SecurityVerificationEnum.Upload, SecurityVerificationEnum.Live].includes(verification.type)
            ? this.verificationIdAnalyzerModalService
            : this.verificationModalService
          )
            .open({ verification, dismissAsError: true })
            .pipe(map(data => merge(verification, data)))
        )
      ),
      switchMap(verificationsBiometryModals => concat(...verificationsBiometryModals).pipe(toArray())),
      switchMap(data =>
        data && data.find(v => !v.verified_at && !this.documentService.allowedManualVerifications.includes(v.type))
          ? of(null).pipe(
              this.afterSignPipe(),
              switchMap(() => of())
            )
          : of(data)
      ),
      catchError(err => (err ? throwError(err) : of())),
      switchMap((data: SecurityVerificationType[]) =>
        this.preSignModalService.open({
          action: this.currentSigners[0].action.name,
          padesSignatureId: this.currentSigners[0].signature_id || this.route.snapshot.params.id,
          document: this.document,
          hasFailedBiometry: !!(data || []).find(
            verification => !verification.verified_at && verification.type !== SecurityVerificationEnum.Manual && this.documentService.allowedManualVerifications.includes(verification.type)
          )
        })
      )
    );
  }

  private setUserInfoPipe(signers?: CustomSigner[]) {
    const currentSigners = signers || this.currentSigners;
    const serproVerification = currentSigners.find(signer => (signer.verifications || []).find(verification => verification.type === SecurityVerificationEnum.PfFacial)); // Serpro precisa CPF
    return this.setMissingUserInfoModalService
      .openIfMissingUserInfo({ ignoreEmailAndPassword: true, ignoreCpf: this.document.ignore_cpf && !serproVerification, forceCpfAs: currentSigners[0] && currentSigners[0].configs.cpf })
      .pipe(switchMap(() => this.chooseSignatureModalService.open({ user: this.currentUser, document: this.document, signers: currentSigners })));
  }

  private afterSignPipe() {
    return tap(() => {
      this.currentSigners = [];
      this.getDocument(this.document.id).subscribe();
      if (this.isHistoryAlreadyOpened) this.historyComponent.refreshHistory();
    });
  }

  private setCurrentSignersPipe() {
    return tap<SignatureToken[]>(
      tokens => (this.currentSigners = tokens.map(token => ({ signature_id: token.signature_id, ...this.document.signatures.find(sig => sig.public_id === token.signature_public_id) })))
    );
  }
}
