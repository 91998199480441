import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { AuthenticationMethod } from '@app/models';

export type ModalResult = {
  option: AuthenticationMethod;
  additionalInfo: any;
};

@Component({
  selector: 'app-pre-login-modal',
  templateUrl: './pre-login-modal.component.html',
  styleUrls: ['./pre-login-modal.component.scss']
})
export class PreLoginModalComponent {
  readonly Result = AuthenticationMethod;
  readonly isElectron = environment.electron;

  constructor(public modal: NgbActiveModal) {}

  emit(option: AuthenticationMethod, additionalInfo?: any) {
    this.modal.close({ option, additionalInfo } as ModalResult);
  }
}
