import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@app/core';

import { MainComponent } from './main/main.component';

import { DocumentsComponent } from './documents/documents.component';
import { FoldersComponent } from './folders/folders.component';
import { ConfigurationComponent } from './configuration/configuration.component';

import { DocumentsGroupComponent } from './documents-group/documents-group.component';
import { DocumentsOrganizationComponent } from './documents-organization/documents-organization.component';
import { FoldersGroupComponent } from './folders-group/folders-group.component';
import { FoldersOrganizationComponent } from './folders-organization/folders-organization.component';

import { ChangeOrganizationComponent } from '@app/pages/menu/change-organization/change-organization.component';

const routes: Routes = [
  {
    path: 'menu',
    component: MainComponent,
    canActivate: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: '/menu/documentos', pathMatch: 'full' }, // ''
      { path: 'documentos', component: DocumentsComponent, data: { title: 'Meus documentos' } }, // 'documentos'
      { path: 'documentos/grupo', component: DocumentsGroupComponent, data: { title: 'Documentos do grupo' } }, // 'documentos/grupo'
      { path: 'documentos/organizacao', component: DocumentsOrganizationComponent, data: { title: 'Documentos da organização' } }, // 'documentos/organizacao'
      { path: 'pastas', component: FoldersComponent, data: { title: 'Minhas pastas' } }, // 'pastas'
      { path: 'pastas/grupo', component: FoldersGroupComponent, data: { title: 'Pastas do grupo' } }, // 'pastas/grupo'
      { path: 'pastas/organizacao', component: FoldersOrganizationComponent, data: { title: 'Pastas da organização' } }, // 'pastas/organizacao'
      { path: 'configuracoes', component: ConfigurationComponent, data: { title: 'Configurações' } }, // 'configuracoes'
      { path: 'alterar/organizacao', component: ChangeOrganizationComponent, data: { title: 'Alterar organização' } } // 'alterar/organizacao'
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule {}
