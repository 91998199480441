import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '@app/shared';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthRegisterComponent } from './auth-register/auth-register.component';
import { AuthPasswordNewComponent } from './auth-password-new/auth-password-new.component';
import { AuthContainerBrandComponent } from './auth-container-brand/auth-container-brand.component';
import { AuthContainerComponent } from './auth-container/auth-container.component';
import { AuthPasswordEditComponent } from './auth-password-edit/auth-password-edit.component';
import { AuthEmailConfirmationComponent } from './auth-email-confirmation/auth-email-confirmation.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AuthLoginComponent, AuthRegisterComponent, AuthPasswordNewComponent, AuthContainerBrandComponent, AuthContainerComponent, AuthPasswordEditComponent, AuthEmailConfirmationComponent],
  imports: [ReactiveFormsModule, FormsModule, CommonModule, RouterModule, AuthRoutingModule, SharedModule, NgxMaskModule.forRoot({}), TranslateModule.forRoot()]
})
export class AuthModule {}
