import { Injectable } from '@angular/core';
import { Angulartics2Intercom } from 'angulartics2/intercom';
import { merge } from 'lodash';

import { environment } from '@env/environment';
import { User } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class IntercomService extends Angulartics2Intercom {
  private _isBooted = false;

  super() {}

  get isBooted() {
    return this._isBooted;
  }

  boot() {
    Intercom('boot', { app_id: environment.intercomAppId, custom_launcher_selector: '.open-intercom' });
    this._isBooted = true;
  }

  startTracking() {
    if (!this.isBooted) {
      this.boot();
    }
    super.startTracking();
  }

  setUserProperties(user: User, options?: Intercom_.IntercomSettings) {
    super.setUserProperties(merge(this.parseUser(user), options || {}));
  }

  show() {
    Intercom('update', { hide_default_launcher: false });
  }

  hide() {
    Intercom('update', { hide_default_launcher: true });
  }

  private parseUser(user: User) {
    return {
      user_id: user.email || user.phone,
      uuid: user.id,
      user_hash: user.user_hash,
      name: user.name,
      email: user.email,
      created_at: user.created_at,
      phone: user.phone,
      cpf: user.cpf,
      cnpj: user.cnpj,
      birthday: user.birthday,
      email_verified_at: user.email_verified_at,
      company: user.company ? { id: (user.organization || {}).id || user.company, name: user.company, cnpj: user.cnpj } : null,
      organization_id: (user.organization || {}).id,
      gateway: user.subscription?.iugu_id ? (user.subscription.iugu_id.startsWith('sub_') ? 'Stripe' : 'Iugu') : null,
      iugu_customer: user.organization?.billing_customer?.iugu_id ? `https://alia.iugu.com/contact/customers/${user.organization?.billing_customer?.iugu_id}` : null,
      stripe_customer: user.organization?.billing_customer?.stripe_id ? `https://dashboard.stripe.com/customers/${user.organization?.billing_customer?.stripe_id}` : null,
      settings_format: user.settings.format,
      settings_signature: user.settings.signature,
      settings_webhook_url: ((user.organization || {}).settings || {}).webhook_url,
      settings_webhook_format: ((user.organization || {}).settings || {}).webhook_format,
      subscription_name: user.subscription.name,
      subscription_created_at: user.subscription.created_at,
      subscription_documents: user.subscription.documents,
      subscription_bonus: user.subscription.bonus,
      subscription_expires_at: user.subscription.expires_at,
      subscription_has_premium_features: user.subscription.has_premium_features,
      subscription_iugu_id: user.subscription.iugu_id,
      subscription_iugu_plan: user.subscription.iugu_plan
    };
  }
}
