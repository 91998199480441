import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { set, merge } from 'lodash';

import { Logger } from '@app/core';
import { GraphQLResponse } from '@app/models';
import { NotyService } from './noty.service';

const log = new Logger('ErrorHandlerService');
const errorPrefix = 'error_';

export interface HandledError {
  message: string;
  validation: ValidationErrors | null;
}

export interface ErrorHandlerOptions {
  transformMap?: { [p: string]: string };
  ignoreGraphqlErrorNotifications?: boolean;
  ignoreNotificationOn?: string[];
  validationMode?: boolean;
}

/**
 * Service to handle the main GraphQL API errors.
 */
@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private notyService: NotyService, private translateService: TranslateService) {}

  /**
   * Handles the main GraphQL API requests' errors and returns them formatted.
   * @param errorData Error response from the API.
   * @param options Options to tweak the error handler.
   * @param options.transformMap Replaces the string from the validation key to another value. Ex: { '.name': '.full_name' }
   * @param options.ignoreGraphqlErrorNotifications If true, does not send any notifications that have non standard messages (Common GraphQL errors).
   * @param options.ignoreNotificationOn Array with the messages from the API that should not send a notification.
   * @return The translated message and formatted validations.
   */
  handle(errorData: GraphQLResponse, options: ErrorHandlerOptions = {}): HandledError {
    if (!errorData) {
      return null;
    }

    errorData = errorData.networkError ? errorData.networkError : errorData;
    const errors = (((errorData || { errors: [] }).errors || errorData.graphQLErrors || [])[0] || {}) as GraphQLResponse['errors'][0];
    const errorMessage = errors.message || 'unknown';
    let validation: { [k: string]: string } = null;

    if (Object.keys(errors.validation || errors.errors || errors.extensions?.validation || {}).length > 0) {
      // TODO: Em requests REST está vindo outra 'message', usar (errorMessage === 'validation' &&) quando isso estiver certo
      validation = this.parseValidation(errors.validation || errors.errors || errors.extensions?.validation, options.transformMap || {});
      if (!options.validationMode && validation) {
        Object.keys(validation).forEach(key => this.notyService.error(`${key}: ${typeof validation[key] === 'object' ? Object.values(validation[key])[0] : validation[key]}`));
      }
    } else if (this.isGraphqlError(errorMessage)) {
      if (!options.ignoreGraphqlErrorNotifications) {
        log.error(errorMessage, errors);
        this.notyService.error(this.getErrorMessage(errorMessage) || errorMessage);
      }
    } else if (!['validation', 'unknown', 'token_invalid', 'unauthenticated.'].includes((errorMessage || '').toLowerCase())) {
      if (!(options.ignoreNotificationOn || []).includes(errors.message)) {
        this.notyService.error(this.getErrorMessage(errorMessage) || errorMessage);
      }
    } else if (![401, 423, 429].includes(errorData.status)) {
      log.error(errorMessage, errors);
      this.notyService.error(this.getErrorMessage(errorMessage) || errorMessage);
    }

    return { message: errorMessage, validation };
  }

  /**
   * Handles the main GraphQL API requests' errors, set them on an Angular FormGroup labelled as 'server' and returns them formatted.
   * @param formGroup Angular's FormGroup instance to set the validation errors labelled as 'server'.
   * @param errorData Error response from the API.
   * @param options Options to tweak the error handler.
   * @param options.transformMap Replaces the string from the validation key to another value. Ex: { '.name': '.full_name' }
   * @param options.ignoreGraphqlErrorNotifications If true, does not send any notifications that have non standard messages (Common GraphQL errors).
   * @param options.ignoreNotificationOn Array with the messages from the API that should not send a notification.
   * @return The translated message and formatted validations.
   */
  handleValidation(formGroup: FormGroup, errorData: GraphQLResponse, options: ErrorHandlerOptions = {}) {
    const handledError = this.handle(errorData, merge(options, { validationMode: true }));
    this.setValidation(formGroup, handledError.validation);
    return handledError;
  }

  /**
   * Sets the main GraphQL API requests' errors on an Angular FormGroup labelled as 'server'.
   * @param formGroup Angular's FormGroup instance to set the validation errors labelled as 'server'.
   * @param validation Formatted validation errors. Ex: { user: { name: 'Some error message.' } }
   */
  setValidation(formGroup: FormGroup, validation: ValidationErrors) {
    this.recursiveSetValidation(formGroup, validation);
  }

  private recursiveSetValidation(formGroup: FormGroup, validation: ValidationErrors | ValidationErrors[], path?: string) {
    const iterationFunction = (key: string, value: any, iterationPath: string) => {
      const currentPath = iterationPath ? iterationPath + '.' + key : key;
      if (['string', 'number'].includes(typeof value)) {
        if (formGroup.get(currentPath)) {
          formGroup.get(currentPath).setErrors({ server: value.toString() });
        }
      } else {
        this.recursiveSetValidation(formGroup, value, currentPath);
      }
    };

    if (Array.isArray(validation)) {
      validation.forEach((value, index) => iterationFunction(index.toString(), value, path));
    } else {
      for (const [key, value] of Object.entries(validation || {})) {
        iterationFunction(key, value, path);
      }
    }
  }

  private getErrorMessage(errorKey: string, interpolationParams?: { [p: string]: any } | string) {
    const key = errorPrefix + errorKey;

    if (interpolationParams && typeof interpolationParams === 'string') {
      const errorMessage = this.translateService.instant(key);
      const variableNames = errorMessage.match(/{{(.*?)}}/g)?.map((match: string) => match.slice(2, -2));

      interpolationParams = variableNames?.reduce((acc: { [k: string]: any }, variableName: string, index: number) => {
        acc[variableName] = interpolationParams.split(',')[index];
        return acc;
      }, {});
    }

    const message = this.translateService.instant(key, interpolationParams);
    return message === key ? null : message;
  }

  private parseValidation(validationErrors: GraphQLResponse['errors'][0]['validation'], transformMap: ErrorHandlerOptions['transformMap']) {
    const result = {};

    for (const [validationKey, validationValue] of Object.entries(validationErrors)) {
      let currentValidationKey = validationKey;
      let errorKey = validationValue[0];
      let variables;
      if (typeof errorKey === 'object') {
        [errorKey, variables] = Object.entries(errorKey)[0];
      } else if (typeof errorKey === 'string') {
        variables = errorKey.split(':')[1];
        errorKey = errorKey.split(':')[0];
      }

      for (const [mapKey, mapValue] of Object.entries(transformMap)) {
        if (validationKey.match(mapKey)) {
          currentValidationKey = validationKey.replace(mapKey, mapValue);
          break;
        }
      }

      set(result, currentValidationKey, this.getErrorMessage(errorKey, variables) || errorKey);

      if (!this.getErrorMessage(errorKey)) {
        log.error('ValidationMessageMissing: "' + errorKey + '"', validationErrors);
      }
    }

    return result;
  }

  private isGraphqlError(message: string) {
    return !!(message || '').match(/\s/);
  }
}
