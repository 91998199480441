import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';

import { Invoice } from '@app/models';
import { ErrorHandlerService, PaymentService } from '@app/services';
import { LoaderService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  invoices: Invoice[];
  isLoading: boolean;
  invoicePageNumber: number;
  invoiceIsLastPage = false;

  constructor(
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private loaderService: LoaderService,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.loadInvoices();
  }

  loadInvoices(options: { page?: number; isBuyCredits?: boolean } = {}) {
    this.isLoading = true;
    this.loaderService.show();
    this.invoicePageNumber = options.page || 1;
    this.paymentService
      .organizationInvoices({ organizationUuid: this.route.snapshot.params.id, limit: 60, page: this.invoicePageNumber })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        page => {
          this.invoices = (this.invoicePageNumber === 1 ? [] : this.invoices || []).concat(page.data);
          this.invoiceIsLastPage = page.current_page >= page.last_page;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  nfseUrl(invoice: Invoice, fileType?: 'pdf' | 'xml') {
    if (fileType === 'xml') {
      return invoice.url_nfse.replace(/\.pdf$/, '.xml');
    }
    return invoice.url_nfse;
  }

  openUrl(url: string) {
    open(url, '_blank');
  }
}
