import * as Sentry from '@sentry/angular';
import { Injectable, ErrorHandler } from '@angular/core';
import { merge } from 'lodash';
import { flatten } from 'flat';

import { environment } from '@env/environment';
import { Logger, LogLevel } from './logger.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private sentryErrorHandler: Sentry.SentryErrorHandler;
  private canSendToSentry = false;

  constructor() {
    this.sentryErrorHandler = Sentry.createErrorHandler({ showDialog: false });
    this.canSendToSentry = environment.production && !environment.debug && !!environment.sentryDsn && new RegExp(environment.validHostsRegExp, 'i').test(location.host);

    Sentry.init({
      dsn: environment.sentryDsn,
      enabled: this.canSendToSentry,
      environment: environment.production ? 'production' : 'staging',
      release: environment.commitHash,
      integrations: [
        new Sentry.BrowserTracing({
          tracingOrigins: ['localhost:9000', 'painel.autentique.com.br', 'dev.painel.autentique.com.br', 'valida.ae'],
          routingInstrumentation: Sentry.instrumentAngularRouting
        })
        // Sem chance, ainda trava pra vários
        // new Sentry.Replay({
        //   mutationLimit: 1000,
        //   mutationBreadcrumbLimit: 1000,
        //   blockAllMedia: false,
        //   maskAllText: false,
        //   maskAllInputs: false
        // })
      ],
      beforeBreadcrumb: this.beforeBreadcrumb,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
      ignoreErrors: [
        'validation',
        'document_not_found',
        'signature_not_found',
        'link_not_found',
        'popup_closed_by_user',
        'document_signed',
        'unavailable_credits',
        'could_not_upload_file',
        'not_your_turn',
        'NetworkError',
        'Network error: undefined',
        'TypeError: Failed to fetch',
        'unavailable_verifications_credits',
        'token_invalid',
        'DataCloneError'
      ]
    });

    Logger.outputs.push((source, level, objects) => {
      const levelsMap = {
        [LogLevel.Critical]: 'fatal',
        [LogLevel.Error]: 'error',
        [LogLevel.Warning]: 'warning',
        [LogLevel.Info]: 'info',
        [LogLevel.Debug]: 'debug'
      };

      if (this.canSendToSentry && levelsMap[level] && (objects || []).length > 0) {
        let featuredLog: any;
        if (typeof objects[0] === 'string' || (objects[0]?.message && objects[0]?.stack)) {
          // either if it's a string or an object from "Error" class
          featuredLog = objects.splice(0, 1)[0];
        }

        // Flattens complex error objects to be able to set each value as "Extra" on Sentry
        let flattenedErrorData = merge.apply(
          this,
          [{ source }].concat(objects).map((obj: any) => flatten(obj))
        );
        flattenedErrorData = Object.keys(flattenedErrorData).reduce(
          (result, key) => Object.assign(result, { ['data.' + key]: typeof flattenedErrorData[key] === 'string' ? flattenedErrorData[key] : JSON.stringify(flattenedErrorData[key]) }),
          {}
        );

        Sentry.withScope(scope => {
          scope.setLevel(levelsMap[Logger.level]);
          scope.setTag('data.source', source);
          Object.keys(flattenedErrorData).forEach(key => scope.setExtra(key, flattenedErrorData[key]));

          if (typeof featuredLog === 'string') {
            Sentry.captureMessage(source ? `[${source}] ${featuredLog}` : featuredLog);
          } else if (typeof featuredLog === 'object' && featuredLog !== null) {
            Sentry.captureException(featuredLog);
          } else {
            Sentry.captureMessage(`Something happened (${levelsMap[Logger.level]})`);
          }
        });
      }
    });
  }

  handleError(error: any) {
    const errorToSend = (error || {}).originalError || error;
    if (this.canSendToSentry) {
      this.sentryErrorHandler.handleError(errorToSend);
    } else {
      console.error(errorToSend);
    }
  }

  private beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) {
    if (breadcrumb.category === 'xhr') {
      const forbiddenOnXhr = [
        // contexto 'this' dá problema e não envia o XHR, então manter array aqui dentro
        'password',
        'current_password',
        'password_confirmation',
        'current',
        'new_confirmation',
        'token',
        'card_number',
        'cvv'
      ];
      const body = (hint.xhr.__sentry_xhr_v2__.body || '').substr(0, 300);
      const response = (hint.xhr.response || '').substr(0, 300);

      return {
        ...breadcrumb,
        data: {
          ...(breadcrumb.data || {}),
          ...(body && body.match(new RegExp(forbiddenOnXhr.join('|'))) ? {} : { body }),
          ...(response && response.match(new RegExp(forbiddenOnXhr.join('|'))) ? {} : { response })
        }
      };
    }
    return breadcrumb;
  }
}
