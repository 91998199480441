<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="update()">
  <div class="centermodal__title">
    {{ 'settings.permissionsOf' | translate }} <b>{{ group.name }}</b>
  </div>

  <ng-container formGroupName="permissions">
    <div class="centermodal__group">
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.overrideMemberPermissions' | translate }}</span>
        <app-switch formControlName="overwrite_permissions"></app-switch>
        <div class="textbox">
          <p class="centermodal__switch-description">{{ 'settings.permissionsOverrideByGroup' | translate }}</p>
        </div>
      </div>
    </div>

    <app-organization-permissions-form [form]="$any(form.get('permissions'))" [class.d-none]="!form.get('permissions.overwrite_permissions').value"></app-organization-permissions-form>
  </ng-container>

  <div class="centermodal__button-group mt-3">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.save' | translate }}</button>
  </div>
</form>
