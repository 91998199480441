import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '@app/models';
import { Router } from '@angular/router';

export interface ModalPublicProperties {
  currentUser: User;
}
export type RemainingTime = { days: number; hours: number; minutes: number };

@Component({
  selector: 'app-alert-credits-modal',
  templateUrl: './alert-documents-limit-modal.component.html',
  styleUrls: ['./alert-documents-limit-modal.component.scss']
})
export class AlertDocumentsLimitModalComponent implements OnInit {
  @Input() currentUser: User;
  isLoading = false;
  remain: RemainingTime = { days: 0, hours: 0, minutes: 0 };

  constructor(public modal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {
    this.remain = this.calcTimeDiff(this.currentUser.subscription.expires_at);
  }

  goToPayment(): void {
    this.router.navigate(['/perfil/planos']);
    this.modal.close();
  }

  calcTimeDiff(expiresAt: string): RemainingTime {
    const futureDate = new Date(expiresAt);
    // Fixes expiresAt to represent the same as the backend, as the hour when it actually expires
    futureDate.setDate(futureDate.getDate() + 1);
    futureDate.setHours(2, 0, 0, 0);

    const currentDate = new Date();
    const msDiff = futureDate.getTime() - currentDate.getTime();

    const daysDiff = Math.max(0, Math.floor(msDiff / (1000 * 60 * 60 * 24)));
    const hoursDiff = Math.max(0, Math.floor((msDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutesDiff = Math.max(0, Math.floor((msDiff % (1000 * 60 * 60)) / (1000 * 60)));

    return { days: daysDiff, hours: hoursDiff, minutes: minutesDiff };
  }
}
