<article class="configuration-item d-none d-lg-block">
  <div class="configuration-container">
    <div class="configuration-content">
      <div class="configuration-title mb-1">
        {{ 'whitelabel.subdomainForSignatureOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text">{{ 'whitelabel.urlCustomizationExplanation' | translate }}</div>
      <div class="configuration-switches-item mt-4 mb-4">
        <app-switch [(ngModel)]="subdomainToggle"></app-switch>
        <span class="configuration-switches-name fw400">{{ 'whitelabel.customizeDocumentURL' | translate }}</span>
      </div>
    </div>
    <div class="configuration-generate" *ngIf="whitelabelData.domain">
      <div class="warningDomain">
        <i class="far fa-exclamation-triangle"></i>
        <p>{{ 'whitelabel.changeSubdomainWarning' | translate }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="domainSteps === domainNameSteps.default && subdomainToggle" class="pageSize">
    <div class="configuration-subtitle">{{ 'whitelabel.subdomainNameForSignature' | translate }}</div>
    <div class="configuration-text configuration-subtitle-text">{{ 'whitelabel.subdomainNameExample' | translate }}</div>
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-lg-8">
            <label class="field__holder">
              <input
                type="text"
                class="field"
                [(ngModel)]="subdomainPlaceholder"
                [class.empty]="!subdomainPlaceholder && !whitelabelData?.domain"
                placeholder="{{ whitelabelData?.domain ? whitelabelData.domain : '' }}"
              />
              <span class="field__placeholder">{{ 'whitelabel.subdomain' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="domainSteps === domainNameSteps.verification && subdomainToggle" class="pageSize">
    <div class="configuration-subtitle">{{ 'whitelabel.DNSRecords' | translate }}</div>
    <div class="configuration-text configuration-subtitle-text">{{ 'whitelabel.includeRecordsInDNS' | translate }}</div>
    <table>
      <tr>
        <th class="th-1"></th>
        <th class="th-2">{{ 'whitelabel.recordType' | translate }}</th>
        <th class="th-3">{{ 'whitelabel.host' | translate }}</th>
        <th class="th-4">{{ 'whitelabel.value' | translate }}</th>
      </tr>
      <tr *ngIf="!isDomainLoading; else domainWithBlur">
        <td>
          <i *ngIf="(whitelabelData?.domain && !subdomainPlaceholder) || subdomainVerified" class="fa-regular fa-check-circle" style="color: #00c4a1;"></i>
          <i *ngIf="(!whitelabelData?.domain || subdomainPlaceholder) && !subdomainVerified" class="fa-regular fa-times-circle" style="color: #eb236f;"></i>
        </td>
        <td>{{ 'whitelabel.CNAMERecord' | translate }}</td>
        <td>
          <div class="line">
            <p class="line_1">
              {{ subdomainPlaceholder || whitelabelData?.domain || '' }}
            </p>
            <i class="fal fa-clone copyIcon" (click)="copyToClipboard(subdomainPlaceholder ? subdomainPlaceholder : whitelabelData?.domain)"></i>
          </div>
        </td>
        <td>
          <div class="line">
            <p class="line_2">
              {{ env.production ? 'painel.autentique.com.br' : 'devpainel.autentique.com.br' }}
            </p>
            <i class="fal fa-clone copyIcon" (click)="copyToClipboard(env.production ? 'painel.autentique.com.br' : 'devpainel.autentique.com.br')"></i>
          </div>
        </td>
      </tr>
      <ng-template #domainWithBlur>
        <tr class="">
          <td>
            <i class="fa-regular fa-times-circle" style="--fa-primary-color: #ffea00; --fa-secondary-color: #ffea00;"></i>
          </td>
          <td><div class="animatePlaceholder-content"></div></td>
          <td><div class="animatePlaceholder-content"></div></td>
          <td><div class="animatePlaceholder-content"></div></td>
        </tr>
      </ng-template>
    </table>
  </div>
  <div class="button_navigation">
    <button type="button" class="centermodal__button" (click)="changeContext(contextMenuState.default)">
      {{ domainSteps === domainNameSteps.default ? ('button.cancel' | translate) : ('button.back' | translate) }}
    </button>
    <div class="button_doubleBtn mt-3">
      <button
        *ngIf="domainSteps === domainNameSteps.verification && subdomainToggle"
        #verifyButton
        type="button"
        class="centermodal__button centermodal__button--custom-next configuration-button"
        (click)="verifyDomain()"
      >
        {{ 'button.verify' | translate }}
      </button>
      <button
        *ngIf="subdomainToggle"
        #saveButton
        type="button"
        class="centermodal__button centermodal__button--custom-next ml-3"
        (click)="domainForward()"
        [disabled]="!subdomainVerified && domainSteps === domainNameSteps.verification"
      >
        {{ (whitelabelData && !subdomainToggle) || domainSteps === domainNameSteps.verification ? ('button.save' | translate) : ('button.forward' | translate) }}
      </button>
      <button *ngIf="!subdomainToggle && whitelabelData.domain" type="button" class="centermodal__button centermodal__button--custom-next ml-3" (click)="domainForwardEmpty()">
        {{ 'button.save' | translate }}
      </button>
    </div>
  </div>
</article>
