import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService extends Angulartics2GoogleTagManager {
  private _isBooted = false;

  super() {}

  get isBooted() {
    return this._isBooted;
  }

  boot() {
    return new Observable<void>(subscriber => {
      if (environment.googleTagManagerId) {
        const scriptId = 'gtm-script';
        if (!this.isBooted && !document.getElementById(scriptId)) {
          const scriptElement = document.createElement('script');
          scriptElement.src = `https://www.googletagmanager.com/gtm.js?l=${(window as any).gtagLayerVar || 'dataLayer'}&id=${environment.googleTagManagerId}`;
          scriptElement.type = 'text/javascript';
          scriptElement.async = true;
          scriptElement.id = scriptId;
          scriptElement.onload = () => {
            this._isBooted = true;
            subscriber.next();
            subscriber.complete();
          };
          scriptElement.onerror = () => {
            this._isBooted = false;
            subscriber.error();
          };
          document.getElementsByTagName('head')[0].appendChild(scriptElement);
        } else {
          this._isBooted = true;
          subscriber.next();
          subscriber.complete();
        }
      } else {
        subscriber.complete();
      }
    });
  }

  startTracking() {
    if (!this.isBooted) {
      this.boot().subscribe(() => super.startTracking());
    } else {
      super.startTracking();
    }
  }
}
