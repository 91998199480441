import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { User, SignatureFormat, PositionElementEnum, FontEnum } from '@app/models';

@Component({
  selector: 'app-signature-preview',
  templateUrl: './signature-preview.component.html',
  styleUrls: ['./signature-preview.component.scss']
})
export class SignaturePreviewComponent implements OnChanges {
  @Input() user: User;
  @Input() type?: PositionElementEnum = PositionElementEnum.Signature;
  @Input() format?: SignatureFormat | string;
  @Input() font?: FontEnum | string;
  @Input() imageUrl?: string;
  @Input() drawingUrl?: string;
  PositionElementEnum = PositionElementEnum;
  SignatureFormat = SignatureFormat;

  constructor() {
    this.assignDefaultFormatAndImage();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.assignDefaultFormatAndImage();
  }

  private assignDefaultFormatAndImage() {
    if (this.user) {
      if (!this.format) {
        this.format = this.user.settings.format;
      }
      if (!this.imageUrl) {
        this.imageUrl = this.type === PositionElementEnum.Initials ? this.user.settings.initials : this.user.settings.signature;
      }
      if (!this.drawingUrl) {
        this.drawingUrl = this.type === PositionElementEnum.Initials ? this.user.settings.initials_draw : this.user.settings.signature_draw;
      }
    }
  }
}
