import { AfterContentInit, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { cloneDeep, omit } from 'lodash';

import { OrganizationGroup, OrganizationGroupPermissions } from '@app/models';
import { ErrorHandlerService, OrganizationService } from '@app/services';
import { finalize } from 'rxjs/operators';

export type ModalResult = OrganizationGroup;
export interface ModalPublicProperties {
  group: OrganizationGroup;
}

@Component({
  selector: 'app-create-organization-group-modal',
  templateUrl: './edit-organization-group-permissions-modal.component.html',
  styleUrls: ['./edit-organization-group-permissions-modal.component.scss']
})
export class EditOrganizationGroupPermissionsModalComponent implements ModalPublicProperties, AfterContentInit {
  form!: FormGroup;
  group: OrganizationGroup;
  isLoading = false;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private errorHandlerService: ErrorHandlerService, private organizationService: OrganizationService) {
    this.form = this.formBuilder.group({
      permissions: this.formBuilder.group({
        overwrite_permissions: true,
        create_documents: false,
        archive_documents: false,
        delete_documents: false,
        sign_documents: false,
        view_documents_gr: false,
        view_folders_gr: false,
        actions_documents_gr: false,
        actions_folders_gr: false,
        actions_templates_gr: false,
        view_documents_oz: false,
        view_folders_oz: false,
        view_member_documents_oz: false,
        view_member_folders_oz: false,
        view_group_documents_oz: false,
        view_group_folders_oz: false,
        view_invoices_oz: false,
        actions_documents_oz: false,
        actions_folders_oz: false,
        actions_members_oz: false,
        actions_groups_oz: false,
        actions_webhooks_oz: false,
        change_appearances_oz: false,
        change_plan_oz: false
        // change_whitelabel_oz: false // TO-DO: ACTIVATE WHEN WHITELABEL LAUNCHES
      } as OrganizationGroupPermissions)
    });
  }

  ngAfterContentInit() {
    // TO-DO: REMOVE 'change_whitelabel_oz' FROM OMIT WHEN WHITELABEL LAUNCHES
    this.form.setValue({
      permissions: omit(cloneDeep(this.group.permissions), ['__typename', 'change_whitelabel_oz']) as OrganizationGroupPermissions
    });
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.organizationService
      .updateGroupPermissions({ ...this.form.value, id: this.group.id })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
