import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SecurityVerificationEnum, SecurityVerificationType, Signer } from '@app/models';
import { DocumentService } from '@app/services';
import { AlertModalService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

export enum ManualBiometryApprovalEnum {
  Accepted = 'accept',
  Rejected = 'rejected'
}
export type ModalResult = { choice: ManualBiometryApprovalEnum; verification: SecurityVerificationType };
export interface ModalPublicProperties {
  signer: Signer;
  hideManualApproval?: boolean;
}

@Component({
  selector: 'app-verification-data-modal',
  templateUrl: './verification-data-modal.component.html',
  styleUrls: ['./verification-data-modal.component.scss']
})
export class VerificationDataModalComponent implements ModalPublicProperties, OnInit {
  @Input() signer: Signer;
  @Input() hideManualApproval: boolean;
  signerVerification: SecurityVerificationType;
  isLoading = false;
  canApproveManually = false;
  faceImages: string[] = [];
  docsImages: string[] = [];
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  readonly ManualBiometryApprovalEnum = ManualBiometryApprovalEnum;

  constructor(public modal: NgbActiveModal, private alertModalService: AlertModalService, private documentService: DocumentService, private translateService: TranslateService) {}

  ngOnInit() {
    this.signerVerification = this.documentService.getPendingManualVerification(this.signer);

    if (this.signerVerification) {
      this.canApproveManually =
        !this.hideManualApproval &&
        !this.signerVerification.verified_at &&
        this.documentService.isSignerUnapproved(this.signer) &&
        this.documentService.allowedManualVerifications.includes(this.signerVerification.type);

      if (Array.isArray(this.signerVerification.user.images)) {
        if (this.signerVerification.type === SecurityVerificationEnum.Upload) {
          this.docsImages = this.signerVerification.user.images;
        } else if (this.signerVerification.type === SecurityVerificationEnum.PfFacial) {
          this.faceImages = this.signerVerification.user.images;
        } else {
          this.faceImages = this.signerVerification.user.images.slice(-1, this.signerVerification.user.images.length); // Última
          this.docsImages = this.signerVerification.user.images.slice(0, -1); // Todas menos a última
        }
      } else {
        const imagesKeys = Object.keys(this.signerVerification.user.images || {});
        this.docsImages =
          this.signerVerification.type === SecurityVerificationEnum.PfFacial
            ? []
            : imagesKeys
                .filter(key => ['front', 'back'].includes(key))
                .map(key => this.signerVerification.user.images[key])
                .filter(img => !!img);
        this.faceImages =
          this.signerVerification.type === SecurityVerificationEnum.Upload
            ? []
            : imagesKeys
                .filter(key => key === 'selfie')
                .map(key => this.signerVerification.user.images[key])
                .filter(img => !!img);
      }
    }
  }

  submit(choice: ManualBiometryApprovalEnum) {
    let confirmationModal$;
    if (choice === ManualBiometryApprovalEnum.Accepted) {
      confirmationModal$ = this.alertModalService.confirmation({
        text: this.translateService.instant('alerts.acceptSignature'),
        confirmButtonText: this.translateService.instant('button.accept')
      });
    } else if (choice === ManualBiometryApprovalEnum.Rejected) {
      confirmationModal$ = this.alertModalService.warning({
        text: this.translateService.instant('alerts.rejectSignature'),
        confirmButtonText: this.translateService.instant('button.reject')
      });
    }

    if (confirmationModal$) {
      confirmationModal$.subscribe(() => this.modal.close({ choice, verification: this.signerVerification } as ModalResult));
    }
  }
}
