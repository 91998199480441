import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@app/core';

import { ConfigurationsProfileComponent } from './configurations-profile/configurations-profile.component';
import { ConfigurationsAppearanceComponent } from './configurations-appearance/configurations-appearance.component';
import { ConfigurationsNotificationComponent } from './configurations-notification/configurations-notification.component';
import { ConfigurationsPlanComponent } from './configurations-plan/configurations-plan.component';
import { ConfigurationsApiComponent } from './configurations-api/configurations-api.component';
import { ConfigurationsOrganizationComponent } from './configurations-organization/configurations-organization.component';
import { ConfigurationsOrganizationMembersComponent } from './configurations-organization-members/configurations-organization-members.component';
import { ConfigurationsOrganizationGroupsComponent } from './configurations-organization-groups/configurations-organization-groups.component';
import { ConfigurationsOrganizationEmailsComponent } from './configurations-organization-emails/configurations-organization-emails.component';
import { ConfigurationsWebhooksComponent } from './configurations-webhooks/configurations-webhooks.component';
import { ConfigurationsReferralsComponent } from './configurations-referrals/configurations-referrals.component';
import { ConfigurationsIndexComponent } from '@app/pages/configurations/configurations-index/configurations-index.component';
import { ConfigurationsWhitelabelComponent } from './configurations-whitelabel/configurations-whitelabel.component';

const routes: Routes = [
  {
    path: 'perfil',
    component: ConfigurationsIndexComponent,
    canActivate: [AuthenticationGuard],
    data: { reuse: false },
    children: [
      // { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: '', component: ConfigurationsProfileComponent, data: { title: 'Informações pessoais' } }, // 'profile'
      { path: 'aparencia', component: ConfigurationsAppearanceComponent, data: { title: 'Aparência' } }, // 'appearance'
      { path: 'notificacoes', component: ConfigurationsNotificationComponent, data: { title: 'Notificações' } }, // 'notifications'
      { path: 'planos', component: ConfigurationsPlanComponent, data: { title: 'Planos e pagamentos' } }, // 'plans'
      { path: 'api', component: ConfigurationsApiComponent, data: { title: 'Acesso a API' } }, // 'api'
      { path: 'organizacao', component: ConfigurationsOrganizationComponent, data: { title: 'Organização' } }, // 'organization'
      { path: 'membros', component: ConfigurationsOrganizationMembersComponent, data: { title: 'Membros da organização' } }, // 'members'
      { path: 'grupos', component: ConfigurationsOrganizationGroupsComponent, data: { title: 'Grupos da organização' } }, // 'groups'
      { path: 'emails', component: ConfigurationsOrganizationEmailsComponent, data: { title: 'Personalização de emails da organização' } }, // 'emails'
      { path: 'webhooks', component: ConfigurationsWebhooksComponent, data: { title: 'Webhooks' } }, // 'webhooks'
      { path: 'indicacoes', component: ConfigurationsReferralsComponent, data: { title: 'Indicações' } }, // 'referrals'
      { path: 'personalizacao', component: ConfigurationsWhitelabelComponent, data: { title: 'Personalização' } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule {}
