import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Md5 } from 'ts-md5/dist/md5';

@Directive({
  selector: '[appMatchCpf]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchCpfDirective, multi: true }]
})
export class MatchCpfDirective implements Validator {
  @Input('appMatchCpf') cpf: string;

  static validator(control: AbstractControl, cpf: string): ValidationErrors | null {
    return MatchCpfDirective.matches(control.value, cpf) ? null : { appMatchCpf: true };
  }

  static matches(controlValueCpf: string, validationCpf?: string) {
    return !validationCpf || (controlValueCpf && typeof controlValueCpf === 'string' && typeof validationCpf === 'string' && validationCpf === Md5.hashStr(controlValueCpf.replace(/[^0-9]/g, '')));
  }

  constructor() {}

  validate(control: AbstractControl) {
    return MatchCpfDirective.validator(control, this.cpf);
  }
}
