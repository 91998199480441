import { Component, Input, OnInit } from '@angular/core';
import { WhitelabelService } from '@app/services';

@Component({
  selector: 'app-blured-signer',
  templateUrl: './blured-signer.component.html',
  styleUrls: ['./blured-signer.component.scss']
})
export class BluredSignerComponent implements OnInit {
  @Input() selectedPdfLogoMiniImageUrl: any;

  constructor(public whitelabelService: WhitelabelService) {}

  ngOnInit(): void {}
}
