import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, finalize } from 'rxjs/operators';
import { merge } from 'lodash';

import { slideDownUpTrigger } from '@app/core/animations';
import { AppService, ErrorHandlerService, FolderService, OrganizationService, UserService, WhitelabelService } from '@app/services';
import { ContextEnum, Folder, ListFormat, OrganizationGroup, User } from '@app/models';
import { FoldersListingService } from '../shared/folders-listing.service';

@Component({
  selector: 'app-folders-group-list',
  templateUrl: './folders-group-list.component.html',
  styleUrls: ['./folders-group-list.component.scss'],
  animations: [slideDownUpTrigger]
})
export class FoldersGroupListComponent implements OnInit {
  ListFormat = ListFormat;
  folders: Folder[];
  currentUser: User;
  currentGroup: OrganizationGroup;
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  searchQuery: any;
  mobileSearch = '';
  isSearchOpen = false;
  openTaxonomyMenu = false;

  constructor(
    public route: ActivatedRoute,
    public whitelabelService: WhitelabelService,
    private appService: AppService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private folderService: FolderService,
    private foldersListingService: FoldersListingService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.userService
      .getCurrentUser()
      .pipe(filter(user => !!user))
      .subscribe(user => {
        this.currentUser = user;
        const isAllowed = this.route.snapshot.params.groupId ? user.currentPermissions.view_group_folders_oz : user.currentPermissions.view_folders_gr;
        if (isAllowed) {
          if (this.route.snapshot.params.groupId) {
            this.organizationService.organizationGroup({ uuid: this.route.snapshot.params.groupId }).subscribe(
              data => (this.currentGroup = data),
              error => this.errorHandlerService.handle(error)
            );
          }

          this.loadFolders();
        } else {
          this.userService.permissionCheck(isAllowed);
        }
      });
  }

  loadFolders(options: { page?: number; search?: any } = {}) {
    this.isLoading = true;
    this.pageNumber = options.page || 1;
    this.folderService
      .folders(
        {
          context: ContextEnum.Group,
          ...(this.route.snapshot.params.groupId ? { groupUuid: this.route.snapshot.params.groupId } : {}),
          ...(options.search || {}),
          limit: 60,
          page: this.pageNumber,
          orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection }
        },
        this.pageNumber === 1 ? 'cache-and-network' : 'no-cache'
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        page => {
          this.folders = (this.pageNumber === 1 ? [] : this.folders || []).concat(page.data);
          this.isLastPage = page.current_page >= page.last_page;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  openFoldersModal(folder: Folder) {
    this.foldersListingService.openFoldersModal(this.folders, folder).subscribe();
  }

  deleteFolder(folder: Folder) {
    this.foldersListingService.delete(this.folders, folder).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  openCreateFolderModal() {
    this.foldersListingService.openCreateFolderModal(ContextEnum.Group, { groupId: this.currentGroup ? this.currentGroup.id : null }).subscribe(
      folder => this.folders.unshift(folder),
      error => this.errorHandlerService.handle(error)
    );
  }

  openUpdateFolderModal(folder: Folder) {
    this.foldersListingService.openUpdateFolderModal(folder).subscribe(newFolder => merge(folder, newFolder));
  }

  clearFilters() {
    this.mobileSearch = '';
    this.searchQuery = null;
    this.isSearchOpen = false;
    this.loadFolders({ page: 1 });
  }

  toggleSearch(value: boolean) {
    this.isSearchOpen = value;
    if (this.isSearchOpen) {
      this.searchQuery = {};
    } else {
      this.clearFilters();
    }
  }
}
