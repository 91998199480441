import { TemplateAttributeTypes } from '@app/models';

export const templateAttributeTypes = {
  [TemplateAttributeTypes.Text]: 'template_attribute_type_text',
  [TemplateAttributeTypes.Email]: 'template_attribute_type_email',
  [TemplateAttributeTypes.Longtext]: 'template_attribute_type_longtext',
  [TemplateAttributeTypes.Cpf]: 'template_attribute_type_cpf',
  [TemplateAttributeTypes.Cnpj]: 'template_attribute_type_cnpj',
  [TemplateAttributeTypes.Cep]: 'template_attribute_type_cep',
  [TemplateAttributeTypes.Phone]: 'template_attribute_type_phone',
  [TemplateAttributeTypes.Date]: 'template_attribute_type_date',
  [TemplateAttributeTypes.Number]: 'template_attribute_type_number',
  [TemplateAttributeTypes.Hour]: 'template_attribute_type_hour'
};
