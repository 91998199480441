import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import { map, switchMap } from 'rxjs/operators';

import { throwOnGraphqlError } from '@app/core';
import {
  TemplatesGQL,
  TemplatesQueryVariables,
  TemplateGQL,
  TemplateQueryVariables,
  CreateTemplateGQL,
  CreateTemplateMutationVariables,
  UpdateTemplateGQL,
  UpdateTemplateMutationVariables,
  DeleteTemplateGQL,
  DeleteTemplateMutationVariables
} from 'src/generated/graphql.default';

@Injectable({ providedIn: 'root' })
export class TemplateService {
  constructor(
    private templatesGQL: TemplatesGQL,
    private templateGQL: TemplateGQL,
    private createTemplateGQL: CreateTemplateGQL,
    private updateTemplateGQL: UpdateTemplateGQL,
    private deleteTemplateGQL: DeleteTemplateGQL
  ) {}

  allTemplates(variables: TemplatesQueryVariables) {
    return this.templatesGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.templates)
    );
  }

  template(variables: TemplateQueryVariables) {
    return this.templateGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.template)
    );
  }

  create(variables: CreateTemplateMutationVariables) {
    return this.createTemplateGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createTemplate)
    );
  }

  update(variables: UpdateTemplateMutationVariables) {
    return this.updateTemplateGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateTemplate)
    );
  }

  delete(variables: DeleteTemplateMutationVariables) {
    return this.deleteTemplateGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteTemplate)
    );
  }

  duplicate(id: string, newName: string) {
    return this.template({ id }).pipe(
      switchMap(template =>
        this.create({
          template: { name: newName, text: template.text },
          attributes: template.attributes.map(attr => ({ name: attr.name, type: attr.type, required: attr.required, position: parseInt(attr.position) }))
        })
      )
    );
  }
}
