import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { GraphQLModule } from '@app/graphql.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuModule } from './pages/menu/menu.module';
import { HttpErrorsModule } from './pages/http-errors/http-errors.module';
import { ConfigurationModule } from './pages/configurations/configuration.module';
import { AuthModule } from './pages/auth/auth.module';
import { DocumentsModule } from './pages/documents/documents.module';
import { InvoicesModule } from './pages/invoices/invoices.module';
import { AuditModule } from './pages/audit/audit.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false }), // { enabled: environment.production }
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MM/YYYY',
      displayFormat: 'DD/MM/YYYY',
      separator: ' - ',
      cancelLabel: 'Cancelar',
      applyLabel: 'Aplicar',
      clearLabel: 'Limpar',
      customRangeLabel: 'Personalizado'
    }),
    CoreModule,
    GraphQLModule,
    SharedModule,
    HttpErrorsModule,
    AuditModule,
    AuthModule,
    ConfigurationModule,
    DocumentsModule,
    MenuModule,
    InvoicesModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Diagnostic only: inspect router configurations
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;
    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}
