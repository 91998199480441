import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { OrganizationGroup } from '@app/models';
import { ErrorHandlerService, OrganizationService } from '@app/services';

export type ModalResult = OrganizationGroup;
export interface ModalPublicProperties {
  group: OrganizationGroup;
}

@Component({
  selector: 'app-edit-organization-group-modal',
  templateUrl: './edit-organization-group-modal.component.html',
  styleUrls: ['./edit-organization-group-modal.component.scss']
})
export class EditOrganizationGroupModalComponent implements ModalPublicProperties, AfterContentInit {
  form!: FormGroup;
  group: OrganizationGroup;
  isLoading = false;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private errorHandlerService: ErrorHandlerService, private organizationService: OrganizationService) {
    this.form = this.formBuilder.group({
      group: this.formBuilder.group({ name: '', overwrite_template: false, overwrite_template_group: false })
    });
  }

  ngAfterContentInit() {
    this.form.setValue({
      group: { name: this.group.name, overwrite_template: this.group.overwrite_template, overwrite_template_group: this.group.overwrite_template_group }
    });
    if (this.group.is_default) {
      this.form.get('group.name').disable();
    }
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.organizationService
      .updateGroup({ id: this.group.id, group: { ...this.form.get('group').value, name: this.form.get('group.name').value } })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
