<app-mobile-header-menu menulink="/menu/documentos"></app-mobile-header-menu>
<aside class="sidebar">
  <!-- Always -->
  <header class="sidebar__header">
    <a routerLink="/" class="sidebar__header-brand" *ngIf="!isUnsaved">
      <img src="assets/autentique-logo.svg" alt="" />
    </a>
    <a href="javascript:void(0)" class="sidebar__header-brand" (click)="openUnsavedAlert()" *ngIf="isUnsaved">
      <img src="assets/autentique-logo.svg" alt="" />
    </a>

    <a class="sidebar__header-back" routerLink="/documentos/novo" *ngIf="!isUnsaved">{{ 'button.back' | translate }}</a>
    <a class="sidebar__header-back" (click)="openUnsavedAlert()" *ngIf="isUnsaved">{{ 'button.back' | translate }}</a>

    <button class="sidebar__header-save autentique-button" (click)="openTemplateNameModal()" *ngIf="isRendered">{{ 'button.save' | translate }}</button>
    <button class="sidebar__header-save autentique-button rendering" *ngIf="!isRendered"></button>
  </header>
  <form class="form">
    <h2 class="form__title">{{ 'model.form' | translate }}</h2>
    <p class="form__description">{{ 'model.formToBeFilled' | translate }}</p>
    <div class="form__actions">
      <button class="autentique-button autentique-button--color-gray" type="button" (click)="openCreateVariableModal()" *ngIf="isRendered">
        <i class="fal fa-layer-plus"></i>{{ 'model.addField' | translate }}
      </button>
      <button class="autentique-button autentique-button--color-gray rendering rendering__add-field" type="button" *ngIf="!isRendered"></button>
    </div>
    <div class="form__container" *ngIf="isRendered">
      <ng-container *ngFor="let variable of attributeInputs">
        <div
          class="form__field"
          [attr.data-position]="variable.position"
          (drag)="setCurrentDraggableElement($event)"
          (dragover)="dragstart_handler($event)"
          (dragleave)="dragleave_handler($event)"
          (drop)="onDrop($event)"
          draggable="true"
        >
          <div class="field__holder {{ variable.type == 'longtext' ? 'textarea' : '' }}">
            <textarea class="field active pr-80" name="{{ variable.slug }}" *ngIf="variable.type === 'longtext'"></textarea>

            <input class="field active pr-80" type="text" name="{{ variable.slug }}" *ngIf="variable.type === 'text'" readonly />
            <input class="field active pr-80" type="email" name="{{ variable.slug }}" *ngIf="variable.type === 'email'" readonly />
            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'number'" readonly />

            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'cpf'" mask="000.000.000-00" readonly />
            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'cnpj'" mask="00.000.000/0000-00" readonly />
            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'cep'" mask="00000-000" readonly />
            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'phone'" mask="(00) 00000-0000" readonly />
            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'date'" mask="00/00/0000" readonly />
            <input class="field active pr-80" type="tel" name="{{ variable.slug }}" *ngIf="variable.type === 'hour'" mask="00:00" readonly />

            <span class="field__placeholder field__placeholder--static">{{ variable.name }}{{ variable.required ? ' *' : '' }}</span>
            <div title="Remover" class="field__button field__button--third" (click)="removeVariable(variable)"><i class="fal fa-trash"></i></div>
            <div title="Editar" class="field__button field__button--second" (click)="openEditVariableModal(variable)"><i class="fal fa-edit"></i></div>
            <div title="Inserir" class="field__button" (click)="insertVariable(variable)"><i class="fal fa-file-import"></i></div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form__container" *ngIf="!isRendered">
      <div class="rendering rendering__form-field"></div>
      <div class="rendering rendering__form-field"></div>
      <div class="rendering rendering__form-field"></div>
      <div class="rendering rendering__form-field"></div>
    </div>
  </form>
</aside>

<section class="template" id="tinymce__root" [class.d-none]="!isRendered">
  <div class="template__header">
    <div class="template__header-container">
      <div class="template__header-toolbox"></div>
      <!-- <div class="autentique-button" (click)="openTemplateNameModal()">
        Salvar
      </div> -->
    </div>
  </div>
  <div class="template__wrapper" (click)="templateFocus()">
    <div class="template__wrapper-paper">
      <div id="tinymce"></div>
    </div>
  </div>
</section>

<section class="template" *ngIf="!isRendered">
  <div class="template__header">
    <div class="template__header-container rendering"></div>
  </div>
  <div class="template__wrapper rendering">
    <div class="template__wrapper-paper"></div>
  </div>
</section>
