import { AfterContentInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';

import { LoaderService } from '@app/shared';
import { AddressInput, CreditPackType, Customer, CustomerInput, Order, PaymentMethodsEnum, Plan, User } from '@app/models';
import { AppService, ErrorHandlerService, NotyService, OrganizationService, PaymentService, UserService } from '@app/services';
import { StripeCardElementOptions, StripeCardNumberElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

export enum EditPaymentInfoModalStep {
  paymentMethod = 'paymentMethod',
  paymentInfo = 'paymentInfo',
  paymentEmail = 'paymentEmail',
  checkout = 'checkout'
}
export interface ModalResult {
  currentCustomer: Customer;
  payableWith: PaymentMethodsEnum;
  cardToken: string;
}
export interface ModalPublicProperties {
  currentCustomer: Customer;
  currentOrder: Order;
  lockStep?: EditPaymentInfoModalStep;
  plan?: Plan;
  creditsPack?: CreditPackType;
}

@Component({
  selector: 'app-edit-payment-info-modal',
  templateUrl: './edit-payment-info-modal.component.html',
  styleUrls: ['./edit-payment-info-modal.component.scss', '../configurations-plan.component.scss']
})
export class EditPaymentInfoModalComponent implements AfterContentInit, OnChanges, OnDestroy {
  @Input() currentCustomer: Customer;
  @Input() currentOrder: Order;
  @Input() lockStep: EditPaymentInfoModalStep;
  @Input() plan: ModalPublicProperties['plan'];
  @Input() creditsPack: ModalPublicProperties['creditsPack'];
  form!: FormGroup;
  payableWith: PaymentMethodsEnum = PaymentMethodsEnum.CreditCard;
  PaymentMethodsEnum = PaymentMethodsEnum;
  defaultPaymentDataIsCreditCard = false;
  isLegalPerson = false;
  isLoading = false;
  canPurchaseCredits = false;
  currentStep: EditPaymentInfoModalStep;
  paymentOptions = {
    [PaymentMethodsEnum.CreditCard]: 'payment_credit_card',
    [PaymentMethodsEnum.Boleto]: 'payment_bank_slip',
    [PaymentMethodsEnum.Pix]: 'payment_bank_pix'
  };
  readonly EditPaymentInfoModalStep = EditPaymentInfoModalStep;

  // CARD STRIPE CONFIGS
  stripeCardToken: string;
  public baseStyle = {
    iconColor: '#666EE8',
    color: '#14112d',
    fontWeight: '400',
    fontFamily: 'Lato,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    fontSize: '14px',
    '::placeholder': { color: '#959cae' }
  };
  public cardOptions: StripeCardElementOptions = {
    style: { base: this.baseStyle }
  };
  public cardNumberOptions: StripeCardNumberElementOptions = {
    iconStyle: 'solid',
    showIcon: true,
    style: { base: this.baseStyle }
  };
  // @ts-ignore
  elementsOptions: StripeElementsOptions = { locale: this.translateService.currentLang };
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  constructor(
    public modal: NgbActiveModal,
    public organizationService: OrganizationService,
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private appService: AppService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private userService: UserService,
    private paymentService: PaymentService,
    private stripeService: StripeService
  ) {
    this.form = this.formBuilder.group({
      customer: this.formBuilder.group({ name: null, email: null, cpf: null, cnpj: null } as CustomerInput),
      address: this.formBuilder.group({ zip_code: null, street: null, number: null, complement: null, district: null, city: null, state: null, country: null, ibge_code: null } as AddressInput)
    });
  }

  ngAfterContentInit() {
    this.defaultPaymentDataIsCreditCard = !!this.currentOrder?.card_data?.last_digits;
    this.currentStep = this.lockStep || EditPaymentInfoModalStep.paymentMethod;
    this.refreshForms(this.currentCustomer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentCustomer && changes.currentCustomer.currentValue && changes.currentCustomer.currentValue !== changes.currentCustomer.previousValue) {
      this.refreshForms(changes.currentCustomer.currentValue);
    }
  }

  setPayableWith(paymentEnum: PaymentMethodsEnum) {
    this.payableWith = paymentEnum;
  }

  ngOnDestroy() {}

  update() {
    if (!this.creditsPack && !this.plan && this.payableWith === PaymentMethodsEnum.CreditCard) {
      this.createStripeToken(true);
    } else {
      this.submitModal();
    }
  }

  submitModal() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.loaderService.show();

    (this.lockStep === EditPaymentInfoModalStep.paymentMethod ? of(this.currentCustomer) : this.setCustomer())
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        data => {
          this.modal.close({ currentCustomer: data, payableWith: this.payableWith, cardToken: this.stripeCardToken } as ModalResult);
        },
        error => {
          this.errorHandlerService.handle(error);
          return of(null);
        }
      );
  }

  fillAddressByZipCode(zipCode: string) {
    const parsedZipCode = (zipCode || '').replace(/[^0-9]/g, '');
    if (parsedZipCode.length === 8) {
      this.appService.getZipCodeInfo(zipCode).subscribe(
        info => {
          this.form.get('address.street').setValue(info.logradouro);
          this.form.get('address.district').setValue(info.bairro);
          this.form.get('address.city').setValue(info.localidade);
          this.form.get('address.state').setValue(info.uf);
          this.form.get('address.country').setValue('BR');
          this.form.get('address.ibge_code').setValue(info.ibge);
        },
        error => {
          if (typeof error === 'string' && error.match(/invalid/i)) {
            this.form.get('address.zip_code').setErrors({ server: this.translateService.instant('error_invalid_cep') });
            this.form.get('address.zip_code').markAsTouched();
          }
        }
      );
    }
  }

  additionalGroupsCount() {
    return this.organizationService.additionalGroupsCount(this.currentOrder);
  }

  additionalGroupsMonths() {
    return this.organizationService.additionalGroupsMonths(this.plan);
  }

  checkoutTotal() {
    return this.creditsPack
      ? ((this.creditsPack.is_promo ? this.creditsPack.amount_promo : this.creditsPack.amount) * this.creditsPack.quantity) / 100
      : this.plan.amount / 100 + this.additionalGroupsCount() * this.additionalGroupsMonths() * this.organizationService.additionalGroupPrice;
  }

  createStripeToken(isUpdatePayableWith?: boolean) {
    // if stripeCardElemenet doesn't exist and if the user has a saved card, there's no need to create a new token, the saved card will be used for the charge.
    if (!this.card?.element) {
      if (this.lockStep) {
        this.submitModal();
        return;
      }
      this.currentStep = EditPaymentInfoModalStep.paymentInfo;
      return;
    }

    this.isLoading = true;
    this.loaderService.show();
    this.stripeService
      .createToken(this.card.element)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        data => {
          if (data?.error) {
            this.currentStep = EditPaymentInfoModalStep.paymentMethod;
            this.notyService.error(data.error.message);
            return;
          }

          this.stripeCardToken = data.token.id;
          if (isUpdatePayableWith) {
            this.submitModal();
            return;
          }
          this.currentStep = EditPaymentInfoModalStep.paymentInfo;
        },
        error => {
          this.currentStep = EditPaymentInfoModalStep.paymentMethod;
          this.errorHandlerService.handle(error);
        }
      );
  }

  private setCustomer() {
    const addressData = this.form.get('address').value;
    const customer = this.form.get('customer').value;

    addressData.number = addressData.number || 'S/N';
    addressData.country = addressData.country || 'BR';
    if (this.isLegalPerson) {
      delete customer.cpf;
    } else {
      delete customer.cnpj;
    }

    const costumer$ = this.currentCustomer?.created_at
      ? this.paymentService.updateCustomer({ address: addressData, customer })
      : this.paymentService.createCustomer({ address: addressData, customer });
    return costumer$;
  }

  private refreshForms(customer: Customer) {
    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(user => this.fillForms(user, customer));
  }

  private fillForms(user: User, customer: Customer) {
    if (user) {
      customer = customer || ({ default_payment: {} } as Customer);
      this.payableWith = this.currentOrder?.payment_method || PaymentMethodsEnum.CreditCard;

      this.isLegalPerson = customer.cnpj ? customer.cnpj.length >= 14 : !!user.cnpj;
      this.form.get('customer.email').setValue(this.form.get('customer.email').value || customer.email || user.email);
      this.form.get('customer.cpf').setValue(this.form.get('customer.cpf').value || customer.cpf || user.cpf);
      this.form.get('customer.cnpj').setValue(this.form.get('customer.cnpj').value || customer.cnpj || user.cnpj);
      this.form.get('customer.name').setValue(this.form.get('customer.name').value || customer.name || (this.isLegalPerson ? user.company || '' : user.name));

      this.form.get('address.street').setValue(this.form.get('address.street').value || customer.street || '');
      this.form.get('address.number').setValue(this.form.get('address.number').value || customer.number || '');
      this.form.get('address.complement').setValue(this.form.get('address.complement').value || customer.complement || '');
      this.form.get('address.district').setValue(this.form.get('address.district').value || customer.district || '');
      this.form.get('address.zip_code').setValue(this.form.get('address.zip_code').value || customer.zip_code || '');
      this.form.get('address.state').setValue(this.form.get('address.state').value || customer.state || '');
      this.form.get('address.city').setValue(this.form.get('address.city').value || customer.city || '');
      this.form.get('address.country').setValue(this.form.get('address.country').value || customer.country || '');
      this.form.get('address.ibge_code').setValue(this.form.get('address.ibge_code').value || customer.ibge_code || '');
    }
  }
}
