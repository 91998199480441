import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { filter, switchMap, finalize } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { I18nService, untilDestroyed } from '@app/core';
import { ActionEnum, Signer, User, PositionElementEnum, SignatureFormat, FontEnum, UserSignatureInput } from '@app/models';
import { DateService, ErrorHandlerService, NotyService, UserService } from '@app/services';
import { SignaturePadComponent } from '@app/shared/signature-pad/signature-pad.component';
import { EditProfileModalService } from './edit-profile-modal/edit-profile-modal.service';
import { EditPasswordModalService } from './edit-password-modal/edit-password-modal.service';
import { EditSignatureModalService } from './edit-signature-modal/edit-signature-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { EditLocationModalService } from '@app/pages/configurations/configurations-profile/edit-location-modal/edit-location-modal.service';
import { SelectOption } from '@app/shared';

@Component({
  selector: 'app-configurations-profile',
  templateUrl: './configurations-profile.component.html',
  styleUrls: ['../configurations.scss', './configurations-profile.component.scss']
})
export class ConfigurationsProfileComponent implements OnInit, OnDestroy {
  signatureForm: FormGroup;
  currentUser: User;
  isLoading = false;
  isEditingSignature = false;
  fakeSigner: Signer;
  readonly countries: SelectOption[] = [];
  readonly languages: SelectOption[] = [];
  readonly timezones: SelectOption[] = [];
  readonly dateFormat: SelectOption[] = [];
  readonly SignatureFormat = SignatureFormat;
  readonly PositionElementEnum = PositionElementEnum;
  readonly fonts = {
    [FontEnum.Autograf]: 'font_enum_autograf',
    [FontEnum.Fathur]: 'font_enum_farthur',
    [FontEnum.Robertson]: 'font_enum_robertson'
  };
  @ViewChild('signaturePad', { static: false }) private signaturePad: SignaturePadComponent;

  constructor(
    public dateService: DateService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private editProfileModalService: EditProfileModalService,
    private editPasswordModalService: EditPasswordModalService,
    private editSignatureModalService: EditSignatureModalService,
    private translateService: TranslateService,
    private editLocationModalService: EditLocationModalService,
    private i18nService: I18nService
  ) {
    this.signatureForm = this.formBuilder.group({
      user: this.formBuilder.group({ file_image: null, file_draw: null, format: SignatureFormat.Handwriting, font: FontEnum.Autograf } as UserSignatureInput)
    });
    this.languages = this.i18nService.supportedLanguagesAsSelectOptions || null;
    this.countries = this.i18nService.countriesWithCodes || null;
    this.timezones = this.i18nService.timezonesWithGMT || null;
    this.dateFormat = this.i18nService.dateFormatOptions || null;
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.signatureForm.get('user.format').setValue(this.currentUser.settings.format);
        this.signatureForm.get('user.font').setValue(this.currentUser.settings.font);
        this.setFakeSigner(this.currentUser);
      });
  }

  ngOnDestroy() {}

  openEditProfileModal() {
    this.editProfileModalService.open({ user: this.currentUser }).subscribe();
  }

  openEditPasswordModal() {
    this.editPasswordModalService.open().subscribe();
  }

  openEditSignatureModal() {
    this.editSignatureModalService.open({ user: this.currentUser }).subscribe(data => this.updateSignature({ signature: data.signature, initials: data.initials }));
  }

  openEditLocationModal() {
    this.editLocationModalService.open({ user: this.currentUser }).subscribe();
  }

  updateSignature(params?: { signature: UserSignatureInput; initials: UserSignatureInput }) {
    this.signatureForm.markAllAsTouched();
    let signatureFile: File;
    let initialsFile: File;
    const signatureFormat = params ? params.signature.format : this.signatureForm.get('user.format').value;
    const isEmptySignature = params ? !params.signature : this.signaturePad.isEmptySignature() || this.signaturePad.isMinSize('signature');
    const isEmptyInitials = params ? !params.initials : this.signaturePad.isEmptyInitials() || this.signaturePad.isMinSize('initials');

    if (this.signaturePad || params) {
      if ([SignatureFormat.Draw, SignatureFormat.Image].includes(signatureFormat) && isEmptySignature && isEmptyInitials) {
        this.notyService.error(this.translateService.instant('notyService.cannotSendEmptySignature'));
        return;
      } else if (!params && [SignatureFormat.Image, SignatureFormat.Draw].includes(signatureFormat)) {
        signatureFile = this.signaturePad.toImageFile('signature');
        initialsFile = this.signaturePad.toImageFile('initials');
      }
    }

    const signatureParams = { ...(params ? { user: params.signature } : cloneDeep(this.signatureForm.value || {})) };
    const initialsParams = { ...(params ? { user: params.initials } : cloneDeep(this.signatureForm.value || {})) };
    if (!params) {
      const fileParamName = signatureFormat === SignatureFormat.Draw ? 'file_draw' : 'file_image';
      signatureParams.user[fileParamName] = signatureFile;
      initialsParams.user[fileParamName] = initialsFile;
    }
    [signatureParams, initialsParams].forEach(formParams => {
      ['file_draw', 'file_image'].forEach(paramName => {
        if (formParams.user && !formParams.user[paramName]) {
          delete formParams.user[paramName];
        }
      });
    });

    this.isLoading = true;
    (!isEmptySignature ? this.userService.updateCurrentUserSignature(signatureParams) : of(null))
      .pipe(
        switchMap(() => (!isEmptyInitials ? this.userService.updateCurrentUserInitials(initialsParams) : of(null))),
        finalize(() => {
          if (this.signaturePad) {
            this.signaturePad.clear();
          }
          this.isLoading = false;
        })
      )
      .subscribe(
        () => (this.isEditingSignature = false),
        error => {
          const validationErrors = (this.errorHandlerService.handleValidation(this.signatureForm, error).validation || {}).user || {};
          const errorMessage = validationErrors.file_image || validationErrors.file_draw;
          if (errorMessage) {
            this.notyService.error(errorMessage);
          }
        }
      );
  }

  private setFakeSigner(user: User) {
    this.fakeSigner = {
      public_id: '123',
      email: user.email,
      name: null,
      parent: null,
      created_at: user.created_at,
      updated_at: user.created_at,
      archived_at: null,
      action: { name: ActionEnum.Sign },
      email_events: { delivered: null, opened: null, reason: null, refused: null, sent: null },
      viewed: null,
      signed: {
        ip: '127.0.0.1',
        reason: null,
        created_at: new Date().toJSON(),
        geolocation: { country: 'Brazil', countryISO: 'BR', state: 'São Paulo', stateISO: 'SP', city: 'São Paulo', zipcode: '04026', latitude: '0', longitude: '0' }
      },
      rejected: null,
      positions: [],
      link: null,
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        cpf: user.cpf,
        birthday: user.birthday,
        company: user.company,
        phone: user.phone,
        escavador: null,
        organization: { id: user.organization.id || null, name: user.organization.name || null },
        settings: null
      }
    };
  }
}
