import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { LocaleInput, User } from '@app/models';
import { ErrorHandlerService, NotyService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@app/shared';
import { I18nService } from '@app/core';

export type ModalResult = void;
export interface ModalPublicProperties {
  user: User;
}

@Component({
  selector: 'app-edit-password-modal',
  templateUrl: './edit-location-modal.component.html',
  styleUrls: ['./edit-location-modal.component.scss']
})
export class EditLocationModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() user: User;
  form!: FormGroup;
  isLoading = false;

  readonly countries: SelectOption[] = [];
  readonly languages: SelectOption[] = [];
  readonly timezones: SelectOption[] = [];
  readonly dateFormat: SelectOption[] = [];

  @ViewChild('appSelectCountries', { static: true }) appSelectCountries: ElementRef<HTMLElement>;

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private notyService: NotyService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private i18nService: I18nService
  ) {
    this.form = this.formBuilder.group({
      locale: this.formBuilder.group({ country: '', language: '', timezone: '', date_format: null } as LocaleInput)
    });
    this.countries = this.i18nService.countriesWithCodes || null;
    this.languages = this.i18nService.supportedLanguagesAsSelectOptions || null;
    this.timezones = this.i18nService.timezonesWithGMT || null;
    this.dateFormat = this.i18nService.dateFormatOptions || null;
  }

  ngAfterContentInit() {
    this.form.setValue({
      locale: {
        country: this.user.locale.country,
        language: this.user.locale.language,
        timezone: this.user.locale.timezone,
        date_format: this.user.locale.date_format
      } as LocaleInput
    });
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.userService
      .updateCurrentUserLocale(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => {
          if (data) {
            this.notyService.success(this.translateService.instant('success_update_locale'));
          } else {
            this.notyService.error(this.translateService.instant('error_update_locale'));
          }
          this.modal.close();
        },
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
