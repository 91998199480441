import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { AuthenticationMethod } from '@app/models';
import { NotyService } from '@app/services/noty.service';
import { ErrorHandlerService } from '@app/services/error-handler.service';
import { LoaderService } from '@app/shared/loader/loader.service';
import { PhoneService } from '@app/services/phone.service';
import { CountdownTimerService } from '@app/services/countdowntimer.service';

export interface ModalResult {
  accessToken: string;
  phoneNumber: string;
}
export interface ModalPublicProperties {
  type?: AuthenticationMethod;
  forceUserCreation?: boolean;
}

@Component({
  selector: 'app-sms-login-modal',
  templateUrl: './sms-login-modal.component.html',
  styleUrls: ['./sms-login-modal.component.scss']
})
export class SmsLoginModalComponent implements AfterContentInit, ModalPublicProperties, OnDestroy {
  @Input() type: AuthenticationMethod;
  @Input() forceUserCreation = false;
  form: FormGroup;
  isLoading = false;
  token: string;
  isVerifyingToken = false;
  isInvalidToken = false;
  verifyPhoneExisting = false;
  phoneNumber: string;
  formattedTime: string = '';
  readonly AuthenticationMethod = AuthenticationMethod;

  constructor(
    public modal: NgbActiveModal,
    public whatsAppTimer: CountdownTimerService,
    private formBuilder: FormBuilder,
    private notyService: NotyService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private loaderService: LoaderService,
    private phoneService: PhoneService
  ) {
    this.form = this.formBuilder.group({ phoneNumber: '', code: '' });
  }

  ngOnDestroy() {}

  ngAfterContentInit() {
    setTimeout(() => (document.querySelector('app-sms-login-modal input[name=phoneNumber]') as HTMLInputElement).focus());
  }

  sendSms() {
    this.isVerifyingToken = true;
    this.isInvalidToken = false;
    this.loaderService.show();
    this.phoneNumber = this.form.get('phoneNumber').value.replace(/^[+]/, '');
    this.phoneService[this.type === AuthenticationMethod.Whatsapp ? 'sendWhatsappVerification' : 'sendSmsVerification'](this.phoneNumber, this.forceUserCreation)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        () => {
          setTimeout(() => (this.isVerifyingToken = false), 20000);
          this.verifyPhoneExisting = true;
          this.notyService.success(this.translateService.instant('notyService.' + (this.type === AuthenticationMethod.Whatsapp ? 'whatsappVerificationSent' : 'smsVerificationSent')));
        },
        error => {
          setTimeout(() => (this.isVerifyingToken = false), 2000);
          this.loaderService.hide();
          this.errorHandlerService.handle(error);
        }
      );
  }

  requestCode(method: any) {
    this.type = method;
    this.sendSms();
  }

  submitToken() {
    this.isVerifyingToken = true;
    this.isInvalidToken = false;
    this.isLoading = true;

    this.phoneService[this.type === AuthenticationMethod.Whatsapp ? 'submitWhatsappVerificationCode' : 'submitSmsVerificationCode'](this.phoneNumber, this.token)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isVerifyingToken = false;
        })
      )
      .subscribe(
        response => {
          this.modal.close({ accessToken: response.access_token, phoneNumber: this.phoneNumber } as ModalResult);
        },
        () => {
          this.isInvalidToken = true;
        }
      );
  }

  timeOutForWhatsappVerification() {
    if (this.whatsAppTimer.isCountdownInProgress()) {
      this.whatsAppTimer.stopCountdown();
    }
    this.whatsAppTimer
      .startCountdownForWhatsApp()
      .pipe(untilDestroyed(this))
      .subscribe(time => {
        this.formattedTime = time;
      });
  }
}
