import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { omit } from 'lodash';

import { slideDownUpTrigger } from '@app/core/animations';
import { AppService, DocumentService, ErrorHandlerService, WhitelabelService } from '@app/services';
import { AlertButtonType, ContextEnum, DocumentStatusEnum, ListFormat, SlimDocument } from '@app/models';
import { DocumentDeleteAlertModalService, LoaderService } from '@app/shared';
import { DocumentsListingService } from '../shared/documents-listing.service';

@Component({
  selector: 'app-documents-trashbin-list',
  templateUrl: './documents-trashbin-list.component.html',
  styleUrls: ['./documents-trashbin-list.component.scss'],
  animations: [slideDownUpTrigger]
})
export class DocumentsTrashbinListComponent implements OnInit {
  ListFormat = ListFormat;
  documents: SlimDocument[];
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  searchQuery: any;
  mobileSearch = '';
  isSearchOpen = false;
  openTaxonomyMenu = false;
  readonly perPage = 60;

  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    public documentsListingService: DocumentsListingService,
    public whitelabelService: WhitelabelService,
    private loaderService: LoaderService,
    private documentService: DocumentService,
    private errorHandlerService: ErrorHandlerService,
    private documentDeleteAlertModalService: DocumentDeleteAlertModalService
  ) {}

  ngOnInit() {
    if (!(this.documents || [])[0]) {
      this.isSearchOpen = this.documentsListingService.isSearchParams(this.route.snapshot.queryParams);
      this.searchQuery = this.documentsListingService.searchQueryFromQueryParams(this.route.snapshot.queryParams);
      this.documents = [].constructor(7);
    }

    this.loadDocuments({ search: typeof (this.searchQuery || {}).includeDeleted === 'undefined' ? { ...(this.searchQuery || {}), includeDeleted: true } : this.searchQuery });
  }

  loadDocuments(options: { page?: number; search?: any } = {}, extraOptions: { showLoader?: boolean } = {}) {
    this.isLoading = true;

    this.pageNumber = options.page || 1;
    const showLoader = extraOptions.showLoader;
    if (showLoader) {
      this.loaderService.show();
    }
    this.documentsListingService.pushSearchQueryToUrl(omit(options.search, ['includeDeleted']));

    this.documentService[options.search ? 'unarchivedDocuments' : 'trashedDocuments'](
      {
        limit: this.perPage,
        page: this.pageNumber,
        orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection },
        ...(options.search || {}),
        status: DocumentStatusEnum.Deleted
      },
      this.pageNumber === 1 ? 'cache-and-network' : 'no-cache'
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        page => {
          this.documents = page ? (this.pageNumber === 1 ? [] : this.documents || []).concat(page.data) : null;
          this.isLastPage = !page || (page.data || []).length < this.perPage;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  restore(documents: SlimDocument[]) {
    const text = `Você tem certeza que deseja restaurar ${(documents || []).length > 1 ? 'esses documentos' : 'este documento'}?`;
    const documentsToUnblock = documents.filter(doc => this.documentsListingService.isAllowedToToggleBlock(doc) && this.documentService.isPastDeadline(doc));

    this.documentDeleteAlertModalService
      .open({
        text,
        isRestoration: true,
        dismissAsError: true,
        showExtraBtn: documentsToUnblock.length > 0
      })
      .subscribe(
        type => {
          this.documentsListingService.transfer(this.documents, documents, { isRestoration: true, context: ContextEnum.User }).subscribe(
            () => {
              (type === AlertButtonType.confirmExtra ? this.documentService.unblock(documentsToUnblock.map(doc => doc.id)) : of(null)).subscribe(
                () => this.loadDocuments({ page: 1, search: this.searchQuery }, { showLoader: true }),
                error => this.errorHandlerService.handle(error)
              );
            },
            error => this.errorHandlerService.handle(error)
          );
        },
        () => {}
      );
  }

  isTrash() {
    return !this.isSearchOpen || (this.searchQuery && this.searchQuery.includeDeleted);
  }

  clearFilters() {
    this.appService.documentStatusFilter = null;
    this.mobileSearch = '';
    this.searchQuery = null;
    this.isSearchOpen = false;
    this.loadDocuments({ page: 1 });
  }

  toggleSearch(value: boolean) {
    this.isSearchOpen = value;
    if (this.isSearchOpen) {
      this.searchQuery = { ...(this.searchQuery || {}), includeDeleted: true };
    } else {
      this.clearFilters();
    }
  }
}
