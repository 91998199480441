import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { User, Order } from '@app/models';
import { OrganizationService, PaymentService, UserService } from '@app/services';

@Component({
  selector: 'app-payment-warning',
  templateUrl: './payment-warning.component.html',
  styleUrls: ['./payment-warning.component.scss']
})
export class PaymentWarningComponent implements OnInit, OnDestroy {
  @Input() currentOrder: Order;
  @Input() onlyShowExpired: boolean;
  currentUser: User;

  constructor(public paymentService: PaymentService, private organizationService: OrganizationService, private userService: UserService) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        if (!this.currentOrder && this.currentUser.currentPermissions.change_plan_oz) {
          this.paymentService.order({ useCache: true }).subscribe(order => (this.currentOrder = order));
        }
      });
  }

  ngOnDestroy() {}

  subscriptionInvoiceExpirationDate() {
    return this.paymentService.subscriptionInvoiceExpirationDate(this.currentOrder);
  }

  hasPendingSubscriptionInvoice() {
    return this.paymentService.hasSubscriptionInvoicePending(this.currentOrder);
  }

  hasExpiredSubscriptionInvoice() {
    const expirationDate = this.paymentService.subscriptionInvoiceExpirationDate(this.currentOrder);
    return expirationDate && expirationDate < new Date() && this.currentOrder?.latest_invoice?.status === 'pending';
  }

  hasGatewaySubscriptionExpired() {
    return this.paymentService.hasGatewaySubscriptionExpired(this.currentOrder);
  }

  hasSubscriptionExpired() {
    return this.paymentService.hasSubscriptionExpired(this.currentUser);
  }

  isSubscriptionExpiring() {
    return this.hasGatewaySubscriptionExpired() && !this.hasSubscriptionExpired();
  }

  isSubscriptionRenewalExpiring() {
    return (this.currentUser?.subscription?.name || '').toLowerCase() !== 'free' && this.isSubscriptionExpiring() && !this.hasExpiredSubscriptionInvoice();
  }
}
