<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="formStep = FormSteps.permissions" *ngIf="formStep === FormSteps.invites">
  <p class="centermodal__title">{{ 'settings.inviteUsers' | translate }}</p>
  <p class="centermodal__description text-left">{{ 'settings.allUsersWillShareOrgPlanAndSetPermissions' | translate }}</p>

  <ng-container formGroupName="member">
    <div class="centermodal__form">
      <label class="field__holder textarea" [appFormControlWrap]="form.get('member.email')">
        <textarea class="field" formControlName="email" autocomplete="none" role="presentation" required #emailsInput></textarea>
        <p class="field__placeholder">{{ 'settings.userEmails' | translate }}</p>
      </label>
    </div>
  </ng-container>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.forward' | translate }}</button>
  </div>
</form>

<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="formStep = FormSteps.group" *ngIf="formStep === FormSteps.permissions">
  <div class="centermodal__title">{{ 'settings.setPermissions' | translate }}</div>

  <ng-container [ngTemplateOutlet]="membersTagList" [ngTemplateOutletContext]="{ $implicit: getEmails() }"></ng-container>

  <app-organization-permissions-form [form]="$any(form.get('member.permissions'))"></app-organization-permissions-form>

  <div class="centermodal__button-group mt-3">
    <button type="button" class="centermodal__button" (click)="formStep = FormSteps.invites">{{ 'button.back' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.forward' | translate }}</button>
  </div>
</form>

<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="invite()" *ngIf="formStep === FormSteps.group">
  <div class="centermodal__title">{{ 'settings.selectUserGroup' | translate }}</div>
  <p class="centermodal__description text-left">{{ 'settings.youCanAssignGroupToUsers' | translate }}</p>

  <ng-container [ngTemplateOutlet]="membersTagList" [ngTemplateOutletContext]="{ $implicit: getEmails() }"></ng-container>

  <ng-container formGroupName="member">
    <div class="centermodal__group">
      <label class="field__holder select" [appFormControlWrap]="form.get('member.group_id')">
        <select class="field" formControlName="group_id" required>
          <option [ngValue]="group.id" *ngFor="let group of groups">{{ group.name }}</option>
        </select>
        <span class="field__placeholder">{{ 'settings.group' | translate }}</span>
      </label>
    </div>
  </ng-container>

  <div class="centermodal__button-group mt-4">
    <button type="button" class="centermodal__button" (click)="formStep = FormSteps.permissions">{{ 'button.back' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.sendInvitations' | translate }}</button>
  </div>
</form>

<ng-template let-emails #membersTagList>
  <div class="centermodal__tag-list">
    <div class="centermodal__tag-title">{{ 'settings.' + (emails.length > 1 ? 'forNewMembers' : 'forNewMember') | translate: { emailLength: emails.length } }}</div>
    <div class="centermodal__tag" *ngFor="let email of emails">{{ email }}</div>
  </div>
</ng-template>
