import { Component, Input } from '@angular/core';

import { User } from '@app/models';
import { UserService } from '@app/services';

@Component({
  selector: 'app-documents-count',
  templateUrl: './documents-count.component.html',
  styleUrls: ['./documents-count.component.scss']
})
export class DocumentsCountComponent {
  @Input() user!: User;
  @Input() disableIcon = false;

  constructor(public userService: UserService) {}
}
