<div class="body-holder">
  <app-mobile-header-menu menulink="/menu/documentos" backButtonText="Documentos"></app-mobile-header-menu>

  <aside class="sidebar sidebar--mobile" [class.d-none]="!(formStep === FormSteps.mobileFile || formStep === FormSteps.signaturePositions)">
    <main class="document-signers" [class.d-none]="formStep !== FormSteps.mobileFile">
      <div class="document-signers-top">
        <div class="document-signers-title">{{ 'documents.selectFileToSign' | translate }}</div>
      </div>

      <div class="models__droparea models__merge" [class.d-none]="!!file || !isMergeFiles">
        <p class="models__droparea-title">{{ 'documents.mergeFiles' | translate }}</p>
        <p class="models__droparea-text">{{ 'documents.filesWillBeMerged' | translate }}</p>

        <div class="document-list" [dndDropzone] (dndDrop)="onDragAndDropMergeFile($event)">
          <div dndPlaceholderRef></div>
          <div class="list" *ngFor="let mergeFile of mergeFiles; let i = index" [dndDraggable]="i" dndEffectAllowed="move">
            <div class="item" dndHandle>
              <span class="number">
                {{ i + 1 + ' ||' }}
              </span>
              <div class="documents">
                <p class="title">{{ mergeFile.file.name }}</p>
                <p class="text">{{ mergeFile.file.size / 1024 | number: '1.0-0' }}kb</p>
              </div>
              <i class="fal fa-trash" (click)="deleteMergeFile(i)"></i>
            </div>
          </div>
        </div>

        <div class="models__droparea-actions">
          <button type="button" class="models__droparea-floatingButton" (click)="openGooglePicker(true)" [ngbTooltip]="tipGoogleDrive" container="body" placement="top" *ngIf="!isElectron">
            <i class="fab fa-google-drive"></i>
          </button>
          <label class="btn btn-primary btn-white">
            <input
              type="file"
              class="models-submit-field-file"
              multiple
              [accept]="acceptedFileMimeTypes.join(',')"
              (change)="addMultipleFiles($event.target['files'], true); $any($event.target).value = ''"
            />
            {{ 'documents.addMoreFiles' | translate }}
          </label>
          <button class="btn btn-primary" type="button" (click)="submitMergeFiles()">"{{ 'button.merge' | translate }}</button>
        </div>
      </div>

      <label class="document-signers__select" [class.d-none]="!!file || isMergeFiles">
        <input
          type="file"
          class="models-submit-field-file"
          multiple
          [accept]="acceptedFileMimeTypes.join(',')"
          (change)="addMultipleFiles($event.target['files'], false); $any($event.target).value = ''"
        />
        <i class="fal fa-cloud-upload-alt"></i>
        {{ 'documents.selectFile' | translate }}
      </label>

      <div class="document-signers-top mt-3" [class.d-none]="!!file">
        <div class="document-signers-title">{{ 'documents.orSelectTemplate' | translate }}</div>
      </div>

      <div class="template" [class.d-none]="!!file">
        <div class="template__list" *ngIf="templates && templates.length > 0">
          <div class="template__item" *ngFor="let template of templates; let index = index">
            <div class="template__item-head_options" ngbDropdown placement="bottom-right" (click)="$event.preventDefault()" *ngIf="currentUser?.currentPermissions.actions_templates_gr">
              <label ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
              <div ngbDropdownMenu>
                <a [routerLink]="['/modelos', template.id, 'editar']" ngbDropdownItem>{{ 'button.edit' | translate }}</a>
                <button type="button" (click)="openDuplicateTemplateModal(template)" ngbDropdownItem>{{ 'button.duplicate' | translate }}</button>
                <button type="button" (click)="deleteTemplate(template)" ngbDropdownItem>{{ 'button.exclude' | translate }}</button>
              </div>
            </div>
            <a href="javascript:void(0)" class="template__item-head" (click)="fillTemplate(template.id)">
              <div class="template__item-head_icon"><i class="fal fa-code"></i></div>
            </a>
            <a href="javascript:void(0)" class="template__item-body" (click)="fillTemplate(template.id)">
              <div class="template__item-name">{{ template.name }}</div>
              <div class="template__item-date" *ngIf="template.created_at === template.updated_at" title="{{ template.updated_at | ldate: 'long' }}">
                {{ 'documents.createdOn' | translate: { date: template.created_at | dateTime: 'mediumDate' } }}
              </div>
              <div class="template__item-date" *ngIf="template.created_at !== template.updated_at" title="{{ template.updated_at | ldate: 'long' }}">
                {{ 'documents.updatedOn' | translate: { date: template.updated_at | dateTime: 'mediumDate' } }}
              </div>
            </a>
          </div>
        </div>
        <div class="template__pagination" *ngIf="!isLoading && !isTemplatesLastPage">
          <button type="button" class="btn btn-link" (click)="loadTemplates({ page: templatesPageNumber + 1 })">{{ 'documents.loadMoreTemplates' | translate }}</button>
        </div>
      </div>
    </main>

    <main class="document-signers document-signers--mobile-spacing" [class.d-none]="formStep !== FormSteps.signaturePositions">
      <div class="document-signers-top">
        <div class="document-signers-title">{{ 'documents.positionVisibleSignatures' | translate }}</div>
      </div>

      <label class="document-signers__position" for="mobile-document-position">
        <div type="button" class="sidebar-options-submit sidebar-options-submit--field">{{ 'documents.insertField' | translate }}</div>
      </label>
      <input type="checkbox" class="d-none" name="mobile-document-position" id="mobile-document-position" />
      <label class="document-signers__position-backdrop" for="mobile-document-position"></label>
      <label class="document-signers__position-holder" for="mobile-document-position">
        <h3 class="document-signers__position-title">{{ 'documents.signatureFields' | translate }}</h3>
        <p class="document-signers__position-descr">{{ 'documents.selectSignerAndFieldType' | translate }}</p>
        <div class="document-signers-list">
          <app-signer-floating-fields-input
            *ngFor="let signer of signers | uniqueSigners: document?.qualified; let index = index"
            [signatureAction]="signer.action"
            [identification]="signer[signer._extra.signerMainAttribute]"
            [idColor]="idColors[index % idColors.length]"
            [qualified]="document?.qualified"
            [isCountryBR]="document?.locale?.country === 'BR'"
            [selected]="signer._extra.isSelected"
            [disabled]="isLoading"
            (addFloatingField)="setFloatingFieldFor(signer, $event, idColors[index % idColors.length])"
          >
          </app-signer-floating-fields-input>
        </div>
      </label>
    </main>
  </aside>
  <aside class="sidebar" [class.file]="!!file && (formStep === FormSteps.mobileFile || formStep === FormSteps.signaturePositions)">
    <!-- Always -->
    <app-document-sidebar-header>
      <a class="back" [routerLink]="whitelabelService.params.mainPath || backLink" *ngIf="formStep === FormSteps.signers">{{ 'documents.myDocuments' | translate }}</a>
      <a class="back" (click)="setFormStep(FormSteps.signers)" *ngIf="formStep === FormSteps.signaturePositions" [class.d-none]="isUploadingFile">{{ 'button.back' | translate }}</a>
      <a class="back" (click)="setFormStep(FormSteps.signaturePositions)" *ngIf="formStep === FormSteps.extraConfigurations" [class.d-none]="isUploadingFile">{{ 'button.back' | translate }}</a>
    </app-document-sidebar-header>

    <main class="document-signers" [class.d-none]="formStep !== FormSteps.signers">
      <div class="document-signers__language" *ngIf="document?.locale?.country && document?.locale?.language">
        <div class="d-inline-flex align-items-center">
          <i class="fa-regular fa-language mr-1"></i>
          {{ 'documents.jurisdictionOfDocument' | translate }}
        </div>
        <div class="d-inline-flex align-items-center">
          <div class="document-signers__language-dropdown mr-1" ngbDropdown placement="bottom-right" title="{{ 'countryCodes.' + (document.locale.country | lowercase) | translate }}">
            <div class="with-caret d-inline-flex align-items-center" ngbDropdownToggle container="body" placement="right">
              <div class="text-truncate" style="max-width: 67px">{{ 'countryCodes.' + (document.locale.country | lowercase) | translate }}</div>
            </div>
            <div class="document-signers__language-dropdown-list" ngbDropdownMenu>
              <input type="text" class="searchInput" placeholder="{{ 'button.search' | translate }}" [(ngModel)]="searchLocaleInputText" (ngModelChange)="filterDropdown($event)" />
              <ng-container *ngFor="let country of countriesFiltered">
                <button
                  class="text-truncate"
                  style="max-width: 145px;"
                  ngbDropdownItem
                  (click)="changeLocaleInput('country', country.key)"
                  title="{{ country.value }}"
                  [innerHTML]="country.value | highlight: searchLocaleInputText"
                ></button>
              </ng-container>
            </div>
          </div>
          <div class="document-signers__language-dropdown" ngbDropdown placement="bottom-right" title="{{ document.locale.language }}">
            <div class="with-caret d-inline-flex align-items-center" ngbDropdownToggle container="body" placement="right">
              <div class="text-truncate" style="max-width: 40px">{{ document.locale.language }}</div>
            </div>
            <div ngbDropdownMenu>
              <ng-container *ngFor="let language of languages">
                <button ngbDropdownItem (click)="changeLocaleInput('language', language.key)">{{ language.value }}</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="document-signers-title">{{ 'documents.informSigner' | translate }}</div>
      <div class="document-signers-subtitle">{{ 'documents.informSignerDesc' | translate }}</div>
      <div #signerToolsSort="ngbTooltip" [ngbTooltip]="tipSignerOrder" container="body" placement="right">
        <app-switch-tabs
          (hover)="signerToolsSort.toggle()"
          name="type"
          [disabled]="document.qualified"
          [options]="[
            [false, translateService.instant('documents.withoutOrder')],
            [true, translateService.instant('documents.withOrder')]
          ]"
          [(ngModel)]="document.sortable"
          [ngModelOptions]="{ standalone: true }"
        ></app-switch-tabs>
      </div>
      <div class="document-signers-top" *ngIf="showAddMeToList">
        <button type="button" class="document-signers-button" (click)="addNewSigner(currentUser.email, true); showAddMeToList = false" *ngIf="currentUser">
          {{ 'documents.addMeToList' | translate }}
        </button>
      </div>
      <div class="document-signers-list" [dndDropzone] (dndDrop)="onDragAndDropSigner($event)">
        <div dndPlaceholderRef></div>

        <div class="signer" *ngFor="let signer of signers; let i = index" [dndDraggable]="i" dndEffectAllowed="move">
          <div class="signer-order" [class.d-none]="!document.sortable">
            <div class="signer-order-num" dndHandle>{{ i + 1 }}&nbsp;<i class="fal fa-sort"></i></div>
          </div>
          <app-signer-input
            [signer]="signer"
            [qualified]="document.qualified"
            [isNewDocument]="true"
            [isCountryBR]="document.locale?.country === 'BR'"
            [validationErrors]="(validationErrors.signers || [])[i]"
            [class.has-error]="(validationErrors.signers || [])[i]?.name || (validationErrors.signers || [])[i]?.email"
            (changeSigner)="onSignerChange(signer)"
          ></app-signer-input>
        </div>
      </div>

      <ng-template #tipSignerOrder>
        <div class="tooltip__title">{{ 'documents.signingOrder' | translate }}</div>
        <div class="tooltip__descr">
          {{ 'documents.whenActive' | translate }}
        </div>
      </ng-template>

      <div class="document-signers-sort" #qualifiedSignatureTooltip="ngbTooltip" [ngbTooltip]="qualifiedSignatureTooltip" container="body" placement="right">
        <label class="document-signers-sort-label" (hover)="qualifiedSignatureTooltip.toggle()">
          <app-switch [(ngModel)]="document.qualified" (ngModelChange)="document.sortable = document.qualified || document.sortable; setQualifiedSignature(this.document.qualified)"></app-switch>
          <span class="document-signers-sort-text">{{ 'appSelect.qualifiedElectronicSignature' | translate }}</span>
        </label>
      </div>
      <ng-template #qualifiedSignatureTooltip>
        <div class="tooltip__title">
          {{ 'documents.qualifiedElectronicSignature' | translate }}
        </div>
        <div class="tooltip__descr" [innerHTML]="'documents.qualifiedSignatureDescription' | translate"></div>
      </ng-template>
      <div class="alert-message" *ngIf="document.qualified">
        <p class="mb-1">{{ 'documents.qualifiedElectronicSignature' | translate }}</p>
        <span [innerHTML]="'documents.qualifiedSignatureWarningDescription' | translate"></span>
      </div>
    </main>

    <main class="document-signers document-signers--mobile-none" [class.d-none]="formStep !== FormSteps.signaturePositions">
      <div class="document-signers-top">
        <div class="document-signers-title">{{ 'documents.positionVisibleSignatures' | translate }}</div>
      </div>
      <div class="document-signers-list">
        <app-signer-floating-fields-input
          *ngFor="let signer of signers | uniqueSigners: document?.qualified; let index = index"
          [signatureAction]="signer.action"
          [identification]="signer[signer._extra.signerMainAttribute]"
          [idColor]="idColors[index % idColors.length]"
          [qualified]="document?.qualified"
          [isCountryBR]="document?.locale?.country === 'BR'"
          [selected]="signer._extra.isSelected"
          [disabled]="isLoading"
          (addFloatingField)="setFloatingFieldFor(signer, $event, idColors[index % idColors.length])"
        >
        </app-signer-floating-fields-input>
      </div>
    </main>

    <main class="document-signers" [class.d-none]="formStep !== FormSteps.extraConfigurations">
      <div class="document-signers-top">
        <div class="document-signers-title">{{ 'documents.additionalSettings' | translate }}</div>
      </div>
      <app-document-configs-form
        [(document)]="document"
        [isQualified]="isQualified"
        [validationErrors]="validationErrors"
        [isNewDocument]="true"
        *ngIf="currentUser"
        #configsForm
      ></app-document-configs-form>
    </main>

    <div class="sidebar-options-submit-holder">
      <ng-container *ngIf="formStep === FormSteps.signers">
        <button type="button" class="sidebar-options-submit sidebar-options-submit--mobile-none" [disabled]="isLoading" (click)="setFormStep(FormSteps.signaturePositions)">
          {{ 'button.forward' | translate }}
        </button>
        <button type="button" class="sidebar-options-submit sidebar-options-submit--mobile" [disabled]="isLoading" (click)="setFormStep(FormSteps.mobileFile)">
          {{ 'button.forward' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="formStep === FormSteps.mobileFile">
        <button type="button" class="sidebar-options-submit sidebar-options-submit--back" (click)="setFormStep(FormSteps.signers)" [class.d-none]="isUploadingFile">
          {{ 'button.back' | translate }}
        </button>
        <button type="button" class="sidebar-options-submit" [class.d-none]="isLoading || !file" (click)="setFormStep(FormSteps.signaturePositions)">{{ 'button.forward' | translate }}</button>
      </ng-container>
      <ng-container *ngIf="formStep === FormSteps.signaturePositions">
        <button type="button" class="sidebar-options-submit sidebar-options-submit--back sidebar-options-submit--mobile-none" (click)="setFormStep(FormSteps.signers)" [class.d-none]="isUploadingFile">
          {{ 'button.back' | translate }}
        </button>
        <button
          type="button"
          class="sidebar-options-submit sidebar-options-submit--back sidebar-options-submit--mobile"
          (click)="removeFile(FormSteps.mobileFile)"
          *ngIf="mergeFiles.length === 1"
          [class.d-none]="isUploadingFile"
        >
          {{ 'button.back' | translate }}
        </button>
        <button
          type="button"
          class="sidebar-options-submit sidebar-options-submit--back sidebar-options-submit--mobile"
          (click)="isMergeFileMobile(FormSteps.mobileFile)"
          *ngIf="mergeFiles.length > 1"
          [class.d-none]="isUploadingFile"
        >
          {{ 'button.back' | translate }}
        </button>
        <button type="button" class="sidebar-options-submit sidebar-options-submit--field sidebar-options-submit--mobile" (click)="isMergeFileMobile(FormSteps.mobileFile)">
          {{ 'button.mergeFiles' | translate }}
        </button>
        <button type="button" class="sidebar-options-submit" [disabled]="isLoading" (click)="setFormStep(FormSteps.extraConfigurations)">{{ 'button.forward' | translate }}</button>
      </ng-container>
      <ng-container *ngIf="formStep === FormSteps.extraConfigurations">
        <button type="button" class="sidebar-options-submit sidebar-options-submit--back" (click)="setFormStep(FormSteps.signaturePositions)" [class.d-none]="isUploadingFile">
          {{ 'button.back' | translate }}
        </button>
        <button type="button" class="sidebar-options-submit" [disabled]="isLoading || isUploadingFile || (!isMergeFiles && pdfViewer.pagesCount() === 0)" (click)="submitDocument()">
          {{ 'button.sendForSignature' | translate }}
          <span class="credits" *ngIf="appService.totalCreditPrice(signers) > 0">
            <div class="credits__content">
              <i class="fal fa-shield-check"></i>
              -{{ appService.totalCreditPrice(signers) }}
            </div>
          </span>
        </button>
      </ng-container>
    </div>
    <div class="credits" *ngIf="formStep === FormSteps.extraConfigurations && appService.totalCreditPrice(signers) > 0">
      <p class="credits__content submit">
        <i class="fal fa-shield-check"></i>
        <span [innerHTML]="'documents.creatingDocumentCost' | translate: { creditPrice: appService.totalCreditPrice(signers) }"></span>
      </p>
    </div>
  </aside>

  <ng-template #tipGoogleDrive>
    <div class="tooltip__tip">{{ 'documents.googleDrive' | translate }}</div>
  </ng-template>

  <section class="models" [class.file]="!!file && formStep === FormSteps.mobileFile" [class.position]="!!file && formStep === FormSteps.signaturePositions">
    <app-payment-warning [onlyShowExpired]="true"></app-payment-warning>

    <div class="models__droparea" #documentUploadWrapper [class.d-none]="file || !currentUser || isMergeFiles">
      <p class="models__droparea-title">{{ 'documents.dragDocumentHere' | translate }}</p>
      <p class="models__droparea-text">{{ 'documents.orClickTo' | translate }}</p>
      <div class="models__droparea-actions">
        <label class="autentique-button autentique-button--size-large">
          <i class="fal fa-file-upload"></i>
          <input
            type="file"
            class="models-submit-field-file"
            multiple
            [accept]="acceptedFileMimeTypes.join(',')"
            (change)="addMultipleFiles($event.target['files'], false); $any($event.target).value = ''"
          />
          {{ 'documents.selectFile' | translate }}
        </label>
        <button type="button" class="models__droparea-floatingButton" (click)="openGooglePicker(false)" [ngbTooltip]="tipGoogleDrive" container="body" placement="top" *ngIf="!isElectron">
          <i class="fab fa-google-drive"></i>
        </button>
      </div>
    </div>

    <div class="models__droparea models__merge" [class.d-none]="file || !currentUser || !isMergeFiles">
      <p class="models__droparea-title">{{ 'documents.mergeFiles' | translate }}</p>
      <p class="models__droparea-text">{{ 'documents.filesWillBeMerged' | translate }}</p>

      <div class="document-list" [dndDropzone] (dndDrop)="onDragAndDropMergeFile($event)">
        <div dndPlaceholderRef></div>
        <div class="list" *ngFor="let mergeFile of mergeFiles; let i = index" [dndDraggable]="i" dndEffectAllowed="move">
          <div class="item" dndHandle>
            <span class="number">
              {{ i + 1 + ' ||' }}
            </span>
            <div class="documents">
              <p class="title">{{ mergeFile.file.name }}</p>
              <p class="text">{{ mergeFile.file.size / 1024 | number: '1.0-0' }}kb</p>
            </div>
            <i class="fal fa-trash" (click)="deleteMergeFile(i)"></i>
          </div>
        </div>
      </div>

      <div class="models__droparea-alert alert alert-danger" role="alert" *ngIf="isMergeFilesTooLarge()">
        {{ 'documents.' + (currentUser?.subscription.has_premium_features ? 'totalFileSizeLimit20MB' : 'totalFileSizeLimit5MB') | translate }}
      </div>
      <div class="models__droparea-actions">
        <button type="button" class="models__droparea-floatingButton" (click)="openGooglePicker(true)" [ngbTooltip]="tipGoogleDrive" container="body" placement="top" *ngIf="!isElectron">
          <i class="fab fa-google-drive"></i>
        </button>
        <label class="btn btn-primary btn-white">
          <input
            type="file"
            class="models-submit-field-file"
            multiple
            [accept]="acceptedFileMimeTypes.join(',')"
            (change)="addMultipleFiles($event.target['files'], true); $any($event.target).value = ''"
          />
          {{ 'documents.addMoreFilesDescription' | translate }}
        </label>
        <button class="btn btn-primary" type="button" (click)="submitMergeFiles()" [disabled]="isMergeFilesTooLarge()">{{ 'button.merge' | translate }}</button>
      </div>
    </div>

    <div class="models__or" [class.d-none]="file || !currentUser">
      <span class="models__or-title">{{ 'documents.orSelectTemplateDescription' | translate }}</span>
    </div>
    <div class="models__container" [class.d-none]="file || !currentUser">
      <div class="models__head">
        <span class="models__head-title">{{ 'documents.myTemplates' | translate }}</span>
        <a [routerLink]="['/modelos/novo']" class="models__head-button" *ngIf="currentUser?.currentPermissions.actions_templates_gr">
          {{ 'documents.newTemplate' | translate }}
        </a>
      </div>
      <div class="template">
        <div class="template__list" *ngIf="templates && templates.length > 0">
          <div class="template__item" *ngFor="let template of templates; let index = index">
            <div class="template__item-head_options" ngbDropdown placement="bottom-right" (click)="$event.preventDefault()" *ngIf="currentUser?.currentPermissions.actions_templates_gr">
              <label ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
              <div ngbDropdownMenu>
                <a [routerLink]="['/modelos', template.id, 'editar']" ngbDropdownItem>{{ 'button.edit' | translate }}</a>
                <button type="button" (click)="openDuplicateTemplateModal(template)" ngbDropdownItem>{{ 'button.duplicate' | translate }}</button>
                <button type="button" (click)="deleteTemplate(template)" ngbDropdownItem>{{ 'button.exclude' | translate }}</button>
              </div>
            </div>
            <a href="javascript:void(0)" class="template__item-head" (click)="fillTemplate(template.id)">
              <div class="template__item-head_icon"><i class="fal fa-code"></i></div>
            </a>
            <a href="javascript:void(0)" class="template__item-body" (click)="fillTemplate(template.id)">
              <div class="template__item-name">{{ template.name }}</div>
              <div class="template__item-date" *ngIf="template.created_at === template.updated_at" title="{{ template.created_at | ldate: 'long' }}">
                {{ 'documents.createdOn' | translate: { date: template.created_at | dateTime: 'mediumDate' } }}
              </div>
              <div class="template__item-date" *ngIf="template.created_at !== template.updated_at" title="{{ template.updated_at | ldate: 'long' }}">
                {{ 'documents.updatedOn' | translate: { date: template.updated_at | dateTime: 'mediumDate' } }}
              </div>
            </a>
          </div>
        </div>
        <div class="template__pagination" *ngIf="!isLoading && !isTemplatesLastPage">
          <button type="button" class="btn btn-link" (click)="loadTemplates({ page: templatesPageNumber + 1 })">{{ 'documents.loadMoreTemplates' | translate }}</button>
        </div>
      </div>
    </div>
    <app-pdf-viewer
      [class.d-none]="!file"
      [enableEditFloatingFields]="true"
      [qualified]="document?.qualified"
      [isNewSignatureStyle]="document?.new_signature_style"
      [documentFooter]="!isMobile && document?.new_signature_style && formStep !== FormSteps.signaturePositions && document?.footer"
      [document]="$any(document)"
      (selectedFieldChange)="selectFloatingFieldInput($event)"
      [documentWhitelabel]="documentWhitelabel"
      [isDocumentWhitelabelLoaded]="isDocumentWhitelabelLoaded"
      #pdfViewer
    >
    </app-pdf-viewer>
    <div class="models-float-bottom" *ngIf="file && pdfViewer.pagesCount() > 0 && !isSettingFloatingFields">
      <button type="button" class="btn btn-primary" (click)="isMergeFile()" *ngIf="mergeFiles.length > 1">{{ 'button.changeFile' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="removeFile()" *ngIf="mergeFiles.length === 1">{{ 'button.changeFile' | translate }}</button>
      <ng-template #tipMergeFiles>
        <div class="tooltip__title">{{ 'documents.mergeFile' | translate }}</div>
        <div class="tooltip__descr">{{ 'documents.selectMultipleFilesDescription' | translate }}</div>
      </ng-template>
      <button type="button" class="btn btn-primary btn-white" (click)="isMergeFile()" (hover)="toggleMergeFiles.toggle()" #toggleMergeFiles="ngbTooltip" [ngbTooltip]="tipMergeFiles">
        {{ 'button.mergeFiles' | translate }}
      </button>
    </div>
  </section>
</div>

<div class="document-focus-overlay" *ngIf="isSettingFloatingFields" (click)="cancelSettingFloatingFields()"></div>
