import { Component, Input, OnInit } from '@angular/core';
import { WhitelabelService } from '@app/services';

@Component({
  selector: 'app-auth-brand-container',
  templateUrl: './auth-container-brand.component.html',
  styleUrls: ['./auth-container-brand.component.scss']
})
export class AuthContainerBrandComponent implements OnInit {
  @Input() backToRegister = false;

  constructor(public whitelabelService: WhitelabelService) {}

  ngOnInit() {}
}
