import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { User, SignatureFormat, FontEnum, UserSignatureInput } from '@app/models';
import { SignaturePadComponent } from '@app/shared/signature-pad/signature-pad.component';

export type ModalResult = { signature: UserSignatureInput; initials: UserSignatureInput };
export interface ModalPublicProperties {
  user: User;
}

@Component({
  selector: 'app-edit-signature-modal',
  templateUrl: './edit-signature-modal.component.html',
  styleUrls: ['./edit-signature-modal.component.scss']
})
export class EditSignatureModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() user: User;
  form!: FormGroup;
  readonly SignatureFormat = SignatureFormat;
  readonly fonts = {
    [FontEnum.Autograf]: 'font_enum_autograf',
    [FontEnum.Fathur]: 'font_enum_farthur',
    [FontEnum.Robertson]: 'font_enum_robertson'
  };
  @ViewChild('signaturePad', { static: false }) private signaturePad: SignaturePadComponent;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ user: this.formBuilder.group({ file_draw: null, file_image: null, format: SignatureFormat.Handwriting, font: FontEnum.Autograf } as UserSignatureInput) });
  }

  ngAfterContentInit() {
    this.form.get('user.format').setValue(this.user.settings.format);
    this.form.get('user.font').setValue(this.user.settings.font);
  }

  submit() {
    const data: UserSignatureInput = this.form.get('user').value;
    const fileParamName = data.format === SignatureFormat.Draw ? 'file_draw' : 'file_image';
    let signatureFile: File;
    let initialsFile: File;

    if ([SignatureFormat.Image, SignatureFormat.Draw].includes(data.format as SignatureFormat)) {
      signatureFile = this.signaturePad.toImageFile('signature');
      initialsFile = this.signaturePad.toImageFile('initials');
    }

    this.modal.close({ signature: { ...data, [fileParamName]: signatureFile || null }, initials: { ...data, [fileParamName]: initialsFile || null } } as ModalResult);
  }
}
