<div class="container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12">
      <div class="text-center">
        <app-lottie name="document-crash" [loop]="true" height="200"></app-lottie>
      </div>

      <div class="text-center" *ngIf="fromDocumentUrl">
        <div [innerHTML]="'httpError.documentNotFoundOops' | translate"></div>
        <div>{{ 'httpError.addressMayBeIncorrectOrDeletedByCreator' | translate }}</div>
        <div>
          <u>{{ obscureUrl(fromDocumentUrl) }}</u>
        </div>
      </div>
      <div class="text-center" *ngIf="!fromDocumentUrl">
        <div [innerHTML]="'httpError.pageNotFoundOops' | translate"></div>
        <div>{{ 'httpError.checkIfEnteredAddressIsCorrect' | translate }}</div>
      </div>

      <div class="text-center mt-4" *ngIf="appService.isAutentiqueDomain()">
        <a [href]="appService.panelPath('/')" class="btn btn-lg btn-primary rounded-pill pl-5 pr-5">{{ 'httpError.homePage' | translate }}</a>
      </div>
    </div>
  </div>
</div>
