<form class="modal-body" [formGroup]="form" (ngSubmit)="updateProfile()" novalidate>
  <p class="centermodal__title">{{ 'settings.changeProfileData' | translate }}</p>

  <app-switch-tabs
    name="type"
    [options]="[
      [false, translateService.instant('profile.naturalPerson')],
      [true, translateService.instant('profile.legalEntity')]
    ]"
    [(ngModel)]="isCompany"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="setInputFocus()"
  ></app-switch-tabs>

  <ng-container formGroupName="user">
    <div class="centermodal__form" formGroupName="organization" *ngIf="isCompany">
      <div class="centermodal__group-title">{{ 'profile.companyData' | translate }}</div>

      <label class="field__holder" [appFormControlWrap]="form.get('user.organization.name')">
        <input type="text" class="field" formControlName="name" name="company" autocomplete="company" #companyInput />
        <span class="field__placeholder">{{ 'profile.businessName' | translate }}</span>
      </label>
      <label class="field__holder" [appFormControlWrap]="form.get('user.organization.cnpj')">
        <input type="text" class="field" formControlName="cnpj" name="cnpj" autocomplete="cnpj" mask="00.000.000/0000-00" />
        <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
      </label>
    </div>

    <div class="centermodal__form">
      <div class="centermodal__group-title">{{ 'profile.personalData' | translate }}</div>

      <label class="field__holder" [appFormControlWrap]="form.get('user.name')">
        <input type="text" class="field" formControlName="name" name="name" autocomplete="name" required appValidName appFullName appForbiddenNames #nameInput />
        <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
      </label>
      <label class="field__holder" [appFormControlWrap]="form.get('user.email')">
        <input type="email" class="field" formControlName="email" name="email" autocomplete="email" appValidEmail />
        <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
      </label>
      <label class="field__holder" [appFormControlWrap]="form.get('user.cpf')">
        <input type="tel" class="field" formControlName="cpf" name="cpf" autocomplete="cpf" mask="000.000.000-00" [required]="isCpfRequired" />
        <span class="field__placeholder">{{ 'profile.cpf' | translate }}</span>
      </label>
      <label class="field__holder" [appFormControlWrap]="form.get('user.birthday')">
        <input type="tel" class="field" formControlName="birthday" name="birthday" autocomplete="birthday" mask="00/00/0000" [dropSpecialCharacters]="false" [specialCharacters]="['/']" required />
        <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
      </label>
    </div>
  </ng-container>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.save' | translate }}</button>
  </div>
</form>
