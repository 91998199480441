import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { User, UserInput, OrganizationInput } from '@app/models';
import { DateService, ErrorHandlerService, NotyService, UserService } from '@app/services';
import { CurrentPasswordModalService } from '../current-password-modal/current-password-modal.service';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = void;
export interface ModalPublicProperties {
  user: User;
}

@Component({
  selector: 'app-edit-profile-modal',
  templateUrl: './edit-profile-modal.component.html',
  styleUrls: ['./edit-profile-modal.component.scss']
})
export class EditProfileModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() user: User;
  form!: FormGroup;
  isLoading = false;
  isCompany = false;
  isCpfRequired = false;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;
  @ViewChild('companyInput', { static: false }) private companyInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private notyService: NotyService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private currentPasswordModalService: CurrentPasswordModalService,
    private dateService: DateService
  ) {
    this.form = this.formBuilder.group({
      user: this.formBuilder.group({
        name: '',
        email: '',
        cpf: '',
        birthday: '',
        password: null,
        organization: this.formBuilder.group({ name: '', cnpj: '' } as OrganizationInput)
      })
    } as UserInput);
  }

  ngAfterContentInit() {
    this.form.setValue({
      user: {
        name: this.user.name,
        email: this.user.email,
        cpf: this.user.cpf,
        birthday: this.dateService.normalizeDate(this.user.birthday),
        password: null,
        organization: {
          name: this.form.get('user.organization.name').value || this.user.company || null,
          cnpj: this.form.get('user.organization.cnpj').value || this.user.cnpj || null
        }
      }
    } as UserInput);
    this.isCompany = !!this.form.get('user.organization.name').value;
    this.isCpfRequired = !!this.user.cpf;
    this.setInputFocus();
  }

  updateProfile() {
    const newEmail = (this.form.get('user.email').value || '').trim();
    if (newEmail && newEmail !== this.user.email && !this.form.get('user.password').value) {
      this.currentPasswordModalService.open().subscribe(password => {
        this.form.get('user.password').setValue(password || null);
        setTimeout(() => this.updateProfile());
      });
    } else {
      this.isLoading = true;
      this.form.markAllAsTouched();

      const userParams = this.form.value;
      if (!this.form.get('user.password').value) {
        delete userParams.user.password;
        delete userParams.user.email;
      }
      if (!this.form.get('user.email').value) {
        delete userParams.user.email;
      }
      if (userParams.user && userParams.user.organization) {
        userParams.user.organization.name = (this.isCompany ? userParams.user.organization.name : null) || null;
        userParams.user.organization.cnpj = (this.isCompany ? userParams.user.organization.cnpj : null) || null;
      }
      userParams.user.birthday = this.dateService.normalizeDate(userParams.user.birthday);

      this.userService
        .updateCurrentUser(userParams)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.form.get('user.password').setValue(null);
          })
        )
        .subscribe(
          () => {
            if (userParams.user.email && this.user.email !== (userParams.user.email || '').trim()) {
              this.notyService.success(this.translateService.instant('notyService.emailConfirmationSent', { userEmail: userParams.user.email }));
            }
            this.modal.close();
          },
          error => this.errorHandlerService.handleValidation(this.form, error)
        );
    }
  }

  setInputFocus() {
    setTimeout(() => (this.isCompany ? this.companyInput : this.nameInput).nativeElement.focus());
  }
}
