import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlimOrganization, User } from '@app/models';
import { OrganizationService, UserService } from '@app/services';
import { untilDestroyed } from '@app/core';
import { from } from 'rxjs';
import { CreateOrganizationModalService } from '@app/shared/create-organization-modal/create-organization-modal.service';
import { Router } from '@angular/router';
import { delay, finalize, switchMap } from 'rxjs/operators';
import { LoaderService } from '@app/shared';

@Component({
  selector: 'app-change-organization',
  templateUrl: './change-organization.component.html',
  styleUrls: ['./change-organization.component.scss']
})
export class ChangeOrganizationComponent implements OnInit, OnDestroy {
  currentUser: User;
  organizations: SlimOrganization[] = [];

  constructor(
    public userService: UserService,
    public router: Router,
    private loaderService: LoaderService,
    private organizationService: OrganizationService,
    private createOrganizationModalService: CreateOrganizationModalService
  ) {}

  ngOnInit(): void {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.currentUser = user;
      });

    this.organizationService
      .watchOrganizations()
      .pipe(untilDestroyed(this))
      .subscribe(organizations => {
        this.organizations = organizations;
      });
  }

  ngOnDestroy() {}

  openCreateOrganizationModal() {
    this.createOrganizationModalService.open().subscribe(() => {
      from(this.router.navigate(['/'])).subscribe(() => {
        this.userService.getCurrentUser({ fetchPolicy: 'network-only' }).subscribe();
        this.organizationService.getOrganizations({ fetchPolicy: 'network-only' }).subscribe();
      });
    });
  }

  selectDefaultOrganization(id: number) {
    if (!this.currentUser?.organization || id !== this.currentUser.organization.id) {
      this.loaderService.show();
      this.organizationService.selectDefault({ id }).subscribe(
        () => {
          from(this.router.navigate(['/']))
            .pipe(
              switchMap(() => this.userService.getCurrentUser({ fetchPolicy: 'network-only' })),
              delay(2000),
              finalize(() => this.loaderService.hide())
            )
            .subscribe();
        },
        () => this.loaderService.hide()
      );
    }
  }
}
