<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" novalidate>
  <p class="centermodal__title">{{ 'settings.changeOrganizationData' | translate }}</p>

  <div class="centermodal__form" formGroupName="organization">
    <label class="field__holder" [appFormControlWrap]="form.get('organization.name')">
      <input type="text" class="field" formControlName="name" name="company" autocomplete="company" #companyInput />
      <span class="field__placeholder">{{ 'profile.businessName' | translate }}</span>
    </label>
    <label class="field__holder" [appFormControlWrap]="form.get('organization.cnpj')">
      <input type="text" class="field" formControlName="cnpj" name="cnpj" autocomplete="cnpj" mask="00.000.000/0000-00" />
      <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.save' | translate }}</button>
  </div>
</form>
