import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionEnum, PositionElementEnum } from '@app/models';

@Component({
  selector: 'app-signer-floating-fields-input',
  templateUrl: './signer-floating-fields-input.component.html',
  styleUrls: ['./signer-floating-fields-input.component.scss']
})
export class SignerFloatingFieldsInputComponent {
  @Input() identification: string;
  @Input() idColor: string;
  @Input() signatureAction: ActionEnum = ActionEnum.Sign;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Input() qualified: boolean;
  @Input() isCountryBR: boolean;
  @Output() addFloatingField = new EventEmitter<PositionElementEnum>();
  readonly PositionElementEnum = PositionElementEnum;

  constructor() {}

  wrapperStyles() {
    return this.selected && this.idColor ? { borderColor: this.idColor, 'box-shadow': `0 5px 10px ${this.idColor}33` } : {};
  }
}
