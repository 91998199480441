import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { blobToBase64String } from 'blob-util';

@Component({
  selector: 'app-autenticats',
  templateUrl: './autenticats.component.html',
  styleUrls: ['./autenticats.component.scss']
})
export class AutenticatsComponent implements OnInit, OnChanges {
  @Input() seed: string;
  @Input() forceAutenticat = false;

  isLoading = true;
  color = { body: '', mark: '' };
  chosen = { mark: 1, eye: 1, mouth: 1 };
  gravatarUrl = '';

  private defaultGravatarBase64Images = [
    'iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAIAAAABc2X6AAAABnRSTlMAAAAAAABupgeRAAAAKklEQVR4nO3BAQ0AAADCoPdPbQ43oAAAAAAAAAAAAAAAAAAAAAAAAODXAEtQAAGPJpE5AAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAIAAAABc2X6AAAABnRSTlMAAAAAAABupgeRAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKklEQVR4nO3BAQ0AAADCoPdPbQ43oAAAAAAAAAAAAAAAAAAAAAAAAODXAEtQAAGPJpE5AAAAAElFTkSuQmCC'
  ];
  private colors = {
    body: ['#f9f4d3', '#fbe2e6', '#d9eded', '#ede9e8', '#e0ebf9', '#dfdff1', '#d7e9d4', '#f6e2cd'],
    mark: ['#fcef93', '#9ad2f4', '#9a6cac', '#777ab9', '#405876', '#826864', '#b29272', '#85c593', '#ee817f', '#ffffff']
  };
  private layers = ['body', 'mark', 'eye', 'mouthbg', 'mouth'];
  private primeNumbers = { eye: 11, mark: 13, mouth: 17, bodyColor: 19, markColor: 23 };
  private quantities = { eye: 8, mark: 12, mouth: 8, bodyColor: this.colors.body.length, markColor: this.colors.mark.length };

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.changeAvatar(changes.seed.currentValue, changes.seed.previousValue);
  }

  private changeAvatar(currentSeed?: string, previousSeed?: string) {
    if (!currentSeed || currentSeed === previousSeed) {
      return;
    }

    if (!window.Blob || !/@/.test(currentSeed) || this.forceAutenticat) {
      this.changeAvatarToAutenticat(currentSeed);
    } else {
      const gravatarUrl = 'https://www.gravatar.com/avatar/' + Md5.hashStr(currentSeed.trim()) + '?s=80&d=blank';
      this.httpClient
        .disableLoader()
        .cache({ expiresIn: 604800 })
        .get(gravatarUrl, { responseType: 'blob' })
        .subscribe(
          data => {
            blobToBase64String(data).then(base64Image => {
              this.isLoading = false;
              if (this.defaultGravatarBase64Images.includes(base64Image)) {
                this.changeAvatarToAutenticat(currentSeed);
              } else {
                this.gravatarUrl = gravatarUrl;
              }
            });
          },
          () => this.changeAvatarToAutenticat(currentSeed)
        );
    }
  }

  private changeAvatarToAutenticat(seed: string) {
    const domain = seed.trim().split('@')[1] || 'eunaotenhoemail';
    let charCodeCount = 0;
    let domainCharCodeCount = 0; // Herança
    for (let i = 0; i < seed.length; i++) {
      charCodeCount += seed.charCodeAt(i);
    }
    for (let i = 0; i < domain.length; i++) {
      domainCharCodeCount += domain.charCodeAt(i);
    }

    this.layers.forEach(layer => {
      if (!['body', 'mouthbg'].includes(layer)) {
        this.chosen[layer] = this.pickAttributeNumber(layer, layer === 'mark' ? domainCharCodeCount : charCodeCount) + 1;
      }
    });
    for (const key in this.color) {
      this.color[key] = this.colors[key][this.pickAttributeNumber(key + 'Color', key === 'mark' ? domainCharCodeCount : charCodeCount)];
    }

    this.isLoading = false;
  }

  private pickAttributeNumber(attribute: string, charCodeCount: number) {
    const base = this.quantities[attribute] + this.primeNumbers[attribute];
    return Math.ceil(Math.max(charCodeCount, base) / Math.min(charCodeCount, base) + this.quantities[attribute]) % this.quantities[attribute];
  }
}
