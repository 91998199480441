<div class="modal-body">
  <div id="sms-recaptcha-container"></div>
  <form class="centermodal__form mb-0" [formGroup]="form" (ngSubmit)="sendSms(); timeOutForWhatsappVerification()" *ngIf="!verifyPhoneExisting" novalidate>
    <p class="centermodal__title">{{ 'shared.confirmPhoneNumber' | translate }}</p>
    <p class="centermodal__description">
      {{ 'shared.' + (type === AuthenticationMethod.Whatsapp ? 'enterPhoneNumberWhatsapp' : 'enterPhoneNumberSMS') | translate }}
    </p>
    <label class="field__holder" style="margin: 0 0 30px;">
      <app-phone-number inputClass="field" formControlName="phoneNumber" name="phoneNumber" autocomplete="tel-national" required></app-phone-number>
      <span class="field__placeholder">{{ 'shared.yourPhoneNumber' | translate }}</span>
      <small [hidden]="!form.get('phoneNumber').value || !form.get('phoneNumber').hasError('phoneError')" class="text-danger">{{ 'error_invalid_phone_number' | translate }}</small>
    </label>
    <div class="centermodal__button-group">
      <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.forward' | translate }}</button>
    </div>
  </form>
  <form class="centermodal__form mb-0" (ngSubmit)="submitToken()" *ngIf="verifyPhoneExisting" novalidate>
    <p class="centermodal__title">{{ 'shared.confirmYourPhoneNumber' | translate }}</p>
    <p
      class="centermodal__description"
      [innerHTML]="'shared.' + (type === AuthenticationMethod.Whatsapp ? 'enterConfirmationCodeWhatsapp' : 'enterConfirmationCodeSMS') | translate: { phoneNumber: phoneNumber }"
    ></p>
    <app-token-field class="token" [class.invalid]="isInvalidToken" (tokenChange)="token = $event" (submit)="submitToken()"></app-token-field>

    <div class="tokenField">
      <p class="tokenField__alert" *ngIf="isInvalidToken"><i class="tokenField__alert-icon"></i>{{ 'shared.incorrectCode' | translate }}</p>
      <div class="tokenField__resend">
        <span class="tokenField__resend-text">{{ 'shared.notReceivedCode' | translate }}</span>
        <button
          type="button"
          class="tokenField__resend-button btn btn-link btnAnimatedBackground"
          [disabled]="isVerifyingToken"
          (click)="requestCode(AuthenticationMethod.Sms); timeOutForWhatsappVerification()"
        >
          {{ 'shared.resendCode' | translate }}
        </button>
      </div>
    </div>

    <div class="tokenField">
      <div class="tokenField__resend whatsDiv">
        <span class="tokenField__resend-text">{{ 'shared.orTryWhatsappIn' | translate }}</span>
        <button
          type="button"
          class="btn btn-link whatsDiv__btn btnAnimatedBackground"
          [ngClass]="{ whatsAppColor: !whatsAppTimer.isCountdownInProgress() }"
          [disabled]="isLoading || whatsAppTimer.isCountdownInProgress()"
          (click)="requestCode(AuthenticationMethod.Whatsapp); timeOutForWhatsappVerification()"
        >
          <span class="whatsDiv__whatsapp"> <i class="fab fa-whatsapp"></i> WhatsApp </span>
          &nbsp;{{ (whatsAppTimer.isCountdownInProgress() ? 'shared.in' : 'shared.clickingHere') | translate }}
          <div>&nbsp;{{ whatsAppTimer.isCountdownInProgress() ? formattedTime : '' }}</div>
        </button>
      </div>
    </div>

    <div class="centermodal__button-group mt-3">
      <button type="button" class="centermodal__button" (click)="verifyPhoneExisting = false">{{ 'button.back' | translate }}</button>
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || isInvalidToken">{{ 'button.forward' | translate }}</button>
    </div>
  </form>
</div>
