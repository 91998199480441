import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { I18nService } from '@app/core';

/**
 * Localized currency pipe (i18n)
 */
@Pipe({ name: 'lcurrency' })
export class LcurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private i18nService: I18nService) {}

  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string) {
    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, this.i18nService.language);
  }
}
