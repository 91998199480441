import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { I18nService } from '@app/core';

/**
 * Formats the date as 'd MMM \'às\' HH:mm' if the date is on the current year, else 'dd/MM/yy HH:mm'
 */
@Pipe({ name: 'shortDate' })
export class ShortDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private i18nService: I18nService) {}

  transform(date: number | string, timezone?: string): string {
    if (!date) {
      return '';
    }
    return this.datePipe.transform(date, new Date().getFullYear() !== new Date(date).getFullYear() ? 'dd/MM/yy HH:mm' : "d MMM 'às' HH:mm", timezone, this.i18nService.language);
  }
}
