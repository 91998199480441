import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap } from 'rxjs/operators';

import { I18nService, Logger } from '@app/core';
import { AppService, DocumentService, ErrorHandlerService, WhitelabelService } from '@app/services';
import { Document, DocumentInput, FloatingField, FooterEnum, UpdateDocumentLocaleInput, User, Whitelabel } from '@app/models';
import { DocumentConfigsFormComponent } from '../shared/document-configs-form/document-configs-form.component';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('DocumentsEditComponent');

@Component({
  selector: 'app-documents-edit',
  templateUrl: './documents-edit.component.html',
  styleUrls: ['./documents-edit.component.scss']
})
export class DocumentsEditComponent implements OnInit, OnDestroy {
  documentId: string;
  currentUser: User;
  document: Document;
  documentInput: DocumentInput;
  floatingFields: FloatingField[];
  isLoading = false;
  validationErrors: { [k: string]: any } = {};
  isDocumentWhitelabelLoaded: boolean = false;

  documentWhitelabel: Whitelabel = {
    main_color: null,
    logo: '',
    pdf_logo: '',
    pdf_logo_mini: ''
  };

  @ViewChild('configsForm', { static: false }) private configsFormComponent!: DocumentConfigsFormComponent;

  constructor(
    public documentService: DocumentService,
    public appService: AppService,
    public whitelabelService: WhitelabelService,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService
  ) {
    this.documentId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.documentService.documentWithFloatingFields({ id: this.documentId }).subscribe(({ document, floatingFields }) => {
      this.document = document;
      this.floatingFields = floatingFields;
      this.documentInput = {
        name: this.document.name,
        ignore_cpf: this.document.ignore_cpf,
        refusable: this.document.refusable,
        sortable: this.document.sortable,
        stop_on_rejected: this.document.stop_on_rejected,
        deadline_at: this.document.deadline_at,
        cc: (this.document.cc || []).map(cc => ({ email: cc })) || null,
        reply_to: this.document.reply_to,
        reminder: this.document.reminder,
        expiration: { notify_at: this.document.expiration_at || null, days_before: this.document.notify_in || null },
        message: this.document.message,
        footer: this.document.footer as FooterEnum,
        qualified: this.document.qualified, // adiciona qualified para verificação das opções de edição
        new_signature_style: this.document.new_signature_style,
        show_audit_page: this.document.show_audit_page,
        scrolling_required: this.document.scrolling_required,
        configs: { notification_signed: this.document.configs.notification_signed, notification_finished: this.document.configs.notification_finished },
        locale: { timezone: this.document.locale.timezone, date_format: this.document.locale.date_format, language: this.document.locale.language, country: this.document.locale.country }
      };
    });

    this.whitelabelService.loadWhitelabelByDocument({ documentId: this.documentId }).subscribe(whitelabel => {
      this.documentWhitelabel.pdf_logo_mini = whitelabel.pdf_logo_mini || this.whitelabelService.pdfLogoMini;
      this.isDocumentWhitelabelLoaded = true;
    });
  }

  ngOnDestroy() {}

  updateDocument() {
    this.isLoading = true;
    const documentInput = this.configsFormComponent.parsedDocumentInput();

    delete documentInput.qualified; // remove o qualified adicionado na verificação acima
    delete documentInput.reply_to; // reply-to não pode ser alterado
    delete documentInput.locale?.language; // language não pode ser alterado
    delete documentInput.locale?.country; // country não pode ser alterado

    this.documentService
      .update({ id: this.document.id, document: documentInput })
      .pipe(switchMap(() => this.documentService.clearPdfCache({ id: this.document.id })))
      .subscribe(
        () => this.router.navigate(['/documentos/', this.document.id]),
        error => {
          this.isLoading = false;
          const handledError = this.errorHandlerService.handle(error, { ignoreGraphqlErrorNotifications: true, validationMode: true });
          this.validationErrors = handledError.validation || {};
          log.error(handledError.message, handledError.validation);
        }
      );
  }
}
