<div class="modal-body">
  <p class="centermodal__title">{{ 'documents.rejectDocument' | translate }}</p>
  <p class="centermodal__description">{{ 'documents.rejectDocumentConfirmation' | translate }}</p>
  <form (ngSubmit)="send()" [formGroup]="form" novalidate>
    <div class="centermodal__form">
      <label class="field__holder textarea" [appFormControlWrap]="form.get('reason')">
        <textarea class="field" type="text" formControlName="reason" autocomplete="none" role="presentation"></textarea>
        <span class="field__placeholder">{{ 'documents.reason' | translate }}</span>
      </label>
    </div>
    <div class="centermodal__button-group">
      <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
      <button type="submit" class="centermodal__button centermodal__button--custom-next">{{ 'button.reject' | translate }}</button>
    </div>
  </form>
</div>
