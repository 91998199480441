import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-small-loader',
  templateUrl: './small-loader.component.html',
  styleUrls: ['./small-loader.component.scss']
})
export class SmallLoaderComponent {
  @Input() message = this.translateService.instant('shared.loading');

  constructor(private translateService: TranslateService) {}
}
