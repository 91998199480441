import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, switchMap } from 'rxjs/operators';

import { SlimDocument, Document, CreditTypeEnum } from '@app/models';
import { AppService, DocumentService, ErrorHandlerService, UserService } from '@app/services';

export type ModalResult = SlimDocument | Document;
export interface ModalPublicProperties {
  document: SlimDocument | Document;
}

@Component({
  selector: 'app-restore-lifecycle-modal',
  templateUrl: './restore-lifecycle-modal.component.html',
  styleUrls: ['./restore-lifecycle-modal.component.scss']
})
export class RestoreLifecycleModalComponent implements ModalPublicProperties {
  @Input() document: SlimDocument | Document;
  isLoading = false;
  readonly creditAmount: number;

  constructor(
    public modal: NgbActiveModal,
    private errorHandlerService: ErrorHandlerService,
    private appService: AppService,
    private documentService: DocumentService,
    private userService: UserService
  ) {
    this.creditAmount = this.appService.creditPriceByType(CreditTypeEnum.DocumentLifecycleRescue);
  }

  restore() {
    this.isLoading = true;
    this.documentService
      .lifecycleRescue({ documentId: this.document.id })
      .pipe(
        switchMap(() => this.documentService.documentFile({ id: this.document.id })),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        data => {
          this.userService.currentUserCredits().subscribe();
          this.modal.close({ ...this.document, lifecycle_in: null, files: data.files });
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  calculateNextLifecycle(lifecycle_in: string) {
    if (lifecycle_in) {
      const lifecycle = new Date(lifecycle_in);
      lifecycle.setFullYear(lifecycle.getFullYear() + 5);
      return lifecycle.toJSON();
    }
    return lifecycle_in;
  }
}
