<div class="item item--signed" [ngClass]="{ itemDM: isDarkmode }">
  <div class="item-head">
    <span class="item-head-contact blured" [ngClass]="{ bluredDM: isDarkmode }">{{ 'whitelabel.creator' | translate }}></span>
  </div>
  <div class="item-content">
    <app-autenticats class="item-content-avatar blured" [ngClass]="{ bluredDM: isDarkmode }"></app-autenticats>
    <div class="item-content-data">
      <div class="item-content-name blured" [ngClass]="{ bluredDM: isDarkmode }">{{ 'whitelabel.personName' | translate }}</div>
      <div class="item-content-name blured" [ngClass]="{ bluredDM: isDarkmode }">{{ 'whitelabel.secondLine' | translate }}</div>
      <div class="item-content-links" [ngClass]="{ 'itemDM-content-links': isDarkmode }"></div>
    </div>
  </div>
  <div class="item-info-holder">
    <div class="item-info" [class.active]="true">
      <div class="item-info-icon">
        <i class="fal fa-check"></i>
      </div>
      <div class="item-content-data">
        <div class="item-content-name blured" [ngClass]="{ bluredDM: isDarkmode }">{{ 'whitelabel.createdDocument' | translate }}</div>
        <div class="item-content-name blured" [ngClass]="{ bluredDM: isDarkmode }">{{ 'whitelabel.largerSecondLine' | translate }}</div>
      </div>
    </div>
  </div>
</div>
