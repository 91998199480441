<div class="signer-position" [ngStyle]="wrapperStyles()">
  <div class="signer-position-marker" [ngStyle]="idColor ? { backgroundColor: idColor } : null"></div>
  <div class="signer-position-descr">
    <span class="signer-position-descr-contact">{{ identification }}</span>
    <span class="signer-position-descr-role">{{ 'signature.signAction.' + (signatureAction | lowercase) | translate }}</span>
  </div>
  <div class="signer-position-content">
    <div class="signer-position-option" [class.disabled]="disabled" (click)="!disabled && addFloatingField.emit(PositionElementEnum.Signature)">{{ 'signature.signature' | translate }}</div>
    <div class="signer-position-option" [class.disabled]="disabled" (click)="!disabled && addFloatingField.emit(PositionElementEnum.Initials)" *ngIf="!qualified">
      {{ 'signature.initials' | translate }}
    </div>
    <div class="signer-position-option" [class.disabled]="disabled" (click)="!disabled && addFloatingField.emit(PositionElementEnum.Name)" *ngIf="!qualified">{{ 'signature.name' | translate }}</div>
    <div class="signer-position-option" [class.disabled]="disabled" (click)="!disabled && addFloatingField.emit(PositionElementEnum.Cpf)" *ngIf="!qualified && isCountryBR">
      {{ 'signature.cpf' | translate }}
    </div>
    <div class="signer-position-option" [class.disabled]="disabled" (click)="!disabled && addFloatingField.emit(PositionElementEnum.Date)" *ngIf="!qualified">{{ 'signature.date' | translate }}</div>
  </div>
</div>
