import { Component, ElementRef, forwardRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch-tabs',
  templateUrl: './switch-tabs.component.html',
  styleUrls: ['./switch-tabs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchTabsComponent),
      multi: true
    }
  ]
})
export class SwitchTabsComponent implements OnChanges {
  @Input() value: any;
  @Input() formControlName: string;
  @Input() name = '';
  @Input() disabled = false;
  @Input() disableInput = false;
  @Input() inputWidth = '325px';
  @Input() options: [any, string][];
  @ViewChild('marker', { static: false }) private marker: ElementRef<HTMLInputElement>;
  @ViewChildren('switchInput') private switchInputs: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('switchLabel') private switchLabels: QueryList<ElementRef<HTMLInputElement>>;

  onChange: (_: boolean) => void;
  onTouch: () => void;

  readonly inputId: string;

  constructor() {
    this.onChange = () => {};
    this.onTouch = () => {};
    this.inputId = String(Math.floor(Math.random() * 1000000));
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (changes.options?.isFirstChange()) {
        this.marker.nativeElement.style.display = 'block';
      }
      if (changes.options?.previousValue !== changes.options?.currentValue) {
        this.marker.nativeElement.style.width = this.switchLabels.first.nativeElement.offsetWidth + 'px';
      }
    });
  }

  writeValue(value: any) {
    this.value = value;
    setTimeout(() => {
      const checkedInput = this.switchInputs.filter(input => input.nativeElement.checked)[0];
      const checkedLabel = checkedInput && this.switchLabels.filter(label => label.nativeElement.getAttribute('for') === checkedInput.nativeElement.id)[0];
      if (checkedLabel) {
        this.marker.nativeElement.style.left = checkedLabel.nativeElement.offsetLeft + 'px';
      }
    });
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.disableInput = isDisabled;
  }
}
