import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SlimTemplate } from '@app/models';

export type ModalResult = { name: SlimTemplate['name'] };
export interface ModalPublicProperties {
  template: { name: SlimTemplate['name'] };
}

@Component({
  selector: 'app-template-name-modal',
  templateUrl: './template-name-modal.component.html',
  styleUrls: ['./template-name-modal.component.scss']
})
export class TemplateNameModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() template: ModalPublicProperties['template'];
  form!: FormGroup;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ template: this.formBuilder.group({ name: '' }) });
  }

  ngAfterContentInit() {
    this.form.get('template.name').setValue((this.template || {}).name || '');
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  submit() {
    const result: ModalResult = this.form.get('template').value;
    result.name = (result.name || '').trim().replace(/\s+/, ' ');
    this.modal.close(result);
  }
}
