<div class="d-flex">
  <app-mobile-header-main
    (titleClicked)="openTaxonomyMenu = $event"
    [mainTitle]="!route.snapshot.params.groupId ? translateService.instant('menu.wholeGroup') : currentGroup?.name"
    menulink="/menu/documentos"
  ></app-mobile-header-main>
  <div class="taxonomy-overlay" [class.taxonomy-overlay-active]="openTaxonomyMenu" (click)="openTaxonomyMenu = false"></div>
  <app-taxonomy
    *ngIf="whitelabelService.params.sidebar"
    mainTitle="{{ 'menu.documents' | translate }}"
    allowSearch
    [(isSearchOpen)]="isSearchOpen"
    (isSearchOpenChange)="toggleSearch($event)"
    [class.isMenuMobileOpen]="openTaxonomyMenu"
  >
    <app-documents-taxonomy [isSearchOpen]="isSearchOpen" (sortingChange)="loadDocuments({ page: 1, search: searchQuery })" [listPath]="'group'"></app-documents-taxonomy>
  </app-taxonomy>
</div>

<div class="d-flex flex-column w-100">
  <app-documents-search
    [(search)]="searchQuery"
    [selectedMemberOrGroup]="currentGroup ? { id: currentGroup.uuid, name: currentGroup.name, type: 'group' } : null"
    (searchChange)="pageNumber = 1; loadDocuments({ page: 1, search: $event })"
    (closeClick)="isSearchOpen = false; toggleSearch(isSearchOpen)"
    *ngIf="isSearchOpen"
    @slideDownUpTrigger
  ></app-documents-search>

  <div class="documents">
    <div class="documents__header">
      <ng-container *ngIf="!((isSearchOpen && searchQuery) || mobileSearch)">
        <h2 class="documents__title" *ngIf="!route.snapshot.params.groupId">{{ 'menu.wholeGroup' | translate }}</h2>
        <h2 class="documents__title" *ngIf="route.snapshot.params.groupId">{{ currentGroup?.name }}&nbsp;</h2>
      </ng-container>
      <h2 class="documents__title" *ngIf="(isSearchOpen && searchQuery) || mobileSearch">{{ 'documents.searchResults' | translate }}</h2>
      <app-search [(query)]="mobileSearch" (queryChange)="pageNumber = 1; loadDocuments({ page: 1, search: { search: $event } })"></app-search>
      <button type="button" class="btn btn-link text-right d-none d-md-inline-block" [disabled]="isLoading" (click)="exportDocuments(searchQuery)" *ngIf="isSearchOpen && searchQuery">
        <i class="fal fa-file-csv"></i>&nbsp; {{ 'documents.export' | translate }}
      </button>
    </div>

    <div
      class="documents-empty"
      *ngIf="currentUser && !isLoading && !searchQuery && !mobileSearch && !appService.documentStatusFilter && documents && documents.length === 0; else documentsEmptyWithFilters"
    >
      <i class="fal fa-file-alt documents-empty__icon"></i>
      {{ 'documents.nothingHereYet' | translate }}
    </div>

    <ng-template #documentsEmptyWithFilters>
      <div class="documents-filtered-empty" *ngIf="currentUser && !isLoading && documents && (searchQuery || mobileSearch || appService.documentStatusFilter) && documents.length === 0">
        <div class="documents-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="documents-filtered-empty_text">
          <span>{{ 'documents.noDocumentsMatchingFilter' | translate }}</span>
        </div>
        <button type="button" class="default-button" (click)="clearFilters()">{{ 'documents.clearFilters' | translate }}</button>
      </div>
    </ng-template>

    <app-documents-list-boxes
      *ngIf="appService.listFormat === ListFormat.Box"
      [currentUser]="currentUser"
      [hidePagination]="isLoading || isLastPage"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      [documents]="documents"
      (signClick)="sign($event)"
      (folderClick)="openFoldersModal({ documents: $event })"
      (deleteClick)="delete($event)"
      (transferClick)="transfer($event)"
      (toggleBlockClick)="toggleBlock($event)"
      (resendWebhookClick)="resendWebhook($event)"
    >
    </app-documents-list-boxes>

    <app-documents-list-table
      *ngIf="appService.listFormat === ListFormat.List"
      [currentUser]="currentUser"
      [hidePagination]="isLoading || isLastPage"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      [documents]="documents"
      (signClick)="sign($event)"
      (folderClick)="openFoldersModal({ documents: $event })"
      (deleteClick)="delete($event)"
      (transferClick)="transfer($event)"
      (toggleBlockClick)="toggleBlock($event)"
      (resendSignaturesClick)="resendSignatures($event)"
      (resendWebhookClick)="resendWebhook($event)"
    >
    </app-documents-list-table>
  </div>
</div>
