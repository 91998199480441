import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replaces line breaks for '<br>' and removes every other HTML tags from being executed
 */
@Pipe({ name: 'nl2br' })
export class Nl2brPipe implements PipeTransform {
  constructor() {}

  transform(text: string): string {
    return (text || '')
      .replace(new RegExp('<', 'g'), '&lt;')
      .replace(new RegExp('>', 'g'), '&gt;')
      .replace(new RegExp('\n', 'g'), '<br>');
  }
}
