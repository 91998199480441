import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, finalize, tap } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { DocumentStatusEnum, ListFormat, ListSorting, MembersAndGroups, OrderByEnum, User } from '@app/models';
import { AppService, ErrorHandlerService, OrganizationService, UserService, WhitelabelService } from '@app/services';
import { DocumentsOrganizationMemberListComponent } from '../../documents-organization-member-list/documents-organization-member-list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-documents-taxonomy',
  templateUrl: './documents-taxonomy.component.html',
  styleUrls: ['./documents-taxonomy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentsTaxonomyComponent implements OnInit, OnDestroy {
  @Input() disableMainButton = false;
  @Input() listPath: 'default' | 'group' | 'member' | 'organization';
  @Input() isSearchOpen = false;
  @Output() statusChange = new EventEmitter();
  @Output() formatChange = new EventEmitter();
  @Output() sortingChange = new EventEmitter<void>();
  @Output() newFolderButtonClick = new EventEmitter();
  isOnFoldersListing: boolean;
  isOnTrash: boolean;
  currentUser: User;
  isLoading = false;
  search = '';
  lastSearch: string;
  organizationThings: MembersAndGroups[] = [];
  documentStatusFilters = [DocumentStatusEnum.Pending, DocumentStatusEnum.Signed, DocumentStatusEnum.NotSigned];
  readonly ListFormat = ListFormat;
  readonly OrderByEnum = OrderByEnum;
  readonly listSortings = {
    [ListSorting.CreatedAt]: this.translateService.instant('documents.creationDate'),
    [ListSorting.Name]: this.translateService.instant('documents.name'),
    [ListSorting.UpdatedAt]: this.translateService.instant('documents.lastModified')
  };

  constructor(
    public appService: AppService,
    public whitelabelService: WhitelabelService,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private translateService: TranslateService
  ) {
    this.isOnFoldersListing = !!location.pathname.match('^/pastas');
    this.isOnTrash = !!location.pathname.match('^/documentos/lixeira');

    if (this.route.snapshot.component === DocumentsOrganizationMemberListComponent) {
      this.documentStatusFilters.push(DocumentStatusEnum.Deleted);
    } else {
      const deletedStatusIndex = this.documentStatusFilters.findIndex(status => status === DocumentStatusEnum.Deleted);
      if (deletedStatusIndex >= 0) {
        this.documentStatusFilters.splice(deletedStatusIndex, 1);
      }
      if (this.appService.documentStatusFilter === DocumentStatusEnum.Deleted) {
        this.changeFilter(null);
      }
    }

    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        filter(user => !this.currentUser || this.currentUser.organization.id !== user.organization.id),
        untilDestroyed(this)
      )
      .subscribe(user => {
        const hasOrganizationChanged = this.currentUser && this.currentUser.organization.id !== user.organization.id;
        this.currentUser = user;
        if (this.isOnFoldersListing && this.canShowFolders()) {
          this.searchRecords(this.lastSearch, hasOrganizationChanged);
        } else if (!this.isOnFoldersListing && this.canShowDocuments()) {
          this.searchRecords(this.lastSearch, hasOrganizationChanged);
        }
      });
  }

  ngOnInit() {
    this.onResize();
  }

  ngOnDestroy() {}

  searchRecords(search?: string, ignoreCache?: boolean) {
    if (!this.isLoading && (this.organizationThings.length === 0 || search !== this.lastSearch || ignoreCache)) {
      this.isLoading = true;
      this.organizationService
        .organizationMembersAndGroups({ limit: 8, page: 1, search: search || '' }, { ignoreCache })
        .pipe(
          tap(() => (this.lastSearch = search)),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          page => (this.organizationThings = page.data.filter(data => !!data.name)),
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  changeSorting(sortingKey: string) {
    this.appService.listSorting = sortingKey as ListSorting;
    this.sortingChange.emit();
  }

  changeDirection(direction: string) {
    this.appService.listDirection = direction as OrderByEnum;
    this.sortingChange.emit();
  }

  changeFilter(statusFilter: DocumentStatusEnum) {
    this.appService.documentStatusFilter = statusFilter;
    this.sortingChange.emit();
  }

  recordPath(item: MembersAndGroups) {
    return [`/${this.isOnFoldersListing ? 'pastas' : 'documentos'}/${item.type === 'member' ? 'organizacao' : 'grupo'}`, item.id];
  }

  canShowDocuments(item?: MembersAndGroups) {
    return (
      this.currentUser &&
      (item
        ? item.type === 'member'
          ? this.currentUser.currentPermissions.view_member_documents_oz
          : this.currentUser.currentPermissions.view_group_documents_oz
        : this.currentUser.currentPermissions.view_member_documents_oz || this.currentUser.currentPermissions.view_group_documents_oz)
    );
  }

  canShowFolders(item?: MembersAndGroups) {
    return (
      this.currentUser &&
      (item
        ? item.type === 'member'
          ? this.currentUser.currentPermissions.view_member_folders_oz
          : this.currentUser.currentPermissions.view_group_folders_oz
        : this.currentUser.currentPermissions.view_member_folders_oz || this.currentUser.currentPermissions.view_group_folders_oz)
    );
  }

  changeFormatList(format: string) {
    this.appService.listFormat = format as ListFormat;

    this.formatChange.emit({ format });
  }

  @HostListener('window:resize') private onResize() {
    if (window.innerWidth <= 915 && this.appService.listFormat !== ListFormat.Box) {
      this.appService.listFormat = ListFormat.Box;
    }
  }
}
