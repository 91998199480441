<div *ngFor="let foot of [].constructor((pagesCount || 0) + 1)" class="holder" #container>
  <div class="footer" *ngIf="isDocumentWhitelabelLoaded">
    <div class="divisor">
      <div class="autLogo">
        <div class="autLogo__image">
          <img [src]="documentWhitelabel?.pdf_logo_mini" alt="" />
        </div>
      </div>
      <div class="text">
        <p class="text_first">
          {{ 'shared.' + (qualified ? 'signedWithQualifiedElectronicSignature' : 'signedWithAdvancedElectronicSignature') | translate }}
        </p>
        <p
          *ngIf="!qualified"
          class="text_hash"
          [innerHTML]="document?.hashes?.sha2 ? ('shared.sha256HashOfOriginalPDF' | translate: { sha2: document?.hashes?.sha2 }) : ('shared.sha256HashOfOriginalPDFExample' | translate)"
        ></p>
        <p class="text_valida" [innerHTML]="document?.id ? ('shared.validationLink' | translate: { url: qrCodeUrl() }) : ('shared.validationLinkExample' | translate)"></p>
      </div>
    </div>
    <div class="qrCode">
      <p class="qrCode__text">{{ 'shared.validator' | translate }}</p>
      <qrcode [cssClass]="'qrCode__image'" [width]="52" [qrdata]="qrCodeUrl()" [elementType]="'svg'"></qrcode>
    </div>
    <div class="qrCodeM">
      <p class="qrCodeM__text">{{ 'shared.validator' | translate }}</p>
      <div class="qrCodeM__mobile">
        <qrcode [cssClass]="'qrCode__image'" [width]="26" [qrdata]="qrCodeUrl()" [elementType]="'svg'"></qrcode>
      </div>
    </div>
  </div>
</div>
