<div class="group">
  <div class="group__title">{{ 'menu.organizationDocuments' | translate }}</div>
  <div class="group__options">
    <a routerLink="/documentos/grupo" class="group__option">
      <div class="group__option-icon"><i class="fal fa-users-class"></i></div>
      <div class="group__option-name">{{ 'menu.wholeGroup' | translate }}</div>
    </a>
    <a routerLink="/documentos/organizacao" class="group__option">
      <div class="group__option-icon"><i class="fal fa-users"></i></div>
      <div class="group__option-name">{{ 'menu.wholeOrganization' | translate }}</div>
    </a>
  </div>
</div>

<!--
<div class="group" *ngIf="currentUser?.organization?.members?.length > 1">
  <div class="group__title">Documentos dos membros</div>
  <div class="group__options">
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">Daniel Didone</div>
    </a>
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">Ronaldo Jorge</div>
    </a>
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">Cassiano Calegari</div>
    </a>

    <a
      class="group__option"
      *ngFor="let member of currentUser.organization.members | slice: 0:10"
      [routerLink]="['/documentos/organizacao', member.id]"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="current"
    >
      <div class="group__option-icon"><i class="fal fa-user-alt"></i></div>
      <div class="group__option-name">{{ member.name }}</div>
    </a>
  </div>
</div>
 -->
