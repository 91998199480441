<div class="modal-body">
  <p class="centermodal__title">{{ 'documents.documentCreatedAndSent' | translate }}</p>

  <app-lottie name="document-success" height="170"></app-lottie>

  <div class="centermodal__message-body centermodal__message-body--margin-normal ">
    <p class="centermodal__message" [innerHTML]="'documents.documentSentNotice' | translate"></p>
  </div>

  <div class="centermodal__form" *ngIf="document && (document.signatures | filterHasValue: { email: false, link: true }).length > 0">
    <!-- <div class="centermodal__form" *ngIf="document && (document.signatures | filterHasValue: { email: false, link: true }).length > 0"> -->
    <div class="centermodal__message-body centermodal__message-body--margin-normal ">
      <p class="centermodal__message">{{ 'documents.sendUniqueLink' | translate }}</p>
    </div>
    <p class="centermodal__title mb-4">{{ 'documents.signatureLinks' | translate }}</p>

    <div class="centermodal__message-body" *ngFor="let signer of document.signatures | filterHasValue: { email: false, link: true }">
      <div class="centermodal__message centermodal__message--color-black mb-0">{{ signer.name }}</div>
      <div class="configuration-value">
        <input type="text" class="configuration-value-show" [value]="appService.signerLink(signer.link)" readonly #tokenInputElement />
        <div class="configuration-value-copy" (click)="copySignatureLink(signer.name)" [ngxClipboard]="tokenInputElement"></div>
      </div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" *ngIf="currentSigner && documentService.isSignerTurn(document, currentSigner)" (click)="modal.close()">
      {{ 'sign_action_' + currentSigner.action.name | lowercase | translate }}
    </button>
  </div>
</div>
