import { Pipe, PipeTransform } from '@angular/core';

/**
 * Shortens the name provided by returning only the initials from the name
 */
@Pipe({ name: 'initialsName' })
export class InitialsNamePipe implements PipeTransform {
  constructor() {}

  transform(name: string): string {
    if (!name) {
      return '';
    }

    const nameParts = String(name)
      .replace(/[0-9]/g, '')
      .trim()
      .split(/\s+/); // REMOVE NÚMEROS DO NOME POIS NÃO SÃO SUPORTADOS PELA FONTE
    if (nameParts.length > 1) {
      return nameParts[0][0] + nameParts[nameParts.length - 1][0][0];
    } else {
      return nameParts[0][0];
    }
  }
}
