import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/services/user.service';
import { I18nService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor(private translateService: TranslateService, private userService: UserService, private i18nService: I18nService) {}

  normalizeDate(dateStr: string): string {
    const defaultFormat = this.i18nService.transformDateFormat(this.userService.getUserLocale()?.date_format) || null;
    if (!dateStr) return null;

    let day;
    let month;
    let year;

    if (defaultFormat === 'MM/dd/yyyy') {
      [month, day, year] = dateStr.split('/');
    } else {
      [day, month, year] = dateStr.split('/');
    }

    return `${day}/${month}/${year}`;
  }
}
