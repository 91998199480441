<div class="modal-body">
  <p class="centermodal__title">{{ 'documents.individualSignatureLink' | translate }}</p>
  <p class="centermodal__description">
    {{ 'documents.individualSignatureLinkDescription' | translate }}
    <span [class.d-none]="!signer.email" [innerHTML]="'documents.signedOnBehalfOf' | translate: { email: signer.email }"></span>
  </p>

  <div class="centermodal__form">
    <div class="text-center" *ngIf="!signer.link?.id">
      <button type="button" class="btn btn-primary" [disabled]="isLoading" (click)="createSignLink()">{{ 'documents.generateLink' | translate }}</button>
    </div>
    <div class="input-group input-group-sm" *ngIf="signer.link?.id">
      <input type="text" class="form-control" [value]="appService.signerLink(signer.link)" readonly #linkInputElement />
      <div class="input-group-append">
        <button type="button" class="btn btn-outline-secondary" type="button" (click)="copySignatureLink()" [ngxClipboard]="linkInputElement"><i class="fal fa-copy"></i></button>
      </div>
    </div>
    <p *ngIf="signer.link?.id">
      <small class="text-danger"><i class="fal fa-exclamation-triangle"></i> {{ 'documents.doNotShareLink' | translate }}</small>
    </p>
  </div>

  <div class="centermodal__button-group" *ngIf="signer.link?.id">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" (click)="deleteSignLink()">{{ 'button.revokeLink' | translate }}</button>
  </div>
</div>
