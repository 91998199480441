<img [src]="gravatarUrl" class="img-fluid rounded-circle" *ngIf="!isLoading && gravatarUrl" />

<div class="wrapper" *ngIf="!isLoading && !gravatarUrl">
  <!-- body -->
  <div class="positioning">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9"><circle cx="47.4" cy="47.4" r="47.4" [style.fill]="color.body" /></svg>
  </div>

  <!-- mark -->
  <div class="positioning" [ngSwitch]="chosen.mark">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="1"></svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="2"><circle class="a" cx="68" cy="39" r="15.3" [style.fill]="color.mark" /></svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="3">
      <path d="M45.7,37c0,9.8,16.2,17.7,36.2,17.7a72.1,72.1,0,0,0,12.6-1.1c.2-2,.4-4.1.4-6.2a47.3,47.3,0,0,0-9.2-28.1H81.9C61.9,19.2,45.7,27.2,45.7,37Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="4">
      <path
        d="M30.1,3.3a31,31,0,0,0-8.2,14.5,14.4,14.4,0,0,1,7-4.6c.9,3.7-1.4,7.4-2.8,10.7,14.4,0,28.2-1,38.9-12.1-.5,1.4,0,2.8,0,4.2,7.6-.4,5.6-6.4,3.2-11.2A47.3,47.3,0,0,0,30.1,3.3Z"
        [style.fill]="color.mark"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="5">
      <path d="M-.1,47.4A47.4,47.4,0,0,0,5.7,70.2a47.5,47.5,0,0,0,39-46.7A47.4,47.4,0,0,0,38.9.7,47.5,47.5,0,0,0-.1,47.4Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="6">
      <path [style.fill]="color.mark" d="M.3,52.4a22.3,22.3,0,0,1,5.4-1.1h0a2.9,2.9,0,1,0,0-5.8,21.3,21.3,0,0,0-5.7,1v.9A40.7,40.7,0,0,0,.3,52.4Z" />
      <path [style.fill]="color.mark" d="M1,41a13.8,13.8,0,0,1,4,.4h.8a2.9,2.9,0,0,0,2.8-2.1,3,3,0,0,0-2-3.6,26.3,26.3,0,0,0-5-.6A38.2,38.2,0,0,0,.5,40.9Z" />
      <path [style.fill]="color.mark" d="M42.6.2a45,45,0,0,0-6.4,1.1,32.5,32.5,0,0,0-1.3,10.6,2.8,2.8,0,0,0,2.9,2.8h.1a3,3,0,0,0,2.8-3A27.5,27.5,0,0,1,42.5.8C42.5.6,42.6.4,42.6.2Z" />
      <path [style.fill]="color.mark" d="M53.4.4A38.1,38.1,0,0,0,47.5,0c-2.3,6.7-2.9,15-2.3,18.1a2.9,2.9,0,0,0,2.9,2.3h.6a2.9,2.9,0,0,0,2.2-3.4C50.4,14.7,51,6.8,53.3.8A.6.6,0,0,0,53.4.4Z" />
      <path [style.fill]="color.mark" d="M58.3,1.2a32.8,32.8,0,0,0-1.4,10.7,2.9,2.9,0,0,0,2.9,2.8h.1a3,3,0,0,0,2.8-3,29.5,29.5,0,0,1,1.1-8.8A50.1,50.1,0,0,0,58.3,1.2Z" />
      <path [style.fill]="color.mark" d="M93.7,37h0a21.6,21.6,0,0,0-5.7.1,2.9,2.9,0,0,0-2.3,3.4,3,3,0,0,0,3.5,2.3,20.2,20.2,0,0,1,5.5.1A36.3,36.3,0,0,0,93.7,37Z" />
      <path d="M88.1,46.8a2.9,2.9,0,1,0-.4,5.8,29.6,29.6,0,0,1,6.6,2.1q.5-3,.6-6A25.5,25.5,0,0,0,88.1,46.8Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="7">
      <defs>
        <style>
          .a {
            fill: #ccdde1;
          }
        </style>
      </defs>
      <title>mark7</title>
      <path class="a" d="M59.7,1.6c-1.6,6.7-4.6,19.4-4.6,21S67.7,54,67.7,54l3-5.6,4.7-5.6s-6.9-18-6.9-20.8,2.7-10.6,4.1-14.8A46.3,46.3,0,0,0,59.7,1.6Z" />
      <polygon
        class="a"
        points="35.6 22.2 32.8 25.2 32.2 25.3 32.4 25.5 28.7 29.5 27.8 29.5 28.2 30 22.8 36.2 21.2 44.4 20.4 44.3 21.1 45.1 20.8 49.9 22.4 45.5 22.9 45.5 22.5 45.1 26.2 36.2 29.9 30.7 31.4 30.6 30.4 30 33.1 25.9 34 26 33.4 25.5 35.6 22.2"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="8">
      <path [style.fill]="color.mark" d="M0,47.4a28.1,28.1,0,0,0,.1,2.9c38-4.4,30.6-26.7,22.8-43.5A47.5,47.5,0,0,0,0,47.4Z" />
      <path d="M94.9,47.4A47.4,47.4,0,0,0,73.3,7.6c-6,15.6-7.1,34.5,21.6,39.8Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="9">
      <path [style.fill]="color.mark" d="M20.4,17.7c13.9,8.5,12.2-.3,11.1-4.5-.6-2.2-2.6-5.8-4.3-8.7A47.7,47.7,0,0,0,14,13.8Z" />
      <path [style.fill]="color.mark" d="M54.3.5A50.9,50.9,0,0,0,47.4,0,48.1,48.1,0,0,0,36,1.4c.8,3.1,1.4,6.4,2,9.6,1.4,7.2,4,14.6,8.9,14.7C53.7,26,54.4,9.6,54.3.5Z" />
      <path d="M67.7,4.5C65.3,11.2,61.9,23,66.3,25s10.1-5.3,14.6-11.1A47.4,47.4,0,0,0,67.7,4.5Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="10">
      <path [style.fill]="color.mark" d="M18.7,20.6a4.6,4.6,0,0,0,3.6-3c.1-.2,1-2.3,0-3.9s-4.4-2.4-6.6-1.6l-1.8,1.8C13.2,16.3,15.9,20.7,18.7,20.6Z" />
      <path [style.fill]="color.mark" d="M60,1.7c5.4,17.8,20.9,23.8,28.4,25.1l1.9.2A47.6,47.6,0,0,0,60,1.7Z" />
      <path [style.fill]="color.mark" d="M38.1,12.4a8.1,8.1,0,0,0,.3,9.7c3.1,3.6,9.1,1.9,10.8,1.4,4.2-1.2,9.7-4.7,9.2-7.6C57.7,11.3,42.7,6.9,38.1,12.4Z" />
      <path d="M32.7,30.4c-2.5,1.8-3.1,7.3-.7,9.9s4.8,1.5,7.3,1.3c.3-.1,7.7-.9,8.2-3.6S37.1,27.3,32.7,30.4Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="11">
      <path [style.fill]="color.mark" d="M75.3,9.1a48.2,48.2,0,0,0-3.4,9.2.6.6,0,0,0,.4.7h.4a39.5,39.5,0,0,1,9.1-4.2A49.3,49.3,0,0,0,75.3,9.1Z" />
      <path
        [style.fill]="color.mark"
        d="M57.6,24.3c.3.2.6,0,.8-.3.8-1.9,1.8-3.8,2.6-5.8A117.6,117.6,0,0,1,67.5,4.4,53.1,53.1,0,0,0,57.3,1c-.3,3.2-.4,6.4-.5,9.7,0,0,.4,9.1.4,13.1A.7.7,0,0,0,57.6,24.3Z"
      />
      <path [style.fill]="color.mark" d="M27.2,8a100.9,100.9,0,0,1,6.6,9.5,67.2,67.2,0,0,1,3.7,6.7.7.7,0,0,0,.8.3c.2-.1.4-.4.3-.6C38,16.4,38.5,8.4,36,1.4A50,50,0,0,0,24.7,5.8Z" />
      <path d="M20.8,18.4v-.3a29.3,29.3,0,0,0-3.9-6.9,50.2,50.2,0,0,0-6.1,6.1,72.1,72.1,0,0,1,9.3,1.5A.6.6,0,0,0,20.8,18.4Z" [style.fill]="color.mark" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="12">
      <path
        d="M47.5,0A47.5,47.5,0,0,0,22.7,7c1.2,2.6,3,4.9,5.6,5.7s4.5.2,7.2,2.1,3.8,4.8,5,7.7a10.8,10.8,0,0,0,4.8,5.8,8.2,8.2,0,0,0,8-.2c1.8-1.1,2.4-2.4,3.5-5.2,2-5.1,1.3-5.3,3-7.9s5.1-2.6,8.5-5.4a9.5,9.5,0,0,0,2.8-3.3A47,47,0,0,0,47.5,0Z"
        [style.fill]="color.mark"
      />
    </svg>
  </div>

  <!-- eye -->
  <div class="positioning" [ngSwitch]="chosen.eye">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="1">
      <path d="M24.1,43.4a12.8,12.8,0,0,1-10.9-6.2,1.4,1.4,0,0,1,.5-1.8,1.3,1.3,0,0,1,1.8.5,10.2,10.2,0,0,0,8.6,5A10.1,10.1,0,0,0,34,32.6a1.3,1.3,0,0,1,2.5.5A12.6,12.6,0,0,1,24.1,43.4Z" />
      <path
        class="a"
        d="M70.8,43.4A12.6,12.6,0,0,1,58.4,33.1a1.3,1.3,0,0,1,1-1.5,1.3,1.3,0,0,1,1.5,1.1,10.1,10.1,0,0,0,9.9,8.2,10.2,10.2,0,0,0,8.6-5,1.3,1.3,0,0,1,1.8-.5,1.4,1.4,0,0,1,.5,1.8A12.9,12.9,0,0,1,70.8,43.4Z"
        style="fill:#352a1f;"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="2">
      <path style="fill:#352a1f;" d="M75.6,35c2.7,4.4,2.1,9.8-1.5,12s-8.7.5-11.4-3.9-2.1-9.8,1.5-12S72.9,30.6,75.6,35Z" />
      <path style="fill:#fffefa;" d="M71.6,33.5a3.4,3.4,0,0,1-.5,4.6,3.4,3.4,0,0,1-4.4-1.5c-1.1-1.7-.8-3.8.6-4.6S70.6,31.8,71.6,33.5Z" />
      <path style="fill:#352a1f;" d="M34.3,43.6c-2.8,4.3-7.9,6-11.5,3.7s-4.1-7.6-1.3-12,7.9-6,11.5-3.7S37.1,39.2,34.3,43.6Z" />
      <path style="fill:#fffefa;" d="M34,39.3c-1.1,1.7-3.1,2.3-4.4,1.5s-1.6-3-.5-4.6,3-2.3,4.4-1.5A3.4,3.4,0,0,1,34,39.3Z" />
      <path style="fill:#352a1f;" d="M16.9,34.1c5.1-2.5,10.5-5,15-8.6,1.5-1.3-.5-3.8-2.2-2.7l-7.1,4.4c-2.4,1.4-4.9,2.7-7.4,3.9s-.3,4,1.7,3Z" />
      <path style="fill:#352a1f;" d="M83.8,31.1c-2.4-1.2-4.8-2.4-7.1-3.7s-5-3.1-7.5-4.6-3.7,1.4-2.1,2.7c4.4,3.6,9.8,6.1,14.9,8.6,2,1,3.8-2,1.8-3Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="3">
      <ellipse style="fill:#fffefa;" cx="68.9" cy="38.2" rx="13.1" ry="10.8" />
      <path style="fill:#7da8b1;" d="M78.2,38.2c0,4.2-4.1,7.7-9.3,7.7s-9.2-3.5-9.2-7.7,4.1-7.7,9.2-7.7S78.2,34,78.2,38.2Z" />
      <path style="fill:#352a1f;" d="M75.8,38.2c0,3.2-3,5.7-6.9,5.7s-6.8-2.5-6.8-5.7,3-5.7,6.8-5.7S75.8,35.1,75.8,38.2Z" />
      <path style="fill:#fffefa;" d="M75.5,35.5a2.9,2.9,0,1,1-2.8-2.8A2.9,2.9,0,0,1,75.5,35.5Z" />
      <path style="fill:#fffefa;" d="M66.9,41.4a1.3,1.3,0,0,1-1.2,1.2,1.2,1.2,0,0,1-1.2-1.2,1.2,1.2,0,0,1,1.2-1.2A1.2,1.2,0,0,1,66.9,41.4Z" />
      <path style="fill:#fffefa;" d="M39,38.2c0,6-5.8,10.8-13.1,10.8s-13-4.8-13-10.8,5.8-10.8,13-10.8S39,32.2,39,38.2Z" />
      <ellipse style="fill:#7da8b1;" cx="25.9" cy="38.2" rx="9.3" ry="7.7" />
      <path style="fill:#352a1f;" d="M32.8,38.2c0,3.2-3,5.7-6.9,5.7S19,41.4,19,38.2s3.1-5.7,6.9-5.7S32.8,35.1,32.8,38.2Z" />
      <path style="fill:#fffefa;" d="M32.5,35.5a2.9,2.9,0,1,1-2.8-2.8A2.9,2.9,0,0,1,32.5,35.5Z" />
      <path style="fill:#fffefa;" d="M23.9,41.4a1.3,1.3,0,0,1-1.2,1.2,1.3,1.3,0,0,1-1.3-1.2,1.2,1.2,0,0,1,1.3-1.2A1.2,1.2,0,0,1,23.9,41.4Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="4">
      <path
        style="fill:#352a1f;"
        d="M13.1,32.7a7.1,7.1,0,0,1,1.4-1l1.6-.8a13.9,13.9,0,0,1,3.5-.9,14.9,14.9,0,0,1,7.2,1,14.3,14.3,0,0,1,5.8,4.5,10.9,10.9,0,0,1,1.7,3.2,8.4,8.4,0,0,1,.5,1.7,5.5,5.5,0,0,1,.1,1.8,7.1,7.1,0,0,0-1-1.4,14.7,14.7,0,0,0-1-1.3,21.9,21.9,0,0,0-2.3-2.1,19.4,19.4,0,0,0-5.1-3.1,19.7,19.7,0,0,0-5.9-1.5,16.7,16.7,0,0,0-3.2-.2,8.5,8.5,0,0,0-1.6,0Z"
      />
      <path
        style="fill:#352a1f;"
        d="M81,35.1l-1.6-.3-1.7-.3H74.6a23.5,23.5,0,0,0-6,.8,22.7,22.7,0,0,0-5.5,2.5,20.7,20.7,0,0,0-2.5,1.9l-1.2,1.1L58.3,42a5.7,5.7,0,0,1,.3-1.8l.6-1.6a16.1,16.1,0,0,1,2.1-3,13.1,13.1,0,0,1,6.3-3.7,13.9,13.9,0,0,1,7.3-.2,9.3,9.3,0,0,1,3.3,1.2,5.6,5.6,0,0,1,1.5,1A3.7,3.7,0,0,1,81,35.1Z"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="5">
      <path style="fill:#352a1f;" d="M75.1,39.4c0,4.7-3.1,8.5-6.9,8.5s-6.9-3.8-6.9-8.5,3.1-8.5,6.9-8.5S75.1,34.7,75.1,39.4Z" />
      <path style="fill:#fff;" d="M72.8,36.3c0,1.8-1.2,3.3-2.6,3.3s-2.7-1.5-2.7-3.3a3,3,0,0,1,2.7-3.2C71.6,33.1,72.8,34.6,72.8,36.3Z" />
      <path style="fill:#352a1f;" d="M32.5,39.4c0,4.7-3.1,8.5-6.9,8.5s-6.9-3.8-6.9-8.5,3.1-8.5,6.9-8.5S32.5,34.7,32.5,39.4Z" />
      <path style="fill:#fff;" d="M30.2,36.3c0,1.8-1.2,3.3-2.7,3.3s-2.6-1.5-2.6-3.3,1.2-3.2,2.6-3.2A3,3,0,0,1,30.2,36.3Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="6">
      <path style="fill:#fff;" d="M79.9,40.1a10.6,10.6,0,0,1-1.2,5L58.6,46.7A18.8,18.8,0,0,1,57,40.1a11.5,11.5,0,0,1,22.9,0Z" />
      <path style="fill:#65756b;" d="M76.3,41.6a8.2,8.2,0,0,1-.8,3.5h-14a7.7,7.7,0,0,1-.9-3.6,7.8,7.8,0,0,1,7.8-7.8A7.9,7.9,0,0,1,76.3,41.6Z" />
      <path style="fill:#352a1f;" d="M73.7,42.3a6.4,6.4,0,0,1-.5,2.4H63.7a6.4,6.4,0,0,1-.5-2.4,5.3,5.3,0,1,1,10.5,0Z" />
      <path style="fill:#fff;" d="M73.6,38.3a2,2,0,0,1-2,2,2,2,0,0,1,0-4A2,2,0,0,1,73.6,38.3Z" />
      <path style="fill:#fff;" d="M15.7,40.3a11,11,0,0,0,1.2,5.1L37.4,47A18.8,18.8,0,0,0,39,40.3a11.7,11.7,0,0,0-23.3,0Z" />
      <path style="fill:#65756b;" d="M19.4,41.8a8.6,8.6,0,0,0,.8,3.6c.4.1,14.3,0,14.3,0a8.6,8.6,0,0,0,.8-3.6,8,8,0,0,0-15.9,0Z" />
      <path style="fill:#352a1f;" d="M22,42.5a7,7,0,0,0,.5,2.5h9.6a5.4,5.4,0,0,0,.6-2.5,5.4,5.4,0,0,0-10.7,0Z" />
      <path style="fill:#fff;" d="M28.6,38.5a2,2,0,1,0,2-2A2,2,0,0,0,28.6,38.5Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="7">
      <path style="fill:#352a1f;" d="M19.2,33.1a7.3,7.3,0,0,0-.5,3c0,4.5,3.3,8.2,7.5,8.2s7.6-3.7,7.6-8.2a9.4,9.4,0,0,0-.5-3Z" />
      <path style="fill:#fffefa;" d="M30.8,36.2a2.3,2.3,0,1,1-2.3-2.5A2.4,2.4,0,0,1,30.8,36.2Z" />
      <path style="fill:#352a1f;" d="M37.4,34.9H10.5a1.1,1.1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.1-1.3H37.4a1.3,1.3,0,0,1,1.2,1.2A1.2,1.2,0,0,1,37.4,34.9Z" />
      <path style="fill:#352a1f;" d="M59.7,33.1a7.3,7.3,0,0,0-.5,3c0,4.5,3.3,8.2,7.5,8.2s7.6-3.7,7.6-8.2a7.3,7.3,0,0,0-.6-3l-5.5-.6Z" />
      <path style="fill:#fffefa;" d="M71.3,36.2A2.3,2.3,0,1,1,69,33.7,2.4,2.4,0,0,1,71.3,36.2Z" />
      <path style="fill:#352a1f;" d="M77.9,34.9H51a1.1,1.1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.1-1.3H77.9a1.3,1.3,0,0,1,1.2,1.2A1.2,1.2,0,0,1,77.9,34.9Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="8">
      <path style="fill:#fffefa;" d="M40.3,38.5c0,6.5-5.9,11.8-13.1,11.8s-13-5.3-13-11.8,5.8-11.9,13-11.9S40.3,31.9,40.3,38.5Z" />
      <path style="fill:#352a1f;" d="M34.1,41.2c0,4.2-2.9,7.6-6.5,7.6s-6.5-3.4-6.5-7.6,2.9-7.7,6.5-7.7S34.1,36.9,34.1,41.2Z" />
      <path style="fill:#fffefa;" d="M32,38.4a2.8,2.8,0,0,1-2.6,2.9,2.8,2.8,0,0,1-2.5-2.9,2.9,2.9,0,0,1,2.5-3A2.9,2.9,0,0,1,32,38.4Z" />
      <path style="fill:#fffefa;" d="M80.7,38.5c0,6.5-5.8,11.8-13,11.8S54.6,45,54.6,38.5s5.8-11.9,13.1-11.9S80.7,31.9,80.7,38.5Z" />
      <ellipse style="fill:#352a1f;" cx="68" cy="41.2" rx="6.5" ry="7.6" />
      <path style="fill:#fffefa;" d="M72.3,38.4a2.5,2.5,0,1,1-5,0,2.5,2.5,0,1,1,5,0Z" />
    </svg>
  </div>

  <!-- mouth background -->
  <div class="positioning">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9">
      <path style="fill:#fff;" d="M1.4,58.9a47.4,47.4,0,0,0,91.6,2c-14-4.3-32.7-6.1-51.7-6.1C25.5,54.8,11.9,56,1.4,58.9Z" />
    </svg>
  </div>

  <!-- mouth -->
  <div class="positioning" [ngSwitch]="chosen.mouth">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="1">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path
        style="fill:#352a1f;"
        d="M59.5,65.3A28.9,28.9,0,0,0,49,61.5c-.1-.2-.1-.4-.2-.6a7.7,7.7,0,0,0-.2-1.5,14.5,14.5,0,0,0-.4-2.9c-.4-1-2-.6-1.7.5a20.2,20.2,0,0,1,.4,2.6,11.1,11.1,0,0,0,.2,1.7h0A13.3,13.3,0,0,0,37.7,66c-.7.7.4,1.9,1.1,1.1A11.9,11.9,0,0,1,48.1,63c3.8.2,7.4,1.9,10.6,3.7A.8.8,0,0,0,59.5,65.3Z"
      />
      <path style="fill:#e9b7af;" d="M51.8,55.1a4.9,4.9,0,0,1-9,0c-.2-1.2,1.8-2.2,4.5-2.3S52,53.9,51.8,55.1Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="2">
      <path
        style="fill:#352a1f;"
        d="M56.1,59.9a.8.8,0,0,0-1.1.4A4.1,4.1,0,0,1,51.2,63a4.3,4.3,0,0,1-3-1.3V56.3a.8.8,0,0,0-.8-.8.8.8,0,0,0-.7.8v5.4a4.3,4.3,0,0,1-3,1.3,4.1,4.1,0,0,1-3.8-2.7.8.8,0,0,0-1.1-.4.7.7,0,0,0-.4,1,5.7,5.7,0,0,0,5.3,3.7,5.6,5.6,0,0,0,3.7-1.5,6.1,6.1,0,0,0,3.8,1.5,5.7,5.7,0,0,0,5.3-3.7A.7.7,0,0,0,56.1,59.9Z"
      />
      <path style="fill:#e9b7af;" d="M51.5,55.2c-.2,1.1-2.2,2.9-4.4,2.9a5,5,0,0,1-4.5-2.9C42.4,54,44.4,53,47.1,53S51.7,54,51.5,55.2Z" />
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="3">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path
        style="fill:#352a1f;"
        d="M55.8,59.4a.7.7,0,0,0-1,.4,3.9,3.9,0,0,1-3.5,2.6h-.2a4,4,0,0,1-2.9-1.2V55.9a.8.8,0,0,0-.8-.8.8.8,0,0,0-.7.8v5.3a4,4,0,0,1-2.9,1.2h-.2A4.1,4.1,0,0,1,40,59.8a.6.6,0,0,0-.9-.4.9.9,0,0,0-.5,1,5.5,5.5,0,0,0,5,3.5h.1a3.9,3.9,0,0,0,7.6,0h.1a5.6,5.6,0,0,0,4.9-3.5A.9.9,0,0,0,55.8,59.4Z"
      />
      <path style="fill:#e9b7af;" d="M51.7,55.3a4.5,4.5,0,0,1-3.8,2.9h-1a4.5,4.5,0,0,1-3.8-2.9c-.2-1.1,1.5-2.1,3.8-2.1H48C50.3,53.2,52,54.2,51.7,55.3Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="4">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path
        style="fill:#dcaca3;"
        d="M54.9,64l-6.7-6.7h0a1.1,1.1,0,0,0-1.3,0L40.2,64a1.1,1.1,0,0,0,0,1.3,1.1,1.1,0,0,0,.7.3.9.9,0,0,0,.6-.3l6-6,6.1,6a.9.9,0,0,0,.6.3,1.1,1.1,0,0,0,.7-.3A1.1,1.1,0,0,0,54.9,64Z"
      />
      <path style="fill:#dcaca3;" d="M52.3,54.9c-.2,1.2-2.1,3-4.2,3.1H47c-2.1-.1-4-1.9-4.2-3.1s1.6-2.4,4.1-2.4h1.3C50.7,52.5,52.6,53.6,52.3,54.9Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="5">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path
        style="fill:#352a1f;"
        d="M67.1,72.2a62.5,62.5,0,0,0-10.6,2c-6.2,1.7-13.4,6-16.1,10.5H39.1a13.9,13.9,0,0,1-3-.3c-6.6-1.1-11.5-6-11.5-11.7s3.6-9.1,8.7-11a18.1,18.1,0,0,1,5.8-.9H53.4a16.9,16.9,0,0,1,6,1.3C63.8,64.1,66.9,67.8,67.1,72.2Z"
      />
      <path style="fill:#e9b7af;" d="M67.2,72.7c0,4.5-3,8.4-7.4,10.4A16,16,0,0,1,54,84.6H40.4c2.7-4.5,9.9-8.8,16.1-10.5a62.5,62.5,0,0,1,10.6-2A1.1,1.1,0,0,1,67.2,72.7Z" />
      <path style="fill:#fffefa;" d="M41.9,84.7H39.1a13.9,13.9,0,0,1-3-.3c.1-1,.3-2,.5-3.1a3.3,3.3,0,0,1,1-2.1h.3c.3-.1,1.1.6,1.7,1.6S41.2,83.4,41.9,84.7Z" />
      <path style="fill:#fffefa;" d="M59.8,83.1A16,16,0,0,1,54,84.6c.2-1.4.4-2.9.7-4.3s.8-2.1,1.1-2.1H56c.3,0,1.1.7,1.8,1.6Z" />
      <path style="fill:#fffefa;" d="M59.4,62.1,56.5,66c-.8.8-1.7,1.4-1.9,1.3h-.3c-.3,0-.6-1-.8-2.2s-.1-2.8-.1-4.2A15,15,0,0,1,59.4,62.1Z" />
      <path style="fill:#fffefa;" d="M39.5,60.7v.2c-.4,1.6-.9,3.2-1.4,4.7s-1.1,1.9-1.4,1.9h-.2c-.3,0-1-.8-1.5-1.9s-1.2-2.6-1.7-4a18.1,18.1,0,0,1,5.8-1Z" />
      <path style="fill:#e9b7af;" d="M51.1,55.2a4.6,4.6,0,0,1-4,2.9,1.4,1.4,0,0,1-.9,0,4.6,4.6,0,0,1-4-2.9c-.2-1.1,1.6-2.1,3.9-2.2h1.1C49.6,53.1,51.3,54.1,51.1,55.2Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="6">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path style="fill:#352a1f;" d="M47.4,66.7H45.6a4.9,4.9,0,0,0-1.7.1.5.5,0,0,0,0,1h1.9a4,4,0,0,0,1.9-.2A.6.6,0,1,0,47.4,66.7Z" />
      <path
        style="fill:#352a1f;"
        d="M59.4,64.1a18.2,18.2,0,0,1-2.3-1.5l-.3-.3a1.1,1.1,0,0,1-.3-.8.3.3,0,1,0-.6,0,2.8,2.8,0,0,0,.1.9,32.4,32.4,0,0,1-9.7,2.1V63.3c.1-.6.1-1.2.2-1.7l.3-3.5c.1-.6-.7-.9-.9-.3s-.7,2.4-.9,3.6-.1,1.1-.2,1.7a6.5,6.5,0,0,0,0,1.4c-2.7-.1-5.6-1-7.3-3.3l.3-.2c.4-.2.2-.9-.3-.7a.9.9,0,0,0-.7.3c-.3,0-.6.2-.6.4l-.8.7c-.3.4.2,1,.6.6l.5-.4c2,3.1,5.7,4.1,9.2,4.1a23.3,23.3,0,0,0,11-2.5,3.8,3.8,0,0,0,2.4,1.3A.5.5,0,0,0,59.4,64.1Z"
      />
      <path style="fill:#e9b7af;" d="M51.1,55.3c-.1,1-1.6,2.4-3.4,2.5a1.1,1.1,0,0,1-.8,0,3.9,3.9,0,0,1-3.4-2.5c-.2-1,1.3-1.9,3.3-1.9h1C49.8,53.4,51.4,54.3,51.1,55.3Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="7">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path
        style="fill:#352a1f;"
        d="M55.4,64.2a43,43,0,0,0-7.3-1.8V55.7a.9.9,0,0,0-1.8,0v6.7a34.9,34.9,0,0,0-6.9,1.8.9.9,0,0,0,.4,1.8h.3c.1,0,4.2-1.8,7.1-1.8s7.6,1.8,7.6,1.8a1,1,0,0,0,1.2-.5A1,1,0,0,0,55.4,64.2Z"
      />
      <path style="fill:#e9b7af;" d="M51.5,55.3a4.5,4.5,0,0,1-3.9,2.9h-1a4.8,4.8,0,0,1-3.9-2.9c-.2-1.1,1.5-2.2,3.9-2.2h1.1C50,53.1,51.7,54.2,51.5,55.3Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.9 94.9" *ngSwitchCase="8">
      <path style="fill:#352a1f;" d="M63.3,60.8a1,1,0,0,0,1.2.9,1.1,1.1,0,0,0,1-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,63.3,60.8Z" />
      <path style="fill:#352a1f;" d="M69.5,64.6a1,1,0,0,0,1.2.9,1.2,1.2,0,0,0,1-1.3,1.1,1.1,0,0,0-2.2.4Z" />
      <path style="fill:#352a1f;" d="M69.5,60.3a1.1,1.1,0,0,0,1.3.9,1,1,0,0,0,.9-1.2,1.2,1.2,0,0,0-1.3-1A1.1,1.1,0,0,0,69.5,60.3Z" />
      <path style="fill:#352a1f;" d="M75.2,62.6a1.1,1.1,0,0,0,2.2-.4,1,1,0,0,0-1.2-.9A1.2,1.2,0,0,0,75.2,62.6Z" />
      <path style="fill:#352a1f;" d="M31.2,60.8a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2,1.2,1.2,0,0,1,1.3-1A1.1,1.1,0,0,1,31.2,60.8Z" />
      <path style="fill:#352a1f;" d="M25,64.6a1.1,1.1,0,1,1-.9-1.3A1.1,1.1,0,0,1,25,64.6Z" />
      <path style="fill:#352a1f;" d="M25,60.3a1.1,1.1,0,0,1-1.3.9,1,1,0,0,1-.9-1.2A1.1,1.1,0,0,1,24,59,1.1,1.1,0,0,1,25,60.3Z" />
      <path style="fill:#352a1f;" d="M19.2,62.6a1,1,0,0,1-1.2.9,1.2,1.2,0,0,1-1-1.3,1.1,1.1,0,1,1,2.2.4Z" />
      <path
        style="fill:#352a1f;"
        d="M55.7,59.1V60A14,14,0,0,1,54,66.2l-.7,1.4c-1.6,2.3-3.8,3.9-5.9,4H47c-2.1-.1-4.3-1.6-5.9-4a5,5,0,0,1-.8-1.2,15.5,15.5,0,0,1-1.8-6.2V59.1h0a.8.8,0,0,1,.5-.7c.4-.1.8,0,.9.4s.3.5.4.8a4.2,4.2,0,0,0,3.3,1.7,3.7,3.7,0,0,0,2.9-1.4l.6-.2h0l.6.2a3.7,3.7,0,0,0,3,1.4,4,4,0,0,0,3.2-1.7,3,3,0,0,0,.4-.8.7.7,0,0,1,.9-.4.8.8,0,0,1,.5.7Z"
      />
      <path style="fill:#dcaca3;" d="M53.3,67.6c-1.6,2.3-3.8,3.9-5.9,4H47c-2.1-.1-4.3-1.6-5.9-4a9.8,9.8,0,0,1,6.6-2C50.3,65.7,52.6,66.7,53.3,67.6Z" />
      <path style="fill:#dcaca3;" d="M51.1,54.7a4.4,4.4,0,0,1-3.7,2.8h-1a4.4,4.4,0,0,1-3.7-2.8c-.2-1.1,1.4-2.1,3.7-2.1h1C49.7,52.6,51.3,53.6,51.1,54.7Z" />
    </svg>
  </div>
</div>
