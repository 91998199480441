import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, finalize, map, switchMap, takeWhile, tap } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { User } from '@app/models';
import { NotyService, OrganizationService, UserService } from '@app/services';
import { EditOrganizationModalService } from './edit-organization-modal/edit-organization-modal.service';
import { DeleteSelfOrganizationMemberModalService } from './delete-self-organization-member-modal/delete-self-organization-member-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-organization',
  templateUrl: './configurations-organization.component.html',
  styleUrls: ['../configurations.scss', './configurations-organization.component.scss']
})
export class ConfigurationsOrganizationComponent implements OnInit, OnDestroy {
  currentUser: User;
  isLoading = false;
  isLastMember: boolean;

  constructor(
    public userService: UserService,
    private router: Router,
    private notyService: NotyService,
    private organizationService: OrganizationService,
    private editOrganizationModalService: EditOrganizationModalService,
    private deleteSelfOrganizationMemberModalService: DeleteSelfOrganizationMemberModalService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => (this.currentUser = user));

    this.organizationService.organizationMembers({ page: 1, limit: 2 }, { fetchPolicy: 'network-only' }).subscribe(page => (this.isLastMember = (page.data || []).length <= 1));
  }

  ngOnDestroy() {}

  openEditOrganizationModal() {
    this.editOrganizationModalService.open({ user: this.currentUser }).subscribe();
  }

  openSelfDeleteOrganizationMemberModal() {
    // Se não for o último membro, não pode sair se for admin
    // Ao sair da organização como último membro, a organização é excluída.
    // Não deixa que a organização seja excluída se o usuário não tiver outros membros ou uma outra organização. (Evita que alguém que acabou de criar conta de tentar ficar excluíndo ela)

    const canDelete$ = this.isLastMember
      ? this.organizationService.getOrganizations({ fetchPolicy: 'cache-first' }).pipe(map(orgs => orgs.length > 1))
      : this.organizationService.organizationMember({ id: this.currentUser.id }).pipe(switchMap(member => this.organizationService.canMemberBeRemovedFromGroup$(member)));

    this.isLoading = true;
    canDelete$
      .pipe(
        tap(canDelete => !canDelete && this.notyService.error(this.translateService.instant('notyService.' + (this.isLastMember ? 'cannotDeleteWithoutOtherOrganizations' : 'cannotLeaveLastAdmin')))),
        takeWhile(canDelete => canDelete),
        switchMap(() => this.deleteSelfOrganizationMemberModalService.open({ user: this.currentUser, isLastMember: this.isLastMember })),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(() => this.router.navigate(['/documentos/todos']));
  }
}
