import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type ModalResult = void;

@Component({
  selector: 'app-version-update-modal',
  templateUrl: './version-update-modal.component.html',
  styleUrls: ['./version-update-modal.component.scss']
})
export class VersionUpdateModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
