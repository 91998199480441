import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

export type ModalResult = string;

@Component({
  selector: 'app-current-password-modal',
  templateUrl: './current-password-modal.component.html',
  styleUrls: ['./current-password-modal.component.scss']
})
export class CurrentPasswordModalComponent implements AfterContentInit {
  form!: FormGroup;
  @ViewChild('passwordInput', { static: false }) private passwordInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ password: '' });
  }

  ngAfterContentInit() {
    setTimeout(() => this.passwordInput.nativeElement.focus());
  }

  send() {
    this.modal.close(this.form.get('password').value as ModalResult);
  }
}
