import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoaderService } from '@app/shared/loader/loader.service';

/**
 * Enables loader when a request is running
 */
@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(request);
    if (!this.loaderService.httpLoading.getValue()) {
      this.loaderService.httpLoading.next(true);
    }

    return next.handle(request).pipe(
      tap(
        event => (event instanceof HttpResponse ? this.removeRequest(request) : null),
        () => this.removeRequest(request),
        () => this.removeRequest(request)
      )
    );
  }

  private removeRequest(request: HttpRequest<any>) {
    const index = this.requests.indexOf(request);
    if (index >= 0) {
      this.requests.splice(index, 1);
      if (this.requests.length === 0) {
        this.loaderService.httpLoading.next(false);
      }
    }
  }
}
