import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as intlTelInput from 'intl-tel-input';

declare const intlTelInputGlobals: any;

@Injectable({ providedIn: 'root' })
export class PhoneService {
  static isValidPhone(phoneNumber: string) {
    const phoneInput = PhoneService.createPhoneInput(phoneNumber);
    return !!phoneInput.getNumber() && phoneInput.isValidNumber();
  }

  static getFullPhoneNumber(phoneNumber: string) {
    return PhoneService.createPhoneInput(phoneNumber)
      .getNumber()
      ?.replace(/^[+]/, '');
  }

  static createPhoneInput(phoneNumber: string) {
    const wrapperElement = document.createElement('div'); // Sim, dá erro se o input não tiver um parentElement
    const inputElement = document.createElement('input');
    inputElement.value = (phoneNumber || '').replace(/[^0-9]/g, '');
    wrapperElement.appendChild(inputElement);

    return intlTelInput(inputElement, {
      initialCountry: 'br',
      nationalMode: true,
      placeholderNumberType: 'MOBILE',
      utilsScript: this.intlTelUtilsScript()
    });
  }

  static intlTelUtilsScript() {
    return intlTelInputGlobals?.version && `https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/${intlTelInputGlobals.version}/js/utils.js`;
  }

  constructor(private httpClient: HttpClient) {}

  sendSmsVerification(phoneNumber: string, forceUserCreation?: boolean) {
    return this.sendPhoneVerification('sms', phoneNumber, forceUserCreation);
  }

  sendWhatsappVerification(phoneNumber: string, forceUserCreation?: boolean) {
    return this.sendPhoneVerification('wa', phoneNumber, forceUserCreation);
  }

  submitSmsVerificationCode(phoneNumber: string, verificationCode: string) {
    return this.submitPhoneVerificationCode('sms', phoneNumber, verificationCode);
  }

  submitWhatsappVerificationCode(phoneNumber: string, verificationCode: string) {
    return this.submitPhoneVerificationCode('wa', phoneNumber, verificationCode);
  }

  private sendPhoneVerification(type: 'sms' | 'wa', phoneNumber: string, forceUserCreation: boolean) {
    return this.httpClient.disableLoader().post(`/auth/login/${type}/send`, { phone_number: PhoneService.getFullPhoneNumber(phoneNumber), create: forceUserCreation });
  }

  private submitPhoneVerificationCode(type: 'sms' | 'wa', phoneNumber: string, verificationCode: string) {
    return this.httpClient.disableLoader().post<{ access_token: string }>(`/auth/login/${type}/verify`, { phone_number: PhoneService.getFullPhoneNumber(phoneNumber), code: verificationCode });
  }
}
