import { Pipe, PipeTransform } from '@angular/core';

import { SlimDocument } from '@app/models';
import { DocumentService } from '@app/services';

@Pipe({ name: 'filterSignedSigners' })
export class FilterSignedSignersPipe implements PipeTransform {
  constructor(private documentService: DocumentService) {}

  transform(signers: SlimDocument['signatures']): SlimDocument['signatures'] {
    return signers.filter(signer => signer.action && this.documentService.isSignerSigned(signer));
  }
}
