import { Observable } from 'rxjs';

export function loadTinyMce() {
  return new Observable<undefined>(subscriber => {
    if (!document.getElementById('tinymce-script')) {
      const scriptElement = document.createElement('script');
      scriptElement.src = '/vendors/tinymce/tinymce.min.js';
      scriptElement.type = 'text/javascript';
      scriptElement.id = 'tinymce-script';
      scriptElement.onload = () => {
        subscriber.next();
        subscriber.complete();
      };
      scriptElement.onerror = () => {
        subscriber.error();
      };
      document.getElementsByTagName('head')[0].appendChild(scriptElement);
    } else {
      subscriber.next();
      subscriber.complete();
    }
  });
}
