import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { untilDestroyed } from '@app/core';
import { CreditPackType, Customer, Invoice, Order, PaymentMethodsEnum, Plan, User } from '@app/models';
import { AlertModalService, LoaderService, SetMissingUserInfoModalService } from '@app/shared';
import { LdatePipe } from '@app/shared/pipes';
import { AppService, ErrorHandlerService, NotyService, OrganizationService, PaymentService, UserService } from '@app/services';
import { EditPaymentInfoModalService, EditPaymentInfoModalStep } from './edit-payment-info-modal/edit-payment-info-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

type CustomPlan = Plan & { promo?: Plan };

@Component({
  selector: 'app-configurations-plan',
  templateUrl: './configurations-plan.component.html',
  styleUrls: ['../configurations.scss', './configurations-plan.component.scss']
})
export class ConfigurationsPlanComponent implements OnInit, OnDestroy {
  plans: Plan[];
  credits: CreditPackType[];
  invoices: Invoice[];
  invoicePageNumber: number;
  invoiceIsLastPage = false;
  currentUser: User;
  currentPlan: CustomPlan;
  currentOrderPlan: CustomPlan;
  processingPlan: CustomPlan;
  featuredPlan: CustomPlan;
  planInSale: CustomPlan;
  currentOrder: Order;
  currentCustomer: Customer;
  PaymentMethodsEnum = PaymentMethodsEnum;
  isLoading = false;
  isYearly = false;
  isMobile = false;
  readonly EditPaymentInfoModalStep = EditPaymentInfoModalStep;
  private readonly plansIntervalMap = {
    1: { subject: this.translateService.instant('settings.month'), title: this.translateService.instant('settings.monthly') },
    6: { subject: this.translateService.instant('settings.semester'), title: this.translateService.instant('settings.semiAnnual') },
    9: { subject: this.translateService.instant('settings.nineMonths'), title: this.translateService.instant('settings.9Months') },
    12: { subject: this.translateService.instant('settings.year'), title: this.translateService.instant('settings.annual') }
  };

  constructor(
    public appService: AppService,
    public translateService: TranslateService,
    public userService: UserService,
    public organizationService: OrganizationService,
    private route: ActivatedRoute,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private alertModalService: AlertModalService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private paymentService: PaymentService,
    private editPaymentInfoModalService: EditPaymentInfoModalService,
    private setMissingUserInfoModalService: SetMissingUserInfoModalService,
    private ldatePipe: LdatePipe
  ) {}

  ngOnInit() {
    this.isMobile = window.innerWidth <= 992;
    const plans$ = this.paymentService.plans();
    const currentOrder$ = this.paymentService.order();
    const credits$ = this.paymentService.credits();

    this.userService
      .watchCurrentUser({ fetchPolicy: 'network-only' })
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        if (this.currentUser.currentPermissions.change_plan_oz) {
          this.isLoading = true;
          this.loaderService.show();
          forkJoin([plans$, currentOrder$, credits$])
            .pipe(
              finalize(() => {
                this.isLoading = false;
                this.loaderService.hide();
              })
            )
            .subscribe(([plans, currentOrder, credits]) => {
              this.plans = plans;
              this.credits = credits;
              this.setCurrentOrder(currentOrder);

              const currentPlan = this.plans.find(plan => plan.id === this.currentUser.subscription.iugu_plan);
              this.currentPlan = !this.userService.isSubscriptionFree(this.currentUser) || (this.currentOrder && this.currentOrder?.active && !this.currentOrder?.suspended) ? currentPlan : null;
              if (this.paymentService.hasSubscriptionInvoicePending(this.currentOrder) && !this.paymentService.isSubscriptionActive(this.currentUser)) {
                this.processingPlan = this.plans.find(plan => plan.name === this.currentOrder?.plan_name && parseFloat(plan.currency) === this.currentOrder?.price / 100);
              }
              this.setPromotions(this.currentPlan, this.plans);
              this.setPlanInSale(this.plans);
            });

          setTimeout(() => this.paymentService.currentCustomer().subscribe(currentCustomer => (this.currentCustomer = currentCustomer)), 100);
        }

        setTimeout(() => {
          this.loadInvoices();
        }, 100);
      });

    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(() => {
      this.setMissingUserInfoModalService.openIfMissingUserInfo({ ignoreCpf: true, dismissAsError: true }).subscribe(
        () => {},
        () => this.router.navigate(['/'])
      );
    });
  }

  loadInvoices(options: { page?: number } = {}) {
    this.invoicePageNumber = options.page || 1;
    this.paymentService.organizationInvoices({ organizationUuid: this.currentUser.organization.uuid, limit: 5, page: this.invoicePageNumber }).subscribe(
      page => {
        this.invoices = (this.invoicePageNumber === 1 ? [] : this.invoices || []).concat(page.data);
        this.invoiceIsLastPage = page.current_page >= page.last_page;
      },
      error => this.errorHandlerService.handle(error)
    );
  }

  ngOnDestroy() {}

  isGovernmentUser() {
    return this.currentUser && (this.currentUser.subscription.name || '').toLowerCase() === 'governamental';
  }

  hasGatewaySubscriptionExpired() {
    return this.paymentService.hasGatewaySubscriptionExpired(this.currentOrder);
  }

  isSubscriptionActive() {
    return this.paymentService.isSubscriptionActive(this.currentUser);
  }

  openUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  buySelectedPlan(plan: CustomPlan) {
    if (this.currentOrder?.latest_invoice?.status === 'pending') {
      this.alertModalService
        .warning({
          text: this.translateService.instant('settings.alertSwitchPlanPending')
        })
        .subscribe(() => {
          this.editPaymentInfoModalService.open({ currentCustomer: this.currentCustomer, currentOrder: this.currentOrder, plan }).subscribe(data => {
            this.currentCustomer = data.currentCustomer;
            this.createOrUpdateOrder(plan, data.payableWith, data.cardToken);
          });
        });
    } else {
      this.editPaymentInfoModalService.open({ currentCustomer: this.currentCustomer, currentOrder: this.currentOrder, plan }).subscribe(data => {
        this.currentCustomer = data.currentCustomer;
        this.createOrUpdateOrder(plan, data.payableWith, data.cardToken);
      });
    }
  }

  buySelectedCredit(credit: CreditPackType) {
    this.editPaymentInfoModalService.open({ currentCustomer: this.currentCustomer, currentOrder: this.currentOrder, creditsPack: credit }).subscribe(data => {
      this.currentCustomer = data.currentCustomer;
      this.buyCredits(credit, data.payableWith, data.cardToken);
    });
  }

  buyCredits(credit: CreditPackType, paymentMethod?: PaymentMethodsEnum, cardToken?: string) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loaderService.show();

      this.paymentService
        .buyCredits({
          package: credit.name,
          ...(paymentMethod ? { payment_method: paymentMethod } : {}),
          ...(cardToken ? { card_token: cardToken } : {})
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.loaderService.hide();
          })
        )
        .subscribe(
          invoice => {
            this.invoices.unshift(invoice);
            if (invoice) setTimeout(() => document.getElementById('invoices').scrollIntoView(), 500);
            this.notyService.success(this.translateService.instant('notyService.orderRegistered'));
          },
          error => {
            this.errorHandlerService.handle(error);
          }
        );
    }
  }

  cancelSubscription(isBuyPlan?: boolean): Observable<any> {
    return new Observable(subscriber => {
      if (this.currentOrder) {
        this.paymentService
          .cancelSubscription()
          .pipe(
            finalize(() => {
              if (!isBuyPlan) {
                this.isLoading = false;
                this.loaderService.hide();
              }
            })
          )
          .subscribe(
            () => {
              if (isBuyPlan) {
                subscriber.next(null);
                subscriber.complete();
                return;
              }
              if (this.currentUser.subscription.iugu_plan) {
                this.notyService.success(
                  this.translateService.instant('notyService.currentPlanCancelled', {
                    date: this.ldatePipe.transform(this.currentUser.subscription.expires_at, 'longDate')
                  }),
                  10000
                );
              } else {
                this.notyService.success(this.translateService.instant('notyService.currentPlanCancelledImmediately'), 10000);
                this.userService.getCurrentUser({ fetchPolicy: 'network-only' }).subscribe();
              }
              this.router.navigate(['/']);
              subscriber.next(null);
              subscriber.complete();
            },
            error => {
              this.errorHandlerService.handle(error);
              subscriber.error(error);
            }
          );
      } else {
        const error = this.translateService.instant('notyService.noActiveSignatureFound');
        this.notyService.error(error);
        subscriber.error(new Error(error));
      }
    });
  }

  triggerCancelSubscription() {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.changePlan')
      })
      .subscribe(() => {
        if (!this.isLoading) {
          this.isLoading = true;
          this.loaderService.show();
          this.cancelSubscription().subscribe();
        }
      });
  }

  setPlanInSale(allPlans?: CustomPlan[]) {
    this.planInSale = (allPlans || this.plans || []).find(plan => plan?.active && plan?.interval === (this.isYearly ? 12 : 1));
    this.featuredPlan = (allPlans || this.plans || []).find(plan => plan?.active && ['corporativo_carnaval', 'corporativo_blackfriday'].includes(plan?.id));
  }

  invoicesPublicUrl() {
    return this.currentUser && this.currentUser.organization ? location.origin + '/notas/' + this.currentUser.organization.uuid : '';
  }

  openEditPaymentInfoModal(step?: EditPaymentInfoModalStep) {
    this.editPaymentInfoModalService.open({ currentCustomer: this.currentCustomer, currentOrder: this.currentOrder, lockStep: step }).subscribe(data => {
      if (data?.currentCustomer) this.currentCustomer = data.currentCustomer;
      if (data?.payableWith && step === EditPaymentInfoModalStep.paymentMethod) {
        this.createOrUpdateOrder(null, data.payableWith, data?.cardToken, step);
        return;
      }

      this.notyService.success(this.translateService.instant('notyService.billingInfoUpdated'));
    });
  }

  copyInvoicesLink() {
    this.notyService.success(this.translateService.instant('notyService.invoiceLinkCopied'), 2000);
  }

  nfseUrl(invoice: Invoice, fileType?: 'pdf' | 'xml') {
    if (fileType === 'xml') {
      return invoice.url_nfse.replace(/\.pdf$/, '.xml');
    }
    return invoice.url_nfse;
  }

  invoiceUrl(invoice: Invoice) {
    const nfseUrl = this.nfseUrl(invoice);
    if (invoice.url_payment && !nfseUrl && !['canceled', 'disabled', 'expired', 'error', 'paid'].includes(invoice.status)) {
      return invoice.url_payment;
    }
    return '';
  }

  getPlansIntervalMap(interval: number) {
    return this.plansIntervalMap[interval] || this.plansIntervalMap[1];
  }

  additionalGroupsCount() {
    return this.organizationService.additionalGroupsCount(this.currentOrder);
  }

  additionalGroupsMonths() {
    return this.organizationService.additionalGroupsMonths(this.currentOrderPlan);
  }

  checkoutTotal() {
    return (
      ((this.currentOrder?.price || 0) / 100 || this.currentPlan?.amount / 100 || 0) + this.additionalGroupsCount() * this.additionalGroupsMonths() * this.organizationService.additionalGroupPrice
    );
  }

  private createOrUpdateOrder(plan?: CustomPlan, payableWith?: PaymentMethodsEnum, cardToken?: string, lockStep?: EditPaymentInfoModalStep) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loaderService.show();

      const isUpdate = this.currentOrder && !this.currentOrder?.suspended && (this.currentOrder?.latest_invoice?.status !== 'pending' || lockStep);
      const order$ = isUpdate
        ? this.paymentService.updateSubscription({
            ...(plan ? { plan: plan.id } : {}),
            ...((payableWith && payableWith !== this.currentOrder?.payment_method) || cardToken ? { payment_method: payableWith } : {}),
            ...(cardToken ? { card_token: cardToken } : {})
          })
        : this.paymentService.createSubscription({
            plan: plan.id,
            payment_method: payableWith,
            ...(cardToken ? { card_token: cardToken } : {})
          });

      const cancel$ = this.currentOrder?.latest_invoice?.status === 'pending' && !lockStep ? this.cancelSubscription(true) : of(null);

      cancel$
        .pipe(
          switchMap(() => order$),
          finalize(() => {
            this.isLoading = false;
            this.loaderService.hide();
          })
        )
        .subscribe(
          order => {
            this.processingPlan = plan;
            this.setCurrentOrder(order);
            this.loadInvoices();

            if (isUpdate) {
              this.notyService.success(this.translateService.instant('notyService.billingInfoUpdated'));
              return;
            }
            if (payableWith === PaymentMethodsEnum.CreditCard) {
              this.notyService.success(this.translateService.instant('notyService.orderRegisteredWaitingApproval'), 15000);
            } else {
              this.notyService.success(this.translateService.instant('notyService.orderRegisteredWaitingPayment'), 15000);
            }
          },
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  private setPromotions(currentPlan: CustomPlan, allPlans: CustomPlan[]) {
    const promotions = {};
    if (this.route.snapshot.queryParams.promo) {
      this.route.snapshot.queryParams.promo.split(',').forEach((promotion: string) => (promotions[promotion.split('-')[0]] = promotion.split('-')[1]));
    }

    if (!currentPlan || !promotions[currentPlan.id] || !promotions[currentPlan.stripe_id] || !promotions[currentPlan.iugu_id]) {
      for (const key in promotions) {
        const planBefore = allPlans.find(plan => plan.id === key || plan.stripe_id === key || plan.iugu_id === key);
        const planAfter = allPlans.find(plan => plan.id === promotions[key] || plan.stripe_id === promotions[key] || plan.iugu_id === promotions[key]);
        if (planBefore && planAfter) {
          planBefore.promo = planAfter;
          planAfter.active = true;
        }
      }
    }
  }

  private setCurrentOrder(order: Order) {
    this.currentOrder = order;
    this.currentOrderPlan = this.currentOrder ? this.plans.find(plan => plan.id === this.currentOrder?.plan_id) : this.currentPlan;
  }
}
