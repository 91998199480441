<div class="modal-body">
  <p class="centermodal__title">{{ 'documents.addSignatory' | translate }}</p>

  <div class="centermodal__form">
    <div class="d-flex">
      <app-signer-input
        [signer]="signer"
        [qualified]="document.qualified"
        [isCountryBR]="document.locale?.country === 'BR'"
        [validationErrors]="validationErrors?.signers"
        [class.has-error]="validationErrors.signer?.name || validationErrors.signer?.email"
        (changeSigner)="onSignerChange()"
      ></app-signer-input>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" (click)="create(signer)">
      {{ 'documents.addSignatory' | translate }}
      <span class="credits" *ngIf="appService.totalCreditPrice([signer]) > 0">
        <div class="credits__content">
          <i class="fal fa-shield-check"></i>
          -{{ appService.totalCreditPrice([signer]) }}
        </div>
      </span>
    </button>
  </div>
</div>
