import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalService } from '@app/core/modal.service';
import { ModalPublicProperties, ModalResult, AuthEmailConfirmationModalComponent as ModalComponent } from './auth-email-confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class AuthEmailConfirmationModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: ModalPublicProperties, options?: NgbModalOptions) {
    return this.modalService.open<ModalResult>(ModalComponent, properties, options);
  }
}
