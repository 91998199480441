import { Pipe, PipeTransform } from '@angular/core';

import { PhoneService } from '@app/services/phone.service';

/**
 * Formats phone number
 */
@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  constructor() {}

  transform(phone: string): string {
    let newPhone = PhoneService.getFullPhoneNumber(phone);
    if (('+' + newPhone).indexOf('+55') === 0) {
      newPhone = '+' + newPhone;
      const isMobile = newPhone.substring(5).length > 8;
      return newPhone.substring(0, 3) + ' (' + newPhone.substring(3, 5) + ') ' + newPhone.substring(5, isMobile ? 10 : 9) + '-' + newPhone.substring(isMobile ? 10 : 9);
    } else {
      return phone;
    }
  }
}
