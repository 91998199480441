<div class="group">
  <div class="group__title">{{ 'menu.currentOrganization' | translate }}</div>
  <div class="group__options">
    <div class="group__option group__option--without-arrow">
      <div class="group__option-icon"><i class="fal fa-users"></i></div>
      <div class="group__option-name">{{ currentUser?.organization.name }}</div>
    </div>
  </div>
</div>

<div class="group">
  <div class="group__title">{{ 'menu.changeOrganization' | translate }}</div>
  <div class="group__options">
    <a href="javascript:void(0)" class="group__option" [class.d-none]="org.id === currentUser?.organization?.id" *ngFor="let org of organizations" (click)="selectDefaultOrganization(org.id)">
      <div class="group__option-icon"><i class="fal fa-users"></i></div>
      <div class="group__option-name">{{ org.name }}</div>
    </a>
  </div>
</div>

<div class="group">
  <div class="group__options">
    <div class="group__option group__option--without-arrow" (click)="openCreateOrganizationModal()">
      <div class="group__option-icon"><i class="fal fa-plus"></i></div>
      <div class="group__option-name">{{ 'menu.createNewOrganization' | translate }}</div>
    </div>
  </div>
</div>
