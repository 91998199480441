import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-credits-modal',
  templateUrl: './alert-credits-limit-modal.component.html',
  styleUrls: ['./alert-credits-limit-modal.component.scss']
})
export class AlertCreditsLimitModalComponent {
  isLoading = false;

  constructor(public modal: NgbActiveModal, private router: Router) {}

  goToPayment(): void {
    this.router.navigate(['/perfil/planos']);
    this.modal.close();
  }
}
