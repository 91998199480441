import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ModalService } from '@app/core/modal.service';
import { User } from '@app/models';
import { UserService, ValidationService } from '@app/services';
import { ModalPublicProperties, ModalResult, SetMissingUserInfoModalComponent as ModalComponent, userNameValidations, userCpfValidations } from './set-missing-user-info-modal.component';

type BaseModalPublicProperties = ModalPublicProperties & { dismissAsError?: boolean };
type CustomModalPublicProperties = Pick<BaseModalPublicProperties, 'ignoreEmailAndPassword' | 'ignoreCpf' | 'forceCpfAs' | 'dismissAsError'>;

@Injectable({ providedIn: 'root' })
export class SetMissingUserInfoModalService {
  constructor(private modalService: ModalService, private userService: UserService, private validationService: ValidationService) {}

  open(properties?: BaseModalPublicProperties, options?: NgbModalOptions) {
    const dismissAsError = properties.dismissAsError;
    delete properties.dismissAsError;

    return this.modalService.open<ModalResult>(ModalComponent, properties, options, dismissAsError);
  }

  openIfMissingUserInfo(properties?: CustomModalPublicProperties, options?: NgbModalOptions): Observable<ModalResult> {
    return this.userService
      .getCurrentUser({ fetchPolicy: 'cache-first' })
      .pipe(switchMap(currentUser => (this.isMissingUserInfo(currentUser, properties || {}) ? this.open({ currentUser, ...(properties || {}) }, options) : of<ModalResult>(undefined))));
  }

  isMissingUserInfo(user: User, options: CustomModalPublicProperties = {}) {
    const isUserNameInvalid = !!this.validationService.validate(user.name, userNameValidations);
    const isUserCpfInvalid = !!this.validationService.validate(
      user.cpf,
      userCpfValidations.filter(val => !options.ignoreCpf || val !== 'required'),
      { appMatchCpf: [options.forceCpfAs] }
    );
    return user && (isUserNameInvalid || isUserCpfInvalid || !user.birthday || (options.ignoreEmailAndPassword ? false : !user.email || !user.email_verified_at));
  }
}
