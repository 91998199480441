import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appForbiddenNames]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenNamesDirective, multi: true }]
})
export class ForbiddenNamesDirective implements Validator {
  static validator(control: AbstractControl): ValidationErrors | null {
    return control.value && typeof control.value === 'string' && ForbiddenNamesDirective.isForbiddenName(control.value) ? { appForbiddenNames: true } : null;
  }

  static isForbiddenName(name: string) {
    const forbiddenNames = ['^termo ', '^termos '];
    return name
      .toLowerCase()
      .trim()
      .replace(/\s+/, ' ')
      .match(new RegExp(forbiddenNames.join('|'), 'i'));
  }

  constructor() {}

  validate(control: AbstractControl) {
    return ForbiddenNamesDirective.validator(control);
  }
}
