<ng-container *ngIf="currentOrder && currentUser?.currentPermissions.change_plan_oz">
  <ng-container *ngIf="!onlyShowExpired">
    <div class="payment-warning pending" *ngIf="!onlyShowExpired && hasPendingSubscriptionInvoice()">
      <div class="payment-warning__body">
        <div class="payment-warning__title">{{ 'shared.invoicePending' | translate }}</div>
        <div>{{ 'shared.payInvoice' | translate }}</div>
        <div *ngIf="subscriptionInvoiceExpirationDate()" class="payment-warning__extra">
          <strong>{{ 'shared.dueDate' | translate }} {{ subscriptionInvoiceExpirationDate().toISOString() | ldate }}</strong>
        </div>
      </div>
      <div class="payment-warning__links">
        <a [href]="currentOrder?.latest_invoice?.url_payment" class="autentique-button" target="_blank">{{ 'shared.accessInvoice' | translate }}</a>
      </div>
    </div>

    <div class="payment-warning expiring" *ngIf="hasExpiredSubscriptionInvoice()">
      <div class="payment-warning__body">
        <div class="payment-warning__title">{{ 'shared.invoiceOverdue' | translate }}</div>
        <div>{{ 'shared.invoicePaymentPending' | translate }}</div>
        <div class="payment-warning__extra">
          <strong>{{ 'shared.overdueDate' | translate }} {{ subscriptionInvoiceExpirationDate().toISOString() | ldate }}</strong>
        </div>
      </div>
      <div class="payment-warning__links">
        <a [href]="currentOrder?.latest_invoice?.url_payment" class="autentique-button" target="_blank">{{ 'shared.accessInvoice' | translate }}</a>
      </div>
    </div>
  </ng-container>

  <div class="payment-warning expired" *ngIf="isSubscriptionRenewalExpiring()">
    <div class="payment-warning__body">
      <div class="payment-warning__title">{{ 'shared.planExpiringSoon' | translate }}</div>
      <div>{{ 'shared.planNotRenewed' | translate }}</div>
      <div class="payment-warning__extra">
        <strong>{{ 'shared.expirationDate' | translate }} {{ this.currentUser?.subscription?.expires_at | ldate }}</strong>
      </div>
    </div>
    <div class="payment-warning__links" *ngIf="onlyShowExpired">
      <a [routerLink]="['/perfil/planos']" class="autentique-button">{{ 'shared.accessPlans' | translate }}</a>
    </div>
  </div>
</ng-container>
