import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from '@app/shared';
import { AuditRoutingModule } from './audit-routing.module';
import { AuditComponent } from './audit.component';
import { DocumentsModule } from '@app/pages/documents/documents.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AuditComponent],
  imports: [CommonModule, RouterModule, SharedModule, ReactiveFormsModule, AuditRoutingModule, ClipboardModule, DocumentsModule, TranslateModule]
})
export class AuditModule {}
