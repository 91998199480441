import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

import { ModalService } from '@app/core/modal.service';
import { ModalPublicProperties, ModalResult, EditSignatureModalComponent as ModalComponent } from './edit-signature-modal.component';

@Injectable({ providedIn: 'root' })
export class EditSignatureModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: ModalPublicProperties, options?: NgbModalOptions) {
    const newOptions = cloneDeep(options || {});
    newOptions.windowClass = (newOptions.windowClass || '')
      .split(/\s+/)
      .concat('sidemodal-right')
      .join(' ');
    return this.modalService.open<ModalResult>(ModalComponent, properties, newOptions);
  }
}
