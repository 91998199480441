<form class="modal-body" [formGroup]="form" (ngSubmit)="register()" novalidate>
  <p class="centermodal__title">{{ 'profile.personalData' | translate }}</p>
  <p class="centermodal__description">{{ 'documents.fillDataToSignDocument' | translate }}</p>

  <div class="centermodal__form">
    <div *ngIf="!isRegistered">
      <label class="field__holder" *ngIf="signer?.email">
        <input type="email" class="field" name="email" [value]="signer.email" disabled />
        <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
      </label>
      <label class="field__holder" [appFormControlWrap]="form.get('email')" *ngIf="!signer?.email">
        <input type="text" class="field" formControlName="email" name="email" autocomplete="email" required appValidEmail #emailInput />
        <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
      </label>

      <label class="field__holder" [appFormControlWrap]="form.get('name')">
        <input type="text" class="field" formControlName="name" name="name" autocomplete="name" required appValidName appFullName appForbiddenNames #nameInput />
        <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
      </label>

      <label class="field__holder" [appFormControlWrap]="form.get('cpf')" *ngIf="signer?.configs?.cpf || !ignoreCpf">
        <input type="tel" class="field" formControlName="cpf" name="cpf" autocomplete="cpf" mask="000.000.000-00" [appMatchCpf]="signer?.configs?.cpf" required />
        <span class="field__placeholder">{{ 'profile.cpf' | translate }}</span>
      </label>

      <label class="field__holder" [appFormControlWrap]="form.get('birthday')">
        <input type="tel" class="field" formControlName="birthday" name="birthday" autocomplete="birthday" mask="00/00/0000" [dropSpecialCharacters]="false" [specialCharacters]="['/']" required />
        <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
      </label>

      <label class="field__holder" [appFormControlWrap]="form.get('password')">
        <input type="password" class="field" formControlName="password" name="password" autocomplete="new-password" required minlength="8" appShowPassword data-sentry-mask />
        <span class="field__placeholder">{{ 'settings.setPassword' | translate }}</span>
      </label>

      <div class="row">
        <div class="col-lg-6" [appFormControlWrap]="form.get('country')">
          <app-select placeholder="{{ 'profile.country' | translate }}" formControlName="country" [options]="countries" [required]="true"></app-select>
        </div>
        <div class="col-lg-6" [appFormControlWrap]="form.get('language')">
          <app-select
            placeholder="{{ 'profile.language' | translate }}"
            formControlName="language"
            (click)="translateService.use(form.get('language').value)"
            [options]="languages"
            [disableSearch]="true"
          ></app-select>
        </div>
      </div>
    </div>

    <p class="text-center" *ngIf="isRegistered" [innerHTML]="'documents.registrationCompleted' | translate"></p>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading" *ngIf="!isRegistered">{{ 'button.continue' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading" *ngIf="isRegistered" (click)="openEmailConfirmationModal()">
      {{ 'button.continue' | translate }}
    </button>
  </div>
</form>
