<app-mobile-header-secondary title="{{ 'profile.title' | translate }}"></app-mobile-header-secondary>

<article class="configuration-item" *ngIf="currentUser">
  <div class="row mb-4">
    <div class="col-8">
      <div class="configuration-title mb-1">{{ 'profile.personalInfo' | translate }}</div>
      <div class="configuration-text">{{ 'settings.personalInfoData' | translate }}</div>
      <!-- <a href="javascript:void(0)" class="configuration-link"><i class="fal fa-question-circle"></i> Ajuda sobre essa tela</a> -->
    </div>
    <div class="col-4 text-right">
      <button type="button" class="configuration-button" (click)="openEditProfileModal()">{{ 'button.edit' | translate }}</button>
    </div>
  </div>

  <div class="configuration-subtitle">{{ 'profile.' + (currentUser.company || currentUser.cnpj ? 'companyData' : 'personalData') | translate }}</div>
  <div class="row">
    <div class="col-lg-6">
      <label class="field__holder" *ngIf="currentUser.company || currentUser.cnpj">
        <input type="text" class="field" [value]="currentUser.company" disabled />
        <span class="field__placeholder">{{ 'profile.businessName' | translate }}</span>
      </label>
      <label class="field__holder" *ngIf="currentUser.company || currentUser.cnpj">
        <input type="text" class="field" [value]="currentUser.cnpj" disabled />
        <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
      </label>

      <div class="configuration-subtitle mt-4" *ngIf="currentUser.company || currentUser.cnpj">{{ 'profile.personalData' | translate }}</div>
      <label class="field__holder">
        <input type="text" class="field" [value]="currentUser.name" disabled />
        <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
      </label>
      <label class="field__holder">
        <input type="text" class="field" [value]="currentUser.email" disabled />
        <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
      </label>
      <div class="row">
        <div class="col-md-6 pr-md-2">
          <label class="field__holder">
            <input type="text" class="field" [value]="currentUser.cpf" disabled />
            <span class="field__placeholder">{{ 'profile.cpf' | translate }}</span>
          </label>
        </div>
        <div class="col-md-6 pl-md-2">
          <label class="field__holder">
            <input type="text" class="field" [value]="dateService.normalizeDate(currentUser.birthday)" disabled />
            <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-lg-6 d-none d-lg-flex">
      <div class="configuration-preview-box">
        <p class="configuration-preview-text">{{ 'settings.howItAppearsInDocument' | translate }}</p>
        <app-signer-card [signer]="fakeSigner" [documentAuthorId]="currentUser.id" *ngIf="fakeSigner && currentUser"></app-signer-card>
      </div>
      <!--
      <div class="configuration-switches-item">
        <app-switch></app-switch>
        <span class="configuration-switches-name fw400 lighter">{{ 'settings.highlightCompanyName' | translate }}</span>
      </div>
      -->
    </div>
  </div>
</article>

<article class="configuration-item" *ngIf="currentUser">
  <div class="row mb-3">
    <div class="col-8 d-flex align-items-center align-items-lg-start">
      <div class="configuration-title">{{ 'settings.languageLocation' | translate }}</div>
    </div>
    <div class="col-4 text-right">
      <button type="button" class="configuration-button" (click)="openEditLocationModal()">{{ 'button.edit' | translate }}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-md-6 pr-md-2" *ngIf="currentUser?.locale?.country">
          <app-select placeholder="{{ 'profile.country' | translate }}" [(ngModel)]="currentUser.locale.country" [disabled]="true" [options]="countries"> </app-select>
        </div>
        <div class="col-md-6 pl-md-2" *ngIf="currentUser?.locale?.language">
          <app-select placeholder="{{ 'profile.language' | translate }}" [(ngModel)]="currentUser.locale.language" [disabled]="true" [options]="languages"> </app-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pr-md-2" *ngIf="currentUser?.locale?.timezone">
          <app-select placeholder="{{ 'profile.timezone' | translate }}" [(ngModel)]="currentUser.locale.timezone" [disabled]="true" [options]="timezones"> </app-select>
        </div>
        <div class="col-md-6 pl-md-2" *ngIf="currentUser?.locale?.date_format">
          <app-select placeholder="{{ 'profile.dateFormat' | translate }}" [(ngModel)]="currentUser.locale.date_format" [disabled]="true" [options]="dateFormat"> </app-select>
        </div>
      </div>
    </div>
  </div>
</article>

<article class="configuration-item" *ngIf="currentUser">
  <div class="row mb-3">
    <div class="col-8 d-flex align-items-center align-items-lg-start">
      <div class="configuration-title">{{ 'profile.accessData' | translate }}</div>
    </div>
    <div class="col-4 text-right">
      <button type="button" class="configuration-button" (click)="openEditPasswordModal()">{{ 'button.edit' | translate }}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <label class="field__holder">
        <input type="password" class="field" value="**********" disabled />
        <span class="field__placeholder">{{ 'auth.password' | translate }}</span>
      </label>
    </div>
  </div>
</article>

<article class="configuration-item" *ngIf="currentUser">
  <div class="row mb-3">
    <div class="col-8 d-flex align-items-center align-items-lg-start">
      <div class="configuration-title">{{ 'settings.signatureAppearance' | translate }}</div>
    </div>
    <div class="col-4 text-right d-none d-lg-block">
      <button type="button" class="btn btn-link text-danger" *ngIf="isEditingSignature" (click)="isEditingSignature = false">{{ 'button.cancel' | translate }}</button>
      <button type="button" class="configuration-button" (click)="updateSignature()" [disabled]="signatureForm.invalid || isLoading" *ngIf="isEditingSignature">{{ 'button.save' | translate }}</button>
      <button type="button" class="configuration-button" (click)="isEditingSignature = true" *ngIf="!isEditingSignature">{{ 'button.edit' | translate }}</button>
    </div>
    <div class="col-4 text-right d-block d-lg-none">
      <button type="button" class="configuration-button" (click)="openEditSignatureModal()">{{ 'button.edit' | translate }}</button>
    </div>
  </div>
  <div class="configuration-preview-signature d-block" [ngClass]="{ 'd-lg-none': isEditingSignature, 'd-lg-inline-flex': !isEditingSignature }">
    <app-signature-preview
      class="configuration-preview-signature__item"
      [user]="currentUser"
      [type]="PositionElementEnum.Signature"
      [font]="currentUser.settings.font"
      [format]="currentUser.settings.format"
      [imageUrl]="currentUser.settings.signature"
      [drawingUrl]="currentUser.settings.signature_draw"
    >
    </app-signature-preview>
    <app-signature-preview
      class="configuration-preview-signature__item"
      [user]="currentUser"
      [type]="PositionElementEnum.Initials"
      [format]="currentUser.settings.initials_format"
      [font]="currentUser.settings.font"
      [imageUrl]="currentUser.settings.initials"
      [drawingUrl]="currentUser.settings.initials_draw"
    >
    </app-signature-preview>
  </div>
  <form class="form d-none d-lg-block" [formGroup]="signatureForm" (ngSubmit)="updateSignature()" *ngIf="currentUser && isEditingSignature" novalidate>
    <ng-container formGroupName="user">
      <app-signature-pad
        [format]="signatureForm.get('user.format').value"
        [font]="signatureForm.get('user.font').value"
        [isLoading]="isLoading"
        (formatChange)="signatureForm.get('user.format').setValue($event)"
        *ngIf="isEditingSignature"
        #signaturePad
      >
      </app-signature-pad>
      <label class="field__holder select" *ngIf="currentUser && isEditingSignature && signatureForm.get('user.format').value === SignatureFormat.Handwriting">
        <select class="field" formControlName="font">
          <option [ngValue]="font.key" *ngFor="let font of fonts | keyvalue">{{ font.value | translate }}</option>
        </select>
        <p class="field__placeholder">{{ 'settings.font' | translate }}</p>
      </label>
    </ng-container>
  </form>
</article>
