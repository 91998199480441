<div class="container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12">
      <div class="text-center">
        <h3>{{ 'httpError.accountBlocked' | translate }}</h3>
        <div>{{ 'httpError.yourAccountHasBeenBlockedDueToFraudSuspicion' | translate }}</div>
        <div>{{ 'httpError.ifYouNeedMoreInformationContactUsAt' | translate }} <a href="mailto:contato@autentique.com.br">contato@autentique.com.br</a></div>
      </div>
      <div class="text-center mt-4" *ngIf="appService.isAutentiqueDomain()">
        <a href="/login" class="btn btn-lg btn-primary rounded-pill pl-5 pr-5">{{ 'httpError.homePage' | translate }}</a>
      </div>
    </div>
  </div>
</div>
