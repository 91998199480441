import { AfterContentInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, switchMap, takeWhile, tap } from 'rxjs/operators';
import { omit, cloneDeep } from 'lodash';

import { SlimOrganizationGroup, OrganizationMember, OrganizationMemberPermissions } from '@app/models';
import { ErrorHandlerService, NotyService, OrganizationService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

export enum FormType {
  Permissions,
  Group
}

export type ModalResult = OrganizationMember;
export interface ModalPublicProperties {
  type: FormType;
  member: OrganizationMember;
}

@Component({
  selector: 'app-edit-organization-member-modal',
  templateUrl: './edit-organization-member-modal.component.html',
  styleUrls: ['./edit-organization-member-modal.component.scss']
})
export class EditOrganizationMemberModalComponent implements ModalPublicProperties, AfterContentInit {
  form!: FormGroup;
  isLoading = false;
  groups: SlimOrganizationGroup[] = [];
  @Input() type: FormType;
  @Input() member: OrganizationMember;
  readonly FormType = FormType;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      member: this.formBuilder.group({
        email: null,
        group_id: null,
        permissions: this.formBuilder.group({
          create_documents: false,
          archive_documents: false,
          delete_documents: false,
          sign_documents: false,
          view_documents_gr: false,
          view_folders_gr: false,
          actions_documents_gr: false,
          actions_folders_gr: false,
          actions_templates_gr: false,
          view_documents_oz: false,
          view_folders_oz: false,
          view_member_documents_oz: false,
          view_member_folders_oz: false,
          view_group_documents_oz: false,
          view_group_folders_oz: false,
          view_invoices_oz: false,
          actions_documents_oz: false,
          actions_folders_oz: false,
          actions_members_oz: false,
          actions_groups_oz: false,
          actions_webhooks_oz: false,
          change_appearances_oz: false,
          change_plan_oz: false
          // change_whitelabel_oz: false // TO-DO: ACTIVATE WHEN WHITELABEL LAUNCHES
        } as OrganizationMemberPermissions)
      })
    });
  }

  ngAfterContentInit() {
    // TO-DO: REMOVE 'change_whitelabel_oz' FROM OMIT WHEN WHITELABEL LAUNCHES
    this.groups = [this.member.group];

    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(user => {
      this.form.setValue({
        member: {
          email: this.member.user.email || '',
          group_id: (this.member.group && this.member.group.id) || null,
          permissions: omit(cloneDeep(this.member.permissions), ['__typename', 'change_whitelabel_oz']) as OrganizationMemberPermissions
        }
      });

      if (this.type === FormType.Group) {
        this.organizationService.slimOrganizationGroups({ page: 1, limit: 60, organizationId: user.organization.id }, { fetchPolicy: 'cache-first' }).subscribe(page => {
          this.groups = page.data.concat(page.data.find(group => group.id === (this.member.group && this.member.group.id)) ? [] : [this.member.group]);
        });
      }
    });
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    if (this.type === FormType.Group && this.member.group.id !== this.form.get('member.group_id').value) {
      this.organizationService
        .canMemberBeRemovedFromGroup$(this.member)
        .pipe(
          tap(canBeRemoved => !canBeRemoved && this.notyService.error(this.translateService.instant('notyService.cannotRemoveLastAdmin'))),
          takeWhile(canBeRemoved => canBeRemoved),
          switchMap(() => this.organizationService.updateMember({ ...this.form.value, id: this.member.user.id })),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          data => this.modal.close(data as ModalResult),
          error => this.errorHandlerService.handleValidation(this.form, error)
        );
    } else if (this.type !== FormType.Group) {
      this.organizationService
        .updateMember({ ...this.form.value, id: this.member.user.id })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          data => this.modal.close(data as ModalResult),
          error => this.errorHandlerService.handleValidation(this.form, error)
        );
    } else {
      this.modal.close(this.member as ModalResult);
    }
  }
}
