import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '@app/services';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  isLoading = false;

  constructor(private router: Router, public appService: AppService) {
    this.appService.loadStatusPageWidget();
  }

  retry() {
    this.router.navigate(['/']);
  }
}
