import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { throwOnGraphqlError } from '@app/core';
import {
  EmailTemplatesGQL,
  EmailTemplatesQueryVariables,
  SlimEmailTemplatesGQL,
  SlimEmailTemplatesQueryVariables,
  CreateEmailTemplateGQL,
  CreateEmailTemplateMutationVariables,
  UpdateEmailTemplateGQL,
  UpdateEmailTemplateMutationVariables,
  DeleteEmailTemplateGQL,
  DeleteEmailTemplateMutationVariables,
  DefaultEmailTemplateGQL,
  DefaultEmailTemplateQueryVariables
} from 'src/generated/graphql.default';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService {
  constructor(
    private emailTemplatesGQL: EmailTemplatesGQL,
    private slimEmailTemplatesGQL: SlimEmailTemplatesGQL,
    private createEmailTemplateGQL: CreateEmailTemplateGQL,
    private updateEmailTemplateGQL: UpdateEmailTemplateGQL,
    private deleteEmailTemplateGQL: DeleteEmailTemplateGQL,
    private defaultEmailTemplateGQL: DefaultEmailTemplateGQL
  ) {}

  emailTemplates(variables: EmailTemplatesQueryVariables) {
    return this.emailTemplatesGQL.fetch(variables, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.emailTemplates)
    );
  }

  defaultEmailTemplates(variables: DefaultEmailTemplateQueryVariables) {
    return this.defaultEmailTemplateGQL.fetch(variables, { fetchPolicy: 'cache-first' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.defaultEmailTemplate)
    );
  }

  slimEmailTemplates(variables: SlimEmailTemplatesQueryVariables) {
    return this.slimEmailTemplatesGQL.fetch(variables, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.emailTemplates)
    );
  }

  create(variables: CreateEmailTemplateMutationVariables) {
    return this.createEmailTemplateGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createEmailTemplate)
    );
  }

  update(variables: UpdateEmailTemplateMutationVariables) {
    return this.updateEmailTemplateGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateEmailTemplate)
    );
  }

  delete(variables: DeleteEmailTemplateMutationVariables) {
    return this.deleteEmailTemplateGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteEmailTemplate)
    );
  }
}
