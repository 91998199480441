import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent {
  @Input() value: boolean;
  @Input() formControlName: string;
  @Input() name = '';
  @Input() disabled = false;
  @Input() disableInput = false;

  onChange: (_: boolean) => void;
  onTouch: () => void;

  constructor() {
    this.onChange = () => {};
    this.onTouch = () => {};
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.disableInput = isDisabled;
  }

  toggle() {
    this.value = !this.value;
  }
}
