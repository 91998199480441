<form class="modal-body" [formGroup]="form" (ngSubmit)="send()" novalidate>
  <p class="centermodal__title">{{ 'settings.passwordConfirmCurrent' | translate }}</p>

  <div class="centermodal__form">
    <label class="field__holder" [appFormControlWrap]="form.get('password')">
      <input type="password" class="field" formControlName="password" name="password" autocomplete="current-password" required appShowPassword data-sentry-mask #passwordInput />
      <span class="field__placeholder">{{ 'settings.passwordCurrent' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid">{{ 'button.continue' | translate }}</button>
  </div>
</form>
