<form class="modal-body" [formGroup]="form" (ngSubmit)="create()" novalidate>
  <p class="centermodal__title">{{ 'shared.newOrganization' | translate }}</p>
  <p class="centermodal__description" [innerHTML]="'shared.defineNewOrganization' | translate"></p>

  <div class="centermodal__form" formGroupName="organization">
    <label class="field__holder" [appFormControlWrap]="form.get('organization.name')">
      <input type="text" class="field" formControlName="name" name="name" autocomplete="none" role="presentation" required #nameInput />
      <span class="field__placeholder">{{ 'shared.name' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.create' | translate }}</button>
  </div>
</form>
