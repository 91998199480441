<app-mobile-header-secondary title="{{ 'settings.accessApi' | translate }}"></app-mobile-header-secondary>

<!--<div class="mobile mobile__page-in-construction">-->
<!--  <div class="mobile__message mobile__message&#45;&#45;red"><b>Disponível na versão desktop.</b></div>-->
<!--</div>-->

<div class="group">
  <div class="group__title">{{ 'settings.documentations' | translate }}</div>

  <div class="group__options">
    <a href="https://docs.autentique.com.br/" class="group__option" target="_blank">
      <div class="group__option-icon"><i class="fal fa-external-link"></i></div>
      <div class="group__option-name">{{ 'settings.documentationsApi' | translate }}</div>
    </a>
  </div>
</div>

<div class="group">
  <div class="group__title">{{ 'settings.tokensApi' | translate }}</div>

  <div class="group__options">
    <button type="button" class="group__option" (click)="createApiToken()" [disabled]="isLoading">
      <div class="group__option-icon"><i class="fal fa-external-link"></i></div>
      <div class="group__option-name">{{ 'settings.generateNewTokenApi' | translate }}</div>
    </button>
  </div>
</div>

<div class="group">
  <div class="group__options">
    <ng-container *ngFor="let token of apiTokens">
      <div class="group__option group__option--without-arrow" [ngxClipboard]="tokenInputElement" (click)="copyApiLink()">
        <div class="group__option-icon"><i class="fal fa-copy"></i></div>
        <div class="group__option-small group__option-small--alternative" style="overflow: hidden; text-overflow: ellipsis;">{{ token.access_token }}</div>
        <input type="text" class="d-none" [value]="token.access_token" readonly #tokenInputElement />
      </div>
    </ng-container>
  </div>
</div>

<div class="group">
  <div class="group__title">{{ 'settings.sandboxOptions' | translate }}</div>
  <div class="group__options">
    <label class="group__option group__option--without-arrow mb-0">
      <app-switch [(ngModel)]="appService.showSandbox" class="mr-2 ml-3"></app-switch>
      <div class="group__option-name">{{ 'settings.showAllSandboxDocuments' | translate }}</div>
    </label>
  </div>
</div>

<article class="configuration-item d-none d-lg-block">
  <div class="configuration-container">
    <div class="configuration-content">
      <p class="configuration-title ">{{ 'settings.tokensApi' | translate }}</p>
    </div>
    <div class="configuration-generate">
      <button type="button" class="configuration-button" (click)="createApiToken()" [disabled]="isLoading">{{ 'settings.generateNewToken' | translate }}</button>
    </div>
  </div>
  <a href="https://docs.autentique.com.br/" class="configuration-documentation" target="_blank">
    <i class="fal fa-external-link"></i>
    {{ 'settings.accessDocumentation' | translate }}
  </a>
  <div class="configuration-container mb-4">
    <div class="configuration-switches-item">
      <app-switch [(ngModel)]="appService.showSandbox"></app-switch>
      <span class="configuration-switches-name">{{ 'settings.showAllSandboxDocuments' | translate }}</span>
    </div>
  </div>
  <div class="configuration-container">
    <div class="configuration-content">
      <div class="configuration-sub_item" *ngFor="let token of apiTokens">
        <div class="configuration-info">
          <span class="configuration-info-date" title="{{ token.created_at | ldate: 'long' }}">{{ token.created_at | dateTime: 'mediumDate' }}</span>
          <span class="configuration-info-data" [innerHTML]="'settings.' + (token.env === 'production' ? 'useProductionSandbox' : 'useDeveloperSandbox') | translate"></span>
          <button type="button" class="btn btn-link configuration-info-rm" (click)="deleteApiToken(token.access_token)" [disabled]="isLoading">{{ 'settings.revokeToken' | translate }}</button>
        </div>
        <div class="configuration-value">
          <input type="text" class="configuration-value-show" [value]="token.access_token" readonly #tokenInputElement />
          <div class="configuration-value-copy" (click)="copyApiLink()" [ngxClipboard]="tokenInputElement"></div>
        </div>
      </div>
    </div>
  </div>
</article>
