import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { untilDestroyed } from '@app/core';
import { User } from '@app/models';
import { UserService, AuthenticationService, WhitelabelService } from '@app/services';

@Component({
  selector: 'app-mobile-header-main',
  templateUrl: './mobile-header-main.component.html',
  styleUrls: ['./mobile-header-main.component.scss']
})
export class MobileHeaderMainComponent implements OnInit, OnDestroy {
  currentUser: User;
  isOnFoldersListing: boolean;
  @Input() mainTitle: string;
  @Input() menulink: string;
  @Output() titleClicked = new EventEmitter<boolean>();

  constructor(public whitelabelService: WhitelabelService, private router: Router, private authenticationService: AuthenticationService, private userService: UserService) {
    this.isOnFoldersListing = !!location.pathname.match('^/pastas');
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnDestroy() {}

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true }));
  }

  toggleTitle() {
    this.titleClicked.emit(true);
  }
}
