import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/core';
import { CustomSignerInput, DeliveryMethodEnum, Document, DocumentInput, FloatingField, SecurityVerificationEnum, SignerInput, SignerMainAttributes, User } from '@app/models';
import { DocumentService, UserService } from '@app/services';
import { UniqueSignersPipe } from './unique-signers.pipe';

@Injectable({ providedIn: 'root' })
export class DocumentFormService implements OnDestroy {
  private currentUser: User;

  constructor(private documentService: DocumentService, private userService: UserService, private uniqueSigners: UniqueSignersPipe) {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnDestroy() {}

  transformToSignerInputs(document: DocumentInput | Document, customSigners: CustomSignerInput[], floatingFields: FloatingField[]): SignerInput[] {
    const customSignersClone = this.uniqueSigners.transform(cloneDeep(customSigners || []), document.qualified);
    customSignersClone.forEach(signer => {
      if (signer && signer._extra) {
        // se for qualificada remover validaçoes opcionais, aqui remove o CPF, no return remove o securityVerifications
        if (!signer._extra.showCpfVerification || document.qualified) {
          (signer.configs || {}).cpf = null;
        }
        if (signer._extra.showSmsVerification && [SignerMainAttributes.Email, SignerMainAttributes.Name].includes(signer._extra.signerMainAttribute)) {
          signer.security_verifications.push({
            type: SecurityVerificationEnum.Sms,
            verify_phone: signer._extra.phoneNumber ? signer._extra.phoneNumber.replace(/^[+]/, '') : null
          });
        }
        if (signer._extra.showSecurityVerifications && signer._extra.documentType) {
          signer.security_verifications.push({
            type: signer._extra.documentType as SecurityVerificationEnum
          });
        }
      }
      if (signer && signer._extra && signer._extra.signerMainAttribute && signer[signer._extra.signerMainAttribute]) {
        signer[signer._extra.signerMainAttribute] = signer[signer._extra.signerMainAttribute].trim();
      }
      const signerIdentification = this.uniqueSigners.identification(signer);
      signer.positions = (floatingFields || [])
        .filter(field => field.identification === signerIdentification)
        .map(field => ({ element: field.element, x: String(field.position.x), y: String(field.position.y), z: field.position.z, angle: field.position.angle }));

      if ((signer.positions || []).length === 0) {
        delete signer.positions;
      }

      if ([SignerMainAttributes.Sms, SignerMainAttributes.Whatsapp].includes(signer._extra.signerMainAttribute)) {
        delete signer.name;
        delete signer.email;

        signer.phone = signer?.sms || signer?.whatsapp;

        delete signer.sms;
        delete signer.whatsapp;
      }

      if ([SignerMainAttributes.Email].includes(signer._extra.signerMainAttribute)) signer.delivery_method = DeliveryMethodEnum.DeliveryMethodEmail;
      if ([SignerMainAttributes.Name].includes(signer._extra.signerMainAttribute)) signer.delivery_method = DeliveryMethodEnum.DeliveryMethodLink;
      if ([SignerMainAttributes.Sms].includes(signer._extra.signerMainAttribute)) signer.delivery_method = DeliveryMethodEnum.DeliveryMethodSms;
      if ([SignerMainAttributes.Whatsapp].includes(signer._extra.signerMainAttribute)) signer.delivery_method = DeliveryMethodEnum.DeliveryMethodWhatsapp;
      if (signer?.configs?.cpf && document.locale.country !== 'BR') delete signer.configs.cpf;
    });

    return customSignersClone.map(signer => {
      delete signer._extra;
      if (!signer.security_verifications || document.qualified) delete signer.security_verifications;
      return signer;
    });
  }
}
