<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" novalidate>
  <div class="centermodal__form">
    <ng-container *ngIf="currentStep === EditPaymentInfoModalStep.paymentMethod">
      <p class="centermodal__title">{{ 'settings.selectPaymentMethod' | translate }}</p>
      <p class="centermodal__description">{{ 'settings.selectPaymentMethod' | translate }}</p>

      <div class="payment__options">
        <a class="option text-decoration-none" (click)="setPayableWith(PaymentMethodsEnum.CreditCard)" [ngClass]="{ selected: payableWith === PaymentMethodsEnum.CreditCard }">
          <i class="fal fa-credit-card"></i>
          {{ 'settings.creditCard' | translate }}
        </a>
        <a class="option text-decoration-none" (click)="setPayableWith(PaymentMethodsEnum.Boleto)" [ngClass]="{ selected: payableWith === PaymentMethodsEnum.Boleto }">
          <i class="fal fa-barcode-alt"></i>
          {{ 'settings.bankSlip' | translate }}
        </a>
        <a class="option text-decoration-none" (click)="setPayableWith(PaymentMethodsEnum.Pix)" [ngClass]="{ selected: payableWith === PaymentMethodsEnum.Pix }">
          <img src="/assets/pix.svg" alt="" />
          {{ 'settings.pix' | translate }}
        </a>
      </div>

      <div class="alert-message" *ngIf="payableWith === PaymentMethodsEnum.Boleto">
        <i class="fal fa-info-circle"></i>
        <span>{{ 'settings.boletoPaymentProcessingTime' | translate }}</span>
      </div>
      <div *ngIf="payableWith === PaymentMethodsEnum.CreditCard">
        <div class="billingInfo__resume" *ngIf="defaultPaymentDataIsCreditCard">
          <p class="mb-0 number">{{ 'settings.last4Digits' | translate: { last4: currentOrder?.card_data?.last_digits } }}</p>
          <p class="mb-0">{{ (currentOrder?.card_data?.month < 10 ? '0' : '') + currentOrder?.card_data?.month + '/' + currentOrder?.card_data?.year }}</p>
          <span class="billingInfo__remove" (click)="defaultPaymentDataIsCreditCard = !defaultPaymentDataIsCreditCard">{{ 'settings.remove' | translate }}</span>
        </div>

        <ng-container *ngIf="!defaultPaymentDataIsCreditCard">
          <form class="row" ngxStripeCardGroup [elementsOptions]="elementsOptions">
            <div class="col-lg-6">
              <ngx-stripe-card-number [options]="cardNumberOptions"></ngx-stripe-card-number>
            </div>
            <div class="col-lg-3">
              <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
            </div>
            <div class="col-lg-3">
              <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
            </div>
          </form>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === EditPaymentInfoModalStep.paymentInfo || currentStep === EditPaymentInfoModalStep.paymentEmail">
      <p class="centermodal__title">{{ 'settings.fillBillingInformation' | translate }}</p>
      <p class="centermodal__description" [innerHTML]="'settings.billingInfoUsedForInvoicesAndReceipts' | translate"></p>
    </ng-container>

    <ng-container *ngIf="currentStep === EditPaymentInfoModalStep.paymentInfo">
      <app-switch-tabs
        name="type"
        [options]="[
          [false, translateService.instant('profile.naturalPerson')],
          [true, translateService.instant('profile.legalEntity')]
        ]"
        [(ngModel)]="isLegalPerson"
        [ngModelOptions]="{ standalone: true }"
      ></app-switch-tabs>

      <div class="centermodal__group-title">{{ 'profile.' + (isLegalPerson ? 'companyData' : 'personalData') | translate }}</div>

      <div formGroupName="customer">
        <div class="row">
          <div class="col-lg-7">
            <label class="field__holder" [appFormControlWrap]="form.get('customer.name')">
              <input type="text" class="field" formControlName="name" autocomplete="name" maxlength="70" required />
              <p class="field__placeholder">{{ 'profile.' + (isLegalPerson ? 'legalName' : 'fullName') | translate }}</p>
            </label>
          </div>
          <div class="col-lg-5" [class.d-none]="isLegalPerson">
            <label class="field__holder" [appFormControlWrap]="form.get('customer.cpf')">
              <input type="tel" class="field" formControlName="cpf" autocomplete="cpf" mask="000.000.000-00" [dropSpecialCharacters]="false" appValidCpf required="{{ !isLegalPerson }}" />
              <p class="field__placeholder">{{ 'profile.cpf' | translate }}</p>
            </label>
          </div>
          <div class="col-lg-5" [class.d-none]="!isLegalPerson">
            <label class="field__holder" [appFormControlWrap]="form.get('customer.cnpj')">
              <input type="tel" class="field" formControlName="cnpj" autocomplete="cnpj" mask="00.000.000/0000-00" [dropSpecialCharacters]="false" appValidCnpj required="{{ isLegalPerson }}" />
              <p class="field__placeholder">{{ 'profile.enterCnpj' | translate }}</p>
            </label>
          </div>
        </div>
      </div>

      <div class="centermodal__group-title">{{ 'settings.billingAddress' | translate }}</div>

      <div formGroupName="address">
        <div class="row">
          <div class="col-lg-5">
            <label class="field__holder" [appFormControlWrap]="form.get('address.zip_code')">
              <input type="text" class="field" formControlName="zip_code" (change)="fillAddressByZipCode(form.get('address.zip_code').value)" autocomplete="postal-code" mask="00000-000" required />
              <p class="field__placeholder">{{ 'profile.zipCode' | translate }}</p>
            </label>
          </div>
          <div class="col-lg-7">
            <label class="field__holder" [appFormControlWrap]="form.get('address.district')">
              <input type="text" class="field" formControlName="district" autocomplete="neighboorhood" required maxlength="50" />
              <p class="field__placeholder">{{ 'profile.neighborhood' | translate }}</p>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-9">
            <label class="field__holder" [appFormControlWrap]="form.get('address.street')">
              <input type="text" class="field" formControlName="street" autocomplete="address" required />
              <p class="field__placeholder">{{ 'profile.address' | translate }}</p>
            </label>
          </div>
          <div class="col-lg-3">
            <label class="field__holder" [appFormControlWrap]="form.get('address.number')">
              <input type="text" class="field" formControlName="number" autocomplete="address-number" />
              <p class="field__placeholder">{{ 'profile.number' | translate }}</p>
            </label>
          </div>
        </div>
        <!--         If you pay by bank slip, you must inform the city, state and country -->
        <div class="row" *ngIf="payableWith === PaymentMethodsEnum.Boleto">
          <div class="col-lg-6">
            <label class="field__holder" [appFormControlWrap]="form.get('address.city')">
              <input type="text" class="field" formControlName="city" autocomplete="city" required />
              <p class="field__placeholder">{{ 'profile.city' | translate }}</p>
            </label>
          </div>
          <div class="col-lg-3">
            <label class="field__holder" [appFormControlWrap]="form.get('address.state')" (hover)="toogleIso3166.toggle()" #toogleIso3166="ngbTooltip" [ngbTooltip]="tipIso3166">
              <input type="text" class="field" formControlName="state" autocomplete="address-state" maxlength="2" minlength="2" required="" />
              <p class="field__placeholder">{{ 'profile.state' | translate }}</p>
            </label>
          </div>
          <div class="col-lg-3">
            <label class="field__holder" [appFormControlWrap]="form.get('address.country')" (hover)="toogleIso3166.toggle()" #toogleIso3166="ngbTooltip" [ngbTooltip]="tipIso3166">
              <input type="text" class="field" formControlName="country" autocomplete="address-country" maxlength="2" minlength="2" required="" />
              <p class="field__placeholder">{{ 'profile.country' | translate }}</p>
            </label>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === EditPaymentInfoModalStep.paymentEmail || (!lockStep && currentStep === EditPaymentInfoModalStep.paymentInfo)">
      <div class="centermodal__group-title">{{ 'settings.billingEmailRecipient' | translate }}</div>
      <div formGroupName="customer">
        <div class="row">
          <div class="col-lg-12">
            <label class="field__holder" [appFormControlWrap]="form.get('customer.email')">
              <input type="email" class="field" formControlName="email" autocomplete="email" required appValidEmail />
              <p class="field__placeholder">{{ 'settings.billingEmail' | translate }}</p>
            </label>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === EditPaymentInfoModalStep.checkout">
      <p class="centermodal__title">{{ 'settings.reviewBillingInfo' | translate }}</p>
      <p class="centermodal__description">{{ 'settings.checkPlanAndAmounts' | translate }}</p>

      <div class="centermodal__group-title">{{ 'settings.invoice' | translate }}</div>
      <div class="checkout" *ngIf="plan || creditsPack">
        <div class="row">
          <div class="col-8">
            <div class="text">{{ plan ? 'Plano ' + plan.name : creditsPack?.description }}</div>
            <div class="checkout-subtitle" *ngIf="plan">
              {{ 'settings.' + ({ '6': 'billedSemiAnnually', '9': 'billedIn9Months', '12': 'billedAnnually' }[plan.interval] || 'billedMonthly') | translate }}
            </div>
          </div>
          <div class="col-4 text-right text">
            {{ (creditsPack ? (creditsPack.is_promo ? creditsPack.amount_promo : creditsPack.amount) * creditsPack.quantity : plan.amount) / 100 | lcurrency: 'R$' }}
          </div>
        </div>
        <div class="row" *ngIf="!creditsPack && additionalGroupsCount() > 0">
          <div class="col-8 checkout-item">
            <div>{{ 'settings.additionalGroups' | translate }}</div>
            <div class="checkout-subtitle">
              ({{ additionalGroupsCount() }} {{ 'settings.' + (additionalGroupsCount() !== 1 ? 'groups' : 'group') | translate }} &#215; {{ additionalGroupsMonths() }}
              {{ 'settings.' + (additionalGroupsMonths() !== 1 ? 'months' : 'month') | translate }} &#215; {{ organizationService.additionalGroupPrice | lcurrency: 'R$' }})
            </div>
          </div>
          <div class="col-4 text-right">{{ additionalGroupsCount() * additionalGroupsMonths() * organizationService.additionalGroupPrice | lcurrency: 'R$' }}</div>
        </div>
        <div class="row checkout-total">
          <div class="col-12 text-right">
            <span>{{ 'settings.totalToBePaid' | translate }}</span
            >&nbsp; <strong>{{ checkoutTotal() | lcurrency: 'R$' }}</strong>
          </div>
        </div>
      </div>

      <div class="warning-message" *ngIf="!!creditsPack">
        <div class="validation__checkbox">
          <input type="checkbox" class="checkbox__field" [checked]="canPurchaseCredits" #canPurchase />
          <label class="checkbox__label" role="checkbox" (click)="canPurchaseCredits = !canPurchaseCredits" [for]="canPurchase"></label>
        </div>
        <p class="checkbox__text" [innerHTML]="'settings.creditsConfirmCheckbox' | translate"></p>
      </div>
    </ng-container>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()" *ngIf="lockStep || currentStep === EditPaymentInfoModalStep.paymentMethod">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button" (click)="currentStep = EditPaymentInfoModalStep.paymentMethod" *ngIf="!lockStep && currentStep === EditPaymentInfoModalStep.paymentInfo">
      {{ 'button.back' | translate }}
    </button>
    <button type="button" class="centermodal__button" (click)="currentStep = EditPaymentInfoModalStep.paymentInfo" *ngIf="!lockStep && currentStep === EditPaymentInfoModalStep.checkout">
      {{ 'button.back' | translate }}
    </button>

    <button
      type="button"
      class="centermodal__button centermodal__button--custom-next"
      (click)="payableWith === PaymentMethodsEnum.CreditCard ? createStripeToken() : (currentStep = EditPaymentInfoModalStep.paymentInfo)"
      *ngIf="!lockStep && currentStep === EditPaymentInfoModalStep.paymentMethod"
    >
      {{ 'button.forward' | translate }}
    </button>

    <button
      type="button"
      class="centermodal__button centermodal__button--custom-next"
      (click)="currentStep = EditPaymentInfoModalStep.checkout"
      [disabled]="form.get('address').invalid || form.get('customer').invalid"
      *ngIf="!lockStep && currentStep === EditPaymentInfoModalStep.paymentInfo"
    >
      {{ 'button.forward' | translate }}
    </button>

    <ng-container *ngIf="creditsPack">
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="!canPurchaseCredits" *ngIf="lockStep || currentStep === EditPaymentInfoModalStep.checkout">
        {{ 'button.buy' | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="!creditsPack">
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" *ngIf="lockStep || currentStep === EditPaymentInfoModalStep.checkout">
        {{ 'button.' + (plan ? 'subscribe' : 'save') | translate }}
      </button>
    </ng-container>
  </div>
</form>

<ng-template #tipIso3166>
  <div class="tooltip__title">{{ 'settings.iso3166' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'settings.iso3166Desc' | translate }}
  </div>
</ng-template>
