<app-mobile-header-secondary title="{{ 'settings.accessApi' | translate }}"></app-mobile-header-secondary>

<div class="mobile mobile__page-in-construction">
  <div class="mobile__message mobile__message--red">
    <b>{{ 'external.availableForDesktop' | translate }}</b>
  </div>
</div>

<article class="configuration-item d-none d-lg-block">
  <div class="configuration-container">
    <div class="configuration-content">
      <div class="configuration-title mb-1">
        {{ 'settings.webhooksOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text">{{ 'settings.webhooksTriggeredWhenOrganizationDocumentSigned' | translate }}</div>
    </div>
    <div class="configuration-generate">
      <button type="button" class="btn btn-link text-danger" *ngIf="isEditingApiOptions" (click)="isEditingApiOptions = false">{{ 'button.cancel' | translate }}</button>
      <button type="button" class="configuration-button" (click)="updateApiOptions()" [disabled]="apiOptionsForm.invalid || isLoading" *ngIf="isEditingApiOptions">
        {{ 'button.save' | translate }}
      </button>
      <button type="button" class="configuration-button" (click)="isEditingApiOptions = true" *ngIf="currentUser?.currentPermissions.actions_webhooks_oz && !isEditingApiOptions">
        {{ 'button.edit' | translate }}
      </button>
    </div>
  </div>
  <div class="configuration-container wrap">
    <!-- <p class="configuration-container-title">URL de webhook</p> -->

    <div class="configuration-content">
      <div class="w-100" [class.d-none]="isEditingApiOptions">
        <div class="configuration-sub_item">
          <div class="configuration-info">
            <!-- <span class="configuration-info-data">API v2</span> -->
            <span class="configuration-info-data">{{ 'settings.urlSendWebhook' | translate }}</span>
          </div>
          <div class="configuration-value">
            <input type="text" class="configuration-value-show" [value]="currentUser?.organization.settings.webhook_url" readonly #webhookInputElement />
            <div class="configuration-value-copy" (click)="copyHookLink()" [ngxClipboard]="webhookInputElement"></div>
          </div>
        </div>
        <div class="configuration-sub_item">
          <div class="configuration-info">
            <span class="configuration-info-data">{{ 'settings.urlSendWebhookFormat' | translate }}</span>
          </div>
          <div class="configuration-value">
            <input type="text" class="configuration-value-show" readonly [value]="getFriendlyWebhookFormatName(webhookFormat)" />
            <div class="configuration-value-extend"></div>
          </div>
        </div>
        <!--
        <div class="configuration-sub_item">
          <div class="configuration-info">
            <span class="configuration-info-data">API v1 <span class="red">(deprecado)</span></span>
          </div>
          <div class="configuration-value">
            <div class="configuration-value-show">https://www.autentique.com.br/minhaurl</div>
            <div class="configuration-value-copy"></div>
          </div>
        </div>
        -->
      </div>

      <form class="form d-none" [formGroup]="apiOptionsForm" (ngSubmit)="updateApiOptions()" [class.d-none]="!currentUser || !isEditingApiOptions" *ngIf="apiOptionsForm" novalidate>
        <!-- Para o submit rodar no Enter -->
        <button type="submit" class="d-none" [disabled]="apiOptionsForm.invalid || isLoading"></button>
        <div formGroupName="options">
          <label class="field__holder full" [appFormControlWrap]="apiOptionsForm.get('options.webhook_url')">
            <input type="text" class="field" formControlName="webhook_url" autocomplete="none" role="presentation" autocorrect="off" spellcheck="false" autocapitalize="off" />
            <span class="field__placeholder">{{ 'settings.urlSendWebhook' | translate }}</span>
          </label>
          <app-select
            style="width: 330px"
            placeholder="{{ 'settings.urlSendWebhookFormat' | translate }}"
            [disableSearch]="true"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="webhookFormat"
            (ngModelChange)="apiOptionsForm.get('options.webhook_format').setValue($event)"
            [options]="[
              { key: webhookFormatEnum.Urlencoded, value: 'URL encoded', height: 46, fontSize: 16 },
              { key: webhookFormatEnum.Json, value: 'JSON', height: 46, fontSize: 16 }
            ]"
          >
          </app-select>
        </div>
      </form>
    </div>
  </div>

  <!--
  <div class="configuration-container wrap">
    <p class="configuration-container-title">Retorno do webhook</p>

    <div class="configuration-content">
      <div class="configuration-switches">
        <div class="configuration-switches-item">
          <app-switch></app-switch>
          <span class="configuration-switches-name">Data de assinatura</span>
        </div>

        <div class="configuration-switches-item">
          <app-switch></app-switch>
          <span class="configuration-switches-name">Status do documento</span>
        </div>

        <div class="configuration-switches-item">
          <app-switch></app-switch>
          <span class="configuration-switches-name">Email/telefone do signatário</span>
        </div>

        <div class="configuration-switches-item">
          <app-switch></app-switch>
          <span class="configuration-switches-name">Nome do signatário</span>
        </div>
      </div>
    </div>
    <div class="configuration-code">
      <pre><code>{{ '{' }}
<span class="red">"event"</span>: <span class="green">"signatures.signed"</span>,
<span class="red">"data":</span>{{ '{' }}
<span class="blue">"document_id"</span>: <span class="green">"c6c0c33187113464a16ba5f717adc3b6e485cb576a9a4805d"</span>,
<span class="blue">"user_id"</span>: <span class="green">"3db06506c18c8048bb56667d9001ac29b24b8250"</span>
{{ '}' }}
{{ '}' }}</code></pre>
    </div>
  </div>

  <div class="configuration-container wrap">
    <p class="configuration-container-title">Log de erros recebidos pelo webhook</p>

    <div class="configuration-content">
      <div class="configuration-lists">
        <div class="configuration-lists-line">
          <div class="date">
            12 set<br />
            às 14:21:52
          </div>
          <div class="data">
            Server error: `POST https://api.asdf.com.br/api/asdf` resulted in a `500 Internal Server Error`
          </div>
          <a routerLink class="resend">Reenviar</a>
        </div>
        <div class="configuration-lists-line">
          <div class="date">
            12 set<br />
            às 14:21:52
          </div>
          <div class="data">
            Server error: `POST https://api.asdf.com.br/api/asdf` resulted in a `500 Internal Server Error`
          </div>
          <a routerLink class="resend">Reenviar</a>
        </div>
        <div class="configuration-lists-line">
          <div class="date">
            12 set<br />
            às 14:21:52
          </div>
          <div class="data">
            Server error: `POST https://api.asdf.com.br/api/asdf` resulted in a `500 Internal Server Error`
          </div>
          <a routerLink class="resend">Reenviar</a>
        </div>
      </div>
    </div>
  </div>
  -->
</article>
