<app-mobile-header-secondary title="{{ 'menu.notification' | translate }}"></app-mobile-header-secondary>

<article class="configuration-item">
  <div class="configuration-container d-none d-lg-block">
    <div class="configuration-content">
      <p class="configuration-title">{{ 'menu.notification' | translate }}</p>
    </div>
  </div>
  <div class="configuration-container">
    <div class="configuration-content" *ngIf="notificationForm">
      <form class="form" [formGroup]="notificationForm" (ngSubmit)="updateNotificationsInfo()" novalidate>
        <div formGroupName="notification">
          <div class="configuration-switches-item">
            <app-switch formControlName="signature_others" (ngModelChange)="updateNotificationsInfo()" [disableInput]="isLoading"></app-switch>
            <span class="configuration-switches-name fw400">{{ 'settings.receiveNotificationEmailWhenDocumentSignedBySignatories' | translate }}</span>
          </div>
          <div class="configuration-switches-item">
            <app-switch formControlName="signature_you" (ngModelChange)="updateNotificationsInfo()" [disableInput]="isLoading"></app-switch>
            <span class="configuration-switches-name fw400">{{ 'settings.receiveNotificationEmailWhenYouSignTheDocument' | translate }}</span>
          </div>
          <!-- <div class="configuration-switches-item">
            <app-switch formControlName="signature_solicitation" (ngModelChange)="updateNotificationsInfo()" [disableInput]="isLoading"></app-switch>
            <span class="configuration-switches-name fw400">{{ 'settings.notificationEmailYouSignedTheDocument' | translate }}</span>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</article>
