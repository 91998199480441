<div class="hgroup">
  <div class="hgroup__options">
    <a class="hgroup__option" (click)="openCreateFolderModal()">
      <i class="hgroup__option-icon fa-regular fa-folder-plus"></i>
      <p class="hgroup__option-title">{{ 'menu.newFolder' | translate }}</p>
    </a>
  </div>
</div>
<div class="group">
  <div class="group__options">
    <a routerLink="/documentos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-file-alt"></i></div>
      <div class="group__option-name">{{ 'menu.myDocuments' | translate }}</div>
    </a>
    <a routerLink="/menu/configuracoes" class="group__option">
      <div class="group__option-icon"><i class="fal fa-cog"></i></div>
      <div class="group__option-name">{{ 'menu.settings' | translate }}</div>
    </a>
    <a routerLink="/documentos/lixeira" class="group__option">
      <div class="group__option-icon"><i class="fal fa-trash"></i></div>
      <div class="group__option-name">{{ 'menu.trash' | translate }}</div>
    </a>
  </div>
</div>

<div class="group">
  <div class="group__options">
    <app-menu-mobile-sign-out></app-menu-mobile-sign-out>
  </div>
</div>
