import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthenticationMethod } from '@app/models';
import { ErrorHandlerService, AuthenticationService, RegistrationContext, LoginContext, OauthService, DateService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@app/shared';
import { I18nService } from '@app/core';

@Component({
  selector: 'app-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['../auth.scss', './auth-register.component.scss']
})
export class AuthRegisterComponent implements OnInit {
  form!: FormGroup;
  isLoading = false;
  isCompany = false;
  oauthForm!: FormGroup;

  readonly isElectron = environment.electron;
  readonly AuthenticationMethod = AuthenticationMethod;
  readonly countries: SelectOption[] = [];
  readonly languages: SelectOption[] = [];

  constructor(
    public route: ActivatedRoute,
    public i18nService: I18nService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private oauthService: OauthService,
    private errorHandlerService: ErrorHandlerService,
    public translateService: TranslateService,
    private dateService: DateService
  ) {
    this.oauthForm = this.formBuilder.group({ email: this.route.snapshot.queryParams.email || '', password: '', referral: this.route.snapshot.queryParams.r } as LoginContext);
    this.form = this.formBuilder.group({
      email: this.route.snapshot.queryParams.email || '',
      name: '',
      // cpf: '',
      birthday: '',
      password: '',
      company: null,
      cnpj: null,
      referral: this.route.snapshot.queryParams.r || null,
      country: null,
      language: this.translateService.currentLang || null,
      timezone: this.i18nService.timezone || null
    } as RegistrationContext);

    this.languages = this.i18nService.supportedLanguagesAsSelectOptions || null;
    this.countries = this.i18nService.countriesWithCodes || null;
  }

  ngOnInit() {
    this.authenticationService.logout();
  }

  register() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.form.value.birthday = this.dateService.normalizeDate(this.form.value.birthday);

    this.authenticationService
      .register(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => (data.isEmailConfirmed ? this.router.navigate(['/']) : this.router.navigate(['/confirmacao'], { queryParams: { email: this.form.get('email').value } })),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }

  login(method: AuthenticationMethod = AuthenticationMethod.Email, additionalInfo?: any) {
    this.isLoading = true;
    if (method === AuthenticationMethod.Email) {
      this.oauthForm.markAllAsTouched();
    }

    this.oauthService.login(method, additionalInfo).subscribe(
      oauth => {
        this.authenticationService
          .login(oauth ? { code: oauth.accessToken, referral: this.oauthForm.get('referral').value } : this.oauthForm.value, method)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            () => this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true, queryParams: this.route.snapshot.queryParams }),
            error => {
              if (error && error.status === 424) {
                this.router.navigate(['/confirmacao'], { queryParams: { email: this.oauthForm.get('email').value } });
              } else {
                this.errorHandlerService.handleValidation(this.oauthForm, error);
              }
            }
          );
      },
      () => (this.isLoading = false)
    );
  }
}
