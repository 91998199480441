<div class="radio">
  <div class="group__radio-holder mx-auto" [ngStyle]="{ width: inputWidth }">
    <ng-container *ngFor="let option of options; let index = index">
      <input
        type="radio"
        class="group__radio-input"
        [id]="inputId + index"
        [name]="name"
        [value]="option[0]"
        [disabled]="disabled || disableInput"
        [checked]="value === option[0]"
        (change)="writeValue(option[0]); onChange(value)"
        #switchInput
      />
      <label [for]="inputId + index" class="group__radio-button" #switchLabel>
        <div class="group__radio-title">{{ option[1] }}</div>
      </label>
    </ng-container>
    <div class="group__radio-marker" style="display: none;" #marker></div>
  </div>
</div>
