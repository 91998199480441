import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, TemplateService } from '@app/services';
import { SlimTemplate } from '@app/models';

export type ModalResult = SlimTemplate;
export interface ModalPublicProperties {
  template: SlimTemplate;
}

@Component({
  selector: 'app-duplicate-template-modal',
  templateUrl: './duplicate-template-modal.component.html',
  styleUrls: ['./duplicate-template-modal.component.scss']
})
export class DuplicateTemplateModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() template: SlimTemplate;
  form!: FormGroup;
  isLoading = false;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private templateService: TemplateService, private errorHandlerService: ErrorHandlerService) {
    this.form = this.formBuilder.group({ template: this.formBuilder.group({ name: '' }) });
  }

  ngAfterContentInit() {
    this.form.get('template.name').setValue(this.template.name || '');
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  duplicate() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.templateService
      .duplicate(this.template.id, this.form.get('template.name').value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
