import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { ApiToken, User, WebhookFormatEnum } from '@app/models';
import { ApiTokenService, AppService, ErrorHandlerService, NotyService, UserService } from '@app/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-webhooks',
  templateUrl: './configurations-webhooks.component.html',
  styleUrls: ['../configurations.scss', './configurations-webhooks.component.scss']
})
export class ConfigurationsWebhooksComponent implements OnInit, OnDestroy {
  currentUser: User;
  apiTokens: ApiToken[] = [];
  apiOptionsForm: FormGroup;
  isLoading = false;
  isEditingApiOptions = false;
  webhookFormatEnum = WebhookFormatEnum;
  webhookFormat = WebhookFormatEnum.Urlencoded;

  constructor(
    public appService: AppService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private apiTokenService: ApiTokenService,
    private userService: UserService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.apiTokenService.all().subscribe(tokens => (this.apiTokens = tokens));

    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.apiOptionsForm = this.formBuilder.group({
          options: this.formBuilder.group({
            webhook_url: this.currentUser.organization.settings.webhook_url,
            webhook_format: this.currentUser.organization.settings.webhook_format
          })
        });
        this.webhookFormat = (this.currentUser.organization.settings.webhook_format as WebhookFormatEnum) || WebhookFormatEnum.Urlencoded;
      });
  }

  ngOnDestroy() {}

  updateApiOptions() {
    this.isLoading = true;
    this.apiOptionsForm.markAllAsTouched();

    this.userService
      .updateApiOptions(this.apiOptionsForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => (this.isEditingApiOptions = false),
        error => this.errorHandlerService.handleValidation(this.apiOptionsForm, error)
      );
  }

  copyHookLink(): void {
    this.notyService.success(this.translateService.instant('notyService.webhookLinkCopied'), 2000);
  }

  getFriendlyWebhookFormatName(type: WebhookFormatEnum): string {
    switch (type) {
      case WebhookFormatEnum.Json:
        return 'JSON';
      default:
        return 'URL encoded';
    }
  }
}
