import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterHasValue' })
export class FilterHasValuePipe implements PipeTransform {
  constructor() {}

  transform<T>(items: T[], filters: { [k: string]: boolean }) {
    return (items || []).filter(item => Object.keys(filters || {}).filter(key => !!item[key] !== filters[key]).length === 0);
  }
}
