<div class="modal-body">
  <p class="centermodal__title">{{ 'shared.certificateData' | translate }}</p>
  <p class="centermodal__description">
    {{ 'shared.' | translate }} <strong *ngIf="signer?.certificate">{{ signer.certificate.name }}</strong>
  </p>

  <div class="certificate" *ngIf="true">
    <div class="certificate-category">
      <div class="certificate-category__title">{{ 'shared.version' | translate }}</div>
      <div class="certificate-category__value">{{ 'shared.certificateVersionNumber' | translate }}</div>
    </div>
    <div class="certificate-category">
      <div class="certificate-category__title">{{ 'shared.certificateAlgorithm' | translate }}</div>
      <div class="certificate-category__value">{{ 'shared.certificateAlgorithmSHA256RSA' | translate }}</div>
    </div>
    <div class="certificate-category multiline">
      <div class="certificate-category__title">{{ 'shared.certificateSubject' | translate }}</div>
      <div class="certificate-category__value" [innerHTML]="'shared.certificateDetails' | translate"></div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
  </div>
</div>
