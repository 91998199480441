<div class="history__wrapper" *ngIf="!isLoading">
  <div ngbDropdown class="history__filter" placement="bottom-right" display="dynamic" size="200">
    <div ngbDropdownToggle class="history__filter-toggle" [class.history__filter-toggle--pressed]="false"><i class="fal fa-filter"></i>{{ 'documents.filterEvents' | translate }}</div>
    <div ngbDropdownMenu class="history__filter-container">
      <div class="history__filter-title">{{ 'documents.eventType' | translate }}</div>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('signed')">
        <input type="checkbox" class="checkbox__field" [checked]="activeFilters.type.signed" (change)="activeFilters.type.signed = !activeFilters.type.signed; refreshList()" />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.signature' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('viewed')">
        <input type="checkbox" class="checkbox__field" [checked]="activeFilters.type.viewed" (change)="activeFilters.type.viewed = !activeFilters.type.viewed; refreshList()" />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.view' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('rejected')">
        <input type="checkbox" class="checkbox__field" [checked]="activeFilters.type.rejected" (change)="activeFilters.type.rejected = !activeFilters.type.rejected; refreshList()" />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.refusal' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('signed_unapproved')">
        <input
          type="checkbox"
          class="checkbox__field"
          [checked]="activeFilters.type.signed_unapproved"
          (change)="activeFilters.type.signed_unapproved = !activeFilters.type.signed_unapproved; refreshList()"
        />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.unverified' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('biometric_rejected')">
        <input
          type="checkbox"
          class="checkbox__field"
          [checked]="activeFilters.type.biometric_rejected"
          (change)="activeFilters.type.biometric_rejected = !activeFilters.type.biometric_rejected; refreshList()"
        />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.disapproved' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('biometric_approved')">
        <input
          type="checkbox"
          class="checkbox__field"
          [checked]="activeFilters.type.biometric_approved"
          (change)="activeFilters.type.biometric_approved = !activeFilters.type.biometric_approved; refreshList()"
        />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.approved' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('email')">
        <input type="checkbox" class="checkbox__field" [checked]="activeFilters.type.email" (change)="activeFilters.type.email = !activeFilters.type.email; refreshList()" />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.emails' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('error')">
        <input type="checkbox" class="checkbox__field" [checked]="activeFilters.type.error" (change)="activeFilters.type.error = !activeFilters.type.error; refreshList()" />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.errors' | translate }}</div>
      </label>
      <label class="history__filter-option checkbox" *ngIf="doesItHaveHistoryFilter('other')">
        <input type="checkbox" class="checkbox__field" [checked]="activeFilters.type.other" (change)="activeFilters.type.other = !activeFilters.type.other; refreshList()" />
        <div class="checkbox__label"></div>
        <div class="checkbox__label-text">{{ 'documents.others' | translate }}</div>
      </label>

      <div class="history__filter-title">{{ 'documents.signatories' | translate }}</div>
      <ng-container *ngFor="let signer of signatures; let index = index">
        <label class="history__filter-option checkbox">
          <input
            type="checkbox"
            class="checkbox__field"
            [checked]="activeFilters.signer[signer.public_id]"
            (change)="activeFilters.signer[signer.public_id] = !activeFilters.signer[signer.public_id]; refreshList()"
          />
          <div class="checkbox__label"></div>
          <div class="checkbox__label-text">{{ signer.user?.name || signer.user?.email || signer?.name || signer.email || signer.user?.phone }}</div>
        </label>
      </ng-container>
    </div>
  </div>
  <ng-container *ngFor="let item of history; let index = index">
    <div
      *ngIf="activeFilters.signer[item.signer.public_id] && activeFilters.type[item.filter.type]"
      class="history__event"
      [class.first]="item.filter.first === true"
      [attr.data-date]="item.date | ldate: 'mediumDate'"
    >
      <div class="history__event-container">
        <div
          class="history__event-icon"
          [ngClass]="{
            'history__event-icon--green': item.data.color === 'green',
            'history__event-icon--blue': item.data.color === 'blue',
            'history__event-icon--red': item.data.color === 'red',
            'history__event-icon--yellow': item.data.color === 'yellow'
          }"
        >
          <i class="fal fa-{{ item.data.icon }}"></i>
        </div>
        <div class="history__event-data">
          <div
            class="history__event-title"
            [ngClass]="{
              'history__event-title--green': item.data.color === 'green',
              'history__event-title--blue': item.data.color === 'blue',
              'history__event-title--red': item.data.color === 'red',
              'history__event-title--yellow': item.data.color === 'yellow'
            }"
          >
            {{ item.data.title }}
          </div>
          <div class="history__event-id">
            <ng-container *ngIf="item.signer.name.length > 0 && item.signer.address.length > 0"> <i class="fal fa-user"></i> {{ item.signer.name }} ({{ item.signer.address }}) </ng-container>
            <ng-container *ngIf="item.signer.name.length > 0 && item.signer.address.length === 0"> <i class="fal fa-user"></i> {{ item.signer.name }} </ng-container>
            <ng-container *ngIf="item.signer.name.length === 0 && item.signer.address.length > 0"> <i class="fal fa-user"></i> {{ item.signer.address }} </ng-container>
            <ng-container *ngIf="item.signer.name.length === 0 && item.signer.address.length === 0"> <i class="fal fa-robot"></i> {{ 'documents.authenticate' | translate }}</ng-container>
          </div>
        </div>
      </div>
      <div class="history__event-description" [innerHTML]="item.data.description"></div>
      <div class="history__event-date" title="{{ item.date | ldate: 'long' }}">{{ item.date | ldate: 'shortTime' }}</div>
    </div>
  </ng-container>
</div>

<div class="history__wrapper" *ngIf="isLoading">
  <div class="history__event" [class.first]="true" [attr.data-date]="translateService.instant('documents.searchingHistory')">
    <div class="history__event-container">
      <div class="history__event-icon history__event-icon--green rendering"></div>
      <div class="history__event-data">
        <div class="history__event-title history__event-title--green rendering" style="width: 39%; height: 14px; margin: 4px 0 5px;border-radius: 3px;">&emsp;&emsp;</div>
        <div class="history__event-id rendering" style="width: 87%;height: 12px; margin: 4px 0 8px;border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div>
      </div>
    </div>
    <div class="history__event-description rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;</div>
    <div class="history__event-date rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;</div>
  </div>

  <div class="history__event">
    <div class="history__event-container">
      <div class="history__event-icon history__event-icon--green rendering"></div>
      <div class="history__event-data">
        <div class="history__event-title history__event-title--green rendering" style="width: 39%; height: 14px; margin: 4px 0 5px;border-radius: 3px;">&emsp;&emsp;</div>
        <div class="history__event-id rendering" style="width: 87%;height: 12px; margin: 4px 0 8px;border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div>
      </div>
    </div>
    <div class="history__event-description rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;</div>
    <div class="history__event-date rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;</div>
  </div>

  <div class="history__event">
    <div class="history__event-container">
      <div class="history__event-icon history__event-icon--green rendering"></div>
      <div class="history__event-data">
        <div class="history__event-title history__event-title--green rendering" style="width: 39%; height: 14px; margin: 4px 0 5px;border-radius: 3px;">&emsp;&emsp;</div>
        <div class="history__event-id rendering" style="width: 87%;height: 12px; margin: 4px 0 8px;border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div>
      </div>
    </div>
    <div class="history__event-description rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;</div>
    <div class="history__event-date rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;</div>
  </div>

  <div class="history__event" style="visibility: hidden">
    <div class="history__event-container">
      <div class="history__event-icon history__event-icon--green rendering"></div>
      <div class="history__event-data">
        <div class="history__event-title history__event-title--green rendering" style="width: 39%; height: 14px; margin: 4px 0 5px;border-radius: 3px;">
          {{ 'documents.loadingHistory' | translate }}
        </div>
        <div class="history__event-id rendering" style="width: 87%;height: 12px; margin: 4px 0 8px;border-radius: 3px;">
          {{ 'documents.loremIpsum' | translate }}
        </div>
      </div>
    </div>
    <div class="history__event-description rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;&emsp;</div>
    <div class="history__event-date rendering" style="border-radius: 3px;">&emsp;&emsp;&emsp;&emsp;</div>
  </div>
</div>
