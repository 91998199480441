<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" novalidate>
  <div class="centermodal__title" *ngIf="type === FormType.Permissions">{{ 'settings.setPermissions' | translate }}</div>
  <div class="centermodal__title" *ngIf="type === FormType.Group">{{ 'settings.selectUserGroup' | translate }}</div>

  <div class="centermodal__tag-list">
    <div class="centermodal__tag-title">{{ 'settings.for' | translate }}</div>
    <div class="centermodal__tag">{{ member.user?.email }}</div>
  </div>

  <ng-container formGroupName="member">
    <app-organization-permissions-form [form]="$any(form.get('member.permissions'))" *ngIf="type === FormType.Permissions"></app-organization-permissions-form>

    <div class="centermodal__group" *ngIf="type === FormType.Group">
      <label class="field__holder select" [appFormControlWrap]="form.get('member.group_id')">
        <select class="field" formControlName="group_id" required>
          <option [ngValue]="group.id" *ngFor="let group of groups">{{ group.name }}</option>
        </select>
        <span class="field__placeholder">{{ 'settings.group' | translate }}</span>
      </label>
    </div>
  </ng-container>

  <div class="centermodal__button-group mt-3">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.save' | translate }}</button>
  </div>
</form>
