import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document-loader',
  templateUrl: './document-loader.component.html',
  styleUrls: ['./document-loader.component.scss']
})
export class DocumentLoaderComponent implements OnInit {
  @Input() message = this.translateService.instant('shared.processingPagesPleaseWait');

  constructor(private translateService: TranslateService) {}

  ngOnInit() {}
}
