<div class="modal-body">
  <p class="centermodal__title" *ngIf="!waitingBiometry">{{ 'signature.signDone.' + (currentSigner.action.name | lowercase) | translate }}</p>
  <p class="centermodal__title" *ngIf="waitingBiometry">{{ 'documents.waitVerification' | translate }}</p>
  <app-lottie name="document-success" height="200"></app-lottie>
  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <p class="centermodal__message" *ngIf="!waitingBiometry">
      <span>{{ 'documents.successfulElectronicSignature' | translate }}</span
      ><br />
      <span [innerHTML]="'documents.downloadSignedDocument' | translate"></span>
    </p>
    <p class="centermodal__message" *ngIf="waitingBiometry">
      {{ 'documents.successfulSignatureNotification' | translate }}
    </p>
  </div>
  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" (click)="openSignedDocument()" *ngIf="!waitingBiometry">
      {{ 'button.download' | translate }}
    </button>
  </div>
</div>
