<div class="modal-body">
  <p class="centermodal__title">{{ 'shared.confirmRestoreDocument' | translate }}</p>
  <div class="centermodal__description">
    <p>
      {{ 'shared.restoreDocumentWarning' | translate }}
      <strong>{{ calculateNextLifecycle(document?.lifecycle_in) | ldate }}</strong>
    </p>
    <p>
      <span class="title">{{ 'shared.changesToDocument' | translate }}</span
      ><br />
      {{ 'shared.accessToDocument' | translate }}
    </p>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" (click)="restore()">
      {{ 'button.restore' | translate }}
      <span class="credits-badge" *ngIf="creditAmount && creditAmount > 0"><i class="fal fa-shield-check"></i> {{ creditAmount }}</span>
    </button>
  </div>
</div>
