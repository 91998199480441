import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

let firstVersion: string;
const versionChangeSubject = new BehaviorSubject<string>(null);
export const versionChange$ = versionChangeSubject.pipe(filter(value => !!value && value !== firstVersion));

/**
 * Asks user to refresh the application to apply new changes detected through the API header X-Front-Version
 */
@Injectable({
  providedIn: 'root'
})
export class VersionUpdateInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((data: any) => {
        const version = data && data.headers && data.headers.getAll && (data.headers.getAll('x-front-version') || [])[0];
        const lastVersion = versionChangeSubject.getValue();
        if (version && lastVersion && version !== lastVersion) {
          versionChangeSubject.next(version);
        } else if (version && !lastVersion) {
          firstVersion = version;
          versionChangeSubject.next(version);
        }
      })
    );
  }
}
