import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared';
import { HttpErrorsRoutingModule } from './http-errors-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { BannedComponent } from './banned/banned.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NotFoundComponent, BannedComponent, MaintenanceComponent],
  imports: [CommonModule, RouterModule, SharedModule, HttpErrorsRoutingModule, TranslateModule]
})
export class HttpErrorsModule {}
