import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AppService, ErrorHandlerService, UserService } from '@app/services';

export type ModalResult = { userExists: boolean; email: string };
export interface ModalPublicProperties {
  nextWhen?: 'exists' | 'not_exists' | false;
}

@Component({
  selector: 'app-user-exists-modal',
  templateUrl: './user-exists-modal.component.html',
  styleUrls: ['./user-exists-modal.component.scss']
})
export class UserExistsModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() nextWhen: ModalPublicProperties['nextWhen'];
  form!: FormGroup;
  isLoading = false;
  @ViewChild('emailInput', { static: false }) private emailInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private appService: AppService, private userService: UserService, private errorHandlerService: ErrorHandlerService) {
    this.form = this.formBuilder.group({ email: '' });
  }

  ngAfterContentInit() {
    setTimeout(() => this.emailInput.nativeElement.focus());
  }

  search() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.userService
      .userExists({ email: this.form.get('email').value })
      .pipe(
        switchMap(userExists => {
          if (this.nextWhen === 'exists' && !userExists) {
            return throwError(this.appService.mockFormValidationError({ email: ['not_created_user'] }));
          } else if (this.nextWhen === 'not_exists' && userExists) {
            return throwError(this.appService.mockFormValidationError({ email: ['already_registered'] }));
          }
          return of(userExists);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        userExists => this.modal.close({ userExists, email: this.form.get('email').value } as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
