<div class="modal-body">
  <p class="centermodal__title">{{ 'documents.biometricVerification' | translate }}</p>

  <div class="centermodal__form" *ngIf="signerVerification">
    <div class="centermodal__text-wrapper">
      <p class="centermodal__text">
        {{ 'documents.verificationDate' | translate }}
        <strong title="{{ signerVerification.verified_at || signer.signed_unapproved?.created_at | ldate: 'long' }}">
          {{ signerVerification.verified_at || signer.signed_unapproved?.created_at | dateTime: 'mediumDate' }}
        </strong>
      </p>
      <p class="centermodal__text" *ngIf="signerVerification.user.confidence">
        {{ 'documents.similarityRate' | translate }} <strong class="{{ signerVerification.verified_at ? 'text-bg__success' : 'text-bg__warning' }}">{{ signerVerification.user.confidence }}%</strong>
      </p>

      <div class="biometry-warning" *ngIf="canApproveManually && signerVerification?.type !== SecurityVerificationEnum.Manual">
        <i class="fal fa-exclamation-circle"></i>
        <div class="biometry-warning__text">
          {{ 'documents.lowSimilarityRateMessage' | translate }}
        </div>
      </div>
    </div>

    <div class="img__container">
      <div class="img__list" *ngIf="docsImages?.length > 0">
        <div class="img__list-label">{{ 'documents.signatoryDocument' | translate }}</div>
        <a [href]="image" target="_blank" *ngFor="let image of docsImages">
          <img class="img__list-item" [src]="image" />
        </a>
      </div>
      <div class="img__list" *ngIf="faceImages?.length > 0">
        <div class="img__list-label">{{ 'documents.signatoryPhoto' | translate }}</div>
        <a [href]="image" target="_blank" *ngFor="let image of faceImages">
          <img class="img__list-item" [src]="image" />
        </a>
      </div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <ng-container *ngIf="canApproveManually">
      <button type="button" class="centermodal__button centermodal__button--custom-danger" (click)="submit(ManualBiometryApprovalEnum.Rejected)">{{ 'button.reject' | translate }}</button>
      <button type="button" class="centermodal__button centermodal__button--custom-next ml-2" (click)="submit(ManualBiometryApprovalEnum.Accepted)">{{ 'button.approve' | translate }}</button>
    </ng-container>
  </div>
</div>
