<div class="modal-body">
  <p class="centermodal__title mb-3">{{ 'documents.queryRegistrationRF' | translate }}</p>

  <ng-container *ngIf="!isInvalid">
    <div class="cpfcard">
      <img src="assets/brbras.svg" alt="" class="cpfcard__brand" />
      <div class="cpfcard__min">
        <div class="cpfcard__min-brand"><img src="assets/min-fazenda.svg" width="61.58" alt="Ministério da fazenda" /></div>
        <div class="cpfcard__min-text">
          <div class="mb-05">{{ 'documents.ministryOfFinance' | translate }}</div>
          <div>{{ 'documents.revenueDepartment' | translate }}</div>
        </div>
      </div>
      <div class="cpfcard__data">
        <div class="cpfcard__data-title">{{ 'documents.individualRegistration' | translate }}</div>
        <div class="cpfcard__data-label">{{ 'documents.registrationNumber' | translate }}</div>
        <div class="cpfcard__data-number">{{ signer.user.cpf }}</div>

        <div class="cpfcard__data-label">{{ 'profile.name' | translate }}</div>
        <div class="cpfcard__data-info cpfcard__data-info--name">{{ dataSourceName }}</div>

        <div class="cpfcard__data-label">{{ 'profile.birth' | translate }}</div>
        <div class="cpfcard__data-info">{{ dataSourceDate }}</div>
      </div>

      <div class="cpfcard__loader" [class.d-none]="!isLoading || (isLoading && isFinished)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display:block" width="70px" height="70px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
        </svg>
      </div>
    </div>
    <div class="cpfForm centermodal__form">
      <label class="field__holder" [class.error]="isNameInvalid">
        <input type="text" class="field" disabled [value]="dataSourceName" />
        <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
      </label>
      <div class="cpfForm__label-holder">
        <label class="field__holder cpfForm__label--size-half">
          <input type="text" class="field" disabled [value]="signer.user.cpf" />
          <span class="field__placeholder">{{ 'profile.cpf' | translate }}</span>
        </label>
        <label class="field__holder cpfForm__label--size-half" [class.error]="isDateInvalid">
          <input type="text" class="field" disabled [value]="dataSourceDate" />
          <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
        </label>
      </div>
    </div>
    <div class="cpfcard__consult" [class.d-none]="isLoading">{{ 'documents.queryVoucherCode' | translate: { code: dataSourceVoucher } }}</div>
    <div class="cpfcard__consult" [class.d-none]="!isLoading">&nbsp;</div>
  </ng-container>
  <ng-container *ngIf="isInvalid && isFinished">
    <div class="cpfcard__message">
      <div class="cpfcard__message-icon"></div>
      <!--
      <div class="cpfcard__message-container">
        <div class="cpfcard__message-title">CPF ou data de nascimento inválido</div>
        <div class="cpfcard__message-descr">
          Não foi possível verificar os dados pois o CPF <b>{{ signer.user.cpf }}</b> ou data de nascimento <b>{{ signer.user.birthday }}</b> informadas pelo signatário não correspondem aos dados do
          cadastro na receita federal
        </div>
      </div>
      -->
      <!-- Mensagem temporária até que o hubdev volte a funcionar -->
      <div class="cpfcard__message-container">
        <div class="cpfcard__message-title">{{ 'documents.unableToQueryCPF' | translate }}</div>
        <div class="cpfcard__message-descr">
          {{ 'documents.unableToVerifySignatoryData' | translate }}
        </div>
      </div>
    </div>
  </ng-container>

  <p class="centermodal__title text-left mb-3">{{ 'documents.userInfo' | translate }}</p>
  <div class="centermodal__form">
    <label class="field__holder" [class.error]="isNameInvalid">
      <input type="text" class="field" disabled [value]="signer.user.name" />
      <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
    </label>
    <div class="cpfForm__label-holder">
      <label class="field__holder cpfForm__label--size-half">
        <input type="text" class="field" disabled [value]="signer.user.cpf" />
        <span class="field__placeholder">{{ 'profile.cpf' | translate }}</span>
      </label>
      <label class="field__holder cpfForm__label--size-half" [class.error]="isDateInvalid">
        <input type="text" class="field" disabled [value]="signer.user.birthday" />
        <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
      </label>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isInvalid || isLoading || isOverWrited || (!isDateInvalid && !isNameInvalid)" (click)="update()">
      {{ 'documents.useCPFData' | translate }}
    </button>
  </div>
</div>
