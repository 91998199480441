<div class="holder">
  <div class="corner"></div>
  <div class="content">
    <div class="content-img">
      <img [src]="selectedPdfLogoMiniImageUrl" alt="" />
    </div>
    <div class="content-stuff">
      <div class="content-stuff-1 blured"></div>
      <div class="content-stuff-2 blured"></div>
      <div class="content-stuff-3 blured"></div>
      <div class="content-stuff-4 blured"></div>
    </div>
  </div>
</div>
