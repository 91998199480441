<!-- MOBILE: -->
<app-mobile-header-secondary title="{{ 'menu.organization' | translate }}">
  <!--  <a href="javascript:void(0)" class="mobile-header__option" (click)="(null)">Adicionar</a>-->
</app-mobile-header-secondary>

<!--
<div class="group" *ngIf="currentUser">
  <div class="group__title">Membros da organização</div>
  <div class="group__options" *ngIf="currentUser && currentUser.organization.members.length > 0">
    <label
      class="group__option with-radio-action"
      *ngFor="let member of currentUser.organization.members"
      [class.group__option-first]="member.role.name == OrganizationRoles.Owner || currentUser.organization.role.name == OrganizationRoles.User"
      [class.group__option--custom-button]="member.role.name == OrganizationRoles.Owner || currentUser.organization.role.name == OrganizationRoles.User"
    >
      <input class="radio-action" type="radio" name="member-action" value="{{ member.name }}" />
      <div class="group__option-name">{{ member.name }}</div>
      <div *ngIf="member.role.name !== OrganizationRoles.Owner && currentUser.organization.role.name !== OrganizationRoles.User" class="action" (click)="deleteMember(member.id)">Desvincular</div>
    </label>
  </div>
</div>
-->

<!-- DESKTOP: -->

<article class="configuration-item">
  <div class="row mb-4">
    <div class="col-8">
      <div class="configuration-title mb-1">
        {{ 'settings.membersOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text">{{ 'settings.orgMembersSharePlanAndAdminPermissions' | translate }}</div>
      <!-- <a href="javascript:void(0)" class="configuration-link"><i class="fal fa-question-circle"></i> Ajuda sobre essa tela</a> -->
    </div>
    <div class="col-4 d-flex justify-content-end">
      <button type="button" class="autentique-button" (click)="openInviteMembersModal()" *ngIf="currentUser?.currentPermissions.actions_members_oz">{{ 'settings.addUser' | translate }}</button>
    </div>
  </div>
  <!--
  <div class="configuration-generate" *ngIf="currentUser && currentUser.organization.members.length > 0">
    <button type="button" class="btn btn-link text-danger" (click)="deleteMember(currentUser.id)" *ngIf="currentUser.organization.role.name !== OrganizationRoles.Owner">
      Sair da organização
    </button>
    <button type="button" class="configuration-button" (click)="openInviteMembersModal()" *ngIf="currentUser.organization.role.name !== OrganizationRoles.User">
      Adicionar usuário
    </button>
  </div>
  -->

  <div class="row mb-4">
    <div class="col-lg-8">
      <div class="d-inline-block">
        <app-switch-tabs
          [options]="[
            [false, translateService.instant('settings.accept')],
            [true, translateService.instant('settings.pending')]
          ]"
          [(ngModel)]="isShowingInvitations"
          [ngModelOptions]="{ standalone: true }"
        ></app-switch-tabs>
      </div>
    </div>
    <div class="col-lg-4 d-flex justify-content-end">
      <span class="configuration-filter-text">{{ 'settings.filterBy' | translate }}</span>
      <div class="configuration-filter" ngbDropdown>
        <div class="configuration-filter-toggle" ngbDropdownToggle>
          {{ selectedGroup ? selectedGroup.name : ('settings.all' | translate) }}
          <i class="fal fa-chevron-down"></i>
        </div>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="selectedGroup = null; loadData({ page: 1, search: searchQuery, group: null })">{{ 'settings.all' | translate }}</button>
          <button ngbDropdownItem *ngFor="let group of groups" (click)="selectedGroup = group; loadData({ page: 1, search: searchQuery, group: group })">{{ group.name }}</button>
        </div>
      </div>
      <app-search [(query)]="searchQuery" [theme]="'clean'" (queryChange)="pageNumber = 1; loadData({ page: 1, search: $event })"></app-search>
    </div>
  </div>

  <div class="row" *ngIf="currentUser && (isShowingInvitations ? invitations : members).length > 0">
    <div class="col-12">
      <table class="configuration-table">
        <thead class="head">
          <th class="col1">{{ 'settings.name' | translate }}</th>
          <th class="col2">{{ 'settings.individualPermissions' | translate }}</th>
          <th class="col2">{{ 'settings.group' | translate }}</th>
          <th class="col3"></th>
        </thead>
        <tbody class="body">
          <tr *ngFor="let member of isShowingInvitations ? invitations : members; let index = index">
            <td class="user">
              <div class="user-holder">
                <app-autenticats class="user-avatar" [seed]="member.user?.email || member.user?.name"></app-autenticats>
                <div class="user-info">
                  <p class="user-name">{{ member.user?.name }}</p>
                  <p class="user-contact">{{ member.user?.email }}</p>
                </div>
              </div>
            </td>
            <td class="highlight">
              <a
                href="javascript:void(0)"
                (click)="!isLoading && openEditPermissionsModal(member)"
                *ngIf="!isShowingInvitations && currentUser?.currentPermissions.actions_members_oz && !this.organizationService.isMemberAdmin(member)"
              >
                {{ (this.organizationService.isMemberAdmin(member) ? 'settings.admin' : hasFullAccess(member) ? 'settings.accessTotal' : 'settings.accessRestricted') | translate }}
              </a>
              <span *ngIf="isShowingInvitations || !currentUser?.currentPermissions.actions_members_oz || this.organizationService.isMemberAdmin(member)">
                {{ (this.organizationService.isMemberAdmin(member) ? 'settings.admin' : hasFullAccess(member) ? 'settings.accessTotal' : 'settings.accessRestricted') | translate }}
              </span>
            </td>
            <td class="highlight">
              <a href="javascript:void(0)" (click)="!isLoading && openEditGroupModal(member)" *ngIf="!isShowingInvitations && currentUser?.currentPermissions.actions_members_oz">
                {{ member.group?.name | truncate: 60 }}
              </a>
              <span *ngIf="isShowingInvitations || !currentUser?.currentPermissions.actions_members_oz">{{ member.group?.name | truncate: 60 }}</span>
            </td>
            <td class="opts" *ngIf="!isShowingInvitations">
              <div class="dropdown-table" placement="bottom-right" *ngIf="currentUser?.currentPermissions.actions_members_oz" container="body" ngbDropdown>
                <label class="dropdown-table-toggle" [id]="'options-dropdown-' + index" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
                <div [attr.aria-labelledby]="'options-dropdown-' + index" ngbDropdownMenu>
                  <button (click)="openEditPermissionsModal(member)" [disabled]="isLoading || this.organizationService.isMemberAdmin(member)" ngbDropdownItem>
                    {{ 'button.editPermissions' | translate }}
                  </button>
                  <button (click)="openEditGroupModal(member)" [disabled]="isLoading" ngbDropdownItem>{{ 'button.editGroup' | translate }}</button>
                  <button (click)="deleteMember(member)" [disabled]="isLoading" ngbDropdownItem>{{ 'button.removeMember' | translate }}</button>
                </div>
              </div>
            </td>
            <td class="opts" *ngIf="isShowingInvitations">
              <div class="dropdown-table" placement="bottom-right" *ngIf="currentUser?.currentPermissions.actions_members_oz" container="body" ngbDropdown>
                <label class="dropdown-table-toggle" [id]="'options-dropdown-' + index" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
                <div [attr.aria-labelledby]="'options-dropdown-' + index" ngbDropdownMenu>
                  <button (click)="resendInvitation($any(member))" [disabled]="isLoading || invitationsResent.includes($any(member).id)" ngbDropdownItem>{{ 'button.resendEmail' | translate }}</button>
                  <button (click)="deleteMemberInvitation($any(member))" [disabled]="isLoading" ngbDropdownItem>{{ 'button.revokeInvite' | translate }}</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-3" *ngIf="!isLoading && !isLastPage">
        <button type="button" class="btn btn-link" (click)="loadData({ page: pageNumber + 1, search: searchQuery, group: selectedGroup })">{{ 'button.loadMore' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="configuration-filtered-empty" *ngIf="!isShowingInvitations && currentUser && members.length === 0 && (searchQuery || selectedGroup)">
    <div class="configuration-filtered-empty_text">
      {{ 'settings.noMatchingOrgMembers' | translate }}
      <span *ngIf="selectedGroup"
        >"<strong>{{ selectedGroup.name }}</strong
        >"</span
      >
      <span *ngIf="searchQuery && selectedGroup">e</span>
      <span *ngIf="searchQuery"
        >"<strong>{{ searchQuery }}</strong
        >"</span
      >
    </div>
    <button type="button" class="default-button" (click)="searchQuery = ''; selectedGroup = null; loadMembers({ page: 1 })">{{ 'button.removeFilters' | translate }}</button>
  </div>
  <div class="configuration-filtered-empty" *ngIf="currentUser && (isShowingInvitations ? invitations.length === 0 : members.length === 0 && (searchQuery || selectedGroup))">
    <div class="configuration-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
  </div>
</article>
