import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { WhitelabelService } from '@app/services';

@Component({
  selector: 'app-taxonomy',
  templateUrl: './taxonomy.component.html',
  styleUrls: ['./taxonomy.component.scss']
})
export class TaxonomyComponent {
  @Input() mainTitle: string;
  @Input() allowSearch = false;
  @Input() isSearchOpen = false;
  @Input() isMenuMobileOpen = false;
  @Output() isSearchOpenChange = new EventEmitter<boolean>();

  constructor(public whitelabelService: WhitelabelService) {}

  @HostListener('window:resize') private onResize() {
    if (window.innerWidth <= 991 && this.isSearchOpen) {
      this.isSearchOpen = false;
      this.isSearchOpenChange.emit(this.isSearchOpen);
    }
  }
}
