import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() query: string;
  @Input() theme: 'blue' | 'clean' = 'blue';
  @Input() placeholder = this.translateService.instant('shared.searchEllips');
  @Output() queryChange = new EventEmitter<string>();
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
  private previousQuery: string;

  constructor(private translateService: TranslateService) {}

  emit(query: string) {
    if (this.previousQuery !== query) {
      this.queryChange.emit(query || '');
    }
    this.previousQuery = query;
  }

  focus() {
    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.focus();
    }
  }
}
