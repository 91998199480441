import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { User } from '@app/models';
import { AppService } from '@app/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class WootricService {
  private _isBooted = false;

  constructor(private appService: AppService) {}

  get isBooted() {
    return this._isBooted;
  }

  boot(currentUser: User) {
    if (!this.isBooted && environment.wootricKey && currentUser) {
      // this.activateTestMode();

      (window as any).wootricSettings = {
        account_token: environment.wootricKey,
        email: currentUser.email,
        created_at: Math.round(new Date(currentUser.created_at).getTime() / 1000),
        modal_theme: this.appService.isDarkmode ? 'dark' : 'light',
        product_name: 'Autentique',
        modal_footprint: 'compact',
        language: 'PT',
        twitter_account: 'AutentiqueQG',
        facebook_page: 'https://www.facebook.com/autentique.com.br',
        properties: { pricing_plan: (currentUser.subscription && currentUser.subscription.name) || 'Free' }
      };
      setTimeout(() => {
        if ((window as any).wootric) {
          (window as any).wootric('run');
        }
      });

      this._isBooted = true;
    }
  }

  activateTestMode() {
    // TEST ONLY FLAGS - REMOVE BEFORE GOING LIVE

    // Overwrites sampling settings and forces the server to return true to all survey requests.
    (window as any).wootric_survey_immediately = true;

    // Disables the cookie writing after a survey is taken effectively disabling any client side mechanisms to prevent multiple surveys from being rendered.
    (window as any).wootric_no_surveyed_cookie = true;
  }
}
