import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalService } from '@app/core/modal.service';
import { AlertModalComponent as ModalComponent, AlertType, ModalPublicProperties, ModalResult } from './alert-modal.component';

export { AlertType };

type CustomModalPublicProperties = ModalPublicProperties & { dismissAsError?: boolean };

@Injectable({ providedIn: 'root' })
export class AlertModalService {
  constructor(private modalService: ModalService) {}

  confirmation(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    return this.open(AlertType.confirmation, properties, options);
  }

  warning(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    return this.open(AlertType.warning, properties, options);
  }

  warningPayment(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    return this.open(AlertType.warningPayment, properties, options);
  }

  private open(type: AlertType, properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    const dismissAsError = properties.dismissAsError;
    delete properties.dismissAsError;
    return this.modalService.open<ModalResult>(ModalComponent, { ...properties, type }, options, dismissAsError);
  }
}
