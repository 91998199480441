import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CustomContact, UserService } from '@app/services/user.service';
import { User } from '@app/models';
import { ContactsService } from '@app/services/contacts.service';
import { NotyService, AppService } from '@app/services';
// import { StorageService } from '@app/core';
import { ColumnControlService } from '@app/services/columncontrol.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contacts-user',
  templateUrl: './contacts-user.component.html',
  styleUrls: ['./contacts-user.component.scss']
})
export class ContactsUserComponent implements OnInit, OnDestroy, AfterViewInit {
  isMainCheckboxChecked: boolean = false;
  contacts: Array<CustomContact> = [];
  currentUser: User;
  currentPage: number = 1;
  showPagination: boolean;
  searchNameResult: string = '';
  searchEmailResult: string = '';
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('container__inside') container__inside: ElementRef;
  @ViewChild('c1') c1: ElementRef;
  @ViewChild('c2') c2: ElementRef;
  @ViewChild('c3') c3: ElementRef;
  @ViewChild('c4') c4: ElementRef;
  @ViewChild('c5') c5: ElementRef;

  constructor(
    public userService: UserService,
    public appService: AppService,
    public columnControl: ColumnControlService,
    private contactsService: ContactsService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getContacts().subscribe({
      next: data => {
        this.contacts = data;
        this.hidePagination(data);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.columnControl.start(this.container__inside.nativeElement.clientWidth);
    });
  }

  ngOnDestroy() {
    this.currentPage = 1;
    this.setColumns('c1' || '', 'c2' || '', 'c3' || '', 'c4' || '', 'c5' || '');
  }

  getContacts(limit?: number, contact?: string) {
    let a = { search: contact, limit: limit || 20, page: this.currentPage };
    if (!contact) {
      delete a.search;
    }
    return this.userService.contacts(a, { fetchPolicy: 'no-cache' });
  }

  onChangeMainCheckbox(value: boolean) {
    this.contacts.forEach(contact => (contact._isSelected = value));
    this.isMainCheckboxChecked = value;
  }

  onChangeCheckbox() {
    this.isMainCheckboxChecked = this.contacts.every(contact => contact._isSelected);
  }

  indetermineMainCheckbox() {
    const selectedCountContacts = this.contacts.filter(contact => contact._isSelected).length;
    if (this.contacts.length === selectedCountContacts) {
      return false;
    } else {
      return selectedCountContacts > 0 && !this.isMainCheckboxChecked;
    }
  }

  deleteSelectedContacts(contactId?: number) {
    let selectedContacts: number[];
    if (contactId) {
      selectedContacts = [contactId];
    } else {
      selectedContacts = this.contacts.filter(contact => contact._isSelected).map(contact => contact.id);
    }
    this.contactsService.deleteContacts(selectedContacts).subscribe(
      () => {
        this.currentPage = 1;
        this.getContacts(20, this.searchNameResult || this.searchEmailResult || '').subscribe({
          next: data => {
            this.contacts = data;
            this.notyService.success(this.translateService.instant('notyService.deletionRequestCompleted'));
            this.hidePagination(data);
          }
        });
      },
      error => {
        this.notyService.error(this.translateService.instant('notyService.somethingWentWrong', { error }));
      }
    );
  }

  searchContacts(contact?: string) {
    this.currentPage = 1;
    if (contact.length >= 3) {
      this.getContacts(20, contact).subscribe({
        next: data => {
          this.contacts = data;
          this.hidePagination(data);
        }
      });
    } else if (contact.length === 0) {
      this.getContacts().subscribe({
        next: data => {
          this.contacts = data;
          this.hidePagination(data);
        }
      });
    }
  }

  loadMoreContacts() {
    this.currentPage++;
    this.getContacts(20, this.searchNameResult || this.searchEmailResult || '').subscribe({
      next: data => {
        data.forEach(contact => this.contacts.push(contact));
        this.hidePagination(data);
      }
    });
  }

  hidePagination(data: any) {
    if (data.length < 20) {
      this.showPagination = false;
    } else {
      this.showPagination = true;
    }
  }

  changeColumn(name: any) {
    this.columnControl.toggleColumn(name);
  }

  setColumns(...columnNames: any) {
    columnNames.forEach((columnName: string) => {
      if (this[columnName]?.width) {
        this.columnControl.setWidth('--' + columnName, this[columnName].width);
        this.columnControl.saveToLocalStorage();
      }
    });
  }
}
