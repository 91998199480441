import { Pipe, PipeTransform } from '@angular/core';

/**
 * Limits the text length by a max amount and ends text with "..."
 */
@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  constructor() {}

  transform(text: string, maxLength: number): string {
    return (text || '').length > maxLength ? text.slice(0, Math.max(maxLength - 3, 0)) + '...' : text;
  }
}
