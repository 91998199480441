import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WhitelabelParams, WhitelabelService } from '@app/services';
import { ColumnControlService } from '@app/services/columncontrol.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {
  constructor(public whitelabelService: WhitelabelService, private columnControlService: ColumnControlService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}
}
