<div class="modal-body biometry">
  <p class="centermodal__title">{{ 'documents.biometricVerification' | translate }}</p>
  <p class="centermodal__description">
    {{ 'documents.' + (isMobile ? 'biometricVerificationSteps' : 'scanQRCode') | translate }}
  </p>

  <div class="centermodal__form" *ngIf="verification?.payload">
    <div class="biometry__code" *ngIf="verification.payload.url && !isMobile">
      <qrcode class="biometry__code-qrcode" [qrdata]="verification.payload.url" [width]="140" [margin]="0"></qrcode>
    </div>

    <div class="centermodal__button-group centermodal__button-group--vertical" *ngIf="verification.payload.url && isMobile">
      <a class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" target="_blank" [href]="verification.payload.url">
        {{ 'documents.startVerification' | translate }}
      </a>
    </div>

    <p class="centermodal__description" style="margin: auto" [innerHTML]="'documents.accessVerificationLink' | translate: { url: verification.payload.url }"></p>

    <div class="biometry__code">
      <span class="biometry__code-text">{{ verification.payload.reference }}</span>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" (click)="checkSecurityVerification()" [disabled]="isLoading || isTimedOut">
      {{ 'button.forward' | translate }}
    </button>
  </div>
</div>
