import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Replaces line breaks for '<br>' and removes every other HTML tags from being executed
 */
@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(url: string) {
    return url ? this.domSanitizer.bypassSecurityTrustResourceUrl(url) : '';
  }
}
