<form [formGroup]="form" (ngSubmit)="emitSearchChange()" novalidate class="dSearch">
  <div class="dSearch__inputs" *ngIf="!isFoldersSearch">
    <div class="dSearch__fields">
      <label class="dSearch__fields-item dSearch__fields-item--type-one field__holder" [appFormControlWrap]="form.get('name')">
        <input type="text" class="field" formControlName="name" autocomplete="none" role="presentation" />
        <span class="field__placeholder"><i class="fal fa-file-alt"></i>&nbsp; {{ 'documents.documentName' | translate }}</span>
      </label>
      <label class="dSearch__fields-item dSearch__fields-item--type-one field__holder" [appFormControlWrap]="form.get('signer')">
        <input type="text" class="field" formControlName="signer" autocomplete="none" role="presentation" />
        <span class="field__placeholder"><i class="fal fa-user"></i>&nbsp; {{ 'documents.signatorie' | translate }}</span>
      </label>
      <div class="dSearch__fields-item dSearch__fields-item--type-two" [appFormControlWrap]="form.get('status')" *ngIf="!isTrash">
        <app-select formControlName="status" placeholder="{{ 'appSelect.status' | translate }}" [options]="documentStatuses" [disableSearch]="true"></app-select>
      </div>
      <label class="dSearch__fields-item dSearch__fields-item--type-two field__holder" #dateRangePickerLabel>
        <input
          #dateRange
          type="text"
          class="field"
          ngxDaterangepickerMd
          opens="center"
          [(ngModel)]="selectedDateRange"
          [ngModelOptions]="{ standalone: true }"
          [class.empty]="!(selectedDateRange && (selectedDateRange.startDate || selectedDateRange.endDate))"
          [keepCalendarOpeningWithRange]="true"
          [alwaysShowCalendars]="true"
          [showCustomRangeLabel]="true"
          [showCancel]="true"
          [showClearButton]="true"
          [locale]="dateRangePickerOptions.locale"
          [ranges]="dateRangePickerOptions.ranges"
          (datesUpdated)="fixInvalidEndDateOnChange($event, dateRange)"
          (startDateChanged)="allowApplyOnlyWhenStartAndEndDateExists(false)"
          (endDateChanged)="allowApplyOnlyWhenStartAndEndDateExists(true)"
          readonly
        />
        <span class="field__placeholder">
          <i class="fal fa-calendar"></i>
          {{ 'documents.createdOnEllipsis' | translate }}
        </span>
      </label>
      <app-select
        class="dSearch__fields-item dSearch__fields-item--type-three"
        placeholder="{{ 'documents.documentsOf' | translate }}"
        [(ngModel)]="selectedMemberOrGroup"
        [ngModelOptions]="{ standalone: true }"
        [optionsRequest]="organizationMembersAndGroups.bind(this)"
      ></app-select>
    </div>
    <div class="dSearch__switches" *ngIf="!isTrash">
      <label class="dSearch__switches-item" [class.active]="form.get('includeDeleted').value">
        <app-switch formControlName="includeDeleted"></app-switch>
        <span>{{ 'documents.deletedDocuments' | translate }}</span>
      </label>
      <label class="dSearch__switches-item" [class.active]="form.get('includeArchived').value">
        <app-switch formControlName="includeArchived"></app-switch>
        <span>{{ 'documents.archivedDocuments' | translate }}</span>
      </label>
    </div>
  </div>

  <div class="dSearch__inputs" *ngIf="isFoldersSearch">
    <div class="dSearch__fields">
      <label class="dSearch__fields-item dSearch__fields-item--type-one field__holder" [appFormControlWrap]="form.get('search')">
        <input type="text" class="field" formControlName="search" autocomplete="none" role="presentation" />
        <span class="field__placeholder"><i class="fal fa-archive"></i>&nbsp; {{ 'documents.folderName' | translate }}</span>
      </label>
    </div>
  </div>

  <div class="dSearch__actions">
    <button type="submit" class="dSearch__actions-submit btn btn-primary">{{ 'button.search' | translate }}</button>
    <button type="button" class="dSearch__actions-close" (click)="closeClick.emit()"><i class="fal fa-times"></i></button>
  </div>
</form>
