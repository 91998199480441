<!--<ng-container *ngIf="user.subscription.credits > 0">-->
<i *ngIf="!disableIcon" class="fal fa-shield-check"></i>
<div *ngIf="user" class="credits" [ngbTooltip]="tooltipSignatureVisible" container="body">
  <div class="credits__monthly">
    {{ user.subscription.credits > 999 ? '99+' : user.subscription.credits }}
  </div>
</div>

<ng-template #tooltipSignatureVisible>
  <div class="tooltip__descr">{{ 'shared.availableCreditsForAdditionalVerification' | translate: { credits: user.subscription.credits } }}</div>
</ng-template>
<!--</ng-container>-->
