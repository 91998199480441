import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import * as Noty from 'noty';

import { LottieComponent } from '@app/shared/lottie/lottie.component';

@Injectable({ providedIn: 'root' })
export class NotyService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private applicationRef: ApplicationRef, private injector: Injector) {
    Noty.overrideDefaults({
      theme: 'bootstrap-v4',
      layout: 'topRight',
      progressBar: true,
      timeout: 5000
    } as Noty.Options);
  }

  success(textOrOptions: string | Noty.Options, timeout?: false | number) {
    return this.notyFactory('success', textOrOptions, timeout, 'face-happy');
  }

  error(textOrOptions: string | Noty.Options, timeout?: false | number) {
    return this.notyFactory('error', textOrOptions, timeout, 'face-sad');
  }

  alert(textOrOptions: string | Noty.Options, timeout?: false | number) {
    return this.notyFactory('alert', textOrOptions, timeout, 'face-neutral');
  }

  warning(textOrOptions: string | Noty.Options, timeout?: false | number) {
    return this.notyFactory('warning', textOrOptions, timeout, 'face-neutral');
  }

  info(textOrOptions: string | Noty.Options, timeout?: false | number) {
    return this.notyFactory('info', textOrOptions, timeout, 'face-neutral');
  }

  private notyFactory(type: Noty.Type, textOrOptions: string | Noty.Options, timeout?: false | number, lottieAnimation?: string) {
    const noty = new Noty({
      ...(typeof textOrOptions === 'string' ? { text: textOrOptions } : textOrOptions),
      ...(timeout === undefined ? {} : { timeout }),
      type
    });

    if (lottieAnimation) {
      this.addLottieAnimation(noty, lottieAnimation);
    }

    noty.show();
  }

  private addLottieAnimation(noty: Noty, lottieAnimation: string) {
    const _that = this;
    let lottieComponentRef: any;

    // Tem que ser function() pra poder usar o 'this'
    // tslint:disable-next-line:only-arrow-functions
    noty.on('onTemplate', function() {
      const scope: { barDom: HTMLElement } = this;

      const contentWrapperHTML = document.createElement('div');
      contentWrapperHTML.classList.add('noty_text');
      contentWrapperHTML.innerHTML = scope.barDom.children[0].innerHTML;
      scope.barDom.children[0].innerHTML = '';

      const componentFactory = _that.componentFactoryResolver.resolveComponentFactory(LottieComponent);
      const componentRef = componentFactory.create(_that.injector);
      _that.applicationRef.attachView(componentRef.hostView);
      lottieComponentRef = componentRef;

      scope.barDom.children[0].appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
      scope.barDom.children[0].appendChild(contentWrapperHTML);

      setTimeout(() => {
        lottieComponentRef.instance.name = lottieAnimation;
        lottieComponentRef.instance.width = 70;
        lottieComponentRef.instance.height = 70;
      });
    });

    noty.on('afterClose', () => {
      if (lottieComponentRef) {
        this.applicationRef.detachView(lottieComponentRef.hostView);
        lottieComponentRef.destroy();
      }
    });
  }
}
