import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Whitelabel } from '@app/models';
import { WhitelabelService } from '@app/services';

@Component({
  selector: 'app-signed-document',
  templateUrl: './signed-document.component.html',
  styleUrls: ['./signed-document.component.scss']
})
export class SignedDocumentComponent implements OnChanges {
  @Input() whitelabelData: Whitelabel;
  @Input() selectedPdfLogoImageUrl: string | null = null;
  @Input() selectedPdfLogoMiniImageUrl: string | null = null;

  pdfLogo: string;
  pdfLogoMini: string;

  constructor(public whitelabelService: WhitelabelService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.whitelabelData && changes.whitelabelData.currentValue) {
      this.pdfLogo = this.whitelabelData.pdf_logo;
      this.pdfLogoMini = this.whitelabelData.pdf_logo_mini;
    }
  }
}
