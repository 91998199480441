import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CredentialsService } from '@app/core';
import { AppService, WhitelabelParams, WhitelabelService } from '@app/services';

@Component({
  selector: 'app-documents-home',
  templateUrl: './documents-home.component.html',
  styleUrls: ['./documents-home.component.scss']
})
export class DocumentsHomeComponent implements OnInit {
  constructor(public credentialsService: CredentialsService, public whitelabelService: WhitelabelService, private appService: AppService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.appService.loadStatusPageWidget();
  }
}
