<div class="modal-body">
  <p class="centermodal__title mb-3">{{ 'documents.' + (isNewDocument ? 'archiveIn' : 'archive') | translate }}</p>

  <app-switch-tabs
    name="type"
    inputWidth="100%"
    [options]="tabOptions"
    [(ngModel)]="appService.folderContext"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="parentFolder = undefined; selectedFolder = undefined; searchFolders({ search: search }); focusSearchInput()"
    *ngIf="appService.folderContext && tabOptions.length > 1"
  ></app-switch-tabs>

  <div class="centermodal__form" [class.mb-3]="parentFolder">
    <app-search [(query)]="search" theme="clean" (queryChange)="searchFolders({ pageNumber: 1, search: search })" #searchComponent></app-search>
  </div>

  <div class="centermodal__form back-btn" *ngIf="parentFolder">
    <a href="javascript:void(0)" (click)="selectFolder(parentFolder.parentFolder || undefined)"> <i class="fal fa-chevron-left mr-1"></i> {{ 'button.back' | translate }}</a>
  </div>

  <ul class="centermodal__item-list mb-0">
    <li class="centermodal__item" [class.active]="selectedFolder === null" (click)="selectFolder(null)" *ngIf="!isLoading && !parentFolder && !search && folders && folders.length > 0">
      <i class="fal fa-lg fa-ban"></i> {{ 'documents.noFolder' | translate }}
    </li>
    <li class="centermodal__item" [class.active]="selectedFolder?.id === parentFolder.id" (click)="selectFolder(parentFolder, true)" *ngIf="parentFolder">
      <i class="fal fa-lg fa-folders"></i> {{ parentFolder.name }}
    </li>
    <li class="centermodal__item" [class.ml-4]="parentFolder" *ngFor="let folder of folders" [class.active]="selectedFolder?.id === folder.id" (click)="selectFolder(folder)">
      <i class="fal fa-lg fa-{{ folder.children_counter > 0 ? 'folders' : 'folder' }}"></i> {{ folder.name }}
      <span class="folder-count" *ngIf="folder.children_counter > 0">{{ folder.children_counter > 99 ? '99+' : folder.children_counter }}</span>
    </li>
  </ul>

  <app-small-loader *ngIf="isLoading && (!folders || folders.length === 0)"></app-small-loader>

  <div class="centermodal__form empty-warning" *ngIf="!isLoading && folders && folders.length === 0">{{ 'documents.noFoldersFound' | translate }}</div>

  <div class="pagination">
    <button type="button" class="btn btn-link" (click)="searchFolders({ search: search, pageNumber: pageNumber + 1 })" *ngIf="!isLastPage && !isLoading">{{ 'button.loadMore' | translate }}</button>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-outline" [disabled]="isLoading" (click)="openCreateFolderModal()" *ngIf="!isNewDocument">
      {{ 'documents.createFolder' | translate }}
    </button>
    <button
      type="button"
      class="centermodal__button centermodal__button--custom-next centermodal__button--margin-simple"
      [disabled]="isLoading || selectedFolder === undefined"
      (click)="moveToFolder(selectedFolder)"
      *ngIf="!isNewDocument"
    >
      {{ 'documents.' + (selectedFolder === null ? 'unarchive' : 'archive') | translate }}
    </button>
    <button
      type="button"
      class="centermodal__button centermodal__button--custom-next"
      [disabled]="isLoading || selectedFolder === undefined"
      (click)="targetFolder(selectedFolder)"
      *ngIf="isNewDocument"
    >
      {{ 'button.select' | translate }}
    </button>
  </div>
</div>
