<!-- MOBILE: -->
<app-mobile-header-secondary title="{{ 'menu.organization' | translate }}">
  <!--  <a href="javascript:void(0)" class="mobile-header__option" (click)="(null)">Adicionar</a>-->
</app-mobile-header-secondary>

<!--
<div class="group">
  <div class="group__title">Dados da organização</div>

  <div class="group__options">
    <div class="group__option group__option--multiline group__option--custom-button">
      <div class="group__option-placeholder">Nome da organização</div>
      <div class="group__option-value">{{ currentUser?.company }}</div>

      <div class="group__option-placeholder">CNPJ</div>
      <div class="group__option-value">{{ currentUser?.cnpj }}</div>

      <div class="group__option-placeholder">Titular</div>
      <div class="group__option-value">Cassiano Calegari, 025.915.970-06</div>

      <label for="plano-cobranca-menu" class="group__option-button">Alterar</label>
    </div>
  </div>
</div>
-->

<!--
<div class="group" *ngIf="currentUser && currentUser.organization.id && currentUser.organization.role.name === OrganizationRoles.Owner">
  <div class="group__title">Opções da organização</div>
  <div class="group__switches" *ngIf="organizationFlagsForm">
    <form class="form" [formGroup]="organizationFlagsForm" (ngSubmit)="updateOrganizationFlags()" novalidate>
      <div formGroupName="flags">
        <div class="group__switch">
          <app-switch formControlName="overwrite_email" (ngModelChange)="updateOrganizationFlags()" [disableInput]="isLoading"></app-switch>
          <span class="group__switch-name">Sobrescrever a personalização dos e-mails</span>
        </div>
        <div class="group__switch">
          <app-switch formControlName="overwrite_template" (ngModelChange)="updateOrganizationFlags()" [disableInput]="isLoading"></app-switch>
          <span class="group__switch-name">Sobrescrever os modelos dos usuários</span>
        </div>
        <div class="group__switch">
          <app-switch formControlName="overwrite_name" (ngModelChange)="updateOrganizationFlags()" [disableInput]="isLoading"></app-switch>
          <span class="group__switch-name">Sobrescrever a empresa e CNPJ dos usuários</span>
        </div>
      </div>
    </form>
  </div>
</div>
-->

<!-- DESKTOP: -->

<article class="configuration-item" *ngIf="currentUser">
  <div class="row mb-4">
    <div class="col-8">
      <div class="configuration-title mb-1">
        {{ 'settings.settingsGeneralOf' | translate }} <span class="highlight">{{ currentUser.organization?.name }}</span>
      </div>
      <div class="configuration-text">
        {{ 'settings.organizationsSharedDocuments' | translate }}
      </div>
      <!-- <a href="javascript:void(0)" class="configuration-link"><i class="fal fa-question-circle"></i> Ajuda sobre essa tela</a> -->
    </div>
    <div class="col-4 text-right" *ngIf="currentUser?.organization?.id && currentUser.currentPermissions.change_appearances_oz">
      <button type="button" class="configuration-button" (click)="openEditOrganizationModal()">{{ 'button.edit' | translate }}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-8">
      <label class="field__holder">
        <input type="text" class="field" [value]="currentUser.organization?.name" disabled />
        <span class="field__placeholder">{{ 'profile.organizationName' | translate }}</span>
      </label>
    </div>
  </div>

  <div class="configuration-subtitle mt-3">{{ 'profile.companyData' | translate }}</div>
  <div class="row">
    <div class="col-xl-8">
      <div class="row">
        <div class="col-lg-8">
          <label class="field__holder">
            <input type="text" class="field" [value]="currentUser.organization?.name" disabled />
            <span class="field__placeholder">{{ 'profile.businessName' | translate }}</span>
          </label>
        </div>
        <div class="col-lg-4">
          <label class="field__holder">
            <input type="text" class="field" [value]="currentUser.organization?.cnpj" disabled />
            <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</article>

<article class="configuration-item" *ngIf="currentUser?.organization?.id">
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-danger" (click)="openSelfDeleteOrganizationMemberModal()" [disabled]="isLoading">
        <i class="fal fa-sign-out"></i>&nbsp; {{ 'settings.' + (isLastMember ? 'excludeFromOrganization' : 'leaveFromOrganization') | translate }}
      </button>
    </div>
  </div>
</article>
