import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Credentials, CredentialsService } from '@app/core';
import { AuthenticationMethod } from '@app/models';
import { AppService } from './app.service';

// tslint:disable-next-line:no-empty-interface
export interface BaseLoginContext {}
export interface LoginContext extends BaseLoginContext {
  email: string;
  password: string;
  referral?: string;
}
export interface OauthLoginContext extends BaseLoginContext {
  code: string;
  referral?: string;
  phone_number: string;
}
export interface PasswordlessLoginContext extends BaseLoginContext {
  signature_id: string;
}
export interface EnterpriseLoginContext extends BaseLoginContext {
  enterprise_token: string;
}
export interface RegistrationContext {
  name: string;
  email: string;
  cpf: string;
  birthday: string;
  password: string;
  password_confirmation?: string;
  company?: string;
  cnpj?: string;
  referral?: string;
  country: string;
  language: string;
  timezone?: string;
}
export interface ResetPasswordContext {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

/**
 * Provides a base for authentication workflow.
 * The auth-login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  static parseCredentials(responseBody: any): Credentials {
    return {
      accessToken: responseBody.access_token,
      tokenType: responseBody.token_type,
      expiresAt: new Date().setSeconds(new Date().getSeconds() + responseBody.expires_in),
      firebaseToken: responseBody.firebase_jwt
    };
  }

  constructor(private credentialsService: CredentialsService, private httpClient: HttpClient, private appService: AppService) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @param method Authentication method. Defaults to the enum key 'email'.
   * @return The user credentials.
   */
  login(context: BaseLoginContext, method: AuthenticationMethod = AuthenticationMethod.Email): Observable<Credentials> {
    if ((context as LoginContext).email) {
      (context as LoginContext).email = ((context as LoginContext).email || '').trim();
    }

    return this.httpClient.post('/auth/login/' + method, context).pipe(
      map(body => {
        const credentials = AuthenticationService.parseCredentials(body);
        this.credentialsService.setCredentials(credentials);
        return credentials;
      }),
      tap(() => this.appService.refreshCreditsPrice())
    );
  }

  /**
   * Logs out the user and clear credentials.
   */
  logout() {
    let logout$ = of(null);

    if (this.credentialsService.isAuthenticated()) {
      logout$ = this.httpClient.disableLoader().post('/auth/logout', null, { headers: this.credentialsService.authorizationHeader });
    }
    logout$ = logout$.pipe(tap(() => this.credentialsService.clearCredentials()));

    return logout$;
  }

  /**
   * Signs up the user.
   * @return The user credentials.
   */
  register(context: RegistrationContext): Observable<Credentials & { isEmailConfirmed: boolean }> {
    context.password_confirmation = context.password;
    context.email = (context.email || '').trim().toLowerCase();
    context.name = (context.name || '').trim();
    context.company = (context.company || '').trim() || null;
    context.cnpj = (context.cnpj || '').trim() || null;

    if (context.name && context.name === context.name.toUpperCase()) {
      context.name = context.name
        .toLowerCase()
        .split(' ')
        .filter(part => !!part)
        .map(part => part[0].toUpperCase() + part.substring(1))
        .join(' ');
    }

    return this.httpClient.post('/auth/register/email', context).pipe(
      map((body: any) => {
        const credentials = AuthenticationService.parseCredentials(body);
        if (body.verified) {
          this.credentialsService.setCredentials(credentials);
        }
        return { isEmailConfirmed: body.verified, ...credentials };
      })
    );
  }

  /**
   * Sends email cofirmation link.
   * @param email The user's email.
   */
  sendEmailConfirmation(email: string): Observable<null> {
    return this.httpClient.post('/email/verify/resend', { email: (email || '').trim() }).pipe(map(() => null));
  }

  /**
   * Sends email with link to change password.
   * @param email The user's email.
   */
  sendResetPasswordConfirmation(email: string): Observable<null> {
    return this.httpClient.post('/password/email', { email: (email || '').trim() }).pipe(map(() => null));
  }

  /**
   * Sends email with link to change password.
   * @param context The user's token and new password.
   */
  resetPassword(context: ResetPasswordContext): Observable<null> {
    return this.httpClient.post('/password/reset', context).pipe(map(() => null));
  }

  clearAllFields() {
    document.querySelectorAll('.tokenField__field').forEach((e: any) => {
      e.value = '';
    });
  }
}
