import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { of, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Contact, CustomSignerInput, SignerMainAttributes, User, CreditTypeEnum, SecurityVerificationEnum } from '@app/models';
import { AppService, DocumentService, NotyService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

export { HighlightPipe } from './highlight.pipe';

@Component({
  selector: 'app-signer-input',
  templateUrl: './signer-input.component.html',
  styleUrls: ['./signer-input.component.scss']
})
export class SignerInputComponent implements OnInit, AfterViewInit {
  @Input() signer: CustomSignerInput;
  @Input() qualified: boolean;
  @Input() isNewDocument: boolean;
  @Input() isCountryBR: boolean;
  @Input() validationErrors: { [k: string]: any } = {};
  @Output() changeSigner = new EventEmitter<CustomSignerInput>();
  @ViewChild('inputModel') inputModel: NgModel;
  @ViewChild('phoneInputModel') phoneInputModel: NgModel;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('suggestions') suggestionsRef: ElementRef<HTMLDivElement>;
  searchQuery: string;
  currentUser: User;
  contacts: Contact[] = [];
  hasPhoneError = false;
  hasVerificationPhoneError = false;
  readonly CreditTypeEnum = CreditTypeEnum;
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  readonly inputId = 'signer-input-element-' + Math.floor(Math.random() * 1000000);
  readonly signerMainAttributes = Object.values(SignerMainAttributes);

  constructor(public appService: AppService, public documentService: DocumentService, public translateService: TranslateService, private userService: UserService, private notyService: NotyService) {}

  ngAfterViewInit() {
    if (this.signer._extra.signerMainAttribute) {
      setTimeout(() => {
        if (this.inputModel && this.inputModel.value) {
          this.inputModel.control.markAsTouched();
        } else if (this.phoneInputModel && this.phoneInputModel.value) {
          this.phoneInputModel.control.markAsTouched();
        }
      });
    }
  }

  ngOnInit() {
    this.userService
      .getCurrentUser()
      .pipe(filter(user => !!user))
      .subscribe(user => (this.currentUser = user));
  }

  modelChange(signer: CustomSignerInput) {
    this.changeSigner.emit(signer);
    if (signer._extra && ['email', 'name'].includes(signer._extra.signerMainAttribute) && signer[signer._extra.signerMainAttribute].length >= 3) {
      this.searchContacts(signer);
    }
  }

  searchContacts(signer: CustomSignerInput) {
    const parseSearchQuery = () =>
      ((signer && signer.email) || '')
        .trim()
        .slice(0, 30)
        .toLowerCase();
    const search = parseSearchQuery();
    if (signer._extra.signerMainAttribute !== 'name' && this.currentUser && search && search !== this.currentUser.email) {
      timer(500)
        .pipe(
          switchMap(() => (search === parseSearchQuery() ? this.userService.contacts({ search, limit: 5 }) : of(null))),
          map(contacts => (contacts || []).filter((contact: Contact) => !!contact.name)),
          filter(contacts => contacts?.length > 0)
        )
        .subscribe(contacts => (this.contacts = contacts));
    } else {
      this.contacts = [];
    }
  }

  selectContact(contact: Contact) {
    this.signer.email = contact.email;
    this.contacts = [];
    this.inputElement.nativeElement.focus();
  }

  changeSignerMainAttribute(signer: CustomSignerInput, attr: SignerMainAttributes | string) {
    if (signer._extra.signerMainAttribute !== attr) {
      const lastMainAttribute = this.signerMainAttributes.find(mainAttr => mainAttr === signer._extra.signerMainAttribute);
      if ([`${SignerMainAttributes.Sms}`, `${SignerMainAttributes.Whatsapp}`].includes(attr) && [`${SignerMainAttributes.Sms}`, `${SignerMainAttributes.Whatsapp}`].includes(lastMainAttribute)) {
        signer[attr] = signer[lastMainAttribute] || '';
      } else {
        signer[attr] = '';
      }
      delete signer[lastMainAttribute];
      signer._extra.signerMainAttribute = attr as SignerMainAttributes;

      setTimeout(() => {
        if (['email', 'name'].includes(this.signer._extra.signerMainAttribute)) {
          this.inputModel.control.markAsTouched();
          this.inputModel.control.updateValueAndValidity();
        } else if (['whatsapp', 'sms'].includes(this.signer._extra.signerMainAttribute)) {
          this.phoneInputModel.control.markAsTouched();
          this.phoneInputModel.control.updateValueAndValidity();
        }
      });
    }
  }

  checkPremiumFeatures(event: MouseEvent) {
    if (!this.currentUser || !this.currentUser.subscription) {
      event.preventDefault();
    } else if (!this.currentUser.subscription.has_premium_features) {
      event.preventDefault();
      this.notyService.error(this.translateService.instant('notyService.notAvailableFreePlan'));
    }
  }

  @HostListener('keydown', ['$event']) private onKeyDown(event: KeyboardEvent) {
    if (this.contacts && this.contacts.length > 0) {
      const key = (event.key || '').toLowerCase();
      if (key === 'escape') {
        this.contacts = [];
      } else if (['arrowup', 'arrowdown'].includes(key)) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const element = (key === 'arrowup' ? document.activeElement.previousElementSibling : document.activeElement.nextElementSibling) as HTMLAnchorElement;
        if (element && element.tagName === 'A' && this.suggestionsRef.nativeElement.contains(element)) {
          element.focus();
        } else {
          (this.suggestionsRef.nativeElement.querySelector('a:' + (key === 'arrowup' ? 'last-child' : 'first-child')) as HTMLAnchorElement).focus();
        }
      }
    }
  }

  @HostListener('focusout', ['$event']) private onBlur(event: FocusEvent) {
    setTimeout(() => {
      if (!event.relatedTarget || !this.suggestionsRef.nativeElement.contains(event.relatedTarget as HTMLElement)) {
        this.contacts = [];
      }
    }, 200);
  }
}
