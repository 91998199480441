<form class="modal-body" [formGroup]="form" (ngSubmit)="transfer()" novalidate>
  <p class="centermodal__title">{{ 'documents.' + (isRestoration ? 'restoreDocument' : 'transferDocument') | translate }}</p>
  <p class="centermodal__description" *ngIf="isRestoration">{{ 'documents.transferDocumentDescription' | translate }}</p>
  <p class="centermodal__description" *ngIf="!isRestoration">{{ 'documents.transferDocumentAction' | translate }}</p>

  <div class="centermodal__form">
    <label class="field__holder select" [appFormControlWrap]="form.get('organization')">
      <select class="field" [class.active]="organizations.length" formControlName="organization" [appDisableInput]="isLoading" required>
        <option [ngValue]="organization.id" *ngFor="let organization of organizations">{{ organization.name }}</option>
      </select>
      <p class="field__placeholder">{{ 'documents.organization' | translate }}</p>
    </label>
    <label class="field__holder select" [appFormControlWrap]="form.get('group')">
      <select class="field" [class.active]="groups.length" formControlName="group" [appDisableInput]="isLoading || !groups || groups.length === 0" required>
        <option ngValue="null" disabled></option>
        <option [ngValue]="group.id" *ngFor="let group of groups">{{ group.name }}</option>
      </select>
      <p class="field__placeholder">{{ 'documents.group' | translate }}</p>
    </label>
    <div class="warning-message" *ngIf="!isRestoration"><i class="fal fa-exclamation-triangle"></i>&nbsp; {{ 'documents.transferNotUndoable' | translate }}</div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">
      {{ 'button.' + (isRestoration ? 'restore' : 'transfer') | translate }}
    </button>
  </div>
</form>
