import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { I18nService } from '@app/core';

/**
 * Localized decimal pipe (i18n)
 */
@Pipe({ name: 'ldecimal' })
export class LdecimalPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private i18nService: I18nService) {}

  transform(value: any, digitsInfo?: string) {
    return this.decimalPipe.transform(value, digitsInfo, this.i18nService.language);
  }
}
