<div class="modal-body">
  <app-email-verify [verifier]="email" [email]="email" (verified)="nextStep($event)"></app-email-verify>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || isWaitingConfirmation" (click)="modal.close()">
      {{ 'button.continue' | translate }}
    </button>
  </div>
</div>
