import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { ErrorHandlerService, OrganizationService, UserService } from '@app/services';
import { User } from '@app/models';

export type ModalResult = void;
export interface ModalPublicProperties {
  user: User;
  isLastMember?: boolean;
}

@Component({
  selector: 'app-delete-self-organization-member-modal',
  templateUrl: './delete-self-organization-member-modal.component.html',
  styleUrls: ['./delete-self-organization-member-modal.component.scss']
})
export class DeleteSelfOrganizationMemberModalComponent implements ModalPublicProperties, AfterContentInit {
  form: FormGroup;
  isLoading = false;
  @Input() user: User;
  @Input() isLastMember: boolean;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private organizationService: OrganizationService
  ) {
    this.form = this.formBuilder.group({ name: '' });
  }

  ngAfterContentInit() {
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  deleteSelfOrganizationMember() {
    this.form.markAllAsTouched();
    this.form.get('name').setErrors(null);
    const userInput = this.form.get('name').value;
    if (userInput && this.user && this.user.organization && this.formatOrganizationName(userInput) === this.formatOrganizationName(this.user.organization.name)) {
      this.isLoading = true;
      this.organizationService
        .deleteMember({ id: this.user.id })
        .pipe(
          switchMap(() => forkJoin([this.userService.getCurrentUser({ fetchPolicy: 'network-only' }), this.organizationService.getOrganizations({ fetchPolicy: 'network-only' })])),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          () => this.modal.close(),
          error => this.errorHandlerService.handle(error)
        );
    } else {
      this.form.get('name').setErrors({ server: this.translateService.instant('error_name_invalid') });
    }
  }

  private formatOrganizationName(name: string) {
    return (name || '')
      .toLowerCase()
      .trim()
      .replace(/\s+/, ' ');
  }
}
