import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTrimSpaces]'
})
export class TrimSpacesDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput() {
    const input = this.el.nativeElement as HTMLInputElement;
    input.value = input.value.trimStart();
  }
}
