import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ModalService } from '@app/core/modal.service';
import { SecurityVerificationEnum } from '@app/models';
import { ModalPublicProperties, ModalResult, VerificationModalComponent as ModalComponent } from './verification-modal.component';

export type CustomModalPublicProperties = ModalPublicProperties & { dismissAsError?: boolean };

@Injectable({ providedIn: 'root' })
export class VerificationModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    return of(null as ModalResult).pipe(
      switchMap(() => {
        const dismissAsError = properties.dismissAsError;
        delete properties.dismissAsError;

        const needsVerification =
          properties.verification && !properties.verification.verified_at && (properties.verification.type === SecurityVerificationEnum.Manual || properties.verification.max_attempt > 0);
        return needsVerification ? this.modalService.open<ModalResult>(ModalComponent, properties, options, dismissAsError) : of(null as ModalResult);
      })
    );
  }
}
