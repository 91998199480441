import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { I18nService } from '@app/core';
import { UserService } from '@app/services';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private i18nService: I18nService, private userService: UserService) {}

  // default date format: for user config and time format: 'h:mm a'
  // result ex: 30/12/2023 15:25 or 30/12/2023 3:25 PM
  // Possible call date and time format in html: {{ date | dateTime }} or {{ date | dateTime: 'mediumDate':'shortTime' }}
  transform(value: any, dateFormat?: string, timeFormat?: string, timezone?: string): string {
    if (!value) {
      return '';
    }
    if ((value || '').match(/^[0-9]+-[0-9]+-[0-9]+\s[0-9]+:[0-9]+:[0-9]+$/)) {
      value = value.replace(/\s/, 'T');
    }
    const defaultFormat = this.i18nService.transformDateFormat(this.userService.getUserLocale()?.date_format) || 'shortDate';
    const defaultTimezone = this.i18nService.getTimezoneOffset(this.userService.getUserLocale()?.timezone) || null;
    const date = this.datePipe.transform(value, dateFormat || defaultFormat, timezone || defaultTimezone, this.i18nService.language);
    const time = this.datePipe.transform(value, timeFormat || 'shortTime', timezone || defaultTimezone, this.i18nService.language);
    return `${date} ${time}`;
  }
}
