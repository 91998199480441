import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, repeat, switchMap, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { throwOnGraphqlError } from '@app/core';
import { Referral } from '@app/models';
import { ReferralsGQL, ReferralsQueryVariables, ExportAffiliatesGQL, ExportAffiliatesQueryVariables } from 'src/generated/graphql.default';

@Injectable({ providedIn: 'root' })
export class ReferralService {
  constructor(private referralsGQL: ReferralsGQL, private exportAffiliatesGQL: ExportAffiliatesGQL) {}

  all(variables: ReferralsQueryVariables): Observable<Referral> {
    return this.referralsGQL.fetch(variables, { fetchPolicy: 'cache-first' }).pipe(
      throwOnGraphqlError(),
      map((response: any) => response.data.referrals),
      map((referral: Referral) => {
        const referralClone = cloneDeep(referral || {});
        referralClone.recent_extracts = JSON.parse(referralClone.recent_extracts || '[]');
        // referralClone.payment_info = JSON.parse(referralClone.payment_info || '{}');
        // referralClone.referral_configuration = JSON.parse(referralClone.referral_configuration || '{}');
        // referralClone.monthly_amount = JSON.parse(referralClone.monthly_amount || '{}');
        referralClone.affiliates = referralClone.affiliates || [];
        referralClone.affiliates.forEach(item => (item.payment_info = JSON.parse(item.payment_info || '[]')));
        return referralClone;
      })
    );
  }

  export(): Observable<Referral> {
    const limit = 500;
    let page = 1;
    let currentReferral: Referral;
    let affiliates: Referral['affiliates'] = [];

    return of(null).pipe(
      switchMap(() => this.exportAffiliatesGQL.fetch({ page, limit }, { fetchPolicy: 'no-cache' })),
      throwOnGraphqlError(),
      map((response: any) => response.data.referrals),
      map((referral: Referral) => {
        const referralClone = cloneDeep(referral || {});
        referralClone.affiliates = referralClone.affiliates || [];
        referralClone.affiliates.forEach(item => (item.payment_info = JSON.parse(item.payment_info || '[]')));
        return referralClone;
      }),
      tap(referral => {
        page += 1;
        currentReferral = referral;
        affiliates = affiliates.concat(referral.affiliates);
      }),
      delay(1000),
      switchMap(referral => (referral.affiliates.length < limit ? throwError('norepeat') : of())),
      repeat(),
      catchError(error => (error === 'norepeat' ? of({ ...currentReferral, affiliates }) : throwError(error)))
    );
  }
}
