import { tap } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';

/**
 * RxJS operator that detects an error on the usual success GraphQL response and throws it as an error to RxJS
 *
 * @example
 * ```
 * import { throwOnGraphqlError } from '@app/core';
 *
 * @Component({
 * selector: 'app-example',
 *   templateUrl: './example.component.html'
 * })
 * export class ExampleComponent implements OnInit {
 *   ngOnInit() {
 *     graphqlRequest()
 *       .pipe(() => throwOnGraphqlError())
 *       .subscribe(val => console.log(val), err => console.error(err);
 *   }
 * }
 * ```
 */
export function throwOnGraphqlError<T>() {
  return tap((response: ApolloQueryResult<T>) => {
    if (response.errors || response.data === null) {
      throw response;
    }
  });
}
