import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { PasswordInput } from '@app/models';
import { ErrorHandlerService, NotyService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = void;
// export interface ModalPublicProperties {}

@Component({
  selector: 'app-edit-password-modal',
  templateUrl: './edit-password-modal.component.html',
  styleUrls: ['./edit-password-modal.component.scss']
})
export class EditPasswordModalComponent implements AfterContentInit {
  form!: FormGroup;
  isLoading = false;
  @ViewChild('passwordInput', { static: false }) private passwordInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private notyService: NotyService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService
  ) {
    this.form = this.formBuilder.group({
      newPasswordInfo: this.formBuilder.group({ current: '', new: '', new_confirmation: '' } as PasswordInput)
    });
  }

  ngAfterContentInit() {
    setTimeout(() => this.passwordInput.nativeElement.focus());
  }

  updatePassword() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.userService
      .updateCurrentUserPassword(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        changeUserPassword => {
          if (changeUserPassword) {
            this.notyService.success(this.translateService.instant('success_change_password'));
          } else {
            this.notyService.error(this.translateService.instant('error_change_password'));
          }
          this.modal.close();
        },
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
