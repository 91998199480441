import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';

import { AppService, AppStorageKeys } from '@app/services/app.service';

// TODO: Alguma hora dessas, colocar essa ModalService em @app/services, já que ela está usando a AppService de lá

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private ngbModal: NgbModal, private appService: AppService) {}

  open<T>(component: any, properties?: { [k: string]: any }, options?: NgbModalOptions, throwErrorOnDismiss?: boolean) {
    return new Observable<T>(subscriber => {
      const newOptions = cloneDeep(options || {});
      if (this.appService.isDarkmode) {
        newOptions.windowClass = (newOptions.windowClass || '')
          .split(/\s+/)
          .concat(AppStorageKeys.Darkmode)
          .join(' ');
      }

      const modal = this.ngbModal.open(component, newOptions);
      Object.keys(properties || {}).forEach(key => (modal.componentInstance[key] = properties[key]));
      modal.result
        .then(data => subscriber.next(data))
        .catch(data => (throwErrorOnDismiss ? subscriber.error(data) : null))
        .finally(() => subscriber.complete());
    });
  }

  dismissAll(): void {
    this.ngbModal.dismissAll();
  }
}
