import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalService } from '@app/core/modal.service';
import { ModalPublicProperties, ModalResult, DocumentDeleteAlertModalComponent as ModalComponent } from './document-delete-alert-modal.component';

type CustomModalPublicProperties = ModalPublicProperties & { dismissAsError?: boolean };

@Injectable({ providedIn: 'root' })
export class DocumentDeleteAlertModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    const dismissAsError = properties.dismissAsError;
    delete properties.dismissAsError;
    return this.modalService.open<ModalResult>(ModalComponent, properties, options, dismissAsError);
  }
}
