import { Component, OnInit } from '@angular/core';
import { AppService } from '@app/services';

@Component({
  selector: 'app-menu-darkmode-button',
  templateUrl: './darkmode.component.html',
  styleUrls: ['./darkmode.component.scss']
})
export class DarkmodeComponent implements OnInit {
  constructor(public appService: AppService) {}

  ngOnInit() {}
}
