import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import * as intlTelInput from 'intl-tel-input';

import { PhoneService } from '@app/services';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true
    }
  ]
})
export class PhoneNumberComponent implements ControlValueAccessor, Validator, AfterViewInit, OnChanges {
  @Input() value: string;
  @Input() formControlName: string;
  @Input() name = '';
  @Input() inputClass = '';
  @Input() disabled = false;
  @Input() autocomplete = 'off';
  @Input() required = false;
  @Output() validationError = new EventEmitter<boolean>();
  partialValue: string;
  onChange: (_: string) => void;
  onTouch: () => void;
  onValidationChange: () => void;
  mask = '(00) 00000-0000';
  doesDataExist = false;
  @ViewChild('phoneInputElement', { static: false }) private phoneInputElement: ElementRef<HTMLInputElement>;
  private phoneInput: any;

  constructor() {
    this.onChange = () => {};
    this.onTouch = () => {};
    this.onValidationChange = () => {};
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check if the data exists before using it
    if (this.value) {
      this.doesDataExist = true;
    }
  }

  ngAfterViewInit() {
    const that = this;
    this.phoneInput = intlTelInput(this.phoneInputElement.nativeElement, {
      initialCountry: 'br',
      preferredCountries: ['br', 'pt', 'us'],
      autoHideDialCode: false,
      nationalMode: true,
      placeholderNumberType: 'MOBILE',
      autoPlaceholder: 'aggressive',
      customPlaceholder: (selectedCountryPlaceholder: string) => {
        that.transformMask(selectedCountryPlaceholder);
        return selectedCountryPlaceholder;
      },
      utilsScript: PhoneService.intlTelUtilsScript()
    });

    this.phoneInputElement.nativeElement.addEventListener('countrychange', () => this.setValue(this.partialValue));

    // setTimeout(() => {
    //   if (this.phoneInputModel.value) {
    //     this.phoneInputModel.control.markAllAsTouched();
    //   }
    // });
  }

  writeValue(value: string) {
    this.partialValue = value || '';
    if (this.doesDataExist) this.setValue(this.partialValue);
    if (this.partialValue) {
      this.onTouch();
    }
  }

  transformMask(placeholder: string) {
    this.mask = placeholder.replace(/([1-9])/g, '0');
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouch = fn;
  }

  registerOnValidatorChange(fn: () => void) {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  setValue(partialValue: string) {
    this.value = this.phoneInput ? this.phoneInput.getNumber() : partialValue || '';
    setTimeout(() => this.onChange(this.value));
    this.validationError.emit(!(!this.phoneInput || this.phoneInput.isValidNumber()));
  }

  validate(control: FormControl): ValidationErrors | null {
    return !this.phoneInput || !this.phoneInput.getNumber() || this.phoneInput.isValidNumber() ? null : { phoneError: { valid: false } };
  }
}
