import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isAuthenticated() && route.queryParams.loginRequired != 1) {
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate([route.queryParams.loginRequired && route.queryParams.loginRequired == 1 ? '/waitingroom' : '/entrar'], {
      replaceUrl: true,
      queryParams: { ...route.queryParams, loginRequired: undefined, redirect: state.url.split('?')[0] }
    });

    return false;
  }
}
