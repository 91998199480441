<div class="documentPreview">
  <h3 class="documentPreview__title"></h3>
  <p class="documentPreview__createdAt">{{ 'documents.createdIn' | translate }}</p>
  <div class="documentPreview__actions">
    <a class="documentPreview__viewDocument autentique-button" data-icon="&#xf35d;">{{ 'documents.viewDocument' | translate }}</a>
    <div class="documentPreview__options" appDropdown placement="bottom-right">
      <div appDropdownToggle>{{ 'documents.options' | translate }} <i class="fal fa-ellipsis-v"></i></div>
      <div appDropdownMenu></div>
    </div>
  </div>

  <main class="documentPreview__wrapper">
    <div class="documentPreview__wrapper-options">
      <div class="documentPreview__wrapper-option" [class.documentPreview__wrapper-option--selected]="sidebarContainer === 'signers'" (click)="changeSidebarContainer('signers')">
        <i class="fal fa-feather-alt"></i>{{ 'documents.signatories' | translate }}
      </div>
      <div class="documentPreview__wrapper-option" [class.documentPreview__wrapper-option--selected]="sidebarContainer === 'history'" (click)="changeSidebarContainer('history')">
        <i class="fal fa-history"></i>{{ 'documents.history' | translate }}
      </div>
    </div>
    <div class="sidebar-signers-list" *ngIf="sidebarContainer === 'signers'">
      <!--      <ng-container [ngTemplateOutlet]="signersTemplate"></ng-container>-->
    </div>
    <app-document-history-sidebar [class.d-none]="sidebarContainer !== 'history'" #historyComponent></app-document-history-sidebar>
  </main>

  <div class="documentPreview__close" (click)="modal.close()">{{ 'button.close' | translate }} <i class="fal fa-times"></i></div>
</div>
