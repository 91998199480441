import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { SharedModule } from '@app/shared';

import { MenuRoutingModule } from './menu-routing.module';

import { MainComponent } from './main/main.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { FoldersComponent } from './folders/folders.component';
import { DocumentsComponent } from './documents/documents.component';
import { FoldersGroupComponent } from './folders-group/folders-group.component';
import { FoldersOrganizationComponent } from './folders-organization/folders-organization.component';
import { DocumentsGroupComponent } from './documents-group/documents-group.component';
import { DocumentsOrganizationComponent } from './documents-organization/documents-organization.component';

import { HeaderComponent } from './shared/header/header.component';
import { MobileSignOutComponent } from './shared/mobile-sign-out/mobile-sign-out.component';
import { DarkmodeComponent } from './shared/darkmode/darkmode.component';
import { ChangeOrganizationComponent } from './change-organization/change-organization.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MainComponent,
    ConfigurationComponent,
    FoldersComponent,
    DocumentsComponent,
    HeaderComponent,
    FoldersGroupComponent,
    FoldersOrganizationComponent,
    DocumentsGroupComponent,
    DocumentsOrganizationComponent,
    MobileSignOutComponent,
    DarkmodeComponent,
    ChangeOrganizationComponent
  ],
  imports: [CommonModule, MenuRoutingModule, SharedModule, FormsModule, ReactiveFormsModule, FilterPipeModule, TranslateModule.forRoot()],
  exports: [DarkmodeComponent, HeaderComponent]
})
export class MenuModule {}
