import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { omit } from 'lodash';

import { Signer, DataSourceEnum } from '@app/models';
import { ErrorHandlerService, DocumentService, AppService } from '@app/services';

export type ModalResult = void;
export interface ModalPublicProperties {
  signer: Signer;
}

@Component({
  templateUrl: './consult-cpf-modal.component.html',
  styleUrls: ['./consult-cpf-modal.component.scss']
})
export class ConsultCPFModalComponent implements ModalPublicProperties, OnInit {
  @Input() signer: Signer;

  isLoading = false;
  isInvalid = false;
  isFinished = false;
  isOverWrited = false;

  isNameInvalid = false;
  isDateInvalid = false;

  dataSourceName = '';
  dataSourceDate = '';
  dataSourceVoucher = '';

  constructor(public modal: NgbActiveModal, public appService: AppService, private documentService: DocumentService, private errorHandlerService: ErrorHandlerService) {}

  ngOnInit() {
    this.consultCPF();
  }

  consultCPF() {
    this.isLoading = true;
    this.documentService
      .getDataSource({ cpf: this.signer.user.cpf, birthday: this.signer.user.birthday, cnpj: null, type: DataSourceEnum.Cpf })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFinished = true;
        })
      )
      .subscribe(
        response => {
          if (response) {
            this.dataSourceName = response.name;
            this.dataSourceDate = response.birthday;
            this.dataSourceVoucher = response.voucher;

            this.isNameInvalid = this.dataSourceName !== this.signer.user.name;
            this.isDateInvalid = this.dataSourceDate !== this.signer.user.birthday;

            if (this.signer.configs?.overwrite_name) this.isOverWrited = true;
          } else {
            this.isInvalid = true;
          }
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  update() {
    this.isLoading = true;

    this.documentService
      .overwriteSigner({
        publicId: this.signer.public_id,
        signer: {
          positions: (this.signer.positions || []).map(position => omit(position, ['__typename']) as any),
          configs: {
            overwrite_name: this.dataSourceName,
            overwrite_date: this.dataSourceDate
          }
        }
      })
      .subscribe(
        () => this.modal.close(),
        error => this.errorHandlerService.handle(error)
      );
  }
}
