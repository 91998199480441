import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blured-signer-card',
  templateUrl: './blured-signer-card.component.html',
  styleUrls: ['./blured-signer-card.component.scss']
})
export class BluredSignerCardComponent implements OnInit {
  @Input() isDarkmode: string;
  constructor() {}

  ngOnInit(): void {}
}
