import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { Referral } from '@app/models';
import { ErrorHandlerService, NotyService, ReferralService, UserService } from '@app/services';
import { LoaderService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-referrals',
  templateUrl: './configurations-referrals.component.html',
  styleUrls: ['../configurations.scss', './configurations-referrals.component.scss']
})
export class ConfigurationsReferralsComponent implements OnInit {
  referralsData: Referral;
  referralLink = '';
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  private readonly perPage = 50;

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private referralService: ReferralService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      if (user && !user.email) {
        this.notyService.error(this.translateService.instant('notyService.emailRequiredToAccessPage'), 10000);
        this.router.navigate(['/perfil']);
      }
    });

    this.loadReferrals();
  }

  loadReferrals(options: { page?: number } = {}) {
    this.isLoading = true;
    this.pageNumber = options.page || 1;
    this.referralService
      .all({ limit: this.perPage, page: this.pageNumber })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        referralsData => {
          if (referralsData && !this.referralsData) {
            this.referralsData = referralsData;
            this.referralLink = 'https://autentique.com.br/?r=' + referralsData.referral_code;
          }
          this.referralsData.affiliates = referralsData ? (this.pageNumber === 1 ? [] : this.referralsData.affiliates || []).concat(referralsData.affiliates) : null;
          this.isLastPage = !referralsData || referralsData.affiliates.length < this.perPage;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  encodeURI(url: string) {
    return encodeURIComponent(url || '');
  }

  paymentsSum(paymentInfo: any) {
    const n = (paymentInfo || []).map((item: any) => item.referral_amount).reduce((partialSum: number, current: number) => (parseFloat(partialSum as any) || 0) + (parseFloat(current as any) || 0), 0);
    return +n.toFixed(2);
  }

  copyReferralLink() {
    this.notyService.success(this.translateService.instant('notyService.referralLinkCopied'), 2000);
  }

  exportReferrals() {
    this.isLoading = true;
    this.loaderService.show();
    this.referralService
      .export()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(referral => {
        let text = 'Nome;Email;Cadastro;Total ganho;Plano;Contratação';
        referral.affiliates.forEach(affiliate => {
          const plan = affiliate.is_receiving_payments ? parseFloat((affiliate.payment_info[0] as any).amount || 0) : 0;
          const signedPlanAt = affiliate.payment_info[0] ? ((affiliate.payment_info[0] as any).created_at || '').slice(0, 19).replace('T', ' ') : '';
          const createdAt = (affiliate.created_at || '').slice(0, 19).replace('T', ' ');
          text += `\n${affiliate.full_name || ''};${affiliate.email || ''};${createdAt};${this.paymentsSum(affiliate.payment_info)};${plan};${signedPlanAt}`;
        });

        const file = new File([new Blob(['\uFEFF' + text], { type: 'text/csv; charset=utf-8' })], 'Indicacoes Autentique.csv', { type: 'text/csv; charset=utf-8' });
        const url = URL.createObjectURL(file);
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', file.name);
        document.body.appendChild(linkElement);
        linkElement.click();
        linkElement.remove();
        URL.revokeObjectURL(url);
      });
  }
}
