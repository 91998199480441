import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

import { ModalService } from '@app/core/modal.service';
import { ModalResult, ModalPublicProperties, RestoreLifecycleModalComponent as ModalComponent } from './restore-lifecycle-modal.component';

@Injectable({ providedIn: 'root' })
export class RestoreLifecycleModalService {
  constructor(private modalService: ModalService) {}

  open(properties: ModalPublicProperties, options?: NgbModalOptions) {
    return this.modalService.open<ModalResult>(ModalComponent, properties, { size: 'sm', ...(options || {}) });
  }
}
