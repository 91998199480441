import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from '@angular/router';

import { AuditComponent } from './audit.component';

export function auditUrlMatch(segments: UrlSegment[]): UrlMatchResult {
  if (location.hostname === 'valida.ae') {
    if (segments.length <= 1) {
      return { consumed: segments, ...(segments[0] && segments[0].path ? { posParams: { id: new UrlSegment(segments[0].path, {}) } } : {}) };
    } else {
      return { consumed: [new UrlSegment('404', {})] };
    }
  }
  return null;
}

const routes: Routes = [
  // Redireciona para a página de auditoria ao acessar com o domínio valida.ae
  { matcher: auditUrlMatch, component: AuditComponent, data: { title: 'Verificar documento' } },
  { path: 'verificar/:id', component: AuditComponent, data: { title: 'Verificar documento' } },
  { path: 'verificar', component: AuditComponent, data: { title: 'Verificar documento' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditRoutingModule {}
