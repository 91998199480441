import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ModalService } from '@app/core/modal.service';
import { ModalPublicProperties, ModalResult, VerificationIdAnalyzerModalComponent as ModalComponent } from './verification-id-analyzer-modal.component';

export type CustomModalPublicProperties = ModalPublicProperties & { dismissAsError?: boolean };

@Injectable({ providedIn: 'root' })
export class VerificationIdAnalyzerModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    return of(null as ModalResult).pipe(
      switchMap(() => {
        const dismissAsError = properties.dismissAsError;
        delete properties.dismissAsError;

        if (properties.verification && !properties.verification.verified_at && !properties.verification.payload?.failcode) {
          return this.modalService.open<ModalResult>(ModalComponent, properties, options, dismissAsError);
        } else {
          return of(properties.verification as ModalResult);
        }
      })
    );
  }
}
