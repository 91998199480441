import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { untilDestroyed } from '@app/core';
import { User } from '@app/models';
import { UserService, AuthenticationService, WhitelabelService } from '@app/services';

@Component({
  selector: 'app-mobile-header-secondary',
  templateUrl: './mobile-header-secondary.component.html',
  styleUrls: ['./mobile-header-secondary.component.scss']
})
export class MobileHeaderSecondaryComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() menuID: string;
  @Input() disablePageChange: boolean;
  @Output() goBack = new EventEmitter();
  currentUser: User;
  hasNewMenu: boolean;

  constructor(public whitelabelService: WhitelabelService, private location: Location, private router: Router, private authenticationService: AuthenticationService, private userService: UserService) {
    this.hasNewMenu = false;
  }

  ngOnInit() {
    this.hasNewMenu = this.menuID !== undefined;

    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  goBackClick() {
    this.goBack.emit();
    if (!this.disablePageChange) {
      this.location.back();
    }
  }

  ngOnDestroy() {}

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true }));
  }
}
