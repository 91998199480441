import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ClipboardModule } from 'ngx-clipboard';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { QRCodeModule } from 'angularx-qrcode';

import { AppDropdownModule } from './dropdown/dropdown.module';
import {
  Nl2brPipe,
  TruncatePipe,
  ShortDatePipe,
  LdatePipe,
  LdecimalPipe,
  LpercentPipe,
  LcurrencyPipe,
  SignatureNamePipe,
  SignatureNewStyleNamePipe,
  InitialsNamePipe,
  FilterHasValuePipe,
  PhonePipe,
  CepPipe,
  SafeUrlPipe,
  FilterSignersPipe,
  FilterPendingSignersPipe,
  FilterSignedSignersPipe,
  CpfCnpjPipe,
  DateTimePipe
} from './pipes';
import {
  FullNameDirective,
  ValidNameDirective,
  ValidEmailDirective,
  ForbiddenNamesDirective,
  ExistingNamesDirective,
  MatchCpfDirective,
  ValidCpfDirective,
  ValidCnpjDirective,
  ValidPhoneDirective,
  ValidEmailPhoneDirective
} from './validators';
import { DisableInputDirective, ShowPasswordDirective, TrimSpacesDirective } from './directives';
import { FormControlWrapComponent } from './form-control-wrap/form-control-wrap.component';
import { SwitchComponent } from './switch/switch.component';
import { SelectComponent } from './select/select.component';
import { SwitchTabsComponent } from './switch-tabs/switch-tabs.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColorInputComponent } from './color-input/color-input.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { LottieComponent } from './lottie/lottie.component';
import { LoaderComponent } from './loader/loader.component';
import { DocumentLoaderComponent } from './document-loader/document-loader.component';
import { AutenticatsComponent } from './autenticats/autenticats.component';
import { FloatingFieldsComponent } from './floating-fields/floating-fields.component';
import { SetMissingUserInfoModalComponent } from './set-missing-user-info-modal/set-missing-user-info-modal.component';
import { RepeatFloatingFieldModalComponent } from './floating-fields/repeat-floating-field-modal/repeat-floating-field-modal.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { DocumentsCountComponent } from './documents-count/documents-count.component';
import { NoltComponent } from './nolt/nolt.component';
import { HeaderComponent } from './header/header.component';
import { TaxonomyComponent } from './taxonomy/taxonomy.component';
import { DocumentLifecycleWarningComponent } from './document-lifecycle-warning/document-lifecycle-warning.component';
import { MobileHeaderMainComponent } from './mobile-header-main/mobile-header-main.component';
import { MobileHeaderMenuComponent } from './mobile-header-menu/mobile-header-menu.component';
import { MobileHeaderSecondaryComponent } from './mobile-header-secondary/mobile-header-secondary.component';
import { VersionUpdateModalComponent } from './version-update-modal/version-update-modal.component';
import { SmsLoginModalComponent } from './sms-login-modal/sms-login-modal.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { CreateOrganizationModalComponent } from './create-organization-modal/create-organization-modal.component';
import { CertificateModalComponent } from './certificate-modal/certificate-modal.component';
import { DocumentDeleteAlertModalComponent } from './document-delete-alert-modal/document-delete-alert-modal.component';
import { RestoreLifecycleModalComponent } from './restore-lifecycle-modal/restore-lifecycle-modal.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { ToSignatureStepsPipe } from './signer-card/to-signature-steps.pipe';
import { SignerCardComponent } from './signer-card/signer-card.component';
import { DocumentSidebarHeaderComponent } from './document-sidebar-header/document-sidebar-header.component';
import { TokenFieldComponent } from './token-field/token-field.component';
import { SearchComponent } from './search/search.component';
import { SmallLoaderComponent } from './small-loader/small-loader.component';
import { EmailVerifyComponent } from '@app/shared/email-verify/email-verify.component';
import { VerificationsCreditsCountComponent } from '@app/shared/verifications-credits-count/verifications-credits-count.component';
import { GoogleOauthComponent } from '@app/shared/google-oauth/google-oauth.component';
import { FooterPreviewComponent } from './footer-preview/footer-preview.component';
import { PaymentWarningComponent } from './payment-warning/payment-warning.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    RouterModule,
    ReactiveFormsModule,
    ColorChromeModule,
    TranslateModule,
    NgbModule,
    ClipboardModule,
    FilterPipeModule,
    AppDropdownModule,
    NgxMaskModule,
    QRCodeModule
  ],
  declarations: [
    ShortDatePipe,
    LdatePipe,
    LdecimalPipe,
    LpercentPipe,
    LcurrencyPipe,
    SignatureNamePipe,
    SignatureNewStyleNamePipe,
    InitialsNamePipe,
    FilterHasValuePipe,
    Nl2brPipe,
    TruncatePipe,
    PhonePipe,
    CepPipe,
    SafeUrlPipe,
    FilterSignersPipe,
    FilterPendingSignersPipe,
    FilterSignedSignersPipe,
    ToSignatureStepsPipe,
    CpfCnpjPipe,
    DateTimePipe,
    FullNameDirective,
    ValidNameDirective,
    ValidEmailDirective,
    ValidCpfDirective,
    ValidCnpjDirective,
    ValidPhoneDirective,
    ValidEmailPhoneDirective,
    ForbiddenNamesDirective,
    ExistingNamesDirective,
    MatchCpfDirective,
    DisableInputDirective,
    ShowPasswordDirective,
    TrimSpacesDirective,
    FormControlWrapComponent,
    SwitchComponent,
    SelectComponent,
    SwitchTabsComponent,
    CheckboxComponent,
    PhoneNumberComponent,
    ColorInputComponent,
    LottieComponent,
    LoaderComponent,
    DocumentLoaderComponent,
    AutenticatsComponent,
    FloatingFieldsComponent,
    PdfViewerComponent,
    DocumentsCountComponent,
    VerificationsCreditsCountComponent,
    PaymentWarningComponent,
    VersionUpdateModalComponent,
    AlertModalComponent,
    NoltComponent,
    SignerCardComponent,
    SignaturePadComponent,
    HeaderComponent,
    TaxonomyComponent,
    DocumentLifecycleWarningComponent,
    MobileHeaderMainComponent,
    MobileHeaderMenuComponent,
    MobileHeaderSecondaryComponent,
    SetMissingUserInfoModalComponent,
    SmsLoginModalComponent,
    CreateOrganizationModalComponent,
    RepeatFloatingFieldModalComponent,
    CertificateModalComponent,
    DocumentDeleteAlertModalComponent,
    RestoreLifecycleModalComponent,
    DocumentSidebarHeaderComponent,
    TokenFieldComponent,
    EmailVerifyComponent,
    GoogleOauthComponent,
    SearchComponent,
    SmallLoaderComponent,
    FooterPreviewComponent
  ],
  exports: [
    AppDropdownModule,
    ShortDatePipe,
    LdatePipe,
    LdecimalPipe,
    LpercentPipe,
    LcurrencyPipe,
    SignatureNamePipe,
    SignatureNewStyleNamePipe,
    InitialsNamePipe,
    FilterHasValuePipe,
    Nl2brPipe,
    TruncatePipe,
    PhonePipe,
    CepPipe,
    SafeUrlPipe,
    ToSignatureStepsPipe,
    FilterSignersPipe,
    FilterPendingSignersPipe,
    FilterSignedSignersPipe,
    CpfCnpjPipe,
    DateTimePipe,
    FullNameDirective,
    ValidNameDirective,
    ValidEmailDirective,
    ValidCpfDirective,
    ValidCnpjDirective,
    ValidPhoneDirective,
    ValidEmailPhoneDirective,
    ForbiddenNamesDirective,
    ExistingNamesDirective,
    MatchCpfDirective,
    DisableInputDirective,
    ShowPasswordDirective,
    TrimSpacesDirective,
    FormControlWrapComponent,
    SwitchComponent,
    SelectComponent,
    SwitchTabsComponent,
    CheckboxComponent,
    PhoneNumberComponent,
    ColorInputComponent,
    LottieComponent,
    LoaderComponent,
    DocumentLoaderComponent,
    AutenticatsComponent,
    FloatingFieldsComponent,
    PdfViewerComponent,
    DocumentsCountComponent,
    VerificationsCreditsCountComponent,
    PaymentWarningComponent,
    NoltComponent,
    SignerCardComponent,
    SignaturePadComponent,
    HeaderComponent,
    TaxonomyComponent,
    DocumentLifecycleWarningComponent,
    MobileHeaderMainComponent,
    MobileHeaderSecondaryComponent,
    MobileHeaderMenuComponent,
    DocumentSidebarHeaderComponent,
    TokenFieldComponent,
    EmailVerifyComponent,
    GoogleOauthComponent,
    SmallLoaderComponent,
    SearchComponent
  ],
  providers: [
    DatePipe,
    PercentPipe,
    DecimalPipe,
    CurrencyPipe,
    LdatePipe,
    Nl2brPipe,
    TruncatePipe,
    SignatureNamePipe,
    SignatureNewStyleNamePipe,
    InitialsNamePipe,
    PhonePipe,
    ShortDatePipe,
    FilterHasValuePipe,
    CepPipe,
    SafeUrlPipe,
    FilterSignersPipe,
    FilterPendingSignersPipe,
    FilterSignedSignersPipe,
    CpfCnpjPipe,
    DateTimePipe
  ],
  entryComponents: [
    LottieComponent,
    VersionUpdateModalComponent,
    AlertModalComponent,
    LoaderComponent,
    SetMissingUserInfoModalComponent,
    SmsLoginModalComponent,
    CreateOrganizationModalComponent,
    RepeatFloatingFieldModalComponent,
    CertificateModalComponent,
    DocumentDeleteAlertModalComponent,
    RestoreLifecycleModalComponent
  ]
})
export class SharedModule {}
