import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent {
  @Input() value: boolean;
  @Input() formControlName: string;
  @Input() name = '';
  @Input() disabled = false;
  @ViewChild('checkboxInput', { static: false }) input: ElementRef<HTMLInputElement>;
  checkboxId = 'checkbox-' + Math.floor(Math.random() * 10000);

  onChange: (_: boolean) => void;
  onTouch: () => void;

  constructor() {
    this.onChange = () => {};
    this.onTouch = () => {};
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  toggle() {
    this.value = !this.value;
  }
}
