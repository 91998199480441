<app-mobile-header-secondary title="{{ 'menu.customization' | translate }}"></app-mobile-header-secondary>

<ng-container *ngIf="contextMenu === contextMenuState.domainName">
  <app-whitelabel-domain-name [whitelabelData]="whitelabelData" (contextChange)="changeContext($event)" style="padding:0;" [currentUser]="currentUser" (goBack)="goBack(contextMenuState.default)">
  </app-whitelabel-domain-name>
</ng-container>

<ng-container *ngIf="contextMenu === contextMenuState.emailAdress">
  <app-whitelabel-email-adress
    [whitelabelData]="whitelabelData"
    (contextChange)="changeContext($event)"
    style="padding:0;"
    [currentUser]="currentUser"
    [sendgridDns]="sendgridDns"
    (goBack)="goBack(contextMenuState.default)"
  >
  </app-whitelabel-email-adress>
</ng-container>

<ng-container *ngIf="contextMenu === contextMenuState.signingPage">
  <app-whitelabel-signing-page
    (contextChange)="changeContext($event)"
    (goBack)="goBack(contextMenuState.default)"
    [whitelabelData]="whitelabelData"
    [mainColor]="whitelabelData.main_color || whitelabelService.mainColor"
    style="padding:0;"
    [currentUser]="currentUser"
  ></app-whitelabel-signing-page>
</ng-container>

<ng-container *ngIf="contextMenu === contextMenuState.signedDocument">
  <app-whitelabel-signed-document
    (contextChange)="changeContext($event)"
    (goBack)="goBack(contextMenuState.default)"
    [whitelabelData]="whitelabelData"
    style="padding:0;"
    [currentUser]="currentUser"
  ></app-whitelabel-signed-document>
</ng-container>

<div *ngIf="contextMenu === contextMenuState.default">
  <div class="d-flex flex-column flex-xl-row">
    <div class="configuration-plans-row">
      <article class="mb-4" *ngIf="currentUser">
        <div class="configuration-container">
          <div class="configuration-content">
            <p class="configuration-title">
              {{ 'whitelabel.customizeURL' | translate }} <span class="highlight">{{ currentUser?.organization?.name || ('whitelabel.organization' | translate) }}</span>
            </p>
            <p class="configuration-text">{{ 'whitelabel.urlCustomizationExplanation' | translate }}</p>
            <div class="container-submenu">
              <div class="submenu-background">
                <div class="submenu">
                  <div class="submenu-name">
                    <p class="submenu-name-title">{{ 'whitelabel.subdomainInAutentique' | translate }}</p>
                    <p *ngIf="!isLoading" class="submenu-name-description">{{ whitelabelData?.domain || 'acme.autentique.com.br' }}</p>
                    <div *ngIf="isLoading" class="animatePlaceholder-content" style="width:200px; height: 14px; border-radius: 3px;"></div>
                  </div>
                  <div class="submenu-button" (click)="changeContext(contextMenuState?.domainName)">
                    <p class="submenu-button-text">{{ 'button.edit' | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article class="mb-4" *ngIf="currentUser?.currentPermissions.change_whitelabel_oz">
        <div class="configuration-container">
          <div class="configuration-content">
            <p class="configuration-title">{{ 'whitelabel.customizeEmailSendingAddress' | translate }}</p>
            <p class="configuration-text" [innerHTML]="'whitelabel.useYourOwnEmail' | translate"></p>
            <div class="submenu-background">
              <div class="submenu">
                <div class="submenu-name">
                  <p class="submenu-name-title">{{ 'whitelabel.senderDomain' | translate }}</p>
                  <p *ngIf="!isLoading" class="submenu-name-description">{{ whitelabelData?.sendgrid_domain || 'sign.acme.com' }}</p>
                  <div *ngIf="isLoading" class="animatePlaceholder-content" style="width:200px; height: 14px; border-radius: 3px;"></div>
                </div>
                <div class="submenu-button" (click)="changeContext(contextMenuState.emailAdress)">
                  <p class="submenu-button-text">{{ 'button.edit' | translate }}</p>
                </div>
              </div>
              <div class="submenu pt-2">
                <div class="submenu-name">
                  <p class="submenu-name-title">{{ 'whitelabel.sendingEmailAddress' | translate }}</p>
                  <p *ngIf="!isLoading" class="submenu-name-description">
                    {{ whitelabelData?.sendgrid_email_user || ('whitelabel.signature' | translate) }}&#64;{{ whitelabelData?.sendgrid_domain || 'sign.acme.com' }}
                  </p>
                  <div *ngIf="isLoading" class="animatePlaceholder-content" style="width:200px; height: 14px; border-radius: 3px;"></div>
                </div>
              </div>
              <div class="submenu pt-2">
                <div *ngIf="sendgridDns; else noPlan" class="submenu-name">
                  <p class="submenu-name-title">{{ 'whitelabel.DNSRecords' | translate }}</p>
                  <p *ngFor="let dns of sendgridDns?.dns" class="submenu-name-description">
                    {{ dns.host }}
                    <i class="fa-regular fa-{{ dns.valid ? 'check-circle green' : 'times-circle red' }}"></i>
                  </p>
                </div>
                <ng-template #noPlan>
                  <div class="submenu-name">
                    <p class="submenu-name-title">{{ 'whitelabel.DNSRecords' | translate }}</p>
                    <p *ngIf="!isLoading" class="submenu-name-description">sign.acme.com <i class="fa-regular fa-check-circle green"></i></p>
                    <div *ngIf="isLoading" class="animatePlaceholder-content" style="width:200px; height: 14px; border-radius: 3px;"></div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article class="mb-4" *ngIf="currentUser?.currentPermissions.change_whitelabel_oz">
        <div class="configuration-container">
          <div class="configuration-content headerFlex">
            <div>
              <p class="configuration-title">{{ 'whitelabel.customizeSignaturePage' | translate }}</p>
              <p class="configuration-text">{{ 'whitelabel.customizeSignaturePageDescription' | translate }}</p>
            </div>
            <div class="configuration-generate" (click)="changeContext(contextMenuState.signingPage)">
              <button type="button" class="configuration-button">{{ 'button.edit' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="pageSize" *ngIf="!isLoading">
          <app-signing-page [whitelabelData]="whitelabelData" [isDarkmode]="isDarkmode"></app-signing-page>
        </div>
      </article>
    </div>

    <div class="configuration-plans-row">
      <article class="mb-4" *ngIf="currentUser?.currentPermissions.change_whitelabel_oz">
        <div class="configuration-container">
          <div class="configuration-content headerFlex">
            <div>
              <p class="configuration-title">{{ 'whitelabel.customizeSignedDocument' | translate }}</p>
              <p class="configuration-text">{{ 'whitelabel.customizeSignedDocumentDescription' | translate }}</p>
            </div>
            <div class="configuration-generate">
              <button type="button" class="configuration-button" (click)="changeContext(contextMenuState.signedDocument)">{{ 'button.edit' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="pageSize" *ngIf="!isLoading">
          <app-signed-document [whitelabelData]="whitelabelData"></app-signed-document>
        </div>
      </article>
    </div>
  </div>
</div>
