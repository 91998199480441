import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { filter, finalize } from 'rxjs/operators';

import { I18nService, untilDestroyed } from '@app/core';
import { User, EmailTemplate, EmailTemplates, EmailTemplateTypeEnum, EmailTemplatesInput } from '@app/models';
import { UserService, EmailTemplateService, ErrorHandlerService, NotyService } from '@app/services';
import { AlertModalService, LoaderService, SelectOption } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-configurations-organization-emails',
  templateUrl: './configurations-organization-emails.component.html',
  styleUrls: ['../configurations.scss', './configurations-organization-emails.component.scss']
})
export class ConfigurationsOrganizationEmailsComponent implements OnInit, OnDestroy, AfterViewInit {
  currentUser: User;
  emailTemplates: EmailTemplate[] = [];
  form: FormGroup;
  isLoading = false;
  isLastPage = false;
  isFormOpen = false;
  isNewTemplate = false;
  pageNumber: number;
  emailTemplateTypeEnum = EmailTemplateTypeEnum;
  emailSelectedType = EmailTemplateTypeEnum.Solicitation;
  selectedLang: string;
  languages: SelectOption[] = [];
  @ViewChild('textarea') textarea: ElementRef;

  readonly templates = {
    [EmailTemplates.Default]: { key: 'email_template_default', multiColor: false },
    [EmailTemplates.Classic]: { key: 'email_template_classic', multiColor: false },
    [EmailTemplates.Corporate]: { key: 'email_template_corporate', multiColor: true },
    [EmailTemplates.Clean]: { key: 'email_template_clean', multiColor: true }
  };
  readonly variables = ['nome', 'empresa', 'funcao', 'documento', 'identificador'];

  constructor(
    public userService: UserService,
    public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private alertModalService: AlertModalService,
    private emailTemplateService: EmailTemplateService,
    private notyService: NotyService,
    private i18nService: I18nService,
    private loaderService: LoaderService
  ) {
    this.languages =
      this.i18nService.supportedLanguagesAsSelectOptions.map(language => {
        return { ...language, key: language.key.replace('-', '') };
      }) || [];
    this.selectedLang = this.i18nService.language.replace('-', '');
    this.initForm();
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
      });

    this.getDefaultEmailTemplate(EmailTemplateTypeEnum.Solicitation);
    this.loadEmailTemplates();
  }

  ngAfterViewInit() {
    if (this.textarea) {
      this.autoResize(this.textarea.nativeElement);
    }
  }

  ngOnDestroy() {}

  initForm() {
    const languageForms = {};

    this.languages.forEach(lang => {
      languageForms[lang.key] = this.createLanguageForm();
    });

    this.form = this.formBuilder.group({
      id: null,
      email: this.formBuilder.group({
        name: null,
        type: [EmailTemplateTypeEnum.Solicitation],
        ...languageForms
      })
    });
  }

  createLanguageForm(): FormGroup {
    return this.formBuilder.group({
      title: null,
      text: null,
      subject: null,
      sender: null,
      template: null,
      image: null,
      footer: null,
      colors: this.formBuilder.array([new FormControl('#000000'), new FormControl('#000000')])
    });
  }

  loadEmailTemplates(options: { page?: number } = {}) {
    this.isLoading = true;
    this.pageNumber = options.page || 1;
    this.emailTemplateService
      .emailTemplates({ limit: 20, page: this.pageNumber })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        page => {
          this.emailTemplates = (this.pageNumber === 1 ? [] : this.emailTemplates || []).concat(page.data);
          this.isLastPage = page.current_page >= page.last_page;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  saveSignatureEmail() {
    this.form.markAllAsTouched();
    const isEdit = !!this.form.get('id').value;
    let hasColorError = false;
    const emailTemplatesInput = {
      enUS: null,
      ptBR: null,
      name: this.form.get('email.name').value,
      type: this.form.get('email.type').value
    } as EmailTemplatesInput;

    this.languages.forEach(lang => {
      const formGroup = this.form.get(`email.${lang.key}`) as FormGroup;

      if (this.form.dirty && formGroup) {
        const formValue = formGroup.getRawValue();

        if (formGroup.dirty) {
          emailTemplatesInput[lang.key] = {
            colors: formValue.colors,
            footer: formValue.footer,
            ...(typeof formValue.image !== 'string' && { image: formValue.image }),
            sender: formValue.sender,
            subject: formValue.subject,
            template: formValue.template,
            text: formValue.text,
            title: formValue.title
          };
        }

        if (
          (!emailTemplatesInput[lang.key]?.colors[0] || !emailTemplatesInput[lang.key]?.colors[1]) &&
          [EmailTemplates.Corporate, EmailTemplates.Clean].includes(emailTemplatesInput[lang.key]?.template)
        ) {
          hasColorError = true;
          return;
        }
      }
    });

    if (hasColorError) {
      this.notyService.error(this.translateService.instant('notyService.hexadecimalColorError'));
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.loaderService.show();

    // @ts-ignore
    this.emailTemplateService[isEdit ? 'update' : 'create'](isEdit ? { email: emailTemplatesInput, id: this.form.get('id').value } : { email: emailTemplatesInput })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        () => {
          this.isFormOpen = false;
          this.isNewTemplate = false;
          this.loadEmailTemplates();
          this.changeDefault(EmailTemplateTypeEnum.Solicitation);
          this.selectedLang = this.i18nService.language.replace('-', '');
        },
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }

  deleteEmailTemplate(emailTemplate: EmailTemplate) {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.deleteEmailTemplate'),
        confirmButtonText: this.translateService.instant('button.exclude')
      })
      .subscribe(() => {
        this.isLoading = true;
        this.emailTemplateService.delete({ id: emailTemplate.id }).subscribe(
          () => this.loadEmailTemplates(),
          error => {
            this.isLoading = false;
            this.errorHandlerService.handle(error);
          }
        );
      });
  }

  openCreateForm() {
    this.isFormOpen = true;
    this.isNewTemplate = true;
    Object.keys(this.form.controls).forEach(key => {
      if (key !== 'name' && key !== 'type') {
        this.form.get(key).enable();
      }
    });
    this.changeDefault();
  }

  openEditForm(emailTemplate: EmailTemplate) {
    this.isFormOpen = true;
    this.emailSelectedType = emailTemplate.type;
    Object.keys((this.form.get('email') as FormGroup).controls).forEach(langKey => {
      if (!['name', 'type'].includes(langKey)) {
        this.form.get(`email.${langKey}`).setValue({
          title: emailTemplate[langKey].title,
          text: emailTemplate[langKey].text,
          subject: emailTemplate[langKey].subject,
          sender: emailTemplate[langKey].sender,
          template: emailTemplate[langKey].template,
          image: emailTemplate[langKey].image,
          footer: emailTemplate[langKey].footer,
          colors: emailTemplate[langKey].colors || ['#3379F2', '#3379F2']
        });
      }
    });
    this.form.get('email.name').setValue(emailTemplate.name);
    this.form.get('email.type').setValue(emailTemplate.type);
    this.form.get('id').setValue(emailTemplate.id);
  }

  selectEmailImage(image: File) {
    if (image) {
      Object.keys((this.form.get('email') as FormGroup).controls).forEach(key => {
        if (!['id', 'name', 'type'].includes(key)) {
          this.form
            .get(`email.${key}`)
            .get('image')
            .setValue(image);
        }
      });
    }
  }

  addEmailVariableTo(variable: string, input: FormControl, model?: string) {
    const value = (input.value ? input.value + ' ' : '') + `{{${variable}}}`;

    if (model) {
      this[model] = value;
    }
    input.setValue(value);
  }

  autoResize(textArea: HTMLTextAreaElement): void {
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  changeDefault(emailType: any = EmailTemplateTypeEnum.Solicitation) {
    this.isLoading = true;
    this.getDefaultEmailTemplate(emailType)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(templates => {
        if (this.isNewTemplate) {
          this.form.get('id').setValue(null);
          this.form.get('email.name').setValue(null);
          this.form.get('email.type').setValue(emailType || EmailTemplateTypeEnum.Solicitation);
          this.languages.forEach(lang => {
            const template = templates[lang.key];
            if (template) {
              this.form.get(`email.${lang.key}`).setValue({
                title: template.title,
                text: template.text,
                subject: template.subject,
                sender: template.sender,
                template: template.template,
                image: template.image,
                footer: template.footer,
                colors: [template.colors[0] || '#3379F2', template.colors[1] || '#FFFFFF']
              });
            }
          });
        }
      });
  }

  getDefaultEmailTemplate(type: EmailTemplateTypeEnum): Observable<any> {
    return this.emailTemplateService.defaultEmailTemplates({ type });
  }

  get subject() {
    return this.form.get(`email.${this.selectedLang}`)?.get('subject')?.value || '';
  }

  set subject(value: string) {
    const form = this.form.get(`email.${this.selectedLang}`);
    form.markAsDirty();
    form?.get('subject')?.setValue(value);
  }

  get sender() {
    return this.form.get(`email.${this.selectedLang}`)?.get('sender')?.value || '';
  }

  set sender(value: string) {
    const form = this.form.get(`email.${this.selectedLang}`);
    form.markAsDirty();
    form?.get('sender')?.setValue(value);
  }
}
