<header class="mobile-header" [class.show-signers]="isShowingSignersMobile || isShowingHistoryMobile"
  *ngIf="document && whitelabelService.params.mobileNavigation">
  <a routerLink="/" class="header-brand" *ngIf="!isShowingSignersMobile && !isShowingHistoryMobile">
    <img [src]="documentWhitelabel.pdf_logo_mini" alt="" />
  </a>
  <a class="mobile-header__back" (click)="isShowingSignersMobile = false" *ngIf="isShowingSignersMobile">{{
    'button.back' | translate }}</a>
  <a class="mobile-header__back" (click)="isShowingHistoryMobile = false" *ngIf="isShowingHistoryMobile">{{
    'button.back' | translate }}</a>
  <span class="mobile-header__title" *ngIf="isShowingSignersMobile">{{ 'documents.signatories' | translate }}</span>
  <span class="mobile-header__title" *ngIf="isShowingHistoryMobile">{{ 'documents.history' | translate }}</span>
  <ng-container [ngTemplateOutlet]="documentDropdownTemplateMobile" *ngIf="document"></ng-container>
</header>

<ng-template #signersTemplate>
  <div *ngFor="let signer of document?.signatures | sortByCurrentSigners: document:currentSigners; let index = index"
    class="sidebar-signers-item"
    [class.pending]="document.sortable && index > documentService.getNextSignerIndex(document)"
    [class.to-sign-target]="document.sortable ? documentService.isSignerTurn(document, signer) : isCurrentSigner(signer)">
    <div class="sidebar-signers-order" *ngIf="document.sortable">
      <div class="sidebar-signers-order--num">{{ index + 1 }}</div>
    </div>
    <app-signer-card [signer]="signer" [sortable]="document.sortable" [isAllowedUser]="isAdmin() || isAutnq()"
      [isSignerTurn]="documentService.isSignerTurn(document, signer)"
      [forceAutenticat]="document?.signatures.length > 20" [documentAuthorId]="document.author.id"
      (updateClick)="openUpdateSignerModal($event)" (deleteClick)="deleteSigner($event)"
      (createLinkClick)="openSignLinkModal($event)" (consultCPFClick)="openConsultCPFModal($event)"
      (resendSignaturesClick)="resendSignatures($event)" (certificateClick)="openCertificateModal($event)"
      (verifyBiometryClick)="openVerificationDataModal($event)">
    </app-signer-card>
  </div>
</ng-template>

<div [ngClass]="{ darkmode: documentWhitelabel.is_darkmode }">
  <div class="body-holder"
    [class.to-sign]="(isSignatureByLink() || isSignaturePage()) && currentSigners[0] && documentService.isSignerPending(currentSigners[0], { ignoreEmailErrors: true })">
    <aside class="sidebar">
      <app-document-sidebar-header>
        <a class="back" [routerLink]="whitelabelService.params.mainPath || backLink">{{ backButtonLabel() }}</a>
      </app-document-sidebar-header>
      <main class="sidebar__wrapper">
        <div class="sidebar__wrapper-options">
          <div class="sidebar__wrapper-option"
            [class.sidebar__wrapper-option--selected]="sidebarContainer === 'signers'"
            (click)="changeSidebarContainer('signers')">
            <i class="fal fa-feather-alt"></i>{{ 'documents.signatories' | translate }}
          </div>
          <div class="sidebar__wrapper-option"
            [class.sidebar__wrapper-option--selected]="sidebarContainer === 'history'"
            (click)="changeSidebarContainer('history')">
            <i class="fal fa-history"></i>{{ 'documents.history' | translate }}
          </div>
        </div>
        <p class="sortable-alert" *ngIf="document?.sortable && sidebarContainer === 'signers'"><i
            class="fal fa-info-circle"></i> {{ 'documents.specificOrder' | translate }}</p>
        <div class="sidebar-signers-list" *ngIf="sidebarContainer === 'signers'">
          <ng-container [ngTemplateOutlet]="signersTemplate"></ng-container>
        </div>
        <app-document-history-sidebar [class.d-none]="sidebarContainer !== 'history'"
          #historyComponent></app-document-history-sidebar>
      </main>
    </aside>
    <div class="mobile-signers-list" *ngIf="isShowingSignersMobile">
      <ng-container [ngTemplateOutlet]="signersTemplate"></ng-container>
    </div>
    <div class="mobile-signers-list" [class.d-none]="!isShowingHistoryMobile">
      <app-document-history-sidebar #historyComponentMobile></app-document-history-sidebar>
    </div>
    <section class="document" [class.d-none]="isShowingSignersMobile || isShowingHistoryMobile">
      <div class="document-warning" *ngIf="document?.is_blocked">
        <div class="document-warning-title"><i class="fal fa-exclamation-triangle"></i>&nbsp; {{ 'audit.securityNotice'
          | translate }}</div>
        <div class="document-warning-descr">
          {{ 'audit.thisDocumentHasBeenBlockedDueToFraudSuspicion' | translate }}<br />
          {{ 'audit.forMoreInformationContactUsAt' | translate }} <a
            href="mailto:contato@autentique.com.br">contato@autentique.com.br</a>
        </div>
      </div>
      <div class="document-sandbox" *ngIf="document?.sandbox">
        <div class="document-sandbox-title">{{ 'audit.sandboxTestDocument' | translate }}</div>
        <div class="document-sandbox-descr">{{ 'audit.thisDocumentWasCreatedAsASandboxTestAndHasNoLegalValue' |
          translate }}</div>
      </div>

      <div class="document-head" *ngIf="document">
        <h2 class="document-head-name">{{ document.name }}</h2>
        <p class="document-head-date">
          {{ 'documents.createdOn' | translate: { date: document.created_at | dateTime: 'mediumDate' } }}
          <span *ngIf="document.expiration_at">
            ({{ 'documents.' + (documentService.isExpired(document) ? 'expiredOn' : 'expiresOn') | translate: { date:
            document.expiration_at | ldate: 'mediumDate' } }})
          </span>
        </p>
        <div class="document-head-attributes" *ngIf="isAdmin()">
          <div class="document-head-attributes--item" *ngIf="document.reminder">{{ 'documents.reminder.' +
            (document.reminder | lowercase) | translate }}</div>
          <div class="document-head-attributes--item" *ngIf="document.sortable">{{ 'documents.signatureOrder' |
            translate }}</div>
          <div class="document-head-attributes--item has-tooltip" *ngIf="document.cc && document.cc.length > 0"
            [ngbTooltip]="ccTooltip" placement="bottom">{{ 'documents.ccs' | translate }}</div>
          <div class="document-head-attributes--item" *ngIf="document.refusable">{{ 'documents.allowRefusal' | translate
            }}</div>
          <div class="document-head-attributes--item" *ngIf="document.qualified">{{ 'documents.qualifiedSignature' |
            translate }}</div>
          <div class="document-head-attributes--item" *ngIf="floatingFields && floatingFields.length > 0">{{
            'documents.visibleFields' | translate }}</div>

          <ng-template #ccTooltip>
            <div class="tooltip__descr" *ngFor="let item of document.cc">{{ item }}</div>
          </ng-template>
        </div>
        <ng-container [ngTemplateOutlet]="documentDropdownTemplate" *ngIf="document"></ng-container>
      </div>

      <app-pdf-viewer [pdfUrl]="!documentService.isPastLifecycle(document) && document?.files?.original"
        [iframePdfUrl]="documentService.isSignedOnce(document) ? (document.qualified ? documentService.padesDocumentUrl(document) : documentService.signedDocumentUrl(document)) : null"
        [isNewSignatureStyle]="document?.new_signature_style"
        [documentFooter]="document?.new_signature_style && document?.footer" [floatingFields]="floatingFields"
        [document]="document" [documentWhitelabel]="documentWhitelabel"
        [isDocumentWhitelabelLoaded]="isDocumentWhitelabelLoaded"
        [class.d-none]="documentService.isPastLifecycle(document)" #pdfViewer>
      </app-pdf-viewer>
      <app-document-lifecycle-warning [(document)]="document"
        *ngIf="documentService.isPastLifecycle(document)"></app-document-lifecycle-warning>

      <div class="document-action-holder"
        *ngIf="canBeSigned() && pdfViewer?.pagesCount() > 0 && isDocumentWhitelabelLoaded">
        <button type="button" class="document-action" [ngClass]="scrollingRequired() ? 'scroll-action' : 'refuse'"
          [disabled]="isLoading || scrollingRequired()" (click)="rejectDocumentPrompt()"
          *ngIf="document.refusable && documentService.isSignerTurn(document, currentSigners[0])">
          {{ 'button.reject' | translate }}
        </button>
        <button type="button" class="document-action" [ngStyle]="{
            backgroundColor: documentWhitelabel.main_color,
            boxShadow: '0 5px 25px ' + documentWhitelabel.main_color,
            color: appService.getContrastColor(documentWhitelabel.main_color)
          }" [ngClass]="scrollingRequired() ? 'scroll-action' : ''" [disabled]="isLoading || scrollingRequired()"
          (click)="signDocumentPrompt()"
          *ngIf="documentService.isSignerTurn(document, currentSigners[0]) && isDocumentWhitelabelLoaded">
          {{ 'signature.signAction.' + ((currentSigners[0].action && currentSigners[0].action.name) || 'SIGN' |
          lowercase) | translate }}
        </button>
        <div [ngClass]="scrollingRequired() ? 'scroll-warning' : 'd-none'">
          {{ 'documents.viewDocumentBeforeSigning' | translate }}
        </div>
        <button type="button" class="document-action wait" disabled
          *ngIf="!documentService.isSignerTurn(document, currentSigners[0])">{{ 'documents.notYourTurnToSignYet' |
          translate }}</button>
      </div>
      <div class="document-action-holder" *ngIf="needsElectronLogin() && pdfViewer?.pagesCount() > 0">
        <button type="button" class="document-action" (click)="redirectToLogin()">{{ 'documents.login' | translate
          }}</button>
      </div>
    </section>
  </div>
</div>

<ng-template #documentDropdownTemplate let-isMobile="isMobile">
  <div appDropdown display="dynamic" placement="bottom-right">
    <label id="document-dropdown-1" *ngIf="isMobile" appDropdownToggle><i class="fal fa-bars"></i></label>
    <ng-container *ngIf="!isMobile">
      <label id="document-dropdown-2" *ngIf="isAdmin(); else simpleDocumentDropdown" class="active" appDropdownToggle>{{
        'documents.options' | translate }} &nbsp;&nbsp;<i class="fal fa-lg fa-ellipsis-v"></i></label>
      <ng-template #simpleDocumentDropdown>
        <label id="document-dropdown-2" appDropdownToggle><i class="fal fa-lg fa-ellipsis-v"></i></label>
      </ng-template>
    </ng-container>

    <div [attr.aria-labelledby]="'document-dropdown-' + isMobile ? '1' : '2'" appDropdownMenu>
      <button type="button" *ngIf="isMobile" (click)="isShowingSignersMobile = true; changeSidebarContainer('signers')"
        appDropdownItem>
        <i class="fal fa-feather-alt"></i> {{ 'documents.signatories' | translate }}
      </button>
      <button type="button" *ngIf="isMobile" (click)="isShowingHistoryMobile = true; changeSidebarContainer('history')"
        appDropdownItem>
        <i class="fal fa-history"></i> {{ 'documents.history' | translate }}
      </button>
      <button type="button" appDropdownItem submenuToggle="document-dropdown-submenu1"
        aria-labelledby="document-dropdown-submenu1" [disabled]="documentService.isPastLifecycle(document)">
        <i class="fal fa-cloud-download-alt"></i> {{ 'documents.downloadFile' | translate }}
      </button>
      <button type="button" *ngIf="isAdmin() || isAutnq()"
        (click)="$any($event.target).classList.add('disabled'); resendSignatures()" appDropdownItem>
        <i class="fal fa-redo"></i> {{ 'documents.resendToSignatories' | translate }}
      </button>
      <button type="button" *ngIf="isAdmin()" appDropdownItem submenuToggle="document-dropdown-submenu3"
        aria-labelledby="document-dropdown-submenu3">
        <i class="fal fa-edit"></i> {{ 'documents.change' | translate }}
      </button>
      <div class="dropdown-divider"></div>
      <button type="button" *ngIf="isAdmin() || isAutnq()" (click)="clearPdfCache()" appDropdownItem><i
          class="fal fa-sync"></i>{{ 'documents.syncPDFData' | translate }}</button>
      <button type="button"
        *ngIf="(isAdmin() && documentService.isAllowedToResendWebhook(currentUser, document)) || isAutnq()"
        (click)="resendDocumentWebhook()" appDropdownItem>
        <i class="fal fa-webhook"></i>{{ 'button.webhookResend' | translate }}
      </button>
      <div *ngIf="isAdmin() || isAutnq()" class="dropdown-divider"></div>
      <button type="button" class="dropdown-item-switch"
        *ngIf="pdfViewer && !documentService.isPastLifecycle(document)">
        <label><app-switch class="d-inline" style="margin-left: -5px;"
            [(ngModel)]="pdfViewer.renderOnIframe"></app-switch>&nbsp; {{ 'documents.renderInBrowser' | translate
          }}</label>
      </button>
      <div *ngIf="isAutnq()" class="dropdown-divider"></div>
      <button type="button" *ngIf="isAutnq()" (click)="logout()" appDropdownItem><i class="fal fa-sign-out"></i>{{
        'documents.logoutOfAccount' | translate }}</button>
    </div>

    <div id="document-dropdown-submenu1" appDropdownSubmenu="document-dropdown-submenu1">
      <button type="button" appDropdownBackButton>{{ 'button.back' | translate }}</button>
      <ng-container *ngIf="!document.qualified">
        <a class="dropdown-item-descriptive" [href]="documentService.signedDocumentUrl(document)"
          [disabled]="!documentService.isSignedOnce(document)" target="_blank" appDropdownItem>
          <i class="fal fa-file-contract"></i>
          <div>
            <div class="dropdown-item-title">{{ 'documents.signedPDF' | translate }}</div>
            <div class="dropdown-item-description" [innerHTML]="'documents.signedPDFDescriptionLineBreak' | translate">
            </div>
          </div>
        </a>
        <a class="dropdown-item-descriptive" [href]="document.files?.certified"
          [disabled]="!documentService.isSignedOnce(document)" target="_blank" appDropdownItem>
          <i class="fal fa-file-certificate"></i>
          <div>
            <div class="dropdown-item-title">{{ 'documents.certifiedFile' | translate }}</div>
            <div class="dropdown-item-description"
              [innerHTML]="'documents.certifiedFileDescriptionLineBreak' | translate"></div>
          </div>
        </a>
      </ng-container>
      <a class="dropdown-item-descriptive" [href]="documentService.padesDocumentUrl(document)"
        [disabled]="!documentService.isSignedOnce(document)" target="_blank" *ngIf="document.qualified" appDropdownItem>
        <i class="fal fa-file-code"></i>
        <div>
          <div class="dropdown-item-title">{{ 'documents.padesFile' | translate }}</div>
          <div class="dropdown-item-description" [innerHTML]="'documents.padesFileDescriptionLineBreak' | translate">
          </div>
        </div>
      </a>
      <a class="dropdown-item-descriptive" [href]="document.files?.original" [disabled]="!document.files?.original"
        target="_blank" appDropdownItem>
        <i class="fal fa-file-alt"></i>
        <div>
          <div class="dropdown-item-title">{{ 'documents.originalFile' | translate }}</div>
          <div class="dropdown-item-description" [innerHTML]="'documents.originalFileDescriptionLineBreak' | translate">
          </div>
        </div>
      </a>
    </div>

    <div id="document-dropdown-submenu3" appDropdownSubmenu="document-dropdown-submenu3">
      <button type="button" appDropdownBackButton>{{ 'button.back' | translate }}</button>
      <a [routerLink]="['/documentos', document.id, 'campos']" class="dropdown-item-descriptive" appDropdownItem>
        <i class="fal fa-money-check-edit"></i>
        <div>
          <div class="dropdown-item-title">{{ 'documents.changeFields' | translate }}</div>
          <div class="dropdown-item-description"
            [innerHTML]="'documents.' + (document.qualified ? 'changeAddRemoveSignatureFieldsLineBreak' : 'changeAddRemoveSignatureFieldsAndInitialsLineBreak') | translate">
          </div>
        </div>
      </a>
      <button type="button" class="dropdown-item-descriptive" (click)="openCreateSignerModal()" appDropdownItem>
        <i class="fal fa-user-plus"></i>
        <div>
          <div class="dropdown-item-title">{{ 'documents.addSignatory' | translate }}</div>
          <div class="dropdown-item-description"
            [innerHTML]="'documents.addNewSignatoryByEmailOrLinkLineBreak' | translate"></div>
        </div>
      </button>
      <a [routerLink]="['/documentos', document.id, 'editar']" class="dropdown-item-descriptive" appDropdownItem>
        <i class="fal fa-edit"></i>
        <div>
          <div class="dropdown-item-title">{{ 'documents.changeAdvancedOptions' | translate }}</div>
          <div class="dropdown-item-description"
            [innerHTML]="'documents.changeAdvancedOptionsDescriptionLineBreak' | translate"></div>
        </div>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #documentDropdownTemplateMobile>
  <div (click)="isShowingMobileMenu = true" class="document-mobile__menu-toggle"
    [class.invisible]="isShowingSignersMobile || isShowingHistoryMobile"><i class="fal fa-bars"></i></div>
  <div
    (click)="isShowingMobileMenu = false; isShowingMobileMenuDownloads = false; isShowingMobileMenuAlteration = false"
    class="document-mobile__menu-overlay" [class.visible]="isShowingMobileMenu"></div>
  <div class="document-mobile__menu" [class.visible]="isShowingMobileMenu">
    <div class="group" *ngIf="pdfViewer?.pagesCount() > 0 && canBeSigned()">
      <div class="group__title">{{ 'documents.actions' | translate }}</div>
      <div class="group__options">
        <button class="group__option" [disabled]="isLoading" (click)="signDocumentPrompt()"
          *ngIf="documentService.isSignerTurn(document, currentSigners[0])">
          <div class="group__option-icon"><i class="fal fa-feather-alt"></i></div>
          <div class="group__option-name">
            {{ 'signature.signActionDocument.' + ((currentSigners[0].action && currentSigners[0].action.name) || 'SIGN'
            | lowercase) | translate }}
          </div>
        </button>
        <button class="group__option"
          *ngIf="document.refusable && documentService.isSignerTurn(document, currentSigners[0])"
          (click)="rejectDocumentPrompt()">
          <div class="group__option-icon" style="font-size: 22px"><i class="fal fa-times"></i></div>
          <div class="group__option-name">{{ 'documents.rejectTheDocument' | translate }}</div>
        </button>
      </div>
    </div>

    <div class="group">
      <div class="group__title">{{ 'documents.organization' | translate }}</div>
      <div class="group__options">
        <div class="group__option"
          (click)="isShowingSignersMobile = true; isShowingMobileMenu = false; changeSidebarContainer('signers')">
          <div class="group__option-icon"><i class="fal fa-feather-alt"></i></div>
          <div class="group__option-name">{{ 'documents.signatories' | translate }}</div>
        </div>
        <div class="group__option"
          (click)="isShowingHistoryMobile = true; isShowingMobileMenu = false; changeSidebarContainer('history')">
          <div class="group__option-icon"><i class="fal fa-history"></i></div>
          <div class="group__option-name">{{ 'documents.history' | translate }}</div>
        </div>
        <div class="group__option" [class.disabled]="documentService.isPastLifecycle(document)"
          (click)="isShowingMobileMenuDownloads = !documentService.isPastLifecycle(document)">
          <div class="group__option-icon"><i class="fal fa-cloud-download"></i></div>
          <div class="group__option-name">{{ 'documents.downloadFiles' | translate }}</div>
        </div>
        <button class="group__option" *ngIf="isAdmin() || isAutnq()"
          (click)="isShowingMobileMenu = false; $any($event.target).classList.add('disabled'); resendSignatures()">
          <div class="group__option-icon"><i class="fal fa-undo"></i></div>
          <div class="group__option-name">{{ 'documents.resendToSignatories' | translate }}</div>
        </button>
        <div class="group__option" *ngIf="isAdmin()" (click)="isShowingMobileMenuAlteration = true">
          <div class="group__option-icon"><i class="fal fa-edit"></i></div>
          <div class="group__option-name">{{ 'documents.change' | translate }}</div>
        </div>
        <div class="group__option" *ngIf="isAdmin()" (click)="isShowingMobileMenu = false; toggleBlock()">
          <div class="group__option-icon"><i
              class="fal fa-{{ documentService.isPastDeadline(document) ? 'lock-open' : 'lock' }}"></i></div>
          <div class="group__option-name">{{ 'documents.' + (documentService.isPastDeadline(document) ? 'unlock' :
            'lock') | translate }}</div>
        </div>
      </div>
    </div>

    <div class="group" *ngIf="isAdmin() || isAutnq()">
      <div class="group__title">{{ 'documents.others' | translate }}</div>
      <div class="group__options">
        <div class="group__option" (click)="clearPdfCache(); isShowingMobileMenu = false">
          <div class="group__option-icon"><i class="fal fa-sync"></i></div>
          <div class="group__option-name">{{ 'documents.syncPDFData' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="group">
      <div class="group__title">{{ 'documents.accountOptions' | translate }}</div>
      <div class="group__options">
        <div class="group__option" routerLink="/menu/configuracoes">
          <div class="group__option-icon"><i class="fal fa-cog"></i></div>
          <div class="group__option-name">{{ 'documents.settings' | translate }}</div>
        </div>
        <div class="group__option" *ngIf="isAutnq()" (click)="logout()">
          <div class="group__option-icon"><i class="fal fa-sign-out"></i></div>
          <div class="group__option-name">{{ 'documents.logout' | translate }}</div>
        </div>
      </div>
    </div>

    <div (click)="isShowingMobileMenuDownloads = false; isShowingMobileMenuAlteration = false"
      class="document-mobile__menu-overlay"
      [class.visible]="isShowingMobileMenuDownloads || isShowingMobileMenuAlteration"></div>
    <div class="document-mobile__menu document-mobile__menu--secondLevel"
      [class.visible]="isShowingMobileMenuDownloads">
      <div class="group">
        <div class="group__title">{{ 'documents.downloadFiles' | translate }}</div>
        <div class="group__options">
          <ng-container *ngIf="!document.qualified">
            <a class="group__option" [href]="documentService.signedDocumentUrl(document)"
              [class.disabled]="!documentService.isSignedOnce(document)" target="_blank">
              <div class="group__option-icon"><i class="fal fa-file-contract"></i></div>
              <div class="group__option-name">
                {{ 'documents.signedPDF' | translate }} <br />
                <span class="group__option-description">{{ 'documents.signedPDFDescription' | translate }}</span>
              </div>
            </a>
            <a class="group__option" [href]="document.files?.certified"
              [class.disabled]="!documentService.isSignedOnce(document)" target="_blank">
              <div class="group__option-icon"><i class="fal fa-file-certificate"></i></div>
              <div class="group__option-name">
                {{ 'documents.certifiedFile' | translate }}<br />
                <span class="group__option-description">{{ 'documents.certifiedFileDescription' | translate }}</span>
              </div>
            </a>
          </ng-container>

          <a class="group__option" *ngIf="document.qualified" [href]="documentService.padesDocumentUrl(document)"
            [class.disabled]="!documentService.isSignedOnce(document)" target="_blank">
            <div class="group__option-icon"><i class="fal fa-file-code"></i></div>
            <div class="group__option-name">
              {{ 'documents.padesFile' | translate }}<br />
              <span class="group__option-description">{{ 'documents.padesFileDescription' | translate }}</span>
            </div>
          </a>
          <a class="group__option" [href]="document.files?.original" [class.disabled]="!document.files?.original"
            target="_blank">
            <div class="group__option-icon"><i class="fal fa-file-alt"></i></div>
            <div class="group__option-name">
              {{ 'documents.originalFile' | translate }}<br />
              <span class="group__option-description">{{ 'documents.originalFileDescription' | translate }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="document-mobile__menu document-mobile__menu--secondLevel"
      [class.visible]="isShowingMobileMenuAlteration">
      <div class="group">
        <div class="group__title">{{ 'documents.change' | translate }}</div>
        <div class="group__options">
          <a class="group__option" [routerLink]="['/documentos', document.id, 'campos']">
            <div class="group__option-icon"><i class="fal fa-money-check-edit"></i></div>
            <div class="group__option-name">
              {{ 'documents.changeFields' | translate }} <br />
              <span class="group__option-description">{{ 'documents.' + (document.qualified ?
                'changeAddRemoveSignatureFields' : 'changeAddRemoveSignatureFieldsAndInitials') | translate }}</span>
            </div>
          </a>
          <button class="group__option" (click)="openCreateSignerModal()">
            <div class="group__option-icon"><i class="fal fa-user-plus"></i></div>
            <div class="group__option-name">
              {{ 'documents.addSignatory' | translate }} <br />
              <span class="group__option-description">{{ 'documents.addNewSignatoryByEmailOrLink' | translate }}</span>
            </div>
          </button>
          <a class="group__option" [routerLink]="['/documentos', document.id, 'editar']">
            <div class="group__option-icon"><i class="fal fa-edit"></i></div>
            <div class="group__option-name">
              {{ 'documents.changeAdvancedOptions' | translate }} <br />
              <span class="group__option-description">{{ 'documents.changeAdvancedOptionsDescription' | translate
                }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>