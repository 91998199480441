import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { EmailTemplateTypeEnum, OrganizationGroup } from '@app/models';
import { EmailTemplateService, ErrorHandlerService, OrganizationService } from '@app/services';
import { EmailTemplates } from 'src/generated/graphql.default';
import { uniqBy } from 'lodash';

export type ModalResult = OrganizationGroup;
export interface ModalPublicProperties {
  group: OrganizationGroup;
}

@Component({
  selector: 'app-create-organization-group-modal',
  templateUrl: './edit-organization-group-appearance-modal.component.html',
  styleUrls: ['./edit-organization-group-appearance-modal.component.scss']
})
export class EditOrganizationGroupAppearanceModalComponent implements ModalPublicProperties, AfterContentInit, OnInit {
  form!: FormGroup;
  group: OrganizationGroup;
  emailTemplates: Pick<EmailTemplates, 'id' | 'name' | 'type'>[] = [];
  isLoading = false;
  emailTemplateTypeEnum = EmailTemplateTypeEnum;

  emailSolicitation: number = null;
  emailSignatureCompleted: number = null;

  isOverwriteName: boolean;
  isOverWritingEmail: boolean;

  emailTemplateItens: any = {
    [EmailTemplateTypeEnum.Solicitation]: [{ key: null, value: 'Template padrão', height: 46, fontSize: 16 }],
    [EmailTemplateTypeEnum.SignatureCompleted]: [{ key: null, value: 'Template padrão', height: 46, fontSize: 16 }]
  };

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private emailTemplateService: EmailTemplateService,
    private organizationService: OrganizationService
  ) {
    this.form = this.formBuilder.group({
      styles: this.formBuilder.group({
        overwrite_email: false,
        overwrite_name: false,
        email_templates_ids: this.formBuilder.array([null, null]),
        company: '',
        cnpj: ''
      })
    });
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    const emailTemplateIds = this.group.email_templates.map(template => template.id);
    this.form.setValue({
      styles: {
        email_templates_ids: [emailTemplateIds[0] || null, emailTemplateIds[1] || null],
        overwrite_email: this.group.styles.overwrite_email,
        overwrite_name: this.group.styles.overwrite_name,
        company: this.group.company,
        cnpj: this.group.cnpj
      }
    });

    this.onChangeOverwriteName(this.form.get('styles.overwrite_name').value);
    this.onChangeOverwriteEmail(this.form.get('styles.overwrite_email').value);

    this.requestSlimTemplates(this.emailTemplateTypeEnum.SignatureCompleted);
    this.requestSlimTemplates(this.emailTemplateTypeEnum.Solicitation);
  }

  requestSlimTemplates(templateType: any) {
    this.isLoading = true;
    this.emailTemplateService.slimEmailTemplates({ page: 1, limit: 60, type: templateType }).subscribe(page => {
      this.emailTemplates = uniqBy([...this.emailTemplates, ...page.data, ...this.group.email_templates.filter(t => t.type === templateType)], 'id'); // this operator makes sure the current selected group.email_template always shows on the app-select list + 60 more from query, with uniq ID`s
      this.emailTemplateItens[templateType] = this.makeTemplateObject(templateType); //creates app-selector options based on type
      if (this.group.email_templates && this.emailTemplates.length > 0) {
        // changes initial value if group has previous template
        this.group.email_templates.forEach(groupTemplate => {
          if (groupTemplate.type === this.emailTemplateTypeEnum.SignatureCompleted && groupTemplate.type === templateType) {
            this.emailSignatureCompleted = groupTemplate.id;
          }
          if (groupTemplate.type === this.emailTemplateTypeEnum.Solicitation && groupTemplate.type === templateType) {
            this.emailSolicitation = groupTemplate.id;
          }
        });
      }
      this.isLoading = false;
    });
  }

  onChangeOverwriteEmail(value: boolean) {
    this.isOverWritingEmail = value;
  }

  onChangeOverwriteName(value: boolean) {
    this.isOverwriteName = !!value;
  }

  onChangeSelectedTemplateSignatureCompleted(value: EmailTemplates['id'] | null) {
    this.emailSignatureCompleted = value;
    this.form.patchValue({
      styles: {
        email_templates_ids: [this.emailSignatureCompleted, this.emailSolicitation]
      }
    });
  }

  onChangeSelectedTemplateSolicitation(value: EmailTemplates['id'] | null) {
    this.emailSolicitation = value;
    this.form.patchValue({
      styles: {
        email_templates_ids: [this.emailSignatureCompleted, this.emailSolicitation]
      }
    });
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    const emailTemplatesArray = this.form.get('styles.email_templates_ids') as FormArray; // removes any null from id`s array
    for (let i = emailTemplatesArray.length - 1; i >= 0; i--) {
      if (emailTemplatesArray.at(i).value === null) {
        emailTemplatesArray.removeAt(i);
      }
    }

    this.organizationService
      .updateGroupStyles({ ...this.form.getRawValue(), id: this.group.id })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }

  makeTemplateObject(type: any) {
    //creates app-select options for each EmailTemplateTypeEnum inside emailTemplateItens
    return [
      { key: null, value: 'Template padrão', height: 46, fontSize: 16 },
      ...this.emailTemplates
        .filter(template => template.type === type)
        .map(template => ({
          key: template.id,
          value: template.name,
          height: 46,
          fontSize: 16
        }))
    ];
  }
}
