import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Document, Signer } from '@app/models';
import { DocumentService, AppService, NotyService } from '@app/services';
import { SetMissingUserInfoModalService } from '@app/shared';

export type ModalResult = void;
export interface ModalPublicProperties {
  document: Document;
  currentSigner?: Signer;
}

@Component({
  selector: 'app-document-created-modal',
  templateUrl: './document-created-modal.component.html',
  styleUrls: ['./document-created-modal.component.scss']
})
export class DocumentCreatedModalComponent implements ModalPublicProperties {
  @Input() document: Document;
  @Input() currentSigner: Signer;

  constructor(
    public modal: NgbActiveModal,
    public appService: AppService,
    public documentService: DocumentService,
    public setMissingUserInfoModalService: SetMissingUserInfoModalService,
    private notyService: NotyService
  ) {}

  copySignatureLink(signer: string): void {
    this.notyService.success(`Link de ${signer} copiado!`, 2000);
  }
}
