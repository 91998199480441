import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalService } from '@app/core/modal.service';
import { ModalResult, ModalPublicProperties, EditPaymentInfoModalStep, EditPaymentInfoModalComponent as ModalComponent } from './edit-payment-info-modal.component';
import { cloneDeep } from 'lodash';

export { EditPaymentInfoModalStep };

@Injectable({ providedIn: 'root' })
export class EditPaymentInfoModalService {
  constructor(private modalService: ModalService) {}

  open(properties: ModalPublicProperties, options?: NgbModalOptions) {
    const newOptions = cloneDeep(options || {});
    newOptions.windowClass = (newOptions.windowClass || '')
      .split(/\s+/)
      .concat('sidemodal-right')
      .join(' ');
    return this.modalService.open<ModalResult>(ModalComponent, properties, newOptions);
    // return this.modalService.open<ModalResult>(ModalComponent, properties, { size: 'lg', ...(options || {}) });
  }
}
