import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { flattenDeep } from 'lodash';

import { ModalService } from '@app/core/modal.service';
import { PositionElementEnum, SignatureAppearanceEnum } from '@app/models';
import { ModalPublicProperties, ModalResult, ChooseSignatureModalComponent as ModalComponent } from './choose-signature-modal.component';

export type CustomModalPublicProperties = ModalPublicProperties & { dismissAsError?: boolean };

@Injectable({ providedIn: 'root' })
export class ChooseSignatureModalService {
  constructor(private modalService: ModalService) {}

  open(properties?: CustomModalPublicProperties, options?: NgbModalOptions) {
    return of(null as ModalResult).pipe(
      switchMap(() => {
        const dismissAsError = properties.dismissAsError;
        delete properties.dismissAsError;

        if (typeof properties.disableInitials !== 'boolean') {
          const ignorePositions = !(properties.signers || []).find(signer => typeof signer.positions !== 'undefined'); // positions são undefined na listagem, então pede rúbrica de qualquer forma
          const allPositionTypes = ignorePositions ? [PositionElementEnum.Initials] : flattenDeep((properties.signers || []).map(signer => (signer.positions || []).map(position => position.element)));
          properties.disableInitials =
            !properties.user ||
            !properties.document ||
            !allPositionTypes.includes(PositionElementEnum.Initials) ||
            // Abre modal somente na primeira vez que precisar de rúbrica. Mas se o documento forçar um tipo (signature_appearance), precisa garantir que o tipo forçado não fique em branco.
            (properties.user.settings.has_initials_appearance && [SignatureAppearanceEnum.Image, SignatureAppearanceEnum.Draw].includes(properties.document.configs.signature_appearance)
              ? (properties.document.configs.signature_appearance === SignatureAppearanceEnum.Image && !!properties.user.settings.initials) ||
                (properties.document.configs.signature_appearance === SignatureAppearanceEnum.Draw && !!properties.user.settings.initials_draw)
              : properties.user.settings.has_initials_appearance);
        }

        if (typeof properties.disableSignature !== 'boolean') {
          properties.disableSignature =
            !properties.user ||
            !properties.document ||
            // Abre modal somente na primeira vez que precisar de assinatura. Mas se o documento forçar um tipo (signature_appearance), precisa garantir que o tipo forçado não fique em branco.
            (properties.user.settings.has_signature_appearance && [SignatureAppearanceEnum.Image, SignatureAppearanceEnum.Draw].includes(properties.document.configs.signature_appearance)
              ? (properties.document.configs.signature_appearance === SignatureAppearanceEnum.Image && !!properties.user.settings.signature) ||
                (properties.document.configs.signature_appearance === SignatureAppearanceEnum.Draw && !!properties.user.settings.signature_draw)
              : properties.user.settings.has_signature_appearance);
          properties.disableSignature = properties.disableSignature && properties.disableInitials; // Não deixa mostrar o campo de rúbrica sozinho
        }

        return properties.disableSignature && properties.disableInitials
          ? of(null as ModalResult)
          : this.modalService.open<ModalResult>(ModalComponent, properties, { size: 'lg', ...options }, dismissAsError);
      })
    );
  }
}
