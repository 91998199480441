import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Whitelabel } from '@app/models';
import { AppService, WhitelabelService } from '@app/services';

@Component({
  selector: 'app-signing-page',
  templateUrl: './signing-page.component.html',
  styleUrls: ['./signing-page.component.scss']
})
export class SigningPageComponent implements OnChanges {
  @Input() mainColor: string;
  @Input() isDarkmode: boolean;
  @Input() whitelabelData: Whitelabel;
  @Input() selectedLogoImageUrl: string | null = null;
  logo: string;

  constructor(public appService: AppService, public whitelabelService: WhitelabelService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.whitelabelData && changes.whitelabelData.currentValue) {
      this.logo = this.whitelabelData.logo;
      this.mainColor = this.whitelabelData.main_color || this.whitelabelService.mainColor;
    }
  }
}
