import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { merge } from 'lodash';

import { slideDownUpTrigger } from '@app/core/animations';
import { AppService, ErrorHandlerService, FolderService, UserService, WhitelabelService } from '@app/services';
import { ContextEnum, Folder, ListFormat, User } from '@app/models';
import { FoldersListingService } from '../shared/folders-listing.service';

@Component({
  selector: 'app-folders-organization-list',
  templateUrl: './folders-organization-list.component.html',
  styleUrls: ['./folders-organization-list.component.scss'],
  animations: [slideDownUpTrigger]
})
export class FoldersOrganizationListComponent implements OnInit {
  ListFormat = ListFormat;
  folders: Folder[];
  currentUser: User;
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  searchQuery: any;
  mobileSearch = '';
  isSearchOpen = false;
  openTaxonomyMenu = false;

  constructor(
    public whitelabelService: WhitelabelService,
    private appService: AppService,
    private userService: UserService,
    private folderService: FolderService,
    private foldersListingService: FoldersListingService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      if (!user || user.currentPermissions.view_folders_oz) {
        this.currentUser = user;
        this.loadFolders();
      } else {
        this.userService.permissionCheck(user.currentPermissions.view_folders_oz);
      }
    });
  }

  loadFolders(options: { page?: number; search?: any } = {}) {
    this.isLoading = true;
    this.pageNumber = options.page || 1;
    this.folderService
      .folders(
        {
          context: ContextEnum.Organization,
          ...(options.search || {}),
          limit: 60,
          page: this.pageNumber,
          orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection }
        },
        this.pageNumber === 1 ? 'cache-and-network' : 'no-cache'
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        page => {
          this.folders = (this.pageNumber === 1 ? [] : this.folders || []).concat(page.data);
          this.isLastPage = page.current_page >= page.last_page;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  openFoldersModal(folder: Folder) {
    this.foldersListingService.openFoldersModal(this.folders, folder).subscribe();
  }

  deleteFolder(folder: Folder) {
    this.foldersListingService.delete(this.folders, folder).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  openCreateFolderModal() {
    this.foldersListingService.openCreateFolderModal(ContextEnum.Organization).subscribe(
      folder => this.folders.unshift(folder),
      error => this.errorHandlerService.handle(error)
    );
  }

  openUpdateFolderModal(folder: Folder) {
    this.foldersListingService.openUpdateFolderModal(folder).subscribe(newFolder => merge(folder, newFolder));
  }

  clearFilters() {
    this.mobileSearch = '';
    this.searchQuery = null;
    this.isSearchOpen = false;
    this.loadFolders({ page: 1 });
  }

  toggleSearch(value: boolean) {
    this.isSearchOpen = value;
    if (this.isSearchOpen) {
      this.searchQuery = {};
    } else {
      this.clearFilters();
    }
  }
}
