import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { untilDestroyed } from '@app/core';
import { User } from '@app/models';
import { UserService, AuthenticationService } from '@app/services';

@Component({
  selector: 'app-mobile-header-menu',
  templateUrl: './mobile-header-menu.component.html',
  styleUrls: ['./mobile-header-menu.component.scss']
})
export class MobileHeaderMenuComponent implements OnInit, OnDestroy {
  @Input() backButtonText = 'voltar';
  currentUser: User;
  @ViewChild(NgbDropdown, { static: false }) private dropdown: NgbDropdown;

  constructor(public userService: UserService, private location: Location, private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnDestroy() {}

  backClicked() {
    this.location.back();
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true }));
  }
}
