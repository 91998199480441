import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-organization-permissions-form',
  templateUrl: './organization-permissions-form.component.html',
  styleUrls: ['./organization-permissions-form.component.scss']
})
export class OrganizationPermissionsFormComponent implements OnInit {
  @Input() form!: FormGroup;

  constructor() {}

  ngOnInit() {}
}
