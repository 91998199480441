import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertButtonType } from '@app/models';
import { TranslateService } from '@ngx-translate/core';

export enum AlertType {
  confirmation = 'confirmation',
  warning = 'warning',
  warningPayment = 'warning-payment'
}
export type AlertModalOrderItem = {
  title: string;
  price?: string;
};
export type ModalResult = AlertButtonType;
export interface ModalPublicProperties {
  text: string;
  title?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
  creditsPrice?: number;
  orderItems?: AlertModalOrderItem[];
  bottomInfoTexts?: string[];
}

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements ModalPublicProperties {
  @Input() type: AlertType = AlertType.confirmation;
  @Input() text: string;
  @Input() title = this.translateService.instant('button.attention');
  @Input() confirmButtonText = this.translateService.instant('button.ok');
  @Input() closeButtonText = this.translateService.instant('button.close');
  @Input() creditsPrice = 0;
  @Input() orderItems: AlertModalOrderItem[] = [];
  @Input() bottomInfoTexts: string[] = [];
  readonly AlertButtonType = AlertButtonType;

  constructor(public modal: NgbActiveModal, private sanitizer: DomSanitizer, private translateService: TranslateService) {}

  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
