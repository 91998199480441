import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { Signer } from '@app/models';
import { ErrorHandlerService, DocumentService } from '@app/services';
import { UpdateSignerMutation } from 'src/generated/graphql.default';

export type ModalResult = UpdateSignerMutation['changeSignature'];
export interface ModalPublicProperties {
  signer: Signer;
}

@Component({
  selector: 'app-update-signer-modal',
  templateUrl: './update-signer-modal.component.html',
  styleUrls: ['./update-signer-modal.component.scss']
})
export class UpdateSignerModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() signer: Signer;
  form!: FormGroup;
  isLoading = false;
  @ViewChild('emailInput', { static: false }) private emailInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private documentService: DocumentService, private errorHandlerService: ErrorHandlerService) {
    this.form = this.formBuilder.group({ signature: this.formBuilder.group({ email: '' }) });
  }

  ngAfterContentInit() {
    this.form.get('signature.email').setValue(this.signer.email || '');
    setTimeout(() => this.emailInput.nativeElement.focus());
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.documentService
      .updateSigner({ publicId: this.signer.public_id, signer: this.form.get('signature').value })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
