import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blured-event',
  templateUrl: './blured-event.component.html',
  styleUrls: ['./blured-event.component.scss']
})
export class BluredEventComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
