import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Output } from '@angular/core';
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { fromEvent, of } from 'rxjs';

@Directive({
  selector: '[resizable]'
})
export class ResizableDirective {
  @Output()
  readonly resizable = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown').pipe(
    tap(e => e.preventDefault()),
    switchMap(() => {
      const element = this.elementRef.nativeElement.closest('th');
      if (element) {
        const { width, right } = element.getBoundingClientRect();
        return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
          map(({ clientX }) => width + clientX - right),
          distinctUntilChanged(),
          takeUntil(fromEvent(this.documentRef, 'mouseup'))
        );
      } else {
        return of(null);
      }
    })
  );
  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef)
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}
}
