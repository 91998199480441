<div class="modal-body">
  <p class="centermodal__title">
    {{ 'shared.updateAvailable' | translate }}
  </p>
  <p class="centermodal__description">
    {{ 'shared.updateNotice' | translate }}
  </p>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" (click)="modal.close()">{{ 'button.refresh' | translate }}</button>
  </div>
</div>
