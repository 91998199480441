import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Document, User } from '@app/models';
import { DocumentService } from '@app/services';
import { Router } from '@angular/router';
import { DocumentHistoryComponent } from '@app/pages/documents/documents-show/document-history-sidebar/document-history-sidebar.component';

export interface ModalPublicProperties {
  currentUser: User;
}

@Component({
  selector: 'app-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss']
})
export class DocumentPreviewModalComponent implements ModalPublicProperties, OnInit {
  @Input() currentUser: User;
  @Input() documentId: string;
  documentData: Document | any;
  isLoading = false;
  isHistoryAlreadyOpened = false;
  sidebarContainer: 'signers' | 'history' = 'signers';

  @ViewChild('historyComponent', { static: false }) historyComponent: DocumentHistoryComponent;

  constructor(public modal: NgbActiveModal, private router: Router, private documentService: DocumentService) {}

  ngOnInit(): void {
    // this.documentService
    //   .signatureUnattachedDataForDocumentPreview({ documentsId: this.documentId, userId: this.currentUser.id })
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((response: any) => {
    //     this.documentData = response;
    //   });
  }

  goToPayment(): void {
    this.router.navigate(['/perfil/planos']);
    this.modal.close();
  }

  changeSidebarContainer(container: 'signers' | 'history') {
    if (container === 'signers') this.sidebarContainer = 'signers';
    if (container === 'history') {
      if (this.historyComponent.documentData.id == null) {
        this.historyComponent.documentData = {
          id: this.documentData.document.id,
          created_at: this.documentData.document.created_at,
          author: {
            name: this.documentData.document.author.name,
            email: this.documentData.document.author.email
          }
        };
        this.historyComponent.refreshHistory();
      }

      this.sidebarContainer = 'history';
      this.isHistoryAlreadyOpened = true;
    }
  }
}
