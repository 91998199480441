import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { EmailTemplateTypeEnum, OrganizationGroup, OrganizationGroupPermissions, User } from '@app/models';
import { EmailTemplateService, ErrorHandlerService, NotyService, OrganizationService, UserService } from '@app/services';
import { EmailTemplates } from 'src/generated/graphql.default';
import { AlertModalService } from '@app/shared';
import { LcurrencyPipe } from '@app/shared/pipes';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';

export type ModalResult = OrganizationGroup;

enum FormSteps {
  name,
  permissions,
  appearance
}

@Component({
  selector: 'app-create-organization-group-modal',
  templateUrl: './create-organization-group-modal.component.html',
  styleUrls: ['./create-organization-group-modal.component.scss']
})
export class CreateOrganizationGroupModalComponent implements AfterContentInit {
  form!: FormGroup;
  currentUser: User;
  formStep = FormSteps.name;
  emailTemplates: Pick<EmailTemplates, 'id' | 'name' | 'type'>[] = [];
  totalGroups: number;
  isLoading = false;
  emailTemplateTypeEnum = EmailTemplateTypeEnum;

  isOverwriteName: boolean;
  isOverWritingEmail: boolean;

  emailSolicitation: number = null;
  emailSignatureCompleted: number = null;

  emailTemplateItens: any = {
    [EmailTemplateTypeEnum.Solicitation]: [{ key: null, value: 'Template padrão', height: 46, fontSize: 16 }],
    [EmailTemplateTypeEnum.SignatureCompleted]: [{ key: null, value: 'Template padrão', height: 46, fontSize: 16 }]
  };

  readonly FormSteps = FormSteps;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private alertModalService: AlertModalService,
    private notyService: NotyService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private emailTemplateService: EmailTemplateService,
    private lcurrencyPipe: LcurrencyPipe,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      group: this.formBuilder.group({ name: '', overwrite_template: false, overwrite_template_group: false }),
      permissions: this.formBuilder.group({
        overwrite_permissions: true,
        create_documents: true,
        archive_documents: true,
        delete_documents: true,
        sign_documents: true,
        view_documents_gr: true,
        view_folders_gr: true,
        actions_documents_gr: false,
        actions_folders_gr: false,
        actions_templates_gr: false,
        view_documents_oz: true,
        view_folders_oz: true,
        view_member_documents_oz: false,
        view_member_folders_oz: false,
        view_group_documents_oz: false,
        view_group_folders_oz: false,
        view_invoices_oz: true,
        actions_documents_oz: false,
        actions_folders_oz: false,
        actions_members_oz: false,
        actions_groups_oz: false,
        actions_webhooks_oz: false,
        change_appearances_oz: false,
        change_plan_oz: false
        // change_whitelabel_oz: false // TO-DO: ACTIVATE WHEN WHITELABEL LAUNCHES
      } as OrganizationGroupPermissions),
      styles: this.formBuilder.group({
        overwrite_email: true,
        overwrite_name: false,
        email_templates_ids: this.formBuilder.array([null, null]),
        company: '',
        cnpj: ''
      })
    });

    this.userService
      .getCurrentUser({ fetchPolicy: 'cache-first' })
      .pipe(
        tap(user => (this.currentUser = user)),
        switchMap(user => this.organizationService.organizationGroupsTotal({ organizationId: user.organization.id }))
      )
      .subscribe(total => (this.totalGroups = total));
  }

  ngAfterContentInit() {
    this.requestSlimTemplates(this.emailTemplateTypeEnum.SignatureCompleted);
    this.requestSlimTemplates(this.emailTemplateTypeEnum.Solicitation);

    this.onChangeOverwriteName(this.form.get('styles.overwrite_name').value);
    this.onChangeOverwriteEmail(this.form.get('styles.overwrite_email').value);
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  requestSlimTemplates(templateType: any) {
    this.isLoading = true;
    this.emailTemplateService.slimEmailTemplates({ page: 1, limit: 60, type: templateType }).subscribe(page => {
      this.emailTemplates = uniqBy([...this.emailTemplates, ...page.data], 'id'); // this operator makes sure the current selected group.email_template always shows on the app-select list + 60 more from query, with uniq ID`s
      this.emailTemplateItens[templateType] = this.makeTemplateObject(templateType); //creates app-selector options based on type
      this.isLoading = false;
    });
  }

  onChangeOverwriteEmail(value: boolean) {
    this.isOverWritingEmail = value;
  }

  onChangeOverwriteName(value: boolean) {
    this.isOverwriteName = !!value;
  }

  onChangeSelectedTemplateSignatureCompleted(value: EmailTemplates['id'] | null) {
    this.emailSignatureCompleted = value;
    this.form.patchValue({
      styles: {
        email_templates_ids: [this.emailSignatureCompleted, this.emailSolicitation]
      }
    });
  }

  onChangeSelectedTemplateSolicitation(value: EmailTemplates['id'] | null) {
    this.emailSolicitation = value;
    this.form.patchValue({
      styles: {
        email_templates_ids: [this.emailSignatureCompleted, this.emailSolicitation]
      }
    });
  }

  create() {
    if (typeof this.totalGroups !== 'number') {
      this.modal.dismiss();
      return;
    }

    const emailTemplatesArray = this.form.get('styles.email_templates_ids') as FormArray; // removes any null from id`s array
    for (let i = emailTemplatesArray.length - 1; i >= 0; i--) {
      if (emailTemplatesArray.at(i).value === null) {
        emailTemplatesArray.removeAt(i);
      }
    }

    let alertModal$ = of(null);
    if (this.totalGroups >= this.organizationService.maxFreeGroups) {
      if (!this.currentUser?.subscription?.has_premium_features) {
        this.notyService.error(this.translateService.instant('notyService.needCorporatePlanForMoreGroups', { maxFreeGroups: this.organizationService.maxFreeGroups }));
        return;
      }

      alertModal$ = this.alertModalService.warningPayment({
        title: this.translateService.instant('alerts.aboutToPurchaseSomething'),
        text: this.translateService.instant('alerts.additionalGroupChargeNotice', {
          groupPrice: this.lcurrencyPipe.transform(this.organizationService.additionalGroupPrice, 'R$')
        }),
        confirmButtonText: this.translateService.instant('button.createGroup'),
        closeButtonText: this.translateService.instant('button.cancel'),
        orderItems: [
          {
            title: this.translateService.instant('alerts.additionalGroupPurchased'),
            price: this.translateService.instant('alerts.groupPricePerMonth', {
              groupPrice: this.lcurrencyPipe.transform(this.organizationService.additionalGroupPrice, 'R$')
            })
          }
        ],
        bottomInfoTexts: [this.translateService.instant('alerts.additionalGroupExclusionNotice')]
      });
    }
    alertModal$.subscribe(() => {
      this.isLoading = true;
      this.form.markAllAsTouched();
      this.organizationService
        .createGroup(this.form.getRawValue())
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          data => this.modal.close(data as ModalResult),
          error => {
            this.formStep = FormSteps.name;
            this.errorHandlerService.handleValidation(this.form, error);
          }
        );
    });
  }

  makeTemplateObject(type: any) {
    //creates app-select options for each EmailTemplateTypeEnum inside emailTemplateItens
    const obj: any = [{ key: null, value: 'Template padrão', height: 46, fontSize: 16 }];
    this.emailTemplates.forEach(template => {
      if (template.type === type) {
        obj.push({ key: template.id, value: template.name, height: 46, fontSize: 16 });
      }
    });
    return obj;
  }
}
