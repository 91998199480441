<ng-container [formGroup]="form">
  <div class="centermodal__group">
    <div class="centermodal__group-title">{{ 'settings.personal' | translate }}</div>

    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canCreateDocuments' | translate }}</span>
      <app-switch formControlName="create_documents"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canArchiveDocuments' | translate }}</span>
      <app-switch formControlName="archive_documents"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canDeleteDocuments' | translate }}</span>
      <app-switch formControlName="delete_documents"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canSignDocuments' | translate }}</span>
      <app-switch formControlName="sign_documents"></app-switch>
    </div>
  </div>

  <div class="centermodal__group">
    <div class="centermodal__group-title">{{ 'settings.group' | translate }}</div>

    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewGroupUserDocuments' | translate }}</span>
      <app-switch formControlName="view_documents_gr"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewGroupUserFolders' | translate }}</span>
      <app-switch formControlName="view_folders_gr"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canManageGroupUserDocuments' | translate }}</span>
      <app-switch formControlName="actions_documents_gr"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canManageGroupUserFolders' | translate }}</span>
      <app-switch formControlName="actions_folders_gr"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canManageGroupDocumentTemplates' | translate }}</span>
      <app-switch formControlName="actions_templates_gr"></app-switch>
    </div>
  </div>

  <div class="centermodal__group">
    <div class="centermodal__group-title">{{ 'settings.organization' | translate }}</div>

    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewAllOrganizationDocuments' | translate }}</span>
      <app-switch formControlName="view_documents_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewAllOrganizationFolders' | translate }}</span>
      <app-switch formControlName="view_folders_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewOrganizationMemberDocuments' | translate }}</span>
      <app-switch formControlName="view_member_documents_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewOrganizationMemberFolders' | translate }}</span>
      <app-switch formControlName="view_member_folders_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewOrganizationGroupDocuments' | translate }}</span>
      <app-switch formControlName="view_group_documents_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canViewOrganizationGroupFolders' | translate }}</span>
      <app-switch formControlName="view_group_folders_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canManageAllOrganizationDocuments' | translate }}</span>
      <app-switch formControlName="actions_documents_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canManageAllOrganizationFolders' | translate }}</span>
      <app-switch formControlName="actions_folders_oz"></app-switch>
    </div>
  </div>

  <div class="centermodal__group">
    <div class="centermodal__group-title">{{ 'settings.administration' | translate }}</div>

    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.accessToInvoices' | translate }}</span>
      <app-switch formControlName="view_invoices_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canChangePlanAndBilling' | translate }}</span>
      <app-switch formControlName="change_plan_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canManageOrganizationMembersAndPermissions' | translate }}</span>
      <app-switch formControlName="actions_members_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canChangeOrganizationGroupSettings' | translate }}</span>
      <app-switch formControlName="actions_groups_oz"></app-switch>
    </div>
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canChangeOrganizationAPIWebhook' | translate }}</span>
      <app-switch formControlName="actions_webhooks_oz"></app-switch>
    </div>
    <!-- TO-DO: REMOVE COMMENTS WHEN WHITELABEL LAUNCHES -->
    <!-- <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.modifyWhitelabelCustomization' | translate }}</span>
      <app-switch formControlName="change_whitelabel_oz"></app-switch>
    </div> -->
    <!-- TO-DO: REMOVE COMMENTS WHEN WHITELABEL LAUNCHES -->
    <div class="centermodal__switch">
      <span class="centermodal__switch-name">{{ 'settings.canChangeOrganizationAppearanceSettings' | translate }}</span>
      <app-switch formControlName="change_appearances_oz"></app-switch>
    </div>
  </div>
</ng-container>
