<!-- MOBILE: -->
<app-mobile-header-secondary title="{{ 'menu.organization' | translate }}">
  <!--  <a href="javascript:void(0)" class="mobile-header__option" (click)="(null)">Adicionar</a>-->
</app-mobile-header-secondary>

<!-- DESKTOP: -->

<article class="configuration-item">
  <div class="row mb-4">
    <div class="col-8">
      <div class="configuration-title mb-1">
        {{ 'settings.groupsOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text">
        {{ 'settings.groupsDefinition' | translate }}
        <span class="featured-purple">{{ organizationService.additionalGroupPrice | lcurrency: 'R$' }}{{ 'settings.perMonth' | translate }}</span>
        {{ 'settings.inYourPlan' | translate }}
      </div>
      <!-- <a href="javascript:void(0)" class="configuration-link"><i class="fal fa-question-circle"></i> Ajuda sobre essa tela</a> -->
    </div>
    <div class="col-4 d-flex justify-content-end">
      <button type="button" class="autentique-button" (click)="openCreateGroupModal()" *ngIf="currentUser?.currentPermissions.actions_groups_oz">{{ 'settings.newGroup' | translate }}</button>
    </div>
  </div>

  <div class="row" *ngIf="currentUser">
    <div class="col-12">
      <div class="group-count" *ngIf="total >= 0">
        <span class="group-count__label"
          ><span class="featured-purple">{{ total }}</span
          >{{ 'settings.groupFree' | translate: { maxFreeGroups: organizationService.maxFreeGroups } }}</span
        >&nbsp;&nbsp;
        <span class="featured-purple">({{ additionalGroupsCount() }} {{ 'settings.' + (additionalGroupsCount() === 1 ? 'additional' : 'additionals') | translate }})</span>
      </div>

      <table class="configuration-table">
        <thead class="head">
          <th class="col1">{{ 'settings.name' | translate }}</th>
          <th class="col2">{{ 'settings.members' | translate }}</th>
          <th class="col2">{{ 'settings.permissions' | translate }}</th>
          <th class="col2">{{ 'settings.appearance' | translate }}</th>
          <th class="col2">{{ 'settings.models' | translate }}</th>
          <th class="col3"></th>
        </thead>
        <tbody class="body">
          <tr *ngFor="let group of groups; let index = index">
            <td class="user pt-3 pb-3" [ngClass]="{ 'is-default': group.name === 'Sem grupo' }">
              <strong [ngbTooltip]="group.name === 'Sem grupo' ? withoutGroupTooltip : null" container="body" placement="right">{{ group.name | truncate: 80 }}</strong>
            </td>
            <td class="status pl-1">{{ group.members_count }}</td>
            <td class="highlight" [ngClass]="{ 'is-default': group.is_default }">
              <a href="javascript:void(0)" (click)="!isLoading && openEditGroupPermissionsModal(group)" *ngIf="currentUser?.currentPermissions.actions_groups_oz && !group.is_default">
                {{ 'settings.' + (group.permissions.overwrite_permissions ? 'perGroup' : 'perUser') | translate }}
              </a>
              <span *ngIf="!currentUser?.currentPermissions.actions_groups_oz || group.is_default">{{
                'settings.' + (group.permissions.overwrite_permissions ? 'perGroup' : 'perUser') | translate
              }}</span>
            </td>
            <td class="highlight">
              <a href="javascript:void(0)" (click)="!isLoading && openEditGroupAppearanceModal(group)" *ngIf="currentUser?.currentPermissions.actions_groups_oz">
                {{ 'settings.' + (group.styles.overwrite_email ? 'perGroup' : 'perUser') | translate }}
              </a>
              <span *ngIf="!currentUser?.currentPermissions.actions_groups_oz">{{ 'settings.' + (group.styles.overwrite_email ? 'perGroup' : 'perUser') | translate }}</span>
            </td>
            <td class="highlight">
              <a href="javascript:void(0)" (click)="!isLoading && openEditGroupModal(group)" *ngIf="currentUser?.currentPermissions.actions_groups_oz">
                {{ 'settings.' + (group.overwrite_template ? 'perOrganization' : group.overwrite_template_group ? 'perGroup' : 'perUser') | translate }}
              </a>
              <span *ngIf="!currentUser?.currentPermissions.actions_groups_oz">
                {{ 'settings.' + (group.overwrite_template ? 'perOrganization' : group.overwrite_template_group ? 'perGroup' : 'perUser') | translate }}
              </span>
            </td>
            <td class="opts">
              <div class="dropdown-table" placement="bottom-right" *ngIf="currentUser?.currentPermissions.actions_groups_oz" ngbDropdown>
                <label class="dropdown-table-toggle" [id]="'options-dropdown-' + index" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
                <div [attr.aria-labelledby]="'options-dropdown-' + index" ngbDropdownMenu>
                  <button (click)="openEditGroupModal(group)" [disabled]="isLoading || !currentUser?.currentPermissions.actions_groups_oz" ngbDropdownItem>{{ 'button.editGroup' | translate }}</button>
                  <button (click)="openEditGroupPermissionsModal(group)" [disabled]="isLoading || group.is_default" ngbDropdownItem>{{ 'button.editPermissions' | translate }}</button>
                  <button (click)="openEditGroupAppearanceModal(group)" [disabled]="isLoading || !currentUser?.currentPermissions.actions_groups_oz" ngbDropdownItem>
                    {{ 'button.editAppearance' | translate }}
                  </button>
                  <button (click)="deleteGroup(group)" [disabled]="isLoading || group.is_default" ngbDropdownItem>{{ 'button.exclude' | translate }}</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-3" *ngIf="!isLoading && !isLastPage">
        <button type="button" class="btn btn-link" (click)="loadGroups({ page: pageNumber + 1 })">{{ 'button.loadMore' | translate }}</button>
      </div>
    </div>
  </div>
</article>

<ng-template #withoutGroupTooltip>
  <div class="tooltip__title">{{ 'settings.withoutGroup' | translate }}</div>
  <div class="tooltip__descr">{{ 'settings.withoutGroupInfo' | translate }}</div>
</ng-template>
