<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="update()">
  <p class="centermodal__title">
    {{ 'settings.edit' | translate }} <b>{{ group.name }}</b>
  </p>

  <ng-container formGroupName="group">
    <div class="centermodal__form">
      <label class="field__holder" [appFormControlWrap]="form.get('group.name')">
        <input type="text" class="field" formControlName="name" autocomplete="none" role="presentation" required #nameInput />
        <p class="field__placeholder">{{ 'settings.groupName' | translate }}</p>
      </label>
    </div>

    <div class="centermodal__group mb-4">
      <div class="centermodal__group-title"></div>
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.accessAllModelsOrganization' | translate }}</span>
        <app-switch formControlName="overwrite_template"></app-switch>
      </div>
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.shareAllModelsGroup' | translate }}</span>
        <app-switch formControlName="overwrite_template_group"></app-switch>
      </div>
    </div>
  </ng-container>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.save' | translate }}</button>
  </div>
</form>
