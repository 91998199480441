import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  fromDocumentUrl: string;

  constructor(private router: Router, public appService: AppService) {
    this.fromDocumentUrl = (router.getCurrentNavigation().extras.state || {}).fromDocumentUrl;
  }

  obscureUrl(url: string) {
    return (url || '').split('?')[0].replace(/([A-z0-9]{8})$/, '********');
  }
}
