import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User, Whitelabel } from '@app/models';
import { contextMenuState } from '../configurations-whitelabel.component';
import { AppService, ErrorHandlerService, NotyService, WhitelabelService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-whitelabel-signing-page',
  templateUrl: './whitelabel-signing-page.component.html',
  styleUrls: ['../../configurations.scss', './whitelabel-signing-page.component.scss']
})
export class WhitelabelSigningPageComponent implements OnInit {
  @Input() currentUser: User;
  @Input() whitelabelData: Whitelabel;
  @Input() mainColor: string;
  @Output() contextChange: EventEmitter<contextMenuState> = new EventEmitter();
  @Output() goBack: EventEmitter<contextMenuState> = new EventEmitter();

  contextMenuState = contextMenuState;

  selectedLogoImage: File | null = null;
  selectedLogoImageUrl: SafeUrl | null = null;

  isDarkmode: boolean = false;
  showHexadecimalError: boolean = false;

  constructor(
    public whitelabelService: WhitelabelService,
    public appService: AppService,
    public translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private notyService: NotyService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    if (this.whitelabelData.is_darkmode) {
      this.isDarkmode = true;
    }
  }

  submitForm() {
    if (this.showHexadecimalError) {
      this.notyService.error('Cor hexadecimal inválida. Por favor, insira um formato válido (#FFFFFF).');
      return;
    }
    this.whitelabelService.appearance['main_color'] = this.mainColor || this.whitelabelData.main_color;
    this.whitelabelService.appearance['is_darkmode'] = this.isDarkmode;
    this.whitelabelService.updateWhitelabelAppearance({ whitelabel: this.whitelabelService.appearance }).subscribe(
      () => {
        this.notyService.success('Personalização salva com sucesso.');
        this.goBack.emit();
      },
      error => {
        this.errorHandlerService.handle(error);
      }
    );
  }

  changeContext(context: contextMenuState) {
    this.contextChange.emit(context);
  }

  selectLogoImage(image: File) {
    if (image) {
      if (this.selectedLogoImageUrl) {
        URL.revokeObjectURL(this.selectedLogoImageUrl as string);
      }
      const objectURL = URL.createObjectURL(image);
      this.selectedLogoImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      this.selectedLogoImage = image;
      this.whitelabelService.appearance['logo'] = this.selectedLogoImage;
    }
  }

  updateMainColor(color: string) {
    if (this.appService.isHexaColor(color.trim())) {
      this.mainColor = color.trim();
      this.showHexadecimalError = false;
    } else {
      this.showHexadecimalError = true;
    }
  }
}
