<form class="modal-body" [formGroup]="form" novalidate>
  <p class="centermodal__title">{{ 'settings.changeLanguageAndLocation' | translate }}</p>

  <div class="centermodal__form" formGroupName="locale">
    <div class="centermodal__group-title">{{ 'settings.languageLocation' | translate }}</div>
    <input class="d-none" />
    <div [appFormControlWrap]="form.get('locale.country')">
      <app-select placeholder="{{ 'profile.country' | translate }}" formControlName="country" [options]="countries" [required]="true"></app-select>
    </div>
    <div [appFormControlWrap]="form.get('locale.language')">
      <app-select placeholder="{{ 'profile.language' | translate }}" formControlName="language" [disableSearch]="true" [options]="languages"> </app-select>
    </div>
    <div [appFormControlWrap]="form.get('locale.timezone')">
      <app-select placeholder="{{ 'profile.timezone' | translate }}" formControlName="timezone" [options]="timezones" [required]="true"></app-select>
    </div>
    <div [appFormControlWrap]="form.get('locale.date_format')">
      <app-select placeholder="{{ 'profile.dateFormat' | translate }}" formControlName="date_format" [disableSearch]="true" [options]="dateFormat"> </app-select>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading" (click)="update()">{{ 'button.save' | translate }}</button>
  </div>
</form>
