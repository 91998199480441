<div class="d-flex">
  <app-mobile-header-main (titleClicked)="openTaxonomyMenu = $event" mainTitle="{{ currentMember?.user?.name }}" menulink="/menu/documentos"></app-mobile-header-main>
  <div class="taxonomy-overlay" [class.taxonomy-overlay-active]="openTaxonomyMenu" (click)="openTaxonomyMenu = false"></div>
  <app-taxonomy
    *ngIf="whitelabelService.params.sidebar"
    [mainTitle]="isOnFolder ? ('documents.folders' | translate) : ('documents.documents' | translate)"
    allowSearch
    [(isSearchOpen)]="isSearchOpen"
    (isSearchOpenChange)="toggleSearch($event)"
    [class.isMenuMobileOpen]="openTaxonomyMenu"
  >
    <app-documents-taxonomy
      [isSearchOpen]="isSearchOpen"
      [listPath]="'member'"
      [disableMainButton]="!isMemberCurrentUser || !currentUser?.currentPermissions.actions_folders_oz"
      (sortingChange)="loadDocuments({ page: 1, search: searchQuery }); loadFolders({ page: 1, search: searchQuery })"
      (newFolderButtonClick)="openCreateFolderModal()"
    >
    </app-documents-taxonomy>
  </app-taxonomy>
</div>

<div class="d-flex flex-column w-100">
  <app-documents-search
    [(search)]="searchQuery"
    [selectedMemberOrGroup]="currentMember ? { id: currentMember.user.id, name: currentMember.user.name, type: 'member' } : null"
    (searchChange)="loadDocuments({ page: 1, search: $event }); loadFolders({ page: 1, search: $event })"
    (closeClick)="isSearchOpen = false; toggleSearch(isSearchOpen)"
    *ngIf="isSearchOpen"
    @slideDownUpTrigger
  ></app-documents-search>

  <div class="documents">
    <div class="documents__header">
      <ng-container *ngIf="!((isSearchOpen && searchQuery) || mobileSearch)">
        <h2 class="documents__title" *ngIf="currentMember && !isOnFolder">{{ currentMember.user?.name }}&nbsp;</h2>

        <h2 class="documents__title">{{ currentFolder?.name }}&nbsp;</h2>
        <ng-container *ngIf="currentMember && currentFolder && whitelabelService.params.folderBreadCrumbs">
          <div class="documents__pathing" *ngIf="currentMember">
            <a [routerLink]="['/pastas', 'organizacao', currentMember.user?.id]">{{ currentMember.user?.name | truncate: 35 }}&nbsp;</a>
            <ng-container *ngIf="currentFolder.parentFolder">
              <p class="documents__pathing__current">>&nbsp;&hellip;&nbsp;>&nbsp;</p>
              <a [routerLink]="['/pastas', currentFolder.parentFolder.id, 'organizacao', currentMember.user?.id, 'documentos']">{{ currentFolder.parentFolder.name | truncate: 35 }}&nbsp;</a>
            </ng-container>
            <p class="documents__pathing__current">>&nbsp;{{ currentFolder.name | truncate: 35 }}</p>
          </div>
        </ng-container>
      </ng-container>
      <h2 class="documents__title" *ngIf="(isSearchOpen && searchQuery) || mobileSearch">{{ 'documents.searchResults' | translate }}</h2>
      <app-search [(query)]="mobileSearch" (queryChange)="pageNumber = 1; loadDocuments({ page: 1, search: { search: $event } })"></app-search>
      <button
        type="button"
        class="btn btn-link text-right d-none d-md-inline-block"
        [disabled]="isLoading"
        (click)="exportDocuments(searchQuery)"
        *ngIf="isSearchOpen && searchQuery && this.documents?.length > 0"
      >
        <i class="fal fa-file-csv"></i>&nbsp; {{ 'documents.export' | translate }}
      </button>
    </div>

    <div
      class="documents-empty"
      *ngIf="
        currentUser && !isLoading && !searchQuery && !mobileSearch && !appService.documentStatusFilter && documents?.length === 0 && (!isOnFolder || folders?.length === 0);
        else documentsEmptyWithFilters
      "
    >
      <i class="fal fa-file-alt documents-empty__icon"></i>
      {{ 'documents.nothingHereYet' | translate }}
    </div>

    <ng-template #documentsEmptyWithFilters>
      <div
        class="documents-filtered-empty"
        *ngIf="currentUser && !isLoading && documents && (searchQuery || mobileSearch || appService.documentStatusFilter) && documents?.length === 0 && (!isOnFolder || folders?.length === 0)"
      >
        <div class="documents-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="documents-filtered-empty_text">
          <span>{{ 'documents.noDocumentsMatchingFilter' | translate }}</span>
        </div>
        <button type="button" class="default-button" (click)="clearFilters()">{{ 'documents.clearFilters' | translate }}</button>
      </div>
    </ng-template>

    <app-folders-list-boxes
      *ngIf="whitelabelService.params.folderBreadCrumbs"
      [folders]="folders"
      [linkPath]="['/pastas', ':id', 'organizacao', currentMember?.user.id, 'documentos']"
      [hidePagination]="isLoading || isLastPageFolders"
      [hideDropdownMenu]="!isMemberCurrentUser || !currentUser?.currentPermissions.actions_folders_oz"
      (loadPageClick)="loadFolders({ page: pageNumberFolders + 1, search: searchQuery })"
      (folderClick)="openFoldersModal({ folder: $event })"
      (updateClick)="openUpdateFolderModal($event)"
      (deleteClick)="deleteFolder($event)"
    >
    </app-folders-list-boxes>

    <app-documents-list-boxes
      *ngIf="appService.listFormat === ListFormat.Box"
      [currentUser]="currentUser"
      [documents]="documents"
      [currentOrganizationMemberId]="route.snapshot.params.memberId"
      [hidePagination]="isLoading || isLastPage"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      (signClick)="sign($event)"
      (folderClick)="openFoldersModal({ documents: $event })"
      (deleteClick)="delete($event)"
      (transferClick)="transfer($event)"
      (toggleBlockClick)="toggleBlock($event)"
      (resendWebhookClick)="resendWebhook($event)"
    >
    </app-documents-list-boxes>

    <app-documents-list-table
      *ngIf="appService.listFormat === ListFormat.List"
      [currentUser]="currentUser"
      [hidePagination]="isLoading || isLastPage"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      [documents]="documents"
      (signClick)="sign($event)"
      (folderClick)="openFoldersModal({ documents: $event })"
      (deleteClick)="delete($event)"
      (transferClick)="transfer($event)"
      (toggleBlockClick)="toggleBlock($event)"
      (resendSignaturesClick)="resendSignatures($event)"
      (resendWebhookClick)="resendWebhook($event)"
    >
    </app-documents-list-table>
  </div>
</div>
