import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { defer, Observable, Observer, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators';
import { clone, uniqBy } from 'lodash';
import * as Bowser from 'bowser';

import { untilDestroyed } from '@app/core';
import { FloatingField, PositionElementEnum, Document, Whitelabel } from '@app/models';
import { FloatingFieldsComponent } from '../floating-fields/floating-fields.component';

declare const pdfjsLib: any;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() pdfUrl: string;
  @Input() iframePdfUrl: string;
  @Input() pdfFile: File;
  @Input() enableEditFloatingFields: boolean;
  @Input() renderOnIframe: boolean;
  @Input() qualified: boolean;
  @Input() isNewSignatureStyle: boolean;
  @Input() document: Document;
  @Input() documentFooter: string;
  @Input() documentWhitelabel: Whitelabel;
  @Input() isDocumentWhitelabelLoaded: boolean;
  @Input() floatingFields: FloatingField[];
  @Output() selectedFieldChange = new EventEmitter<FloatingField>();
  pdf: any;
  floatingFieldSubscriber: Observer<FloatingField>;
  safePdfUrl: any = '';
  floatingFieldsScale: number;
  canvasHeight: number;
  loadedPages: number;
  @ViewChild('floatingFields', { static: false }) private floatingFieldsComponent!: FloatingFieldsComponent;
  @ViewChild('pdfContainer', { static: false }) private pdfContainer: ElementRef<HTMLElement>;
  private _isLoading = true;
  private isOldBrowser = false;
  private readonly pagesBatch = 10;
  private readonly maxPageWidth = 794;
  private pagesToLoad = this.pagesBatch;
  private floatingFieldInfo: { identification?: string; element: FloatingField['element']; idColor?: string; signature?: FloatingField['signature'] };

  constructor(private domSanitizer: DomSanitizer, private ngZone: NgZone) {
    this.isOldBrowser = Bowser.getParser(window.navigator.userAgent).satisfies({
      chrome: '<86',
      firefox: '>0',
      opera: '<70',
      edge: '<85',
      safari: '<13',
      ie: '>0',
      miui: '>0',
      samsung_internet: '>0'
    });
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.floatingFieldsComponent.fields = this.floatingFields || [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.iframePdfUrl && changes.iframePdfUrl.currentValue) || (changes.pdfUrl && changes.pdfUrl.currentValue)) {
      this.safePdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl((changes.iframePdfUrl && changes.iframePdfUrl.currentValue) || (changes.pdfUrl && changes.pdfUrl.currentValue) || '');
    }
    if (changes.pdfUrl && changes.pdfUrl.currentValue) {
      if (changes.pdfUrl.currentValue !== changes.pdfUrl.previousValue) {
        this.setPdf({ url: changes.pdfUrl.currentValue }).subscribe();
      } else if (changes.pdfFile.currentValue !== changes.pdfFile.previousValue) {
        this.setPdf({ file: changes.pdfFile.currentValue }).subscribe();
      }
    }
    if (changes.floatingFields && changes.floatingFields.currentValue !== changes.floatingFields.previousValue) {
      this.refreshFloatingFields(changes.floatingFields.currentValue);
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  setPdf(options: { url?: string; file?: File }) {
    this._isLoading = true;
    this.pdfUrl = options.url;
    this.pdfFile = options.file;

    let objectUrl: string;
    if (this.pdfFile) {
      objectUrl = URL.createObjectURL(this.pdfFile);
    }

    const pdfLoader$ = defer(
      () =>
        new Promise<any>((resolve, reject) => {
          this.promiseHandler(pdfjsLib.getDocument({ url: objectUrl || this.pdfUrl })).then(
            (promise: any) => resolve(promise),
            (reason: any) => reject(reason)
          );
        })
    ).pipe(
      retryWhen(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          if (retryAttempt > 50) {
            return throwError(error);
          }
          return timer(retryAttempt * 1000);
        })
      ),
      tap(pdf => {
        this._isLoading = false;
        this.pdf = pdf;
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
        }
        this.setupPdfContainer();
        this.loadPages(this.pdf);
      }),
      untilDestroyed(this)
    );

    return this.loadPdfJs().pipe(switchMap(() => pdfLoader$));
  }

  reset() {
    this._isLoading = false;
    this.pdf = undefined;
    this.pagesToLoad = this.pagesBatch;
    this.pdfContainer.nativeElement.innerHTML = '';
  }

  pagesCount() {
    return this.pdfContainer ? this.pdfContainer.nativeElement.childElementCount : 0;
  }

  getFloatingFields() {
    return this.floatingFieldsComponent.fields || [];
  }

  floatingFieldsSettingModeFor(floatingFieldInfo: { identification: string; element: FloatingField['element']; idColor?: string; signature?: FloatingField['signature'] }) {
    this.disableFloatingFieldsSettingMode();
    return new Observable<FloatingField>(subscriber => {
      this.floatingFieldInfo = floatingFieldInfo;
      this.floatingFieldSubscriber = subscriber;
    });
  }

  disableFloatingFieldsSettingMode() {
    if (this.floatingFieldSubscriber) {
      this.floatingFieldSubscriber.complete();
      this.floatingFieldSubscriber = undefined;
      this.floatingFieldInfo = undefined;
    }
  }

  clearFloatingFields() {
    clone(this.getFloatingFields()).forEach(field => this.floatingFieldsComponent.removeField(field));
  }

  refreshFloatingFields(floatingFields?: FloatingField[]) {
    setTimeout(() => {
      this.floatingFieldsComponent.fields = floatingFields || this.floatingFields || [];
      if (this.pdf) {
        this.pdf.getPage(1).then((page: any) => {
          this.floatingFieldsScale = this.getFloatingFieldsScale(page.getViewport(this.viewportScaleHandler(1)));
          this.floatingFieldsComponent.refreshFields();
        });
      } else {
        this.floatingFieldsComponent.refreshFields();
      }
    });
  }

  removeFloatingFieldsWhenQualifiedDocument(signerIdentification?: string) {
    if (this.qualified) {
      this.getFloatingFields().forEach(floatingField => this.floatingFieldsComponent.unlinkFields(floatingField));

      this.getFloatingFields()
        .filter(floatingField => (signerIdentification && signerIdentification === floatingField.identification) || floatingField.element !== PositionElementEnum.Signature)
        .forEach(floatingField => this.floatingFieldsComponent.removeField(floatingField));

      const floatingFields = this.getFloatingFields();
      const uniqFloatingFields = uniqBy(floatingFields, 'identification');
      floatingFields.filter(floatingField => !uniqFloatingFields.includes(floatingField)).forEach(floatingField => this.floatingFieldsComponent.removeField(floatingField));
    }
  }

  private loadPages(pdf: any) {
    const currentPagesCount = this.pagesCount();
    if (pdf && this.pagesToLoad > 0 && currentPagesCount < pdf.numPages) {
      this._isLoading = true;
      pdf.getPage(currentPagesCount + 1).then((page: any) => {
        const originalViewport = page.getViewport(this.viewportScaleHandler(1));
        const canvas = document.createElement('canvas');
        canvas.width = 1015;
        canvas.style.width = '100%';
        const viewport = page.getViewport(this.viewportScaleHandler(canvas.width / originalViewport.width));
        canvas.height = viewport.height;

        this.floatingFieldsScale = this.getFloatingFieldsScale(originalViewport);
        this.canvasHeight = this.floatingFieldsScale;

        this.promiseHandler(page.render({ canvasContext: canvas.getContext('2d', { willReadFrequently: true }), viewport })).then(() => {
          this._isLoading = false;
          this.pagesToLoad--;
          this.loadedPages = currentPagesCount + 1;
          this.pdfContainer.nativeElement.appendChild(canvas);
          this.ngZone.run(() => {});
          this.loadPages(pdf);
        });
      });
    } else {
      this.pagesToLoad = this.pagesBatch;
      this.floatingFieldsComponent.refreshFields();
    }
  }

  private setupPdfContainer() {
    // This is a workaround to fix the pdf container, that changes its width after 1 canvas is inserted
    const canvas = document.createElement('canvas');
    canvas.width = this.maxPageWidth;
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    this.pdfContainer.nativeElement.appendChild(canvas);
    this.pdfContainer.nativeElement.innerHTML = '';
  }

  private getFloatingFieldsScale(viewport: any) {
    return this.pdfContainer.nativeElement.offsetWidth / Math.max(viewport.width, this.maxPageWidth);
  }

  private promiseHandler(promise: any) {
    return this.isOldBrowser ? promise : promise.promise;
  }

  private viewportScaleHandler(value: number) {
    return this.isOldBrowser ? value : { scale: value };
  }

  private loadPdfJs() {
    return new Observable<undefined>(subscriber => {
      const version = this.isOldBrowser ? '2.0.550' : '2.5.207';
      const scriptId = 'pdfjs-script';
      if (!document.getElementById(scriptId)) {
        const scriptElement = document.createElement('script');
        scriptElement.src = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.min.js`;
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.id = scriptId;
        scriptElement.onload = () => {
          pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
          subscriber.next();
          subscriber.complete();
        };
        scriptElement.onerror = () => {
          subscriber.error();
        };
        document.getElementsByTagName('head')[0].appendChild(scriptElement);
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  @HostListener('touchend')
  @HostListener('window:wheel')
  @HostListener('window:scroll')
  private onScroll() {
    if (!this._isLoading && this.pdf && this.pdf.numPages > this.pagesCount()) {
      const pixelsToBottom = document.documentElement.scrollHeight - (document.documentElement.scrollTop + window.outerHeight);
      if (pixelsToBottom < 1000) {
        this.loadPages(this.pdf);
      }
    }
  }

  @HostListener('click', ['$event', '$event.target']) private onClickAddFloatingField(event: MouseEvent, target: HTMLCanvasElement) {
    if (target.tagName === 'CANVAS' && this.floatingFieldSubscriber && this.floatingFieldInfo) {
      this.removeFloatingFieldsWhenQualifiedDocument(this.floatingFieldInfo.identification);
      const field = this.floatingFieldsComponent.createField(
        {
          ...this.floatingFieldInfo,
          position: {
            x: (event.offsetX * 100) / target.offsetWidth,
            y: (event.offsetY * 100) / target.offsetHeight,
            z: Array.prototype.indexOf.call(target.parentElement.children, target) + 1
          }
        },
        true
      );
      this.floatingFieldSubscriber.next(field);
      this.disableFloatingFieldsSettingMode();
    }
  }
}
