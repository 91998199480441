import { Pipe, PipeTransform } from '@angular/core';

import { Document } from '@app/models';
import { DocumentService } from '@app/services';
import { CustomSigner } from '../documents-show/documents-show.component';

@Pipe({ name: 'sortByCurrentSigners', pure: false })
export class SortByCurrentSignersPipe implements PipeTransform {
  constructor(private documentService: DocumentService) {}

  transform(signers: Document['signatures'], document: Document, currentSigners: CustomSigner[]) {
    if (signers && document && !document.sortable && (currentSigners || []).length > 0) {
      const currentSignersPublicIds = currentSigners.map(sig => sig.public_id);
      return (signers || []).sort(sig => (currentSignersPublicIds.includes(sig.public_id) && this.documentService.isSignerPending(sig) ? -1 : 1));
    } else {
      return signers;
    }
  }
}
