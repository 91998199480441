import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appFullName]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FullNameDirective, multi: true }]
})
export class FullNameDirective implements Validator {
  static validator(control: AbstractControl): ValidationErrors | null {
    return control.value && typeof control.value === 'string' && FullNameDirective.isFullName(control.value) ? null : { appFullName: true };
  }

  static isFullName(fullName: string) {
    return fullName.trim().split(/\s+/).length > 1;
  }

  constructor() {}

  validate(control: AbstractControl) {
    return FullNameDirective.validator(control);
  }
}
