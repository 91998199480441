import { Injectable, OnChanges, OnInit } from '@angular/core';
import { StorageService } from '@app/core/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ColumnControlService implements OnInit {
  columns: any = {
    // TOOGLE COLUMNS
    //table Contacts toogle
    c1: true,
    c2: true,
    c3: true,
    c4: true,
    c5: true
  };

  columnsWidth: any = {
    //Size will be overwritten
    //Contacts in px
    c1: 240,
    c2: 240,
    c3: 240,
    c4: 240,
    c5: 240
  };

  columnsWidthPercent: any = {
    //default % layout on load
    //Contacts
    c1: 30,
    c2: 30,
    c3: 10,
    c4: 10,
    c5: 12
  };

  columnsData = {
    columns: this.columns,
    columnsWidth: this.columnsWidth
  };

  constructor(private storageService: StorageService) {}

  ngOnInit() {}

  start(maxWidth: any) {
    let columnsData = this.storageService.getJSON('columnService') || {};
    if (!!columnsData) {
      for (let column in this.columnsWidth) {
        this.columnsWidth[column] = ((maxWidth - 15 - 20) * this.columnsWidthPercent[column]) / 100;
      }
      this.columns = { ...this.columns, ...(columnsData.columns || {}) };
      this.columnsWidth = { ...this.columnsWidth, ...(columnsData.columnsWidth || {}) };
      Object.entries(this.columnsWidth).forEach(value => {
        // console.log('--' + value[0], value[1], 'start');
        this.setWidth('--' + value[0], value[1]);
      });
    }
  }

  toggleColumn(columnName: any) {
    for (let column in this.columns) {
      if (columnName === column) {
        this.columns[columnName] = !this.columns[columnName];
      }
    }
    this.saveToLocalStorage();
  }

  setWidth(columnName: any, value: any) {
    let name = columnName.replace('--', '');
    for (let column in this.columnsWidth) {
      if (name === column) {
        document.documentElement.style.setProperty(columnName, value + 'px');
        this.columnsWidth[name] = value;
      }
    }
  }

  saveToLocalStorage() {
    this.columnsData = {
      columns: this.columns,
      columnsWidth: this.columnsWidth
    };
    this.storageService.setJSON('columnService', this.columnsData);
  }
}

// 1- Put these inside component

// ngAfterViewInit() {
//   this.columnControl.start(this.wrapper.nativeElement.offsetWidth);
// }
// ngOnDestroy() {
//   this.setColumns('c1' || '', 'c2' || '', 'c3' || '', 'c4' || '', 'c5' || '');
// }

// changeColumn(name: any) {
//   this.columnControl.toggleColumn(name);
// }

// setColumns(...columnNames: any) {
//   columnNames.forEach((columnName: string) => {
//     if (this[columnName]?.width) {
//       this.columnControl.setWidth('--' + columnName, this[columnName].width);
//       this.columnControl.saveToLocalStorage();
//     }
//   });
// }

// 2- declare @viewchild for columns and wrapper
// 3- use resizable directive no HTML
// 4- definir variaveis css no theme-variables em :root
// 5- usar a classe definida no :root na class='' do <th>
