import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService, AuthenticationService, NotyService } from '@app/services';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {
  @Input() verifier: string;
  @Input() email: string;
  @Output() verified = new EventEmitter<boolean>();

  token: string;
  isVerifyingToken = false;
  isInvalidToken = false;
  isPaste = false;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private authenticationService: AuthenticationService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.email = this.email.toLowerCase();
    this.verifier = this.verifier.toLowerCase();
  }

  submitToken() {
    if (!this.isVerifyingToken) {
      this.isVerifyingToken = true;
      this.isInvalidToken = false;
      this.httpClient
        .disableLoader()
        .post('/email/verify', { token: this.token, email: this.verifier })
        .subscribe(
          (response: any) => {
            this.isVerifyingToken = false;
            this.isInvalidToken = response.message === 'token_invalid';

            if (response.message === 'email_verified') {
              this.verified.emit(true);
            } else if (!this.isInvalidToken) {
              this.notyService.error(this.translateService.instant('notyService.emailVerificationFailed'));
            }
          },
          error => {
            this.errorHandlerService.handle(error);
          }
        );
    }
  }

  sendEmailConfirmation() {
    this.isVerifyingToken = true;
    this.isInvalidToken = false;

    this.authenticationService
      .sendEmailConfirmation(this.email)
      .pipe(finalize(() => (this.isVerifyingToken = false)))
      .subscribe(
        () => {
          this.notyService.success(this.translateService.instant('notyService.confirmationCodeResent'));
          this.authenticationService.clearAllFields();
          this.isInvalidToken = false;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  isOutlookEmail(email: string) {
    return !!(email || '').match(/(@hotmail\.com)|(@outlook\.com)/);
  }
}
