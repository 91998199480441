<header class="header">
  <a routerLink="/" class="header-brand" *ngIf="whitelabelService.params.mobileNavigation">
    <img src="assets/autentique-logo.svg" alt="" />
  </a>
  <div class="header__title" (click)="toggleTitle()" [attr.size]="mainTitle?.length" *ngIf="whitelabelService.params.mobileNavigation">{{ mainTitle }}</div>
  <div class="header__title--whitelabel" [attr.size]="mainTitle?.length" *ngIf="!whitelabelService.params.mobileNavigation">{{ mainTitle }}</div>
  <input id="header__menu--checkbox" type="checkbox" class="header__menu--checkbox" />
  <a routerLink="{{ [menulink] }}" for="header__menu--checkbox" class="header__menu--toggle" *ngIf="whitelabelService.params.mobileNavigation"
    ><svg xmlns="http://www.w3.org/2000/svg" class="icon" width="100" height="100" viewBox="0 0 24 24" stroke-width="2" stroke="#1768E1" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <g transform="translate(1 1)">
        <line class="a" x2="30" transform="translate(0 10)" />
        <line class="a" x2="30" />
        <line class="a" x2="30" transform="translate(0 20)" />
      </g>
    </svg>
  </a>
</header>
