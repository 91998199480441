import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { ValidEmailDirective } from './valid-email.directive';
import { ValidPhoneDirective } from './valid-phone.directive';

@Directive({
  selector: '[appValidEmailPhone]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidEmailPhoneDirective, multi: true }]
})
export class ValidEmailPhoneDirective implements Validator {
  @Input('appValidEmailPhone') isActive = true;

  static validator(control: AbstractControl, isActive: boolean = true): ValidationErrors | null {
    return (typeof isActive === 'boolean' && !isActive) || ValidEmailDirective.isEmailValid(control.value) || ValidPhoneDirective.isPhoneValid(control.value) ? null : { appValidEmailPhone: true };
  }

  constructor() {}

  validate(control: AbstractControl) {
    return ValidEmailPhoneDirective.validator(control, this.isActive);
  }
}
