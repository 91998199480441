import { Component, Input } from '@angular/core';

import { User } from '@app/models';
import { UserService } from '@app/services';

@Component({
  selector: 'app-verifications-credits-count',
  templateUrl: './verifications-credits-count.component.html',
  styleUrls: ['./verifications-credits-count.component.scss']
})
export class VerificationsCreditsCountComponent {
  @Input() user!: User;
  @Input() disableIcon = false;

  constructor(public userService: UserService) {}
}
