<article class="configuration-item d-none d-lg-block">
  <div class="configuration-container">
    <div class="configuration-content">
      <div class="configuration-title mb-1">
        {{ 'whitelabel.customizeSignaturePageOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text">{{ 'whitelabel.customizeSignedDocumentDescription' | translate }}</div>
    </div>
    <div class="configuration-generate">
      <button type="button" class="btn btn-link text-danger" (click)="changeContext(contextMenuState.default)">{{ 'button.cancel' | translate }}</button>
      <button type="button" class="configuration-button" (click)="submitForm()">{{ 'button.save' | translate }}</button>
    </div>
  </div>
  <div class="configuration-container wrap">
    <div>
      <div class="optionsMenu">
        <app-select
          [(ngModel)]="isDarkmode"
          placeholder="{{ 'whitelabel.tone' | translate }}"
          [disableSearch]="true"
          [options]="[
            {
              key: false,
              value: translateService.instant('whitelabel.lightMode'),
              height: 44,
              fontSize: 16
            },
            {
              key: true,
              value: translateService.instant('whitelabel.darkMode'),
              height: 44,
              fontSize: 16
            }
          ]"
        >
        </app-select>
      </div>
      <div class="colorBox" [ngClass]="{ 'hex-input-validation': showHexadecimalError }">
        <input type="color" class="colorBox-color" [(ngModel)]="mainColor" [style.background]="mainColor" (ngModelChange)="updateMainColor($event)" />
        <input type="text" class="colorBox-text" [ngModel]="mainColor" (ngModelChange)="updateMainColor($event)" />
      </div>
      <div>
        <div class="configuration-generate logoBtn" placement="bottom">
          <label class="btn btn-sm">
            <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectLogoImage($event.target['files'][0]); $any($event.target).value = ''" />
            <div class="configuration-button">{{ 'whitelabel.logoImage' | translate }}</div>
          </label>
          <div class="warning">
            <i class="fal fa-exclamation-circle"></i>
            <p>{{ 'whitelabel.logoImageWarning' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pageSize">
      <app-signing-page [isDarkmode]="isDarkmode" [mainColor]="mainColor" [whitelabelData]="whitelabelData" [selectedLogoImageUrl]="selectedLogoImageUrl"></app-signing-page>
    </div>
  </div>
</article>
