import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ValidationService } from '@app/services';

@Component({
  selector: '[appFormControlWrap]',
  templateUrl: './form-control-wrap.component.html',
  styleUrls: ['./form-control-wrap.component.scss']
})
export class FormControlWrapComponent implements AfterViewInit {
  @Input() appFormControlWrap!: AbstractControl;
  @Input() hideErrorMessage = false;

  constructor(private elementRef: ElementRef, private translateService: TranslateService, private validationService: ValidationService) {}

  ngAfterViewInit() {
    this.checkEmptyInput(this.appFormControlWrap.value);
    this.appFormControlWrap.valueChanges.subscribe(value => this.checkEmptyInput(value));
  }

  getErrorMessage() {
    return this.validationService.getErrorMessage(this.appFormControlWrap.errors);
  }

  private getInput() {
    return this.elementRef.nativeElement.querySelector('input,select,textarea');
  }

  private checkEmptyInput(value: any) {
    const input = this.getInput();
    if (input && !this.elementRef.nativeElement.querySelector('app-select')) {
      if (value) {
        input.classList.remove('empty');
      } else {
        input.classList.add('empty');
      }
    }
  }
}
