<div *ngIf="folders && folders.length > 0">
  <div class="documents-folders">
    <div class="folder" *ngFor="let folder of folders">
      <div class="folder-option" ngbDropdown placement="bottom-right" *ngIf="!hideDropdownMenu">
        <button type="button" class="folder-option-toggle" ngbDropdownToggle>
          <i class="fal fa-ellipsis-v"></i>
        </button>
        <div ngbDropdownMenu>
          <button (click)="folderClick.emit(folder)" ngbDropdownItem><i class="fal fa-archive"></i> {{ 'button.archive' | translate }}</button>
          <button (click)="updateClick.emit(folder)" ngbDropdownItem><i class="fal fa-edit"></i> {{ 'button.rename' | translate }}</button>
          <button (click)="deleteClick.emit(folder)" ngbDropdownItem><i class="fal fa-trash-alt"></i> {{ 'button.exclude' | translate }}</button>
        </div>
      </div>
      <div class="folder-icon-holder">
        <i class="fal fa-folder-open folder-icon"></i>
      </div>
      <div class="folder-name-holder">
        <div class="folder-name">{{ folder.name }}</div>
      </div>
      <div class="folder-counter-holder">
        <!-- <div class="folder-counter green">12</div>
        <div class="folder-counter blue">5</div>
        <div class="folder-counter red">7</div> -->
      </div>
      <a [routerLink]="insertIdOnPath(linkPath, folder.id)" class="folder__link"></a>
    </div>
  </div>

  <div class="text-center" *ngIf="!hidePagination">
    <button type="button" class="btn btn-link" (click)="loadPageClick.emit()">{{ 'documents.loadMoreFolders' | translate }}</button>
  </div>
</div>
