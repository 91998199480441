import { Component, OnInit, OnDestroy } from '@angular/core';

import { untilDestroyed } from '@app/core';
import { ListFormat, ListSorting, User } from '@app/models';
import { AppService, UserService } from '@app/services';

@Component({
  selector: 'app-documents-organization',
  templateUrl: './documents-organization.component.html',
  styleUrls: ['./documents-organization.component.scss']
})
export class DocumentsOrganizationComponent implements OnInit, OnDestroy {
  currentUser: User;

  constructor(private userService: UserService) {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
