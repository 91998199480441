import { Injectable } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from 'apollo-client';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { omit } from 'lodash';

import { throwOnGraphqlError } from '@app/core';
import { Order, MembersAndGroups, OrganizationMember, Plan, SlimOrganization } from '@app/models';
import {
  OrganizationsGQL,
  CreateOrganizationGQL,
  CreateOrganizationMutationVariables,
  SelectDefaultOrganizationGQL,
  SelectDefaultOrganizationMutationVariables,
  UpdateOrganizationGQL,
  UpdateOrganizationMutationVariables,
  OrganizationGroupsGQL,
  OrganizationGroupsQueryVariables,
  OrganizationGroupsTotalGQL,
  OrganizationGroupsTotalQueryVariables,
  SlimOrganizationGroupsGQL,
  SlimOrganizationGroupsQueryVariables,
  OrganizationGroupGQL,
  OrganizationGroupQueryVariables,
  CreateOrganizationGroupGQL,
  CreateOrganizationGroupMutationVariables,
  UpdateOrganizationGroupGQL,
  UpdateOrganizationGroupMutationVariables,
  UpdateOrganizationGroupPermissionsGQL,
  UpdateOrganizationGroupPermissionsMutationVariables,
  UpdateOrganizationGroupStylesGQL,
  UpdateOrganizationGroupStylesMutationVariables,
  DeleteOrganizationGroupGQL,
  DeleteOrganizationGroupMutationVariables,
  OrganizationMembersGQL,
  OrganizationMembersQueryVariables,
  OrganizationMemberGQL,
  OrganizationMemberQueryVariables,
  CreateOrganizationMemberGQL,
  CreateOrganizationMemberMutationVariables,
  UpdateOrganizationMemberGQL,
  UpdateOrganizationMemberMutationVariables,
  TransferDocumentGQL,
  TransferDocumentMutationVariables,
  DeleteOrganizationMemberGQL,
  DeleteOrganizationMemberMutationVariables,
  OrganizationMembersInvitationsGQL,
  OrganizationMembersInvitationsQueryVariables,
  DeleteOrganizationMemberInvitationGQL,
  DeleteOrganizationMemberInvitationMutationVariables,
  ResendOrganizationMemberInvitationGQL,
  ResendOrganizationMemberInvitationMutationVariables
} from 'src/generated/graphql.default';
import { Apollo } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  readonly maxFreeGroups = 5;
  readonly additionalGroupPrice = 19.9;

  constructor(
    private organizationsGQL: OrganizationsGQL,
    private createOrganizationGQL: CreateOrganizationGQL,
    private updateOrganizationGQL: UpdateOrganizationGQL,
    private selectDefaultOrganizationGQL: SelectDefaultOrganizationGQL,
    private organizationGroupsGQL: OrganizationGroupsGQL,
    private organizationGroupsTotalGQL: OrganizationGroupsTotalGQL,
    private slimOrganizationGroupsGQL: SlimOrganizationGroupsGQL,
    private organizationGroupGQL: OrganizationGroupGQL,
    private createOrganizationGroupGQL: CreateOrganizationGroupGQL,
    private updateOrganizationGroupGQL: UpdateOrganizationGroupGQL,
    private updateOrganizationGroupPermissionsGQL: UpdateOrganizationGroupPermissionsGQL,
    private updateOrganizationGroupStylesGQL: UpdateOrganizationGroupStylesGQL,
    private deleteOrganizationGroupGQL: DeleteOrganizationGroupGQL,
    private organizationMembersGQL: OrganizationMembersGQL,
    private organizationMemberGQL: OrganizationMemberGQL,
    private createOrganizationMemberGQL: CreateOrganizationMemberGQL,
    private updateOrganizationMemberGQL: UpdateOrganizationMemberGQL,
    private transferDocumentGQL: TransferDocumentGQL,
    private deleteOrganizationMemberGQL: DeleteOrganizationMemberGQL,
    private organizationMembersInvitationsGQL: OrganizationMembersInvitationsGQL,
    private deleteOrganizationMemberInvitationGQL: DeleteOrganizationMemberInvitationGQL,
    private resendOrganizationMemberInvitationGQL: ResendOrganizationMemberInvitationGQL,
    private apollo: Apollo
  ) {}

  getOrganizations(options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'cache-first' }): Observable<SlimOrganization[]> {
    return this.organizationsGQL.fetch(null, options).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizations)
    );
  }

  watchOrganizations(options: { fetchPolicy: WatchQueryFetchPolicy } = { fetchPolicy: 'cache-first' }): Observable<SlimOrganization[]> {
    return this.organizationsGQL.watch(null, options).valueChanges.pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizations)
    );
  }

  organizationGroups(variables: OrganizationGroupsQueryVariables, options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'network-only' }) {
    return this.organizationGroupsGQL.fetch(variables, options).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationGroups)
    );
  }

  organizationGroupsTotal(variables: OrganizationGroupsTotalQueryVariables) {
    return this.organizationGroupsTotalGQL.fetch(variables, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationGroups.total - 1) // Tirando o "Sem grupo"
    );
  }

  slimOrganizationGroups(variables: SlimOrganizationGroupsQueryVariables, options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'network-only' }) {
    return this.slimOrganizationGroupsGQL.fetch(variables, options).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationGroups)
    );
  }

  organizationMembers(variables: OrganizationMembersQueryVariables, options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'no-cache' }) {
    return this.organizationMembersGQL.fetch(variables, options).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationMembers)
    );
  }

  organizationGroup(variables: OrganizationGroupQueryVariables) {
    return this.organizationGroupGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationGroup)
    );
  }

  organizationMember(variables: OrganizationMemberQueryVariables) {
    return this.organizationMemberGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationMember)
    );
  }

  organizationMembersInvitations(variables: OrganizationMembersInvitationsQueryVariables) {
    return this.organizationMembersInvitationsGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.invitations)
    );
  }

  create(variables: CreateOrganizationMutationVariables) {
    return this.createOrganizationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createOrganization)
    );
  }

  update(variables: UpdateOrganizationMutationVariables) {
    return this.updateOrganizationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateOrganization)
    );
  }

  selectDefault(variables: SelectDefaultOrganizationMutationVariables) {
    return of(null).pipe(
      tap(() => this.apollo.getClient().resetStore()),
      switchMap(() => this.selectDefaultOrganizationGQL.mutate(variables)),
      throwOnGraphqlError(),
      map(response => response.data.updateUserDefaultOrganization)
    );
  }

  createGroup(variables: CreateOrganizationGroupMutationVariables) {
    return this.createOrganizationGroupGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createGroup)
    );
  }

  updateGroup(variables: UpdateOrganizationGroupMutationVariables) {
    return this.updateOrganizationGroupGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateGroup)
    );
  }

  updateGroupPermissions(variables: UpdateOrganizationGroupPermissionsMutationVariables) {
    return this.updateOrganizationGroupPermissionsGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateGroupPermissions)
    );
  }

  updateGroupStyles(variables: UpdateOrganizationGroupStylesMutationVariables) {
    return this.updateOrganizationGroupStylesGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateGroupStyles)
    );
  }

  deleteGroup(variables: DeleteOrganizationGroupMutationVariables) {
    return this.deleteOrganizationGroupGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteGroup)
    );
  }

  createMember(membersVariables: CreateOrganizationMemberMutationVariables[]) {
    return forkJoin(
      membersVariables.map(variables => {
        return this.createOrganizationMemberGQL.mutate(variables).pipe(
          throwOnGraphqlError(),
          map(response => response.data.createOrganizationMember)
        );
      })
    );
  }

  updateMember(variables: UpdateOrganizationMemberMutationVariables) {
    return this.updateOrganizationMemberGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateOrganizationMember)
    );
  }

  deleteMember(variables: DeleteOrganizationMemberMutationVariables) {
    return this.deleteOrganizationMemberGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteOrganizationMember)
    );
  }

  deleteMemberInvitation(variables: DeleteOrganizationMemberInvitationMutationVariables) {
    return this.deleteOrganizationMemberInvitationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteInvitation)
    );
  }

  resendMemberInvitation(variables: ResendOrganizationMemberInvitationMutationVariables) {
    return this.resendOrganizationMemberInvitationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.resendInvitation)
    );
  }

  organizationMembersAndGroups(variables: { limit: number; page: number; search?: string }, options: { ignoreCache?: boolean } = {}) {
    return forkJoin([
      this.organizationGroups({ ...variables, limit: Math.floor(variables.limit / 2) }, { fetchPolicy: options.ignoreCache ? 'network-only' : 'cache-first' }),
      this.organizationMembers({ ...variables, limit: Math.floor(variables.limit / 2) }, { fetchPolicy: options.ignoreCache ? 'network-only' : 'cache-first' })
    ]).pipe(
      map(([groupsPage, membersPage]) => {
        let page: { current_page: number; last_page?: number; total?: number; data?: MembersAndGroups[] };
        page = groupsPage.last_page > membersPage.last_page ? { ...omit(groupsPage, ['data']) } : { ...omit(membersPage, ['data']) };
        page.data = (membersPage.data || []).map((item: any) => ({ id: item.user.id, name: item.user.name, type: 'member' }));
        page.data = page.data.concat((groupsPage.data || []).map((item: any) => ({ id: item.uuid || item.id, name: item.name, type: 'group' })));
        return page;
      })
    );
  }

  transferDocuments(documentIds: string[], variables: Omit<TransferDocumentMutationVariables, 'documentId'>) {
    return forkJoin(
      documentIds.map(id => {
        return this.transferDocumentGQL.mutate({ ...variables, documentId: id }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.transferDocument)
        );
      })
    );
  }

  isMemberAdmin(member: OrganizationMember) {
    return member.group && member.group.is_default && !!member.group.name.match(/^admin/i);
  }

  canMemberBeRemovedFromGroup$(member: OrganizationMember): Observable<boolean> {
    if (this.isMemberAdmin(member)) {
      return this.organizationGroup({ uuid: member.group.uuid }).pipe(map(group => group.members_count > 1));
    } else {
      return of(true);
    }
  }

  additionalGroupsCount(order: Order) {
    return Math.max((order?.subitems || []).filter(item => /grupo/i.test(item.description))[0]?.quantity, 0) || 0;
  }

  additionalGroupsMonths(plan?: Plan) {
    return plan?.interval || 1;
  }
}
