import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { OrganizationGroup, User } from '@app/models';
import { ErrorHandlerService, OrganizationService, UserService } from '@app/services';
import { AlertModalService } from '@app/shared';
import { CreateOrganizationGroupModalService } from './create-organization-group-modal/create-organization-group-modal.service';
import { EditOrganizationGroupModalService } from './edit-organization-group-modal/edit-organization-group-modal.service';
import { EditOrganizationGroupPermissionsModalService } from './edit-organization-group-permissions-modal/edit-organization-group-permissions-modal.service';
import { EditOrganizationGroupAppearanceModalService } from './edit-organization-group-appearance-modal/edit-organization-group-appearance-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-organization-groups',
  templateUrl: './configurations-organization-groups.component.html',
  styleUrls: ['../configurations.scss', './configurations-organization-groups.component.scss']
})
export class ConfigurationsOrganizationGroupsComponent implements OnInit, OnDestroy {
  currentUser: User;
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  groups: OrganizationGroup[] = [];
  total: number;

  constructor(
    public userService: UserService,
    public organizationService: OrganizationService,
    private errorHandlerService: ErrorHandlerService,
    private alertModalService: AlertModalService,
    private createOrganizationGroupModalService: CreateOrganizationGroupModalService,
    private editOrganizationGroupModalService: EditOrganizationGroupModalService,
    private editOrganizationGroupPermissionsModalService: EditOrganizationGroupPermissionsModalService,
    private editOrganizationGroupAppearanceModalService: EditOrganizationGroupAppearanceModalService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => (this.currentUser = user));

    this.loadGroups();
  }

  ngOnDestroy() {}

  loadGroups(options: { page?: number } = {}) {
    this.isLoading = true;
    this.pageNumber = options.page || 1;
    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(user => {
      this.organizationService
        .organizationGroups({ page: this.pageNumber, limit: 60, organizationId: user.organization.id })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          page => {
            this.total = page.total - 1; // Tirando o "Sem grupo"
            this.groups = (this.pageNumber === 1 ? [] : this.groups || []).concat(page.data);
            this.isLastPage = page.current_page >= page.last_page;
          },
          error => this.errorHandlerService.handle(error)
        );
    });
  }

  openCreateGroupModal() {
    this.createOrganizationGroupModalService.open().subscribe(() => this.loadGroups());
  }

  openEditGroupModal(group: OrganizationGroup) {
    this.editOrganizationGroupModalService.open({ group }).subscribe(data => (this.groups[this.groups.findIndex(item => item.id === data.id)] = data));
  }

  openEditGroupPermissionsModal(group: OrganizationGroup) {
    this.editOrganizationGroupPermissionsModalService.open({ group }).subscribe(data => (this.groups[this.groups.findIndex(item => item.id === data.id)] = data));
  }

  openEditGroupAppearanceModal(group: OrganizationGroup) {
    this.editOrganizationGroupAppearanceModalService.open({ group }).subscribe(data => (this.groups[this.groups.findIndex(item => item.id === data.id)] = data));
  }

  deleteGroup(group: OrganizationGroup) {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.deleteGroup'),
        confirmButtonText: this.translateService.instant('button.exclude')
      })
      .subscribe(() => {
        this.isLoading = true;
        this.organizationService.deleteGroup({ id: group.id }).subscribe(
          () => this.loadGroups(),
          error => {
            this.isLoading = false;
            this.errorHandlerService.handle(error);
          }
        );
      });
  }

  additionalGroupsCount() {
    return this.total >= 0 ? Math.max(this.total - this.organizationService.maxFreeGroups, 0) : 0;
  }
}
