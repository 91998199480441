<i *ngIf="!disableIcon" class="fal fa-file-plus"></i>
<div *ngIf="user" class="documents">
  <div
    class="documents__monthly"
    [class.alwaysVisible]="user.subscription.bonus === 0 || user.founder || userService.isSubscriptionPaid(user)"
    *ngIf="user.founder || userService.isSubscriptionPaid(user); else documentsCount"
  >
    <i class="far fa-infinity" [ngbTooltip]="tooltipSignatureCorporate" container="body"></i>
  </div>
  <ng-template #documentsCount>
    <div class="documents__monthly" [class.alwaysVisible]="user.subscription.bonus === 0" [ngbTooltip]="tooltipSignatureFree" container="body">
      {{ user.subscription.bonus > 0 ? user.subscription.bonus + user.subscription.documents : user.subscription.documents }}
    </div>
    <div class="documents__bonus" *ngIf="user.subscription.bonus > 0">{{ user.subscription.documents + ' + ' + user.subscription.bonus }}</div>
  </ng-template>
</div>
<ng-template #tooltipSignatureFree>
  <div class="tooltip__descr">{{ 'shared.howManyDocumentsYouCanCreate' | translate }}</div>
</ng-template>
<ng-template #tooltipSignatureCorporate>
  <div class="tooltip__descr">{{ 'shared.youCanCreateUnlimitedDocuments' | translate }}</div>
</ng-template>
