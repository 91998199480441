<div class="wrapper" #wrapper>
  <div class="wrapper__header">
    <h2 class="wrapper__title  beta">{{ 'documents.myContacts' | translate }}</h2>
  </div>
  <div class="multi-selection-box" *ngIf="contacts && (contacts | filterBy: { _isSelected: true }).length > 0">
    <h5 class="mb-4">
      <ng-container *ngIf="(contacts | filterBy: { _isSelected: true }).length">
        {{
          'documents.' + ((contacts | filterBy: { _isSelected: true }).length === 1 ? 'selectedContact' : 'selectedContacts')
            | translate: { contact: (contacts | filterBy: { _isSelected: true }).length }
        }}
      </ng-container>
    </h5>
    <button type="button" class="btn btn-sm btn-outline-danger" (click)="deleteSelectedContacts()">{{ 'button.exclude' | translate }}</button>
  </div>
  <div class="container">
    <div class="container__inside" #container__inside>
      <table class="table">
        <thead>
          <div class="columns-dropdown" display="dynamic" placement="bottom-right" appDropdown>
            <label appDropdownToggle><i class="fal fa-columns"></i></label>
            <div appDropdownMenu>
              <button type="button" class="dropdown-item-checkbox">
                <div (click)="changeColumn(contactName.value)" class="dropdown__div">
                  <input type="checkbox" class="checkbox__field" [checked]="columnControl.columns.c1" value="c1" #contactName />
                  <label class="checkbox__label" [for]="'contactName'"></label>
                  <p>{{ 'profile.name' | translate }}</p>
                </div>
              </button>
              <button type="button" class="dropdown-item-checkbox">
                <div (click)="changeColumn(contactEmail.value)" class="dropdown__div">
                  <input type="checkbox" class="checkbox__field" [checked]="columnControl.columns.c2" value="c2" #contactEmail />
                  <label class="checkbox__label" [for]="'contactEmail'"></label>
                  <p>{{ 'profile.email' | translate }}</p>
                </div>
              </button>
              <button type="button" class="dropdown-item-checkbox">
                <div (click)="changeColumn(contactBorn.value)" class="dropdown__div">
                  <input type="checkbox" class="checkbox__field" [checked]="columnControl.columns.c3" value="c3" #contactBorn />
                  <label class="checkbox__label" [for]="'contactBorn'"></label>
                  <p>{{ 'profile.birth' | translate }}</p>
                </div>
              </button>
              <button type="button" class="dropdown-item-checkbox">
                <div (click)="changeColumn(contactCPF.value)" class="dropdown__div">
                  <input type="checkbox" class="checkbox__field" [checked]="columnControl.columns.c4" value="c4" #contactCPF />
                  <label class="checkbox__label" [for]="'contactCPF'"></label>
                  <p>{{ 'profile.cpf' | translate }}</p>
                </div>
              </button>
              <!-- <button type="button" class="dropdown-item-checkbox">
                <div class="dropdown__div">
                  <input type="checkbox" class="checkbox__field" [checked]="columnControl.columns.c5" value="c5" #contactCreatedAt />
                  <label class="checkbox__label" [for]="'contactCreatedAt'"></label>
                  <p>Criado em</p>
                </div>
              </button> -->
            </div>
          </div>
          <tr class="table__headerRow">
            <th class="c0">
              <div class="contactMainCheckboxColumn">
                <input
                  type="checkbox"
                  class="checkbox__field"
                  [id]="'inpt-validationSmsId-'"
                  [indeterminate]="indetermineMainCheckbox()"
                  [checked]="isMainCheckboxChecked"
                  (change)="onChangeMainCheckbox(!isMainCheckboxChecked)"
                  #mainCheckbox
                />
                <label class="checkbox__label" [for]="'inpt-validationSmsId-'"></label>
                <div class="contacts__title dot" style="z-index: -1000;">.</div>
              </div>
            </th>
            <th resizable class="c1" *ngIf="columnControl.columns.c1" #c1>
              <div class="contacts__header">
                <div class="contacts__title">{{ 'profile.name' | translate }}</div>
                <input class="contacts__search" placeholder="{{ 'shared.searchEllips' | translate }}" #inputName [(ngModel)]="searchNameResult" (keyup)="searchContacts(inputName.value)" type="text" />
              </div>
            </th>
            <th resizable class="c2" *ngIf="columnControl.columns.c2" #c2>
              <div class="contacts__header">
                <div class="contacts__title">{{ 'profile.email' | translate }}</div>
                <input
                  class="contacts__search"
                  placeholder="{{ 'shared.searchEllips' | translate }}"
                  #inputEmail
                  [(ngModel)]="searchEmailResult"
                  (keyup)="searchContacts(inputEmail.value)"
                  type="text"
                />
              </div>
            </th>
            <th resizable class="c3" *ngIf="columnControl.columns.c3" #c3>
              <div class="contacts__header">
                <div class="contacts__title">{{ 'profile.birthDate' | translate }}</div>
                <input class="contacts__search" disabled placeholder="" type="text" />
              </div>
            </th>
            <th resizable class="c4" *ngIf="columnControl.columns.c4" #c4>
              <div class="contacts__header">
                <div class="contacts__title">{{ 'profile.cpf' | translate }}</div>
                <input class="contacts__search" disabled placeholder="" type="text" />
              </div>
            </th>
            <th resizable class="c5" *ngIf="columnControl.columns.c5" #c5>
              <div class="contacts__header">
                <div class="contacts__title">{{ 'profile.createdId' | translate }}</div>
                <input class="contacts__search" disabled type="text" placeholder="" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of contacts; let i = index" class="table_BodyRow">
            <td class="table_BodyRow_DataCell">
              <div class="contacts__checkbox">
                <input type="checkbox" class="checkbox__field" [(ngModel)]="contact._isSelected" [id]="'inpt-contactId-' + i" (change)="onChangeCheckbox()" />
                <label class="checkbox__label" [for]="'inpt-contactId-' + i"></label>
              </div>
            </td>
            <td class="table_BodyRow_DataCell" *ngIf="columnControl.columns.c1">
              <div class="contacts__data">
                <p class="contacts__data__user-name">{{ contact.name }}</p>
                <p class="contacts__data__company" *ngIf="contact?.company"><i class="far fa-building"></i>{{ contact.company }}</p>
              </div>
            </td>
            <td class="table_BodyRow_DataCell" *ngIf="columnControl.columns.c2">
              <div class="contacts__data">
                <p class="contacts__data__email"><i class="far fa-envelope"></i>{{ contact.email }}</p>
                <!-- <p class="contacts__data__phone"><i class="far fa-phone"></i>(54) 11111 1111</p> -->
              </div>
            </td>
            <td class="table_BodyRow_DataCell" *ngIf="columnControl.columns.c3">
              <div class="contacts__data">
                <p class="contacts__data__birthday">{{ contact.born }}</p>
              </div>
            </td>
            <td class="table_BodyRow_DataCell" *ngIf="columnControl.columns.c4">
              <div class="contacts__data">
                <p class="contacts__data__cpf">{{ contact.cpf | cpfCnpj }}</p>
              </div>
            </td>
            <td class="table_BodyRow_DataCell" *ngIf="columnControl.columns.c5">
              <div class="contacts__created">
                <p class="contacts__data__created-at">{{ contact.add }}</p>
                <div class="opts">
                  <div class="dropdown-table" placement="left" ngbDropdown>
                    <label class="dropdown-table-toggle" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
                    <div ngbDropdownMenu>
                      <button ngbDropdownItem (click)="deleteSelectedContacts(contact.id)">{{ 'button.exclude' | translate }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="noContact" *ngIf="contacts.length === 0">
        <div class="noContact__title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="noContact__text">
          <span>{{ 'documents.noContactsFound' | translate }} </span>
        </div>
      </div>
    </div>
    <div class="wrapper__more" *ngIf="showPagination">
      <button type="button" class="wrapper__more-button" (click)="loadMoreContacts()">{{ 'documents.loadMoreContacts' | translate }}</button>
    </div>
  </div>
</div>
