import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { Document, FloatingField, SecurityVerificationEnum, Signer, Whitelabel } from '@app/models';
import { AppService, DocumentService, ErrorHandlerService, WhitelabelService } from '@app/services';
import { CertificateModalService } from '@app/shared/certificate-modal/certificate-modal.service';
import { DocumentHistoryComponent } from './../documents/documents-show/document-history-sidebar/document-history-sidebar.component';
import { VerificationDataModalService } from '@app/pages/documents/shared/verification-data-modal/verification-data-modal.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  document: Document;
  floatingFields: FloatingField[];
  currentSigner: Signer;
  isLoading = false;
  showPdfViewer = false;
  showSigners = false;
  showHistory = false;
  showDownloadOptions = false;
  isHistoryAlreadyOpened = false;
  sidebarContainer: 'signers' | 'history' = 'signers';
  verificationForm: FormGroup;
  isDocumentWhitelabelLoaded: boolean = false;
  @ViewChild('historyComponent', { static: false }) historyComponent: DocumentHistoryComponent;
  @ViewChild('historyComponentMobile', { static: false }) historyComponentMobile: DocumentHistoryComponent;

  documentWhitelabel: Whitelabel = {
    main_color: null,
    logo: '',
    pdf_logo: '',
    pdf_logo_mini: ''
  };

  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    public documentService: DocumentService,
    private router: Router,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private whitelabelService: WhitelabelService,
    private certificateModalService: CertificateModalService,
    private verificationDataModalService: VerificationDataModalService
  ) {
    this.verificationForm = this.formBuilder.group({ code: '' });
  }

  ngOnInit() {
    if (this.route.snapshot.params.id) {
      this.documentService.documentWithFloatingFields({ id: this.route.snapshot.params.id }).subscribe(
        ({ document, floatingFields }) => {
          this.document = document;
          this.floatingFields = floatingFields;

          this.whitelabelService.loadWhitelabelByDocument({ documentId: this.document.id }).subscribe(whitelabel => {
            this.documentWhitelabel = whitelabel;
            this.documentWhitelabel.pdf_logo = whitelabel.pdf_logo || this.whitelabelService.pdfLogo;
            this.documentWhitelabel.pdf_logo_mini = whitelabel.pdf_logo_mini || this.whitelabelService.pdfLogoMini;

            this.whitelabelService.setFavicon(whitelabel.pdf_logo_mini);
            this.isDocumentWhitelabelLoaded = true;
          });

          if (this.route.snapshot.params.id.length < 49) {
            this.currentSigner = this.document.signatures.find(signer => signer.public_id === this.route.snapshot.params.id);
          }
          if (this.currentSigner && Object.keys(this.route.snapshot.queryParams || {}).includes('sv')) {
            this.openVerificationDataModal(this.currentSigner);
          }
          if (!this.documentService.isPastLifecycle(document) && !this.document.files?.original) {
            setTimeout(
              () =>
                this.documentService
                  .retryDocumentFile({ id: this.route.snapshot.params.id })
                  .pipe(untilDestroyed(this))
                  .subscribe(data => (this.document.files.original = data.files.original)),
              2000
            );
          }
        },
        error => {
          this.errorHandlerService.handle(error, { ignoreNotificationOn: ['document_not_found'] });
          this.router.navigate([this.appService.panelPath('/404')], { replaceUrl: true, state: { fromDocumentUrl: location.href } });
        }
      );
    }
  }

  isShowingExtra() {
    return this.showPdfViewer || this.showSigners || this.showDownloadOptions || this.showHistory;
  }

  returnShowExtraName() {
    if (this.showDownloadOptions) return 'Baixar';
    if (this.showPdfViewer) return 'Visualizar';
    if (this.showSigners) return 'Signatários';
    if (this.showHistory) return 'Histórico';
  }

  goBack() {
    this.showPdfViewer = false;
    this.showSigners = false;
    this.showDownloadOptions = false;
    this.showHistory = false;
  }

  changeSidebarContainer(container: 'signers' | 'history') {
    if (container === 'signers') this.sidebarContainer = 'signers';
    if (container === 'history' && this.document) {
      if (this.historyComponent.documentData.id == null && this.showHistory === false) {
        this.historyComponent.documentData = {
          id: this.document.id,
          created_at: this.document.created_at,
          author: {
            name: this.document.author.name,
            email: this.document.author.email
          }
        };
        this.historyComponent.refreshHistory();
      }
      if (this.historyComponentMobile.documentData.id == null && this.showHistory === true) {
        this.historyComponentMobile.documentData = {
          id: this.document.id,
          created_at: this.document.created_at,
          author: {
            name: this.document.author.name,
            email: this.document.author.email
          }
        };
        this.historyComponentMobile.refreshHistory();
      }

      this.sidebarContainer = 'history';
      this.isHistoryAlreadyOpened = true;
    }
  }

  openCertificateModal(signer: Signer) {
    this.certificateModalService.open({ signer }).subscribe();
  }

  verify() {
    this.isLoading = true;
    this.verificationForm.markAllAsTouched();
    this.documentService
      .signatureBySecurityVerificationCode({ code: (this.verificationForm.get('code').value || '').toUpperCase().replace(/[^A-z0-9]+/g, '') })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        signatureId => this.router.navigate(['/assinar/', signatureId], { queryParams: { signPrompt: 1 } }),
        error => this.errorHandlerService.handleValidation(this.verificationForm, error)
      );
  }

  openVerificationDataModal(signer: Signer) {
    this.verificationDataModalService
      .open({ signer, hideManualApproval: true }, signer.verifications.find(verification => verification.type === SecurityVerificationEnum.Live) ? { size: 'lg' } : {})
      .subscribe();
  }

  setUppercase(control: AbstractControl) {
    control.setValue((control.value || '').toUpperCase().replace(/[^A-z0-9]+/g, ''));
  }
}
