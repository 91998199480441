import { Pipe, PipeTransform } from '@angular/core';

/**
 * Displays a CEP into its correct format
 */
@Pipe({ name: 'cep' })
export class CepPipe implements PipeTransform {
  constructor() {}

  transform(text: string): string {
    const cep = String(text || '').replace(/[^0-9]/g, '');
    return cep.length > 5 ? cep.substring(5, 0) + '-' + cep.substring(5) : '';
  }
}
