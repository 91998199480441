import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

export type ModalResult = { reason: string };
// export interface ModalPublicProperties {}

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss']
})
export class RejectModalComponent {
  form: FormGroup;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ reason: null });
  }

  send() {
    this.modal.close(this.form.value);
  }
}
