import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { Folder, ContextEnum } from '@app/models';
import { FolderService } from '@app/services';
import { AlertModalService } from '@app/shared';
import { CreateFolderModalService } from './create-folder-modal/create-folder-modal.service';
import { UpdateFolderModalService } from './update-folder-modal/update-folder-modal.service';
import { FoldersModalService } from './folders-modal/folders-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class FoldersListingService {
  constructor(
    private folderService: FolderService,
    private alertModalService: AlertModalService,
    private createFolderModalService: CreateFolderModalService,
    private updateFolderModalService: UpdateFolderModalService,
    private foldersModalService: FoldersModalService,
    private translateService: TranslateService
  ) {}

  openFoldersModal(allFolders: Folder[], folder: Folder, isNewDocument?: boolean) {
    return this.foldersModalService.open({ targetType: 'folder', itemsToMove: [folder], isNewDocument }).pipe(
      tap(() =>
        allFolders.splice(
          allFolders.findIndex(item => item.id === folder.id),
          1
        )
      )
    );
  }

  delete(allFolders: Folder[], targetFolder: Folder) {
    return new Observable<boolean>(subscriber => {
      this.alertModalService
        .warning({
          text: this.translateService.instant('alerts.deleteFolders'),
          confirmButtonText: this.translateService.instant('button.exclude'),
          dismissAsError: true
        })
        .subscribe(
          () => {
            this.folderService
              .delete({ id: targetFolder.id })
              .pipe(
                tap(() =>
                  allFolders.splice(
                    allFolders.findIndex(item => item.id === targetFolder.id),
                    1
                  )
                ),
                finalize(() => subscriber.complete())
              )
              .subscribe(
                result => subscriber.next(result),
                error => subscriber.error(error)
              );
          },
          () => subscriber.complete()
        );
    });
  }

  openCreateFolderModal(context: ContextEnum, options: { groupId?: number; parentId?: string } = {}) {
    return this.createFolderModalService.open({ context, groupId: options.groupId, parentId: options.parentId });
  }

  openUpdateFolderModal(folder: Folder) {
    return this.updateFolderModalService.open({ folder });
  }
}
