import { Pipe, PipeTransform } from '@angular/core';

import { SlimDocument } from '@app/models';

@Pipe({ name: 'filterSigners' })
export class FilterSignersPipe implements PipeTransform {
  constructor() {}

  transform(signers: SlimDocument['signatures']): SlimDocument['signatures'] {
    return signers.filter(signer => signer.action);
  }
}
