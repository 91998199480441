<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" novalidate>
  <p class="centermodal__title">{{ 'documents.renameFolder' | translate }}</p>

  <div class="centermodal__form" formGroupName="folder">
    <label class="field__holder" [appFormControlWrap]="form.get('folder.name')">
      <input type="text" class="field" formControlName="name" name="name" autocomplete="none" minlength="3" role="presentation" required #nameInput appTrimSpaces />
      <span class="field__placeholder">{{ 'documents.folderName' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.renameFolder' | translate }}</button>
  </div>
</form>
