import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FloatingFieldRepeatEnum } from '@app/models';

export type ModalResult = FloatingFieldRepeatEnum;

@Component({
  selector: 'app-repeat-floating-field-modal',
  templateUrl: './repeat-floating-field-modal.component.html',
  styleUrls: ['./repeat-floating-field-modal.component.scss']
})
export class RepeatFloatingFieldModalComponent {
  form: FormGroup;
  FloatingFieldRepeatEnum = FloatingFieldRepeatEnum;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ option: null });
  }

  apply() {
    if (typeof this.form.get('option').value === 'number') {
      this.modal.close(this.form.get('option').value as ModalResult);
    } else {
      this.modal.dismiss();
    }
  }
}
