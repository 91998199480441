<div class="group">
  <div class="group__title">{{ 'menu.personal' | translate }}</div>
  <div class="group__options">
    <a routerLink="/perfil" class="group__option">
      <div class="group__option-icon"><i class="fal fa-user"></i></div>
      <div class="group__option-name">{{ 'profile.personalInfo' | translate }}</div>
    </a>
    <a routerLink="/perfil/notificacoes" class="group__option">
      <div class="group__option-icon"><i class="fal fa-bell"></i></div>
      <div class="group__option-name">{{ 'menu.notification' | translate }}</div>
    </a>
  </div>
</div>

<div class="group">
  <div class="group__title">{{ 'menu.organization' | translate }}</div>
  <div class="group__options">
    <a routerLink="/perfil/organizacao" class="group__option">
      <div class="group__option-icon"><i class="fal fa-cog"></i></div>
      <div class="group__option-name">{{ 'menu.settingsGeneral' | translate }}</div>
    </a>
    <a routerLink="/perfil/planos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-credit-card"></i></div>
      <div class="group__option-name">{{ 'menu.plansPayment' | translate }}</div>
    </a>
  </div>
</div>

<!-- <div class="group">
  <div class="group__title">Planos</div>
  <div class="group__options">
    <a routerLink="/perfil/planos" class="group__option">
      <div class="group__option-icon"><i class="fal fa-credit-card"></i></div>
      <div class="group__option-name">Planos e pagamento</div>
    </a>
  </div>
</div> -->

<div class="group">
  <div class="group__title">{{ 'menu.developer' | translate }}</div>
  <div class="group__options">
    <a routerLink="/perfil/api" class="group__option">
      <div class="group__option-icon"><i class="fal fa-key"></i></div>
      <div class="group__option-name">{{ 'menu.accessApi' | translate }}</div>
    </a>
  </div>
</div>

<app-menu-darkmode-button></app-menu-darkmode-button>
