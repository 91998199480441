<div class="d-flex">
  <app-mobile-header-main (titleClicked)="openTaxonomyMenu = $event" mainTitle="{{ 'menu.trash' | translate }}" menulink="/menu/documentos"></app-mobile-header-main>
  <div class="taxonomy-overlay" [class.taxonomy-overlay-active]="openTaxonomyMenu" (click)="openTaxonomyMenu = false"></div>
  <app-taxonomy
    *ngIf="whitelabelService.params.sidebar"
    mainTitle="{{ 'documents.documents' | translate }}"
    allowSearch
    [(isSearchOpen)]="isSearchOpen"
    (isSearchOpenChange)="toggleSearch($event)"
    [class.isMenuMobileOpen]="openTaxonomyMenu"
  >
    <app-documents-taxonomy [isSearchOpen]="isSearchOpen" (sortingChange)="loadDocuments({ page: 1, search: searchQuery })"></app-documents-taxonomy>
  </app-taxonomy>
</div>

<div class="d-flex flex-column w-100">
  <app-documents-search
    [(search)]="searchQuery"
    [isTrash]="true"
    (searchChange)="pageNumber = 1; loadDocuments({ page: 1, search: $event })"
    (closeClick)="isSearchOpen = false; toggleSearch(isSearchOpen)"
    *ngIf="isSearchOpen"
    @slideDownUpTrigger
  ></app-documents-search>

  <div class="documents">
    <div class="documents__header">
      <h2 class="documents__title" *ngIf="!((isSearchOpen && searchQuery) || mobileSearch)">{{ 'menu.trash' | translate }}</h2>
      <h2 class="documents__title" *ngIf="(isSearchOpen && searchQuery) || mobileSearch">{{ 'documents.searchResults' | translate }}</h2>
      <app-search [(query)]="mobileSearch" (queryChange)="pageNumber = 1; loadDocuments({ page: 1, search: { search: $event } })"></app-search>
    </div>

    <div class="documents-empty" *ngIf="!isLoading && !searchQuery && !mobileSearch && !appService.documentStatusFilter && documents && documents.length === 0; else documentsEmptyWithFilters">
      <i class="fal fa-file-alt documents-empty__icon"></i>
      {{ 'documents.nothingHereYet' | translate }}
    </div>

    <ng-template #documentsEmptyWithFilters>
      <div class="documents-filtered-empty" *ngIf="!isLoading && documents && (searchQuery || mobileSearch || appService.documentStatusFilter) && documents.length === 0">
        <div class="documents-filtered-empty_title">{{ 'settings.nothingHere' | translate }}</div>
        <div class="documents-filtered-empty_text">
          <span>{{ 'documents.noDocumentsMatchingFilter' | translate }}</span>
        </div>
        <button type="button" class="default-button" (click)="clearFilters()">{{ 'documents.clearFilters' | translate }}</button>
      </div>
    </ng-template>

    <app-documents-list-boxes
      *ngIf="appService.listFormat === ListFormat.Box"
      [documents]="documents"
      [hidePagination]="isLoading || isLastPage"
      [isTrash]="isTrash()"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      (restoreClick)="restore($event)"
    >
    </app-documents-list-boxes>

    <app-documents-list-table
      *ngIf="appService.listFormat === ListFormat.List"
      [documents]="documents"
      [hidePagination]="isLoading || isLastPage"
      [isTrash]="isTrash()"
      (loadPageClick)="loadDocuments({ page: pageNumber + 1, search: searchQuery })"
      (restoreClick)="restore($event)"
    >
    </app-documents-list-table>
  </div>
</div>
