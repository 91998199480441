<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" novalidate>
  <p class="centermodal__title">{{ 'documents.changeSignerEmail' | translate }}</p>

  <div class="centermodal__form" formGroupName="signature">
    <label class="field__holder" [appFormControlWrap]="form.get('signature.email')">
      <input
        type="email"
        class="field"
        formControlName="email"
        name="email"
        autocomplete="none"
        role="presentation"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        required
        appValidEmail
        #emailInput
      />
      <span class="field__placeholder">{{ 'documents.changeSignerEmail' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.changeEmail' | translate }}</button>
  </div>
</form>
