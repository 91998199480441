import { AfterContentInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Signer } from '@app/models';

export type ModalResult = void;
export interface ModalPublicProperties {
  signer: Signer;
}

@Component({
  selector: 'app-certificate-modal',
  templateUrl: './certificate-modal.component.html',
  styleUrls: ['./certificate-modal.component.scss']
})
export class CertificateModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() signer: Signer;

  constructor(public modal: NgbActiveModal) {}

  ngAfterContentInit() {
    // TODO: Fazer query para pegar dados do certificado
  }
}
