import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { PhoneService } from '@app/services/phone.service';

@Directive({
  selector: '[appValidPhone]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidPhoneDirective, multi: true }]
})
export class ValidPhoneDirective implements Validator {
  @Input('appValidPhone') isActive = true;

  static validator(control: AbstractControl, isActive: boolean = true): ValidationErrors | null {
    return (typeof isActive === 'boolean' && !isActive) || ValidPhoneDirective.isPhoneValid(control.value) ? null : { appValidPhone: true };
  }

  static isPhoneValid(phone: string) {
    return !phone || (typeof phone === 'string' && PhoneService.isValidPhone(phone));
  }

  constructor() {}

  validate(control: AbstractControl) {
    return ValidPhoneDirective.validator(control, this.isActive);
  }
}
