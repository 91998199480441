<div class="container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12">
      <div class="text-center">
        <h3>{{ 'httpError.underMaintenance' | translate }}</h3>
        <div>{{ 'httpError.sorryWeAreMakingImprovements' | translate }}</div>
        <div [innerHTML]="'httpError.weWillBeBackSoon' | translate"></div>
      </div>

      <div class="text-center mt-4" *ngIf="appService.isAutentiqueDomain()">
        <button type="button" class="btn btn-lg btn-primary rounded-pill pl-5 pr-5" [disabled]="isLoading" (click)="retry()">{{ 'button.retry' | translate }}</button>
      </div>
    </div>
  </div>
</div>
