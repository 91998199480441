// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  hmr: false,
  appName: env.npm_package_name,
  version: env.npm_package_version + '-staging',
  commitHash: env.GIT_HEAD,
  electron: false,
  debug: true,
  serverUrl: 'https://local-unraid-api.autentique.com.br',
  panelUrl: 'https://devpainel.autentique.com.br',
  validHostsRegExp: 'valida.ae|autentique.com.br|localhost:|192.168.1.',
  defaultLanguage: 'pt-BR',
  supportedLanguages: ['pt-BR', 'en-US'],
  sentryDsn: '',
  intercomAppId: '',
  googleTagManagerId: '',
  oauthGoogle: '1044630865545-tjiuhqlvulksrgev7v8fba6ns83jaeet.apps.googleusercontent.com',
  oauthFacebook: '403038750724817',
  oauthMicrosoft: '05f0b9db-cb92-484f-807a-0946be31a2e1',
  googleDeveloperKey: 'AIzaSyAbyIwyDOeeqRkBbl3jkI_wZ7hmMHjxuNM',
  wootricKey: '',
  firebaseApiKey: 'AIzaSyBpS5Qi9V4OBHAyDHsNbrU6dhxnE-I1J2I',
  firebaseAuthDomain: 'autentique-development.firebaseapp.com',
  firebaseProjectId: 'autentique-development',
  firebaseConfig: {
    apiKey: 'AIzaSyBpS5Qi9V4OBHAyDHsNbrU6dhxnE-I1J2I',
    authDomain: 'autentique-development.firebaseapp.com',
    databaseURL: 'https://autentique-development.firebaseio.com',
    projectId: 'autentique-development',
    storageBucket: 'autentique-development.appspot.com',
    messagingSenderId: '1044630865545',
    appId: '1:1044630865545:web:0b5c6107e0070574a16858'
  },
  stripePublicKey: 'pk_test_51JCUB4BcZ7ytgOad1MGI8Ik3IjvmSSetWVzHRcNa9bStldo5vNTAddXWpXTUNnHzoCw4jmorTE49htWgcE9WqKAW00FgToTIry'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
