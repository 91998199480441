import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { default as SignaturePad } from 'signature_pad';
import { filter } from 'rxjs/operators';
import { dataURLToBlob } from 'blob-util';

import { untilDestroyed } from '@app/core';
import { FontEnum, SignatureFormat, User } from '@app/models';
import { UserService } from '@app/services';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements AfterViewInit, OnChanges, OnDestroy {
  signaturePad: SignaturePad;
  initialsPad: SignaturePad;
  SignatureFormat = SignatureFormat;
  selectedSignatureImageUrl: string;
  selectedInitialsImageUrl: string;
  isPadOn = true;
  currentUser: User;
  @Input() format: SignatureFormat | string;
  @Input() font: FontEnum | string;
  @Input() isLoading: boolean;
  @Input() disableSignature: boolean;
  @Input() disableInitials: boolean;
  @Input() disableFormat: boolean;
  @Output() formatChange = new EventEmitter<SignatureFormat | string>();
  private selectedSignatureImage: File;
  private selectedInitialsImage: File;
  @ViewChild('signatureCanvas', { static: false }) private signatureCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('signatureLine', { static: false }) private signatureLine: ElementRef<HTMLDivElement>;
  @ViewChild('initialsCanvas', { static: false }) private initialsCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('initialsLine', { static: false }) private initialsLine: ElementRef<HTMLDivElement>;

  constructor(private userService: UserService) {}

  ngAfterViewInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.selectedSignatureImageUrl = this.currentUser.settings.signature;
        this.selectedInitialsImageUrl = this.currentUser.settings.initials;
      });

    setTimeout(() => {
      this.resizePad();
      if (this.signatureCanvas) {
        this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, { penColor: '#1c324d' });
      }
      if (this.initialsCanvas) {
        this.initialsPad = new SignaturePad(this.initialsCanvas.nativeElement, { penColor: '#1c324d' });
      }
      try {
        (document.activeElement as HTMLElement).blur();
      } catch (e) {}
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.format && changes.format.currentValue) {
      setTimeout(() => this.selectPreviewMode(changes.format.currentValue));
    }
    if (changes.isLoading) {
      this.togglePad(!changes.isLoading.currentValue);
    }
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.selectedSignatureImageUrl);
    URL.revokeObjectURL(this.selectedInitialsImageUrl);
  }

  togglePad(isEnabled: boolean) {
    if (this.signaturePad) {
      isEnabled ? this.signaturePad.on() : this.signaturePad.off();
    }
    if (this.initialsPad) {
      isEnabled ? this.initialsPad.on() : this.initialsPad.off();
    }
  }

  clearSignature() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  clearInitials() {
    if (this.initialsPad) {
      this.initialsPad.clear();
    }
  }

  clear() {
    this.clearSignature();
    this.clearInitials();
  }

  isEmptySignature() {
    return (
      !this.signaturePad ||
      (this.format === SignatureFormat.Draw && this.signaturePad.isEmpty()) ||
      (this.format === SignatureFormat.Image && !this.selectedSignatureImage && !this.selectedSignatureImageUrl)
    );
  }

  isEmptyInitials() {
    return (
      !this.initialsPad ||
      (this.format === SignatureFormat.Draw && this.initialsPad.isEmpty()) ||
      (this.format === SignatureFormat.Image && !this.selectedInitialsImage && !this.selectedInitialsImageUrl)
    );
  }

  isEmpty() {
    return (!this.disableSignature && (this.isEmptySignature() || this.isMinSize('signature'))) || (!this.disableInitials && (this.isEmptyInitials() || this.isMinSize('initials')));
  }

  isMinSize(type: 'signature' | 'initials') {
    const sizes = this.canvasSizes((type === 'signature' ? this.signatureCanvas : this.initialsCanvas).nativeElement);
    return sizes.trimWidth < 30 || sizes.trimHeight < 30;
  }

  toImageFile(type: 'signature' | 'initials', fileType: string = 'image/png', fileName: string = 'drawn-signature.png') {
    if (this.format === SignatureFormat.Image) {
      return type === 'signature' ? this.selectedSignatureImage : this.selectedInitialsImage;
    } else if (this.format === SignatureFormat.Draw) {
      return (type === 'signature' && !this.isEmptySignature()) || (type === 'initials' && !this.isEmptyInitials())
        ? new File([dataURLToBlob(this.trimCanvas((type === 'signature' ? this.signatureCanvas : this.initialsCanvas).nativeElement).toDataURL(fileType))], fileName, {
            lastModified: new Date().getTime()
          })
        : null;
    }
  }

  selectPreviewMode(format: SignatureFormat) {
    this.format = format;
    this.formatChange.emit(this.format);
    if (!this.format || this.format === SignatureFormat.Draw) {
      this.isPadOn = true;
      if (this.signaturePad) {
        this.signaturePad.on();
        // Inicia canvas com imagem desenhada, mas não funciona porquê dá erro "The canvas has been tainted by cross-origin data"
        // if (this.signaturePad.isEmpty() && this.currentUser.settings.signature_draw) {
        //   this.signaturePad.fromDataURL(this.currentUser.settings.signature_draw);
        // }
      }
      if (this.initialsPad) {
        this.initialsPad.on();
        // Inicia canvas com imagem desenhada, mas não funciona porquê dá erro "The canvas has been tainted by cross-origin data"
        // if (this.initialsPad.isEmpty() && this.currentUser.settings.initials_draw) {
        //   this.initialsPad.fromDataURL(this.currentUser.settings.initials_draw);
        // }
      }
    } else {
      this.clear();
      this.isPadOn = false;
      if (this.signaturePad) {
        this.signaturePad.off();
      }
      if (this.initialsPad) {
        this.initialsPad.off();
      }
    }
  }

  selectSignatureImage(image: File) {
    if (image) {
      URL.revokeObjectURL(this.selectedSignatureImageUrl);
      this.selectedSignatureImage = image;
      this.selectedSignatureImageUrl = URL.createObjectURL(image);
    }
  }

  selectInitialsImage(image: File) {
    if (image) {
      URL.revokeObjectURL(this.selectedInitialsImageUrl);
      this.selectedInitialsImage = image;
      this.selectedInitialsImageUrl = URL.createObjectURL(image);
    }
  }

  private resizePad() {
    if (
      this.signatureCanvas &&
      this.signatureCanvas.nativeElement.parentElement.offsetWidth > 0 &&
      this.signatureCanvas.nativeElement.width !== this.signatureCanvas.nativeElement.parentElement.offsetWidth
    ) {
      this.signatureCanvas.nativeElement.width = this.signatureCanvas.nativeElement.parentElement.offsetWidth;
      this.signatureCanvas.nativeElement.height = this.signatureCanvas.nativeElement.width * (window.innerWidth <= 992 ? 0.4 : 0.333);
      this.signatureLine.nativeElement.style.bottom = this.signatureCanvas.nativeElement.height / 5 + 'px';
      this.clearSignature();
    }
    if (
      this.initialsCanvas &&
      this.initialsCanvas.nativeElement.parentElement.offsetWidth > 0 &&
      this.initialsCanvas.nativeElement.width !== this.initialsCanvas.nativeElement.parentElement.offsetWidth
    ) {
      this.initialsCanvas.nativeElement.width = this.initialsCanvas.nativeElement.parentElement.offsetWidth;
      this.initialsCanvas.nativeElement.height = this.initialsCanvas.nativeElement.width * (this.disableSignature || window.innerWidth <= 992 ? 0.5 : 0.79);
      this.initialsLine.nativeElement.style.bottom = this.initialsCanvas.nativeElement.height / 5 + 'px';
      this.clearInitials();
    }
  }

  @HostListener('window:resize') private onResize() {
    this.resizePad();
  }

  private canvasSizes(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d');
    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const length = pixels.data.length;
    const bound: { top?: number; bottom?: number; left?: number; right?: number } = {};

    // Iterate over every pixel to find the highest
    // and where it ends on every axis ()
    for (let i = 0; i < length; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        const x = (i / 4) % canvas.width;
        const y = Math.floor(i / 4 / canvas.width);

        if (bound.top === undefined) {
          bound.top = y;
        }

        if (bound.left === undefined) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }

        if (bound.right === undefined) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }

        if (bound.bottom === undefined) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }

    // Calculate the height and width of the content
    const trimHeight = bound.bottom - bound.top;
    const trimWidth = bound.right - bound.left;
    return { boundTop: bound.top, boundBottom: bound.bottom, boundLeft: bound.left, boundRight: bound.right, trimWidth, trimHeight };
  }

  private trimCanvas(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d');
    const copy = document.createElement('canvas').getContext('2d');
    const sizes = this.canvasSizes(canvas);
    const trimmed = ctx.getImageData(sizes.boundLeft, sizes.boundTop, sizes.trimWidth, sizes.trimHeight);

    copy.canvas.width = sizes.trimWidth;
    copy.canvas.height = sizes.trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // Return trimmed canvas
    return copy.canvas;
  }
}
