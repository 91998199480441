import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { User, Whitelabel } from '@app/models';
import { ErrorHandlerService, UserService, WhitelabelService } from '@app/services';
import { filter } from 'rxjs/operators';
import { untilDestroyed } from '@app/core';
import { WhitelabelSendgridDns } from 'src/generated/graphql.default';

export enum contextMenuState {
  default = 0,
  domainName = 1,
  emailAdress = 2,
  signingPage = 3,
  signedDocument = 4
}

@Component({
  selector: 'app-configurations-whitelabel',
  templateUrl: './configurations-whitelabel.component.html',
  styleUrls: ['../configurations.scss', './configurations-whitelabel.component.scss']
})
export class ConfigurationsWhitelabelComponent implements OnInit, OnDestroy {
  @Input() contextDefault: boolean = false;

  currentUser: User;
  contextMenuState = contextMenuState;
  contextMenu: contextMenuState = contextMenuState.default;

  whitelabelData: Whitelabel;
  sendgridDns: WhitelabelSendgridDns;

  isLoading = false;

  isDarkmode: boolean = false;

  constructor(private whitelabelService: WhitelabelService, private userService: UserService, private errorHandlerService: ErrorHandlerService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
      });
    this.loadWhitelabel();
  }

  ngOnDestroy(): void {}

  loadWhitelabel() {
    this.whitelabelService.loadCurrentWhitelabel().subscribe(whitelabelData => {
      this.whitelabelData = whitelabelData;
      this.changeDarkMode();
      if (whitelabelData.sendgrid_domain) {
        this.loadSendgriDns();
      } else {
        this.isLoading = false;
      }
    });
  }

  loadSendgriDns() {
    if (!this.whitelabelData || !this.whitelabelData.sendgrid_domain) {
      return;
    }
    this.whitelabelService.sendgridDns({ sendgrid_domain: this.whitelabelData.sendgrid_domain }).subscribe(
      sendgridDns => {
        this.sendgridDns = sendgridDns;
        this.isLoading = false;
      },
      error => {
        this.errorHandlerService.handle(error);
      }
    );
  }

  changeContext(context: contextMenuState) {
    this.contextMenu = context;
  }

  changeDarkMode() {
    if (this.whitelabelData.is_darkmode) {
      this.isDarkmode = true;
    } else this.isDarkmode = false;
  }

  goBack(context: contextMenuState) {
    this.sendgridDns = {};
    this.whitelabelService.loadCurrentWhitelabel().subscribe(whitelabelData => {
      this.whitelabelData = whitelabelData;
      this.changeDarkMode();
      this.contextMenu = context;
      this.loadSendgriDns();
    });
  }
}
