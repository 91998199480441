<article class="configuration-item d-none d-lg-block">
  <div class="configuration-container">
    <div class="configuration-content">
      <div class="configuration-title mb-1">
        {{ 'whitelabel.emailAddressCustomization' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
      </div>
      <div class="configuration-text" [innerHTML]="'whitelabel.useYourOwnEmail' | translate"></div>
      <div class="configuration-switches-item mt-4 mb-4">
        <app-switch [(ngModel)]="emailToggle"></app-switch>
        <span class="configuration-switches-name fw400">{{ 'whitelabel.customizeSendingEmailAddress' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="pageSize" [class.d-none]="domainSteps === emailDomainSteps.verification || !emailToggle">
    <div class="configuration-subtitle">{{ 'whitelabel.sendingDomain' | translate }}</div>
    <div class="configuration-text configuration-subtitle-text">{{ 'whitelabel.domainExample' | translate }}</div>
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-lg-8">
            <label class="field__holder">
              <input
                type="text"
                class="field"
                [(ngModel)]="domainPlaceholder"
                [class.empty]="!domainPlaceholder && !whitelabelData?.sendgrid_domain"
                placeholder="{{ whitelabelData?.sendgrid_domain ? whitelabelData.sendgrid_domain : '' }}"
              />
              <span class="field__placeholder">{{ 'whitelabel.domain' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="configuration-subtitle mt-3">{{ 'whitelabel.senderEmail' | translate }}</div>
    <div class="configuration-text configuration-subtitle-text">{{ 'whitelabel.emailSenderAddress' | translate }}</div>
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-lg-8">
            <label class="field__holder">
              <input
                type="text"
                id="domainInput"
                (input)="adjustDomainPlaceholderPosition()"
                class="field domainPlaceholder"
                [(ngModel)]="emailPlaceholder"
                [class.empty]="!emailPlaceholder && !whitelabelData?.sendgrid_email_user"
                placeholder="{{ returnEmail() }}"
              />
              <span class="field__placeholder">{{ 'whitelabel.email' | translate }}</span>
              <span class="field__dinamicDomain">{{ emailPlaceholder ? '@' + (domainPlaceholder ? domainPlaceholder : whitelabelData?.sendgrid_domain) : '' }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pageSize" *ngIf="domainSteps === emailDomainSteps.verification && emailToggle">
    <div class="configuration-subtitle">{{ 'whitelabel.DNSRecords' | translate }}</div>
    <div class="configuration-text configuration-subtitle-text">{{ 'whitelabel.includeRecordsInDNS' | translate }}</div>
    <div class="row">
      <div class="col-xl-8">
        <div class="row"></div>
      </div>
    </div>
    <table>
      <tr>
        <th class="th-1"></th>
        <th class="th-2">{{ 'whitelabel.recordType' | translate }}</th>
        <th class="th-3">{{ 'whitelabel.host' | translate }}</th>
        <th class="th-4">{{ 'whitelabel.value' | translate }}</th>
      </tr>
      <ng-container *ngIf="!isDomainLoading; else domainWithBlur">
        <tr *ngFor="let dns of sendgridDns?.dns">
          <td>
            <i class="fa-regular fa-{{ dns.valid ? 'check-circle green' : 'times-circle red' }}"></i>
          </td>
          <td>{{ dns.type || '-' | uppercase }}</td>
          <td>
            <div class="line">
              <p class="line_1">
                {{ dns.host || '-' }}
              </p>
              <i class="fal fa-clone" (click)="copyToClipboard(dns.host)"></i>
            </div>
          </td>
          <td>
            <div class="line">
              <p class="line_2">
                {{ dns.data || '-' }}
              </p>
              <i class="fal fa-clone" (click)="copyToClipboard(dns.data)"></i>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #domainWithBlur>
        <tr *ngFor="let dns of sendgridDns?.dns">
          <td>
            <i class="fa-regular fa-times-circle" style="--fa-primary-color: #ffea00; --fa-secondary-color: #ffea00;"></i>
          </td>
          <td><div class="placeholder-content"></div></td>
          <td><div class="placeholder-content"></div></td>
          <td><div class="placeholder-content"></div></td>
        </tr>
      </ng-template>
    </table>
  </div>
  <div class="button_navigation">
    <button type="button" class="centermodal__button" (click)="changeContext(contextMenuState.default)">
      {{ domainSteps === emailDomainSteps.default ? ('button.cancel' | translate) : ('button.back' | translate) }}
    </button>
    <div class="button_doubleBtn">
      <button
        *ngIf="domainSteps === emailDomainSteps.verification && emailToggle"
        type="button"
        #verifyButton
        class="centermodal__button centermodal__button--custom-next configuration-button"
        (click)="verifyDomainEmail()"
      >
        {{ 'button.verify' | translate }}
      </button>
      <button
        *ngIf="emailToggle"
        type="button"
        class="centermodal__button centermodal__button--custom-next ml-3"
        (click)="domainForwardEmail()"
        [disabled]="!isEmailVerified && domainSteps === emailDomainSteps.verification"
        #saveButton
      >
        {{ (whitelabelData && !emailToggle) || domainSteps === emailDomainSteps.verification ? ('button.save' | translate) : ('button.forward' | translate) }}
      </button>
      <button *ngIf="!emailToggle && whitelabelData.sendgrid_domain" type="button" class="centermodal__button centermodal__button--custom-next ml-3" (click)="domainForwardEmptyEmail()">
        {{ 'button.save' | translate }}
      </button>
    </div>
  </div>
</article>
