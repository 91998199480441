import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { I18nService } from '@app/core';
import { UserService } from '@app/services';

/**
 * Localized date pipe (i18n)
 */
@Pipe({ name: 'ldate' })
export class LdatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private i18nService: I18nService, private userService: UserService) {}

  // default date format: for user config dd/MM/yyyy or MM/dd/yyyy
  // result ex: 30/12/2023 or 12/30/2023
  // Possible call in html: {{ date | ldate }} or {{ date | ldate: 'long' }}
  transform(value: any, format?: string, timezone?: string, lang?: string) {
    if ((value || '').match(/^[0-9]+-[0-9]+-[0-9]+\s[0-9]+:[0-9]+:[0-9]+$/)) {
      value = value.replace(/\s/, 'T');
    }
    const defaultFormat = this.i18nService.transformDateFormat(this.userService.getUserLocale()?.date_format) || null;
    const defaultTimezone = this.i18nService.getTimezoneOffset(this.userService.getUserLocale()?.timezone) || null;
    return this.datePipe.transform(value, format || defaultFormat, timezone || defaultTimezone, lang || this.i18nService.language);
  }
}
