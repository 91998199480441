<div class="modal-body">
  <p class="centermodal__title">{{ 'settings.attention' | translate }}</p>
  <div class="text-center">
    <img src="assets/alerts/warning.svg" class="centermodal__img" />
  </div>
  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <p class="centermodal__message centermodal__message--text-center">
      {{ 'settings.' + (isLastMember ? 'excludeFromOrganizationConfirm' : 'leaveFromOrganizationConfirm') | translate }}<br />
      {{ 'settings.fillOrganizationName' | translate }}<br />
      ({{ user?.organization?.name }})
    </p>
  </div>
  <form (ngSubmit)="deleteSelfOrganizationMember()" [formGroup]="form" novalidate>
    <div class="centermodal__form">
      <label class="field__holder" [appFormControlWrap]="form.get('name')">
        <input type="text" class="field" formControlName="name" name="name" autocomplete="none" role="presentation" required #nameInput />
        <span class="field__placeholder">{{ 'profile.organizationName' | translate }}</span>
      </label>
    </div>
    <div class="centermodal__button-group">
      <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">
        {{ 'settings.' + (isLastMember ? 'excludeFromOrganization' : 'leaveFromOrganization') | translate }}
      </button>
    </div>
  </form>
</div>
