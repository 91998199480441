<div>
  <div class="document-loading">
    <div class="wrap">
      <div class="center">
        <div class="document-loader">
          <span class="heading short"></span>
          <span class="line short"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line short"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line short"></span>
          <span class="heading"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line short"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line short"></span>
        </div>
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</div>
