<header class="mobile-header">
  <div class="mobile-header__button" *ngIf="whitelabelService.params.mobileNavigation">
    <a *ngIf="!hasNewMenu" (click)="goBackClick()" class="mobile-header__back">{{ 'button.back' | translate }}</a>
    <label *ngIf="hasNewMenu" [for]="menuID" class="mobile-header__back">{{ 'button.back' | translate }}</label>
  </div>
  <div class="mobile-header__title">{{ title }}</div>
  <div class="mobile-header__button mobile-header__button--right">
    <ng-content></ng-content>
  </div>
</header>
