import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-token-field',
  templateUrl: './token-field.component.html',
  styleUrls: ['./token-field.component.scss']
})
export class TokenFieldComponent {
  @Input() isVerifyingToken = false;
  @Output() tokenChange = new EventEmitter<string>();
  @Output() submit = new EventEmitter<string>();
  @ViewChildren('tokenField') private inputs: QueryList<ElementRef<HTMLInputElement>>;

  constructor() {}

  checkField(evt: KeyboardEvent) {
    const target = evt.target as HTMLInputElement;
    if ((target.value || '').length >= 6) {
      setTimeout(() => this.paste(target.value));
    } else if (evt.key) {
      const inputs = this.inputs.map(input => input.nativeElement);
      const index = inputs.indexOf(target);

      if (['Backspace', 'ArrowLeft', 'ArrowRight'].includes(evt.key) || this.isNumber(evt)) {
        evt.stopPropagation();
        evt.preventDefault();
      }

      if (evt.key === 'Backspace') {
        if (target.value) {
          target.value = '';
        } else if (index > 0) {
          inputs[index - 1].value = '';
          inputs[index - 1].focus();
        }
      } else if (evt.key === 'ArrowLeft' && index > 0) {
        inputs[index - 1].focus();
      } else if (evt.key === 'ArrowRight' && index < 5) {
        inputs[index + 1].focus();
      } else if (this.isNumber(evt)) {
        if (index >= 0 && index < 5) {
          inputs[index + 1].focus();
        }
        target.value = evt.key;
      }
      this.trySendingToken();
    }
  }

  pasteOnFields(evt: ClipboardEvent) {
    this.paste(evt.clipboardData.getData('text'));
  }

  private isNumber(evt: KeyboardEvent) {
    return !!evt.key.match(/[0-9]+/g) && !evt.altKey && !evt.ctrlKey && !evt.metaKey;
  }

  private paste(text: string) {
    const pastedText = (text || '').replace(/[^0-9]/g, '').slice(0, 6);
    this.inputs.forEach((input, index) => (input.nativeElement.value = pastedText[index] || ''));
    this.trySendingToken();
  }

  private trySendingToken() {
    const fullValue = this.inputs.map(input => input.nativeElement.value || '').join('');
    this.tokenChange.emit(fullValue);
    if (fullValue.length === 6) {
      this.submit.emit(fullValue);
    }
  }
}
