import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash';

import { CustomSignerInput } from '@app/models';

@Pipe({ name: 'uniqueSigners', pure: false })
export class UniqueSignersPipe implements PipeTransform {
  constructor() {}

  transform(signers: CustomSignerInput[], ignoreAction?: boolean): CustomSignerInput[] {
    return uniqBy(
      (signers || []).filter(signer => signer[signer._extra.signerMainAttribute]),
      ignoreAction ? this.identificationWithoutAction : this.identificationWithAction
    );
  }

  identification(signer: CustomSignerInput, ignoreAction?: boolean) {
    return ignoreAction ? this.identificationWithoutAction(signer) : this.identificationWithAction(signer);
  }

  private identificationWithoutAction(signer: CustomSignerInput) {
    return (signer[signer._extra.signerMainAttribute] || '').trim().toLowerCase();
  }

  private identificationWithAction(signer: CustomSignerInput) {
    return (signer.action + (signer[signer._extra.signerMainAttribute] || '').trim()).toLowerCase();
  }
}
