import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { DocumentService, ErrorHandlerService, NotyService, UserService } from '@app/services';
import { SecurityVerificationType } from '@app/models';
import { LoaderService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = SecurityVerificationType;
export interface ModalPublicProperties {
  verification: SecurityVerificationType;
}

@Component({
  selector: 'app-verification-id-analyzer-modal',
  templateUrl: './verification-id-analyzer-modal.component.html',
  styleUrls: ['./verification-id-analyzer-modal.component.scss']
})
export class VerificationIdAnalyzerModalComponent implements OnInit, ModalPublicProperties, AfterContentInit {
  @Input() verification: SecurityVerificationType;
  isLoading = false;
  isMobile = false;
  isTimedOut = true;

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private documentService: DocumentService,
    private notyService: NotyService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.isMobile = window.innerWidth <= 992;
  }

  ngAfterContentInit() {
    if (!this.verification?.payload?.url) {
      this.documentService.generateSecurityVerificationCode({ id: this.verification.id }).subscribe(
        verificationPayload => (this.verification.payload = verificationPayload),
        error => this.errorHandlerService.handle(error)
      );
    }
    setTimeout(() => (this.isTimedOut = false), 20000);
  }

  checkSecurityVerification() {
    this.isLoading = true;
    this.loaderService.show();

    this.documentService
      .checkSecurityVerification({ id: this.verification.id })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        response => {
          if (response.payload?.failcode) {
            this.notyService.warning(this.translateService.instant('notyService.biometricRejectedManualVerification'));
            this.modal.close(response as ModalResult);
          } else if (!response.payload?.failcode && !response.verified_at) {
            this.notyService.warning(this.translateService.instant('notyService.biometricNotProcessed'), 15000);
          } else {
            this.modal.close(response as ModalResult);
          }
        },
        error => {
          this.errorHandlerService.handle(error);
        }
      );
  }
}
