import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LoaderComponent } from './loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  httpLoading: BehaviorSubject<boolean>;
  httpActivationTimeout = 5000;
  httpDeactivationTimeout = 200;
  disableHttpLoader = false;
  private loaderComponentRef: ComponentRef<LoaderComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private applicationRef: ApplicationRef, private injector: Injector) {
    this.httpLoading = new BehaviorSubject<boolean>(false);
    let httpActivationTimeoutId: any;
    let httpDeactivationTimeoutId: any;

    this.httpLoading.pipe(filter(() => !this.disableHttpLoader)).subscribe(isActive => {
      // Ativa loader após tempo em 'httpActivationTimeout' e desativa somente após fim de requests subsequentes (que sejam rodados em menos de 'httpDeactivationTimeout' desde o último request)
      if (isActive) {
        clearTimeout(httpDeactivationTimeoutId);
        httpDeactivationTimeoutId = null;
        if (!httpActivationTimeoutId) {
          httpActivationTimeoutId = setTimeout(() => {
            if (!this.disableHttpLoader && this.httpLoading.getValue()) {
              this.createLoader();
            }
            httpActivationTimeoutId = null;
          }, this.httpActivationTimeout);
        }
      } else {
        if (!httpDeactivationTimeoutId) {
          httpDeactivationTimeoutId = setTimeout(() => {
            if (!this.disableHttpLoader) {
              this.destroyLoader();
            }
            httpDeactivationTimeoutId = null;
          }, this.httpDeactivationTimeout);
        }
      }
    });
  }

  show() {
    this.disableHttpLoader = true;
    this.createLoader();
  }

  hide() {
    this.disableHttpLoader = false;
    this.destroyLoader();
  }

  private createLoader() {
    if (!this.loaderComponentRef) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
      const componentRef = componentFactory.create(this.injector);
      this.applicationRef.attachView(componentRef.hostView);
      this.loaderComponentRef = componentRef;

      document.body.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
    }
  }

  private destroyLoader() {
    if (this.loaderComponentRef) {
      this.applicationRef.detachView(this.loaderComponentRef.hostView);
      this.loaderComponentRef.destroy();
      this.loaderComponentRef = null;
    }
  }
}
