import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { DndModule } from 'ngx-drag-drop';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { QRCodeModule } from 'angularx-qrcode';

import { SharedModule } from '@app/shared';
import { ResizableModule } from '@app/shared/directives/resizable/resizable.module';
import { DocumentsRoutingModule } from './documents-routing.module';
import { UniqueSignersPipe } from './shared/unique-signers.pipe';
import { SortByCurrentSignersPipe } from './shared/sort-by-current-signers.pipe';
import { DocumentsListBoxesComponent } from './shared/documents-list-boxes/documents-list-boxes.component';
import { DocumentsTitleDropdownComponent } from './shared/documents-title-dropdown/documents-title-dropdown.component';
import { SignerInputComponent, HighlightPipe } from './shared/signer-input/signer-input.component';
import { SignerFloatingFieldsInputComponent } from './shared/signer-floating-fields-input/signer-floating-fields-input.component';
import { DocumentConfigsFormComponent } from './shared/document-configs-form/document-configs-form.component';
import { DocumentsListTableComponent } from './shared/documents-list-table/documents-list-table.component';
import { FoldersListBoxesComponent } from './shared/folders-list-boxes/folders-list-boxes.component';
import { FoldersModalComponent } from './shared/folders-modal/folders-modal.component';
import { AlertDocumentsLimitModalComponent } from './shared/alert-documents-limit-modal/alert-documents-limit-modal.component';
import { AlertCreditsLimitModalComponent } from './shared/alert-credits-limit-modal/alert-credits-limit-modal.component';
import { DocumentPreviewModalComponent } from './shared/document-preview-modal/document-preview-modal.component';
import { CreateFolderModalComponent } from './shared/create-folder-modal/create-folder-modal.component';
import { UpdateFolderModalComponent } from './shared/update-folder-modal/update-folder-modal.component';
import { CreateSignerModalComponent } from './shared/create-signer-modal/create-signer-modal.component';
import { TransferDocumentsModalComponent } from './shared/transfer-documents-modal/transfer-documents-modal.component';
import { PreSignModalComponent } from './shared/pre-sign-modal/pre-sign-modal.component';
import { VerificationSmsModalComponent } from './shared/verification-sms-modal/verification-sms-modal.component';
import { VerificationDataModalComponent } from './shared/verification-data-modal/verification-data-modal.component';
import { DocumentsTaxonomyComponent } from './shared/documents-taxonomy/documents-taxonomy.component';
import { DocumentsSearchComponent } from './shared/documents-search/documents-search.component';
import { VerificationIdAnalyzerModalComponent } from './shared/verification-id-analyzer-modal/verification-id-analyzer-modal.component';
import { VerificationModalComponent } from './shared/verification-modal/verification-modal.component';
import { DocumentsHomeComponent } from './documents-home/documents-home.component';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentsTrashbinListComponent } from './documents-trashbin-list/documents-trashbin-list.component';
import { DocumentsOrganizationListComponent } from './documents-organization-list/documents-organization-list.component';
import { DocumentsGroupListComponent } from './documents-group-list/documents-group-list.component';
import { DocumentsOrganizationMemberListComponent } from './documents-organization-member-list/documents-organization-member-list.component';
import { FoldersListComponent } from './folders-list/folders-list.component';
import { FoldersGroupListComponent } from './folders-group-list/folders-group-list.component';
import { FoldersOrganizationListComponent } from './folders-organization-list/folders-organization-list.component';
import { FoldersOrganizationMemberListComponent } from './folders-organization-member-list/folders-organization-member-list.component';
import { DocumentsNewComponent } from './documents-new/documents-new.component';
import { DocumentsEditComponent } from './documents-edit/documents-edit.component';
import { DocumentsFieldsEditComponent } from './documents-fields-edit/documents-fields-edit.component';
import { DuplicateTemplateModalComponent } from './documents-new/duplicate-template-modal/duplicate-template-modal.component';
import { DocumentsShowComponent } from './documents-show/documents-show.component';
import { AuthLoginModalComponent } from './documents-show/auth-login-modal/auth-login-modal.component';
import { AuthRegisterModalComponent } from './documents-show/auth-register-modal/auth-register-modal.component';
import { AuthEmailConfirmationModalComponent } from './documents-show/auth-email-confirmation-modal/auth-email-confirmation-modal.component';
import { UpdateSignerModalComponent } from './documents-show/update-signer-modal/update-signer-modal.component';
import { SignLinkModalComponent } from './documents-show/sign-link-modal/sign-link-modal.component';
import { ConsultCPFModalComponent } from './documents-show/consult-cpf-modal/consult-cpf-modal.component';
import { PreLoginModalComponent } from './documents-show/pre-login-modal/pre-login-modal.component';
import { DocumentCreatedModalComponent } from './documents-show/document-created-modal/document-created-modal.component';
import { DocumentSignedModalComponent } from './documents-show/document-signed-modal/document-signed-modal.component';
import { UserExistsModalComponent } from './documents-show/user-exists-modal/user-exists-modal.component';
import { ChooseSignatureModalComponent } from './documents-show/choose-signature-modal/choose-signature-modal.component';
import { RejectModalComponent } from './documents-show/reject-modal/reject-modal.component';
import { CreateTemplateVariableModalComponent } from './documents-templates/create-template-variable-modal/create-template-variable-modal.component';
import { EditTemplateVariableModalComponent } from './documents-templates/edit-template-variable-modal/edit-template-variable-modal.component';
import { TemplateNameModalComponent } from './documents-templates/template-name-modal/template-name-modal.component';
import { DocumentsTemplateComponent } from './documents-templates/documents-template.component';
import { DocumentsFillTemplateComponent } from './documents-templates/fill-template/fill-template.component';
import { DocumentHistoryComponent } from './documents-show/document-history-sidebar/document-history-sidebar.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { ContactsMenuComponent } from './contacts-list/contacts-menu/contacts-menu.component';
import { ContactsUserComponent } from './contacts-list/contacts-user/contacts-user.component';
import { WaitingroomComponent } from '../whitelabel/waitingroom/waitingroom.component';

@NgModule({
  declarations: [
    DocumentsListBoxesComponent,
    DocumentsTitleDropdownComponent,
    SignerInputComponent,
    SignerFloatingFieldsInputComponent,
    DocumentConfigsFormComponent,
    DocumentsListTableComponent,
    FoldersListBoxesComponent,
    UniqueSignersPipe,
    SortByCurrentSignersPipe,
    HighlightPipe,
    DocumentsHomeComponent,
    DocumentsListComponent,
    DocumentsTrashbinListComponent,
    DocumentsGroupListComponent,
    DocumentsOrganizationListComponent,
    DocumentsOrganizationMemberListComponent,
    FoldersListComponent,
    FoldersGroupListComponent,
    FoldersOrganizationListComponent,
    FoldersOrganizationMemberListComponent,
    DocumentsNewComponent,
    DocumentsEditComponent,
    DocumentsFieldsEditComponent,
    DocumentsShowComponent,
    AuthLoginModalComponent,
    AuthRegisterModalComponent,
    AuthEmailConfirmationModalComponent,
    FoldersModalComponent,
    DocumentsTaxonomyComponent,
    DuplicateTemplateModalComponent,
    AlertDocumentsLimitModalComponent,
    AlertCreditsLimitModalComponent,
    DocumentPreviewModalComponent,
    CreateFolderModalComponent,
    UpdateFolderModalComponent,
    CreateSignerModalComponent,
    TransferDocumentsModalComponent,
    PreSignModalComponent,
    VerificationDataModalComponent,
    VerificationIdAnalyzerModalComponent,
    VerificationSmsModalComponent,
    VerificationModalComponent,
    UpdateSignerModalComponent,
    SignLinkModalComponent,
    ConsultCPFModalComponent,
    PreLoginModalComponent,
    DocumentCreatedModalComponent,
    DocumentSignedModalComponent,
    UserExistsModalComponent,
    ChooseSignatureModalComponent,
    RejectModalComponent,
    CreateTemplateVariableModalComponent,
    EditTemplateVariableModalComponent,
    TemplateNameModalComponent,
    DocumentsTemplateComponent,
    DocumentsFillTemplateComponent,
    DocumentHistoryComponent,
    DocumentsSearchComponent,
    ContactsListComponent,
    ContactsMenuComponent,
    ContactsUserComponent,
    WaitingroomComponent
  ],
  imports: [
    ResizableModule,
    CommonModule,
    DocumentsRoutingModule,
    RouterModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbCollapseModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    FilterPipeModule,
    Ng2SearchPipeModule,
    ClipboardModule,
    ReactiveFormsModule,
    NgxMaskModule,
    DndModule,
    EditorModule,
    NgxDaterangepickerMd,
    QRCodeModule
  ],
  exports: [DocumentHistoryComponent],
  providers: [UniqueSignersPipe, SortByCurrentSignersPipe, HighlightPipe, NgbActiveModal, { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
  entryComponents: [
    AuthLoginModalComponent,
    AuthRegisterModalComponent,
    AuthEmailConfirmationModalComponent,
    FoldersModalComponent,
    AlertDocumentsLimitModalComponent,
    AlertCreditsLimitModalComponent,
    DocumentPreviewModalComponent,
    CreateFolderModalComponent,
    UpdateFolderModalComponent,
    CreateSignerModalComponent,
    TransferDocumentsModalComponent,
    PreSignModalComponent,
    VerificationDataModalComponent,
    VerificationIdAnalyzerModalComponent,
    VerificationSmsModalComponent,
    VerificationModalComponent,
    DuplicateTemplateModalComponent,
    UpdateSignerModalComponent,
    SignLinkModalComponent,
    ConsultCPFModalComponent,
    PreLoginModalComponent,
    DocumentCreatedModalComponent,
    DocumentSignedModalComponent,
    UserExistsModalComponent,
    ChooseSignatureModalComponent,
    RejectModalComponent,
    CreateTemplateVariableModalComponent,
    EditTemplateVariableModalComponent,
    TemplateNameModalComponent
  ]
})
export class DocumentsModule {}
