import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { SlimOrganization } from '@app/models';
import { ErrorHandlerService, OrganizationService } from '@app/services';

export type ModalResult = SlimOrganization;
// export interface ModalPublicProperties {}

@Component({
  selector: 'app-create-organization-modal',
  templateUrl: './create-organization-modal.component.html',
  styleUrls: ['./create-organization-modal.component.scss']
})
export class CreateOrganizationModalComponent implements AfterContentInit {
  form!: FormGroup;
  isLoading = false;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private errorHandlerService: ErrorHandlerService, private organizationService: OrganizationService) {
    this.form = this.formBuilder.group({ organization: this.formBuilder.group({ name: '' }) });
  }

  ngAfterContentInit() {
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  create() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.organizationService
      .create(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
