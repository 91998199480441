import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { pick, uniqBy } from 'lodash';
import * as dayjs from 'dayjs';

import { untilDestroyed } from '@app/core';
import { DocumentStatusEnum, MembersAndGroups, User } from '@app/models';
import { AppService, OrganizationService, UserService } from '@app/services';
import { SelectOption } from '@app/shared';

export interface DocumentsSearchQuery {
  name?: string;
  signer?: string;
  search?: string;
  status?: DocumentStatusEnum;
  startDate?: string;
  endDate?: string;
  groupUuid?: string;
  memberId?: string;
  includeArchived?: boolean;
  includeDeleted?: boolean;
}

@Component({
  selector: 'app-documents-search',
  templateUrl: './documents-search.component.html',
  styleUrls: ['./documents-search.component.scss']
})
export class DocumentsSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedMemberOrGroup: MembersAndGroups;
  @Input() isFoldersSearch = false;
  @Input() isTrash = false;
  @Input() search: DocumentsSearchQuery = {};
  @Output() searchChange = new EventEmitter<DocumentsSearchQuery>();
  @Output() closeClick = new EventEmitter<void>();
  @ViewChild('dateRangePickerLabel') dateRangePickerLabelRef: ElementRef;
  form: FormGroup;
  currentUser: User;
  currentUserMember: MembersAndGroups;
  documentStatuses: SelectOption[];
  selectedDateRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs };
  isValidDateRange = false;
  readonly dateRangePickerOptions = {
    ranges: {
      Hoje: [dayjs(), dayjs()],
      Ontem: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
      'Últimos 7 Dias': [dayjs().subtract(6, 'days'), dayjs()],
      'Últimos 30 Dias': [dayjs().subtract(29, 'days'), dayjs()],
      'Este Ano': [dayjs().startOf('year'), dayjs().endOf('year')],
      'Ano passado': [
        dayjs()
          .subtract(1, 'year')
          .startOf('year'),
        dayjs()
          .subtract(1, 'year')
          .endOf('year')
      ],
      'Todo o período': [dayjs('2016-06-12'), dayjs()]
    },
    locale: {
      daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    }
  };
  private previousSearch: DocumentsSearchQuery;

  constructor(
    public organizationService: OrganizationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private appService: AppService
  ) {
    this.documentStatuses = [null, DocumentStatusEnum.Pending, DocumentStatusEnum.Signed, DocumentStatusEnum.NotSigned].map(status => ({
      key: status,
      value: this.translateService.instant(status ? `documents.statusFilter.${status.toLowerCase()}` : 'menu.noFilter')
    }));
    this.setForm();
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserMember = { id: this.currentUser.id, type: 'member', name: this.currentUser.name };
      });

    if (this.search) {
      if (this.search.memberId || this.search.groupUuid) {
        this.selectedMemberOrGroup = { id: this.search.memberId || this.search.groupUuid, name: '', type: this.search.memberId ? 'member' : 'group' };
        delete this.search.memberId;
        delete this.search.groupUuid;
        this.selectedMemberOrGroup.type === 'member'
          ? this.organizationService.organizationMember({ id: this.selectedMemberOrGroup.id }).subscribe(member => (this.selectedMemberOrGroup.name = member.user.name))
          : this.organizationService.organizationGroup({ uuid: this.selectedMemberOrGroup.id }).subscribe(group => (this.selectedMemberOrGroup.name = group.name));
      }
      if (this.search.startDate && this.search.endDate) {
        this.selectedDateRange = { startDate: dayjs(this.search.startDate), endDate: dayjs(this.search.endDate) };
        delete this.search.startDate;
        delete this.search.endDate;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.search && changes.search.currentValue) {
      this.setForm();
    }
  }

  ngOnDestroy() {}

  organizationMembersAndGroups(search: string): Observable<SelectOption[]> {
    return this.organizationService.organizationMembersAndGroups({ limit: 8, page: 1, search }).pipe(
      delay(0), // Faz rodar por último se houver outros requests concorrentes
      map(page =>
        (page.data || []).filter(
          item => (item.type === 'member' && this.currentUser.currentPermissions.view_member_documents_oz) || (item.type === 'group' && this.currentUser.currentPermissions.view_group_documents_oz)
        )
      ),
      map(membersAndGroups => uniqBy((membersAndGroups || []).concat(search ? [] : [this.selectedMemberOrGroup || this.currentUserMember]), 'id')),
      map(membersAndGroups => membersAndGroups.filter(data => data && data.name).map(data => ({ key: data, value: data.name, limitWidth: 380 })))
    );
  }

  emitSearchChange() {
    this.form.get('startDate').setValue(null);
    this.form.get('endDate').setValue(null);
    if (this.selectedDateRange && this.selectedDateRange.startDate && this.selectedDateRange.endDate) {
      this.form.get('startDate').setValue(this.selectedDateRange.startDate.format('YYYY-MM-DD'));
      this.form.get('endDate').setValue(this.selectedDateRange.endDate.format('YYYY-MM-DD'));
    }

    this.form.get('groupUuid').setValue(null);
    this.form.get('memberId').setValue(null);
    if (this.selectedMemberOrGroup) {
      this.form.get(this.selectedMemberOrGroup.type === 'group' ? 'groupUuid' : 'memberId').setValue(this.selectedMemberOrGroup.id);
    }

    const formValue = this.form.value;
    const slimFormValue: any = {};
    for (const key in formValue) {
      if (formValue[key]) {
        slimFormValue[key] = typeof formValue[key] === 'string' ? formValue[key].trim() : formValue[key];
      }
    }

    if (JSON.stringify(this.previousSearch) !== JSON.stringify(slimFormValue)) {
      // Substitui valores em this.search pelos novos valores no mesmo objeto na memória, pra não dar problemas
      this.search = this.search || {};
      for (const key in this.search) {
        delete this.search[key];
      }
      for (const key in slimFormValue) {
        this.search[key] = slimFormValue[key];
      }

      this.appService.searchParams = pick(slimFormValue || {}, ['includeArchived', 'includeDeleted']);
      this.searchChange.emit((slimFormValue || {}) as DocumentsSearchQuery);
    }
    this.previousSearch = slimFormValue;
  }

  /**
   * Ensures that even if the allowApplyOnlyWhenStartAndEndDateExists method fails, the correct value will be delivered, and the query will not fail with an error.
   * @param {object} e Receives an object with startDate and endDate information
   * @void Applies the correct text to the input and applies the same startDate value to the endDate
   */
  fixInvalidEndDateOnChange(e: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }, input: HTMLInputElement) {
    if (e.endDate === null) return; // Prevent errors
    if (!isNaN(e.endDate.day())) return; // If only startDate exists
    this.selectedDateRange.endDate = this.selectedDateRange.startDate;
    input.value = `${this.selectedDateRange.startDate.format('DD/MM/YYYY')} - ${this.selectedDateRange.startDate.format('DD/MM/YYYY')}`; // Displays the input value correctly
  }

  /**
   * Disables the APPLY button of the ngx-daterangepicker-material component if an endDate is not detected
   * @param {boolean} bool Receives from the component the information if the endDate parameter exists
   * @void Applies the disabled attribute to the button if only the startDate is provided and endDate is not provided
   */
  allowApplyOnlyWhenStartAndEndDateExists(bool: boolean) {
    this.dateRangePickerLabelRef.nativeElement.querySelector('ngx-daterangepicker-material .buttons .btn:last-of-type').disabled = !bool;
  }

  private setForm() {
    const attrs: DocumentsSearchQuery = {
      name: this.search.name || null,
      signer: this.search.signer || null,
      search: this.search.search || null,
      status: this.search.status || null,
      startDate: this.search.startDate || null,
      endDate: this.search.endDate || null,
      groupUuid: this.search.groupUuid || null,
      memberId: this.search.memberId || null,
      includeArchived: this.search.includeArchived || this.appService.searchParams.includeArchived || false,
      includeDeleted: this.search.includeDeleted || this.appService.searchParams.includeDeleted || false
    };
    if (this.form) {
      this.form.setValue(attrs);
    } else {
      this.form = this.formBuilder.group(attrs);
    }
  }
}
