<div class="body-holder">
  <aside class="sidebar">
    <app-document-sidebar-header>
      <a class="back" [routerLink]="whitelabelService.params.mainPath || ['/documentos', documentId]">{{ 'button.back' | translate }}</a>
    </app-document-sidebar-header>
    <main class="document-signers">
      <div class="document-signers-top">
        <div class="document-signers-title">{{ 'documents.additionalSettings' | translate }}</div>
      </div>
      <app-document-configs-form [(document)]="documentInput" [validationErrors]="validationErrors" *ngIf="documentInput" #configsForm></app-document-configs-form>
    </main>
    <footer class="sidebar-options">
      <button type="button" class="sidebar-options-submit" [disabled]="isLoading" (click)="updateDocument()">{{ 'button.save' | translate }}</button>
    </footer>
  </aside>
  <section class="models">
    <app-pdf-viewer
      [pdfUrl]="!documentService.isPastLifecycle(document) && document?.files.original"
      [qualified]="document?.qualified"
      [documentFooter]="documentInput?.new_signature_style && documentInput?.footer"
      [document]="document"
      [isNewSignatureStyle]="documentInput?.new_signature_style"
      [floatingFields]="floatingFields"
      [documentWhitelabel]="documentWhitelabel"
      [class.d-none]="documentService.isPastLifecycle(document)"
      [isDocumentWhitelabelLoaded]="isDocumentWhitelabelLoaded"
    >
    </app-pdf-viewer>
    <app-document-lifecycle-warning [(document)]="document" *ngIf="documentService.isPastLifecycle(document)"></app-document-lifecycle-warning>
  </section>
</div>
