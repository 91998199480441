<form class="modal-body" [formGroup]="form" (ngSubmit)="search()" novalidate>
  <p class="centermodal__title">{{ 'auth.fillEmail' | translate }}</p>

  <div class="centermodal__form">
    <label class="field__holder" [appFormControlWrap]="form.get('email')">
      <input
        type="email"
        class="field"
        formControlName="email"
        name="email"
        autocomplete="none"
        role="presentation"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        required
        appValidEmail
        #emailInput
      />
      <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
    </label>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()" ngbAutofocus>{{ 'button.close' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.invalid || isLoading">{{ 'button.continue' | translate }}</button>
  </div>
</form>
