import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { kebabCase, merge, cloneDeep } from 'lodash';

import { untilDestroyed } from '@app/core';
import { SlimTemplate, User } from '@app/models';
import { AppService, TemplateService, ErrorHandlerService, UserService, WhitelabelService } from '@app/services';
import { AlertModalService } from '@app/shared';
import { LdatePipe } from '@app/shared/pipes';
import { loadTinyMce } from '../tinymce-load';
import { TranslateService } from '@ngx-translate/core';

declare var tinymce: any;

// Futuro eu do futuro, desculpe

@Component({
  selector: 'app-documents-fill-template',
  templateUrl: './fill-template.component.html',
  styleUrls: ['../documents-template.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class DocumentsFillTemplateComponent implements OnInit, OnDestroy {
  templates: SlimTemplate[];
  currentUser: User;

  isRendered = false;
  isLoading = false;
  isEditing = false;
  canSubmit = true;

  attributeInputs: any = [];
  templateInput: any = {};

  private navigationState: { [k: string]: any };

  constructor(
    public appService: AppService,
    public whitelabelService: WhitelabelService,
    private errorHandlerService: ErrorHandlerService,
    private templateService: TemplateService,
    private activetedRoute: ActivatedRoute,
    private alertModalService: AlertModalService,
    private userService: UserService,
    private router: Router,
    private ldatePipe: LdatePipe,
    private translateService: TranslateService
  ) {
    this.navigationState = router.getCurrentNavigation().extras.state || {};
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.templateInput.id = this.activetedRoute.snapshot.paramMap.get('id');

    this.isLoading = true;
    this.templateService
      .template({ id: this.templateInput.id })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.slugifyAttributeInputs();
          this.tinyMCEInitialization();
        })
      )
      .subscribe(
        template => {
          this.attributeInputs = template.attributes;
          this.templateInput = {
            id: template.id,
            name: template.name,
            text: template.text,
            created_at: template.created_at,
            updated_at: template.updated_at
          };

          this.isRendered = true;
        },
        error => {
          this.errorHandlerService.handle(error);
          this.router.navigate(['/'], { replaceUrl: true });
        }
      );
  }

  ngOnDestroy() {}

  slugifyAttributeInputs() {
    this.attributeInputs.map((e: any) => {
      e.slug = kebabCase(e.name);
      e.value = '';
      e.position = parseInt(e.position);
    });

    this.attributeInputs.sort(this.comparePosition);
  }

  comparePosition = (a: any, b: any) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  deleteTemplate() {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.deleteTemplate'),
        confirmButtonText: this.translateService.instant('button.exclude')
      })
      .subscribe(() => {
        this.isLoading = true;
        this.templateService
          .delete({ id: this.templateInput.id })
          .pipe(finalize(() => ((this.isLoading = false), this.router.navigate([`documentos/novo`]))))
          .subscribe(
            () =>
              this.templates.splice(
                this.templates.findIndex(item => item.id === this.templateInput.id),
                1
              ),

            error => this.errorHandlerService.handle(error)
          );
      });
  }

  handleChangeForValidation = (event: any, field: string) => {
    const target = event.target;
    const value = event.target.value;

    let response = true;

    if (value.length > 0)
      switch (field) {
        case 'cnpj':
          response = cnpjValidation(value);
          break;

        case 'cpf':
          response = cpfValidation(value);
          break;

        case 'email':
          response = emailValidation(value);
          break;

        default:
          response = true;
          break;
      }

    if (!response) {
      target.parentElement.classList.add('error');
    } else {
      target.parentElement.classList.remove('error');
    }
  };

  handleChangeFillVarible = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    const required = event.target.required;

    const index = this.attributeInputs.findIndex((obj: any) => obj.name === name);
    this.attributeInputs[index].value = value;

    this.changeEditorVariableValue(name, value);
    this.changeFieldsPlaceholder(event.target, value);

    if (required && value.length > 0) {
      event.target.parentElement.classList.remove('error');
    }
  };

  changeFieldsPlaceholder(element: HTMLElement, value: string) {
    if (value === '') element.classList.add('empty');
    else element.classList.remove('empty');
  }

  changeEditorVariableValue(name: string, value: any) {
    let hasContent = true;
    if (value.length === 0 || value === 0) hasContent = false;

    const mceQuery = tinymce.activeEditor && tinymce.activeEditor.dom;
    const element = mceQuery && mceQuery.select(`[data-original-variable="{%${this.parseVariableName(name)}%}"]`);

    if (element) {
      element.forEach((e: any) => {
        hasContent ? (mceQuery.setHTML(e, value), mceQuery.setAttrib(e, 'class', '')) : (mceQuery.setHTML(e, name), mceQuery.setAttrib(e, 'class', 'variable'));
      });
    }
  }

  verifyRequiredFields() {
    this.attributeInputs.map((e: any, i: number) => {
      if (!e.required) return;
      if (e.value.length !== 0) return;

      document.querySelector(`[name="${e.name}"]`).parentElement.classList.add('error');
    });
  }

  verifyErrorFields() {
    const fields = document.querySelectorAll('.form__field .field__holder.error');
    if (fields.length) {
      const element: HTMLElement = fields[0].querySelector('.field');
      element.focus();
      element.scrollIntoView({ block: 'center', behavior: 'auto' });
    }
    this.canSubmit = fields.length === 0;
  }

  editTemplate() {
    this.router.navigate([`modelos/${this.templateInput.id}/editar`], { state: { from: 'fill' } });
  }

  printTemplate() {
    this.verifyRequiredFields();
    this.verifyErrorFields();

    if (!this.canSubmit) return;

    const mceQuery = tinymce.activeEditor.dom;
    this.attributeInputs.map((el: any, i: any) => {
      const elements = mceQuery.select(`[data-original-variable="{%${this.parseVariableName(this.attributeInputs[i].name)}%}"]`);

      elements.forEach((e: any) => {
        mceQuery.setOuterHTML(e, `${this.attributeInputs[i].value}`);
      });
    });

    let content = tinymce.activeEditor.getContent();
    if (!content.match(/@page{/)) {
      content += '<style>@page{margin:1.5cm 1.5cm 2.5cm; size: 210mm 297mm;}</style>';
    }
    const dom = '<div>' + content + '</div>';

    const file = new File([new Blob([dom], { type: 'text/html' })], `${this.templateInput.name.replace(/[\.\\\/|\s]+/g, ' ')}.html`, { type: 'text/html' });

    this.router.navigate(['documentos/novo'], { state: merge(cloneDeep(this.navigationState), { file }) });
  }

  goBack() {
    this.router.navigate(['documentos/novo'], { state: merge(cloneDeep(this.navigationState)) });
  }

  isWhitelabel() {
    // return true;
    return !this.appService.isAutentiqueDomain() && !!this.whitelabelService?.whitelabel?.domain;
  }

  private tinyMCEInitialization() {
    const that = this;
    loadTinyMce().subscribe(() => {
      tinymce.remove();

      tinymce.init({
        init_instance_callback: (editor: any) => {
          // editor.fire('focus');
          editor.setContent(that.templateInput.text || '');
        },
        readonly: 1,
        branding: false,
        elementpath: false,
        inline: true,
        language: 'pt_BR',
        autofocus: false,
        fixed_toolbar_container: '.template__header-toolbox',
        placeholder: this.translateService.instant('shared.loading'),
        resize: 'both',
        selector: '#tinymce',
        event_root: '#tinymce__root',
        min_height: 500,
        content_css: '/assets/tinymce-custom.css',
        content_style: `.variable {
          background: #FDFF32;
          outline: 0!important;
          cursor: default;
        }
        `,
        suffix: '.min',
        base_url: '/vendors/tinymce/',
        menubar: false,
        plugins: ['print', 'searchreplace visualblocks', 'fullscreen table paste quickbars code lists'],
        toolbar: 'fontselect | fontsizeselect | bold italic underline strikethrough | numlist bullist | alignleft aligncenter alignright alignjustify | table | outdent indent | code',
        font_formats:
          'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva',
        fontsize_formats: '11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt'
      });
    });
  }

  private parseVariableName(name: string) {
    return (name || '').replace(/\"/g, "'");
  }
}

export function cnpjValidation(value: any) {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string';
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (value.length > 18) return false;

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

    // Se o formato é válido, usa um truque para seguir o fluxo da validação, se não, retorna inválido
    return digitsOnly || validFormat;
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x: any) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

export function cpfValidation(cpf: any) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  return resto === parseInt(cpf.substring(10, 11));
}

export function emailValidation(email: any) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}
