<div class="body-holder">
  <aside class="sidebar">
    <app-document-sidebar-header>
      <a class="back" [routerLink]="whitelabelService.params.mainPath || ['/documentos', documentId]">{{ 'button.back' | translate }}</a>
    </app-document-sidebar-header>
    <main class="document-signers">
      <div class="document-signers-top">
        <div class="document-signers-title">{{ 'documents.optionalSignaturePlacement' | translate }}</div>
      </div>
      <div class="document-signers-list">
        <app-signer-floating-fields-input
          *ngFor="let signer of signers | uniqueSigners: document?.qualified; let index = index"
          [signatureAction]="signer.action"
          [identification]="signer[signer._extra.signerMainAttribute]"
          [idColor]="idColors[index % idColors.length]"
          [qualified]="document?.qualified"
          [isCountryBR]="document?.locale?.country === 'BR'"
          [selected]="signer._extra.isSelected"
          [disabled]="isLoading"
          (addFloatingField)="setFloatingFieldFor(signer, $event, idColors[index % idColors.length])"
        >
        </app-signer-floating-fields-input>
      </div>
    </main>
    <footer class="sidebar-options">
      <button type="button" class="sidebar-options-submit" [disabled]="isLoading" (click)="updateDocument()">{{ 'button.save' | translate }}</button>
    </footer>
  </aside>
  <section class="models">
    <app-pdf-viewer
      [pdfUrl]="!documentService.isPastLifecycle(document) && document?.files.original"
      [enableEditFloatingFields]="true"
      [documentFooter]="null"
      [qualified]="document?.qualified"
      [isNewSignatureStyle]="document?.new_signature_style"
      [document]="document"
      [class.d-none]="documentService.isPastLifecycle(document)"
      (selectedFieldChange)="selectFloatingFieldInput($event)"
      #pdfViewer
    >
    </app-pdf-viewer>
    <app-document-lifecycle-warning [(document)]="document" *ngIf="documentService.isPastLifecycle(document)"></app-document-lifecycle-warning>
  </section>
</div>

<div class="document-focus-overlay" (click)="disableFloatingFieldsSettingMode()" *ngIf="isSettingFloatingFields"></div>
