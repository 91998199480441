<form class="modal-body" [formGroup]="form" (ngSubmit)="update()" novalidate>
  <p class="centermodal__title">{{ 'documents.customizeYourSignature' | translate }}</p>
  <p class="centermodal__description">{{ 'documents.chooseSignatureAppearance' | translate }}</p>

  <div class="centermodal__signaturepad-holder">
    <ng-container formGroupName="user">
      <app-signature-pad
        class="centermodal__signaturepad-component"
        [format]="form.get('user.format').value"
        [font]="form.get('user.font').value"
        [disableSignature]="disableSignature"
        [disableInitials]="disableInitials"
        [disableFormat]="!!document?.configs?.signature_appearance"
        (formatChange)="form.get('user.format').setValue($event)"
        #signaturePad
      >
      </app-signature-pad>

      <label class="field__holder select" *ngIf="form.get('user.format').value === SignatureFormat.Handwriting">
        <select class="field" formControlName="font">
          <option [ngValue]="font.key" *ngFor="let font of fonts | keyvalue">{{ font.value | translate }}</option>
        </select>
        <p class="field__placeholder">{{ 'settings.font' | translate }}</p>
      </label>
    </ng-container>

    <p class="centermodal__signaturepad-term">{{ 'documents.bySigningYouAgreeToTerms' | translate }}</p>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.back' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading">{{ 'button.forward' | translate }}</button>
  </div>
</form>
