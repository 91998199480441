import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, FolderService } from '@app/services';
import { Folder, ContextEnum } from '@app/models';

export type ModalResult = Folder;
export interface ModalPublicProperties {
  context?: ContextEnum;
  groupId?: number;
  parentId?: string;
}

@Component({
  selector: 'app-create-folder-modal',
  templateUrl: './create-folder-modal.component.html',
  styleUrls: ['./create-folder-modal.component.scss']
})
export class CreateFolderModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() context = ContextEnum.User;
  @Input() groupId: number;
  @Input() parentId: string;
  form!: FormGroup;
  isLoading = false;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private foldersService: FolderService, private errorHandlerService: ErrorHandlerService) {
    this.form = this.formBuilder.group({ folder: this.formBuilder.group({ name: '' }) });
  }

  ngAfterContentInit() {
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  create() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.foldersService
      .create({ context: this.context, groupId: this.groupId || null, parentId: this.parentId || null, ...this.form.value })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
