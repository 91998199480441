import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';

import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthRegisterComponent } from './auth-register/auth-register.component';
import { AuthPasswordNewComponent } from './auth-password-new/auth-password-new.component';
import { AuthPasswordEditComponent } from './auth-password-edit/auth-password-edit.component';
import { AuthEmailConfirmationComponent } from './auth-email-confirmation/auth-email-confirmation.component';

const routes: Routes = [
  { path: 'entrar', component: AuthLoginComponent, data: { title: extract('Entrar | Autentique') } }, // 'login'
  { path: 'cadastro', component: AuthRegisterComponent, data: { title: extract('Cadastrar | Autentique') } }, // 'register'
  { path: 'cadastro/:token', component: AuthRegisterComponent, data: { title: extract('Cadastrar | Autentique') } }, // 'register/:token'
  { path: 'recuperar-senha', component: AuthPasswordNewComponent, data: { title: extract('Recuperar senha | Autentique') } }, // 'recuperar-senha'
  { path: 'recuperar-senha/:token', component: AuthPasswordEditComponent, data: { title: extract('Recuperar senha | Autentique') } }, // 'recover/:token'
  { path: 'confirmacao', component: AuthEmailConfirmationComponent, data: { title: extract('Confirmação de e-mail | Autentique') } } // 'confirmation'
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
