import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AttributeInput, TemplateAttributeTypes } from '@app/models';
import { templateAttributeTypes } from '../template-attribute-types';

export type ModalResult = AttributeInput;
export interface ModalPublicProperties {
  variable: AttributeInput;
  existingNames?: string[];
}

@Component({
  selector: 'app-edit-template-variable-modal',
  templateUrl: './edit-template-variable-modal.component.html',
  styleUrls: ['./edit-template-variable-modal.component.scss']
})
export class EditTemplateVariableModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() variable: ModalPublicProperties['variable'];
  @Input() existingNames: ModalPublicProperties['existingNames'] = [];
  form!: FormGroup;
  readonly types = templateAttributeTypes;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({ variable: this.formBuilder.group({ id: null, name: '', type: '', position: null, required: false } as ModalPublicProperties['variable']) });
  }

  ngAfterContentInit() {
    this.form.get('variable').setValue({
      id: (this.variable || {}).id || null,
      name: (this.variable || {}).name || '',
      type: (this.variable || {}).type || TemplateAttributeTypes.Text,
      position: typeof (this.variable || {}).position === 'number' ? this.variable.position : null,
      required: typeof (this.variable || {}).required === 'boolean' ? this.variable.required : false
    } as ModalPublicProperties['variable']);
    setTimeout(() => this.nameInput.nativeElement.focus());
  }

  edit() {
    const result: ModalResult = this.form.get('variable').value;
    result.name = (result.name || '').trim().replace(/\s+/, ' ');
    this.modal.close(result);
  }
}
