import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { User, UserInput, OrganizationInput, SlimOrganization } from '@app/models';
import { ErrorHandlerService, OrganizationService } from '@app/services';

export type ModalResult = SlimOrganization;
export interface ModalPublicProperties {
  user: User;
}

@Component({
  selector: 'app-edit-organization-modal',
  templateUrl: './edit-organization-modal.component.html',
  styleUrls: ['./edit-organization-modal.component.scss']
})
export class EditOrganizationModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() user: User;
  form!: FormGroup;
  isLoading = false;
  @ViewChild('companyInput', { static: false }) private companyInput: ElementRef<HTMLInputElement>;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder, private errorHandlerService: ErrorHandlerService, private organizationService: OrganizationService) {
    this.form = this.formBuilder.group({
      organization: this.formBuilder.group({ name: null, cnpj: null } as OrganizationInput)
    } as UserInput);
  }

  ngAfterContentInit() {
    this.form.setValue({
      organization: {
        name: this.form.get('organization.name').value || this.user.organization.name || null,
        cnpj: (this.form.get('organization.cnpj').value || this.user.organization.cnpj || '').replace(/[^0-9]/g, '') || null
      }
    } as UserInput);
    setTimeout(() => this.companyInput.nativeElement.focus());
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.organizationService
      .update(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as SlimOrganization),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
